import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

import CloseIcon from '@material-ui/icons/CloseRounded';
import {
  Card,
  Chip,
  ClickAwayListener,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Paper,
  Popover,
  Radio,
  Typography
} from '@material-ui/core';
import { alpha, withStyles } from '@material-ui/core/styles';

let closeCardBtn = false;

const styles = ({
  location: {
    marginTop: 0,
    paddingLeft: 0,
    color: '#3A3A3A',
  },
  cardContainer: {
    cursor: 'pointer',
    height: '100%',
    width: '100%',
  },
  locationCard: {
    cursor: 'pointer',
    position: 'relative',
    backgroundColor: '#f5f5f5',
  },
  locationCardContents: {
    cursor: 'pointer',
  },
  chip: {
    marginRight: 1,
    marginLeft: 1,
    textAlign: 'center',
    alignItems: 'center',
    fontWeight: 400,
    cursor: 'pointer',
    color: '#1e1e1e'
  },
  locMenu: {
    backgroundColor: '#D6D6D6',
  },
  root: {},
  checked: {}
});

const initLocationChecked = (locList, initLoc, emptyCard) => {
  let locationChecked = {};

  for (let l in locList) {
    let locName = locList[l];
    locationChecked[locName] = false;
  }

  if (!emptyCard) {
    locationChecked[locList[initLoc]] = true;
  }

  return locationChecked;
}

const initOwnerChecked = (ownerList) => {
  let ownerChecked = {};

  if (Object.keys(ownerList).length === 1) {
    ownerChecked[Object.keys(ownerList)[0]] = false;
  }
  else {
    for (let o in ownerList) {
      ownerChecked[o] = false;
      let ownerDivisions = ownerList[o];

      if (!Array.isArray(ownerDivisions)) {
        for (let od in ownerDivisions) {
          ownerChecked[od] = false;
        }
      }
    }
  }

  return ownerChecked;
}

class LocationConvCard extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      cardState: {
        name: this.props.name,
        aggregate: true,
        aggregationType: (Object.keys(this.props.initlocationCard).length === 0) ? 'average' : this.props.initlocationCard.aggregationType,
        locationChecked: (Object.keys(this.props.initlocationCard).length === 0)
          ? initLocationChecked(this.props.locationsObject['locationList'], this.props.name, this.props.emptyCard)
          : this.props.initlocationCard.locationChecked,
        locationChecked2: (!this.props.initlocationCard.hasOwnProperty('locationChecked2'))
          ? initLocationChecked(this.props.locationsObject['locationList'], this.props.name, this.props.emptyCard)
          : this.props.initlocationCard.locationChecked2,
        checkedLocOwners: (Object.keys(this.props.initlocationCard).length === 0)
          ? initOwnerChecked(this.props.locationsObject['locOwners'])
          : this.props.initlocationCard.checkedLocOwners,
        checkedLocCategories: (Object.keys(this.props.initlocationCard).length === 0)
          ? Object.keys(this.props.locationsObject['locCategories']).reduce((acc, category) => ({ ...acc, [category]: false }), {})
          : this.props.initlocationCard.checkedLocCategories,
      },
      menuAnchor: null,
      menuOpen: false,
      noCard: this.props.noCard,
    }
  }

  changeLoc = (num, name) => event => {
    let cardState = this.state.cardState;
    let locChecked = cardState.locationChecked;
    let locChecked2 = cardState.locationChecked2;
    let tempCheckedLocOwners = cardState.checkedLocOwners;
    let tempCheckedLocCategories = cardState.checkedLocCategories;

    if (Object.keys(tempCheckedLocOwners).length === 1) {
      tempCheckedLocOwners[Object.keys(tempCheckedLocOwners)[0]] = false;
    }
    else {
      for (let i in tempCheckedLocOwners) {
        tempCheckedLocOwners[i] = false;
      }
    }

    for (let i in tempCheckedLocCategories) {
      tempCheckedLocCategories[i] = false;
    }

    cardState.checkedLocOwners = tempCheckedLocOwners;
    cardState.checkedLocCategories = tempCheckedLocCategories;

    if (num === 1) {
      for (let l in locChecked) {
        locChecked[l] = false;
      }
      locChecked[name] = event.target.checked;
      cardState.locationChecked = locChecked;
    }
    else {
      for (let l in locChecked2) {
        locChecked2[l] = false;
      }
      locChecked2[name] = event.target.checked;
      cardState.locationChecked2 = locChecked2;
    }

    this.setState({ ...this.state, cardState: cardState }, () => {
      this.props.onChange(cardState);
    });
  };

  handleClick = (event) => {
    if (closeCardBtn) return; // handleClick overlaps closeCard, if closeCard was clicked return here.
    this.setState({ menuOpen: !this.state.menuOpen, menuAnchor: event.currentTarget });
  };

  handleClickAway = () => {
    this.setState({ menuOpen: false, menuAnchor: null });
  };

  closeCard = name => () => {
    closeCardBtn = true;
    this.props.closeLocation(name);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    closeCardBtn = false; // reset closeCardBtn

    if (nextProps.noCard !== prevState.noCard) {
      if (nextProps.noCard) {
        let cardState = prevState.cardState;
        let locChecked = cardState.locationChecked;
        let locChecked2 = cardState.locationChecked2;
        let tempCheckedLocOwners = cardState.checkedLocOwners;
        let tempCheckedLocCategories = cardState.checkedLocCategories;

        for (let l in locChecked) {
          locChecked[l] = false;
        }
        for (let l in locChecked2) {
          locChecked2[l] = false;
        }
        for (let i in tempCheckedLocOwners) {
          tempCheckedLocOwners[i] = false;
        }
        for (let i in tempCheckedLocCategories) {
          tempCheckedLocCategories[i] = false;
        }
        cardState.locationChecked = locChecked;
        cardState.locationChecked2 = locChecked2;
        cardState.checkedLocOwners = tempCheckedLocOwners;
        cardState.checkedLocCategories = tempCheckedLocCategories;
        cardState.aggregate = true;
        cardState.aggregationType = 'average';
        cardState.name = 0;

        return {
          cardState: cardState,
          noCard: true,
        };
      }
      else {
        return {
          noCard: false,
        };
      }
    }

    if ((nextProps.initlocationCard !== prevState.cardState) && nextProps.initlocationCard.hasOwnProperty('locationChecked2')) {
      if (Object.keys(nextProps.initlocationCard).length > 0) {

        return {
          cardState: nextProps.initlocationCard,
        };
      }
    }

    return {};
  }

  render() {
    const { classes, locationsObject, name, showX } = this.props;
    const { cardState, menuAnchor, menuOpen, noCard } = this.state;
    const transpar = 0.6

    return (
      <Grid container className={classes.cardContainer} style={{ height: 46 }}>
        <Card className={classes.locationCard} onClick={this.handleClick} style={{ minWidth: 50, position: 'relative', height: '100%', backgroundColor: '#f5f5f5' }}>
          <Grid container direction='column' justifyContent='flex-start' alignItems='stretch' style={{}}>
            <IconButton style={{ position: 'absolute', right: -11, top: -16, display: (noCard || !showX) ? 'none' : '' }} onClick={this.closeCard(name)}>
              <CloseIcon style={{ width: '14px', color: '#1e1e1e' }} />
            </IconButton>

            <Typography style={{ height: 17, paddingRight: 18, paddingLeft: 5, fontSize: 'calc(8px + 0.2vw)', fontWeight: '600', backgroundColor: alpha((locationsObject['COMBCOLORS'][name] !== undefined) ? (locationsObject['COMBCOLORS'][name]) : (locationsObject['COMBCOLORS'][0]), transpar), display: (noCard) ? 'none' : '' }}>
              {Object.values(cardState.checkedLocCategories).indexOf(true) > -1
                ? Object.keys(cardState.checkedLocCategories)[Object.values(cardState.checkedLocCategories).indexOf(true)] + (cardState.aggregationType === 'average' ? ' (Avg)' : ' (Total)')
                : ''}

              {Object.values(cardState.checkedLocOwners).indexOf(true) > -1
                ? Object.keys(cardState.checkedLocOwners)[Object.values(cardState.checkedLocOwners).indexOf(true)] + (cardState.aggregationType === 'average' ? ' (Avg)' : ' (Total)')
                : ''}

              {(Object.values(cardState.checkedLocCategories).indexOf(true) === -1 && Object.values(cardState.checkedLocOwners).indexOf(true) === -1 && cardState.aggregate)
                ? cardState.aggregationType === 'average' ? 'Capture (%)' : 'Capture (%)'
                : ''}
            </Typography>

            <Grid container direction='column' justifyContent='space-around' alignItems='stretch' style={{ padding: 1, height: (cardState.aggregate) ? 29 : 46, paddingTop: 0, paddingBottom: 0 }}>
              <Grid item container direction='row' justifyContent='center' alignItems='stretch' className={classes.locationCardContents} style={{ padding: 0 }}>
                {locationsObject['locationList'].map((value, index) => {
                  return (
                    <Grid key={'l-g' + index}>
                      <Chip
                        key={'l-ch' + index}
                        size={(Object.values(cardState.checkedLocCategories).indexOf(true) === -1 && Object.values(cardState.checkedLocOwners).indexOf(true) === -1) ? 'small' : 'small'}
                        style={{ paddingBottom: 0, backgroundColor: alpha(locationsObject['colors'][index], transpar), display: (cardState.aggregate && !noCard && cardState.locationChecked[Object.keys(cardState.locationChecked)[index]]) ? '' : 'none', fontWeight: '400', fontSize: 'calc(7px + 0.2vw)' }}
                        label={locationsObject['locAbbreviation'][index]}
                        className={classes.chip}
                      />
                      <Chip
                        key={'d-ch' + index}
                        size={(Object.values(cardState.checkedLocCategories).indexOf(true) === -1 && Object.values(cardState.checkedLocOwners).indexOf(true) === -1) ? 'medium' : 'medium'}
                        style={{ paddingBottom: 0, display: index === Object.values(cardState.locationChecked).indexOf(true) ? 'inline' : 'none', fontWeight: '1000', fontSize: 'calc(16px + 0.2vw)', marginLeft: -8, marginRight: -6, backgroundColor: 'transparent' }}
                        label={'/'}
                        className={classes.chip}
                      />
                    </Grid>
                  )
                })}

                {locationsObject['locationList'].map((value, index) => {
                  return (
                    <Grid key={'r-g' + index}>
                      <Chip
                        key={'r-ch' + index}
                        size={(Object.values(cardState.checkedLocCategories).indexOf(true) === -1 && Object.values(cardState.checkedLocOwners).indexOf(true) === -1) ? 'small' : 'small'}
                        style={{ paddingBottom: 0, backgroundColor: alpha(locationsObject['colors'][index], transpar), display: (cardState.aggregate && !noCard && cardState.locationChecked2[Object.keys(cardState.locationChecked2)[index]]) ? '' : 'none', fontWeight: '400', fontSize: 'calc(7px + 0.2vw)' }}
                        label={locationsObject['locAbbreviation'][index]}
                        className={classes.chip}
                      />
                    </Grid>
                  )
                })}

                <Typography style={{ cursor: 'pointer', fontWeight: '600', color: '#4d6e77', paddingLeft: 7, paddingRight: 7, display: (Object.values(cardState.locationChecked).indexOf(true) === -1 || noCard) ? 'block' : 'none' }}>
                  Select Location
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Card>

        <Popover
          open={menuOpen}
          anchorEl={menuAnchor}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <ClickAwayListener onClickAway={this.handleClickAway}>
            <Paper className={classes.locMenu} style={{ width: window.innerWidth > 767 ? '100%' : '72vw' }}>
              <Grid container alignItems='flex-start' direction='row'>
                <Grid item>
                  <FormGroup style={{ paddingTop: 16, paddingLeft: 30, paddingRight: 10, paddingBottom: 15 }}>
                    {locationsObject['locationList'].map((value, index) => {
                      return (
                        <FormControlLabel
                          key={'l-fc' + index}
                          className={classes.location}
                          control={
                            <Radio
                              style={{ marginTop: -4, color: locationsObject['colors'][index] }}
                              checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[index]]}
                              onChange={this.changeLoc(1, Object.keys(cardState.locationChecked)[index])}
                              value={Object.keys(cardState.locationChecked)[index]}
                            />
                          }
                          label={<Typography color='textPrimary'>{Object.keys(cardState.locationChecked)[index]}</Typography>}
                        />
                      )
                    })}
                  </FormGroup>
                </Grid>
                <Divider orientation='vertical' flexItem />
                <Grid item>
                  <FormGroup style={{ paddingTop: 16, paddingLeft: 30, paddingRight: 10, paddingBottom: 15 }}>
                    {locationsObject['locationList'].map((value, index) => {
                      return (
                        <FormControlLabel
                          key={'r-fc' + index}
                          className={classes.location}
                          control={
                            <Radio
                              style={{ marginTop: -4, color: locationsObject['colors'][index] }}
                              checked={cardState.locationChecked2[Object.keys(cardState.locationChecked2)[index]]}
                              onChange={this.changeLoc(2, Object.keys(cardState.locationChecked2)[index])}
                              value={Object.keys(cardState.locationChecked2)[index]}
                            />
                          }
                          label={<Typography color='textPrimary'>{Object.keys(cardState.locationChecked2)[index]}</Typography>}
                        />
                      )
                    })}
                  </FormGroup>
                </Grid>
              </Grid>
            </Paper>
          </ClickAwayListener>
        </Popover>
      </Grid>
    )
  }
}

export default withRouter(withStyles(styles)(LocationConvCard));
