import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { Area, XAxis, YAxis, ComposedChart, ResponsiveContainer } from 'recharts';

import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { reduceArray, getColor } from '../../utils/helpers';
import { colorsMen, colorsWomen, colorsChildren, colorsAdults, colorsSeniors, colorsBikes, colorsBuses, colorsCars, colorsTrucks } from '../../utils/colors';

const styles = theme => ({});


class TinyByTimeOfDay extends PureComponent {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    const { age, gender, mode, multDates, numPlots, openTab, vehicles, series } = this.props;

    let [dataPoints, secondDateCardIndex] = [0, 0];
    try { secondDateCardIndex = series.length - 1; }
    catch (e) {}

    let chartArray = reduceArray(series, numPlots, multDates, secondDateCardIndex);
    for (let i in chartArray) {
      chartArray[i].colorIndex = i; // track locations original index before sorting so combination color matches other charts.
    }

    let chartArrayNoSort = JSON.parse(JSON.stringify(chartArray)); // used w/ getColors function

    if (openTab === 0 && (numPlots > 1 || !multDates)) {
      chartArray.sort((a, b) => {
        if (b.totalCounts && b.totalCounts.count && a.totalCounts && a.totalCounts.count) return (b.totalCounts.count - a.totalCounts.count);
      });
    }

    try {
      for (let ser in chartArray) {
        if (chartArray[ser].data.length > dataPoints) {
          dataPoints = chartArray[ser].data.length;
        }
      }
    } catch (err) {}

    return (
      <Grid container item xs justifyContent='space-around' alignItems='center' style={{ height: '10vh', paddingTop: '2vh', paddingBottom: '3vh', paddingLeft: '2vw', paddingRight: '2vw', textAlign: 'center' }}>
        <Typography style={{ fontSize: 'calc(2px + 1.0vw)', cursor: 'pointer' }}>
          {(this.props.occupancy)
            ? <>Occupancy by<br />Time of Day</>
            : <>{mode} by<br />Time of Day</>
          }
        </Typography>
        {dataPoints === 0 &&
          <Grid container justifyContent='space-around' alignItems='center'>
            <CircularProgress size={'calc(1vw + 1vh)'} style={{ marginTop: 'calc(1vw + 2vh)' }} />
          </Grid>
        }
        {dataPoints > 0 &&
          <ResponsiveContainer width={'100%'} height={'100%'} minHeight={0}>
            <ComposedChart style={{ cursor: 'pointer' }} margin={{ top: 10, right: 2, left: 2, bottom: 0 }}>
              <XAxis type='number' domain={['dataMin', 'dataMax']} scale='time' dataKey='_id.time' allowDuplicatedCategory={false} hide={true} />
              <YAxis hide={true} domain={['auto', dataMax => Math.ceil((dataMax * 1.2) / parseInt('1'.padEnd(Math.log(dataMax) * Math.LOG10E + 1 | 0, '0'))) * parseInt('1'.padEnd(Math.log(dataMax) * Math.LOG10E + 1 | 0, '0'))]} />
              {openTab === 0 &&
                chartArray.map((s, idx) => (
                  [
                    <Area
                      isAnimationActive={true}
                      type='monotone'
                      data={s.data}
                      dataKey='count'
                      stroke={getColor(s.locationLabels, s.colorIndex, this.props.locationsObject, chartArrayNoSort)}
                      fill={getColor(s.locationLabels, s.colorIndex, this.props.locationsObject, chartArrayNoSort)}
                      strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''}
                      name={'Traffic' + (multDates ? ': ' : '') + (s.locationLabels) + (multDates ? (' { ' + s.dateLabel + ' }') : '')}
                      hide={!this.props.count || s.data.length === 0} />,
                  ]
                ))
              }
              {openTab === 1 && !vehicles &&
                chartArray.map((s, idx) => (
                  [
                    <Area
                      isAnimationActive={true}
                      type='monotone'
                      data={s.data}
                      dataKey='women'
                      stroke={colorsWomen[idx]}
                      fill={colorsWomen[idx]}
                      strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''}
                      name={'Women' + (multDates ? ': ' : '') + (s.locationLabels) + (multDates ? (' { ' + s.dateLabel + ' }') : '')}
                      hide={!gender || s.data.length === 0} />,
                    <Area
                      isAnimationActive={true}
                      type='monotone'
                      data={s.data}
                      dataKey='men'
                      stroke={colorsMen[idx]}
                      fill={colorsMen[idx]}
                      strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''}
                      name={'Men' + (multDates ? ': ' : '') + (s.locationLabels) + (multDates ? (' { ' + s.dateLabel + ' }') : '')}
                      hide={!gender || s.data.length === 0} />,
                  ]
                ))
              }
              {openTab === 1 && vehicles &&
                chartArray.map((s, idx) => (
                  [
                    <Area
                      isAnimationActive={true}
                      type='monotone'
                      data={s.data}
                      dataKey='bikes'
                      stroke={colorsBikes[idx]}
                      fill={colorsBikes[idx]}
                      strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''}
                      name={'Bikes' + (multDates ? ': ' : '') + (s.locationLabels) + (multDates ? (' { ' + s.dateLabel + ' }') : '')}
                      hide={!gender || s.data.length === 0} />,
                    <Area
                      isAnimationActive={true}
                      type='monotone'
                      data={s.data}
                      dataKey='buses'
                      stroke={colorsBuses[idx]}
                      fill={colorsBuses[idx]}
                      strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''}
                      name={'Buses' + (multDates ? ': ' : '') + (s.locationLabels) + (multDates ? (' { ' + s.dateLabel + ' }') : '')}
                      hide={!gender || s.data.length === 0} />,
                    <Area
                      isAnimationActive={true}
                      type='monotone'
                      data={s.data}
                      dataKey='cars'
                      stroke={colorsCars[idx]}
                      fill={colorsCars[idx]}
                      strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''}
                      name={'Cars' + (multDates ? ': ' : '') + (s.locationLabels) + (multDates ? (' { ' + s.dateLabel + ' }') : '')}
                      hide={!gender || s.data.length === 0} />,
                    <Area
                      isAnimationActive={true}
                      type='monotone'
                      data={s.data}
                      dataKey='trucks'
                      stroke={colorsTrucks[idx]}
                      fill={colorsTrucks[idx]}
                      strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''}
                      name={'Trucks' + (multDates ? ': ' : '') + (s.locationLabels) + (multDates ? (' { ' + s.dateLabel + ' }') : '')}
                      hide={!gender || s.data.length === 0} />,
                  ]
                ))
              }
              {openTab === 2 && !vehicles &&
                chartArray.map((s, idx) => (
                  [
                    <Area
                      isAnimationActive={true}
                      type='monotone'
                      data={s.data}
                      dataKey='adults'
                      stroke={colorsAdults[idx]}
                      fill={colorsAdults[idx]}
                      strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''}
                      name={'Adults' + (multDates ? ': ' : '') + (s.locationLabels) + (multDates ? (' { ' + s.dateLabel + ' }') : '')}
                      hide={!age || s.data.length === 0} />,
                    <Area
                      isAnimationActive={true}
                      type='monotone'
                      data={s.data}
                      dataKey='children'
                      stroke={colorsChildren[idx]}
                      fill={colorsChildren[idx]}
                      strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''}
                      name={'Children' + (multDates ? ': ' : '') + (s.locationLabels) + (multDates ? (' { ' + s.dateLabel + ' }') : '')}
                      hide={!age || s.data.length === 0} />,
                    <Area
                      isAnimationActive={true}
                      type='monotone'
                      data={s.data}
                      dataKey='seniors'
                      stroke={colorsSeniors[idx]}
                      fill={colorsSeniors[idx]}
                      strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''}
                      name={'Seniors' + (multDates ? ': ' : '') + (s.locationLabels) + (multDates ? (' { ' + s.dateLabel + ' }') : '')}
                      hide={!age || s.data.length === 0} />
                  ]
                ))
              }
            </ComposedChart>
          </ResponsiveContainer>
        }
      </Grid>
    )
  }
}

export default withRouter(withStyles(styles)(TinyByTimeOfDay));
