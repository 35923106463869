import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

import { Grid, ImageList, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const CELL_PAD = 2.7;
const CELL_HEAD_PAD = 0;
const HEADER_WEIGHT = 600;
const HEADER_FONT_SIZE = 14;
const heatmapColors = ['#ffffe5','#f7fcb9','#d9f0a3','#addd8e','#78c679','#41ab5d','#238443','#006837'];

const styles = theme => ({
  gridList: {
    width: '100%',
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'hidden',
  },
  tablecellStyle: {
    fontSize: 11,
    fontFamily: 'Nunito Sans',
    fontWeight: 700,
    paddingLeft: 7,
    paddingTop: 2,
    // height: 20.5,
    // height: '30%',
    // paddingRight: CELL_PAD,
  },
  tableArea: {
    paddingLeft: 0,
    paddingRight: 0,
  }
});

class HourlyByDayTable extends PureComponent {

  cellStyle = (type, value, maxCount) => {
    maxCount = (maxCount / heatmapColors.length) * heatmapColors.length + heatmapColors.length;
    let colorIndex = Math.floor(heatmapColors.length * (value / maxCount));

    if (type === 'counts') {
      return heatmapColors[colorIndex];
    }
  }

  render() {
    const { classes, remDays, series } = this.props;

    const numRows = series[0].data.filter(obj => obj.max > 0).length || 11; // if numRows is 0 default to 11

    let dataPoints = 0;
    try {
      for (let ser in series) {
        if (series[ser].data.length > dataPoints) {
          dataPoints = series[ser].data.length;
        }
      }
    } catch (err) { }

    return (
      <Grid style={{ width: '100%', height: '100%', backgroundColor: 'white' }}>
        <Grid item container direction='column' justifyContent='flex-start' alignItems='stretch' style={{ position: 'relative', paddingLeft: 0, paddingRight: 0, width: '100%', backgroundColor: 'white' }}>
          {dataPoints > 0 &&
            <ImageList className={classes.gridList} cols={1} style={{ backgroundColor: 'white', marginBottom: 0 }}>

              {series.map((s, idx) =>
                [
                  <Grid container justifyContent='space-around' style={{ marginTop: 0, marginBottom: 0, height: '100%', backgroundColor: '', display: s.data.length > 0 ? '' : 'none' }}>
                    <Grid container spacing={0} style={{ width: '100%', height: '100%' }} className={classes.tableArea} direction='row' justifyContent='flex-start' alignItems='center'>
                      <Grid item xs style={{ margin: 0 }}>
                        {/* <TableContainer component={Paper} style={ {backgroundColor: 'yellow'}}> */}
                        <Table size='small' aria-label='simple table'>
                          <TableHead>
                            <TableRow>
                              <TableCell padding={'none'} />
                              <TableCell padding={'none'} style={{ display: remDays.includes(0) ? 'none' : '', fontSize: HEADER_FONT_SIZE, fontFamily: 'Nunito Sans', fontWeight: HEADER_WEIGHT, padding: CELL_HEAD_PAD }} align='left'>{'Mon'}</TableCell>
                              <TableCell padding={'none'} style={{ display: remDays.includes(1) ? 'none' : '', fontSize: HEADER_FONT_SIZE, fontFamily: 'Nunito Sans', fontWeight: HEADER_WEIGHT, padding: CELL_HEAD_PAD }} align='left'>{'Tue'}</TableCell>
                              <TableCell padding={'none'} style={{ display: remDays.includes(2) ? 'none' : '', fontSize: HEADER_FONT_SIZE, fontFamily: 'Nunito Sans', fontWeight: HEADER_WEIGHT, padding: CELL_HEAD_PAD }} align='left'>{'Wed'}</TableCell>
                              <TableCell padding={'none'} style={{ display: remDays.includes(3) ? 'none' : '', fontSize: HEADER_FONT_SIZE, fontFamily: 'Nunito Sans', fontWeight: HEADER_WEIGHT, padding: CELL_HEAD_PAD }} align='left'>{'Thu'}</TableCell>
                              <TableCell padding={'none'} style={{ display: remDays.includes(4) ? 'none' : '', fontSize: HEADER_FONT_SIZE, fontFamily: 'Nunito Sans', fontWeight: HEADER_WEIGHT, padding: CELL_HEAD_PAD }} align='left'>{'Fri'}</TableCell>
                              <TableCell padding={'none'} style={{ display: remDays.includes(5) ? 'none' : '', fontSize: HEADER_FONT_SIZE, fontFamily: 'Nunito Sans', fontWeight: HEADER_WEIGHT, padding: CELL_HEAD_PAD }} align='left'>{'Sat'}</TableCell>
                              <TableCell padding={'none'} style={{ display: remDays.includes(6) ? 'none' : '', fontSize: HEADER_FONT_SIZE, fontFamily: 'Nunito Sans', fontWeight: HEADER_WEIGHT, padding: CELL_HEAD_PAD }} align='left'>{'Sun'}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {s.data.map(row =>
                              <TableRow key={row.Time} style={{ display: row.max > 0 ? '' : 'none' }}>
                                <TableCell padding={'none'} component='th' scope='row' style={{ padding: CELL_PAD, fontWeight: 600, fontSize: 12 + parseInt((24 - numRows) / 8), fontFamily: 'Nunito Sans' }}>
                                  {row.Time}
                                </TableCell>
                                <TableCell padding={'none'} className={classes.tablecellStyle} style={{ fontSize: 11 + parseInt((24 - numRows) / 6), height: 495 / numRows + (24 - numRows) / 5, display: remDays.includes(0) ? 'none' : '', width: parseInt(73.5 / (7 - remDays.length) - parseInt(remDays.length / 3)).toString() + '%', backgroundColor: this.cellStyle('counts', row.Monday, s.maxCount) }} align='left'>{row.Monday}</TableCell>
                                <TableCell padding={'none'} className={classes.tablecellStyle} style={{ fontSize: 11 + parseInt((24 - numRows) / 6), height: 495 / numRows + (24 - numRows) / 5, display: remDays.includes(1) ? 'none' : '', width: parseInt(73.5 / (7 - remDays.length) - parseInt(remDays.length / 3)).toString() + '%', backgroundColor: this.cellStyle('counts', row.Tuesday, s.maxCount) }} align='left'>{row.Tuesday}</TableCell>
                                <TableCell padding={'none'} className={classes.tablecellStyle} style={{ fontSize: 11 + parseInt((24 - numRows) / 6), height: 495 / numRows + (24 - numRows) / 5, display: remDays.includes(2) ? 'none' : '', width: parseInt(73.5 / (7 - remDays.length) - parseInt(remDays.length / 3)).toString() + '%', backgroundColor: this.cellStyle('counts', row.Wednesday, s.maxCount) }} align='left'>{row.Wednesday}</TableCell>
                                <TableCell padding={'none'} className={classes.tablecellStyle} style={{ fontSize: 11 + parseInt((24 - numRows) / 6), height: 495 / numRows + (24 - numRows) / 5, display: remDays.includes(3) ? 'none' : '', width: parseInt(73.5 / (7 - remDays.length) - parseInt(remDays.length / 3)).toString() + '%', backgroundColor: this.cellStyle('counts', row.Thursday, s.maxCount) }} align='left'>{row.Thursday}</TableCell>
                                <TableCell padding={'none'} className={classes.tablecellStyle} style={{ fontSize: 11 + parseInt((24 - numRows) / 6), height: 495 / numRows + (24 - numRows) / 5, display: remDays.includes(4) ? 'none' : '', width: parseInt(73.5 / (7 - remDays.length) - parseInt(remDays.length / 3)).toString() + '%', backgroundColor: this.cellStyle('counts', row.Friday, s.maxCount) }} align='left'>{row.Friday}</TableCell>
                                <TableCell padding={'none'} className={classes.tablecellStyle} style={{ fontSize: 11 + parseInt((24 - numRows) / 6), height: 495 / numRows + (24 - numRows) / 5, display: remDays.includes(5) ? 'none' : '', width: parseInt(73.5 / (7 - remDays.length) - parseInt(remDays.length / 3)).toString() + '%', backgroundColor: this.cellStyle('counts', row.Saturday, s.maxCount) }} align='left'>{row.Saturday}</TableCell>
                                <TableCell padding={'none'} className={classes.tablecellStyle} style={{ fontSize: 11 + parseInt((24 - numRows) / 6), height: 495 / numRows + (24 - numRows) / 5, display: remDays.includes(6) ? 'none' : '', width: parseInt(73.5 / (7 - remDays.length) - parseInt(remDays.length / 3)).toString() + '%', paddingLeft: 7, backgroundColor: this.cellStyle('counts', row.Sunday, s.maxCount) }} align='left'>{row.Sunday}</TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                        {/* </TableContainer> */}
                      </Grid>
                    </Grid>
                  </Grid>
                ])
              }
            </ImageList>
          }
        </Grid>
      </Grid>
    )
  }
}

export default withRouter(withStyles(styles)(HourlyByDayTable));
