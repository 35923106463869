import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import SwipeableViews from 'react-swipeable-views';

import config from '../../utils/config';
import { getData } from '../../utils/utils';
import { clientData } from '../../utils/clients';
import { getLocations, getColor } from '../../utils/helpers';
// import { getDailyAvgData, getBusiestHour, getVehicleMaxDays } from '../../utils/avgHeaderFuncs';
import { getDailyAvgData, getVehicleMaxDays } from '../../utils/avgHeaderFuncs';

import Navbar from '../layout/Navbar';
import PresetDateRangePicker from './DatePicker';

import LocationCard from './LocationCard';
import LocationCard2 from './LocationCard2';
import LocationCard3 from './LocationCard3';

import LocationConvCard from './LocationConvCard';

import LocationCompare from './LocationCompare';
import LocationCompare2 from './LocationCompare2';
import LocationCompare3 from './LocationCompare3';

import LocationMultiCompare from './LocationMultiCompare';
import LocationCategoryCompare from './LocationCategoryCompare';
import LocationConvCompare from './LocationConvCompare';

import TinyByDate from './TinyByDateChart.component';
import TinyByDayOfWeek from './TinyByDayOfWeekChart.component';
import TinyByTimeOfDay from './TinyByTimeOfDayChart.component';

import AveragesHeader from './AveragesHeader.component';
import ByDate from './ByDateChart.component';
import ByTimeChart from './ByTimeChart.component'
import ByDayOfWeek from './ByDayOfWeekChart.component';
// import MonthlyTrendChart from './MonthlyTrendChart.component';
import TimeByDay from './TimeByDayTable.component';

import CreateReport from './CreateReport';
// import CreateReport2 from './CreateReport2';
import { apiGetPdfReport } from '../../utils/reportHelpers';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import MinusCircleIcon from '@mui/icons-material/RemoveCircle';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Add as AddIcon,
  DateRangeRounded as CalIcon,
  SyncAltRounded as CompareIcon,
  LayersClearOutlined as LayersClearRoundedIcon,
  PlaceRounded as LocIcon,
  Save as SaveIcon
} from '@material-ui/icons';
import {
  AppBar, Box, Button, ButtonGroup, Card, CircularProgress, Collapse, Drawer, Grid, FormControlLabel,
  IconButton, Paper, Snackbar, SnackbarContent, Tab, Tabs, Toolbar, Tooltip, Typography
} from '@material-ui/core';
import { alpha, styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';

import isSameDay from 'react-dates/src/utils/isSameDay';
import VideoDashboard from '../video/VideoDashboard';

const CELLWIDTH = 600;
const VIEW_SPACE = '0.5vh'
const API_URL = config.api.url;

const styles = theme => ({
  filterPanel: {
    // background: '#D3DBDE', // theme.palette.grey['400'],//'#c5cae9', //theme.palette.grey['300'],
    // background: theme.palette.primary.main,
    zIndex: 2,
    // width:'100%',
    // overflowX: 'hidden',
  },
  compareStyle: {
    marginLeft:'calc(4px + 0.1vw)',
    marginRight:'calc(4px + 0.1vw)',
    width:'calc(10px + 0.5vw)'
  },
  mainIcon: {
    marginRight:"calc(3px + 0.5vw)",
    // marginLeft:"calc(3px + 0.5vw)",
  },
  bkgrnd: {
    background: theme.palette.grey['100'], //'#fff8e1',// theme.palette.grey['100'], // '#eceff1'
    zIndex: 3,
  },
  tabStyle: {
    textTransform: 'none',
    fontSize: 14,//"calc(10px + 0.25vw)",
    fontWeight: 700,
    color: '#02313D',
    opacity: 0.5,
    '&:hover': {
      // color: '#02313D',
      opacity: 0,
    },
  },
  tabStyleRep: {
    textTransform: 'none',
    fontSize: 14,//"calc(10px + 0.25vw)",
    fontWeight: 700,
    color: '#B3C0C4',
    opacity: 0.5,
    '&:hover': {
      // color: '#02313D',
      opacity: 0,
    },
  },
  fullCard: {
    // marginTop:'1vw',
    // marginBottom:'1vw',
    // display:'grid',
    // padding: '1vh 1vw 1vh 1vw',
    // width:'80vw',
    // height:'100%',
    textAlign:'center'
  },
  tinyCard: {
    // marginTop:'1vh',
    // marginBottom:'1vh',
    // display:'grid',
    // padding:'1vw',
    // width:'15vw',
    // height:'31.3%',
    // height:'33%',
    // height:'31.3%',
    textAlign:'center',
    cursor:'pointer'
  },
  tagline: {
    textAlign: 'center',
    fontSize: 'calc(4px + 0.4vw)',
    display: 'block',
    marginBottom: '-4px',
    color: 'primary'
  },
  tabBox: {
    background: theme.palette.grey['300'], //'#fff9c4', //theme.palette.grey['400'],
    zIndex: 3,
  },
  tabView: {
    // height:'calc(30vh + 20vw)',
    //marginTop: 'calc(12px + 1.5vw)', //'calc(20px + 1vh + 1vw)',
    marginBottom:'300px',
    // height:'70vh',
    zIndex: 5,
  },
  tabsS: {
    minHeight: 'calc(15px + 2.5vh)',
    backgroundColor: '#B3C0C4',
  },
  tabsSrep: {
    minHeight: 'calc(15px + 2.5vh)',
    backgroundColor: '#7e929a',
    zIndex: 1,
  },
  tabS: {
    minHeight:'calc(15px + 2.5vh)',
    "&:hover": {
      backgroundColor: "#02313D",
      color:'#B3C0C4',
      opacity:0.3,
    },
  },
  tabSrep: {
    minHeight:'calc(15px + 2.5vh)',
    color:'#EDF5F8',
    "&:hover": {
      backgroundColor:  "#B3C0C4",
      color: '#02313D',
      opacity:0.9,
    },
  },
  tabSelected: {
    backgroundColor: "#D3DBDE", //"#7A9D96"
    opacity:1,
  },
  tabSelectedRep: {
    backgroundColor: "#667e87", //"#7A9D96"

    opacity:1,
  },
  filterItem: {
    //paddingBottom:10,
    marginLeft:'-8px',
    // marginTop:10,
  },
  changeButton: {
    transition: "background-color 0.5s ease",
  },
  gridList: {
    width: '100%',
    // height: 100,
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    // transform: 'translateZ(0)',
    
  },
  countTitle: {
    fontSize: '5px',
  },
  report: {
    // width: '84vh',
    // height: '88vh',
    position: 'absolute',
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    backgroundColor: theme.palette.grey['600'],
    // textAlign: 'center',
    maxWidth: '835px',
    height: '87%'
    // overflowY: 'scroll',
  },
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    width:300,
    backgroundColor:'red'
  },
  image: {
    width:100
  },
  track: {
    // borderRadius: 26 / 2,
    // border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: '#707070',
    opacity: 0.9,
    // transition: theme.transitions.create(['background-color', 'border']),
  },
  reportDrawerS: {
    background: '#424242',
    // height: '80vh',
    top: '20vh',
    zIndex: 0,
  }
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Grid
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Grid>{children}</Grid>
        </Box>
      )}
    </Grid>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function defaultElement(dateStr, timeStamp, vehicle) {
  let returnObj = (!vehicle)
    ? { '_id': { 'date': dateStr, 'timeStamp': timeStamp.valueOf() / 1000 }, 'men': 0, 'women': 0, 'children': 0, 'adults': 0, 'seniors': 0, 'count': 0, 'holiday': null, 'tavg': null, 'tmax': null, 'tmin': null, 'prcp': 0, 'snow': 0 }
    : { '_id': { 'date': dateStr, 'timeStamp': timeStamp.valueOf() / 1000 }, 'bikes': 0, 'buses': 0, 'cars': 0, 'motorbikes': 0, 'trucks': 0, 'count': 0, 'events': {}, 'holiday': null, 'tavg': null, 'tmax': null, 'tmin': null, 'prcp': 0, 'snow': 0 };

  return returnObj;
}

function formatArray(jsonDataObj, interval = '', startingDate, endingDate, qLocations = [], locTimes, vehicles) {
  var returnArray = [];
  var getNewArray = function(srcArray, idKey) {
    let newArray = [];

    for (let i = 0, len = srcArray.length; i < len; i++) {
      newArray[srcArray[i]._id[idKey]] = srcArray[i];
    }

    return newArray;
  }

  if (interval === 'day') {
    let arrayIndex = getNewArray(jsonDataObj, 'date'); // returns array w/ dates as keys
    let start = moment(startingDate, 'YYYY-MM-DD HH:mm:sssZ').tz('America/New_York').startOf('day'); // (timestamp adjusted to EST)
    let end = moment(endingDate, 'YYYY-MM-DD HH:mm:sssZ').tz('America/New_York').startOf('day');

    while (start <= end) {
      let dateStr = start.toISOString();
      let newEle = (arrayIndex[dateStr]) ? arrayIndex[dateStr] : defaultElement(dateStr, start, vehicles);
      start.add(1, 'days');

      returnArray.push(newEle);
    }
  }
  else if (interval === 'week') {
    let arrayIndex = getNewArray(jsonDataObj, 'date'); // returns array w/ dates as keys
    let start = moment(startingDate, 'YYYY-MM-DD HH:mm:sssZ').tz('America/New_York').startOf('isoWeek'); // 1st day of week (timestamp adjusted for DayLightSavings)
    let end = moment(endingDate, 'YYYY-MM-DD HH:mm:sssZ').tz('America/New_York').startOf('isoWeek');

    while (start <= end) {
      let dateStr = start.toISOString();
      let newEle = (arrayIndex[dateStr]) ? arrayIndex[dateStr] : defaultElement(dateStr, start, vehicles);
      start.add(1, 'weeks');

      returnArray.push(newEle);
    }
  }
  else if (interval === 'month') {
    let arrayIndex = getNewArray(jsonDataObj, 'date'); // returns array w/ dates as keys
    let start = moment(startingDate, 'YYYY-MM-DD HH:mm:sssZ').tz('America/New_York').startOf('month'); // 1st day of mo (timestamp adjusted for DayLightSavings)
    let end = moment(endingDate, 'YYYY-MM-DD HH:mm:sssZ').tz('America/New_York').startOf('month');

    while (start <= end) {
      let dateStr = start.toISOString();
      let newEle = (arrayIndex[dateStr]) ? arrayIndex[dateStr] : defaultElement(dateStr, start, vehicles);
      start.add(1, 'months');

      returnArray.push(newEle);
    }
  }
  else if (interval === 'year') {
    let arrayIndex = getNewArray(jsonDataObj, 'date'); // returns array w/ dates as keys
    let start = moment(startingDate, 'YYYY-MM-DD HH:mm:sssZ').tz('America/New_York').startOf('year'); // 1st day of year (timestamp adjusted for DayLightSavings)
    let end = moment(endingDate, 'YYYY-MM-DD HH:mm:sssZ').tz('America/New_York').startOf('year');

    while (start <= end) {
      let dateStr = start.toISOString();
      let newEle = (arrayIndex[dateStr]) ? arrayIndex[dateStr] : defaultElement(dateStr, start, vehicles);
      start.add(1, 'years');

      returnArray.push(newEle);
    }
  }
  else if (interval === 'time') {
    const arrayIndex = getNewArray(jsonDataObj, 'time'); // returns array w/ times as keys
    const [startArray, endArray] = [[], []];

    if (qLocations.length > 0) {
      qLocations.forEach(loc => {
        startArray.push(moment(locTimes.start[loc])); // if location is missing start/end time a default is used in helpers getLocations() 
        endArray.push(moment(locTimes.end[loc]));
      });
    }

    let start = (moment.min(startArray).isValid()) ? moment.min(startArray).tz('America/New_York') : moment('2019-01-01T14:00:00.000Z').tz('America/New_York'); // 9am EST (adjusted for dst);
    let end = (moment.max(endArray).isValid()) ? moment.max(endArray).tz('America/New_York') : moment('2019-01-02T03:00:00.000Z').tz('America/New_York'); // 10pm EST

    while (start < end) {
      let timeStr = start.toISOString();
      let newEle = (arrayIndex[timeStr]) ? arrayIndex[timeStr] : (!vehicles)
        ? { '_id': { 'time': timeStr }, 'men': 0, 'women': 0, 'children': 0, 'adults': 0, 'seniors': 0, 'count': 0 }
        : { '_id': { 'time': timeStr }, 'bikes': 0, 'buses': 0, 'cars': 0, 'motorbikes': 0, 'trucks': 0, 'count': 0, 'events': {} };
      start.add(15, 'minutes');

      returnArray.push(newEle);
    }
  }

  return returnArray;
}

const isSameWeather = (client) => {
  const checkClient = (client) ? client.toLowerCase() : '';
  const sameWeather = (clientData[checkClient]?.sameWeather) ? true : false;

  return sameWeather;
}

const getClientDate = (client, type, dateRange) => {
  const checkClient = (client) ? client.toLowerCase() : '';

  if (type === 'start') {
    const start = clientData[checkClient]?.calStart.clone() ?? moment().tz('America/New_York').subtract(30, 'days').startOf('day');

    return (start.isAfter(dateRange.end, 'day')) ? dateRange.end.clone().subtract(30, 'days').startOf('day') : start;
  }
  else if (type === 'end') {
    const end = clientData[checkClient]?.calEnd.clone() ?? moment().tz('America/New_York').subtract(1, 'day').endOf('day');

    return (end.isAfter(dateRange.end, 'day')) ? dateRange.end.clone().endOf('day') : end;
  }
}

const ckDataEnd = (sDate, dateUnit, timeUnit, dataEnd) => {
  let returnObj = {};

  // check if report startDate is After or Same as data end date.
  if (sDate.isAfter(dataEnd, 'day') || sDate.isSame(dataEnd, 'day')) {
    if (dataEnd.isoWeekday() === 1) {
      const newStart = dataEnd.clone().subtract(1, timeUnit).startOf(dateUnit);
      const newEnd = dataEnd.clone().subtract(1, timeUnit).endOf(dateUnit);

      returnObj = { 'start': newStart, 'end': newEnd };
    }
    else {
      const newStart = dataEnd.clone().startOf(dateUnit);
      const newEnd = dataEnd.clone().endOf(dateUnit);

      returnObj = { 'start': newStart, 'end': newEnd };
    }
  }
  else {
    return false;
  }

  return returnObj;
}

const ckDataStart = (eDate, dateUnit, timeUnit, dataStart) => {
  let returnObj = {};

  // check if report endDate is Before or Same as data start date.
  if (eDate.isBefore(dataStart, 'day') || eDate.isSame(dataStart, 'day')) {
    if (dataStart.isoWeekday() === 7) {
      const newStart = dataStart.clone().add(1, timeUnit).startOf(dateUnit);
      const newEnd = dataStart.clone().add(1, timeUnit).endOf(dateUnit);

      returnObj = { 'start': newStart, 'end': newEnd };
    }
    else {
      const newStart = dataStart.clone().startOf(dateUnit);
      const newEnd = dataStart.clone().endOf(dateUnit);

      returnObj = { 'start': newStart, 'end': newEnd };
    }
  }
  else {
    return false;
  }

  return returnObj;
}

const getReportDates = (eDate, reportRange, dataDateRange) => {
  let returnObj = {};

  const dateUnit = (reportRange === 1) ? 'isoWeek' : 'month';
  const timeUnit = (reportRange === 1) ? 'week' : 'month';

  const curEndOfRange = moment().endOf(dateUnit).tz('America/New_York');
  const endOfRange = moment(eDate).endOf(dateUnit);
  const isBefore = endOfRange.isBefore(curEndOfRange, 'day'); // check if endDate is current isoWeek (false if Sunday)
  const isSame = moment(eDate).isSame(curEndOfRange, 'day'); // check if same day as end of isoWeek (Sunday)

  if (isBefore || isSame) {
    const newStart = endOfRange.clone().startOf(dateUnit);
    const newEnd = endOfRange.clone();

    returnObj = { 'start': newStart, 'end': newEnd };
  }
  else {
    const newStart = endOfRange.clone().subtract(1, timeUnit).startOf(dateUnit);
    const newEnd = endOfRange.clone().subtract(1, timeUnit).endOf(dateUnit);

    returnObj = { 'start': newStart, 'end': newEnd };
  }

  const checkDataStart = ckDataStart(returnObj.end, dateUnit, timeUnit, dataDateRange.start);
  if (checkDataStart) {
    returnObj = { 'start': checkDataStart.start, 'end': checkDataStart.end };
  }

  // if endDate isSame as current date, location doesn't have an endDate
  if (!(moment().endOf('day').tz('America/New_York').isSame(dataDateRange.end, 'day'))) {
    const checkDataEnd = ckDataEnd(returnObj.start, dateUnit, timeUnit, dataDateRange.end);
    if (checkDataEnd) {
      returnObj = { 'start': checkDataEnd.start, 'end': checkDataEnd.end };
    }
  }

  return returnObj;
}

const getDateRange = (openLocs, sDates, eDates) => {
  if (typeof openLocs !== 'undefined' && Object.keys(openLocs).length !== 0) {
    let [startArray, endArray] = [[], []];
    let locsArray = Object.values(openLocs).flat(); // ['Woodbury Common', 'Wrentham Village', 'NYC - Empire']

    // if user selects a retail type or landlord w/ no locations, openLocs obj will contain an empty array { 0: [] }, in this scenario return default date range.
    if (locsArray.length > 0) {
      locsArray.forEach(loc => {
        startArray.push(moment(sDates[loc]));
        endArray.push(moment(eDates[loc]));
      });

      const startDate = (moment.min(startArray).isValid()) ? moment.min(startArray).tz('America/New_York') : moment('01/16/2018', 'MM/DD/YYYY').tz('America/New_York');
      const endDate = (moment.max(endArray).isValid()) ? moment.max(endArray).tz('America/New_York') : moment().tz('America/New_York');

      return { 'start': startDate.startOf('day'), 'end': endDate.endOf('day') };
    }
  }

  const startArray = Object.values(sDates).map((value) => { return moment(value); });
  const startDate = (moment.min(startArray).isValid()) ? moment.min(startArray).tz('America/New_York') : moment('01/16/2018', 'MM/DD/YYYY').tz('America/New_York');

  return { 'start': startDate.startOf('day'), 'end': moment().endOf('day').tz('America/New_York') }; // start: earliest start date or 1/16/18 and end: current date
}

const getDatePresets = (dateRangeObj) => {
  const startDate = (moment.isMoment(dateRangeObj.start) && dateRangeObj.start.isValid()) ? dateRangeObj.start : moment('01/16/2018').tz('America/New_York');
  const endDate = (moment.isMoment(dateRangeObj.end) && dateRangeObj.end.isValid()) ? dateRangeObj.end : moment().endOf('day').tz('America/New_York');

  const today = moment().startOf('day').tz('America/New_York');
  const yesterday = today.clone().subtract(1, 'day').endOf('day'); // don't need tz again after clone - set to endOf day, always used as end value
  const startOfYear = moment().startOf('year').tz('America/New_York');
  const startOfMonth = moment().startOf('month').tz('America/New_York');
  const startOfLastMonth = moment().subtract(1, 'month').startOf('month').tz('America/New_York');

  let [_all, _ytd] = [{ text: 'All', start: startDate }, { text: 'YTD' }];
  let [_last90, _last30] = [{ text: 'Last 90 Days' }, { text: 'Last 30 Days' }];
  let [_lastMo, _thisMo, _today] = [{ text: 'Last Month' }, { text: 'This Month' }, { text: 'Today' }];
  // let _yoy= [{ text: 'Year over Year' }];

  // Year to Date
  if (startOfYear.isAfter(endDate, 'day')) {
    let endYearStart = endDate.clone().startOf('year');

    _ytd.start = (endYearStart.isBefore(startDate, 'day')) ? startDate : endYearStart;
    _ytd.end = endDate;
  }
  else if (startOfYear.isBefore(startDate, 'day')) {
    _ytd.start = startDate;
    _ytd.end = today.clone().subtract(1, 'day').endOf('day');
  }
  else if (isSameDay(startOfYear, endDate)) {
    let lastYearStart = endDate.clone().subtract(1, 'year').startOf('year');

    _ytd.start = (lastYearStart.isBefore(startDate, 'day')) ? startDate : lastYearStart;
    _ytd.end = endDate.clone().subtract(1, 'day');
  }
  else {
    _ytd.start = startOfYear;
    _ytd.end = yesterday;
  }

  // Last 30 and Last 90 Days
  if (!isSameDay(today, endDate)) {
    _all.end = endDate;

    _last30.start = endDate.clone().subtract(30, 'days').startOf('day');
    _last30.end = endDate;

    _last90.start = endDate.clone().subtract(90, 'days').startOf('day');
    _last90.end = endDate;
  }
  else {
    _all.end = endDate.clone().subtract(1, 'day');

    _last30.start = moment().tz('America/New_York').subtract(30, 'days').startOf('day');
    _last30.end = yesterday;

    _last90.start = moment().tz('America/New_York').subtract(90, 'days').startOf('day');
    _last90.end = yesterday;
  }

  // Last Month
  if (startOfLastMonth.isAfter(endDate, 'day')) {
    _lastMo.start = endDate.clone().subtract(1, 'month').startOf('month');
    _lastMo.end = endDate.clone().subtract(1, 'month').endOf('month');
  }
  else {
    _lastMo.start = startOfLastMonth;
    _lastMo.end = moment().subtract(1, 'month').endOf('month').tz('America/New_York');
  }

  // This Month
  if (startOfMonth.isAfter(endDate, 'day')) {
    _thisMo.start = endDate.clone().startOf('month');
    _thisMo.end = endDate;
  }
  else {
    _thisMo.start = startOfMonth;
    _thisMo.end = yesterday;
  }

  _today.start = today;
  _today.end = today.clone().endOf('day');
  // YOY
  // if (startOfLastMonth.isAfter(endDate, 'day')) {
    // _yoy.start = startDate.clone().subtract(1, 'year');
    // _yoy.end = endDate.clone().subtract(1, 'year');
  // }
  // else {
  //   _lastMo.start = startOfLastMonth;
  //   _lastMo.end = moment().subtract(1, 'month').endOf('month').tz('America/New_York');
  // }

  const presetDatesArray = [_all, _ytd, _last90, _last30, _lastMo, _thisMo, _today];

  return presetDatesArray;
}

// const [NOW_DATE, YEST, THIRD_DATE, FOURTH_DATE] = [new Date(), new Date(), new Date(), new Date()];
// NOW_DATE.setDate(NOW_DATE.getDate() - 30);
// YEST.setDate(YEST.getDate() - 0);
// THIRD_DATE.setDate(THIRD_DATE.getDate() - 27);
// FOURTH_DATE.setDate(FOURTH_DATE.getDate() - 14);

const SELECTED_GRAPH = 'white';
const UNSELECTED_GRAPH = '#D3DBDE';
const UNSELECTED_OPACITY = 0.8;
const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const weekdayLookup = ['-'].concat(days, days, days, days, days);

const getDateLabel = (startDate,endDate) => {
  if (startDate.format('M D YYYY') === endDate.format('M D YYYY')) {
    return startDate.format('ddd, M/D/YYYY');
  } else {
    return startDate.format('M/D/YYYY to ')+endDate.format('M/D/YYYY');
  }
}

const initDataArray = (name, locationListLen) => {
  let dataArray = [];

  for (let i = 0; i < locationListLen + 5; i++) {
    if (name === 'dailyAverages') {
      dataArray.push({
        name: name + '_1_' + (i + 1), dateLabel: [], prevDateLabel: '',
        genderAvg: {
          current: { avgDaily: [], avgWeekend: [], avgWork: [] },
          previous: { avgDaily: [], avgWeekend: [], avgWork: [] },
          delta: { 'men': { avgDaily: 0, avgWeekend: 0, avgWork: 0 }, 'women': { avgDaily: 0, avgWeekend: 0, avgWork: 0 } }
        },
        ageAvg: {
          current: { avgDaily: [], avgWeekend: [], avgWork: [] },
          previous: { avgDaily: [], avgWeekend: [], avgWork: [] },
          delta: { 'children': { avgDaily: 0, avgWeekend: 0, avgWork: 0 }, 'adults': { avgDaily: 0, avgWeekend: 0, avgWork: 0 }, 'seniors': { avgDaily: 0, avgWeekend: 0, avgWork: 0 } }
        },
        vehicleAvg: {
          current: { avgDaily: [], avgWeekend: [], avgWork: [] },
          previous: { avgDaily: [], avgWeekend: [], avgWork: [] },
          delta: { 'bikes': { avgDaily: 0, avgWeekend: 0, avgWork: 0 }, 'buses': { avgDaily: 0, avgWeekend: 0, avgWork: 0 }, 'cars': { avgDaily: 0, avgWeekend: 0, avgWork: 0 }, 'motorbikes': { avgDaily: 0, avgWeekend: 0, avgWork: 0 }, 'trucks': { avgDaily: 0, avgWeekend: 0, avgWork: 0 } }
        },
        countAvg: {
          current: { avgDaily: 0, avgWeekend: 0, avgWork: 0 },
          previous: { avgDaily: 0, avgWeekend: 0, avgWork: 0 },
          delta: { avgDaily: 0, avgWeekend: 0, avgWork: 0 }
        },
        locationLabels: [], data: [], men: [], women: [], children: [], adults: [], seniors: [], aggregationType: ''
      });
    }
    else {
      dataArray.push({
        name: name + '_1_' + (i + 1), dateLabel: [], locationLabels: [], data: [], men: [], women: [], children: [], adults: [], seniors: [], aggregationType: ''
      });
    }
  }

  if (name === 'dailyAverages') {
    dataArray.push({
      name: name + '_2_1', dateLabel: [], prevDateLabel: '',
      genderAvg: {
        current: { avgDaily: [], avgWeekend: [], avgWork: [] },
        previous: { avgDaily: [], avgWeekend: [], avgWork: [] },
        delta: { 'men': { avgDaily: 0, avgWeekend: 0, avgWork: 0 }, 'women': { avgDaily: 0, avgWeekend: 0, avgWork: 0 } }
      },
      ageAvg: {
        current: { avgDaily: [], avgWeekend: [], avgWork: [] },
        previous: { avgDaily: [], avgWeekend: [], avgWork: [] },
        delta: { 'children': { avgDaily: 0, avgWeekend: 0, avgWork: 0 }, 'adults': { avgDaily: 0, avgWeekend: 0, avgWork: 0 }, 'seniors': { avgDaily: 0, avgWeekend: 0, avgWork: 0 } }
      },
      vehicleAvg: {
        current: { avgDaily: [], avgWeekend: [], avgWork: [] },
        previous: { avgDaily: [], avgWeekend: [], avgWork: [] },
        delta: { 'bikes': { avgDaily: 0, avgWeekend: 0, avgWork: 0 }, 'buses': { avgDaily: 0, avgWeekend: 0, avgWork: 0 }, 'cars': { avgDaily: 0, avgWeekend: 0, avgWork: 0 }, 'motorbikes': { avgDaily: 0, avgWeekend: 0, avgWork: 0 }, 'trucks': { avgDaily: 0, avgWeekend: 0, avgWork: 0 } }
      },
      countAvg: {
        current: { avgDaily: 0, avgWeekend: 0, avgWork: 0 },
        previous: { avgDaily: 0, avgWeekend: 0, avgWork: 0 },
        delta: { avgDaily: 0, avgWeekend: 0, avgWork: 0 }
      },
      locationLabels: [], data: [], men: [], women: [], children: [], adults: [], seniors: [], aggregationType: ''
    });
  }
  else {
    dataArray.push({ name: name + '_2_1', dateLabel: [], locationLabels: [], data: [], men: [], women: [], children: [], adults: [], seniors: [], aggregationType: '' });
  }

  return dataArray;
}

const extendArray = (extendArray, objName) => {
  if (objName !== 'dailyAverages') {
    extendArray.push({ name: objName + '_2_1', dateLabel: [], locationLabels: [], data: [], men: [], women: [], children: [], adults: [], seniors: [], aggregationType: '' });
  }
  else {
    extendArray.push({
      name: objName + '_2_1', dateLabel: [], prevDateLabel: '',
      genderAvg: {
        current: { avgDaily: [], avgWeekend: [], avgWork: [] },
        previous: { avgDaily: [], avgWeekend: [], avgWork: [] },
        delta: { 'men': { avgDaily: 0, avgWeekend: 0, avgWork: 0 }, 'women': { avgDaily: 0, avgWeekend: 0, avgWork: 0 } }
      },
      ageAvg: {
        current: { avgDaily: [], avgWeekend: [], avgWork: [] },
        previous: { avgDaily: [], avgWeekend: [], avgWork: [] },
        delta: { 'children': { avgDaily: 0, avgWeekend: 0, avgWork: 0 }, 'adults': { avgDaily: 0, avgWeekend: 0, avgWork: 0 }, 'seniors': { avgDaily: 0, avgWeekend: 0, avgWork: 0 } }
      },
      vehicleAvg: {
        current: { avgDaily: [], avgWeekend: [], avgWork: [] },
        previous: { avgDaily: [], avgWeekend: [], avgWork: [] },
        delta: { 'bikes': { avgDaily: 0, avgWeekend: 0, avgWork: 0 }, 'buses': { avgDaily: 0, avgWeekend: 0, avgWork: 0 }, 'cars': { avgDaily: 0, avgWeekend: 0, avgWork: 0 }, 'motorbikes': { avgDaily: 0, avgWeekend: 0, avgWork: 0 }, 'trucks': { avgDaily: 0, avgWeekend: 0, avgWork: 0 } }
      },
      countAvg: {
        current: { avgDaily: 0, avgWeekend: 0, avgWork: 0 },
        previous: { avgDaily: 0, avgWeekend: 0, avgWork: 0 },
        delta: { avgDaily: 0, avgWeekend: 0, avgWork: 0 }
      },
      locationLabels: [], data: [], men: [], women: [], children: [], adults: [], seniors: [], aggregationType: ''
    });
  }

  return extendArray;
}

const getReqArray = (number) => {
  const reqArray = ['dailyAverages_' + number, 'byTimeOfDay_' + number, 'byDayOfWeek_' + number, 'byDayHour_' + number, 'byDate_' + number];

  return reqArray; // cancel in this order, byDate should stay last bc typically longest to return
}

const initLocation = (locationList, firstLoc) => {
  var location = {};
  location[0] = firstLoc;
  for (let i = 1; i <= locationList.length; i++) {
    location[i] = [];
  }

  return location;
}

const initQueryLocations = (locationList, firstLoc) => {
  var location = {};
  // location[0] = firstLoc;
  location[0] = (Array.isArray(firstLoc)) ? firstLoc.flat() : firstLoc;
  for (let i = 1; i <= locationList.length; i++) {
    location[i] = [];
  }

  return location;
}

const initOwnerChecked = (ownerList) => {
  var ownerChecked = {};

  if (Object.keys(ownerList).length === 1) {
    ownerChecked[Object.keys(ownerList)[0]] = false;
  }
  else {
    for (let o in ownerList) {
      ownerChecked[o] = false;
      let ownerDivisions = ownerList[o];
      if (!Array.isArray(ownerDivisions)) {
        for (let od in ownerDivisions) {
          ownerChecked[od] = false;
        }
      }
    }
  }

  return ownerChecked;
}

const debounce = (callback, delay) => {
  let timer;

  return (...args) => {
    clearTimeout(timer); // clear timer, prevent callback func from executing if func called again before timer expires
    timer = setTimeout(() => callback(...args), delay); // schedule callback func to execute after delay (time in ms)
  };
}


class Dashboard extends PureComponent {
  constructor (props) {
    super(props);

    const locationsObj = getLocations(props.auth.user);
    const initDateRange = getDateRange({ 0: [locationsObj.locationList[0]] }, locationsObj['startDates'], locationsObj['endDates']); // location param is an object containing array

    this.state = {
      retailDash: (clientData[props.auth.user.client?.toLowerCase() ?? '']?.retailDash) ? true : false,
      value: 0,
      mode: 'Traffic',
      modeLabel: 'Traffic',
      locType: (clientData[props.auth.user.client?.toLowerCase() ?? '']?.onlyVehicles) ? 'Vehicles' : 'People',
      sameWeather: isSameWeather(props.auth.user.client),
      startDate: [getClientDate(props.auth.user.client, 'start', initDateRange), []],
      endDate: [getClientDate(props.auth.user.client, 'end', initDateRange), []],
      // level: { 0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [], 8: [], 9: [], 10: [] },
      locationsObject: locationsObj,
      location: initLocation(locationsObj['locationList'], [locationsObj['locationList'][0]]),
      qLocation: initQueryLocations(locationsObj['qNames'], [locationsObj['qNames'][0]]),
      dateCard: [true, false, false], // { 0: true, 1: false, 2: false },
      locationCard: [0],
      initlocationCard: [{}],
      compareActiveDate: true,
      compareActiveLoc: true,
      locationLabels: { 0: [locationsObj['locationList'][0]], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [], 8: [], 9: [], 10: [], 11: [], 12: [] },
      dateLabel: { 0: getDateLabel(getClientDate(props.auth.user.client, 'start', initDateRange), getClientDate(props.auth.user.client, 'end', initDateRange)), 1: [], 2: [] },
      locationList: locationsObj['locationList'],
      labelList: locationsObj['labelList'],
      byDateData: initDataArray('byDate', locationsObj['locationList'].length),
      byTimeOfDayData: initDataArray('byTimeOfDay', locationsObj['locationList'].length),
      byDayOfWeekData: initDataArray('byDayOfWeek', locationsObj['locationList'].length),
      byDayHourData: initDataArray('byDayHour', locationsObj['locationList'].length),
      dailyAverages: initDataArray('dailyAverages', locationsObj['locationList'].length),
      currentGraph: 'byDay',
      interval: 'Day',
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      noCard: false,
      byTimeMessage: false,
      checkedLocOwners: initOwnerChecked(locationsObj['locOwners']),
      checkedLocCategories: Object.keys(locationsObj['locCategories']).reduce((acc, category) => ({ ...acc, [category]: false }), {}),
      dateRange: initDateRange,
      datePresets: getDatePresets(initDateRange),
      simulatedData: true,
      blockedDays: [[], []],
      excludeDates: [[], []],
      reportOpenIms: false,
      reportInterval: 3, // 'Custom',
      reportTab: 0,
      isLoading: false,
      reportKey: 0, // temporay to force full component update when location or date change
      zoomLevel: 1.0,
      // reportDrawerOpen: (window.innerWidth < 950) ? false : false,
      reportDrawerOpen: false,
      reportDemo: props.auth.user.client.toLowerCase() === 'jemb' || props.auth.user.client.toLowerCase() === 'usp' ? false : true,
      reportChange: true,
      reportDetailed: false,
      reportHighlightsEdit: false,
      reportHighlightsValue: '',
      videoDash: (props.auth.user?.roles?.includes('video_only')) ? true : false, // default to video dashboard
    };

    this.fetchQueries();
    // if (!props.auth.user?.roles?.includes('video_only')) this.fetchQueries(); // if user has video_only role don't request data from api
  }

  componentDidMount() {
    document.title = 'Exteros Dashboard';
    window.addEventListener('resize', this.resizeChart);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeChart);
  }

  toggleVideo = (newVideo) => {
    this.setState({
      videoDash: newVideo,
      ...(this.state.reportOpenIms && newVideo) && {
        reportOpenIms: false, // if opening video dash while report is open, close report and reset highlights
        reportHighlightsEdit: false,
        reportHighlightsValue: ''
      },
    });
  }

  toggleReportSwitch = (e) => {
    this.setState({
      [e.target.name]: e.target.checked,
    });
  };

  resizeChart = debounce(() => {
    this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
  }, 100); // debounce prevents executing resizeChart constantly while window is being resized (waits 100ms from last resize)

  changeInterval = (value) => {
    this.setState({ interval: value }, function() {
      this.fetchQueries();
    });
  }

  handleChange = (event, value) => {
    this.setState({ value }); // change dashboard tab by clicking tab
  };

  handleChangeIndex = (index) => {
    this.setState({ value: index }); // this is only called when specifically swiping between dashboard tabs (Counts, Gender, Age)
  };

  reportHighlightsChanged = (e) => {
    // const str = e.target.innerText.replace(/(\n{2})/g, '\n');
    // const str = (/(<div><br><\/div><div>)/.test(e.target.innerHTML)) ? e.target.innerText.replace(/(\n{2})/g, '\n') : e.target.innerText;

    this.setState({
      reportHighlightsEdit: true,
      // reportHighlightsValue: str,
      reportHighlightsValue: e.target.innerHTML
    })
  };

  handleReportTabChangeSwipe = (index) => {
    this.reportTabs(index); // this is only called when specifically swiping between report tabs (Custom, Weekly, Monthly)
  };

  handleReportTabChange = (event, value) => {
    // console.log('(report tabs) handleReportTabChange value: ', value);
    this.reportTabs(value);
  }

  reportTabs = (rIndex) => {
    const rInterval = (rIndex === 0) ? 3 : rIndex;

    // if new tab is weekly (1) or monthly (2), update date picker card to reflect new report date ranges used in the report.
    if (rInterval < 3) {
      const getDates = getReportDates(this.state.endDate[0].clone(), rInterval, this.state.dateRange);

      // checks if start date is before data start and adjusts - This should be moved into getReportDates()
      if (this.state.dateRange.start.isAfter(getDates.start, 'day')) getDates.start = this.state.dateRange.start.clone();

      const getLabels = getDateLabel(getDates.start, getDates.end);
      let tempStartDate = this.state.startDate;
      let tempEndDate = this.state.endDate;
      let tempDateLabel = this.state.dateLabel;
      tempStartDate[0] = getDates.start;
      tempEndDate[0] = getDates.end;
      tempDateLabel[0] = getLabels;

      this.setState({
        reportTab: rIndex,
        reportInterval: rInterval,
        startDate: tempStartDate,
        endDate: tempEndDate,
        dateLabel: tempDateLabel,
        reportKey: this.state.reportKey + 1,
        interval: 'Day', // reset interval to Day
        reportHighlightsEdit: false, // reset report highlights
        reportHighlightsValue: '',
      });
    }
    else {
      this.setState({
        reportTab: rIndex,
        reportInterval: rInterval,
        reportHighlightsEdit: false, // reset report highlights
        reportHighlightsValue: '',
      });
    }
  }

  handleDrawerToggle = () => {
    this.setState({ reportDrawerOpen: !this.state.reportDrawerOpen });
  };

  changeMode = (event, value) => {
    if (value !== null) {
      const label = (value.toLowerCase() === 'conversion') ? 'Capt' : value;

      this.setState({ mode: value, modeLabel: label }, () => {
        this.convCompare();
      });
    }
  };

  changeLocationType = (event, value) => {
    if (value !== null) {
      // value 0 sets open tab to 'Counts'
      this.setState({ locType: value, value: 0 }, () => {
        this.locationType(value);
      });
    }
  };

  toggleSimulated = async (event) => {
    this.setState({
      simulatedData: event.target.checked,
      reportKey: (this.state.reportOpenIms) ? this.state.reportKey + 1 : 0,
      reportHighlightsEdit: false, // reset report highlights
      reportHighlightsValue: '',
    }, () => {
      if (!this.state.reportOpenIms) this.fetchQueries(); // skip dashboard api calls when viewing report
    });
  }

  comparePrevYear = async (event) => {
    let tempStartDate = this.state.startDate;
    let tempEndDate = this.state.endDate;
    tempStartDate[1] = tempStartDate[0].clone().subtract(1, 'year');
    tempEndDate[1] = tempEndDate[0].clone().subtract(1, 'year');
    let tempCard = this.state.dateCard;
    tempCard[1] = true;

    // if (config.analytics) window.analytics.track('New Date', { panel: name, });
    this.setState({ startDate: tempStartDate, endDate: tempEndDate, dateCard: tempCard, compareActiveLoc: false }, () => {
      if (!(this.state.startDate[1].length === 0 || this.state.endDate[1] === 0)) this.changedDateCard(1)({ startDate: tempStartDate[1], endDate: tempEndDate[1] });
    });
  };

  getNextDateCard = name => event => {
    let tempStartDate = this.state.startDate;
    let tempEndDate = this.state.endDate;

    const range = 1 + moment(tempEndDate[0]).tz('America/New_York').diff(tempStartDate[0], 'days'); // updates dateCard2 preset date range when user changes dateCard1 while dateCard2 is open.
    tempStartDate[1] = moment(tempStartDate[0]).tz('America/New_York').subtract(range, 'days');
    tempEndDate[1] = moment(tempEndDate[0]).tz('America/New_York').subtract(range, 'days');

    let tempDateCard = this.state.dateCard;
    tempDateCard[1] = true;

    // if (config.analytics) window.analytics.track('New Date', { panel: name, });

    // changedDateCard() is not called from DatePicker when component is first created (everytime user clicks add dates).
    // call changedDateCard() in callback, all subsequent date changes call changedDateCard() from DatePicker
    this.setState({ startDate: tempStartDate, endDate: tempEndDate, dateCard: tempDateCard, compareActiveLoc: false }, () => {
      this.changedDateCard(1)({ startDate: tempStartDate[1], endDate: tempEndDate[1] });
    });
  };

  closeAllLocations = () => {
    this.closeAllLocs(true);
  }

  closeLocation = (name) => {
    let locArray = this.state.locationCard; // Array of open location card positions: [0, 1, 2]
    let cardInd = locArray.indexOf(name); // Index in array of location card to close (same value as name?)
    let reqArray = getReqArray('1_' + (cardInd + 1));
    getData('', '', '', true, reqArray); // cancel any promises requested by closed location card

    let location = this.state.location; // Obj containing arrays for each open location card: {"0":["Woodbury Common"],"1":["Wrentham Village","Boston - Boylston"],"2":[],"3":[],...
    let qLocation = this.state.qLocation;
    let tempLocLabels = this.state.locationLabels; // Obj - same as location obj but w/ labels for each location card
    let tempInitLocationCard = this.state.initlocationCard; // Array of objs, each obj contains location data for each open location card
    let tempByDateData = Object.assign([], this.state.byDateData); // Creates new array of objects copied from state
    let tempByTimeOfDayData = Object.assign([], this.state.byTimeOfDayData);
    let tempByDayOfWeekData = Object.assign([], this.state.byDayOfWeekData);
    let tempByDayHourData = Object.assign([], this.state.byDayHourData);
    let tempDailyAverages = Object.assign([], this.state.dailyAverages);

    let noCard = false;
    if (locArray.length === 1) {
      const locationListLen = this.state.locationsObject['locationList'].length;

      noCard = true;
      locArray.splice(cardInd, 1);
      tempInitLocationCard.splice(cardInd, 1);
      tempByDateData = initDataArray('byDate', locationListLen);
      tempByTimeOfDayData = initDataArray('byTimeOfDay', locationListLen);
      tempByDayOfWeekData = initDataArray('byDayOfWeek', locationListLen);
      tempByDayHourData = initDataArray('byDayHour', locationListLen);
      tempDailyAverages = initDataArray('dailyAverages', locationListLen);
    }
    else {
      locArray.pop(); // remove last element from location array, location # in this array indicates cards position
      tempInitLocationCard.splice(cardInd, 1); // specifically remove closed location from array
      tempByDateData.splice(cardInd, 1);
      tempByTimeOfDayData.splice(cardInd, 1);
      tempByDayOfWeekData.splice(cardInd, 1);
      tempByDayHourData.splice(cardInd, 1);
      tempDailyAverages.splice(cardInd, 1);

      // arrays are all the same length
      for (let i = cardInd; i < tempByDateData.length; i++) {
        tempByDateData[i].name = 'byDate_1_' + (i + 1);
        tempByTimeOfDayData[i].name = 'byTimeOfDay_1_' + (i + 1);
        tempByDayOfWeekData[i].name = 'byDayOfWeek_1_' + (i + 1);
        tempByDayHourData[i].name = 'byDayHour_1_' + (i + 1);
        tempDailyAverages[i].name = 'dailyAverages_1_' + (i + 1);
      }

      tempByDateData = extendArray(tempByDateData, 'byDate');
      tempByTimeOfDayData = extendArray(tempByTimeOfDayData, 'byTimeOfDay');
      tempByDayOfWeekData = extendArray(tempByDayOfWeekData, 'byDayOfWeek');
      tempByDayHourData = extendArray(tempByDayHourData, 'byDayHour');
      tempDailyAverages = extendArray(tempDailyAverages, 'dailyAverages');
    }

    delete location[cardInd]; // these are objects, use delete instead of splice
    delete qLocation[cardInd];
    delete tempLocLabels[cardInd];

    let count = 0;
    let [newObjLocation, newObjQLocation, newObjLabel] = [{}, {}, {}];

    // use locLabels for loop, if a category w/ no locations is open, 'locationLabels' will contain a label, 'location' will conatin empty array
    for (const key in tempLocLabels) {
      if (Object.prototype.hasOwnProperty.call(tempLocLabels, key)) {
        if (tempLocLabels[key].length !== 0) {
          newObjLocation[count] = location[key] ?? [];
          newObjQLocation[count] = qLocation[key] ?? [];
          newObjLabel[count] = tempLocLabels[key] ?? [];

          if (Object.prototype.hasOwnProperty.call(tempInitLocationCard[count], 'name')) {
            tempInitLocationCard[count].name = count; // initlocationCard 0 is initially empty, if that card is removed, then new property 0 has data
          }
  
          count++
        }
      }
    }

    const changeActive = (tempInitLocationCard.length <= 1) ? true : false; // used to toggle date compare button
    const newDateRange = getDateRange(location, this.state.locationsObject['startDates'], this.state.locationsObject['endDates']);
    const newDatePresets = getDatePresets(newDateRange);

    this.setState({
      noCard: noCard, initlocationCard: tempInitLocationCard, locationCard: locArray, locationLabels: newObjLabel, location: newObjLocation, qLocation: newObjQLocation,
      byDateData: tempByDateData, compareActiveLoc: true, byTimeOfDayData: tempByTimeOfDayData, byDayHourData: tempByDayHourData,
      byDayOfWeekData: tempByDayOfWeekData, compareActiveDate: changeActive, dailyAverages: tempDailyAverages, dateRange: newDateRange, datePresets: newDatePresets,
      reportKey: (this.state.reportOpenIms) ? this.state.reportKey + 1 : 0,
      reportHighlightsEdit: false, // reset report highlights
      reportHighlightsValue: '',
    });
  }

  closeAllLocs = (all = true) => {
    const x = (all) ? 0 : 1; // 1 closes all but first card
    let locArray = this.state.locationCard; // Array of open location card positions: [0, 1, 2]

    for (let l = locArray.length; l > x; l--) {
      let reqArray = getReqArray('1_' + l);
      getData('', '', '', true, reqArray); // cancel any promises requested by closed location card
    }

    let location = this.state.location; // Obj containing arrays for each open location card: {"0":["Woodbury Common"],"1":["Wrentham Village","Boston - Boylston"],"2":[],"3":[],...
    let qLocation = this.state.qLocation;
    let tempLocLabels = this.state.locationLabels; // Obj - same as location obj but w/ labels for each location card
    let tempInitLocationCard = this.state.initlocationCard; // Array of objs, each obj contains location data for each open location card
    let tempByDateData = Object.assign([], this.state.byDateData); // Creates new array of objects copied from state
    let tempByTimeOfDayData = Object.assign([], this.state.byTimeOfDayData);
    let tempByDayOfWeekData = Object.assign([], this.state.byDayOfWeekData);
    let tempByDayHourData = Object.assign([], this.state.byDayHourData);
    let tempDailyAverages = Object.assign([], this.state.dailyAverages);

    const locationListLen = this.state.locationsObject['locationList'].length;

    for (let l = Object.keys(location).length; l > x; l--) {
      delete location[l - 1];
      delete qLocation[l - 1];
    }
    for (let l = Object.keys(tempLocLabels).length; l > x; l--) {
      delete tempLocLabels[l - 1];
    }

    if (x === 1) {
      const [saveDate, saveTime, saveDayOfWeek, saveDayHour, saveDailyAvg] = [tempByDateData[0], tempByTimeOfDayData[0], tempByDayOfWeekData[0], tempByDayHourData[0], tempDailyAverages[0]];

      locArray.splice(1, locArray.length - 1); // delete all elements except first, [0]
      tempInitLocationCard.splice(1, tempInitLocationCard.length - 1);
      tempByDateData = initDataArray('byDate', locationListLen); // reset data arrays to original default values
      tempByTimeOfDayData = initDataArray('byTimeOfDay', locationListLen);
      tempByDayOfWeekData = initDataArray('byDayOfWeek', locationListLen);
      tempByDayHourData = initDataArray('byDayHour', locationListLen);
      tempDailyAverages = initDataArray('dailyAverages', locationListLen);

      tempByDateData.splice(0, 1, saveDate);
      tempByTimeOfDayData.splice(0, 1, saveTime);
      tempByDayOfWeekData.splice(0, 1, saveDayOfWeek);
      tempByDayHourData.splice(0, 1, saveDayHour);
      tempDailyAverages.splice(0, 1, saveDailyAvg);
    }
    else {
      locArray.splice(0); // delete all elements, empty array []
      tempInitLocationCard.splice(0);
      tempByDateData = initDataArray('byDate', locationListLen); // reset data arrays to original default values
      tempByTimeOfDayData = initDataArray('byTimeOfDay', locationListLen);
      tempByDayOfWeekData = initDataArray('byDayOfWeek', locationListLen);
      tempByDayHourData = initDataArray('byDayHour', locationListLen);
      tempDailyAverages = initDataArray('dailyAverages', locationListLen);
    }

    const newDateRange = getDateRange(location, this.state.locationsObject['startDates'], this.state.locationsObject['endDates']);
    const newDatePresets = getDatePresets(newDateRange);

    this.setState({
      noCard: true,
      compareActiveLoc: true,
      compareActiveDate: true,
      dateRange: newDateRange,
      datePresets: newDatePresets,
      locationCard: locArray,
      initlocationCard: tempInitLocationCard,
      location: location,
      qLocation: qLocation,
      locationLabels: tempLocLabels,
      byDateData: tempByDateData,
      byTimeOfDayData: tempByTimeOfDayData,
      byDayOfWeekData: tempByDayOfWeekData,
      byDayHourData: tempByDayHourData,
      dailyAverages: tempDailyAverages
    });
  }

  convCompare = () => {
    let [locChecked, locChecked2, newCardState] = [{}, {}, {}];
    let tempCheckedLocOwners = this.state.checkedLocOwners;
    let tempCheckedLocCategories = this.state.checkedLocCategories;
    let tempChildLocs = this.state.locationsObject.childLocs;
    let tempParentLocs = this.state.locationsObject.parentLocs;
    let tempLocationList = this.state.locationsObject.locationList;

    this.closeAllLocs(false); // closes all but 1 (first) location, leaving 1 card open makes animations/transition smoother, all data is updated correctly

    newCardState.name = 0;
    newCardState.aggregationType = 'average';
    newCardState.checkedLocOwners = tempCheckedLocOwners;
    newCardState.checkedLocCategories = tempCheckedLocCategories;
    newCardState.aggregate = (this.state.mode === 'Conversion') ? true : false;

    // set default child, parent locations. For now both must contain all locs in same order.
    let [c, p] = [false, false];
    for (let l in tempLocationList) {
      if (!c) {
        locChecked[tempLocationList[l]] = (tempChildLocs.includes(tempLocationList[l])) ? true : false; // child
        c = (tempChildLocs.includes(tempLocationList[l])) ? true : false;
      }
      else {
        locChecked[tempLocationList[l]] = false;
      }

      if (!p) {
        locChecked2[tempLocationList[l]] = (tempParentLocs.includes(tempLocationList[l])) ? true : false; // parent
        p = (tempParentLocs.includes(tempLocationList[l])) ? true : false;
      }
      else {
        locChecked2[tempLocationList[l]] = false;
      }
    }

    newCardState.locationChecked = locChecked;
    newCardState.locationChecked2 = locChecked2;

    this.changedLocationCard(newCardState);
  };

  locationType = (type) => {
    let [locChecked, newCardState] = [{}, {}];
    let tempCheckedLocOwners = this.state.checkedLocOwners;
    let tempCheckedLocCategories = this.state.checkedLocCategories;
    let tempLocationList = this.state.locationsObject.locationList;

    this.closeAllLocs(false); // closes all but 1 (first) location, leaving 1 card open makes animations/transition smoother, all data is updated correctly

    newCardState.name = 0;
    newCardState.aggregate = false;
    newCardState.aggregationType = 'total';
    newCardState.checkedLocOwners = tempCheckedLocOwners;
    newCardState.checkedLocCategories = tempCheckedLocCategories;
    newCardState.checkedVehicleTypes = this.state.locationsObject.vehicleTypes.reduce((acc, vehicle) => ({ ...acc, [vehicle]: true }), {}); // { 'bikes': true, 'motorbikes': true, 'cars': true, 'buses': true, 'trucks': true }

    tempLocationList.forEach(loc => locChecked[loc] = false);
    locChecked[this.state.locationsObject.locationTypes[type][0].name] = true;
    newCardState.locationChecked = locChecked;

    this.changedLocationCard(newCardState);
  }

  closeDate = (name, openReport) => {
    let reqArray = getReqArray('2_1');
    getData('', '', '', true, reqArray); // cancel any promises requested by closed location card

    let dateArray = this.state.dateCard;
    let dateLabels = this.state.dateLabel;
    let tempByDateData = Object.assign([], this.state.byDateData); // Creates new array of objects copied from state
    let tempByTimeOfDayData = Object.assign([], this.state.byTimeOfDayData);
    let tempByDayOfWeekData = Object.assign([], this.state.byDayOfWeekData);
    let tempByDayHourData = Object.assign([], this.state.byDayHourData);
    let tempDailyAverages = Object.assign([], this.state.dailyAverages);
    const excludeDates = (openReport) ? [[], []] : [[...this.state.excludeDates[0]], []]; // clear excludeDates from either 2nd date card or both

    dateArray[name] = false;
    dateLabels[name] = [];
    tempByDateData.pop(); // 2nd date card is always last element in array
    tempByTimeOfDayData.pop();
    tempByDayOfWeekData.pop();
    tempByDayHourData.pop();
    tempDailyAverages.pop();

    tempByDateData = extendArray(tempByDateData, 'byDate');
    tempByTimeOfDayData = extendArray(tempByTimeOfDayData, 'byTimeOfDay');
    tempByDayOfWeekData = extendArray(tempByDayOfWeekData, 'byDayOfWeek');
    tempByDayHourData = extendArray(tempByDayHourData, 'byDayHour');
    tempDailyAverages = extendArray(tempDailyAverages, 'dailyAverages');

    this.setState({
      dateCard: dateArray,
      dateLabel: dateLabels,
      compareActiveLoc: true,
      byDateData: tempByDateData,
      byTimeOfDayData: tempByTimeOfDayData,
      byDayOfWeekData: tempByDayOfWeekData,
      byDayHourData: tempByDayHourData,
      dailyAverages: tempDailyAverages,
      excludeDates: excludeDates,
      reportOpenIms: (openReport) ? true : false,
    });
  }

  dateCardExcludeDate = name => exDate => {
    const excludeDates = [...this.state.excludeDates];

    let removeDate = false; // if array already contains dates, check if user is deselecting date.

    if (excludeDates[name].length > 0) {
      excludeDates[name].forEach((d, i) => {
        if (d.isSame(exDate, 'day')) {
          removeDate = true;
          excludeDates[name].splice(i, 1);
        }
      })
    }

    if (!removeDate) excludeDates[name].push(exDate); // if date wasn't remove, add to array

    this.setState({ excludeDates: excludeDates })
  }

  changedDateCard = name => event =>  {
    let tempStartDate = this.state.startDate;
    let tempEndDate = this.state.endDate;
    let tempDateLabel = this.state.dateLabel;
    let newInterval = this.state.interval;

    if (!(event.startDate === null || event.endDate === null)) {
      // if Reports are open to either weekly or monthly tab, dates get updated differently.
      if (this.state.reportOpenIms && this.state.reportTab !== 0) {
        const getDates = getReportDates(event.endDate.tz('America/New_York'), this.state.reportTab, this.state.dateRange);

        tempStartDate[name] = getDates.start;
        tempEndDate[name] = getDates.end;
        tempDateLabel[name] = getDateLabel(tempStartDate[name], tempEndDate[name]);
      }
      else {
        let curStartDate = event.startDate.tz('America/New_York');
        let curEndDate = event.endDate.tz('America/New_York');

        tempStartDate[name] = curStartDate.startOf('day');
        tempEndDate[name] = curEndDate.endOf('day');
        tempDateLabel[name] = getDateLabel(tempStartDate[name], tempEndDate[name]);
      }

      if (this.state.dateCard[1] && name === 0 && (tempStartDate[1] instanceof moment)) {
        const newRange = moment(tempEndDate[name]).tz('America/New_York').diff(tempStartDate[name], 'days'); // updates dateCard2 preset date range when user changes dateCard1 while dateCard2 is open.
        tempEndDate[1] = moment(tempStartDate[1]).tz('America/New_York').add(newRange, 'days');

        // if new endDate is after dateRange end date, set to date range end
        if (tempEndDate[1].isAfter(this.state.dateRange.end, 'day')) tempEndDate[1] = this.state.dateRange.end.clone();

        tempDateLabel[1] = getDateLabel(tempStartDate[1], tempEndDate[1]);
      }

      const daysDiff = tempEndDate[name].diff(tempStartDate[name], 'days') + 1;
      if (daysDiff >= 2190) {
        newInterval = 'Year';
      } else if (daysDiff >= 730) {
        newInterval = 'Month';
      } else if (daysDiff >= 270) {
        newInterval = 'Week';
      } else if (daysDiff < 270) {
        newInterval = 'Day';
      } 

      let tempBlockedDays = this.state.blockedDays;
      tempBlockedDays[name] = [];
      if (event.sunChecked === false) {
        tempBlockedDays[name].push(1);
      }
      if (event.monChecked === false) {
        tempBlockedDays[name].push(2);
      }
      if (event.tueChecked === false) {
        tempBlockedDays[name].push(3);
      }
      if (event.wedChecked === false) {
        tempBlockedDays[name].push(4);
      }
      if (event.thuChecked === false) {
        tempBlockedDays[name].push(5);
      }
      if (event.friChecked === false) {
        tempBlockedDays[name].push(6);
      }
      if (event.satChecked === false) {
        tempBlockedDays[name].push(7);
      }

      const excludeDates = [...this.state.excludeDates];
      if (excludeDates[name].length > 0) {
        excludeDates[name] = excludeDates[name].filter(exDate => {
          // remove dates that are not within new start/end date range
          return !(exDate.startOf('day').isAfter(tempEndDate[name], 'day')) && !(exDate.startOf('day').isBefore(tempStartDate[name], 'day'));
        });
      }

      this.setState({
        startDate: tempStartDate, endDate: tempEndDate, dateLabel: tempDateLabel, interval: newInterval,
        blockedDays: tempBlockedDays,
        excludeDates: excludeDates,
        reportKey: (this.state.reportOpenIms) ? this.state.reportKey + 1 : 0,
        reportHighlightsEdit: false, // reset report highlights
        reportHighlightsValue: '',
      }, function () {
        if (config.analytics) window.analytics.track('Date Selection', { dates: tempDateLabel[name], panel: name, });

        if (!this.state.reportOpenIms) this.fetchQueries(); // skip dashboard api calls when viewing report
      });
    }
  };

  changedLocationCard = event =>  {
    const tempLoc = JSON.parse(JSON.stringify(event));
    let [locs, qLocs, locLabels] = [[], [], []];
    let tempLocCard = this.state.locationCard;
    // let tempLocCard = JSON.parse(JSON.stringify(this.state.locationCard));
    // let levs = [], subLocs = [],  // locNum = name;
    // const locNum =  event.name;
    let locNum = 0;
    if (tempLoc.name >= 0 && tempLoc.name !== null) {
      locNum = tempLoc.name;
    }
    tempLoc.name = locNum;
    // if (tempLocCard.length > 0 && tempLocCard.includes(locNum)) {
    //   locNum = tempLocCard.indexOf(locNum);
    // } 
    // let tempLevel = this.state.level;
    let tempLocation = this.state.location;
    let qLocation = this.state.qLocation;
    // let tempLocation = JSON.parse(JSON.stringify(this.state.location));
    // let tempSublocation = this.state.sublocation;
    let tempLocLabels = this.state.locationLabels;
    // let tempLocLabels = JSON.parse(JSON.stringify(this.state.locationLabels));

    let tempInitLocationCard = this.state.initlocationCard;
    // let tempInitLocationCard = JSON.parse(JSON.stringify(this.state.initlocationCard));
    let tempLocObj = this.state.locationsObject;

    for (let l in this.state.locationList) {
      let curLoc = this.state.locationList[l];
      let curLabel = this.state.labelList[l];

      if (event.aggregate && Object.values(event.locationChecked).filter(Boolean).length > 1) {
        curLabel = this.state.locationsObject['locAbbreviation'][parseInt(l)];
      }

      if (event.locationChecked[curLoc]) {
        locs.push(curLoc);
        qLocs.push(this.state.locationsObject.qNames[l]);
        tempLocation[locNum] = locs;
        qLocation[locNum] = locs;

        let changedLabels = false;
        if (this.props.auth.user.client.toLowerCase() === 'faa') {
          if (locLabels.length > 0) {
            let streetNum = parseInt(curLabel.substring(15, 17));
            let lastIndex = curLabel.lastIndexOf(' ');
            let lastWord = curLabel.substring(lastIndex + 1);
            let prevLabels = locLabels.split(', ');

            for (let i = 0; i < prevLabels.length; i++) {
              let nextStNum = parseInt(prevLabels[i].substring(15, 17));
              let nextlastIndex = prevLabels[i].lastIndexOf(' ');
              let nextlastWord = prevLabels[i].substring(nextlastIndex + 1);

              if (streetNum === nextStNum && lastWord !== nextlastWord) {
                prevLabels[i] = prevLabels[i].substring(0, nextlastIndex);
                locLabels = prevLabels.join(', ');
                changedLabels = true;
              }
            }
          }

          if (!changedLabels) locLabels.length > 0 ? locLabels = locLabels + ', ' + curLabel : locLabels = curLabel;
        }
        else {
          locLabels.length > 0 ? locLabels = locLabels + ', ' + curLabel : locLabels = curLabel;
        }
      }

      if (this.state.mode === 'Conversion') {
        if (event.locationChecked2[curLoc]) {
          locs.push(curLoc);
          qLocs.push(this.state.locationsObject.qNames[l]);
          tempLocation[locNum] = locs;
          locLabels.length > 0 ? locLabels = locLabels + ' / ' + curLabel : locLabels = curLabel;
        }
      }
    }

    if (locNum > tempLocCard.length - 1) {
      tempLocCard.push(locNum);
      tempInitLocationCard.push(tempLoc);
    }
    else {
      tempLocCard[locNum] = locNum;
      tempInitLocationCard[locNum] = tempLoc;
    }

    let compare = (tempLocCard.length > 1) ? false : true; // used to toggle date compare button

    // tempLevel[event.name] = levs;
    tempLocation[locNum] = locs;
    qLocation[locNum] = qLocs.flat(); // ensure this is an array of strings
    // tempSublocation[name] = subLocs;

    let labelPost = '';
    if (event.aggregationType === 'total') {
      if (Object.values(event.locationChecked).filter(Boolean).length > 1) {
        labelPost = ' (Total)';
      }
    }
    else if (event.aggregationType === 'average') {
      if (Object.values(event.locationChecked).filter(Boolean).length > 1) {
        labelPost = ' (Avg)';
      }
    }

    let catIdx = Object.values(event.checkedLocCategories).indexOf(true);
    let ownerIdx = Object.values(event.checkedLocOwners).indexOf(true);

    if (catIdx >= 0) {
      tempLocLabels[locNum] = [Object.keys(event.checkedLocCategories)[catIdx] + labelPost];
    }
    else if (ownerIdx >= 0) {
      tempLocLabels[locNum] = [Object.keys(event.checkedLocOwners)[ownerIdx] + labelPost];
    }
    else {
      if (this.props.auth.user.client.toLowerCase() !== 'mcdonalds') {
        if (this.state.locType !== 'Vehicles' || (this.state.locType === 'Vehicles' && !event.checkedVehicleTypes)) {
          tempLocLabels[locNum] = [locLabels + labelPost];
        }
        else {
          if (Object.values(event.checkedVehicleTypes).every((v) => v === false) || Object.values(event.checkedVehicleTypes).every((v) => v === true)) {
            tempLocLabels[locNum] = [locLabels + labelPost]; // Vehicle labels remain the same when all vehicle types are selected or all are deselected.
          }
          else {
            // Add list of selected v-types to loc label, must be surrounded by [] for regex in getColor(), getImage(), and AveragesHeader to work
            const vehLabels = Object.keys(event.checkedVehicleTypes).filter(v => Boolean(event.checkedVehicleTypes[v])).join(', ');
            tempLocLabels[locNum] = [locLabels + ' [' + vehLabels + ']' + labelPost]; // ex: Southbound Traffic [cars, buses, trucks]
          }
        }
      }
      else {
        // labels are currently always in same order as location list, since both groups of locs are consecutive can just do a string replace.        
        locLabels = locLabels.toString().replace('McDonald\'s Sidewalk, Whole Foods Sidewalk', 'Edgerly Rd');
        locLabels = locLabels.toString().replace('Passage Entering, Passage Exiting', 'Mass Ave - Edgerly Passage'); // labels for combined locs use abbreviations
        tempLocLabels[locNum] = [locLabels + labelPost];
      }
    }

    const newDateRange = getDateRange(tempLocation, tempLocObj['startDates'], tempLocObj['endDates']);
    const newDatePresets = getDatePresets(newDateRange);

    this.setState({
      noCard: false,
      compareActiveDate: compare,
      compareActiveLoc: this.state.dateCard[1] ? false : true,
      dateRange: newDateRange,
      datePresets: newDatePresets,
      location: tempLocation,
      qLocation: qLocation,
      locationCard: tempLocCard,
      locationLabels: tempLocLabels,
      initlocationCard: tempInitLocationCard,
      reportKey: (this.state.reportOpenIms) ? this.state.reportKey + 1 : 0,
      reportHighlightsEdit: false, // reset report highlights
      reportHighlightsValue: '',
      // level: tempLevel,
      // sublocation: tempSublocation,
      // byDateData: tempByDateData,
      // byTimeOfDayData: tempByTimeOfDayData,
      // byDayOfWeekData: tempByDayOfWeekData,
    }, function() {
      if (config.analytics) window.analytics.track('Location Selection', { locations: tempLocLabels[tempLoc.name], compare: false, panel: tempLoc.name });

      if (!this.state.reportOpenIms) this.fetchQuery(1, tempLoc.name + 1, this.state.dateLabel[0], tempLocLabels[tempLoc.name]); // skip dashboard api calls when viewing report

      if (this.state.dateCard[1] && (this.state.startDate[1] instanceof moment)) {
        this.fetchQuery(2, tempLoc.name + 1, this.state.dateLabel[1], tempLocLabels[tempLoc.name]);
      }
    });
  }

  clickGraph = activeGraph => event => {
    this.setState({ currentGraph: activeGraph })

    if (activeGraph === 'byTime' && this.state.value === 0 && this.state.mode.toLowerCase() === 'traffic') {
      this.setState({ byTimeMessage: true });
    }
    if (config.analytics) window.analytics.track('Active Graph', { graph: activeGraph, });
  }

  fetchQueries = async () => {
    // let locCount = 0
    let locB;
    let dateB;
    let locLabels;
    let dateLabel;
    for (let d = 1; d <=2; d++) { 
      // for (let l = 1; l <=this.state.locationList.length; l++) {
        for (let l = 1; l <=this.state.locationCard.length; l++) {
        locB=true;
        // locB = this.state.locationCard[l-1]; 
        dateB = this.state.dateCard[d-1];
        // let curLabel = ""
        if ( dateB && locB ) {
          locLabels = this.state.locationLabels[l-1]; 
          dateLabel = this.state.dateLabel[d-1]; // dateLabel is either a string or empty array

          if (dateLabel.length) {
            this.fetchQuery(d, l, dateLabel, locLabels);
          }
        } 
      }
    }
  }

  createData(Time, Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday) {
    return { Time, Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday };
  }

  getTableRows(data, location, locTimes) {
    var [counts, men, women, children, adults, seniors] = [[], [], [], [], [], []];
    var [maxCount, maxMen, maxWomen, maxChildren, maxAdults, maxSeniors, curHr, nextHr] = [0, 0, 0, 0, 0, 0, 0, 0];
    let [curHrString, hrString, nextHrStr] = ['', '', ''];

    const [startArray, endArray] = [[], []];
    const client = this.props.auth.user.client?.toLowerCase() ?? '';

    if (location.length > 0) {
      location.forEach(loc => {
        startArray.push(locTimes.start[loc]);
        endArray.push(locTimes.end[loc]);
      });
    }

    const minHr = Math.min(...startArray);
    const maxHr = endArray.reduce((a, b) => Math.max(a, b), -Infinity);

    let start = (minHr !== Infinity) ? minHr : (clientData[client]?.hrStart ?? 0);
    let end = (maxHr !== -Infinity) ? maxHr : (clientData[client]?.hrEnd ?? 24);

    for (let hr = 0; hr < end - start; hr++) {
      curHr = (hr + start);
      nextHr = curHr + 1;

      if (nextHr === 24) {
        nextHrStr = '12AM';
      } else if (nextHr >= 13) {
        nextHrStr = (nextHr - 12).toString() + 'PM';
      } else if (nextHr === 12) {
        nextHrStr = '12PM';
      } else {
        nextHrStr = nextHr.toString() + 'AM';
      }

      if (curHr >= 13) {
        curHrString = (curHr - 12).toString() + 'PM';
      } else if (curHr === 12) {
        curHrString = '12PM';
      } else if (curHr === 0) {
        curHrString = '12AM';
      } else {
        curHrString = curHr.toString() + 'AM';
      }

      hrString = curHrString + ' - ' + nextHrStr;
      counts[curHr - start] = this.createData(hrString, 0, 0, 0, 0, 0, 0, 0);
      men[curHr - start] = this.createData(hrString, 0, 0, 0, 0, 0, 0, 0);
      women[curHr - start] = this.createData(hrString, 0, 0, 0, 0, 0, 0, 0);
      children[curHr - start] = this.createData(hrString, 0, 0, 0, 0, 0, 0, 0);
      adults[curHr - start] = this.createData(hrString, 0, 0, 0, 0, 0, 0, 0);
      seniors[curHr - start] = this.createData(hrString, 0, 0, 0, 0, 0, 0, 0);
    }

    var curCounts, curMen, curWomen, curChildren, curAdults, curSeniors, rowObj, curDay

    for (let d in data) {
      rowObj = data[d];
      curHr = rowObj.hour - 5;
      if (curHr < 0) {
        curHr = 24 + curHr;
      }

      if (curHr >= start && curHr < end) {
        curDay = weekdayLookup[rowObj.day];

        curCounts = counts[curHr - start];
        curMen = men[curHr - start];
        curWomen = women[curHr - start];
        curChildren = children[curHr - start];
        curAdults = adults[curHr - start];
        curSeniors = seniors[curHr - start];

        curCounts[curDay] = rowObj.avgs.count || 0;
        curMen[curDay] = rowObj.avgs.men || 0;
        curWomen[curDay] = rowObj.avgs.women || 0;
        curChildren[curDay] = rowObj.avgs.children || 0;
        curAdults[curDay] = rowObj.avgs.adults || 0;
        curSeniors[curDay] = rowObj.avgs.seniors || 0;

        counts[curHr - start] = curCounts;
        men[curHr - start] = curMen;
        women[curHr - start] = curWomen;
        children[curHr - start] = curChildren;
        adults[curHr - start] = curAdults;
        seniors[curHr - start] = curSeniors;

        if (rowObj.avgs.count > maxCount) {
          maxCount = rowObj.avgs.count;
        }
        if (rowObj.avgs.men > maxMen) {
          maxMen = rowObj.avgs.men;
        }
        if (rowObj.avgs.women > maxWomen) {
          maxWomen = rowObj.avgs.women;
        }
        if (rowObj.avgs.children > maxChildren) {
          maxChildren = rowObj.avgs.children;
        }
        if (rowObj.avgs.adults > maxAdults) {
          maxAdults = rowObj.avgs.adults;
        }
        if (rowObj.avgs.seniors > maxSeniors) {
          maxSeniors = rowObj.avgs.seniors;
        }
      }
    }

    return [counts, men, women, children, adults, seniors, maxCount, maxMen, maxWomen, maxChildren, maxAdults, maxSeniors];
  }

  getTableRowsVehicles(data, location, locTimes) {
    var [counts, bikes, buses, cars, motorbikes, trucks] = [[], [], [], [], [], []];
    var [maxCount, maxBikes, maxBuses, maxCars, maxMotorbikes, maxTrucks, curHr, nextHr] = [0, 0, 0, 0, 0, 0, 0, 0];
    let [curHrString, hrString, nextHrStr] = ['', '', ''];

    const [startArray, endArray] = [[], []];
    const client = this.props.auth.user.client?.toLowerCase() ?? '';

    if (location.length > 0) {
      location.forEach(loc => {
        startArray.push(locTimes.start[loc]);
        endArray.push(locTimes.end[loc]);
      });
    }

    const minHr = Math.min(...startArray);
    const maxHr = endArray.reduce((a, b) => Math.max(a, b), -Infinity);

    let start = (minHr !== Infinity) ? minHr : (clientData[client]?.hrStart ?? 0);
    let end = (maxHr !== -Infinity) ? maxHr : (clientData[client]?.hrEnd ?? 24);

    for (let hr = 0; hr < end - start; hr++) {
      curHr = (hr + start);
      nextHr = curHr + 1;

      if (nextHr === 24) {
        nextHrStr = '12AM';
      } else if (nextHr >= 13) {
        nextHrStr = (nextHr - 12).toString() + 'PM';
      } else if (nextHr === 12) {
        nextHrStr = '12PM';
      } else {
        nextHrStr = nextHr.toString() + 'AM';
      }

      if (curHr >= 13) {
        curHrString = (curHr - 12).toString() + 'PM';
      } else if (curHr === 12) {
        curHrString = '12PM';
      } else if (curHr === 0) {
        curHrString = '12AM';
      } else {
        curHrString = curHr.toString() + 'AM';
      }

      hrString = curHrString + ' - ' + nextHrStr;
      counts[curHr - start] = this.createData(hrString, 0, 0, 0, 0, 0, 0, 0);
      bikes[curHr - start] = this.createData(hrString, 0, 0, 0, 0, 0, 0, 0);
      buses[curHr - start] = this.createData(hrString, 0, 0, 0, 0, 0, 0, 0);
      cars[curHr - start] = this.createData(hrString, 0, 0, 0, 0, 0, 0, 0);
      motorbikes[curHr - start] = this.createData(hrString, 0, 0, 0, 0, 0, 0, 0);
      trucks[curHr - start] = this.createData(hrString, 0, 0, 0, 0, 0, 0, 0);
    }

    var curCounts, curBikes, curBuses, curCars, curMotorbikes, curTrucks, rowObj, curDay;

    for (let d in data) {
      rowObj = data[d];
      curHr = rowObj.hour - 5;
      if (curHr < 0) {
        curHr = 24 + curHr;
      }

      if (curHr >= start && curHr < end) {
        curDay = weekdayLookup[rowObj.day];

        curCounts = counts[curHr - start];
        curBikes = bikes[curHr - start];
        curBuses = buses[curHr - start];
        curCars = cars[curHr - start];
        curMotorbikes = motorbikes[curHr - start];
        curTrucks = trucks[curHr - start];

        curCounts[curDay] = rowObj.avgs.count || 0;
        curBikes[curDay] = rowObj.avgs.bikes || 0;
        curBuses[curDay] = rowObj.avgs.buses || 0;
        curCars[curDay] = rowObj.avgs.cars || 0;
        curMotorbikes[curDay] = rowObj.avgs.motorbikes || 0;
        curTrucks[curDay] = rowObj.avgs.trucks || 0;

        counts[curHr - start] = curCounts;
        bikes[curHr - start] = curBikes;
        buses[curHr - start] = curBuses;
        cars[curHr - start] = curCars;
        motorbikes[curHr - start] = curMotorbikes;
        trucks[curHr - start] = curTrucks;

        if (rowObj.avgs.count > maxCount) {
          maxCount = rowObj.avgs.count;
        }
        if (rowObj.avgs.bikes > maxBikes) {
          maxBikes = rowObj.avgs.bikes;
        }
        if (rowObj.avgs.buses > maxBuses) {
          maxBuses = rowObj.avgs.buses;
        }
        if (rowObj.avgs.cars > maxCars) {
          maxCars = rowObj.avgs.cars;
        }
        if (rowObj.avgs.motorbikes > maxMotorbikes) {
          maxMotorbikes = rowObj.avgs.motorbikes;
        }
        if (rowObj.avgs.trucks > maxTrucks) {
          maxTrucks = rowObj.avgs.trucks;
        }
      }
    }

    return [counts, bikes, buses, cars, motorbikes, trucks, maxCount, maxBikes, maxBuses, maxCars, maxMotorbikes, maxTrucks];
  }
  

  fetchQuery = async (dateNum, locNum, dateLabel, locLabels) => {
    let byDateName = 'byDate_' + dateNum + '_' + locNum;
    let byTimeOfDayName = 'byTimeOfDay_' + dateNum + '_' + locNum;
    let byDayOfWeekName = 'byDayOfWeek_' + dateNum + '_' + locNum;
    let byDayHourName = 'byDayHour_' + dateNum + '_' + locNum;
    let dailyAveragesName = 'dailyAverages_' + dateNum + '_' + locNum;
    let curStartDate = this.state.startDate[dateNum - 1];
    let curEndDate = this.state.endDate[dateNum - 1];
    let numCombinedLocs = this.state.location[locNum - 1]?.length || 1;
    let aggType = (numCombinedLocs <= 1) ? 'single' : this.state.initlocationCard[locNum - 1]?.aggregationType ?? 'average';

    const blockedDaysLen = this.state.blockedDays[dateNum - 1].length;
    const excludeDatesLen = this.state.excludeDates[dateNum - 1].length;
    const vehicles = (this.state.locType === 'Vehicles') ? true : false;
    const vTypesObj = (vehicles) ? (this.state.initlocationCard[locNum - 1]?.checkedVehicleTypes ?? []) : [];

    let vFilter = [];
    if (vehicles && !Object.values(vTypesObj).every(item => item === true)) {
      vFilter = Object.keys(vTypesObj).filter(v => vTypesObj[v]).map(vt => '$' + vt);
    }

    const params = {
      startDate: curStartDate.clone().startOf('day').format(),
      endDate: curEndDate.clone().endOf('day').format(),
      days: (excludeDatesLen > 0)
        ? (curEndDate.diff(curStartDate, 'days') + 1) - excludeDatesLen
        : curEndDate.diff(curStartDate, 'days') + 1, // clone not necc, .diff doesn't mutate moment obj
      // location: this.state.location[locNum - 1],
      location: this.state.qLocation[locNum - 1],
      mode: (this.state.mode !== null && this.state.mode !== '') ? this.state.mode.toLowerCase() : 'traffic', // default to traffic if mode is empty
      estimate: this.state.simulatedData,
      blockedDays: this.state.blockedDays[dateNum - 1],
      excludeDates: this.state.excludeDates[dateNum - 1].map(d => d.clone().startOf('day').format()), // format all dates in array
      combineTotal: (this.state.initlocationCard[locNum - 1]?.aggregationType !== 'average') ? true : false, // true is Total, false is Average
      // level: this.state.level[locNum - 1],
      // sublocation: this.state.sublocation[locNum - 1],
      locType: this.state.locType,
      ...(vehicles) && { vFilter: vFilter }, // array of vehicle types, returned 'count' values will represent only the vehicle types in this array (defaults to all)
    };

    const getDayHour = async () => {
      try {
        const byDayHourResponse = await getData(API_URL + '/v2/traffic/byDayHourlyCombo', byDayHourName, params);

        let [peopleTableRows, vehicleTableRows, data, maxData] = [{}, {}, [], 0];
        if (!vehicles) {
          const [counts, men, women, children, adults, seniors, maxCount, maxMen, maxWomen, maxChildren, maxAdults, maxSeniors] = this.getTableRows(byDayHourResponse[0].byDayHourly, params.location, this.state.locationsObject.byHr);
          [data, maxData] = [counts, maxCount];
          peopleTableRows = { men, maxMen, women, maxWomen, children, maxChildren, adults, maxAdults, seniors, maxSeniors };
        }
        else {
          const [countsV, bikes, buses, cars, motorbikes, trucks, maxCountV, maxBikes, maxBuses, maxCars, maxMotorbikes, maxTrucks] = this.getTableRowsVehicles(byDayHourResponse[0].byDayHourly, params.location, this.state.locationsObject.byHr);
          [data, maxData] = [countsV, maxCountV];
          vehicleTableRows = { bikes, maxBikes, buses, maxBuses, cars, maxCars, motorbikes, maxMotorbikes, trucks, maxTrucks };
        }

        // const [hour, suffix] = getBusiestHour(byDayHourResponse);
        // this.setState(prevState => ({
        //   dailyAverages: prevState.dailyAverages.map(
        //     el => el.name === dailyAveragesName ? { ...el, busiestHour: hour, busiestHourSuffix: suffix } : el
        //   ),
        // }));

        this.setState(prevState => ({
          byDayHourData: prevState.byDayHourData.map(
            el => el.name === byDayHourName
              ? {
                  ...el,
                  data: data,
                  maxCount: maxData,
                  peopleTableRows,
                  vehicleTableRows,
                  dateLabel: dateLabel, locationLabels: locLabels, aggregationType: aggType
              }
              : el
          ),
        }))
      } catch (err) {
      }
    };

    const getDailyAverages = async () => {
      try {
        let start_c = curStartDate.clone().subtract(curEndDate.diff(curStartDate, 'days') + 1, 'days').startOf('day');
        start_c = (start_c.isBefore(this.state.dateRange.start)) ? this.state.dateRange.start : start_c; // if before start of data, set to data start date
        const end_c = curStartDate.clone().subtract(1, 'day').endOf('day'); // need endOf() bc cloning startDate
        const avgParams = { ...params, startDate_c: start_c.format(), endDate_c: end_c.format() }; // include start/end dates for prev date range to compare

        const dailyAveragesResponse = await getData(API_URL + '/v2/traffic/dailyAverages', dailyAveragesName, avgParams); // empty traffic response: dailyAveragesResponse: [{ ‘current’: [], ‘previous’: [] }]
        const [genderAvg, ageAvg, countAvg, vehicleAvg] = getDailyAvgData(dailyAveragesResponse, params.mode);
        const prevDateLabel = (params.mode === 'traffic' && dailyAveragesResponse[0]['previous'].length > 0) ? start_c.format('MMM D, YYYY') + ' - ' + end_c.format('MMM D, YYYY') : '-';

        this.setState(prevState => ({
          dailyAverages: prevState.dailyAverages.map(el =>
            el.name === dailyAveragesName ? {
              ...el,
              data: (params.mode === 'traffic') ? dailyAveragesResponse[0]['current'] : dailyAveragesResponse,
              ageAvg: ageAvg,
              countAvg: countAvg,
              genderAvg: genderAvg,
              vehicleAvg: vehicleAvg,
              dateLabel: dateLabel,
              prevDateLabel: prevDateLabel,
              locationLabels: locLabels,
              aggregationType: aggType
            } : el
          )
        }));
      } catch (err) {
      }
    };

    const getDataByDay = async () => {
      try {
        const response = await getData(API_URL + '/v2/traffic/byDay', byDateName, params);

        if (aggType === 'average' && params.mode === 'traffic') {
          if (!vehicles) {
            for (let d = 0; d < response.length; d++) {
              response[d].count = response[d].count / numCombinedLocs;
              response[d].men = response[d].men / numCombinedLocs;
              response[d].women = response[d].women / numCombinedLocs;
              response[d].children = response[d].children / numCombinedLocs;
              response[d].adults = response[d].adults / numCombinedLocs;
              response[d].seniors = response[d].seniors / numCombinedLocs;
            }
          }
          else {
            for (let d = 0; d < response.length; d++) {
              response[d].count = response[d].count / numCombinedLocs;
              response[d].bikes = response[d].bikes / numCombinedLocs;
              response[d].buses = response[d].buses / numCombinedLocs;
              response[d].cars = response[d].cars / numCombinedLocs;
              response[d].motorbikes = response[d].motorbikes / numCombinedLocs;
              response[d].trucks = response[d].trucks / numCombinedLocs;
            }
          }
        }
        const byDayDataResponse = (Object.keys(response).length === params.days)
          ? response
          : (blockedDaysLen > 0 || excludeDatesLen > 0)
            ? response
            : formatArray(response, 'day', curStartDate, curEndDate, [], '', vehicles);

        this.setState(prevState => ({
          byDateData: prevState.byDateData.map(
            el => el.name === byDateName ? { ...el, data: byDayDataResponse, dateLabel: dateLabel, locationLabels: locLabels, aggregationType: aggType  } : el
          ),
        }));
      } catch (err) {
      }
    };

    const getDataByWeek = async () => {
      try {
        const response = await getData(API_URL + '/v2/traffic/byWeek', byDateName, params);
        const numOfWeeks =  curEndDate.clone().startOf('isoWeek').diff(curStartDate.clone().startOf('isoWeek'), 'weeks');
        const byWeekDataResponse = (Object.keys(response).length === numOfWeeks + 1)
          ? response
          : (blockedDaysLen > 0 || excludeDatesLen > 0)
            ? response
            : formatArray(response, 'week', curStartDate, curEndDate, [], '', vehicles);

        for (var i in byWeekDataResponse) {
          byWeekDataResponse[i]._id['timeStamp'] = new Date(byWeekDataResponse[i]._id.date).getTime() / 1000;

          if (aggType === 'average' && params.mode === 'traffic') {
            if (!vehicles) {
              byWeekDataResponse[i].count = byWeekDataResponse[i].count / numCombinedLocs;
              byWeekDataResponse[i].men = byWeekDataResponse[i].men / numCombinedLocs;
              byWeekDataResponse[i].women = byWeekDataResponse[i].women / numCombinedLocs;
              byWeekDataResponse[i].children = byWeekDataResponse[i].children / numCombinedLocs;
              byWeekDataResponse[i].adults = byWeekDataResponse[i].adults / numCombinedLocs;
              byWeekDataResponse[i].seniors = byWeekDataResponse[i].seniors / numCombinedLocs;
            }
            else {
              byWeekDataResponse[i].count = byWeekDataResponse[i].count / numCombinedLocs;
              byWeekDataResponse[i].bikes = byWeekDataResponse[i].bikes / numCombinedLocs;
              byWeekDataResponse[i].buses = byWeekDataResponse[i].buses / numCombinedLocs;
              byWeekDataResponse[i].cars = byWeekDataResponse[i].cars / numCombinedLocs;
              byWeekDataResponse[i].motorbikes = byWeekDataResponse[i].motorbikes / numCombinedLocs;
              byWeekDataResponse[i].trucks = byWeekDataResponse[i].trucks / numCombinedLocs;
            }
          }
        }

        this.setState(prevState => ({
          byDateData: prevState.byDateData.map(
            el => el.name === byDateName ? { ...el, data: byWeekDataResponse, dateLabel: dateLabel, locationLabels: locLabels, aggregationType: aggType } : el
          ),
        }));
      } catch (err) {
      }
    };

    const getDataByMonth = async () => {
      try {
        const response = await getData(API_URL + '/v2/traffic/byMonth', byDateName, params);

        if (aggType === 'average' && params.mode === 'traffic') {
          if (!vehicles) {
            for (let d = 0; d < response.length; d++) {
              response[d].count = response[d].count / numCombinedLocs;
              response[d].men = response[d].men / numCombinedLocs;
              response[d].women = response[d].women / numCombinedLocs;
              response[d].children = response[d].children / numCombinedLocs;
              response[d].adults = response[d].adults / numCombinedLocs;
              response[d].seniors = response[d].seniors / numCombinedLocs;
            }
          }
          else {
            for (let d = 0; d < response.length; d++) {
              response[d].count = response[d].count / numCombinedLocs;
              response[d].bikes = response[d].bikes / numCombinedLocs;
              response[d].buses = response[d].buses / numCombinedLocs;
              response[d].cars = response[d].cars / numCombinedLocs;
              response[d].motorbikes = response[d].motorbikes / numCombinedLocs;
              response[d].trucks = response[d].trucks / numCombinedLocs;
            }
          }
        }
        const numOfMonths =  curEndDate.clone().startOf('month').diff(curStartDate.clone().startOf('month'), 'months');
        const byMonthDataResponse = (Object.keys(response).length === numOfMonths + 1)
          ? response
          : (blockedDaysLen > 0 || excludeDatesLen > 0)
            ? response
            : formatArray(response, 'month', curStartDate, curEndDate, [], '', vehicles);

        this.setState(prevState => ({
          byDateData: prevState.byDateData.map(
            el => el.name === byDateName ? { ...el, data: byMonthDataResponse, dateLabel: dateLabel, locationLabels: locLabels, aggregationType: aggType } : el
          ),
        }));
      } catch (err) {
      }
    };

    const getDataByYear = async () => {
      try {
        const response = await getData(API_URL + '/v2/traffic/byYear', byDateName, params);

        if (aggType === 'average' && params.mode === 'traffic') {
          if (!vehicles) {
            for (let d = 0; d < response.length; d++) {
              response[d].count = response[d].count / numCombinedLocs;
              response[d].men = response[d].men / numCombinedLocs;
              response[d].women = response[d].women / numCombinedLocs;
              response[d].children = response[d].children / numCombinedLocs;
              response[d].adults = response[d].adults / numCombinedLocs;
              response[d].seniors = response[d].seniors / numCombinedLocs;
            }
          }
          else {
            for (let d = 0; d < response.length; d++) {
              response[d].count = response[d].count / numCombinedLocs;
              response[d].bikes = response[d].bikes / numCombinedLocs;
              response[d].buses = response[d].buses / numCombinedLocs;
              response[d].cars = response[d].cars / numCombinedLocs;
              response[d].motorbikes = response[d].motorbikes / numCombinedLocs;
              response[d].trucks = response[d].trucks / numCombinedLocs;
            }
          }
        }
        const numOfYears = curEndDate.clone().startOf('year').diff(curStartDate.clone().startOf('year'), 'years'); // compare full years
        const byYearDataResponse = (Object.keys(response).length === numOfYears + 1)
          ? response
          : (blockedDaysLen > 0 || excludeDatesLen > 0)
            ? response
            : formatArray(response, 'year', curStartDate, curEndDate, [], '', vehicles);

        this.setState(prevState => ({
          byDateData: prevState.byDateData.map(
            el => el.name === byDateName ? { ...el, data: byYearDataResponse, dateLabel: dateLabel, locationLabels: locLabels, aggregationType: aggType } : el
          ),
        }));
      } catch (err) {
      }
    };

    const getDataByTime = async () => {
      try {
        // Returned data structures -- dwell: [{ '_id': {}, 'adults': x, }] -- traffic/conversion: [{ 'times': [{ '_id': {}, 'adults': x, }], 'totalCounts': { 'adults': x, } }]
        const byTimeResponse = await getData(API_URL + '/v2/traffic/byTime', 'byTime_' + dateNum + '_' + locNum, params);
        const [{ totalCounts, times: response }] = (params.mode === 'dwell') ? [{ times: byTimeResponse }] : byTimeResponse;
        const tc = (typeof totalCounts !== 'undefined' && totalCounts !== 0) ? totalCounts : (!vehicles)
          ? { 'men': 0, 'women': 0,'children': 0,'adults': 0,'seniors': 0, 'count': 0, 'est': false }
          : { 'bikes': 0, 'buses': 0,'cars': 0, 'motorbikes': 0, 'trucks': 0,'count': 0, 'est': false };

        if (aggType === 'average' && params.mode === 'traffic') {
          tc.count = tc.count / numCombinedLocs; // set avg for totalCounts.count (count is only value currently used)

          if (!vehicles) {
            for (let d = 0; d < response.length; d++) {
              response[d].count = response[d].count / numCombinedLocs;
              response[d].men = response[d].men / numCombinedLocs;
              response[d].women = response[d].women / numCombinedLocs;
              response[d].children = response[d].children / numCombinedLocs;
              response[d].adults = response[d].adults / numCombinedLocs;
              response[d].seniors = response[d].seniors / numCombinedLocs;
            }
          }
          else {
            for (let d = 0; d < response.length; d++) {
              response[d].count = response[d].count / numCombinedLocs;
              response[d].bikes = response[d].bikes / numCombinedLocs;
              response[d].buses = response[d].buses / numCombinedLocs;
              response[d].cars = response[d].cars / numCombinedLocs;
              response[d].motorbikes = response[d].motorbikes / numCombinedLocs;
              response[d].trucks = response[d].trucks / numCombinedLocs;
            }
          }
        }
        // var byTimeDataResponse = (Object.keys(response).length === 52 && this.props.auth.user.client !== 'urbanumbrella') ? response : formatArray(response, 'time', curStartDate, curEndDate, this.props.auth.user.client); // 52 15min block 9am-10pm
        var byTimeDataResponse = formatArray(response, 'time', curStartDate, curEndDate, params.location, this.state.locationsObject.byTime, vehicles);

        if (this.props.auth.user.client === 'Tavistock') {
          let tavistockResponse = [];
          for (let d = 0; d < byTimeDataResponse.length/4; d++) {
            tavistockResponse[d]=byTimeDataResponse[d*4];
          }
          byTimeDataResponse = tavistockResponse;
        }

        // convert times here before passing to tinyByTime and byTime charts 
        byTimeDataResponse.map((d) => {
          if (d._id && d._id.time) d._id.time = moment(d._id.time).valueOf(); // convert time str ('2019-01-01T14:00:00.000Z') to unix ms timestamp (1546351200000)
        });

        this.setState(prevState => ({
          byTimeOfDayData: prevState.byTimeOfDayData.map(
            el => el.name === byTimeOfDayName ? { ...el, data: byTimeDataResponse, dateLabel: dateLabel, locationLabels: locLabels, aggregationType: aggType, totalCounts: tc } : el
          ),
        }));
      } catch (err) {
      }
    };

    // const getDataByTimeDayType = async () => {
    //   try {
    //     const response = await getData(API_URL + '/v2/traffic/byTimeDayType', 'byTime_' + dateNum + '_' + locNum, params);
    //     if (aggType === 'average' && params.mode === 'traffic') {
    //       for (let d = 0; d < response.length; d++) {
    //         response[d].count = response[d].count / numCombinedLocs;
    //         response[d].men = response[d].men / numCombinedLocs;
    //         response[d].women = response[d].women / numCombinedLocs;
    //         response[d].children = response[d].children / numCombinedLocs;
    //         response[d].adults = response[d].adults / numCombinedLocs;
    //         response[d].seniors = response[d].seniors / numCombinedLocs;
    //       }
    //     }
    //     const byTimeData = [];
    //     byTimeData.weekday = [];
    //     byTimeData.weekend = [];

    //     response.forEach(function(e) {
    //       byTimeData[e._id.day].push(e);
    //     });
    //     byTimeData.weekday = (Object.keys(byTimeData.weekday).length === 52) ? byTimeData.weekday : formatArray(byTimeData.weekday, 'time'); // 52 15min block 9am-10pm
    //     byTimeData.weekend = (Object.keys(byTimeData.weekend).length === 52) ? byTimeData.weekend : formatArray(byTimeData.weekend, 'time'); // 52 15min block 9am-10pm

    //     this.setState(prevState => ({
    //       byTimeOfDayData: prevState.byTimeOfDayData.map(
    //         el => el.name === byTimeOfDayName ? { ...el, data: byTimeData, dateLabel: dateLabel, locationLabels: locLabels, aggregationType: aggType } : el
    //       ),
    //     }));
    //   } catch (err) {
    //   }
    // };

    const getDataByDayOfWeek = async () => {
      try {
        const response = await getData(API_URL + '/v2/traffic/byDayOfWeek', byDayOfWeekName, params);

        // if (aggType === 'average' && params.mode === 'traffic') {
        if (params.mode === 'traffic') {
          if (!vehicles) {
            for (let d = 0; d < response.length; d++) {
              response[d].count = (response[d].count / numCombinedLocs) * (params.location).length;
              response[d].men = response[d].men / numCombinedLocs * (params.location).length;
              response[d].women = response[d].women / numCombinedLocs * (params.location).length;
              response[d].children = response[d].children / numCombinedLocs * (params.location).length;
              response[d].adults = response[d].adults / numCombinedLocs * (params.location).length;
              response[d].seniors = response[d].seniors / numCombinedLocs * (params.location).length;
            }
          }
          else {
            for (let d = 0; d < response.length; d++) {
              response[d].count = response[d].count / numCombinedLocs;
              response[d].bikes = response[d].bikes / numCombinedLocs;
              response[d].buses = response[d].buses / numCombinedLocs;
              response[d].cars = response[d].cars / numCombinedLocs;
              response[d].motorbikes = response[d].motorbikes / numCombinedLocs;
              response[d].trucks = response[d].trucks / numCombinedLocs;
            }
          }
        }

        let allDays = [];
        let sundayObj = response.find(response => response._id.dayOfWeek === 1);
        let maxDayCount = typeof(sundayObj) === 'undefined' ? 0 : sundayObj.count;
        let maxMaleCount = typeof(sundayObj) === 'undefined' ? 0 : sundayObj.men;
        let maxFemaleCount = typeof(sundayObj) === 'undefined' ? 0 : sundayObj.women;
        let maxChildCount = typeof(sundayObj) === 'undefined' ? 0 : sundayObj.children;
        let maxSeniorCount = typeof(sundayObj) === 'undefined' ? 0 : sundayObj.seniors;
        let [maxDay, maxMaleDay, maxFemaleDay, maxChildDay, maxSeniorDay] = [1, 1, 1, 1, 1];

        for (let d = 2; d <= 7; d++) {
          let curObj = response.find(response => response._id.dayOfWeek === d);

          if (typeof curObj === 'undefined') {
            curObj = {};
          }
          else {
            if (curObj.count > maxDayCount) {
              maxDayCount = curObj.count;
              maxDay = d;
            }
            if (curObj.men > maxMaleCount) {
              maxMaleCount = curObj.men;
              maxMaleDay = d;
            }
            if (curObj.women > maxFemaleCount) {
              maxFemaleCount = curObj.women;
              maxFemaleDay = d;
            }
            if (curObj.children > maxChildCount) {
              maxChildCount = curObj.children;
              maxChildDay = d;
            }
            if (curObj.seniors > maxSeniorCount) {
              maxSeniorCount = curObj.seniors;
              maxSeniorDay = d;
            }
          }
          curObj.dayOfWeekName = days[d - 1];
          allDays.push(curObj);
        }

        if (typeof sundayObj === 'undefined') {
          sundayObj = {};
        }
        sundayObj.dayOfWeekName = 'Sunday';
        allDays.push(sundayObj);

        const byDayOfWeekDataResponse = allDays;
        const busiestObj = {
          busiestMaleDay: maxMaleDay,
          busiestFemaleDay: maxFemaleDay,
          busiestChildDay: maxChildDay,
          busiestSeniorDay: maxSeniorDay
        }

        if (!vehicles) {
          this.setState(prevState => ({
            dailyAverages: prevState.dailyAverages.map(
              el => el.name === dailyAveragesName ? { ...el, busiestDay: maxDay, busiestVehObj: {}, busiestObj } : el
            ),
          }));
        }
        else {
          const { maxBikeDay, maxBusDay, maxCarDay, maxMotorbikeDay, maxTruckDay } = getVehicleMaxDays(response);
          const busiestVehObj = { busiestBikeDay: maxBikeDay, busiestBusDay: maxBusDay, busiestCarDay: maxCarDay, busiestMotorbikeDay: maxMotorbikeDay, busiestTruckDay: maxTruckDay };

          this.setState(prevState => ({
            dailyAverages: prevState.dailyAverages.map(
              el => el.name === dailyAveragesName ? { ...el, busiestDay: maxDay, busiestVehObj, busiestObj: {} } : el
            ),
          }));
        }

        this.setState(prevState => ({
          byDayOfWeekData: prevState.byDayOfWeekData.map(
            el => el.name === byDayOfWeekName ? { ...el, data: byDayOfWeekDataResponse, dateLabel: dateLabel, locationLabels: locLabels, aggregationType: aggType } : el
          )
        }));
      } catch (err) {
      }
    };

    getDailyAverages();
    getDayHour();
    if (this.state.interval === 'Year') {
      getDataByYear();
    }
    else if (this.state.interval === 'Month') {
      getDataByMonth();
    }
    else if (this.state.interval === 'Week') {
      getDataByWeek();
    }
    else {
      getDataByDay();
    }
    
    getDataByDayOfWeek();
    getDataByTime();
    // getDataByTimeDayType();
    
  }

  byTimeMessageClose = event => {
    this.setState({ byTimeMessage: false });
  };

  reportViewToggle = async (event, value) => {
    const isReport = value.toLowerCase() === 'reports';

    if (isReport) {
      if (this.state.dateCard[1]) {
        this.closeDate(1, true); // close 2nd date card and open report mode
      }
      else {
        this.setState({ reportOpenIms: isReport, excludeDates: [[], []] }); // open report mode and reset excludeDates
      }
    }
    else {
      this.setState({
        reportOpenIms: isReport, // close report mode (back to dashboard)
        reportTab: 0,
        reportInterval: 3, // reset report tab and interval to custom
        reportHighlightsEdit: false, // reset report highlights
        reportHighlightsValue: '',
      }, () => {
        this.fetchQueries(); // update dashboard layout and data to match any date/location changes made in reports.
      });
    }
  }

  zoomIn = () => {
    const { zoomLevel } = this.state;
    let newZoom = zoomLevel
    if (newZoom < 2) {
      newZoom = newZoom + 0.1
    }
    this.setState({ zoomLevel: newZoom });
  }

  zoomOut = () => {
    const { zoomLevel } = this.state;
    let newZoom = zoomLevel
    if (newZoom > 0.2) {
      newZoom = newZoom - 0.1
    }
    this.setState({ zoomLevel: newZoom });
  }

  apiSavePdf = async (e) => {
    try {
      e.preventDefault();
      const { client, roles } = this.props.auth.user;
      const { dateRange, endDate, interval, locationLabels, qLocation, reportHighlightsEdit, reportHighlightsValue, reportInterval, simulatedData, startDate  } = this.state;

      // don't need this anymore, start/end date from state should be correct.
      // const getDate = (reportInterval < 3)
      //   ? getReportDates(endDate[0].clone(), reportInterval, dateRange)
      //   : { start: startDate[0].clone(), end: endDate[0].clone() };

      const pdfObj = {
        reportEstimate: simulatedData,
        // reportStartDate: getDate.start.format(),
        // reportEndDate: getDate.end.format(),
        reportStartDate: startDate[0].clone().format(),
        reportEndDate: endDate[0].clone().format(),
        reportByWeek: reportInterval, // 1 (week), 2 (month), 3 (custom)
        qLocation: qLocation,
        labels: locationLabels,
        sDateData: dateRange.start.clone().format(),

        demo: (this.state.locType === 'Vehicles') ? false : this.state.reportDemo,
        change: this.state.reportChange,
        detailed: this.state.reportDetailed,
        locType: this.state.locType,
        highlights: (reportHighlightsEdit) ? reportHighlightsValue : '',
        dateInterval: interval, // 'Day', 'Week', 'Month', 'Year'

        adminClient: (roles?.includes('user_manager')) ? (client || 'all') : '', // Admin only - pass current value of client to api for report
      }

      this.setState({ isLoading: true }, () => {
        apiGetPdfReport(pdfObj).then(() => {

          this.setState({
            isLoading: false,
            reportHighlightsEdit: false, // reset report highlights
            reportHighlightsValue: ''
          });
        }).catch((err) => {

          this.setState({ isLoading: false });
        });
      });

    } catch (error) {
      this.setState({ isLoading: false });
      // console.log('apiSavePdf error: ');
      // console.log(error);
    }
  }

  render() {
    const { classes } = this.props;
    const { client } = this.props.auth.user;
    const { locationsObject, videoDash, windowHeight, windowWidth} = this.state;

    const isVehicle = (this.state.locType === 'Vehicles') ? true : false;
    const vehicleLocations = (clientData[client.toLowerCase()]?.vehicleLocations && locationsObject.locationTypes?.Vehicles) ? true : false;

    let isOccupancy = false;
    const locsArray = Object.values(this.state.location).flat();
    if (locsArray.length > 0) {
      locsArray.forEach(loc => {
        if (locationsObject.occupancy[loc] === true) isOccupancy = true;
      });
    }

    return (  
      <Fragment>
        <Navbar locationsObject={locationsObject} onUpdate={this.toggleVideo} videoToggle={videoDash} />
        { videoDash && 
          <Grid container style={{ height: windowWidth > 400 ? 'calc(100vh - 50px)' : 'calc(100vh - 132px)', width: '100vw' }}>
            <VideoDashboard />
          </Grid>
        }
        { !videoDash && 
        <AppBar position={windowHeight > CELLWIDTH ? 'sticky' : ''} className={classes.filterPanel} id={'headerId'}>

          <Grid container item xs={12} direction='row' justifyContent='flex-start' alignItems='center' style={{ backgroundColor: this.state.reportOpenIms ? '#667e87' : '#D3DBDE', zIndex: 2, paddingTop: 9, paddingBottom: 20, paddingRight: 'calc(3px + 0.4vw)' }}>
            <Grid container item xs={(this.state.retailDash || vehicleLocations) ? 10 : 11}>
              <Toolbar variant='dense' className={classes.filterItem} style={{ marginTop: 10, zIndex: 2, }}>

                <CalIcon className={classes.mainIcon} color='primary' style={{ display: 'inline' }} />
                <Tooltip title='Change Date(s)'>
                  <Grid item style={{ minWidth: 125, display: 'inline' }}>
                    <PresetDateRangePicker
                      dateRange={this.state.dateRange}
                      initialStartDate={this.state.startDate[0]}
                      initialEndDate={this.state.endDate[0]}
                      onChange={this.changedDateCard(0)}
                      startDateId={'0'}
                      name={0}
                      presets={this.state.datePresets}
                      closeDate={this.closeDate}
                      simulated={this.state.simulatedData}
                      toggleSimulated={this.toggleSimulated}
                      compareDateClick={this.comparePrevYear}
                      compareActiveDate={this.state.compareActiveDate}
                      panelColor={this.state.reportOpenIms ? '#667e87' : '#D3DBDE'}
                      css={() => {}}
                      styles={{}}
                      theme={{}}
                      reportsActive={this.state.reportOpenIms}
                      reportsTab={this.state.reportTab}
                      trimDates={clientData[client.toLowerCase()]?.trimDates}
                      prevYearActive={(this.state.startDate[0].clone().subtract(1, 'year').isSameOrAfter(this.state.dateRange.start) && !this.state.reportOpenIms)}
                      excludeDates={this.state.excludeDates[0]}
                      excludeDatesChange={this.dateCardExcludeDate(0)}
                    />
                  </Grid>
                </Tooltip>

                <Grid style={{ display: this.state.dateCard[1] ? 'inline' : 'none', color: '#00313D' }}>
                  <CompareIcon className={classes.compareStyle} color='primary' style={{ display: 'inline' }} />
                </Grid>

                <Tooltip title='Add Date(s)'>
                  <Button
                    variant='contained'
                    aria-label='Add'
                    className='changeButton btn btn-medium waves-effect waves-light hoverable accent-3'
                    style={{ textTransform: 'none', color: (this.state.compareActiveDate && !this.state.reportOpenIms) ? '#02313D' : '#4d6e77', fontWeight: 600, lineHeight: '115%', paddingLeft: 12, paddingRight: 8, backgroundColor: (this.state.compareActiveDate && !this.state.reportOpenIms) ? '#f5f5f5' : '#B3C0C4', fontSize: 'calc(7px + 0.2vw)', display: this.state.dateCard[1] ? 'none' : '', height: 44, marginLeft: '20px', marginRight: '60px' }}
                    onClick={this.getNextDateCard(1)}
                    disabled={(this.state.compareActiveDate && !this.state.reportOpenIms) ? false : true}>
                    DATE(S)
                    <AddIcon style={{ width: 'calc(8px + 0.4vw)', paddingBottom: '0.07em', paddingLeft: '0.1vw', display: 'inline' }} />
                  </Button>
                </Tooltip>

                <Collapse in={this.state.dateCard[1]} style={{ display: this.state.dateCard[1] ? 'inline' : 'none' }}>
                  {this.state.dateCard[1] &&
                    <Grid item style={{ minWidth: 125 }}>
                      <PresetDateRangePicker
                        dateRange={this.state.dateRange}
                        initialStartDate={this.state.startDate[1]}
                        initialEndDate={this.state.endDate[1]}
                        onChange={this.changedDateCard(1)}
                        startDateId={'1'}
                        name={1}
                        presets={this.state.datePresets}
                        closeDate={this.closeDate}
                        closeBtn={true}
                        selectRange={this.state.endDate[0].diff(this.state.startDate[0], 'days')}
                        simulated={this.state.simulatedData}
                        toggleSimulated={this.toggleSimulated}
                        panelColor={this.state.reportOpenIms ? '#667e87' : '#D3DBDE'}
                        css={() => { }}
                        styles={{}}
                        theme={{}}
                        excludeDates={this.state.excludeDates[1]}
                        excludeDatesChange={this.dateCardExcludeDate(1)}
                      />
                    </Grid>
                  }
                </Collapse>
              </Toolbar>

              <Toolbar variant='dense' className={classes.filterItem} style={{ marginTop: 4 }}>

                <LocIcon className={classes.mainIcon} color='primary' style={{ display: '', marginTop: 6 }} />
                <Grid container alignItems='center'>
                  {this.state.locationCard.map((l, index) => (

                    <Fragment key={'lc' + index}>
                      <Tooltip title='Compared With' placement='top'>
                        <CompareIcon className={classes.compareStyle} color='primary' style={{ marginTop: 6, display: (index > 0 && this.state.locationCard[index] && this.state.locationCard.slice(0, index).length > 0) ? 'inline' : 'none' }} />
                      </Tooltip>

                      <Collapse in={typeof (this.state.locationCard[index]) !== 'undefined'} style={{ marginTop: 6, display: typeof (this.state.locationCard[index]) !== 'undefined' && this.state.mode !== 'Conversion' ? '' : 'none' }}>


                        {vehicleLocations &&
                          <LocationCard2
                            client={client.toLowerCase()}
                            closeLocation={this.closeLocation}
                            initlocationCard={this.state.initlocationCard[index]}
                            cardColor={getColor('-', index, locationsObject, this.state.byDateData)}
                            locationsObject={locationsObject}
                            name={index}
                            noCard={this.state.noCard}
                            onChange={this.changedLocationCard}
                            showCombos={true}
                            showX={!this.state.noCard}
                            locFilter={this.state.locType} />
                        }


                        {!vehicleLocations && (client.toLowerCase() !== 'jemb' && client.toLowerCase() !== 'bpc' && client.toLowerCase() !== 'cw' && client.toLowerCase() !== 'hsbid' && client.toLowerCase() !== 'va') &&
                          <LocationCard
                            client={client.toLowerCase()}
                            closeLocation={this.closeLocation}
                            initlocationCard={this.state.initlocationCard[index]}
                            cardColor={getColor('-', index, locationsObject, this.state.byDateData)}
                            locationsObject={locationsObject}
                            name={index}
                            noCard={this.state.noCard}
                            onChange={this.changedLocationCard}
                            showCombos={true}
                            showX={!this.state.noCard} />
                        }


                        {!vehicleLocations && (client.toLowerCase() === 'jemb' || client.toLowerCase() === 'bpc' || client.toLowerCase() === 'cw' || client.toLowerCase() === 'hsbid' || client.toLowerCase() === 'va') &&
                          <LocationCard3
                            client={client.toLowerCase()}
                            closeLocation={this.closeLocation}
                            initlocationCard={this.state.initlocationCard[index]}
                            cardColor={getColor('-', index, locationsObject, this.state.byDateData)}
                            locationsObject={locationsObject}
                            name={index}
                            noCard={this.state.noCard}
                            onChange={this.changedLocationCard}
                            showCombos={true}
                            showX={!this.state.noCard} />
                        }


                      </Collapse>

                      {this.state.mode === 'Conversion' &&
                        <Collapse in={typeof (this.state.locationCard[index]) !== 'undefined'} style={{ marginTop: 6, display: typeof (this.state.locationCard[index]) !== 'undefined' && this.state.mode === 'Conversion' ? '' : 'none' }}>
                          <LocationConvCard
                            closeLocation={this.closeLocation}
                            initlocationCard={this.state.initlocationCard[index]}
                            locationsObject={locationsObject}
                            name={index}
                            noCard={this.state.noCard}
                            onChange={this.changedLocationCard}
                            showX={!this.state.noCard} />
                        </Collapse>
                      }
                    </Fragment>
                  ))}

                  <Grid style={{ marginLeft: '20px', height: 44, marginTop: 6 }}>
                    <ButtonGroup variant='contained' aria-label='split button' style={{ height: '100%' }}>
                      <Tooltip title='Add Location' placement='top'>
                        <Button style={{ backgroundColor: this.state.compareActiveLoc || this.state.noCard ? '#f5f5f5' : '#B3C0C4', padding: 0, display: this.state.mode === 'Conversion' ? 'none' : '' }} disabled={this.state.compareActiveLoc || this.state.noCard ? false : true}>


                          {vehicleLocations &&
                            <LocationCompare2
                              client={client.toLowerCase()}
                              compareActiveLoc={this.state.compareActiveLoc}
                              locationsObject={locationsObject}
                              name={(this.state.noCard) ? 0 : Math.max(...Object.values(this.state.locationCard)) + 1}
                              noCard={this.state.noCard}
                              onChange={this.changedLocationCard}
                              showCombos={true}
                              locFilter={this.state.locType}
                              initlocationCard={this.state.initlocationCard} />
                          }


                          {!vehicleLocations && (client.toLowerCase() !== 'jemb' && client.toLowerCase() !== 'bpc' && client.toLowerCase() !== 'cw' && client.toLowerCase() !== 'hsbid' && client.toLowerCase() !== 'va') &&
                            <LocationCompare
                              client={client.toLowerCase()}
                              compareActiveLoc={this.state.compareActiveLoc}
                              locationsObject={locationsObject}
                              name={(this.state.noCard) ? 0 : Math.max(...Object.values(this.state.locationCard)) + 1}
                              noCard={this.state.noCard}
                              onChange={this.changedLocationCard}
                              showCombos={true} />
                          }


                          {!vehicleLocations && (client.toLowerCase() === 'jemb' || client.toLowerCase() === 'bpc' || client.toLowerCase() === 'cw' || client.toLowerCase() === 'hsbid' || client.toLowerCase() === 'va') &&
                            <LocationCompare3
                              client={client.toLowerCase()}
                              compareActiveLoc={this.state.compareActiveLoc}
                              locationsObject={locationsObject}
                              name={(this.state.noCard) ? 0 : Math.max(...Object.values(this.state.locationCard)) + 1}
                              noCard={this.state.noCard}
                              onChange={this.changedLocationCard}
                              showCombos={true} />
                          }


                        </Button>
                      </Tooltip>

                      <Tooltip title='Multi-Compare' placement='top'>
                        <Button style={{ backgroundColor: (!isVehicle && (this.state.compareActiveLoc || this.state.noCard)) ? '#f5f5f5' : '#B3C0C4', padding: 0, display: this.state.mode === 'Conversion' ? 'none' : '' }} disabled={(!isVehicle && (this.state.compareActiveLoc || this.state.noCard)) ? false : true}>
                          <LocationMultiCompare
                            closeAllLocations={this.closeAllLocations}
                            compareActiveLoc={this.state.compareActiveLoc}
                            locationsObject={locationsObject}
                            name={(this.state.noCard) ? 0 : Math.max(...Object.values(this.state.locationCard)) + 1}
                            noCard={this.state.noCard}
                            locFilter={this.state.locType}
                            onChange={this.changedLocationCard} />
                        </Button>
                      </Tooltip>

                      <Tooltip title='Category Compare' placement='top'>
                        <Button style={{ backgroundColor: (!isVehicle && (this.state.compareActiveLoc || this.state.noCard)) ? '#f5f5f5' : '#B3C0C4', padding: 0, display: this.state.mode === 'Conversion' ? 'none' : '' }} disabled={(!isVehicle && (this.state.compareActiveLoc || this.state.noCard)) ? false : true}>
                          <LocationCategoryCompare
                            closeAllLocations={this.closeAllLocations}
                            compareActiveLoc={this.state.compareActiveLoc}
                            locationsObject={locationsObject}
                            name={(this.state.noCard) ? 0 : Math.max(...Object.values(this.state.locationCard)) + 1}
                            noCard={this.state.noCard}
                            locFilter={this.state.locType}
                            onChange={this.changedLocationCard} />
                        </Button>
                      </Tooltip>

                      <Tooltip title='Add Location' placement='top'>
                        <Button style={{ backgroundColor: this.state.compareActiveLoc || this.state.noCard ? '#f5f5f5' : '#B3C0C4', padding: 0, display: this.state.mode === 'Conversion' ? '' : 'none' }} disabled={this.state.compareActiveLoc || this.state.noCard ? false : true}>
                          <LocationConvCompare
                            compareActiveLoc={this.state.compareActiveLoc}
                            locationsObject={locationsObject}
                            name={(this.state.noCard) ? 0 : Math.max(...Object.values(this.state.locationCard)) + 1}
                            noCard={this.state.noCard}
                            onChange={this.changedLocationCard} />
                        </Button>
                      </Tooltip>

                      <Tooltip title='Clear All' placement='top'>
                        <Button
                          variant='contained'
                          aria-label='Clear'
                          size='small'
                          disabled={this.state.noCard ? true : false}
                          className='changeButton btn btn-medium waves-effect waves-light hoverable accent-3'
                          onClick={this.closeAllLocations}
                          style={{ marginLeft: 3, fontSize: 'calc(7px + 0.2vw)', height: '100%', color: '#02313D', backgroundColor: !this.state.noCard ? '#f5f5f5' : '#B3C0C4' }}>
                          <LayersClearRoundedIcon style={{ width: 22, display: 'inline' }} />
                        </Button>
                      </Tooltip>
                    </ButtonGroup>
                  </Grid>

                  {/* <Grid container style={{ marginLeft: 4, height: 44, marginTop: 6, width: 44 }}>
                    <Button variant='contained' aria-label='Clear' size='small'
                      className='changeButton btn btn-medium waves-effect waves-light hoverable accent-3'
                      onClick={this.closeAllLocations} style={{ minWidth: 44, paddingLeft: 0, paddingRight: 0, fontSize: 'calc(7px + 0.2vw)', height: '100%', color: '#02313D', backgroundColor: '#f5f5f5' }}>
                      <LayersClearRoundedIcon style={{ width: 22, display: 'inline' }} />
                    </Button>
                  </Grid>
                  <Button variant='contained' aria-label='Add' disabled={this.state.compareActiveLoc ? false : true}
                    className='changeButton btn btn-medium waves-effect waves-light hoverable accent-3'
                    onClick={this.getNextLocationCard()} style={{ marginTop: 6, color: this.state.compareActiveLoc ? '#02313D' : '#4d6e77', fontWeight: 600, lineHeight: '115%', paddingLeft: 7, paddingRight: 3, fontSize: 'calc(7px + 0.2vw)', backgroundColor: this.state.compareActiveLoc ? '#f5f5f5' : '#B3C0C4', display: '', height: 44, marginLeft: '20px' }}>
                    LOCATION
                    <AddIcon style={{ width: 'calc(8px + 0.4vw)', paddingBottom: '0.07em', paddingLeft: '0.1vw', display: '' }} />
                  </Button> */}

                </Grid>
              </Toolbar>
            </Grid>

            <Grid container item xs={(this.state.retailDash || vehicleLocations) ? 2 : 1}>
              <Grid container item xs={12} alignItems='center' spacing={1} style={{ zIndex: 3 }}>

                <Grid container item xs={vehicleLocations ? 6 : false} style={{ maxWidth: 'none', display: (vehicleLocations) ? '' : 'none' }} alignItems='center' justifyContent='flex-end'>
                  <Tooltip title={this.state.reportOpenIms ? 'Not available on reports.' : ''}>
                    <ToggleButtonGroup
                      orientation='vertical'
                      value={this.state.locType}
                      size='large'
                      exclusive
                      style={{ display: vehicleLocations ? '' : 'none' }}
                      onChange={this.changeLocationType}
                      className='changeButton btn btn-medium waves-effect waves-light hoverable accent-3'
                    >
                      <ToggleButton disabled={this.state.reportOpenIms || clientData[client.toLowerCase()]?.onlyVehicles} value='People' size='small' style={{ textTransform: 'none', fontSize: 'calc(8px + 0.2vw)', fontWeight: 500, lineHeight: '85%', color: this.state.locType === 'People' ? 'white' : ((clientData[client.toLowerCase()]?.onlyVehicles) ? '#0000001f' : '#00313D'), backgroundColor: this.state.locType === 'People' ? '#325A63' : '' }}>
                        People
                      </ToggleButton>
                      <ToggleButton disabled={this.state.reportOpenIms} value='Vehicles' size='small' style={{ textTransform: 'none', fontSize: 'calc(8px + 0.2vw)', fontWeight: 500, lineHeight: '85%', color: (isVehicle) ? 'white' : '#00313D', backgroundColor: (isVehicle) ? '#325A63' : '' }}>
                        Vehicles
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Tooltip>
                </Grid>

                <Grid container item xs={this.state.retailDash ? 6 : false} style={{ maxWidth: 'none', display: (this.state.retailDash) ? '' : 'none' }} alignItems='center' justifyContent='flex-end'>
                  <Tooltip title={this.state.reportOpenIms ? 'Not available on reports.' : ''}>
                    <ToggleButtonGroup
                      orientation='vertical'
                      value={this.state.mode}
                      size='large'
                      exclusive
                      style={{ display: this.state.retailDash ? '' : 'none' }}
                      onChange={this.changeMode}
                      className='changeButton btn btn-medium waves-effect waves-light hoverable accent-3'
                    >
                      <ToggleButton disabled={this.state.reportOpenIms} value='Traffic' size='small' style={{ textTransform: 'none', fontSize: 'calc(8px + 0.2vw)', fontWeight: 500, lineHeight: '85%', color: this.state.mode === 'Traffic' ? 'white' : '#00313D', backgroundColor: this.state.mode === 'Traffic' ? '#325A63' : '' }}>
                        Traffic
                      </ToggleButton>
                      <ToggleButton disabled={this.state.reportOpenIms} value='Dwell' size='small' style={{ textTransform: 'none', fontSize: 'calc(8px + 0.2vw)', fontWeight: 500, lineHeight: '85%', color: this.state.mode === 'Dwell' ? 'white' : '#00313D', backgroundColor: this.state.mode === 'Dwell' ? '#325A63' : '' }}>
                        Dwell
                      </ToggleButton>
                      <ToggleButton disabled={this.state.reportOpenIms} value='Conversion' size='small' style={{ textTransform: 'none', fontSize: 'calc(8px + 0.2vw)', fontWeight: 500, lineHeight: '85%', color: this.state.mode === 'Conversion' ? 'white' : '#00313D', backgroundColor: this.state.mode === 'Conversion' ? '#325A63' : '' }}>
                        Capture
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Tooltip>
                </Grid>

                <Grid container item xs={(this.state.retailDash || vehicleLocations) ? 6 : 12} style={{ maxWidth: 'none' }} alignItems='center' justifyContent='flex-end'>
                  {clientData[client.toLowerCase()]?.reports?.newReports &&
                    <Tooltip title={isVehicle ? 'Not available for vehicles.' : (this.state.mode !== 'Traffic') ? 'Only available for traffic.' : ''}>
                      <ToggleButtonGroup
                        orientation='vertical'
                        value={this.state.mode}
                        size='large'
                        exclusive
                        style={{ backgroundColor: (this.state.mode !== 'Traffic' || isVehicle) ? '#bdbdbd' : '#D3DBDE' }}
                        onChange={this.reportViewToggle}
                        className='changeButton btn btn-medium waves-effect waves-light hoverable accent-3'
                      >
                        <ToggleButton disabled={(this.state.mode !== 'Traffic' || isVehicle) ? false : false} value='Dashboard' size='small' style={{ textTransform: 'none', fontSize: 'calc(8px + 0.2vw)', fontWeight: 500, lineHeight: '150%', color: !this.state.reportOpenIms ? 'white' : '#00313D', backgroundColor: (!this.state.reportOpenIms) ? (this.state.mode === 'Traffic' && !isVehicle) ? '#325A63' : '#424242' : '' }}>
                          Dashboard
                        </ToggleButton>
                        <ToggleButton disabled={(this.state.mode !== 'Traffic' || isVehicle) ? false : false} value='Reports' size='small' style={{ textTransform: 'none', fontSize: 'calc(8px + 0.2vw)', fontWeight: 500, lineHeight: '150%', color: this.state.reportOpenIms ? 'white' : '#00313D', backgroundColor: (this.state.reportOpenIms) ? (this.state.mode === 'Traffic' && !isVehicle) ? '#325A63' : '#e0e0e0' : '' }}>
                          Reports
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Tooltip>
                  }
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {!this.state.reportOpenIms &&
            <Tabs
              value={this.state.value}
              onChange={this.handleChange}
              indicatorColor='primary'
              textColor='primary'
              variant='fullWidth'
              classes={{ root: classes.tabsS }}
            >
              <Tab classes={{ root: classes.tabS, selected: classes.tabSelected }} className={classes.tabStyle} label='Counts' />
              <Tab classes={{ root: classes.tabS, selected: classes.tabSelected }} className={classes.tabStyle} label={(!isVehicle) ? 'Gender' : 'Vehicle Types'} />
              {!isVehicle &&
                <Tab classes={{ root: classes.tabS, selected: classes.tabSelected }} className={classes.tabStyle} label='Age' />
              }
            </Tabs>
          }

          {this.state.reportOpenIms &&
            <Tabs
              value={this.state.reportTab}
              onChange={this.handleReportTabChange}
              variant='fullWidth'
              classes={{ root: classes.tabsSrep }}
              TabIndicatorProps={{ style: { background: '#EDF5F8' } }}
            >
              <Tab classes={{ root: classes.tabSrep, selected: classes.tabSelectedRep }} className={classes.tabStyleRep} label='Custom' />
              <Tab classes={{ root: classes.tabSrep, selected: classes.tabSelectedRep }} className={classes.tabStyleRep} label='Weekly' />
              <Tab classes={{ root: classes.tabSrep, selected: classes.tabSelectedRep }} className={classes.tabStyleRep} label='Monthly' />
            </Tabs>
          }

          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            style={{ marginBottom: '35vh', marginLeft: '5vw' }}
            open={this.state.byTimeMessage && this.state.mode.toLowerCase() === 'traffic'}
            transitionDuration={{ enter: 1000, exit: 1000 }}
            autoHideDuration={3000}
            onClose={this.byTimeMessageClose}
          >
            <SnackbarContent
              style={{ backgroundColor: 'rgba(0, 0, 0, 0.4)' }}
              message={
                <Typography style={{ fontSize: 'calc(10px + 1vw)' }}>
                  Click on the chart to select starting and ending hours
                </Typography>
              }>
            </SnackbarContent>
          </Snackbar>

          {this.state.reportOpenIms &&
            <Fragment>
              <Tooltip title='Save PDF'>
                <Button aria-label='Save PDF' color='secondary' onClick={this.apiSavePdf} style={{ position: 'fixed', bottom: 'calc(4px + 1vw)', right: 'calc(4px + 1.5vw)', zIndex: 1000 }}>
                  {this.state.isLoading
                    ? <CircularProgress color='secondary' size={'calc(8px + 3vw)'} style={{}} />
                    : <SaveIcon style={{ color: '#AFD8D0', height: 'calc(8px + 3vw)', width: 'calc(8px + 3vw)' }} />
                  }
                </Button>
              </Tooltip>

              <Grid container>
                <AppBar position='static' open={this.state.reportDrawerOpen} style={{ backgroundColor: '#212121', zIndex: 1 }}>
                  <Toolbar>
                    <IconButton
                      color='inherit'
                      aria-label='open drawer'
                      onClick={this.handleDrawerToggle}
                      edge='start'
                    >
                      <MenuIcon />
                    </IconButton>

                    <Grid container direction='row' justifyContent='center' alignItems='center' style={{ width: '100%' }}>
                      <FormControlLabel style={{ color: '#7A9D96'}}
                        control={
                          <Switch
                            // disabled= {client.toLowerCase() === 'jemb' || isVehicle}
                            checked={this.state.reportDemo}
                            onChange={this.toggleReportSwitch}
                            name='reportDemo'
                            color='primary'
                            sx={{
                              '& .MuiSwitch-track': { backgroundColor: '#efefefff' },
                              '& .MuiSwitch-switchBase.Mui-checked': {
                                color: '#277175',
                                '&:hover': {
                                  backgroundColor: alpha('#277175', 0.4),
                                },
                              },
                              '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                backgroundColor: '#277175'
                              },
                              // '& .MuiSwitch-switchBase.Mui-disabled': { color: 'red' }
                            }}
                          />
                        }
                        label={<Typography>Demographics</Typography>}
                      />

                      <FormControlLabel style={{ marginLeft: 20, color: '#7A9D96' }}
                        control={
                          <Switch
                            checked={this.state.reportChange}
                            onChange={this.toggleReportSwitch}
                            name='reportChange'
                            color='primary'
                            sx={{
                              '& .MuiSwitch-track': { backgroundColor: '#efefefff' },
                              '& .MuiSwitch-switchBase.Mui-checked': {
                                color: '#277175',
                                '&:hover': {
                                  backgroundColor: alpha('#277175', 0.4),
                                },
                              },
                              '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                backgroundColor: '#277175'
                              }
                            }}
                          />
                        }
                        label={<Typography>Change</Typography>}
                      />

                      <FormControlLabel style={{ marginLeft: 20, color: '#7A9D96' }}
                        control={
                          <Switch
                            checked={this.state.reportDetailed}
                            onChange={this.toggleReportSwitch}
                            name='reportDetailed'
                            color='primary'
                            sx={{
                              '& .MuiSwitch-track': { backgroundColor: '#efefefff' },
                              '& .MuiSwitch-switchBase.Mui-checked': {
                                color: '#277175',
                                '&:hover': {
                                  backgroundColor: alpha('#277175', 0.4),
                                },
                              },
                              '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                backgroundColor: '#277175'
                              }
                            }}
                          />
                        }
                        label={<Typography>Detailed</Typography>}
                      />
                    </Grid>
                  </Toolbar>
                  
                </AppBar>

                <Drawer
                  classes={{ paper: classes.reportDrawerS }}
                  variant='persistent'
                  anchor='left'
                  open={this.state.reportDrawerOpen}
                >
                  <Grid container direction='column' justifyContent='flex-start' alignItems='center' style={{ marginTop: 0, transform: 'scale(.15,.15)', transformOrigin: '28% 90px', height: '100%', width: '15vw', position: 'relative', paddingTop: 15, paddingBottom: 0 }} >
                    {this.state.reportDrawerOpen &&
                      <CreateReport
                        key={this.state.reportKey}
                        dashboard={true}
                        startDate={this.state.startDate[0].clone()}
                        endDate={this.state.endDate[0].clone()}
                        dateRangeStart={this.state.dateRange.start}
                        reportEstimate={this.state.simulatedData}
                        reportLocations={this.state.qLocation}
                        chartLabels={this.state.locationLabels}
                        reportByWeek={(this.state.reportTab === 0) ? 3 : this.state.reportTab}
                        locType={this.state.locType}
                        demo={this.state.reportDemo}
                        change={this.state.reportChange}
                        detailed={this.state.reportDetailed}
                        reportHighlightsChanged={this.reportHighlightsChanged}
                        dateInterval={this.state.interval}
                      />
                    }
                  </Grid>
                </Drawer>

                <Tooltip title='Zoom In'>
                  <Button aria-label='Zoom In PDF' color='secondary' onClick={this.zoomIn} style={{ position: 'fixed', bottom: 'calc(8px + 34vw)', right: 'calc(4px + 1.5vw)', zIndex: 1000 }}>
                    <AddCircleIcon style={{ color: '#AFD8D0', height: 'calc(6px + 2vw)', width: 'calc(8px + 3vw)' }} />
                  </Button>
                </Tooltip>

                <Tooltip title='Zoom Out'>
                  <Button aria-label='Zoom In Out' color='secondary' onClick={this.zoomOut} style={{ position: 'fixed', bottom: 'calc(8px + 30vw)', right: 'calc(4px + 1.5vw)', zIndex: 1000 }}>
                    <MinusCircleIcon style={{ color: '#AFD8D0', height: 'calc(6px + 2vw)', width: 'calc(8px + 3vw)' }} />
                  </Button>
                </Tooltip>
              </Grid>
            </Fragment>
          }
        </AppBar>
        }
        { !videoDash && this.state.reportOpenIms &&
          <SwipeableViews
            axis='x'
            index={this.state.reportTab}
            onChangeIndex={this.handleReportTabChangeSwipe}
            slideStyle={{ overflow: 'hidden', zIndex: 1200 }}
            className={classes.bkgrnd} style={{ backgroundColor: '#757575', height: '100%' }}>

            <TabPanel index={0} value={this.state.reportTab} style={{ backgroundColor: '#757575', minHeight: '80vh', height: '100%' }}>
              <Grid container direction='column' justifyContent='flex-start' alignItems='center' style={{ marginLeft: this.state.reportDrawerOpen ? '7vw' : 0, marginTop: 0, transform: 'scale(' + this.state.zoomLevel.toFixed(1) + ',' + this.state.zoomLevel.toFixed(1) + ')', transformOrigin: '50% 0', height: '100%', position: 'relative', paddingTop: 15, paddingBottom: 0 }} >
                <CreateReport
                  key={this.state.reportKey}
                  dashboard={true}
                  startDate={this.state.startDate[0].clone()}
                  endDate={this.state.endDate[0].clone()}
                  dateRangeStart={this.state.dateRange.start}
                  reportEstimate={this.state.simulatedData}
                  reportLocations={this.state.qLocation}
                  chartLabels={this.state.locationLabels}
                  reportByWeek={3} // 1 (week), 2 (month), 3 (custom)
                  locType={this.state.locType}
                  demo={this.state.reportDemo}
                  change={this.state.reportChange}
                  detailed={this.state.reportDetailed}
                  reportHighlightsChanged={this.reportHighlightsChanged}
                  dateInterval={this.state.interval}
                />
              </Grid>
            </TabPanel>

            <TabPanel index={1} value={this.state.reportTab} style={{ backgroundColor: '#757575', minHeight: '80vh', height: '100%' }}>
              <Grid container direction='column' justifyContent='flex-start' alignItems='center' style={{ marginLeft: this.state.reportDrawerOpen ? '7vw' : 0, marginTop: 0, transform: 'scale(' + this.state.zoomLevel.toFixed(1) + ',' + this.state.zoomLevel.toFixed(1) + ')', transformOrigin: '50% 0', height: '100%', position: 'relative', paddingTop: 15, paddingBottom: 0 }} >
                <CreateReport
                  key={this.state.reportKey}
                  dashboard={true}
                  startDate={this.state.startDate[0].clone()}
                  endDate={this.state.endDate[0].clone()}
                  dateRangeStart={this.state.dateRange.start}
                  reportEstimate={this.state.simulatedData}
                  reportLocations={this.state.qLocation}
                  chartLabels={this.state.locationLabels}
                  reportByWeek={1} // 1 (week), 2 (month), 3 (custom)
                  locType={this.state.locType}
                  demo={this.state.reportDemo}
                  change={this.state.reportChange}
                  detailed={this.state.reportDetailed}
                  reportHighlightsChanged={this.reportHighlightsChanged}
                  dateInterval={this.state.interval}
                />
              </Grid>
            </TabPanel>

            <TabPanel index={2} value={this.state.reportTab} style={{ backgroundColor: '#757575', minHeight: '80vh', height: '100%' }}>
              <Grid container direction='column' justifyContent='flex-start' alignItems='center' style={{ marginLeft: this.state.reportDrawerOpen ? '7vw' : 0, marginTop: 0, transform: 'scale(' + this.state.zoomLevel.toFixed(1) + ',' + this.state.zoomLevel.toFixed(1) + ')', transformOrigin: '50% 0', height: '100%', position: 'relative', paddingTop: 15, paddingBottom: 0 }} >
                <CreateReport
                  key={this.state.reportKey}
                  dashboard={true}
                  startDate={this.state.startDate[0].clone()}
                  endDate={this.state.endDate[0].clone()}
                  dateRangeStart={this.state.dateRange.start}
                  reportEstimate={this.state.simulatedData}
                  reportLocations={this.state.qLocation}
                  chartLabels={this.state.locationLabels}
                  reportByWeek={2} // 1 (week), 2 (month), 3 (custom)
                  locType={this.state.locType}
                  demo={this.state.reportDemo}
                  change={this.state.reportChange}
                  detailed={this.state.reportDetailed}
                  reportHighlightsChanged={this.reportHighlightsChanged}
                  dateInterval={this.state.interval}
                />
              </Grid>
            </TabPanel>
          </SwipeableViews>
        }

 

    { !videoDash && !this.state.reportOpenIms &&
		<SwipeableViews
        axis='x'
        index={this.state.value}
        onChangeIndex={this.handleChangeIndex}
        className={classes.bkgrnd}>
          <TabPanel index={0} value={this.state.value}>
           <Grid container direction='column' justifyContent='flex-start' alignItems='center' style={{ marginTop: '0.5vh' }}>
            <AveragesHeader
              windowWidth={windowWidth}
              windowHeight={windowHeight}
              series={this.state.dailyAverages}
              multDates={this.state.dateCard[1] || this.state.dateCard[2]}
              locationsObject={locationsObject}
              count={true}
              openTab={this.state.value}
              mode={this.state.mode}
              occupancy={isOccupancy}
              vehicles={isVehicle}
              numPlots={this.state.dateCard[1] ? (this.state.locationCard.length * 2) : this.state.locationCard.length}
              client={client}
            />
            <Grid item container direction='row' justifyContent='space-between' alignItems='stretch' style={{ marginTop: '1.0vh', display: (this.state.value === 0) ? '' : 'none' }} className={classes.tabView}>
              <Grid item container direction='row' justifyContent='space-between' alignItems='stretch' style={{ display: windowWidth > CELLWIDTH ? '' : 'none', paddingLeft: '0px', paddingRight: '0px', height: windowWidth > CELLWIDTH ? 'calc(0vw + 60vh)' : '100%', width: '12%' }}>
                    <Card raised={(this.state.currentGraph === 'byDay') ? true : false} className={classes.tinyCard} style={{ width: '100%', cursor: 'pointer', backgroundColor: (this.state.currentGraph === 'byDay') ? SELECTED_GRAPH : UNSELECTED_GRAPH, opacity: (this.state.currentGraph === 'byDay') ? 1 : UNSELECTED_OPACITY }} onClick={this.clickGraph('byDay')}>
                      <TinyByDate
                        container
                        count={true}
                        openTab={this.state.value}
                        mode={this.state.modeLabel}
                        occupancy={isOccupancy}
                        vehicles={isVehicle}
                        series={this.state.byDateData}
                        locationsObject={locationsObject}
                        multDates={this.state.dateCard[1] || this.state.dateCard[2]}
                        numPlots={this.state.locationCard.length}/>
                    </Card>
                    <Card raised={(this.state.currentGraph === 'byTime') ? true : false} className={classes.tinyCard} style={{ width: '100%', cursor: 'pointer', backgroundColor: (this.state.currentGraph === 'byTime') ? SELECTED_GRAPH : UNSELECTED_GRAPH, opacity: (this.state.currentGraph === 'byTime') ? 1 : UNSELECTED_OPACITY, marginTop: VIEW_SPACE, marginBottom: VIEW_SPACE }} onClick={this.clickGraph('byTime')}>
                      <TinyByTimeOfDay
                        container
                        count={true}
                        openTab={this.state.value}
                        mode={this.state.modeLabel}
                        occupancy={isOccupancy}
                        vehicles={isVehicle}
                        series={this.state.byTimeOfDayData}
                        locationsObject={locationsObject}
                        multDates={this.state.dateCard[1] || this.state.dateCard[2]}
                        numPlots={this.state.locationCard.length}/>
                    </Card>
                    <Card raised={(this.state.currentGraph === 'byDayOfWeek') ? true : false} className={classes.tinyCard} style={{ VIEW_SPACE, width: '100%', cursor: 'pointer', backgroundColor: (this.state.currentGraph === 'byDayOfWeek') ? SELECTED_GRAPH : UNSELECTED_GRAPH, opacity: (this.state.currentGraph === 'byDayOfWeek') ? 1 : UNSELECTED_OPACITY }} onClick={this.clickGraph('byDayOfWeek')}>
                      <TinyByDayOfWeek
                        container
                        count={true}
                        openTab={this.state.value}
                        mode={this.state.modeLabel}
                        occupancy={isOccupancy}
                        vehicles={isVehicle}
                        series={this.state.byDayOfWeekData}
                        locationsObject={locationsObject}
                        multDates={this.state.dateCard[1] || this.state.dateCard[2]}
                        numPlots={this.state.locationCard.length}/>
                    </Card>
              </Grid>  
             
              <Grid item container style={{ paddingLeft: VIEW_SPACE, paddingRight: '0px', height: windowWidth > CELLWIDTH ? 'calc(0vw + 60vh)' : '100%', width: windowWidth > CELLWIDTH ? "88%" : "100%" }}>
                  <Card className={classes.fullCard} style={{ width: "100%", marginBottom: windowWidth > CELLWIDTH ? 0 : 10, display: (this.state.currentGraph === "byDay" || windowWidth < CELLWIDTH) ? '' : 'none' }}>
                    <ByDate
                      CELLWIDTH={CELLWIDTH}
                      windowWidth={windowWidth}
                      windowHeight={windowHeight}
                      multDates={this.state.dateCard[1] || this.state.dateCard[2]}
                      numPlots={this.state.locationCard.length}
                      openTab={this.state.value}
                      series={this.state.byDateData}
                      interval={this.state.interval}
                      changeInterval={this.changeInterval}
                      count={true}
                      mode={this.state.mode}
                      occupancy={isOccupancy}
                      vehicles={isVehicle}
                      locationsObject={locationsObject}
                      sameWeather={this.state.sameWeather}/>
                  </Card>
                  <Card className={classes.fullCard} style={{ width: "100%", marginBottom: windowWidth > CELLWIDTH ? 0 : 10, display: (this.state.currentGraph === "byTime" || windowWidth < CELLWIDTH) ? '' : 'none' }}>
                    <ByTimeChart
                      CELLWIDTH={CELLWIDTH}
                      windowWidth={windowWidth}
                      windowHeight={windowHeight}
                      multDates={this.state.dateCard[1] || this.state.dateCard[2]}
                      numPlots={this.state.locationCard.length}
                      openTab={this.state.value}
                      dateLabel={this.state.dateLabel}
                      series={this.state.byTimeOfDayData}
                      count={true} gender={false} age={false}
                      mode={this.state.mode}
                      occupancy={isOccupancy}
                      vehicles={isVehicle}
                      locationsObject={locationsObject}/>
                  </Card>
                  <Card className={classes.fullCard} style={{ width: "100%", marginBottom: windowWidth > CELLWIDTH ? 0 : 10, display: (this.state.currentGraph === "byDayOfWeek" || windowWidth < CELLWIDTH) ? '' : 'none' }}>
                    <ByDayOfWeek
                      CELLWIDTH={CELLWIDTH}
                      windowWidth={windowWidth}
                      windowHeight={windowHeight}
                      multDates={this.state.dateCard[1] || this.state.dateCard[2]}
                      numPlots={this.state.locationCard.length}
                      series={this.state.byDayOfWeekData}
                      count={true}
                      openTab={this.state.value}
                      mode={this.state.mode}
                      occupancy={isOccupancy}
                      vehicles={isVehicle}
                      locationsObject={locationsObject}/>
                  </Card>
              </Grid>
              {/* <Grid  container item style={{marginTop:'2vh',width:"100%",height:'100%'}} >
                <Card className={classes.fullCard} style={{width:"100%",height:'100%'}}>
                  <MonthlyTrendChart
                      CELLWIDTH={CELLWIDTH}
                      windowWidth={windowWidth}
                      windowHeight={windowHeight}
                      multDates={this.state.dateCard[1] || this.state.dateCard[2]}
                      numPlots={this.state.locationCard.length}
                      openTab={this.state.value}
                      series={this.state.byDateData}
                      interval={'month'}
                      // changeInterval={this.changeInterval}
                      count={true}
                      mode={this.state.mode}
                      locationsObject={locationsObject}
                      sameWeather={this.state.sameWeather}/>
                </Card>
              </Grid> */}
              <Grid  container item style={{marginTop:'1.5vh',width:"100%",height:'100%'}} >
                <Card className={classes.fullCard} style={{width:"100%",height:'100%'}}>
                  <TimeByDay
                    windowWidth={windowWidth}
                    windowHeight={windowHeight}
                    multDates={this.state.dateCard[1] || this.state.dateCard[2]}
                    numPlots={this.state.locationCard.length}
                    series={this.state.byDayHourData}
                    count={true}
                    mode={this.state.mode}
                    occupancy={isOccupancy}
                    vehicles={isVehicle}
                    locationsObject={locationsObject}/>
                </Card>
              </Grid>
            </Grid>
          </Grid>

          </TabPanel>
          <TabPanel  index={1} value={this.state.value}>
          <Grid container direction='column' justifyContent='flex-start' alignItems='center' style={{ marginTop: '0.5vh' }}>
            <AveragesHeader
              windowWidth={windowWidth}
              windowHeight={windowHeight}
              series={this.state.dailyAverages}
              multDates={this.state.dateCard[1] || this.state.dateCard[2]}
              locationsObject={locationsObject}
              gender={true}
              age={false}
              openTab={this.state.value}
              mode={this.state.mode}
              occupancy={isOccupancy}
              vehicles={isVehicle}
              numPlots={this.state.dateCard[1] ? (this.state.locationCard.length * 2) : this.state.locationCard.length}
              client={client}
            />
            <Grid item container direction='row' justifyContent='space-between' alignItems='stretch' style={{ marginTop: '1.0vh', display: (this.state.value === 1) ? '' : 'none' }} className={classes.tabView}>
            <Grid item container direction='row' justifyContent='space-between' alignItems='stretch' style={{ display: windowWidth > CELLWIDTH ? '' : 'none', height: windowWidth > CELLWIDTH ? '500' : '500', width: '12%' }}>
                  <Card raised={(this.state.currentGraph === 'byDay') ? true : false} className={classes.fullCard} style={{ width: '100%', cursor: 'pointer', backgroundColor: (this.state.currentGraph === 'byDay') ? SELECTED_GRAPH : UNSELECTED_GRAPH, opacity: (this.state.currentGraph === 'byDay') ? 1 : UNSELECTED_OPACITY }} onClick={this.clickGraph('byDay')}>
                    <TinyByDate
                      gender={true}
                      openTab={this.state.value}
                      mode={this.state.modeLabel}
                      occupancy={isOccupancy}
                      vehicles={isVehicle}
                      series={this.state.byDateData}
                      locationsObject={locationsObject}
                      multDates={this.state.dateCard[1] || this.state.dateCard[2]}
                      numPlots={this.state.locationCard.length}/>
                  </Card> 
                  <Card raised={(this.state.currentGraph === 'byTime') ? true : false} className={classes.fullCard} style={{ width: '100%', cursor: 'pointer', backgroundColor: (this.state.currentGraph === 'byTime') ? SELECTED_GRAPH : UNSELECTED_GRAPH, opacity: (this.state.currentGraph === 'byTime') ? 1 : UNSELECTED_OPACITY, marginTop: VIEW_SPACE, marginBottom: VIEW_SPACE }} onClick={this.clickGraph('byTime')}>
                    <TinyByTimeOfDay
                      gender={true}
                      openTab={this.state.value}
                      mode={this.state.modeLabel}
                      occupancy={isOccupancy}
                      vehicles={isVehicle}
                      series={this.state.byTimeOfDayData}
                      locationsObject={locationsObject}
                      multDates={this.state.dateCard[1] || this.state.dateCard[2]}
                      numPlots={this.state.locationCard.length}/>
                  </Card>
                  <Card raised={(this.state.currentGraph === 'byDayOfWeek') ? true : false} className={classes.fullCard} style={{ width: '100%', cursor: 'pointer', backgroundColor: (this.state.currentGraph === 'byDayOfWeek') ? SELECTED_GRAPH : UNSELECTED_GRAPH, opacity: (this.state.currentGraph === 'byDayOfWeek') ? 1 : UNSELECTED_OPACITY }} onClick={this.clickGraph('byDayOfWeek')}>
                    <TinyByDayOfWeek
                      gender={true}
                      openTab={this.state.value}
                      mode={this.state.modeLabel}
                      occupancy={isOccupancy}
                      vehicles={isVehicle}
                      series={this.state.byDayOfWeekData}
                      locationsObject={locationsObject}
                      multDates={this.state.dateCard[1] || this.state.dateCard[2]}
                      numPlots={this.state.locationCard.length}/>
                  </Card>
              </Grid>
              <Grid container item style={{ paddingLeft: VIEW_SPACE, height: windowWidth > CELLWIDTH ? 'calc(0vw + 60vh)' : '100%', width: windowWidth > CELLWIDTH ? "88%" : "100%" }}>
                  <Card className={classes.fullCard} style={{ width: "100%", marginBottom: windowWidth > CELLWIDTH ? 0 : 10, display: (this.state.currentGraph === "byDay" || windowWidth < CELLWIDTH) ? '' : 'none' }}>
                    <ByDate
                      container
                      CELLWIDTH={CELLWIDTH}
                      windowWidth={windowWidth}
                      windowHeight={windowHeight}
                      multDates={this.state.dateCard[1] || this.state.dateCard[2]}
                      numPlots={this.state.locationCard.length}
                      openTab={this.state.value}
                      series={this.state.byDateData}
                      interval={this.state.interval}
                      changeInterval={this.changeInterval}
                      gender={true}
                      age={false}
                      mode={this.state.mode}
                      occupancy={isOccupancy}
                      vehicles={isVehicle}
                      locationsObject={locationsObject}
                      sameWeather={this.state.sameWeather}/>
                  </Card>
                  <Card className={classes.fullCard} style={{ width: "100%", marginBottom: windowWidth > CELLWIDTH ? 0 : 10, display: (this.state.currentGraph === "byTime" || windowWidth < CELLWIDTH) ? '' : 'none' }}>
                    <ByTimeChart
                      container
                      CELLWIDTH={CELLWIDTH}
                      windowWidth={windowWidth}
                      windowHeight={windowHeight}
                      multDates={this.state.dateCard[1] || this.state.dateCard[2]}
                      numPlots={this.state.locationCard.length}
                      openTab={this.state.value}
                      dateLabel={this.state.dateLabel}
                      series={this.state.byTimeOfDayData}
                      count={false} gender={true} age={false}
                      mode={this.state.mode}
                      occupancy={isOccupancy}
                      vehicles={isVehicle}
                      locationsObject={locationsObject}/>
                  </Card>
                  <Card className={classes.fullCard} style={{ width: "100%", marginBottom: windowWidth > CELLWIDTH ? 0 : 10, display: (this.state.currentGraph === "byDayOfWeek" || windowWidth < CELLWIDTH) ? '' : 'none' }}>
                    <ByDayOfWeek
                      container
                      CELLWIDTH={CELLWIDTH}
                      windowWidth={windowWidth}
                      windowHeight={windowHeight}
                      multDates={this.state.dateCard[1] || this.state.dateCard[2]}
                      numPlots={this.state.locationCard.length}
                      series={this.state.byDayOfWeekData}
                      gender={true}
                      age={false}
                      openTab={this.state.value}
                      mode={this.state.mode}
                      occupancy={isOccupancy}
                      vehicles={isVehicle}
                      locationsObject={locationsObject}/>
                  </Card>
              </Grid>
              <Grid container item style={{ marginTop: '1.5vh', width: '100%', height: '100%' }}>
                <Card className={classes.fullCard} style={{ width:'100%', height: '100%' }}>
                  <TimeByDay
                    windowWidth={windowWidth}
                    windowHeight={windowHeight}
                    multDates={this.state.dateCard[1] || this.state.dateCard[2]}
                    numPlots={this.state.locationCard.length}
                    series={this.state.byDayHourData}
                    gender={true}
                    age={false}
                    mode={this.state.mode}
                    occupancy={isOccupancy}
                    vehicles={isVehicle}
                    locationsObject={locationsObject}/>
                </Card>
              </Grid>

          </Grid>
          </Grid>
          </TabPanel>  
          <TabPanel index={2} value={this.state.value}> 
          <Grid container direction="column" justifyContent='flex-start' alignItems="center" style={{marginTop:'0.5vh'}}>
            <AveragesHeader
              windowWidth={windowWidth}
              windowHeight={windowHeight}
              series={this.state.dailyAverages}
              multDates={this.state.dateCard[1] || this.state.dateCard[2]}
              locationsObject={locationsObject}
              gender={false}
              age={true}
              openTab={this.state.value}
              mode={this.state.mode}
              occupancy={isOccupancy}
              vehicles={isVehicle}
              client={client}
              numPlots={this.state.dateCard[1] ? (this.state.locationCard.length * 2) : this.state.locationCard.length}/>
            <Grid item container direction='row' justifyContent='space-between' alignItems='center' style={{ marginTop: '1.0vh', display: (this.state.value === 2) ? '' : 'none' }} className={classes.tabView}>
              <Grid item container direction='row' justifyContent='space-between' alignItems='stretch' style={{ display: windowWidth > CELLWIDTH ? '' : 'none', height: windowWidth > CELLWIDTH ? 'calc(0vw + 60vh)' : '100%', width: windowWidth > CELLWIDTH ? '12%' : 0 }}>
                  <Card raised={(this.state.currentGraph === 'byDay') ? true : false} className={classes.fullCard} style={{ width: '100%', cursor: 'pointer', backgroundColor: (this.state.currentGraph === 'byDay') ? SELECTED_GRAPH : UNSELECTED_GRAPH, opacity: (this.state.currentGraph === 'byDay') ? 1 : UNSELECTED_OPACITY }} onClick={this.clickGraph('byDay')}>
                    <TinyByDate
                      age={true}
                      openTab={this.state.value}
                      mode={this.state.modeLabel}
                      occupancy={isOccupancy}
                      vehicles={isVehicle}
                      series={this.state.byDateData}
                      locationsObject={locationsObject}
                      multDates={this.state.dateCard[1] || this.state.dateCard[2]}
                      numPlots={this.state.locationCard.length}/>
                  </Card>
                  <Card raised={(this.state.currentGraph === 'byTime') ? true : false} className={classes.fullCard} style={{ width: '100%', cursor: 'pointer', backgroundColor: (this.state.currentGraph === 'byTime') ? SELECTED_GRAPH : UNSELECTED_GRAPH, opacity: (this.state.currentGraph === 'byTime') ? 1 : UNSELECTED_OPACITY, marginTop: VIEW_SPACE, marginBottom: VIEW_SPACE }} onClick={this.clickGraph('byTime')}>
                    <TinyByTimeOfDay
                      age={true}
                      openTab={this.state.value}
                      mode={this.state.modeLabel}
                      occupancy={isOccupancy}
                      vehicles={isVehicle}
                      series={this.state.byTimeOfDayData}
                      locationsObject={locationsObject}
                      multDates={this.state.dateCard[1] || this.state.dateCard[2]}
                      numPlots={this.state.locationCard.length}/>
                  </Card>
                  <Card raised={(this.state.currentGraph === 'byDayOfWeek') ? true : false} className={classes.fullCard} style={{ width: '100%', cursor: 'pointer', backgroundColor: (this.state.currentGraph === 'byDayOfWeek') ? SELECTED_GRAPH : UNSELECTED_GRAPH, opacity: (this.state.currentGraph === 'byDayOfWeek') ? 1 : UNSELECTED_OPACITY }} onClick={this.clickGraph('byDayOfWeek')}>
                    <TinyByDayOfWeek
                      age={true}
                      openTab={this.state.value}
                      mode={this.state.modeLabel}
                      occupancy={isOccupancy}
                      vehicles={isVehicle}
                      series={this.state.byDayOfWeekData}
                      locationsObject={locationsObject}
                      multDates={this.state.dateCard[1] || this.state.dateCard[2]}
                      numPlots={this.state.locationCard.length}/>
                  </Card>
              </Grid>  
              <Grid container item style={{ paddingLeft: VIEW_SPACE, height: windowWidth > CELLWIDTH ? 'calc(0vw + 60vh)' : '100%', width: windowWidth > CELLWIDTH ? "88%" : "100%" }}>
                  <Card className={classes.fullCard} style={{ width: "100%", marginBottom: windowWidth > CELLWIDTH ? 0 : 10, display: (this.state.currentGraph === "byDay" || windowWidth < CELLWIDTH) ? '' : 'none' }}>
                    <ByDate
                      container
                      CELLWIDTH={CELLWIDTH}
                      windowWidth={windowWidth}
                      windowHeight={windowHeight}
                      multDates={this.state.dateCard[1] || this.state.dateCard[2]}
                      numPlots={this.state.locationCard.length}
                      openTab={this.state.value}
                      series={this.state.byDateData}
                      interval={this.state.interval}
                      changeInterval={this.changeInterval}
                      gender={false}
                      age={true}
                      mode={this.state.mode}
                      occupancy={isOccupancy}
                      vehicles={isVehicle}
                      locationsObject={locationsObject}
                      sameWeather={this.state.sameWeather}/>
                  </Card>
                  <Card className={classes.fullCard} style={{ width: "100%", marginBottom: windowWidth > CELLWIDTH ? 0 : 10, display: (this.state.currentGraph === "byTime" || windowWidth < CELLWIDTH) ? '' : 'none' }}>
                    <ByTimeChart
                      container
                      CELLWIDTH={CELLWIDTH}
                      windowWidth={windowWidth}
                      windowHeight={windowHeight}
                      multDates={this.state.dateCard[1] || this.state.dateCard[2]}
                      numPlots={this.state.locationCard.length}
                      openTab={this.state.value}
                      dateLabel={this.state.dateLabel}
                      series={this.state.byTimeOfDayData}
                      count={false} gender={false} age={true}
                      mode={this.state.mode}
                      occupancy={isOccupancy}
                      vehicles={isVehicle}
                      locationsObject={locationsObject}/>
                  </Card>
                  <Card className={classes.fullCard} style={{ width: "100%", marginBottom: windowWidth > CELLWIDTH ? 0 : 10, display: (this.state.currentGraph === "byDayOfWeek" || windowWidth < CELLWIDTH) ? '' : 'none' }}>
                    <ByDayOfWeek
                      container
                      CELLWIDTH={CELLWIDTH}
                      windowWidth={windowWidth}
                      windowHeight={windowHeight}
                      multDates={this.state.dateCard[1] || this.state.dateCard[2]}
                      numPlots={this.state.locationCard.length}
                      series={this.state.byDayOfWeekData}
                      gender={false}
                      age={true}
                      openTab={this.state.value}
                      mode={this.state.mode}
                      occupancy={isOccupancy}
                      vehicles={isVehicle}
                      locationsObject={locationsObject}/>
                  </Card>
              </Grid>
              <Grid container item style={{ marginTop: '1.5vh', width: "100%", height: '100%' }}>
                <Card className={classes.fullCard} style={{ width: "100%", height: '100%' }}>
                  <TimeByDay
                    windowWidth={windowWidth}
                    windowHeight={windowHeight}
                    multDates={this.state.dateCard[1] || this.state.dateCard[2]}
                    numPlots={this.state.locationCard.length}
                    series={this.state.byDayHourData}
                    gender={false}
                    age={true}
                    mode={this.state.mode}
                    occupancy={isOccupancy}
                    vehicles={isVehicle}
                    locationsObject={locationsObject}/>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </TabPanel>
      </SwipeableViews>
      }
    </Fragment>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(withStyles(styles)(Dashboard));
