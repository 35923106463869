import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { CSVLink } from 'react-csv';

import ExportButton from '@material-ui/icons/SaveAltRounded';
import { CircularProgress, Collapse, Grid, IconButton, ImageList, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { alpha, withStyles } from '@material-ui/core/styles';

import { saveImage, reduceArray, getColor } from '../../utils/helpers';
import { colorsMen, colorsWomen, colorsChildren, colorsAdults, colorsSeniors, colorsBikes, colorsBuses, colorsCars, colorsTrucks, heatmapColors } from '../../utils/colors';


const MIN_TABLE = 900;
const CELL_PAD = '0.5%';
const CELL_HEAD_PAD = '0.25%';

const styles = theme => ({
  menuItem: {
    // color: theme.palette.primary.main
  },
  gridList: {
    width: '100%',
    height:'100%',
    overflowX:'hidden',
    overflowY:'hidden',
    // height: 100,
    // flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    // transform: 'translateZ(0)',
  },
  table: {
    // minWidth: 600,
  },
  tablecellStyle: {

    // fontSize: '1vw'
  },
  tableCellAge: {
    padding:'none',
  },
  tableArea: {
    paddingLeft:0,
    paddingRight:0,
  }
});


class TimeByDayTable extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      series: this.props.series,
      anchorEl: null,
      exportData: [],
      exportHeaders: null,
      dateRange: '',
      // showCopied: false,
      colors: this.props.locationsObject['colors'],
      COMBCOLORS: this.props.locationsObject['COMBCOLORS'],
      labelList: this.props.locationsObject['labelList'],
      count: this.props.count,
      gender: this.props.gender,
      age: this.props.age,
      multDates: this.props.multDates,
      numPlots: this.props.numPlots,
      CELLWIDTH: this.props.CELLWIDTH,
    };

    this.currentChart = React.createRef();
  }

    static getDerivedStateFromProps(nextProps, prevState) {
      // do things with nextProps.someProp and prevState.cachedSomeProp
      if (nextProps.series !== prevState.series || nextProps.multDates !== prevState.multDates || nextProps.numPlots !== prevState.numPlots) {
        return {
          series: nextProps.series,
          multDates: nextProps.multDates,
          numPlots: nextProps.numPlots,
        };
      }
      return {};
    }


    clickExport = (event) => {
      this.setState({ anchorEl: event.currentTarget });
    }

    handleClose = () => {
      this.setState({ anchorEl: null });
    }

    exportData = (activeData) => () => {
      // data for this chart is in a different format, export currently disabled.
      let partData = activeData[0].data;
      let exportData = new Array(partData.length);
  
      for (let d = 0; d < partData.length; d++) {
        // exportData[d] = { "Day of Week": DAYS[partData[d]._id.dayOfWeek] };
        if (this.state.count) { exportData[d].Count = partData[d].count };
        if (this.state.gender) { exportData[d].Male = partData[d].men; exportData[d].Female = partData[d].women; };
        if (this.state.age) { exportData[d].Adults = partData[d].adults; exportData[d].Children = partData[d].children; exportData[d].Seniors = partData[d].seniors; };
      }
  
      this.setState({
        exportData: exportData, dateRange: activeData[0].dateLabel, anchorEl: null
      });
    }

    downloadImage = async (format) => {
      try {
        const fileObj = {
          chartName: 'ExterosChart_TimeByDayOfWeek_',
          dateLabel: this.state.series[0].dateLabel,
          count: this.state.count,
          gender: this.state.gender,
          age: this.state.age,
          fileType: format
        }
  
        saveImage(this.currentChart.current, fileObj, false);
      } catch (err) {
        // notify user of error
      } finally {
        this.handleClose();
      }
    }

    cellStyle = (type, value, maxCount) => {
      const cLength = heatmapColors.counts.length;
      maxCount = (maxCount / cLength) * cLength + cLength;
      const colorIndex = Math.floor(cLength * (value / maxCount));

      return heatmapColors[type][colorIndex];
    }

    render() {
      const { age, classes, gender, multDates, vehicles, windowHeight, windowWidth } = this.props;

      let dataPoints = 0;
      let secondDateCardIndex = 0;
      try { secondDateCardIndex = this.state.series.length - 1; }
      catch (e) {}
  
      let chartArray = reduceArray(this.state.series, this.state.numPlots, multDates, secondDateCardIndex);
      try {
        for (let ser in chartArray) {
          if (chartArray[ser].data.length > dataPoints) {
            dataPoints = chartArray[ser].data.length;
          }
        }
      } catch (err) {}

      return (
        <Grid style={{ width: '100%', height: '100%', backgroundColor: 'white' }}>
          <Grid container direction="row" justifyContent='space-between' alignItems="flex-start" style={{ width: '100%', backgroundColor: 'white' }}>
            <Grid xs item style={{ marginTop: 15, marginLeft: 20, marginBottom: 5 }}>
              <Grid container alignItems="flex-end">
                <Typography align='left' style={{ width: '100%', cursor: 'pointer', paddingTop: '0px', paddingBottom: '0px', fontSize: 'calc(12px + 0.6vw)' }}>
                  {this.props.mode === 'Conversion'
                    ? 'Capture: Time by Day of Week (%)'
                    : this.props.mode === 'Dwell'
                      ? 'Avg Dwell: Time by Day of Week (seconds)'
                      : (this.props.occupancy)
                        ? 'Avg Daily Peak Occupancy: Time by Day of Week'
                        : 'Avg Daily Traffic: Time by Day of Week'
                  }
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={2}>
              <Grid container alignItems="flex-start" justifyContent="flex-end">
                <Grid item>
                  <IconButton className={classes.exportBtn} onClick={this.clickExport}>
                    <ExportButton />
                  </IconButton>
                  <Menu
                    id='export-menu'
                    anchorEl={this.state.anchorEl}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.handleClose}>
                    <MenuItem onClick={this.exportData(chartArray)} className={classes.menuItem} disabled={true}>
                      <CSVLink
                        data={this.state.exportData}
                        filename={'ExterosData_TimeByDayOfWeek_'.concat(this.state.dateRange, '_', this.state.count ? 'Counts' : '', gender ? 'Gender' : '', age ? 'Age' : '', '.csv').replace(/\s/g, '')}
                        style={{ textDecoration: 'none', color: 'inherit' }}>
                        CSV (.csv)
                      </CSVLink>
                    </MenuItem>
                    <MenuItem className={classes.menuItem} onClick={() => this.downloadImage('jpeg')} disabled={false}>Image (.jpeg)</MenuItem>
                    <MenuItem className={classes.menuItem} onClick={() => this.downloadImage('svg')} disabled={false}>Image (.svg)</MenuItem>
                  </Menu>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container direction='column' ref={this.currentChart} justifyContent='flex-start' alignItems='stretch' style={{ position: 'relative', paddingLeft: 0, paddingRight: 0, width: '100%', backgroundColor: 'white' }}>
            {/* <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={this.state.showCopied}
              autoHideDuration={1500}
              transitionDuration={{enter:600,exit:1000}}
              onClose={this.messageClose.bind(this)}
              ContentProps={{
                'aria-describedby': 'message-id',
              }}
              message={<span id="message-id">Chart Copied to Clipboard</span>}
            /> */}

            { dataPoints === 0 && 
                <Grid container justifyContent='space-around' alignItems="center" >
                  <CircularProgress style={{ position: 'absolute', top: windowHeight / 2.7, left: windowWidth / 2 }} />
                </Grid>
            }
            { dataPoints > 0 && (!gender && !age) &&

              <ImageList className={classes.gridList} cols={((multDates || this.state.numPlots > 1) && windowWidth > 1280) ? 2 : 1} style={{ backgroundColor: 'white', marginBottom: 5 }}>
                { chartArray.map((s, idx) =>
                    [
                      <Grid container justifyContent='space-around' style={{marginTop:0,marginBottom:0,height:'100%',backgroundColor:'white', display:s.data?.length > 0? '':'none'}}>
                        <Collapse in={s.data?.length > 0} style={{width:'100%',height:'100%'}} >
                          <Grid container spacing={1} style={{width:'100%',height:'100%'}} className={classes.tableArea} direction="row" justifyContent='flex-start' alignItems="center" >
                            <Grid item xs style={{margin:'0.5vh'}}>
                              <TableContainer component={Paper} >   
                                <Typography style={{ paddingTop: 5, paddingBottom: 5, backgroundColor: alpha(getColor(s.locationLabels, idx, this.props.locationsObject, chartArray), 0.6), fontWeight: 500, fontSize: 'calc(8px + 0.4vw)' }}>
                                  {s.locationLabels.join(', ')} {(multDates)? ' (' + s.dateLabel + ')':''}
                                </Typography>
                                <Table className={classes.table} size="small" aria-label="simple table">
                                  <TableHead>
                                    <TableRow style={{}}>
                                      <TableCell padding={'none'}></TableCell>
                                      <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align="right">{windowWidth > (MIN_TABLE * this.state.numPlots) ? 'Monday' : 'Mon'}</TableCell>
                                      <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align="right">{windowWidth > (MIN_TABLE * this.state.numPlots) ? 'Tuesday' : 'Tue'}</TableCell>
                                      <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align="right">{windowWidth > (MIN_TABLE * this.state.numPlots) ? 'Wednesday' : 'Wed'}</TableCell>
                                      <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align="right">{windowWidth > (MIN_TABLE * this.state.numPlots) ? 'Thursday' : 'Thu'}</TableCell>
                                      <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align="right">{windowWidth > (MIN_TABLE * this.state.numPlots) ? 'Friday' : 'Fri'}</TableCell>
                                      <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align="right">{windowWidth > (MIN_TABLE * this.state.numPlots) ? 'Saturday' : 'Sat'}</TableCell>
                                      <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align="right">{windowWidth > (MIN_TABLE * this.state.numPlots) ? 'Sunday' : 'Sun'}</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {s.data?.map( row => 
                                      <TableRow key={row.Time}>
                                        <TableCell padding={'none'} component="th" scope="row" style={{ padding: CELL_PAD, width: (windowWidth >= 1000) ? 'auto' : 'auto' }}>
                                          {row.Time}
                                        </TableCell>
                                        <TableCell padding={'none'} className={classes.tablecellStyle} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('counts', row.Monday, s.maxCount || 0) }} align="right">{row.Monday}</TableCell>
                                        <TableCell padding={'none'} className={classes.tablecellStyle} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('counts', row.Tuesday, s.maxCount) }} align="right">{row.Tuesday}</TableCell>
                                        <TableCell padding={'none'} className={classes.tablecellStyle} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('counts', row.Wednesday, s.maxCount || 0) }} align="right">{row.Wednesday}</TableCell>
                                        <TableCell padding={'none'} className={classes.tablecellStyle} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('counts', row.Thursday, s.maxCount || 0) }} align="right">{row.Thursday}</TableCell>
                                        <TableCell padding={'none'} className={classes.tablecellStyle} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('counts', row.Friday, s.maxCount || 0) }} align="right">{row.Friday}</TableCell>
                                        <TableCell padding={'none'} className={classes.tablecellStyle} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('counts', row.Saturday, s.maxCount || 0) }} align="right">{row.Saturday}</TableCell>
                                        <TableCell padding={'none'} className={classes.tablecellStyle} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('counts', row.Sunday, s.maxCount || 0) }} align="right">{row.Sunday}</TableCell>
                                      </TableRow>
                                    )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Grid>
                          </Grid>
                        </Collapse>
                      </Grid>    
                  ])
                }
              </ImageList>
            } 
            { dataPoints > 0 && gender && !vehicles &&
              <ImageList className={classes.gridList} cols={1} style={{ backgroundColor: 'white', marginTop: '0px', marginBottom: 10, marginLeft: 0, marginRight: 0 }}>
              { chartArray.map((s, idx) =>
                  [
                    <Grid container justifyContent='space-around' style={{margin:'0.3vh',height:'100%',width:'100%', display:s.peopleTableRows?.men.length > 0? '':'none'}}>

                      <Grid style={{width:"100%",height:'100%',marginBottom:8}}>
                        <Typography style={{ paddingTop: 5, paddingBottom: 5, backgroundColor: alpha(getColor(s.locationLabels, idx, this.props.locationsObject, chartArray), 0.6), fontWeight: 500, fontSize: 'calc(8px + 0.4vw)' }}>
                          {s.locationLabels.join(', ')} {(multDates)? ' (' + s.dateLabel + ')':''}
                        </Typography>
                      </Grid>
                      <Grid container spacing={1} className={classes.tableArea} direction="row" justifyContent='space-around' alignItems="stretch" style={{width:"100%",display:s.peopleTableRows?.men.length > 0? '':'none'}}>
                        <Grid item xs={12} lg={6} md={6} sm={12} style={{}}>
                          <TableContainer component={Paper} style={{paddingBottom:8}} >
                            <Typography style={{ paddingTop: 5, paddingBottom: 5, backgroundColor: alpha(colorsWomen[0], 0.6), fontWeight: 500, fontSize: 'calc(6px + 0.4vw)' }}>
                              Female
                            </Typography>
                          <Table className={classes.table} size='small' aria-label='simple table'>
                            <TableHead>
                              <TableRow style={{}}>
                                <TableCell padding={'none'}></TableCell>
                                <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align="right">Mon</TableCell>
                                <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align="right">Tue</TableCell>
                                <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align="right">Wed</TableCell>
                                <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align="right">Thu</TableCell>
                                <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align="right">Fri</TableCell>
                                <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align="right">Sat</TableCell>
                                <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align="right">Sun</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {s.peopleTableRows?.women.map( row => 
                                <TableRow key={row.Time}>
                                  <TableCell padding={'none'} component="th" scope="row" style={{ padding: CELL_PAD, width: (windowWidth >= 1000) ? 'auto' : 'auto' }}>
                                    {row.Time}
                                  </TableCell>
                                  <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('women', row.Monday, s.peopleTableRows?.maxWomen || 0) }} align="right">{row.Monday}</TableCell>
                                  <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('women', row.Tuesday, s.peopleTableRows?.maxWomen || 0) }} align="right">{row.Tuesday}</TableCell>
                                  <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('women', row.Wednesday, s.peopleTableRows?.maxWomen || 0) }} align="right">{row.Wednesday}</TableCell>
                                  <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('women', row.Thursday, s.peopleTableRows?.maxWomen || 0) }} align="right">{row.Thursday}</TableCell>
                                  <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('women', row.Friday, s.peopleTableRows?.maxWomen || 0) }} align="right">{row.Friday}</TableCell>
                                  <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('women', row.Saturday, s.peopleTableRows?.maxWomen || 0) }} align="right">{row.Saturday}</TableCell>
                                  <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('women', row.Sunday, s.peopleTableRows?.maxWomen || 0) }} align="right">{row.Sunday}</TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                          </TableContainer>
                        </Grid>
                      <Grid item xs={12} lg={6} md={6} sm={12} style={{}}>

                        <TableContainer component={Paper} style={{paddingBottom:8}}>
                          <Typography style={{ paddingTop: 5, paddingBottom: 5, backgroundColor: alpha(colorsMen[0], 0.6), fontWeight: 500, fontSize: 'calc(6px + 0.4vw)' }}>
                            Male
                          </Typography>
                        <Table className={classes.table} size="small" aria-label="simple table" >
                          <TableHead>
                            <TableRow style={{}}>
                              <TableCell padding={'none'}></TableCell>
                              <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align="right">Mon</TableCell>
                              <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align="right">Tue</TableCell>
                              <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align="right">Wed</TableCell>
                              <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align="right">Thu</TableCell>
                              <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align="right">Fri</TableCell>
                              <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align="right">Sat</TableCell>
                              <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align="right">Sun</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {s.peopleTableRows?.men.map( row => 
                              <TableRow key={row.Time}>
                                <TableCell component="th" scope="row" padding={'none'} style={{ padding: CELL_PAD, width: (windowWidth >= 1000) ? 'auto' : 'auto' }}>
                                  {row.Time}
                                </TableCell>
                                <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('men', row.Monday, s.peopleTableRows?.maxMen || 0) }} align="right">{row.Monday}</TableCell>
                                <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('men', row.Tuesday, s.peopleTableRows?.maxMen || 0) }} align="right">{row.Tuesday}</TableCell>
                                <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('men', row.Wednesday, s.peopleTableRows?.maxMen || 0) }} align="right">{row.Wednesday}</TableCell>
                                <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('men', row.Thursday, s.peopleTableRows?.maxMen || 0) }} align="right">{row.Thursday}</TableCell>
                                <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('men', row.Friday, s.peopleTableRows?.maxMen || 0) }} align="right">{row.Friday}</TableCell>
                                <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('men', row.Saturday, s.peopleTableRows?.maxMen || 0) }} align="right">{row.Saturday}</TableCell>
                                <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('men', row.Sunday, s.peopleTableRows?.maxMen || 0) }} align="right">{row.Sunday}</TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                        </TableContainer>
                      </Grid>
                      </Grid>
                  </Grid> 
                  ])
                }
              </ImageList>
            }
            { dataPoints > 0 && age && !vehicles &&
              <ImageList className={classes.gridList} cols={1} style={{ height: '100%', backgroundColor: 'white', marginTop: '0px', marginBottom: 10, marginLeft: '0px', marginRight: 0 }}>
                { chartArray.map((s, idx) =>
                    [
                      <Grid container justifyContent='space-around' style={{height:'100%',width:'100%',margin:'0.3vh',backgroundColor:'white', display:s.peopleTableRows?.adults.length > 0? '':'none'}}>

                        <Grid style={{width:"100%",height:'100%',marginBottom:8}}>
                          <Typography style={{ paddingTop: 5, paddingBottom: 5, backgroundColor: alpha(getColor(s.locationLabels, idx, this.props.locationsObject, chartArray), 0.6), fontWeight: 500, fontSize: 'calc(8px + 0.4vw)' }}>
                            {s.locationLabels.join(', ')} {(multDates) ? ' (' + s.dateLabel + ')' : ''}
                          </Typography>
                        </Grid>
                        <Grid container direction="row" justifyContent='space-around' alignItems="flex-start" spacing={1} className={classes.tableArea} style={{ width: '100%', display: s.peopleTableRows?.adults.length > 0 ? '' : 'none' }}>
                          <Grid item xs={12} lg={4} md={4} sm={12}>
                            <TableContainer component={Paper} style={{ width: '100%', paddingBottom: 8 }}>
                              <Typography style={{ paddingTop: 5, paddingBottom: 5, backgroundColor: alpha(colorsChildren[0], 0.6), fontWeight: 500, fontSize: 'calc(6px + 0.4vw)' }}>
                                Children
                              </Typography>
                            <Table className={classes.table} size='small' aria-label='simple table'>
                              <TableHead>
                                <TableRow style={{}}>
                                  <TableCell padding={'none'}></TableCell>
                                  <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align="right">Mon</TableCell>
                                  <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align="right">Tue</TableCell>
                                  <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align="right">Wed</TableCell>
                                  <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align="right">Thu</TableCell>
                                  <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align="right">Fri</TableCell>
                                  <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align="right">Sat</TableCell>
                                  <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align="right">Sun</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {s.peopleTableRows?.children.map( row => 
                                  <TableRow key={row.Time}>
                                    <TableCell padding={'none'} component="th" scope="row" style={{ padding: CELL_PAD }} >
                                      {row.Time}
                                    </TableCell>
                                    <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('children', row.Monday, s.peopleTableRows?.maxChildren || 0) }} align="right">{row.Monday}</TableCell>
                                    <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('children', row.Tuesday, s.peopleTableRows?.maxChildren || 0) }} align="right">{row.Tuesday}</TableCell>
                                    <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('children', row.Wednesday, s.peopleTableRows?.maxChildren || 0) }} align="right">{row.Wednesday}</TableCell>
                                    <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('children', row.Thursday, s.peopleTableRows?.maxChildren || 0) }} align="right">{row.Thursday}</TableCell>
                                    <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('children', row.Friday, s.peopleTableRows?.maxChildren || 0) }} align="right">{row.Friday}</TableCell>
                                    <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('children', row.Saturday, s.peopleTableRows?.maxChildren || 0) }} align="right">{row.Saturday}</TableCell>
                                    <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('children', row.Sunday, s.peopleTableRows?.maxChildren || 0) }} align="right">{row.Sunday}</TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                            </TableContainer>
                          </Grid>
                        <Grid item xs={12} lg={4} md={4} sm={12}  style={{}}>

                          <TableContainer component={Paper} style={{ paddingBottom: 8 }}>
                            <Typography style={{ paddingTop: 5, paddingBottom: 5, backgroundColor: alpha(colorsAdults[0], 0.6), fontWeight: 500, fontSize: 'calc(6px + 0.4vw)' }}>
                              Adults
                            </Typography>
                          <Table className={classes.table} size="small" aria-label="simple table" >
                            <TableHead>
                              <TableRow style={{}}>
                                <TableCell padding={'none'}></TableCell>
                                <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align="right">Mon</TableCell>
                                <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align="right">Tue</TableCell>
                                <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align="right">Wed</TableCell>
                                <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align="right">Thu</TableCell>
                                <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align="right">Fri</TableCell>
                                <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align="right">Sat</TableCell>
                                <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align="right">Sun</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {s.peopleTableRows?.adults.map( row => 
                                <TableRow key={row.Time}>
                                  <TableCell padding={'none'} component="th" scope="row" style={{ padding: CELL_PAD }}>
                                    {row.Time}
                                  </TableCell>
                                  <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('adults', row.Monday, s.peopleTableRows?.maxAdults || 0) }} align="right">{row.Monday}</TableCell>
                                  <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('adults', row.Tuesday, s.peopleTableRows?.maxAdults || 0) }} align="right">{row.Tuesday}</TableCell>
                                  <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('adults', row.Wednesday, s.peopleTableRows?.maxAdults || 0) }} align="right">{row.Wednesday}</TableCell>
                                  <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('adults', row.Thursday, s.peopleTableRows?.maxAdults || 0) }} align="right">{row.Thursday}</TableCell>
                                  <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('adults', row.Friday, s.peopleTableRows?.maxAdults || 0) }} align="right">{row.Friday}</TableCell>
                                  <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('adults', row.Saturday, s.peopleTableRows?.maxAdults || 0) }} align="right">{row.Saturday}</TableCell>
                                  <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('adults', row.Sunday, s.peopleTableRows?.maxAdults || 0) }} align="right">{row.Sunday}</TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                          </TableContainer>
                        </Grid>
                        <Grid item xs={12} lg={4} md={4} sm={12}  style={{}}>

                          <TableContainer component={Paper} style={{ paddingBottom: 8 }}>
                            <Typography style={{ paddingTop: 5, paddingBottom: 5, backgroundColor: alpha(colorsSeniors[0], 0.6), fontWeight: 500, fontSize: 'calc(6px + 0.4vw)' }}>
                              Seniors
                            </Typography>
                          <Table className={classes.table} size="small" aria-label="simple table">
                            <TableHead>
                              <TableRow style={{}}>
                                <TableCell padding={'none'} ></TableCell>
                                <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align="right">Mon</TableCell>
                                <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align="right">Tue</TableCell>
                                <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align="right">Wed</TableCell>
                                <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align="right">Thu</TableCell>
                                <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align="right">Fri</TableCell>
                                <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align="right">Sat</TableCell>
                                <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align="right">Sun</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody >
                              {s.peopleTableRows?.seniors.map( row => 
                                <TableRow key={row.Time}>
                                  <TableCell padding={'none'} component="th" scope="row" style={{ padding: CELL_PAD }}>
                                    {row.Time}
                                  </TableCell>
                                  <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('seniors', row.Monday, s.peopleTableRows?.maxSeniors || 0) }} align="right">{row.Monday}</TableCell>
                                  <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('seniors', row.Tuesday, s.peopleTableRows?.maxSeniors || 0) }} align="right">{row.Tuesday}</TableCell>
                                  <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('seniors', row.Wednesday, s.peopleTableRows?.maxSeniors || 0) }} align="right">{row.Wednesday}</TableCell>
                                  <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('seniors', row.Thursday, s.peopleTableRows?.maxSeniors || 0) }} align="right">{row.Thursday}</TableCell>
                                  <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('seniors', row.Friday, s.peopleTableRows?.maxSeniors || 0) }} align="right">{row.Friday}</TableCell>
                                  <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('seniors', row.Saturday, s.peopleTableRows?.maxSeniors || 0) }} align="right">{row.Saturday}</TableCell>
                                  <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('seniors', row.Sunday, s.peopleTableRows?.maxSeniors || 0) }} align="right">{row.Sunday}</TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                          </TableContainer>
                        </Grid>
                      </Grid>
                    
                  </Grid> 
                  ])

                }
              </ImageList>
            }

            {dataPoints > 0 && vehicles && gender &&
              <ImageList className={classes.gridList} cols={1} style={{ height: '100%', backgroundColor: 'white', marginTop: '0px', marginBottom: 10, marginLeft: '0px', marginRight: 0 }}>
                {chartArray.map((s, idx) =>
                  [
                    <Grid container justifyContent='space-around' style={{ height: '100%', width: '100%', margin: '0.3vh', backgroundColor: 'white', display: s.vehicleTableRows?.bikes.length > 0 ? '' : 'none' }}>

                      <Grid style={{ width: '100%', height: '100%', marginBottom: 8 }}>
                        <Typography style={{ paddingTop: 5, paddingBottom: 5, backgroundColor: alpha(getColor(s.locationLabels, idx, this.props.locationsObject, chartArray), 0.6), fontWeight: 500, fontSize: 'calc(8px + 0.4vw)' }}>
                          {s.locationLabels.join(', ')} {(multDates) ? ' (' + s.dateLabel + ')' : ''}
                        </Typography>
                      </Grid>

                      <Grid container direction='row' justifyContent='space-around' alignItems='flex-start' spacing={1} className={classes.tableArea} style={{ width: '100%', display: s.vehicleTableRows?.bikes.length > 0 ? '' : 'none' }}>

                        <Grid item xs={12} lg={3} md={6} sm={12}>
                          <TableContainer component={Paper} style={{ width: '100%', paddingBottom: 8 }}>
                            <Typography style={{ paddingTop: 5, paddingBottom: 5, backgroundColor: alpha(colorsBikes[0], 0.6), fontWeight: 500, fontSize: 'calc(6px + 0.4vw)' }}>
                              Bikes
                            </Typography>
                            <Table className={classes.table} size='small' aria-label='simple table'>
                              <TableHead>
                                <TableRow style={{}}>
                                  <TableCell padding={'none'}></TableCell>
                                  <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align='right'>Mon</TableCell>
                                  <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align='right'>Tue</TableCell>
                                  <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align='right'>Wed</TableCell>
                                  <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align='right'>Thu</TableCell>
                                  <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align='right'>Fri</TableCell>
                                  <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align='right'>Sat</TableCell>
                                  <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align='right'>Sun</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {s.vehicleTableRows?.bikes.map(row =>
                                  <TableRow key={row.Time}>
                                    <TableCell padding={'none'} component='th' scope='row' style={{ padding: CELL_PAD }} >
                                      {row.Time}
                                    </TableCell>
                                    <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('bikes', row.Monday, s.vehicleTableRows?.maxBikes || 0) }} align='right'>{row.Monday}</TableCell>
                                    <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('bikes', row.Tuesday, s.vehicleTableRows?.maxBikes || 0) }} align='right'>{row.Tuesday}</TableCell>
                                    <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('bikes', row.Wednesday, s.vehicleTableRows?.maxBikes || 0) }} align='right'>{row.Wednesday}</TableCell>
                                    <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('bikes', row.Thursday, s.vehicleTableRows?.maxBikes || 0) }} align='right'>{row.Thursday}</TableCell>
                                    <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('bikes', row.Friday, s.vehicleTableRows?.maxBikes || 0) }} align='right'>{row.Friday}</TableCell>
                                    <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('bikes', row.Saturday, s.vehicleTableRows?.maxBikes || 0) }} align='right'>{row.Saturday}</TableCell>
                                    <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('bikes', row.Sunday, s.vehicleTableRows?.maxBikes || 0) }} align='right'>{row.Sunday}</TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>

                        <Grid item xs={12} lg={3} md={6} sm={12}>
                          <TableContainer component={Paper} style={{ paddingBottom: 8 }}>
                            <Typography style={{ paddingTop: 5, paddingBottom: 5, backgroundColor: alpha(colorsBuses[0], 0.6), fontWeight: 500, fontSize: 'calc(6px + 0.4vw)' }}>
                              Buses
                            </Typography>
                            <Table className={classes.table} size='small' aria-label='simple table' >
                              <TableHead>
                                <TableRow style={{}}>
                                  <TableCell padding={'none'}></TableCell>
                                  <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align='right'>Mon</TableCell>
                                  <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align='right'>Tue</TableCell>
                                  <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align='right'>Wed</TableCell>
                                  <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align='right'>Thu</TableCell>
                                  <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align='right'>Fri</TableCell>
                                  <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align='right'>Sat</TableCell>
                                  <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align='right'>Sun</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {s.vehicleTableRows?.buses.map(row =>
                                  <TableRow key={row.Time}>
                                    <TableCell padding={'none'} component='th' scope='row' style={{ padding: CELL_PAD }}>
                                      {row.Time}
                                    </TableCell>
                                    <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('buses', row.Monday, s.vehicleTableRows?.maxBuses || 0) }} align='right'>{row.Monday}</TableCell>
                                    <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('buses', row.Tuesday, s.vehicleTableRows?.maxBuses || 0) }} align='right'>{row.Tuesday}</TableCell>
                                    <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('buses', row.Wednesday, s.vehicleTableRows?.maxBuses || 0) }} align='right'>{row.Wednesday}</TableCell>
                                    <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('buses', row.Thursday, s.vehicleTableRows?.maxBuses || 0) }} align='right'>{row.Thursday}</TableCell>
                                    <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('buses', row.Friday, s.vehicleTableRows?.maxBuses || 0) }} align='right'>{row.Friday}</TableCell>
                                    <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('buses', row.Saturday, s.vehicleTableRows?.maxBuses || 0) }} align='right'>{row.Saturday}</TableCell>
                                    <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('buses', row.Sunday, s.vehicleTableRows?.maxBuses || 0) }} align='right'>{row.Sunday}</TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>

                        <Grid item xs={12} lg={3} md={6} sm={12}>
                          <TableContainer component={Paper} style={{ paddingBottom: 8 }}>
                            <Typography style={{ paddingTop: 5, paddingBottom: 5, backgroundColor: alpha(colorsCars[0], 0.6), fontWeight: 500, fontSize: 'calc(6px + 0.4vw)' }}>
                              Cars
                            </Typography>
                            <Table className={classes.table} size='small' aria-label='simple table'>
                              <TableHead>
                                <TableRow style={{}}>
                                  <TableCell padding={'none'} ></TableCell>
                                  <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align='right'>Mon</TableCell>
                                  <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align='right'>Tue</TableCell>
                                  <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align='right'>Wed</TableCell>
                                  <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align='right'>Thu</TableCell>
                                  <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align='right'>Fri</TableCell>
                                  <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align='right'>Sat</TableCell>
                                  <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align='right'>Sun</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody >
                                {s.vehicleTableRows?.cars.map(row =>
                                  <TableRow key={row.Time}>
                                    <TableCell padding={'none'} component='th' scope='row' style={{ padding: CELL_PAD }}>
                                      {row.Time}
                                    </TableCell>
                                    <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('cars', row.Monday, s.vehicleTableRows?.maxCars || 0) }} align='right'>{row.Monday}</TableCell>
                                    <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('cars', row.Tuesday, s.vehicleTableRows?.maxCars || 0) }} align='right'>{row.Tuesday}</TableCell>
                                    <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('cars', row.Wednesday, s.vehicleTableRows?.maxCars || 0) }} align='right'>{row.Wednesday}</TableCell>
                                    <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('cars', row.Thursday, s.vehicleTableRows?.maxCars || 0) }} align='right'>{row.Thursday}</TableCell>
                                    <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('cars', row.Friday, s.vehicleTableRows?.maxCars || 0) }} align='right'>{row.Friday}</TableCell>
                                    <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('cars', row.Saturday, s.vehicleTableRows?.maxCars || 0) }} align='right'>{row.Saturday}</TableCell>
                                    <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('cars', row.Sunday, s.vehicleTableRows?.maxCars || 0) }} align='right'>{row.Sunday}</TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>

                        <Grid item xs={12} lg={3} md={6} sm={12}>
                          <TableContainer component={Paper} style={{ paddingBottom: 8 }}>
                            <Typography style={{ paddingTop: 5, paddingBottom: 5, backgroundColor: alpha(colorsTrucks[0], 0.6), fontWeight: 500, fontSize: 'calc(6px + 0.4vw)' }}>
                              Trucks
                            </Typography>
                            <Table className={classes.table} size='small' aria-label='simple table'>
                              <TableHead>
                                <TableRow style={{}}>
                                  <TableCell padding={'none'} ></TableCell>
                                  <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align='right'>Mon</TableCell>
                                  <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align='right'>Tue</TableCell>
                                  <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align='right'>Wed</TableCell>
                                  <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align='right'>Thu</TableCell>
                                  <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align='right'>Fri</TableCell>
                                  <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align='right'>Sat</TableCell>
                                  <TableCell padding={'none'} style={{ padding: CELL_HEAD_PAD }} align='right'>Sun</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody >
                                {s.vehicleTableRows?.trucks.map(row =>
                                  <TableRow key={row.Time}>
                                    <TableCell padding={'none'} component='th' scope='row' style={{ padding: CELL_PAD }}>
                                      {row.Time}
                                    </TableCell>
                                    <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('trucks', row.Monday, s.vehicleTableRows?.maxTrucks || 0) }} align='right'>{row.Monday}</TableCell>
                                    <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('trucks', row.Tuesday, s.vehicleTableRows?.maxTrucks || 0) }} align='right'>{row.Tuesday}</TableCell>
                                    <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('trucks', row.Wednesday, s.vehicleTableRows?.maxTrucks || 0) }} align='right'>{row.Wednesday}</TableCell>
                                    <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('trucks', row.Thursday, s.vehicleTableRows?.maxTrucks || 0) }} align='right'>{row.Thursday}</TableCell>
                                    <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('trucks', row.Friday, s.vehicleTableRows?.maxTrucks || 0) }} align='right'>{row.Friday}</TableCell>
                                    <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('trucks', row.Saturday, s.vehicleTableRows?.maxTrucks || 0) }} align='right'>{row.Saturday}</TableCell>
                                    <TableCell padding={'none'} style={{ width: '10.5%', padding: CELL_PAD, backgroundColor: this.cellStyle('trucks', row.Sunday, s.vehicleTableRows?.maxTrucks || 0) }} align='right'>{row.Sunday}</TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>

                      </Grid>
                    </Grid>
                  ])
                }
              </ImageList>
            }
          </Grid>
        </Grid>
      )
  }
}

export default withRouter(withStyles(styles)(TimeByDayTable));
