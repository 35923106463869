import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import config from '../../utils/config';
import allLocationsArray from '../../utils/locationsArray';

import AddUser from '../auth/AddUser';
import EditUser from './EditUser';
import DisableUser from './DisableUser';
import exterosLogo from './exteros_blue.png';

import {
  alpha, Box, Collapse, Divider, Grid, IconButton, Paper, Tab, Tabs, Tooltip, Typography, withStyles,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel
} from '@material-ui/core';
import { CachedOutlined, Close, Done, EditOutlined, FileCopyOutlined, KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';

const styles = ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  tableRow: {
    '&:last-child th, &:last-child td': {
      borderBottom: 0,
    },
  },
  tableHead: {
    '& tr th': {
      fontWeight: 600
    },
  }
});

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Grid
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Grid>{children}</Grid>
        </Box>
      )}
    </Grid>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const ButtonInTabs = (props) => {
  const { children, onClick, toolTip, ...other } = props;

  return <Tooltip title={toolTip}><IconButton aria-label='refresh' onClick={onClick} children={children} style={{ marginLeft: 'auto' }} /></Tooltip>;
}
ButtonInTabs.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func.isRequired,
  toolTip: PropTypes.string.isRequired,
};

const DisableTransitionComponent = ({ children }) => children;

const getLocations = (client) => {
  try {
    let filterLocations = [];

    filterLocations = allLocationsArray.filter(loc => (client === loc.client && loc.locId)).map(l => ({ id: l.locId, name: l.name, start: l.startDate, end: l.endDate, color: l.color }));

    return filterLocations;
  } catch (error) {
    console.log('getLocations - error: ');
    console.log(error);
  }
}

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


class AdminDashboard extends Component {
  constructor() {
    super();

    this.state = {
      dataObj: {
        admin: {},
        alpha: [],
        clients: {},
        locations: {},
        usersCreated: []
      },
      openObj: {},
      order: 'desc', // 'asc', 'desc'
      orderBy: 'createdAt', // 'updatedAt', 'createdAt'
      tabIndex: 0,

      editUserEmail: '',
      editUserOpen: false,

      disableUserEmail: '',
      disableUserOpen: false
    };
  }

  componentDidMount() {
    document.title = 'Admin Dashboard - Exteros Admin';
    this.getUsers();
  }

  sortData = (dataArray, sortBy) => {
    let sortData = JSON.parse(JSON.stringify(dataArray));

    sortData.sort((a, b) => {
      return (b[sortBy] - a[sortBy]);
    });

    return sortData
  }

  groupData = (dataArray, groupBy) => {
    return dataArray.reduce((rv, x) => {
      (rv[x[groupBy]] = rv[x[groupBy]] || []).push(x);
      return rv;
    }, {});
  };

  formatUserData = async (dataObj) => {
    const dataArray = dataObj.users;
    const groupByClient = this.groupData(dataArray, 'client');

    // Create new object from fa data with each users email as key that contains lastLogin, locations, and roles as values:
      // lastLogin: if lastlogin isSame as insertInstant user has not logged in yet.
      // locations: either an empty array [] or array containing locationIds ['0012-1', '0012-2']
      // roles: empty array [] or array containing 1 or more user roles ['user_manager', 'video_streaming', 'video_only']
    const faDataObj = dataObj.fa.users.reduce((obj, item) => (
      obj[item.email] = {
        lastLogin: (moment(item.insertInstant).isSame(moment(item.lastLoginInstant), 'minute') || item.lastLoginInstant === undefined) ? 0 : item.lastLoginInstant,
        locations: item?.registrations?.[0]?.data?.locations || [], // a user can have multiple registrations, dashboard not guaranteed to be 0 but so far only applies to internal users
        roles: item?.registrations?.[0]?.roles || []
      }, obj
    ), {});

    dataArray.forEach((user) => {
      user.lastLogin = faDataObj[user.email]?.lastLogin || 0;
      user.locationsFA = JSON.stringify(faDataObj[user.email]?.locations) || '[]'; // stringify to match access control locations when displayed
      user.roles = faDataObj[user.email]?.roles || [];
    }); // add fa lastlogin and locations data for each user

    const clientLocations = {};
    const groupByAdmin = {};
    Object.keys(groupByClient).forEach((client) => {
      let admin = this.groupData(groupByClient[client], 'admin');
      groupByAdmin[client] = admin;

      let locations = getLocations(client);
      clientLocations[client] = locations;
    });

    // const sortByCreated = this.sortData(dataArray, 'createdAt');
    // const sortByUpdated = this.sortData(dataArray, 'updatedAt');

    const alphaClients = Object.keys(groupByClient).sort((a, b) => {
      return a.localeCompare(b);
    });

    const returnObj = {
      'admin': groupByAdmin,
      'alpha': alphaClients,
      'clients': groupByClient,
      'locations': clientLocations,
      'usersCreated': dataArray,
      // 'lastLogin': lastLogin,
      // 'usersCreated': sortByCreated,
      // 'usersUpdated': sortByUpdated,
    }

    return returnObj;
  }

  getUsers = async () => {
    try {
      const response = await axios.get(config.api.url + '/v2/admin/getAllUsers', {});
      const userData = await this.formatUserData(response.data);

      this.setState({ dataObj: userData });
    } catch (err) {
      console.log('getAllUsers - error: ');
      console.log(err);
    }
  }

  displayRoles = (rolesArray) => {
    if (!rolesArray.length) return '';

    const newArray = rolesArray.map(r => {
      if (r === 'user_manager') return 'A';
      if (r === 'video_streaming') return 'V';
      if (r === 'video_only') return 'OV';
      return;
    });

    return newArray.join(' - ');
  }

  handleClick = (e) => {
    this.setState({
      openObj: {
        ...this.state.openObj,
        [e]: !this.state.openObj[e]
      }
    });
  };

  collapseAll = () => {
    this.setState({ openObj: {} });
  }

  truncate = (str, n) => {
    if (n) {
      return (str.length > n) ? str.slice(0, n - 1) + '\u2026' : str;
    }

    return str.replace(/@.{3}(.*)/g.exec(str)[1], '\u2026'); // replace 2nd capture group w/ ellipse
  }

  copyToClipboard = (str) => {
    navigator.clipboard.writeText(str);
  }

  handleTabChange = (e, value) => {
    this.setState({ tabIndex: value });
  };

  editUserClick = (e) => {
    this.setState({
      editUserEmail: e?.currentTarget?.dataset?.email ?? '', // need e.currentTarget to get button event
      editUserOpen: !this.state.editUserOpen
    });
  }

  disableUserClick = (e) => {
    this.setState({
      disableUserEmail: e?.currentTarget?.dataset?.email ?? '', // need e.currentTarget to get button event
      disableUserOpen: !this.state.disableUserOpen
    });
  }

  userDisabled = () => {
    this.setState({
      disableUserEmail: '',
      disableUserOpen: false,
      openObj: {
        ...this.state.openObj,
        [this.state.disableUserEmail]: false
      },
    }, () => {
      this.getUsers();
    });
  }

  handleRequestSort = (orderById) => {
    const isAsc = (this.state.orderBy === orderById && this.state.order === 'desc');

    this.setState({
      orderBy: orderById,
      order: isAsc ? 'asc' : 'desc'
    });
  };

  render() {
    const { dataObj, disableUserEmail, disableUserOpen, editUserEmail, editUserOpen, openObj, order, orderBy, tabIndex } = this.state;
    const { classes } = this.props;

    return (
      <Grid container style={{ width: '85%', margin: '10vh auto' }} direction='column' justifyContent='center' alignItems='baseline'>

        <img alt='Logo' src={exterosLogo} style={{ marginLeft: 'auto', marginRight: 'auto', width: '25vw', height: '100%', minWidth: '280px' }} />
        <h2 style={{ margin: '20px auto', fontFamily: 'Lato,sans-serif', fontWeight: 400 }}>Admin Dashboard</h2>

        <TableContainer component={Paper}>
          <Tabs
            value={tabIndex}
            indicatorColor='primary'
            textColor='primary'
            onChange={this.handleTabChange}
          >
            <Tab label='Clients' value={0} />
            <Tab label='Users' value={1} />
            <Tab label='Add User' value={2} />

            <ButtonInTabs onClick={this.getUsers} toolTip={'Refresh Data'}><CachedOutlined color='primary' /></ButtonInTabs>
          </Tabs>

          <TabPanel value={tabIndex} index={0}>

            <Divider />
            <Typography variant='subtitle1' component='div' style={{ margin: '15px' }}>
              All Exteros Clients: each row can be expanded to view a client's active users and locations.
            </Typography>
            <Divider />

            <Table aria-label='Clients Table'>
              <TableHead className={classes.tableHead}>
                <TableRow>
                  {/* <TableCell /> */}
                  <TableCell>
                    <Tooltip title={'Collapse All'}>
                      <IconButton aria-label='collapse all' size='small' onClick={this.collapseAll}><Close /></IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell>Client</TableCell>
                  <TableCell align='center'>Admin</TableCell>
                  <TableCell align='center'>Users</TableCell>
                  <TableCell align='center'>Locations</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>

                {/* {Object.keys(dataObj.clients).map(client => ( */}
                {dataObj.alpha.map(client => (
                  <Fragment key={client}>

                    <TableRow className={classes.root} onClick={this.handleClick.bind(this, client)} style={{ cursor: 'pointer' }}>
                      <TableCell>
                        <IconButton aria-label='expand row' size='small' onClick={this.handleClick.bind(this, client)}>
                          {openObj[client] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                        </IconButton>
                      </TableCell>
                      <TableCell component='th' scope='row' style={{ textTransform: 'uppercase' }}>
                        {client}
                      </TableCell>
                      <TableCell align='center'>{Object.keys(dataObj.admin[client]).length}</TableCell>
                      <TableCell align='center'>{Object.keys(dataObj.clients[client]).length}</TableCell>
                      <TableCell align='center'>{Object.keys(dataObj.locations[client]).length}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                        <Collapse in={openObj[client]} timeout='auto' unmountOnExit>

                          <Box margin={2}>
                            <Typography variant='h6' gutterBottom component='div' onClick={this.handleClick.bind(this, client + '-users')} style={{ cursor: 'pointer' }}>
                              Active Users
                              <IconButton aria-label='expand row' size='small' onClick={this.handleClick.bind(this, client + '-users')}>
                                {openObj[client + '-users'] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                              </IconButton>
                            </Typography>

                            {openObj[client + '-users'] &&
                              <Collapse in={openObj[client + '-users']} timeout='auto' unmountOnExit>
                                <Table size='small' aria-label='Active Users'>
                                  <TableHead className={classes.tableHead}>
                                    <TableRow>
                                      <TableCell>Name</TableCell>
                                      <TableCell>Email<Tooltip title={'Click field to copy'}><FileCopyOutlined style={{ margin: '0 4px', fontSize: 13, cursor: 'help' }} /></Tooltip></TableCell>
                                      <TableCell>Admin<Tooltip title={'Click field to copy'}><FileCopyOutlined style={{ margin: '0 4px', fontSize: 13, cursor: 'help' }} /></Tooltip></TableCell>
                                      <TableCell>Company<Tooltip title={'Click field to copy'}><FileCopyOutlined style={{ margin: '0 4px', fontSize: 13, cursor: 'help' }} /></Tooltip></TableCell>
                                      <TableCell align='center'>Created</TableCell>
                                      <TableCell align='center'>Active</TableCell>
                                      <TableCell align='center'>Dashboard</TableCell>
                                      <TableCell align='center'>Reports</TableCell>
                                      <TableCell align='center'>Roles</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>

                                    {Object.entries(dataObj.clients[client]).map(([key, value]) => (

                                      <TableRow key={key} className={classes.tableRow}>
                                        <TableCell component='th' scope='row'>
                                          {value.fullname}
                                        </TableCell>
                                        <Tooltip title={<p style={{ fontSize: 14, margin: '5px 0' }}>{value.email}</p>} TransitionComponent={DisableTransitionComponent}>
                                          <TableCell onClick={this.copyToClipboard.bind(this, value.email)} style={{ cursor: 'pointer' }}>{this.truncate(value.email)}</TableCell>
                                        </Tooltip>
                                        <Tooltip title={(value.admin !== value.email) ? <p style={{ fontSize: 14, margin: '5px 0' }}>{value.admin}</p> : ''} TransitionComponent={DisableTransitionComponent}>
                                          <TableCell onClick={this.copyToClipboard.bind(this, value.admin)} style={{ cursor: 'pointer' }}>{(value.admin !== value.email) ? this.truncate(value.admin) : '-'}</TableCell>
                                        </Tooltip>
                                        <Tooltip title={(value.company !== '') ? <p style={{ fontSize: 14, margin: '5px 0' }}>{value.company}</p> : ''} TransitionComponent={DisableTransitionComponent}>
                                          <TableCell onClick={this.copyToClipboard.bind(this, value.company)} style={{ cursor: 'pointer' }}>{this.truncate(value.company, 10)}</TableCell>
                                        </Tooltip>
                                        <TableCell align='center'>{moment.unix(value.createdAt).tz('America/New_York').format('MM-DD-YY')}</TableCell>
                                        {/* <TableCell align='center'>{(value.createdAt !== value.updatedAt) ? moment.unix(value.updatedAt).tz('America/New_York').format('MM-DD-YY') : '-'}</TableCell> */}
                                        <TableCell align='center'>{(value.lastLogin !== 0) ? moment(value.lastLogin).tz('America/New_York').format('MM-DD-YY') : '-'}</TableCell>
                                        <TableCell align='center'>{(value.dashboardaccess) ? '' : 'F'}</TableCell>
                                        <TableCell align='center'>{(value.emailreports) ? '' : 'F'}</TableCell>
                                        <Tooltip title={(value.roles.length) ? <p style={{ fontSize: 14, margin: '5px 0' }}>{JSON.stringify(value.roles)}</p> : ''} TransitionComponent={DisableTransitionComponent}>
                                          <TableCell align='center'>{this.displayRoles(value.roles)}</TableCell>
                                        </Tooltip>
                                      </TableRow>

                                    ))}

                                  </TableBody>
                                </Table>
                              </Collapse>
                            }
                          </Box>

                          <Box margin={2}>
                            <Typography variant='h6' gutterBottom component='div' onClick={this.handleClick.bind(this, client + '-locations')} style={{ cursor: 'pointer' }}>
                              Locations
                              <IconButton aria-label='expand row' size='small' onClick={this.handleClick.bind(this, client + '-locations')}>
                                {openObj[client + '-locations'] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                              </IconButton>
                            </Typography>

                            {openObj[client + '-locations'] &&
                              <Collapse in={openObj[client + '-locations']} timeout='auto' unmountOnExit>
                                <Table size='small' aria-label='Locations'>
                                  <TableHead className={classes.tableHead}>
                                    <TableRow>
                                      <TableCell>ID</TableCell>
                                      <TableCell>Name</TableCell>
                                      <TableCell>Start Date</TableCell>
                                      <TableCell>End Date</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>

                                    {Object.entries(dataObj.locations[client]).map(([key, value]) => (
                                      <TableRow key={key} className={classes.tableRow} style={{ backgroundColor: alpha(value.color, 0.6) }}>
                                        <TableCell component='th' scope='row'>
                                          {value.id}
                                        </TableCell>
                                        <TableCell>{value.name}</TableCell>
                                        <TableCell>{moment(value.start).tz('America/New_York').format('MMM. Do, \'YY')}</TableCell>
                                        <TableCell>{(value.end) ? moment(value.end).tz('America/New_York').format('MMM. Do, \'YY') : '-'}</TableCell>
                                      </TableRow>
                                    ))}

                                  </TableBody>
                                </Table>
                              </Collapse>
                            }
                          </Box>

                        </Collapse>
                      </TableCell>
                    </TableRow>

                  </Fragment>
                ))}

              </TableBody>
            </Table>
          </TabPanel>


          <TabPanel value={tabIndex} index={1}>
            {(tabIndex === 1) &&
              <>
                <Divider />
                <Typography variant='subtitle1' component='div' style={{ margin: '15px' }}>
                  All Exteros Users: toggle sort order by clicking 'Created' or 'Updated' fields. Clicking a user's email copies the full email to the clipboard.
                  Last Active field tracks the last time a user logged in or used refresh token (tokens expire every 60min).
                </Typography>
                <Divider />

                <Table aria-label='Users table'>
                  <TableHead className={classes.tableHead}>
                    <TableRow>
                      <TableCell>
                        <Tooltip title={'Collapse All'}>
                          <IconButton aria-label='collapse all' size='small' onClick={this.collapseAll}><Close /></IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Email<Tooltip title={'Click field to copy'}><FileCopyOutlined style={{ margin: '0 4px', fontSize: 13, cursor: 'help' }} /></Tooltip></TableCell>
                      <TableCell>Client</TableCell>

                      <TableCell key='createdAt' align='center' sortDirection={orderBy === 'createdAt' ? order : false}>
                        <TableSortLabel
                          active={orderBy === 'createdAt'}
                          direction={orderBy === 'createdAt' ? order : 'desc'}
                          onClick={this.handleRequestSort.bind(this, 'createdAt')}
                        >
                          Created
                        </TableSortLabel>
                      </TableCell>
                      <TableCell key='updatedAt' align='center' padding='normal' sortDirection={orderBy === 'updatedAt' ? order : false}>
                        <TableSortLabel
                          active={orderBy === 'updatedAt'}
                          direction={orderBy === 'updatedAt' ? order : 'desc'}
                          onClick={this.handleRequestSort.bind(this, 'updatedAt')}
                        >
                          Updated
                        </TableSortLabel>
                      </TableCell>
                      <TableCell key='lastLogin' align='center' padding='normal' sortDirection={orderBy === 'lastLogin' ? order : false}>
                        <TableSortLabel
                          active={orderBy === 'lastLogin'}
                          direction={orderBy === 'lastLogin' ? order : 'desc'}
                          onClick={this.handleRequestSort.bind(this, 'lastLogin')}
                        >
                          Last Active
                        </TableSortLabel>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>

                    {stableSort(dataObj.usersCreated, getComparator(order, orderBy)).map(user => (
                      <Fragment key={user.email}>
                        <TableRow className={classes.root} onClick={this.handleClick.bind(this, user.email)}>
                          <TableCell>
                            <IconButton aria-label='expand row' size='small' onClick={this.handleClick.bind(this, user.email)}>
                              {openObj[user.email] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                            </IconButton>
                          </TableCell>

                          <TableCell component='th' scope='row'>{user.fullname}</TableCell>

                          <Tooltip title={<p style={{ fontSize: 14, margin: '5px 0' }}>{user.email}</p>} TransitionComponent={DisableTransitionComponent}>
                            <TableCell onClick={this.copyToClipboard.bind(this, user.email)} style={{ cursor: 'pointer' }}>{this.truncate(user.email)}</TableCell>
                          </Tooltip>

                          <TableCell>{user.client}</TableCell>
                          <TableCell align='center'>{moment.unix(user.createdAt).tz('America/New_York').format('MM-DD-YY')}</TableCell>
                          <TableCell align='center'>{(user.createdAt !== user.updatedAt) ? moment.unix(user.updatedAt).tz('America/New_York').format('MM-DD-YY') : '-'}</TableCell>
                          <TableCell align='center'>{(user.lastLogin !== 0) ? moment(user.lastLogin).tz('America/New_York').format('MM-DD-YY') : '-'}</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                            <Collapse in={openObj[user.email]} timeout='auto' unmountOnExit>
                              <Box style={{ margin: '0 16px 8px' }}>
                                <Table size='small' aria-label='User Info'>
                                  <TableBody>
                                    <TableRow>
                                      <TableCell style={{ width: 150, fontWeight: 600 }}>Last Active</TableCell>
                                      <TableCell>{(user.lastLogin !== 0) ? moment(user.lastLogin).tz('America/New_York').format('MM/DD/YY h:mma') : '-'}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell style={{ fontWeight: 600 }}>Email</TableCell>
                                      <TableCell onClick={this.copyToClipboard.bind(this, user.email)} style={{ cursor: 'pointer' }}>{user.email}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell style={{ fontWeight: 600 }}>Admin</TableCell>
                                      <TableCell onClick={this.copyToClipboard.bind(this, user.admin)} style={{ cursor: 'pointer' }}>{user.admin}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell style={{ fontWeight: 600 }}>Company</TableCell>
                                      <TableCell>{user.company}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell style={{ fontWeight: 600 }}>Dashboard Access</TableCell>
                                      <TableCell>{(user.dashboardaccess) ? <Done /> : <Close color='error' />}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell style={{ fontWeight: 600 }}>Email Reports</TableCell>
                                      <TableCell>{user.emailreports.toString()}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell style={{ fontWeight: 600 }}>All Locations</TableCell>
                                      <TableCell>{user.locationsAll?.toString() ?? '-'}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell style={{ fontWeight: 600 }}>AC Locations</TableCell>
                                      <TableCell>{user.locations}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell style={{ fontWeight: 600 }}>FA Locations</TableCell>
                                      <TableCell>{user.locationsFA}</TableCell>
                                    </TableRow>
                                    {user.roles.length > 0 &&
                                      <TableRow>
                                        <TableCell style={{ fontWeight: 600 }}>Roles</TableCell>
                                        <TableCell>{JSON.stringify(user.roles)}</TableCell>
                                      </TableRow>
                                    }
                                    <TableRow>
                                      <TableCell style={{ fontWeight: 600 }}>Edit User</TableCell>
                                      <TableCell>
                                        <IconButton data-email={user.email} aria-label='edit user' size='small' onClick={this.editUserClick}><EditOutlined /></IconButton>
                                      </TableCell>
                                    </TableRow>
                                    <TableRow className={classes.tableRow}>
                                      <TableCell style={{ fontWeight: 600 }}>Disable User</TableCell>
                                      <TableCell>
                                        <IconButton data-admin={user.admin} data-email={user.email} aria-label='disable user' size='small' onClick={this.disableUserClick}><Close /></IconButton>
                                      </TableCell>
                                    </TableRow>

                                    {(editUserOpen && editUserEmail === user.email) &&
                                      <EditUser
                                        {...user}
                                        onClose={this.editUserClick}
                                        userUpdated={this.getUsers}
                                      />
                                    }

                                    {(disableUserOpen && disableUserEmail === user.email) &&
                                      <DisableUser
                                        admin={user.admin}
                                        email={user.email}
                                        onClose={this.disableUserClick}
                                        userDisabled={this.userDisabled}
                                      />
                                    }

                                  </TableBody>
                                </Table>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>

                      </Fragment>
                    ))}

                  </TableBody>
                </Table>
              </>
            }
          </TabPanel>

          <TabPanel value={tabIndex} index={2}>

            <Divider />
            <Typography variant='subtitle1' component='div' style={{ margin: '15px' }}>
              Add New User: 'admin accounts' are not included on client or users tab, all other users should appear after adding.
            </Typography>
            <Divider />

            <AddUser
              dashboardView={true}
              userAdded={this.getUsers} />

          </TabPanel>
        </TableContainer>

      </Grid>
    );
  }
}

AdminDashboard.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(withStyles(styles)(AdminDashboard));
