import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { Bar, XAxis, YAxis, BarChart, ResponsiveContainer } from 'recharts';

import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { reduceArray, getColor } from '../../utils/helpers';
import { colorsMen, colorsWomen, colorsChildren, colorsAdults, colorsSeniors, colorsBikes, colorsBuses, colorsCars, colorsTrucks } from '../../utils/colors';

const styles = theme => ({});


class TinyByDayOfWeek extends PureComponent {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    const { age, gender, mode, multDates, numPlots, openTab, vehicles, series } = this.props;

    let [dataPoints, secondDateCardIndex] = [0, 0];
    try { secondDateCardIndex = series.length - 1; }
    catch (e) {}

    let chartArray = reduceArray(series, numPlots, multDates, secondDateCardIndex);
    try {
      for (let ser in chartArray) {
        if (chartArray[ser].data.length > dataPoints) {
          dataPoints = chartArray[ser].data.length;
        }
      }
    } catch (err) {}

    return (
      <Grid container item xs justifyContent='space-around' alignItems='center' style={{ height: '10vh', paddingTop: '2vh', paddingBottom: '3vh', paddingLeft: '2vw', paddingRight: '2vw', textAlign: 'center' }}>
        <Typography style={{ fontSize: 'calc(2px + 1.0vw)', cursor: 'pointer' }}>
          {(this.props.occupancy)
            ? <>Occupancy by<br />Day of Week</>
            : <>{mode} by<br />Day of Week</>
          }
        </Typography>
        {dataPoints === 0 &&
          <Grid container justifyContent='space-around' alignItems='center'>
            <CircularProgress size={'calc(1vw + 1vh)'} style={{ marginTop: 'calc(1vw + 2vh)' }} />
          </Grid>
        }
        {dataPoints > 0 &&
          <ResponsiveContainer width={'100%'} height={'100%'} minHeight={0}>
            <BarChart barGap={0} style={{ cursor: 'pointer' }} data={chartArray} margin={{ top: 10, right: 2, left: 2, bottom: 0 }}>
              <XAxis data={chartArray} dataKey='dayOfWeekName' allowDuplicatedCategory={false} hide={true} />
              <YAxis hide={true} domain={[0, dataMax => Math.ceil((dataMax * 1.2) / parseInt('1'.padEnd(Math.log(dataMax) * Math.LOG10E + 1 | 0, '0'))) * parseInt('1'.padEnd(Math.log(dataMax) * Math.LOG10E + 1 | 0, '0'))]} />
              {openTab === 0 &&
                chartArray.map((s, idx) => (
                  [
                    <Bar isAnimationActive={true} data={s.data} dataKey='count' name={s.name + 1} hide={!this.props.count || s.data.length === 0} stroke={getColor(s.locationLabels, idx, this.props.locationsObject, chartArray)}
                      fill={getColor(s.locationLabels, idx, this.props.locationsObject, chartArray)} strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''} />,
                  ]
                ))
              }
              {openTab === 1 && !vehicles &&
                chartArray.map((s, idx) => (
                  [
                    <Bar isAnimationActive={true} data={s.data} dataKey='women' name={s.name + 3} hide={!gender || s.data.length === 0} stroke={colorsWomen[idx]}
                      fill={colorsWomen[idx]} strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''} />,

                    <Bar isAnimationActive={true} data={s.data} dataKey='men' name={s.name + 2} hide={!gender || s.data.length === 0} stroke={colorsMen[idx]}
                      fill={colorsMen[idx]} strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''} />,
                  ]
                ))
              }
              {openTab === 1 && vehicles &&
                chartArray.map((s, idx) => (
                  [
                    <Bar isAnimationActive={true} data={s.data} dataKey='bikes' name={s.name + 7} hide={!gender || s.data.length === 0} stroke={colorsBikes[idx]}
                      fill={colorsBikes[idx]} strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''} />,

                    <Bar isAnimationActive={true} data={s.data} dataKey='buses' name={s.name + 8} hide={!gender || s.data.length === 0} stroke={colorsBuses[idx]}
                      fill={colorsBuses[idx]} strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''} />,

                    <Bar isAnimationActive={true} data={s.data} dataKey='cars' name={s.name + 9} hide={!gender || s.data.length === 0} stroke={colorsCars[idx]}
                      fill={colorsCars[idx]} strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''} />,

                    <Bar isAnimationActive={true} data={s.data} dataKey='trucks' name={s.name + 10} hide={!gender || s.data.length === 0} stroke={colorsTrucks[idx]}
                      fill={colorsTrucks[idx]} strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''} />,
                  ]
                ))
              }
              {openTab === 2 && !vehicles &&
                chartArray.map((s, idx) => (
                  [
                    <Bar isAnimationActive={true} data={s.data} dataKey='children' name={s.name + 4} hide={!age || s.data.length === 0} stroke={colorsChildren[idx]}
                      fill={colorsChildren[idx]} strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''} />,

                    <Bar isAnimationActive={true} data={s.data} dataKey='adults' name={s.name + 5} hide={!age || s.data.length === 0} stroke={colorsAdults[idx]}
                      fill={colorsAdults[idx]} strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''} />,

                    <Bar isAnimationActive={true} data={s.data} dataKey='seniors' name={s.name + 6} hide={!age || s.data.length === 0} stroke={colorsSeniors[idx]}
                      fill={colorsSeniors[idx]} strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''} />
                  ]
                ))
              }
            </BarChart>
          </ResponsiveContainer>
        }
      </Grid>
    )
  }
}

export default withRouter(withStyles(styles)(TinyByDayOfWeek));
