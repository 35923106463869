import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { alpha, Grid, Typography, withStyles } from '@material-ui/core';

import { clientData } from '../../../utils/clients';
import { getImage } from '../../../utils/reportHelpers';

import ByDateReport from './ByDateLine';
import ByTimeAllReport from './ByTimeAreaAll';
import MonthlyAveragePie from './MonthlyAveragePie';
import ByTimeReport from './ByTimeArea';
import ByDayOfWeekReport from './DayOfWeekBar';
import ByDayHourlyTable from './HourlyByDayTable';

const styles = ({
  highlights: {
    fontFamily: 'Nunito Sans',
    fontSize: 14,
    fontWeight: 400,
    width: '100%',
    // maxHeight: 215,
    maxHeight: 190,
    overflow: 'hidden',
    paddingLeft: 0,
    whiteSpace: 'pre-wrap',
    '&:focus': {
      paddingLeft: 2, // needed to make cursor visible on chrome at start of newline
    },
  },
});


class SingleLocationReport extends PureComponent {

  render() {
    const {
      byDateChartData, byDateData, byTimeChartData, byTimeData, change, chartColors, classes, client, dateLabels, detailed, isFlagship, isPuppeteer,
      locationLabels, pageHeight, pageWidth, reportByWeek, reportClient, reportDateInterval, reportHighlights, reportTitle, showDemoArray } = this.props;

    return (
      <>
        <Grid container direction='column' justifyContent='center' alignItems='center' style={{ width: pageWidth, height: pageHeight, backgroundColor: 'white' }}>
          <Grid container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ width: pageWidth * 0.938, height: pageHeight * 0.9545, marginTop: pageWidth / 32, marginBottom: pageWidth / 32, backgroundColor: 'white' }}>

            <Grid container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ width: pageWidth * 0.938, height: pageHeight / 22 }}>
              <Grid item container direction='row' justifyContent='flex-start' alignItems='flex-start' style={{ width: '65%' }}>
                <Grid item container style={{ width: '100%' }}>
                  <Typography style={{ marginTop: -7, fontWeight: 700, fontSize: Math.min(30, Math.max(15, (1000 / locationLabels[0].length))), fontFamily: 'Rajdhani' }}>{locationLabels[0]}</Typography>
                </Grid>
                <Typography style={{ marginTop: -7, fontWeight: 500, fontSize: 18, fontFamily: 'Rajdhani' }}>{dateLabels.mainHeader}</Typography>
              </Grid>
              <Grid item container direction='row' justifyContent='flex-end' alignItems='flex-start' style={{ height: '100%', width: '35%' }}>
                <img style={{ height: '100%' }} alt='Client Logo' src={this.props.auth.user.email.includes('absre') ? getImage('usp/absreLogo.png', false) : getImage(clientData[reportClient].reports.logo, false)} />
              </Grid>
            </Grid>

            {/* AVERAGES HEADER */}
            <Grid container item direction='row' justifyContent='space-between' alignItems='flex-start' style={{ width: pageWidth * 0.938, height: pageHeight * 0.23, marginTop: 0 }}>
              <Grid item container direction='row' justifyContent='space-between' alignItems='flex-start' style={{ width: pageWidth * 0.938, height: '100%' }}>
                <Grid item container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ width: '53%', height: '100%', marginRight: '0%' }}>

                  <Grid item container direction='row' justifyContent='space-between' alignItems='flex-start' style={{ marginTop: 0, height: !showDemoArray[0] ? pageHeight / 7.5 : pageHeight / 10, width: '100%' }}>
                    <Grid item container direction='column' justifyContent='center' alignItems='center' style={{ width: '32%', height: '100%', backgroundColor: '#efefefff', paddingBottom: 10 }}>
                      <Typography style={{ paddingLeft: 20, width: '100%', marginTop: !showDemoArray[0] ? 16 : 8, fontWeight: 700, fontSize: 24, fontFamily: 'Nunito Sans' }}>{byDateData[0].avgsFormatted.dailyAvg}&thinsp;<small>{(byDateData[0].avgs.dailyAvg >= 1000) ? 'K' : ''}</small></Typography>
                      <Typography style={{ paddingLeft: 20, width: '100%', marginTop: -8, fontWeight: 400, fontSize: 12, fontFamily: 'Nunito Sans' }}>Daily Avg</Typography>
                      <Typography style={{ display: reportClient === 'lee' || isFlagship || !change ? 'none' : '', paddingLeft: 20, width: '100%', marginTop: !showDemoArray[0] ? 16 : 2, fontWeight: 600, fontSize: 20, fontFamily: 'Nunito Sans', color: (byDateData[0].perChange.dailyAvg >= 0) ? '#56993B' : '#BF3E37' }}>{(byDateData[0].perChange.dailyAvg >= 0) ? '+' : ''}{(byDateData[0].perChange.dailyAvg).toFixed(1)}<small>%</small></Typography>
                      <Typography style={{ display: reportClient === 'lee' || isFlagship || !change ? 'none' : '', paddingLeft: 20, width: '100%', marginTop: -5, fontWeight: 400, fontSize: 10, fontFamily: 'Nunito Sans' }}>vs. prev {dateLabels.lower}</Typography>
                    </Grid>

                    <Grid item container direction='column' justifyContent='center' alignItems='center' style={{ width: '32%', height: '100%', backgroundColor: '#efefefff', paddingBottom: 10 }}>
                      <Typography style={{ paddingLeft: 20, width: '100%', marginTop: !showDemoArray[0] ? 16 : 8, fontWeight: 700, fontSize: 24, fontFamily: 'Nunito Sans' }}>{byDateData[0].avgsFormatted.wkdayAvg}&thinsp;<small>{(byDateData[0].avgs.wkdayAvg >= 1000) ? 'K' : ''}</small></Typography>
                      <Typography style={{ paddingLeft: 20, width: '100%', marginTop: -8, fontWeight: 400, fontSize: 12, fontFamily: 'Nunito Sans' }}>Wkday Avg</Typography>
                      <Typography style={{ display: reportClient === 'lee' || isFlagship || !change ? 'none' : '', paddingLeft: 20, width: '100%', marginTop: !showDemoArray[0] ? 16 : 2, fontWeight: 600, fontSize: 20, fontFamily: 'Nunito Sans', color: (byDateData[0].perChange.wkdayAvg >= 0) ? '#56993B' : '#BF3E37' }}>{(byDateData[0].perChange.wkdayAvg >= 0) ? '+' : ''}{(byDateData[0].perChange.wkdayAvg).toFixed(1)}<small>%</small></Typography>
                      <Typography style={{ display: reportClient === 'lee' || isFlagship || !change ? 'none' : '', paddingLeft: 20, width: '100%', marginTop: -5, fontWeight: 400, fontSize: 10, fontFamily: 'Nunito Sans' }}>vs. prev {dateLabels.lower}</Typography>
                    </Grid>

                    <Grid item container direction='column' justifyContent='center' alignItems='center' style={{ width: '32%', height: '100%', backgroundColor: '#efefefff', paddingBottom: 10 }}>
                      <Typography style={{ paddingLeft: 20, width: '100%', marginTop: !showDemoArray[0] ? 16 : 8, fontWeight: 700, fontSize: 24, fontFamily: 'Nunito Sans' }}>{byDateData[0].avgsFormatted.wkendAvg}&thinsp;<small>{(byDateData[0].avgs.wkendAvg >= 1000) ? 'K' : ''}</small></Typography>
                      <Typography style={{ paddingLeft: 20, width: '100%', marginTop: -8, fontWeight: 400, fontSize: 12, fontFamily: 'Nunito Sans' }}>Wkend Avg</Typography>
                      <Typography style={{ display: reportClient === 'lee' || isFlagship || !change ? 'none' : '', paddingLeft: 20, width: '100%', marginTop: !showDemoArray[0] ? 16 : 2, fontWeight: 600, fontSize: 20, fontFamily: 'Nunito Sans', color: (byDateData[0].perChange.wkendAvg >= 0) ? '#56993B' : '#BF3E37' }}>{(byDateData[0].perChange.wkendAvg >= 0) ? '+' : ''}{(byDateData[0].perChange.wkendAvg).toFixed(1)}<small>%</small></Typography>
                      <Typography style={{ display: reportClient === 'lee' || isFlagship || !change ? 'none' : '', paddingLeft: 20, width: '100%', marginTop: -5, fontWeight: 400, fontSize: 10, fontFamily: 'Nunito Sans' }}>vs. prev {dateLabels.lower}</Typography>
                    </Grid>
                  </Grid>

                  <Grid item container direction='row' justifyContent='space-between' alignItems='flex-start' style={{ marginTop: 0, height: !showDemoArray[0] ? pageHeight / 12 : pageHeight / 16, width: '100%' }}>
                    <Grid container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ width: '49%', height: '100%', backgroundColor: '#efefefff' }}>
                      <Typography style={{ paddingLeft: 20, marginTop: !showDemoArray[0] ? 18 : 8, fontWeight: 700, fontSize: 24, fontFamily: 'Nunito Sans' }}>{byTimeData[0].busiestHr.hr}&thinsp;<small>{byTimeData[0].busiestHr.suffix}</small></Typography>
                      <Typography style={{ paddingLeft: 20, marginTop: -6, fontWeight: 400, fontSize: 12, fontFamily: 'Nunito Sans' }}>Busiest Hour</Typography>
                    </Grid>
                    <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ width: '49%', height: '100%', backgroundColor: '#efefefff' }}>
                      <Typography style={{ paddingLeft: 20, marginTop: !showDemoArray[0] ? 18 : 8, fontWeight: 700, fontSize: 24, fontFamily: 'Nunito Sans' }}>{byDateData[0].busiestDay}</Typography>
                      <Typography style={{ paddingLeft: 20, marginTop: -6, fontWeight: 400, fontSize: 12, fontFamily: 'Nunito Sans' }}>Busiest Day</Typography>
                    </Grid>
                  </Grid>

                  <Grid item container direction='row' justifyContent='space-between' alignItems='flex-start' style={{ display: showDemoArray[0] ? '' : 'none', marginTop: 5, marginBottom: 5, width: '100%', height: pageHeight / 20 }}>
                    <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ marginTop: 0, width: '49%', height: pageHeight / 20 }}>
                      <MonthlyAveragePie
                        age={false} gender={true}
                        series={[{ data: [{ genderPie: byDateData[0].genderPie }] }]} />
                    </Grid>

                    <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ marginTop: 0, width: '49%', height: pageHeight / 20 }}>
                      <MonthlyAveragePie
                        age={true} gender={false}
                        series={[{ data: [{ agePie: byDateData[0].agePie }] }]} />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item container direction='row' justifyContent='flex-start' alignItems='flex-start' style={{ width: '45%', height: '100%', marginLeft: '1%' }}>
                  <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start' style={{ width: '100%', height: '100%' }}>
                    <img style={{ height: '100%', width: '100%', objectFit: 'cover' }} alt='Client Logo' src={getImage(client + '/' + locationLabels[0].replace('ä', 'a').replaceAll(/[\s\+\']+/g, '_') + '_Square.jpg', true)} />
                  </Grid>
                </Grid>

              </Grid>
            </Grid>

            {/* ROW 2 */}
            <Grid item container direction='row' justifyContent='space-between' alignItems='flex-start' style={{ marginTop: 5, height: pageHeight / 5, width: '100%' }}>
              <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start' style={{ width: '48%', height: '100%' }}>
                <Typography style={{ width: '100%', paddingLeft: 0, fontWeight: 700, fontSize: 17, fontFamily: 'Nunito Sans' }}>Highlights</Typography>

                {/* Used by Puppeteer with customized highlights section, reportHighlights will always be empty when using dashboard */}
                {reportHighlights !== '' &&
                  <Typography className={classes.highlights} component='div' dangerouslySetInnerHTML={{ __html: reportHighlights }} />
                }

                {reportHighlights === '' &&
                  <Typography className={classes.highlights} contentEditable suppressContentEditableWarning onBlur={this.props.handleHighlightsChange}>
                    {reportByWeek < 3 ? 'During the ' + dateLabels.lower + ' of ' : ''}{dateLabels.highlights}, {locationLabels[0]} pedestrian traffic totaled {byDateData[0].totalTraffic.toLocaleString()} people.
                  </Typography>
                }
              </Grid>

              <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ width: '49%', height: '100%' }}>
                <Grid container justifyContent='flex-start' alignItems='flex-start' style={{ width: '100%' }}>
                  <ByTimeAllReport
                    series={byTimeChartData} />
                </Grid>
              </Grid>
            </Grid>

            {/* BY DATE ROW */}
            <Grid item container direction='row' justifyContent='space-between' alignItems='flex-start' style={{ marginTop: 0, height: pageHeight / 2.9, width: '100%' }}>
              <ByDateReport
                series={byDateChartData}
                // interval={reportByWeek}
                interval={reportDateInterval} />
            </Grid>

            {/* FOOTER */}
            <Grid container item direction='column' justifyContent='space-between' alignItems='flex-start' style={{ width: pageWidth * 0.938, height: pageHeight * 0.05 }}>
              <Grid container direction='row' justifyContent='space-between' alignItems='flex-start' style={{ width: pageWidth * 0.938, height: 1 }}></Grid>
              <Grid container direction='column' justifyContent='flex-end' alignItems='flex-start' style={{ width: pageWidth * 0.938, height: pageHeight / 22 }}>
                <Grid item container direction='row' justifyContent='flex-start' alignItems='flex-start' style={{ width: '50%' }}>
                  <Typography style={{ marginTop: 0, marginBottom: -4, fontWeight: 700, fontSize: 18, fontFamily: 'Rajdhani' }}>Traffic Report</Typography>
                </Grid>

                <Grid item container direction='row' justifyContent='flex-end' alignItems='flex-start' style={{ height: '100%', width: '50%' }}>
                  <img style={{ height: '100%' }} alt='Client Logo' src={require('../../layout/exteros_blue.png')} />
                </Grid>
              </Grid>
            </Grid>

          </Grid>
        </Grid>

        {/* 2nd page if detailed report requested */}
        {detailed &&
          <>
            {!isPuppeteer &&
              <Grid style={{ height: 'calc(100px + 0vh)' }}></Grid> // spacing between pages
            }

            <Grid container direction='column' justifyContent='center' alignItems='center' style={{ width: pageWidth, height: pageHeight, backgroundColor: 'white' }}>
              <Grid container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ width: pageWidth * 0.938, height: pageHeight * 0.9545, marginTop: pageWidth / 32, marginBottom: pageWidth / 32 }}>

                {/* AVERAGES HEADER */}
                <Grid item container direction='row' justifyContent='space-between' alignItems='flex-start' style={{ width: pageWidth * 0.938, height: pageHeight * 0.296 }}>
                  <Grid item container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ width: '53%', height: '100%', marginRight: '0%' }}>

                    <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ width: '100%', height: reportClient === 'lee' || isFlagship ? pageHeight / 16 : pageHeight / 18, backgroundColor: alpha(chartColors[0], 0.6) }}>
                      <Typography
                        style={locationLabels[0].length < 40
                          ? { paddingLeft: 10, marginTop: reportClient === 'lee' || isFlagship ? 9 : 5, fontWeight: 600, fontSize: 23, fontFamily: 'Rajdhani' }
                          : { paddingLeft: 10, marginTop: reportClient === 'lee' || isFlagship ? 9 : 5, fontWeight: 600, fontSize: 20, fontFamily: 'Rajdhani' }
                        }>
                        {locationLabels[0]}
                      </Typography>
                      <Typography style={{ paddingLeft: 10, marginTop: -5, fontWeight: 400, fontSize: 13, fontFamily: 'Nunito Sans' }}>{dateLabels.mainHeader}</Typography>
                    </Grid>

                    <Grid item container direction='row' justifyContent='space-between' alignItems='flex-start' style={{ marginTop: 0, height: reportClient === 'lee' || isFlagship ? pageHeight / 12 : pageHeight / 10, width: '100%' }}>
                      <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ width: '32%', height: '100%', backgroundColor: '#efefefff' }}>
                        <Typography style={{ paddingLeft: 20, width: '100%', marginTop: reportClient === 'lee' || isFlagship ? 20 : 8, fontWeight: 700, fontSize: 24, fontFamily: 'Nunito Sans' }}>{byDateData[0].avgsFormatted.dailyAvg}&thinsp;<small>{(byDateData[0].avgs.dailyAvg >= 1000) ? 'K' : ''}</small></Typography>
                        <Typography style={{ paddingLeft: 20, width: '100%', marginTop: -8, fontWeight: 400, fontSize: 12, fontFamily: 'Nunito Sans' }}>Daily Avg</Typography>
                        <Typography style={{ display: reportClient === 'lee' || isFlagship || !change ? 'none' : '', paddingLeft: 20, width: '100%', marginTop: 2, fontWeight: 600, fontSize: 20, fontFamily: 'Nunito Sans', color: (byDateData[0].perChange.dailyAvg >= 0) ? '#56993B' : '#BF3E37' }}>{(byDateData[0].perChange.dailyAvg >= 0) ? '+' : ''}{(byDateData[0].perChange.dailyAvg).toFixed(1)}<small>%</small></Typography>
                        <Typography style={{ display: reportClient === 'lee' || isFlagship || !change ? 'none' : '', paddingLeft: 20, width: '100%', marginTop: -5, fontWeight: 400, fontSize: 10, fontFamily: 'Nunito Sans' }}>vs. prev {dateLabels.lower}</Typography>
                      </Grid>
                      <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ width: '32%', height: '100%', backgroundColor: '#efefefff' }}>
                        <Typography style={{ paddingLeft: 20, width: '100%', marginTop: reportClient === 'lee' || isFlagship ? 20 : 8, fontWeight: 700, fontSize: 24, fontFamily: 'Nunito Sans' }}>{byDateData[0].avgsFormatted.wkdayAvg}&thinsp;<small>{(byDateData[0].avgs.wkdayAvg >= 1000) ? 'K' : ''}</small></Typography>
                        <Typography style={{ paddingLeft: 20, width: '100%', marginTop: -8, fontWeight: 400, fontSize: 12, fontFamily: 'Nunito Sans' }}>Wkday Avg</Typography>
                        <Typography style={{ display: reportClient === 'lee' || isFlagship || !change ? 'none' : '', paddingLeft: 20, width: '100%', marginTop: 2, fontWeight: 600, fontSize: 20, fontFamily: 'Nunito Sans', color: (byDateData[0].perChange.wkdayAvg >= 0) ? '#56993B' : '#BF3E37' }}>{(byDateData[0].perChange.wkdayAvg >= 0) ? '+' : ''}{(byDateData[0].perChange.wkdayAvg).toFixed(1)}<small>%</small></Typography>
                        <Typography style={{ display: reportClient === 'lee' || isFlagship || !change ? 'none' : '', paddingLeft: 20, width: '100%', marginTop: -5, fontWeight: 400, fontSize: 10, fontFamily: 'Nunito Sans' }}>vs. prev {dateLabels.lower}</Typography>
                      </Grid>
                      <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ width: '32%', height: '100%', backgroundColor: '#efefefff' }}>
                        <Typography style={{ paddingLeft: 20, width: '100%', marginTop: reportClient === 'lee' || isFlagship ? 20 : 8, fontWeight: 700, fontSize: 24, fontFamily: 'Nunito Sans' }}>{byDateData[0].avgsFormatted.wkendAvg}&thinsp;<small>{(byDateData[0].avgs.wkendAvg >= 1000) ? 'K' : ''}</small></Typography>
                        <Typography style={{ paddingLeft: 20, width: '100%', marginTop: -8, fontWeight: 400, fontSize: 12, fontFamily: 'Nunito Sans' }}>Wkend Avg</Typography>
                        <Typography style={{ display: reportClient === 'lee' || isFlagship || !change ? 'none' : '', paddingLeft: 20, width: '100%', marginTop: 2, fontWeight: 600, fontSize: 20, fontFamily: 'Nunito Sans', color: (byDateData[0].perChange.wkendAvg >= 0) ? '#56993B' : '#BF3E37' }}>{(byDateData[0].perChange.wkendAvg >= 0) ? '+' : ''}{(byDateData[0].perChange.wkendAvg).toFixed(1)}<small>%</small></Typography>
                        <Typography style={{ display: reportClient === 'lee' || isFlagship || !change ? 'none' : '', paddingLeft: 20, width: '100%', marginTop: -5, fontWeight: 400, fontSize: 10, fontFamily: 'Nunito Sans' }}>vs. prev {dateLabels.lower}</Typography>
                      </Grid>
                    </Grid>

                    <Grid item container direction='row' justifyContent='space-between' alignItems='flex-start' style={{ marginTop: 0, height: reportClient === 'lee' || isFlagship ? pageHeight / 14 : pageHeight / 16, width: '100%' }}>
                      <Grid container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ width: '49%', height: '100%', backgroundColor: '#efefefff' }}>
                        <Typography style={{ paddingLeft: 20, marginTop: reportClient === 'lee' || isFlagship || !change ? 13 : 8, fontWeight: 700, fontSize: 24, fontFamily: 'Nunito Sans' }}>{byTimeData[0].busiestHr.hr}&thinsp;<small>{byTimeData[0].busiestHr.suffix}</small></Typography>
                        <Typography style={{ paddingLeft: 20, marginTop: -6, fontWeight: 400, fontSize: 12, fontFamily: 'Nunito Sans' }}>Busiest Hour</Typography>
                      </Grid>
                      <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ width: '49%', height: '100%', backgroundColor: '#efefefff' }}>
                        <Typography style={{ paddingLeft: 20, marginTop: reportClient === 'lee' || isFlagship || !change ? 13 : 8, fontWeight: 700, fontSize: 24, fontFamily: 'Nunito Sans' }}>{byDateData[0].busiestDay}</Typography>
                        <Typography style={{ paddingLeft: 20, marginTop: -6, fontWeight: 400, fontSize: 12, fontFamily: 'Nunito Sans' }}>Busiest Day</Typography>
                      </Grid>
                    </Grid>

                    <Grid item container direction='row' justifyContent='space-between' alignItems='flex-start' style={{ display: showDemoArray[0] ? '' : 'none', marginTop: 5, marginBottom: 5, width: '100%', height: pageHeight / 20 }}>
                      <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ marginTop: 0, width: '49%', height: pageHeight / 20 }}>
                        <MonthlyAveragePie
                          age={false} gender={true}
                          series={[{ data: [{ genderPie: byDateData[0].genderPie }] }]} />
                      </Grid>
                      <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ marginTop: 0, width: '49%', height: pageHeight / 20 }}>
                        <MonthlyAveragePie
                          age={true} gender={false}
                          series={[{ data: [{ agePie: byDateData[0].agePie }] }]} />
                      </Grid>
                    </Grid>

                  </Grid>

                  <Grid item container direction='row' justifyContent='flex-start' alignItems='flex-start' style={{ width: '45%', height: pageHeight / 3.17, marginLeft: '1%' }}>
                    <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start' style={{ width: '100%' }}>
                      <img style={{ width: '99.2%' }} alt='Client Logo' src={getImage(client + '/' + locationLabels[0].replace('ä', 'a').replaceAll(/[\s\+\']+/g, '_') + '_Square.jpg', true)} />
                    </Grid>
                  </Grid>

                </Grid>

                {/* GRAPHS */}
                <Grid container item direction='row' justifyContent='space-between' alignItems='flex-start' style={{ width: pageWidth * 0.938, height: pageHeight * 0.56 }}>

                  <Grid container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ width: '53%', height: pageHeight * 0.56 }}>
                    <Grid container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ width: '100%', height: '100%' }}>
                      <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ fontWeight: 700, fontSize: 17, fontFamily: 'Nunito Sans', width: '100%' }}>
                        Avg. Daily Traffic: Time by Day of Week
                      </Grid>
                      <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start'>
                        <ByDayHourlyTable
                          series={[byTimeData[0].byDayHourly]}
                          remDays={isFlagship ? [0, 1, 2, 3] : []} />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ width: '45%', height: pageHeight * 0.56 }}>
                    <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start' style={{ width: '100%', height: showDemoArray[0] ? pageHeight / 6.7 : pageHeight / 5 }}>
                      <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ fontWeight: 700, fontSize: 17, fontFamily: 'Nunito Sans', width: '100%' }}>
                        Traffic By Day of Week
                      </Grid>
                      <ByDayOfWeekReport
                        series={[byDateData[0].dayOfWeekChart]}
                        demo={showDemoArray[0]}
                      />
                    </Grid>

                    {/* Demographics OFF - Traffic by Time */}
                    {!showDemoArray[0] &&
                      <Grid container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ display: showDemoArray[0] ? 'none' : '', width: '100%', height: pageHeight / 2.9, marginTop: 10 }}>
                        <Grid item container direction='row' justifyContent='flex-start' alignItems='flex-start' style={{ width: '100%', height: pageHeight / 7.8 }}>
                          <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ fontWeight: 700, fontSize: 17, fontFamily: 'Nunito Sans', width: '100%' }}>
                            Traffic By Time of Day
                          </Grid>
                          <ByTimeReport
                            age={false} gender={false}
                            series={[byTimeData[0].byTimeSplit]}
                            color={chartColors[0]} />
                        </Grid>
                      </Grid>
                    }

                    {/* Demographics ON - Traffic by Time */}
                    {showDemoArray[0] &&
                      <Grid container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ display: showDemoArray[0] ? '' : 'none', width: '100%', height: pageHeight / 2.53 }}>
                        <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ fontWeight: 700, fontSize: 17, fontFamily: 'Nunito Sans', width: '100%' }}>
                          Demographics By Time of Day
                        </Grid>
                        <Grid item container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ width: '100%', height: pageHeight / 2.75, marginTop: 0, marginBottom: 0 }}>
                          <Grid item container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ width: '100%', height: pageHeight / 5.65, marginTop: 0, marginBottom: 0 }}>
                            <Grid item container direction='row' justifyContent='center' alignItems='flex-start' style={{ backgroundColor: '#efefefff', paddingTop: 3, paddingBottom: 2, fontWeight: 700, marginTop: 0, fontSize: 13, fontFamily: 'Nunito Sans', width: '100%' }}>
                              Gender
                            </Grid>
                            <Grid item container direction='row' justifyContent='center' alignItems='center' style={{ width: '100%', marginTop: 0, marginBottom: 0 }}>
                              <Grid style={{ marginRight: 4, width: 10, height: 10, backgroundColor: '#6089c4' }}></Grid>
                              <Typography style={{ fontWeight: 600, fontSize: 12, fontFamily: 'Nunito Sans' }}> Male</Typography>
                              <Grid style={{ marginLeft: 40, marginRight: 4, width: 10, height: 10, backgroundColor: '#d77da5' }}></Grid>
                              <Typography style={{ fontWeight: 600, fontSize: 12, fontFamily: 'Nunito Sans' }}> Female</Typography>
                            </Grid>
                            <Grid item container direction='row' justifyContent='flex-start' alignItems='flex-start' style={{ width: '100%', height: pageHeight / 7.8 }}>
                              <ByTimeReport
                                age={false} gender={true}
                                series={[byTimeData[0].byTimeSplit]} />
                            </Grid>
                          </Grid>
                          <Grid item container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ width: '100%', height: pageHeight / 5.65, marginTop: 0, marginBottom: 0 }}>
                            <Grid item container direction='row' justifyContent='center' alignItems='flex-start' style={{ backgroundColor: '#efefefff', paddingTop: 3, paddingBottom: 2, fontWeight: 700, marginTop: 0, fontSize: 13, fontFamily: 'Nunito Sans', width: '100%' }}>
                              Age
                            </Grid>
                            <Grid item container direction='row' justifyContent='center' alignItems='center' style={{ width: '100%', marginTop: 0, marginBottom: 0 }}>
                              <Grid style={{ marginRight: 4, width: 10, height: 10, backgroundColor: '#7ea84e' }}></Grid>
                              <Typography style={{ fontWeight: 600, fontSize: 12, fontFamily: 'Nunito Sans' }}> Children</Typography>
                              <Grid style={{ marginLeft: 40, marginRight: 4, width: 10, height: 10, backgroundColor: '#643d23' }}></Grid>
                              <Typography style={{ fontWeight: 600, fontSize: 12, fontFamily: 'Nunito Sans' }}> Seniors</Typography>
                            </Grid>
                            <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start' style={{ width: '100%', height: pageHeight / 7.8 }}>
                              <ByTimeReport
                                age={true} gender={false}
                                series={[byTimeData[0].byTimeSplit]} />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    }

                  </Grid>
                </Grid>

                {/* FOOTER */}
                <Grid container item direction='column' justifyContent='space-between' alignItems='flex-start' style={{ width: pageWidth * 0.938, height: pageHeight * 0.05 }}>
                  <Grid container direction='row' justifyContent='space-between' alignItems='flex-start' style={{ width: pageWidth * 0.938, height: 1 }}></Grid>
                  <Grid container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ width: pageWidth * 0.938, height: pageHeight / 22 }}>

                    <Grid item container direction='row' justifyContent='flex-start' alignItems='flex-start' style={{ width: '50%' }}>
                      <Typography style={{ marginTop: -7, fontWeight: 700, fontSize: 30, fontFamily: 'Rajdhani', width: '100%' }}>{this.props.auth.user.email.includes('absre') ? '200 Park Ave South' : isFlagship ? '5th Ave Flagship Study' : reportTitle}</Typography>
                      <Typography style={{ marginTop: -9, fontWeight: 700, fontSize: 18, fontFamily: 'Rajdhani' }}>Traffic Report</Typography>
                    </Grid>

                    <Grid item container direction='row' justifyContent='flex-end' alignItems='flex-start' style={{ height: '100%', width: '50%' }}>
                      <img style={{ height: '100%' }} alt='Client Logo' src={require('../../layout/exteros_blue.png')} />
                    </Grid>

                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        }
      </>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(withStyles(styles)(SingleLocationReport));
