import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const StreamingRoute = ({ component: Component, auth, ...rest }) => (
  <Route
    {...rest}
    render={props => (auth.isAuthenticated === true && (auth.user.roles.includes('user_manager') || auth.user.roles.includes('video_streaming'))) ? (<Component {...props} />) : (<Redirect to='/dashboard' />)}
  />
);

StreamingRoute.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(StreamingRoute);
