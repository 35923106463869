import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

import CategoryIcon from '@material-ui/icons/CategoryOutlined';
import { Button, ClickAwayListener, Grid, Paper, Popover, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = ({
  locMenu: {
    backgroundColor: '#D6D6D6',
  },
  menuItem: {
    padding: 5,
    width: '100%',
  },
  root: {},
  checked: {}
});

const initLocationChecked = (locList) => {
  let locationChecked = {};

  for (let l in locList) {
    let locName = locList[l];
    locationChecked[locName] = false;
  }

  return locationChecked;
}

const initOwnerChecked = (ownerList) => {
  let ownerChecked = {};

  if (Object.keys(ownerList).length === 1) {
    ownerChecked[Object.keys(ownerList)[0]] = false;
  }
  else {
    for (let o in ownerList) {
      ownerChecked[o] = false;
      let ownerDivisions = ownerList[o];

      if (!Array.isArray(ownerDivisions)) {
        for (let od in ownerDivisions) {
          ownerChecked[od] = false;
        }
      }
    }
  }

  return ownerChecked;
}

class LocationCategoryCompare extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      cardState: {
        name: this.props.name,
        aggregate: false,
        aggregationType: 'average',
        locationChecked: initLocationChecked(this.props.locationsObject['locationList']),
        checkedLocOwners: initOwnerChecked(this.props.locationsObject['locOwners']),
        checkedLocCategories: Object.keys(this.props.locationsObject['locCategories']).reduce((acc, category) => ({ ...acc, [category]: false }), {}),
      },
      menuAnchor: null,
      menuOpen: false,
      newCard: false,
    }
  }

  compareCategories = (e) => {
    this.props.closeAllLocations();

    let locChecked = this.state.cardState.locationChecked;
    let tempCheckedLocOwners = this.state.cardState.checkedLocOwners;
    let tempCheckedLocCategories = this.state.cardState.checkedLocCategories;

    for (let i in tempCheckedLocOwners) {
      tempCheckedLocOwners[i] = false;
    }

    const categoriesArray = this.props.locationsObject.locCategoryTypes[e.currentTarget.name]; // array of category types for selected category

    let n = 0;
    categoriesArray.forEach(catName => {
      let allLocs = this.props.locationsObject.locCategories[catName]; // array of all locations within a category
      allLocs = (allLocs && allLocs.length > 0) ? allLocs.filter(loc => !this.props.locationsObject.noCompare.includes(loc)) : []; // if location should be excluded, remove from array

      if (allLocs.length > 0) {
        for (let i in tempCheckedLocCategories) {
          tempCheckedLocCategories[i] = false;
        }
        tempCheckedLocCategories[catName] = true;

        for (let l in locChecked) {
          locChecked[l] = (allLocs.includes(l)) ? true : false;
        }

        let newCardState = {};
        newCardState.name = n;
        newCardState.locationChecked = locChecked;
        newCardState.checkedLocOwners = tempCheckedLocOwners;
        newCardState.checkedLocCategories = tempCheckedLocCategories;
        newCardState.aggregate = true;
        newCardState.aggregationType = this.props.locationsObject.aggregationType[e.currentTarget.name]; // category
        // newCardState.aggregationType = this.props.locationsObject.aggregationType[catName]; // category type
        n++;

        this.props.onChange(newCardState);
      }
    });
  };

  compareOwners = () => {
    this.props.closeAllLocations();

    let locChecked = this.state.cardState.locationChecked;
    let tempCheckedLocOwners = this.state.cardState.checkedLocOwners;
    let tempCheckedLocCategories = this.state.cardState.checkedLocCategories;
    const locOwners = this.props.locationsObject['locOwners'];
    const noCompareArray = this.props.locationsObject['noCompare'];
    const owners = (typeof locOwners !== 'undefined') ? Object.keys(locOwners) : [];

    for (let i in tempCheckedLocCategories) {
      tempCheckedLocCategories[i] = false;
    }

    for (let i = 0, n = 0; i < owners.length; i++) {
      let allLocs = [];
      const ownerLocs = locOwners[owners[i]];

      for (let j in tempCheckedLocOwners) {
        tempCheckedLocOwners[j] = false;
      }
      tempCheckedLocOwners[owners[i]] = true;

      if (typeof ownerLocs !== 'undefined') {
        if (Array.isArray(ownerLocs)) {
          allLocs = (ownerLocs.length > 0) ? ownerLocs.filter(item => !noCompareArray.includes(item)) : [];
          if (allLocs.length === 0) continue; // if locations array empty skip rest of loop
        }
        else {
          // Owner: { SubOwner1: ['Loc1', 'Loc2'], SubOwner2: ['Loc1', 'Loc2'] }
          for (let subO in ownerLocs) {
            let subOwnerLocs = (typeof ownerLocs[subO] !== 'undefined') ? ownerLocs[subO] : [];
            subOwnerLocs = (subOwnerLocs.length > 0) ? subOwnerLocs.filter(item => !noCompareArray.includes(item)) : [];

            if (subOwnerLocs.length > 0) {
              tempCheckedLocOwners[subO] = true;

              for (let loc of subOwnerLocs) {
                allLocs.push(loc);
              }
            }
          }

          if (allLocs.length === 0) continue; // if locations array empty skip rest of loop
        }
      }

      for (let l in locChecked) {
        locChecked[l] = (allLocs.includes(l)) ? true : false;
      }

      let newCardState = {};
      newCardState.name = n;
      newCardState.locationChecked = locChecked;
      newCardState.checkedLocOwners = tempCheckedLocOwners;
      newCardState.checkedLocCategories = tempCheckedLocCategories;
      newCardState.aggregate = true;
      newCardState.aggregationType = 'average';
      n++;

      this.props.onChange(newCardState);
    }
  };

  handleClick = event => {
    let cardState = this.state.cardState;

    // reset cardState
    if (!this.state.menuOpen) {
      let locChecked = cardState.locationChecked;
      let tempCheckedLocOwners = cardState.checkedLocOwners;
      let tempCheckedLocCategories = cardState.checkedLocCategories;

      for (let l in locChecked) {
        locChecked[l] = false;
      }
      for (let i in tempCheckedLocOwners) {
        tempCheckedLocOwners[i] = false;
      }
      for (let i in tempCheckedLocCategories) {
        tempCheckedLocCategories[i] = false;
      }

      cardState.locationChecked = locChecked;
      cardState.checkedLocOwners = tempCheckedLocOwners;
      cardState.checkedLocCategories = tempCheckedLocCategories;
      cardState.aggregate = false;
      cardState.aggregationType = 'average';
    }

    this.setState({
      newCard: this.state.menuOpen,
      menuOpen: !this.state.menuOpen,
      menuAnchor: event.currentTarget,
      cardState: cardState,
    });
  };

  handleClickAway = () => {
    this.setState({
      newCard: !this.state.newCard,
      menuOpen: false,
      menuAnchor: null
    });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let cardState = prevState.cardState;

    // if ((nextProps.noCard !== prevState.noCard)) {
    //   if (nextProps.noCard) {
    //     cardState.name = 0;
    //   }
    // }

    if (nextProps.name !== prevState.cardState.name && prevState.newCard) {
      cardState.name = nextProps.name;
    }

    return {
      cardState: cardState,
    };
  }

  render() {
    const { classes, compareActiveLoc, locFilter, noCard } = this.props;
    const { menuAnchor, menuOpen } = this.state;

    return (
      <Grid
        container
        alignItems='center'
        justifyContent='center'
        style={{
          height: 40, width: '100%',
          color: (locFilter !== 'Vehicles' && (compareActiveLoc || noCard)) ? '#02313D' : '#4d6e77',
          fontWeight: 600, lineHeight: '115%', paddingLeft: 2, paddingRight: 2, fontSize: 'calc(7px + 0.2vw)',
          backgroundColor: (locFilter !== 'Vehicles' && (compareActiveLoc || noCard)) ? '#f5f5f5' : '#B3C0C4'
        }}
        disabled={compareActiveLoc ? false : true}
        onClick={this.handleClick}
      >
        <CategoryIcon justify='center' style={{ width: 'calc(10px + 0.4vw)', paddingBottom: '0.0em', paddingLeft: '0.0vw' }} />
        <Popover
          open={menuOpen}
          anchorEl={menuAnchor}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
          <ClickAwayListener onClickAway={this.handleClickAway}>
            <Paper className={classes.locMenu}>
              <Grid container direction='column' alignItems='flex-start' style={{ paddingTop: 10, paddingLeft: 10, paddingRight: 20, paddingBottom: 15 }}>
                <Typography className={classes.menuItem} style={{ marginBottom: 3 }}>Category Compare</Typography>

                {Object.keys(this.props.locationsObject.locCategoryTypes).map((cType, index) => {
                  return (
                    <Grid key={index} className={classes.menuItem}>
                      <Button name={cType} onClick={this.compareCategories} className='changeButton btn btn-small waves-effect waves-light hoverable accent-3' variant='contained' justify='center' aria-label={cType} style={{ width: '100%', justifyContent: 'flex-start' }}>
                        {cType}
                      </Button>
                    </Grid>
                  )
                })}

                <Grid className={classes.menuItem}>
                  <Button className='changeButton btn btn-small waves-effect waves-light hoverable accent-3' variant='contained' justify='center' aria-label='Landlords'
                    onClick={this.compareOwners} style={{ width: '100%', justifyContent: 'flex-start' }}>
                    Landlords
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </ClickAwayListener>
        </Popover>
      </Grid>
    )
  }
}

export default withRouter(withStyles(styles)(LocationCategoryCompare));
