import React, { Fragment, PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

import AddIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import {
  Checkbox,
  ClickAwayListener,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Paper,
  Popover,
  Radio,
  RadioGroup,
  Switch,
  Typography,
  Accordion,
  AccordionDetails
} from '@material-ui/core';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import { withStyles } from '@material-ui/core/styles';

const styles = ({
  location: {
    marginTop: 0,
    paddingLeft: 0,
    color: '#3A3A3A',
  },
  subLocation: {
    paddingLeft: 20,
  },
  subsubLocation: {
    paddingLeft: 40,
  },
  locGroupLbl: {
    padding: '10px 0 6px',
    textAlign: 'left',
    borderBottom: '1px solid black',
    marginBottom: '5px',
    fontSize: '14px'
  },
  cardContainer: {
    cursor: 'pointer',
    height: '100%',
    width: '100%',
  },
  locationCard: {
    cursor: 'pointer',
    position: 'relative',
    backgroundColor: '#f5f5f5',
  },
  locMenu: {
    backgroundColor: '#D6D6D6',
  },
  subMenu: {
    backgroundColor: '#D6D6D6',
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
  },
  root: {},
  checked: {},
  MuiAccordionroot: {
    '&.MuiAccordion-root:before': {
      height: 0
    }
  }
});

const StyledRadio = withStyles({
  root: {
    color: '#7A7A7A',
    '&$checked': {
      color: '#277175',
    },
  },
  checked: {},
})(props => <Radio size='small' {...props} />);

const AccordionSummary = withStyles({
  expanded: {},
  content: {
    margin: 0,
    '&$expanded': {
      margin: 0,
    },
  },
  expandIcon: {
    padding: '6px 12px'
  }
})(MuiAccordionSummary);

const initLocationChecked = (locList) => {
  let locationChecked = {};

  for (let l in locList) {
    let locName = locList[l];
    locationChecked[locName] = false;
  }

  return locationChecked;
}

const initOwnerChecked = (ownerList) => {
  let ownerChecked = {};

  if (Object.keys(ownerList).length === 1) {
    ownerChecked[Object.keys(ownerList)[0]] = false;
  }
  else {
    for (let o in ownerList) {
      ownerChecked[o] = false;
      let ownerDivisions = ownerList[o];

      if (!Array.isArray(ownerDivisions)) {
        for (let od in ownerDivisions) {
          ownerChecked[od] = false;
        }
      }
    }
  }

  return ownerChecked;
}

class LocationCompare extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      accGrp1: true,
      accGrp2: true,
      accGrp3: true,
      accGrp4: true,
      cardState: {
        name: this.props.name,
        aggregate: false,
        aggregationType: 'total', // defaults to total
        locationChecked: initLocationChecked(this.props.locationsObject['locationList']),
        checkedLocOwners: initOwnerChecked(this.props.locationsObject['locOwners']),
        checkedLocCategories: Object.keys(this.props.locationsObject['locCategories']).reduce((acc, category) => ({ ...acc, [category]: false }), {}),
      },
      menuAnchor: null,
      menuOpen: false,
    }
  }

  toggleCustom1 = (event) => {
    let cardState = this.state.cardState;
    let locChecked = cardState.locationChecked;
    let tempCheckedLocOwners = cardState.checkedLocOwners;
    let tempCheckedLocCategories = cardState.checkedLocCategories;
    let origAgg = cardState.aggregate;

    if (Object.keys(tempCheckedLocOwners).length === 1) {
      tempCheckedLocOwners[Object.keys(tempCheckedLocOwners)[0]] = false;
    }
    else {
      for (let i in tempCheckedLocOwners) {
        tempCheckedLocOwners[i] = false;
      }
    }

    for (let i in tempCheckedLocCategories) {
      tempCheckedLocCategories[i] = false;
    }

    if (!origAgg) {
      for (let l in locChecked) {
        locChecked[l] = false; // combine off, uncheck all other locations
      }
    }

    locChecked['McDonald\'s Sidewalk'] = (event.target.checked) ? true : false; // true when checked/selected
    locChecked['Whole Foods Sidewalk'] = (event.target.checked) ? true : false;
    locChecked['Whole Foods Entering'] = (event.target.checked) ? true : false;
    locChecked['Whole Foods Exiting'] = (event.target.checked) ? true : false;

    cardState.aggregate = true;
    cardState.locationChecked = locChecked;
    cardState.checkedLocOwners = tempCheckedLocOwners;
    cardState.checkedLocCategories = tempCheckedLocCategories;

    this.props.onChange(cardState); // call without setState, state gets update in getDerivedStateFromProps
    // this.setState({ ...this.state, cardState: cardState, }, () => {
    //   this.props.onChange(cardState);
    // });
  }

  toggleCustom2 = (event) => {
    let cardState = this.state.cardState;
    let locChecked = cardState.locationChecked;
    let tempCheckedLocOwners = cardState.checkedLocOwners;
    let tempCheckedLocCategories = cardState.checkedLocCategories;
    let origAgg = cardState.aggregate;

    if (Object.keys(tempCheckedLocOwners).length === 1) {
      tempCheckedLocOwners[Object.keys(tempCheckedLocOwners)[0]] = false;
    }
    else {
      for (let i in tempCheckedLocOwners) {
        tempCheckedLocOwners[i] = false;
      }
    }

    for (let i in tempCheckedLocCategories) {
      tempCheckedLocCategories[i] = false;
    }

    if (!origAgg) {
      for (let l in locChecked) {
        locChecked[l] = false; // combine off, uncheck all other locations
      }
    }

    locChecked['Mass Ave - Edgerly Passage Entering'] = (event.target.checked) ? true : false; // true when checked/selected
    locChecked['Mass Ave - Edgerly Passage Exiting'] = (event.target.checked) ? true : false;

    cardState.aggregate = true;
    cardState.locationChecked = locChecked
    cardState.checkedLocOwners = tempCheckedLocOwners;
    cardState.checkedLocCategories = tempCheckedLocCategories;

    this.props.onChange(cardState); // call without setState, state gets update in getDerivedStateFromProps
    // this.setState({ ...this.state, cardState: cardState, }, () => {
    //   this.props.onChange(cardState);
    // });
  }

  toggleCustomUSQs  = locs => (event) => {
    let cardState = this.state.cardState;
    let locChecked = cardState.locationChecked;
    let tempCheckedLocOwners = cardState.checkedLocOwners;
    let tempCheckedLocCategories = cardState.checkedLocCategories;
    let origAgg = cardState.aggregate;

    if (Object.keys(tempCheckedLocOwners).length === 1) {
      tempCheckedLocOwners[Object.keys(tempCheckedLocOwners)[0]] = false;
    }
    else {
      for (let i in tempCheckedLocOwners) {
        tempCheckedLocOwners[i] = false;
      }
    }

    for (let i in tempCheckedLocCategories) {
      tempCheckedLocCategories[i] = false;
    }

    if (!origAgg) {
      for (let l in locChecked) {
        locChecked[l] = false; // combine off, uncheck all other locations
      }
    }

    locChecked[locs[0]] = (event.target.checked) ? true : false; // true when checked/selected
    locChecked[locs[1]] = (event.target.checked) ? true : false;

    cardState.aggregate = true;
    cardState.locationChecked = locChecked;
    cardState.checkedLocOwners = tempCheckedLocOwners;
    cardState.checkedLocCategories = tempCheckedLocCategories;

    this.setState({ ...this.state, cardState: cardState, }, () => {
      this.props.onChange(cardState);
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let cardState = Object.assign({}, prevState.cardState);

    if (nextProps.name !== prevState.cardState.name && !prevState.cardState.aggregate) {
      cardState.name = nextProps.name;

      return {
        cardState: cardState
      }
    }
    else if (nextProps.name !== prevState.cardState.name && prevState.cardState.aggregate) {

      return {
        cardState: cardState
      }
    }

    return {}
  }

  handleClick = event => {
    let cardState = this.state.cardState;

    // reset cardState
    if (!this.state.menuOpen) {
      let locChecked = cardState.locationChecked;
      let tempCheckedLocOwners = cardState.checkedLocOwners;
      let tempCheckedLocCategories = cardState.checkedLocCategories;

      for (let l in locChecked) {
        locChecked[l] = false;
      }
      for (let i in tempCheckedLocOwners) {
        tempCheckedLocOwners[i] = false;
      }
      for (let i in tempCheckedLocCategories) {
        tempCheckedLocCategories[i] = false;
      }

      cardState.locationChecked = locChecked;
      cardState.checkedLocOwners = tempCheckedLocOwners;
      cardState.checkedLocCategories = tempCheckedLocCategories;
      cardState.aggregate = false;
      cardState.aggregationType = 'total';
    }

    this.setState({
      menuOpen: !this.state.menuOpen,
      menuAnchor: event.currentTarget,
      cardState: cardState,
    });
  };

  changeLocCategory = catName => event => {
    let cardState = this.state.cardState;
    let locChecked = cardState.locationChecked;
    let tempCheckedLocOwners = cardState.checkedLocOwners;
    let tempCheckedLocCategories = cardState.checkedLocCategories;

    if (Object.keys(tempCheckedLocOwners).length === 1) {
      tempCheckedLocOwners[Object.keys(tempCheckedLocOwners)[0]] = false;
    }
    else {
      for (let i in tempCheckedLocOwners) {
        tempCheckedLocOwners[i] = false;
      }
    }

    for (let i in tempCheckedLocCategories) {
      tempCheckedLocCategories[i] = false;
    }

    if (event.target.checked) tempCheckedLocCategories[catName] = true;

    let catLocs = this.props.locationsObject.locCategories[catName]; // array of all locations within a category
    catLocs = (catLocs && catLocs.length > 0) ? catLocs.filter(loc => !this.props.locationsObject.noCompare.includes(loc)) : []; // if location should be excluded, remove from array

    for (let l in locChecked) {
      if (catLocs.indexOf(l) > -1) {
        locChecked[l] = true;
      }
      else {
        locChecked[l] = false;
      }
    }

    cardState.locationChecked = locChecked;
    cardState.checkedLocOwners = tempCheckedLocOwners;
    cardState.checkedLocCategories = tempCheckedLocCategories;
    cardState.aggregate = true;
    cardState.aggregationType = this.props.locationsObject.aggregationType[catName];

    this.setState({ menuOpen: false, menuAnchor: null }, () => {
      this.props.onChange(cardState);
    });
  }

  changeOwner = name => event => {
    let cardState = this.state.cardState;
    let locChecked = cardState.locationChecked;
    let tempCheckedLocOwners = cardState.checkedLocOwners;
    let tempCheckedLocCategories = cardState.checkedLocCategories;
    const tempLocOwners = this.props.locationsObject['locOwners'];

    if (Object.keys(tempCheckedLocOwners).length === 1) {
      tempCheckedLocOwners[Object.keys(tempCheckedLocOwners)[0]] = false;
    }
    else {
      for (let i in tempCheckedLocOwners) {
        tempCheckedLocOwners[i] = false;
      }
    }

    for (let i in tempCheckedLocCategories) {
      tempCheckedLocCategories[i] = false;
    }

    if (event.target.checked) tempCheckedLocOwners[name] = true;

    let ownerLocs = [];
    let owner = tempLocOwners[name];

    if (typeof owner === 'undefined') {
      for (let o in tempLocOwners) {
        let ownerDivisions = tempLocOwners[o];

        if (!Array.isArray(ownerDivisions)) {
          let ownerDivision = ownerDivisions[name];

          if (typeof ownerDivision !== 'undefined') {
            ownerLocs = ownerDivision;
          }
        }
      }
    }
    else if (Object.keys(tempCheckedLocOwners).length === 1) {
      ownerLocs = tempLocOwners[name];
    }
    else if (!Array.isArray(owner)) {
      for (let od in owner) {
        if (event.target.checked) {
          tempCheckedLocOwners[od] = true;
          ownerLocs = ownerLocs.concat(owner[od]);
        }
      }
    }
    else {
      ownerLocs = tempLocOwners[name];
    }

    ownerLocs = (ownerLocs.length > 0) ? ownerLocs.filter(loc => !this.props.locationsObject.noCompare.includes(loc)) : [];

    for (let l in locChecked) {
      if (ownerLocs.indexOf(l) > -1) {
        locChecked[l] = true;
      }
      else {
        locChecked[l] = false;
      }
    }

    cardState.aggregate = true;
    cardState.locationChecked = locChecked;
    cardState.checkedLocOwners = tempCheckedLocOwners;
    cardState.checkedLocCategories = tempCheckedLocCategories;

    this.setState({ menuOpen: false, menuAnchor: null }, () => {
      this.props.onChange(cardState);
    });
  }

  changeLoc = name => event => {
    let cardState = this.state.cardState;
    let locChecked = cardState.locationChecked;
    let tempCheckedLocOwners = cardState.checkedLocOwners;
    let tempCheckedLocCategories = cardState.checkedLocCategories;

    if (Object.keys(tempCheckedLocOwners).length === 1) {
      tempCheckedLocOwners[Object.keys(tempCheckedLocOwners)[0]] = false;
    }
    else {
      for (let i in tempCheckedLocOwners) {
        tempCheckedLocOwners[i] = false;
      }
    }

    for (let i in tempCheckedLocCategories) {
      tempCheckedLocCategories[i] = false;
    }

    cardState.checkedLocOwners = tempCheckedLocOwners;
    cardState.checkedLocCategories = tempCheckedLocCategories;

    if (cardState.aggregate) {
      const parentLocsObj = this.props.locationsObject['pLocs'];
      const childLocsObj = this.props.locationsObject['cLocs'];
      locChecked[name] = event.target.checked;

      if (Object.keys(childLocsObj).includes(name)) {
        for (const loc of childLocsObj[name]) {
          locChecked[loc] = false; // if name is a child location, uncheck its parent
        }
      }

      if (Object.keys(parentLocsObj).includes(name)) {
        for (const loc of parentLocsObj[name]) {
          locChecked[loc] = false; // if name is a parent location, uncheck all of its child locations
        }
      }

      cardState.locationChecked = locChecked;

      this.props.onChange(cardState);
    }
    else {
      for (let l in locChecked) {
        locChecked[l] = false;
      }
      locChecked[name] = event.target.checked;
      cardState.locationChecked = locChecked;

      if (this.props.uIndex !== undefined) cardState.uIndex = this.props.uIndex; // access control only, add user index to returned object

      // Location card is closed when selecting a single location (aggregate false)
      //  beside updating state to close the menu, state does not need to be maintained
      //  handleClick func is called when user opens locationCompare menu again and cardState is reset.
      this.setState({ menuOpen: false, menuAnchor: null }, () => {
        this.props.onChange(cardState);
      });
    }
  };

  setAggregate = event => {
    let cardState = Object.assign({}, this.state.cardState); // needed for obj comparison when setState is called without onChange()
    let locChecked = cardState.locationChecked;
    let tempCheckedLocOwners = cardState.checkedLocOwners;
    let tempCheckedLocCategories = cardState.checkedLocCategories;

    if (!event.target.checked) {
      let isFirst = false;

      if (Object.keys(tempCheckedLocOwners).length === 1) {
        tempCheckedLocOwners[Object.keys(tempCheckedLocOwners)[0]] = false;
      }
      else {
        for (let i in tempCheckedLocOwners) {
          tempCheckedLocOwners[i] = false;
        }
      }

      for (let i in tempCheckedLocCategories) {
        tempCheckedLocCategories[i] = false;
      }

      for (let l in locChecked) {
        if (isFirst) {
          locChecked[l] = false;
        }
        else if (locChecked[l]) {
          isFirst = true;
        }
      }
    }

    cardState.checkedLocCategories = tempCheckedLocCategories;
    cardState.checkedLocOwners = tempCheckedLocOwners;
    cardState.aggregate = event.target.checked;
    cardState.locationChecked = locChecked;

    // if 'Combine Locations' toggled off and at least 1 location selected
    if (!event.target.checked && Object.values(locChecked).includes(true)) {
      this.setState({ menuOpen: false, menuAnchor: null }, () => {
        this.props.onChange(cardState);
      });
    }
    else {
      this.setState({ ...this.state, cardState: cardState });
    }
  };

  changeAggregationType = (event) => {
    let cardState = Object.assign({}, this.state.cardState); // needed for obj comparison when setState is called without onChange()
    let locChecked = cardState.locationChecked;

    cardState.aggregationType = event.target.value;

    if (Object.values(locChecked).includes(true)) {
      this.setState({ ...this.state, cardState: cardState }, () => {
        this.props.onChange(cardState);
      });
    }
    else {
      this.setState({ ...this.state, cardState: cardState });
    }
  }

  handleClickAway = () => {
    this.setState({ menuOpen: false, menuAnchor: null });
  };

  render() {
    const { accessControl, classes, compareActiveLoc, locationsObject, noCard, showCombos } = this.props;
    const { cardState, menuAnchor, menuOpen } = this.state;
    const headerHeight = document.getElementById('headerId')?.clientHeight || 32; // gets height of the appBar header, use to offset location popover maxheight

    return (
      <Grid container className={classes.cardContainer}>
        <Grid
          container
          alignItems='center'
          justifyContent='center'
          style={{
            height: 40, width: '100%',
            color: compareActiveLoc || noCard ? '#02313D' : '#4d6e77',
            backgroundColor: compareActiveLoc || noCard ? '#f5f5f5' : '#B3C0C4',
            fontWeight: 600, lineHeight: '115%', paddingLeft: 12, paddingRight: 8, fontSize: 'calc(7px + 0.2vw)'
          }}
          onClick={this.handleClick}
          disabled={compareActiveLoc ? false : true}
        >
          LOCATION
          <AddIcon justify='center' style={{ width: 'calc(8px + 0.4vw)', paddingBottom: '0.07em', paddingLeft: '0.1vw', display: '' }} />
        </Grid>
        <Popover
          open={menuOpen}
          anchorEl={menuAnchor}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          PaperProps={{
            style: { maxHeight: 'calc(100% - ' + headerHeight + 'px)' },
          }}
        >
          <ClickAwayListener onClickAway={this.handleClickAway}>
            <Paper className={classes.locMenu} style={{ width: window.innerWidth > 767 ? '100%' : '72vw' }}>
              <Grid container alignItems='flex-start' direction='row'>
                <Grid item style={{ maxWidth: 350 }}>
                  <FormGroup style={{ paddingTop: 16, paddingLeft: 20, paddingRight: 10, paddingBottom: 15 }}>

                    {/* All Locations except Lindt, McDonalds, and USP */}
                    {(this.props.client === 'all' || !['Massachusetts Ave', 'Woodbury Common', 'USQ South Plaza Occupancy', '6th Ave and 41st St Entrance'].includes(locationsObject['locationList'][0]) || accessControl) && locationsObject['locationList'].map((value, index) => {
                      return (Object.keys(cardState.locationChecked)[index] === '26 Broadway') ?
                        <Accordion key={'acc' + index} square style={{ marginTop: 0, marginBottom: 5 }} className={classes.subMenu}>
                          <AccordionSummary
                            style={{ minHeight: 0 }}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls='panel1a-content'
                            id='panel1a-header'
                          >
                            <Typography style={{ fontSize: 'calc(6px + 0.4vw)' }}>ADDITIONAL LOCATIONS</Typography>
                          </AccordionSummary>
                          <AccordionDetails style={{ padding: '4px 16px 8px', flexDirection: 'column' }}>
                            <FormControlLabel
                              key={'fc' + index}
                              className={classes.location}
                              control={cardState.aggregate ?
                                <Checkbox style={{ marginTop: -4, color: locationsObject['colors'][index], padding: '4px 9px 12px' }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[index]]}
                                  onChange={this.changeLoc(Object.keys(cardState.locationChecked)[index])} value={Object.keys(cardState.locationChecked)[index]} /> :
                                <Radio style={{ marginTop: -4, color: locationsObject['colors'][index], padding: '4px 9px 12px' }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[index]]}
                                  onChange={this.changeLoc(Object.keys(cardState.locationChecked)[index])} value={Object.keys(cardState.locationChecked)[index]} />
                              }
                              label={<Typography color='textPrimary' style={{ paddingBottom: 8 }}>{Object.keys(cardState.locationChecked)[index]}</Typography>}
                            />
                          </AccordionDetails>
                        </Accordion> :
                        <Fragment key={index}>
                          {locationsObject['locGroupLbls'][Object.keys(cardState.locationChecked)[index]] &&
                            <FormLabel className={classes.locGroupLbl}>{locationsObject['locGroupLbls'][Object.keys(cardState.locationChecked)[index]]}</FormLabel>
                          }

                          {(accessControl && (locationsObject.locationTypes?.Vehicles?.[0].name === value) && (Object.keys(locationsObject.locationTypes).length > 1)) &&
                            // ONLY Access Control - if user has People and Vehicle locs, insert 'Vehicles' label above 1st vehicle location
                            <FormLabel className={classes.locGroupLbl}>{'Vehicles'}</FormLabel>
                          }

                          <FormControlLabel
                            key={'fc' + index}
                            className={(locationsObject['subsubLocs'].includes(Object.keys(cardState.locationChecked)[index])) ? `${classes.location} ${classes.subsubLocation}` :
                              (locationsObject['subLocs'].includes(Object.keys(cardState.locationChecked)[index]))
                                ? `${classes.location} ${classes.subLocation}`
                                : classes.location
                            }
                            control={cardState.aggregate ?
                              <Checkbox style={{ marginTop: -4, color: locationsObject['colors'][index] }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[index]]}
                                onChange={this.changeLoc(Object.keys(cardState.locationChecked)[index])} value={Object.keys(cardState.locationChecked)[index]} /> :
                              <Radio style={{ marginTop: -4, color: locationsObject['colors'][index] }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[index]]}
                                onChange={this.changeLoc(Object.keys(cardState.locationChecked)[index])} value={Object.keys(cardState.locationChecked)[index]} />
                            }
                            label={<Typography color='textPrimary'>{Object.keys(cardState.locationChecked)[index]}</Typography>}
                          />
                        </Fragment>
                    })}

                    {/* McDonalds Locations */}
                    {(locationsObject['locationList'][0] === 'Massachusetts Ave' && !accessControl) &&
                      <Grid container direction='column'>
                        <FormControlLabel
                          key={'fc' + 0}
                          className={classes.location}
                          label={<Typography color='textPrimary'>{Object.keys(cardState.locationChecked)[0]}</Typography>}
                          control={cardState.aggregate
                            ? <Checkbox style={{ marginTop: -4, color: locationsObject['colors'][0] }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[0]]} onChange={this.changeLoc(Object.keys(cardState.locationChecked)[0])} value={Object.keys(cardState.locationChecked)[0]} />
                            : <Radio style={{ marginTop: -4, color: locationsObject['colors'][0] }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[0]]} onChange={this.changeLoc(Object.keys(cardState.locationChecked)[0])} value={Object.keys(cardState.locationChecked)[0]} />
                          } />

                        <Accordion square elevation={0} expanded={this.state.accGrp1} style={{ marginTop: 0, marginBottom: 0, paddingTop: 0 }} classes={{ root: classes.MuiAccordionroot }}>
                          <AccordionSummary
                            style={{ backgroundColor: '#D6D6D6', marginTop: 0, marginLeft: -15, marginBottom: 0, paddingRight: 0, justifyContent: 'center', minHeight: 0 }}
                            aria-controls='panel1a-content'
                            id='panel1a-header'
                          >
                            <Grid container justifyContent='flex-start' alignItems='center'>
                              <FormControlLabel
                                label='Edgerly Rd'
                                control={cardState.aggregate
                                  ? <Checkbox
                                    style={{ color: '#283593' }}
                                    checked={cardState.locationChecked['McDonald\'s Sidewalk'] && cardState.locationChecked['Whole Foods Sidewalk'] && cardState.locationChecked['Whole Foods Entering'] && cardState.locationChecked['Whole Foods Exiting']}
                                    onChange={this.toggleCustom1} />
                                  : <Radio
                                    style={{ color: '#283593' }}
                                    checked={cardState.locationChecked['McDonald\'s Sidewalk'] && cardState.locationChecked['Whole Foods Sidewalk'] && cardState.locationChecked['Whole Foods Entering'] && cardState.locationChecked['Whole Foods Exiting']}
                                    onChange={this.toggleCustom1} />
                                } />

                              {this.state.accGrp1
                                ? <ExpandLessRoundedIcon onClick={() => { this.setState({ accGrp1: false }) }} style={{ marginBottom: 0, marginLeft: -11, color: '#00313D' }} />
                                : <ExpandMoreIcon onClick={() => { this.setState({ accGrp1: true }) }} style={{ marginBottom: 0, marginLeft: -11, color: '#00313D' }} />
                              }
                            </Grid>
                          </AccordionSummary>
                          <AccordionDetails style={{ marginTop: -5, marginBottom: -5, backgroundColor: '#D6D6D6', }}>
                            <Grid container direction='column' justifyContent='space-evenly' alignItems='flex-start'>
                              <FormControlLabel
                                label={Object.keys(cardState.locationChecked)[1]}
                                control={cardState.aggregate
                                  ? <Checkbox style={{ color: locationsObject['colors'][1] }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[1]]} onChange={this.changeLoc(Object.keys(cardState.locationChecked)[1])} value={Object.keys(cardState.locationChecked)[1]} />
                                  : <Radio style={{ color: locationsObject['colors'][1] }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[1]]} onChange={this.changeLoc(Object.keys(cardState.locationChecked)[1])} value={Object.keys(cardState.locationChecked)[1]} />
                                } />

                              <FormControlLabel
                                label={Object.keys(cardState.locationChecked)[2]}
                                control={cardState.aggregate
                                  ? <Checkbox style={{ color: locationsObject['colors'][2] }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[2]]} onChange={this.changeLoc(Object.keys(cardState.locationChecked)[2])} value={Object.keys(cardState.locationChecked)[2]} />
                                  : <Radio style={{ color: locationsObject['colors'][2] }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[2]]} onChange={this.changeLoc(Object.keys(cardState.locationChecked)[2])} value={Object.keys(cardState.locationChecked)[2]} />
                                } />

                              <FormControlLabel
                                label={Object.keys(cardState.locationChecked)[3]}
                                control={cardState.aggregate
                                  ? <Checkbox style={{ color: locationsObject['colors'][3] }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[3]]} onChange={this.changeLoc(Object.keys(cardState.locationChecked)[3])} value={Object.keys(cardState.locationChecked)[3]} />
                                  : <Radio style={{ color: locationsObject['colors'][3] }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[3]]} onChange={this.changeLoc(Object.keys(cardState.locationChecked)[3])} value={Object.keys(cardState.locationChecked)[3]} />
                                } />

                              <FormControlLabel
                                label={Object.keys(cardState.locationChecked)[4]}
                                control={cardState.aggregate
                                  ? <Checkbox style={{ color: locationsObject['colors'][4] }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[4]]} onChange={this.changeLoc(Object.keys(cardState.locationChecked)[4])} value={Object.keys(cardState.locationChecked)[4]} />
                                  : <Radio style={{ color: locationsObject['colors'][4] }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[4]]} onChange={this.changeLoc(Object.keys(cardState.locationChecked)[4])} value={Object.keys(cardState.locationChecked)[4]} />
                                } />
                            </Grid>
                          </AccordionDetails>
                        </Accordion>

                        <Accordion elevation={0} square expanded={this.state.accGrp2} style={{ marginTop: 0, marginBottom: 0, paddingTop: 0 }} classes={{ root: classes.MuiAccordionroot }}>
                          <AccordionSummary
                            style={{ backgroundColor: '#D6D6D6', marginTop: 0, marginLeft: -15, marginBottom: 0, paddingRight: 0, justifyContent: 'center', minHeight: 0 }}
                            aria-controls='panel1a-content'
                            id='panel1a-header'
                          >
                            <Grid container justifyContent='flex-start' alignItems='center'>
                              <FormControlLabel
                                label='Mass Ave - Edgerly Passage'
                                control={cardState.aggregate
                                  ? <Checkbox
                                    style={{ color: '#D500F9' }}
                                    checked={cardState.locationChecked['Mass Ave - Edgerly Passage Entering'] && cardState.locationChecked['Mass Ave - Edgerly Passage Exiting']}
                                    onChange={this.toggleCustom2} />
                                  : <Radio
                                    style={{ color: '#D500F9' }}
                                    checked={cardState.locationChecked['Mass Ave - Edgerly Passage Entering'] && cardState.locationChecked['Mass Ave - Edgerly Passage Exiting']}
                                    onChange={this.toggleCustom2} />
                                } />

                              {this.state.accGrp2
                                ? <ExpandLessRoundedIcon onClick={() => { this.setState({ accGrp2: false }) }} style={{ marginBottom: 0, marginLeft: -11, color: '#00313D' }} />
                                : <ExpandMoreIcon onClick={() => { this.setState({ accGrp2: true }) }} style={{ marginBottom: 0, marginLeft: -11, color: '#00313D' }} />
                              }
                            </Grid>
                          </AccordionSummary>
                          <AccordionDetails style={{ marginTop: -5, marginBottom: -5, backgroundColor: '#D6D6D6', }}>
                            <Grid container direction='column' justifyContent='space-evenly' alignItems='flex-start'>
                              <FormControlLabel
                                label={Object.keys(cardState.locationChecked)[7]}
                                control={cardState.aggregate
                                  ? <Checkbox style={{ color: locationsObject['colors'][7] }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[7]]} onChange={this.changeLoc(Object.keys(cardState.locationChecked)[7])} value={Object.keys(cardState.locationChecked)[7]} />
                                  : <Radio style={{ color: locationsObject['colors'][7] }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[7]]} onChange={this.changeLoc(Object.keys(cardState.locationChecked)[7])} value={Object.keys(cardState.locationChecked)[7]} />
                                } />

                              <FormControlLabel
                                label={Object.keys(cardState.locationChecked)[8]}
                                control={cardState.aggregate
                                  ? <Checkbox style={{ color: locationsObject['colors'][8] }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[8]]} onChange={this.changeLoc(Object.keys(cardState.locationChecked)[8])} value={Object.keys(cardState.locationChecked)[8]} />
                                  : <Radio style={{ color: locationsObject['colors'][8] }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[8]]} onChange={this.changeLoc(Object.keys(cardState.locationChecked)[8])} value={Object.keys(cardState.locationChecked)[8]} />
                                } />
                            </Grid>
                          </AccordionDetails>
                        </Accordion>

                        <Grid>
                          <FormControlLabel
                            key={'fc' + 5}
                            className={classes.location}
                            label={<Typography color='textPrimary'>{Object.keys(cardState.locationChecked)[5]}</Typography>}
                            control={cardState.aggregate
                              ? <Checkbox style={{ marginTop: -4, color: locationsObject['colors'][5] }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[5]]} onChange={this.changeLoc(Object.keys(cardState.locationChecked)[5])} value={Object.keys(cardState.locationChecked)[5]} />
                              : <Radio style={{ marginTop: -4, color: locationsObject['colors'][5] }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[5]]} onChange={this.changeLoc(Object.keys(cardState.locationChecked)[5])} value={Object.keys(cardState.locationChecked)[5]} />
                            } />
                        </Grid>

                        <Grid>
                          <FormControlLabel
                            key={'fc' + 6}
                            className={classes.location}
                            label={<Typography color='textPrimary'>{Object.keys(cardState.locationChecked)[6]}</Typography>}
                            control={cardState.aggregate
                              ? <Checkbox style={{ marginTop: -4, color: locationsObject['colors'][6] }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[6]]} onChange={this.changeLoc(Object.keys(cardState.locationChecked)[6])} value={Object.keys(cardState.locationChecked)[6]} />
                              : <Radio style={{ marginTop: -4, color: locationsObject['colors'][6] }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[6]]} onChange={this.changeLoc(Object.keys(cardState.locationChecked)[6])} value={Object.keys(cardState.locationChecked)[6]} />
                            } />
                        </Grid>
                      </Grid>
                    }

                    {/* Lindt Locations */}
                    {(this.props.client !== 'all' && locationsObject['locationList'][0] === 'Woodbury Common' && !accessControl) &&
                      <Grid container direction='column'>

                        {locationsObject['locationList'].map((value, index) => {
                          return (index >= 0 && index < 14)
                            ? <Fragment key={index}>
                              {locationsObject['locGroupLbls'][Object.keys(cardState.locationChecked)[index]] && // insert label defined in locationsArray above location
                                <FormLabel className={classes.locGroupLbl}>{locationsObject['locGroupLbls'][Object.keys(cardState.locationChecked)[index]]}</FormLabel>
                              }

                              {value === 'Wrentham Village' &&
                                <Accordion square elevation={0} style={{ marginTop: 0, marginBottom: 0, paddingTop: 0 }} classes={{ root: classes.MuiAccordionroot }}>
                                  <AccordionSummary
                                    style={{ backgroundColor: '#D6D6D6', marginTop: 0, marginLeft: -15, marginBottom: 0, paddingRight: 0, justifyContent: 'center', minHeight: 0 }}
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls='panel1a-content'
                                    id='panel1a-header'
                                  >
                                    <Grid container justifyContent='flex-start' alignItems='center'>
                                      <FormControlLabel
                                        label={<Typography color='textPrimary'>{Object.keys(cardState.locationChecked)[index]}</Typography>}
                                        control={cardState.aggregate
                                          ? <Checkbox style={{ marginTop: -4, color: locationsObject['colors'][index] }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[index]]} onChange={this.changeLoc(Object.keys(cardState.locationChecked)[index])} value={Object.keys(cardState.locationChecked)[index]} />
                                          : <Radio style={{ marginTop: -4, color: locationsObject['colors'][index] }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[index]]} onChange={this.changeLoc(Object.keys(cardState.locationChecked)[index])} value={Object.keys(cardState.locationChecked)[index]} />
                                        }
                                      />
                                    </Grid>
                                  </AccordionSummary>

                                  <AccordionDetails style={{ marginTop: -5, marginBottom: -5, backgroundColor: '#D6D6D6', }}>
                                    <Grid container direction='column' justifyContent='space-evenly' alignItems='flex-start'>
                                      <FormControlLabel
                                        label={Object.keys(cardState.locationChecked)[2]}
                                        control={cardState.aggregate
                                          ? <Checkbox style={{ color: locationsObject['colors'][2] }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[2]]} onChange={this.changeLoc(Object.keys(cardState.locationChecked)[2])} value={Object.keys(cardState.locationChecked)[2]} />
                                          : <Radio style={{ color: locationsObject['colors'][2] }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[2]]} onChange={this.changeLoc(Object.keys(cardState.locationChecked)[2])} value={Object.keys(cardState.locationChecked)[2]} />
                                        } />

                                      <FormControlLabel
                                        label={Object.keys(cardState.locationChecked)[3]}
                                        control={cardState.aggregate
                                          ? <Checkbox style={{ color: locationsObject['colors'][3] }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[3]]} onChange={this.changeLoc(Object.keys(cardState.locationChecked)[3])} value={Object.keys(cardState.locationChecked)[3]} />
                                          : <Radio style={{ color: locationsObject['colors'][3] }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[3]]} onChange={this.changeLoc(Object.keys(cardState.locationChecked)[3])} value={Object.keys(cardState.locationChecked)[3]} />
                                        } />
                                    </Grid>
                                  </AccordionDetails>
                                </Accordion>
                              }

                              {!['Wrentham Village', 'Wrentham Village (Wrentham Court)', 'Wrentham Village (Liberty Court)'].includes(value) &&
                                <FormControlLabel
                                  key={'fc' + index}
                                  className={(locationsObject['subsubLocs'].includes(Object.keys(cardState.locationChecked)[index])) ? `${classes.location} ${classes.subsubLocation}` :
                                    (locationsObject['subLocs'].includes(Object.keys(cardState.locationChecked)[index]))
                                      ? `${classes.location} ${classes.subLocation}`
                                      : classes.location
                                  }
                                  label={<Typography color='textPrimary'>{Object.keys(cardState.locationChecked)[index]}</Typography>}
                                  control={cardState.aggregate
                                    ? <Checkbox style={{ marginTop: -4, color: locationsObject['colors'][index] }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[index]]} onChange={this.changeLoc(Object.keys(cardState.locationChecked)[index])} value={Object.keys(cardState.locationChecked)[index]} />
                                    : <Radio style={{ marginTop: -4, color: locationsObject['colors'][index] }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[index]]} onChange={this.changeLoc(Object.keys(cardState.locationChecked)[index])} value={Object.keys(cardState.locationChecked)[index]} />
                                  }
                                />
                              }

                            </Fragment>
                            : ''
                        })}

                        {/* Lindt Archived Locations */}
                        <Accordion square elevation={0} style={{ marginTop: -5, marginBottom: 5, backgroundColor: '#D6D6D6' }} classes={{ root: classes.MuiAccordionroot }}>
                          <AccordionSummary
                            style={{ minHeight: 0, padding: 0 }}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls='panel1a-content'
                            id='panel1a-header'
                          >
                            <Typography style={{ fontSize: '14px', padding: '0 0 6px', borderBottom: '1px solid black', width: '100%', marginBottom: 5, color: 'rgba(0, 0, 0, 0.54)' }}>Archived</Typography>
                          </AccordionSummary>
                          <AccordionDetails style={{ padding: '4px 16px 0', flexDirection: 'column' }}>
                            <FormControlLabel
                              className={classes.location}
                              label={<Typography color='textPrimary' style={{ paddingBottom: 8 }}>{Object.keys(cardState.locationChecked)[14]}</Typography>}
                              control={cardState.aggregate
                                ? <Checkbox style={{ marginTop: -4, color: locationsObject['colors'][14], padding: '4px 9px 12px' }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[14]]} onChange={this.changeLoc(Object.keys(cardState.locationChecked)[14])} value={Object.keys(cardState.locationChecked)[14]} />
                                : <Radio style={{ marginTop: -4, color: locationsObject['colors'][14], padding: '4px 9px 12px' }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[14]]} onChange={this.changeLoc(Object.keys(cardState.locationChecked)[14])} value={Object.keys(cardState.locationChecked)[14]} />
                              }
                            />

                            <FormControlLabel
                              className={classes.location}
                              label={<Typography color='textPrimary' style={{ paddingBottom: 8 }}>{Object.keys(cardState.locationChecked)[15]}</Typography>}
                              control={cardState.aggregate
                                ? <Checkbox style={{ marginTop: -4, color: locationsObject['colors'][15], padding: '4px 9px 12px' }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[15]]} onChange={this.changeLoc(Object.keys(cardState.locationChecked)[15])} value={Object.keys(cardState.locationChecked)[15]} />
                                : <Radio style={{ marginTop: -4, color: locationsObject['colors'][15], padding: '4px 9px 12px' }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[15]]} onChange={this.changeLoc(Object.keys(cardState.locationChecked)[15])} value={Object.keys(cardState.locationChecked)[15]} />
                              }
                            />

                            {/* 5th Ave Flagship Study Locations */}
                            <Accordion square elevation={0} style={{ marginTop: -5, marginBottom: 5, backgroundColor: '#D6D6D6' }} classes={{ root: classes.MuiAccordionroot }}>
                              <AccordionSummary
                                style={{ minHeight: 0, padding: 0 }}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls='panel1a-content'
                                id='panel1a-header'
                              >
                                <Typography style={{ fontSize: '14px', padding: '0 0 6px', borderBottom: '1px solid black', width: '100%', marginBottom: 5, color: 'rgba(0, 0, 0, 0.54)' }}>5th Ave Flagship Study</Typography>
                              </AccordionSummary>
                              <AccordionDetails style={{ padding: '4px 16px 0', flexDirection: 'column' }}>

                                {locationsObject['locationList'].map((value, index) => {
                                  return (index > 15)
                                    ? <Fragment key={index}>
                                      <FormControlLabel
                                        key={'fc' + index}
                                        className={(locationsObject['subsubLocs'].includes(Object.keys(cardState.locationChecked)[index])) ? `${classes.location} ${classes.subsubLocation}` :
                                          (locationsObject['subLocs'].includes(Object.keys(cardState.locationChecked)[index]))
                                            ? `${classes.location} ${classes.subLocation}`
                                            : classes.location
                                        }
                                        label={<Typography color='textPrimary' style={{ paddingBottom: 8 }}>{Object.keys(cardState.locationChecked)[index]}</Typography>}
                                        control={cardState.aggregate
                                          ? <Checkbox style={{ marginTop: -4, color: locationsObject['colors'][index], padding: '4px 9px 12px' }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[index]]} onChange={this.changeLoc(Object.keys(cardState.locationChecked)[index])} value={Object.keys(cardState.locationChecked)[index]} />
                                          : <Radio style={{ marginTop: -4, color: locationsObject['colors'][index], padding: '4px 9px 12px' }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[index]]} onChange={this.changeLoc(Object.keys(cardState.locationChecked)[index])} value={Object.keys(cardState.locationChecked)[index]} />
                                        }
                                      />
                                    </Fragment>
                                    : ''
                                })}

                              </AccordionDetails>
                            </Accordion>
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    }

                    {/* USP Locations */}
                    {(this.props.client !== 'all' && locationsObject['locationList'][0] === 'USQ South Plaza Occupancy' && !accessControl) &&
                      <Grid container direction='column'>
                        <Accordion square elevation={0} expanded={this.state.accGrp1} style={{ marginTop: 0, marginBottom: 0, paddingTop: 0 }} classes={{ root: classes.MuiAccordionroot }}>
                          <AccordionSummary
                            style={{ backgroundColor: '#D6D6D6', marginTop: 0, marginLeft: -15, marginBottom: 0, paddingRight: 0, justifyContent: 'center', minHeight: 0 }}
                            aria-controls='panel1a-content'
                            id='panel1a-header'
                          >
                            <Typography style={{ fontSize: '14px', borderBottom: '1px solid black', width: '100%', marginBottom: 5, color: 'rgba(0, 0, 0, 1)' }}>Union Square South Plaza</Typography>
                            {this.state.accGrp1
                              ? <ExpandLessRoundedIcon onClick={() => { this.setState({ accGrp1: false }) }} style={{ marginBottom: 0, marginLeft: -11, color: '#00313D' }} />
                              : <ExpandMoreIcon onClick={() => { this.setState({ accGrp1: true }) }} style={{ marginBottom: 0, marginLeft: -11, color: '#00313D' }} />
                            }
                          </AccordionSummary>
                          <AccordionDetails style={{ marginTop: -5, marginBottom: -5, backgroundColor: '#D6D6D6', }}>
                            <Grid container direction='column' justifyContent='space-evenly' alignItems='flex-start'>
                              <FormControlLabel
                                key={'fc' + 0}
                                className={classes.location}
                                label={<Typography color='textPrimary'>{Object.keys(cardState.locationChecked)[0]}</Typography>}
                                control={cardState.aggregate
                                  ? <Checkbox style={{ marginTop: -4, color: locationsObject['colors'][0] }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[0]]} onChange={this.changeLoc(Object.keys(cardState.locationChecked)[0])} value={Object.keys(cardState.locationChecked)[0]} />
                                  : <Radio style={{ marginTop: -4, color: locationsObject['colors'][0] }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[0]]} onChange={this.changeLoc(Object.keys(cardState.locationChecked)[0])} value={Object.keys(cardState.locationChecked)[0]} />
                                } />
                              <Typography style={{ fontSize: '14px', width: '100%', marginTop: 1, marginBottom: -5, color: 'rgba(0, 0, 0, 1)' }}>Union Square South Plaza East</Typography>
                              <Grid style={{ marginLeft: 10 }}>
                                <FormControlLabel
                                  label={Object.keys(cardState.locationChecked)[1]}
                                  control={cardState.aggregate
                                    ? <Checkbox style={{ color: locationsObject['colors'][1] }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[1]]} onChange={this.changeLoc(Object.keys(cardState.locationChecked)[1])} value={Object.keys(cardState.locationChecked)[1]} />
                                    : <Radio style={{ color: locationsObject['colors'][1] }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[1]]} onChange={this.changeLoc(Object.keys(cardState.locationChecked)[1])} value={Object.keys(cardState.locationChecked)[1]} />
                                  } />

                                <FormControlLabel
                                  label={Object.keys(cardState.locationChecked)[2]}
                                  control={cardState.aggregate
                                    ? <Checkbox style={{ color: locationsObject['colors'][2] }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[2]]} onChange={this.changeLoc(Object.keys(cardState.locationChecked)[2])} value={Object.keys(cardState.locationChecked)[2]} />
                                    : <Radio style={{ color: locationsObject['colors'][2] }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[2]]} onChange={this.changeLoc(Object.keys(cardState.locationChecked)[2])} value={Object.keys(cardState.locationChecked)[2]} />
                                  } />
                              </Grid>
                              <Typography style={{ fontSize: '14px', width: '100%', marginTop: 1, marginBottom: -5, color: 'rgba(0, 0, 0, 1)' }}>Union Square South Plaza West</Typography>
                              <Grid style={{ marginLeft: 10 }}>
                                <FormControlLabel
                                  label={Object.keys(cardState.locationChecked)[3]}
                                  control={cardState.aggregate
                                    ? <Checkbox style={{ color: locationsObject['colors'][3] }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[3]]} onChange={this.changeLoc(Object.keys(cardState.locationChecked)[3])} value={Object.keys(cardState.locationChecked)[3]} />
                                    : <Radio style={{ color: locationsObject['colors'][3] }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[3]]} onChange={this.changeLoc(Object.keys(cardState.locationChecked)[3])} value={Object.keys(cardState.locationChecked)[3]} />
                                  } />

                                <FormControlLabel
                                  label={Object.keys(cardState.locationChecked)[4]}
                                  control={cardState.aggregate
                                    ? <Checkbox style={{ color: locationsObject['colors'][4] }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[4]]} onChange={this.changeLoc(Object.keys(cardState.locationChecked)[4])} value={Object.keys(cardState.locationChecked)[4]} />
                                    : <Radio style={{ color: locationsObject['colors'][4] }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[4]]} onChange={this.changeLoc(Object.keys(cardState.locationChecked)[4])} value={Object.keys(cardState.locationChecked)[4]} />
                                  } />
                              </Grid>
                              <Typography style={{ fontSize: '14px', width: '100%', marginTop: 1, marginBottom: -5, color: 'rgba(0, 0, 0, 1)' }}>Union Square South Plaza South</Typography>
                              <Accordion square elevation={0} expanded={this.state.accGrp2} style={{ marginTop: 0, marginLeft: 10, marginBottom: 0, paddingTop: 0 }} classes={{ root: classes.MuiAccordionroot }}>
                                <AccordionSummary
                                  style={{ backgroundColor: '#D6D6D6', marginTop: 0, marginLeft: -15, marginBottom: 0, paddingRight: 0, justifyContent: 'center', minHeight: 0 }}
                                  aria-controls='panel1a-content'
                                  id='panel1a-header'
                                >
                                  <Grid container justifyContent='flex-start' alignItems='center'>
                                    <FormControlLabel
                                      label='USQ S Plaza South Entering'
                                      control={cardState.aggregate
                                        ? <Checkbox
                                          style={{ color: '#283593' }}
                                          checked={cardState.locationChecked['South Entering (Xwalk)'] && cardState.locationChecked['South Entering (street)']}
                                          onChange={this.toggleCustomUSQs(['South Entering (Xwalk)', 'South Entering (street)'])} />
                                        : <Radio
                                          style={{ color: '#283593' }}
                                          checked={cardState.locationChecked['South Entering (Xwalk)'] && cardState.locationChecked['South Entering (street)']}
                                          onChange={this.toggleCustomUSQs(['South Entering (Xwalk)', 'South Entering (street)'])} />
                                      } />

                                    {this.state.accGrp2
                                      ? <ExpandLessRoundedIcon onClick={() => { this.setState({ accGrp2: false }) }} style={{ marginBottom: 0, marginLeft: -11, color: '#00313D' }} />
                                      : <ExpandMoreIcon onClick={() => { this.setState({ accGrp2: true }) }} style={{ marginBottom: 0, marginLeft: -11, color: '#00313D' }} />
                                    }
                                  </Grid>
                                </AccordionSummary>
                                <AccordionDetails style={{ marginTop: -10, marginBottom: -5, backgroundColor: '#D6D6D6', }}>
                                  <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start'>
                                    <FormControlLabel
                                      style={{ marginTop: 0 }}
                                      label={<Typography style={{ lineHeight: 1 }}>{Object.keys(cardState.locationChecked)[5]}</Typography>}
                                      control={cardState.aggregate
                                        ? <Checkbox style={{ color: locationsObject['colors'][5] }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[5]]} onChange={this.changeLoc(Object.keys(cardState.locationChecked)[5])} value={Object.keys(cardState.locationChecked)[5]} />
                                        : <Radio style={{ color: locationsObject['colors'][5] }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[5]]} onChange={this.changeLoc(Object.keys(cardState.locationChecked)[5])} value={Object.keys(cardState.locationChecked)[5]} />
                                      } />

                                    <FormControlLabel
                                      style={{ marginTop: 0 }}
                                      label={<Typography style={{ lineHeight: 1 }}>{Object.keys(cardState.locationChecked)[7]}</Typography>}
                                      control={cardState.aggregate
                                        ? <Checkbox style={{ color: locationsObject['colors'][7] }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[7]]} onChange={this.changeLoc(Object.keys(cardState.locationChecked)[7])} value={Object.keys(cardState.locationChecked)[7]} />
                                        : <Radio style={{ color: locationsObject['colors'][7] }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[7]]} onChange={this.changeLoc(Object.keys(cardState.locationChecked)[7])} value={Object.keys(cardState.locationChecked)[7]} />
                                      } />
                                  </Grid>
                                </AccordionDetails>
                              </Accordion>
                              <Accordion square elevation={0} expanded={this.state.accGrp3} style={{ marginTop: -10, marginLeft: 10, marginBottom: 0, paddingTop: 0 }} classes={{ root: classes.MuiAccordionroot }}>
                                <AccordionSummary
                                  style={{ backgroundColor: '#D6D6D6', marginTop: 0, marginLeft: -15, marginBottom: 0, paddingRight: 0, justifyContent: 'center', minHeight: 0 }}
                                  aria-controls='panel1a-content'
                                  id='panel1a-header'
                                >
                                  <Grid container justifyContent='flex-start' alignItems='center'>
                                    <FormControlLabel
                                      label='USQ S Plaza South Exiting'
                                      control={cardState.aggregate
                                        ? <Checkbox
                                          style={{ color: '#D500F9' }}
                                          checked={cardState.locationChecked['South Exiting (Xwalk)'] && cardState.locationChecked['South Exiting (street)']}
                                          onChange={this.toggleCustomUSQs(['South Exiting (Xwalk)', 'South Exiting (street)'])} />
                                        : <Radio
                                          style={{ color: '#D500F9' }}
                                          checked={cardState.locationChecked['South Exiting (Xwalk)'] && cardState.locationChecked['South Exiting (street)']}
                                          onChange={this.toggleCustomUSQs(['South Exiting (Xwalk)', 'South Exiting (street)'])} />
                                      } />

                                    {this.state.accGrp3
                                      ? <ExpandLessRoundedIcon onClick={() => { this.setState({ accGrp3: false }) }} style={{ marginBottom: 0, marginLeft: -11, color: '#00313D' }} />
                                      : <ExpandMoreIcon onClick={() => { this.setState({ accGrp3: true }) }} style={{ marginBottom: 0, marginLeft: -11, color: '#00313D' }} />
                                    }
                                  </Grid>
                                </AccordionSummary>
                                <AccordionDetails style={{ marginTop: -10, marginBottom: 0, backgroundColor: '#D6D6D6', }}>
                                  <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start'>
                                    <FormControlLabel
                                      style={{ marginTop: 0 }}
                                      label={<Typography style={{ lineHeight: 1 }}>{Object.keys(cardState.locationChecked)[6]}</Typography>}
                                      control={cardState.aggregate
                                        ? <Checkbox style={{ color: locationsObject['colors'][6] }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[6]]} onChange={this.changeLoc(Object.keys(cardState.locationChecked)[6])} value={Object.keys(cardState.locationChecked)[6]} />
                                        : <Radio style={{ color: locationsObject['colors'][6] }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[6]]} onChange={this.changeLoc(Object.keys(cardState.locationChecked)[6])} value={Object.keys(cardState.locationChecked)[6]} />
                                      } />

                                    <FormControlLabel
                                      style={{ marginTop: 0 }}
                                      label={<Typography style={{ lineHeight: 1 }}>{Object.keys(cardState.locationChecked)[8]}</Typography>}
                                      control={cardState.aggregate
                                        ? <Checkbox style={{ color: locationsObject['colors'][8] }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[8]]} onChange={this.changeLoc(Object.keys(cardState.locationChecked)[8])} value={Object.keys(cardState.locationChecked)[8]} />
                                        : <Radio style={{ color: locationsObject['colors'][8] }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[8]]} onChange={this.changeLoc(Object.keys(cardState.locationChecked)[8])} value={Object.keys(cardState.locationChecked)[8]} />
                                      } />
                                  </Grid>
                                </AccordionDetails>
                              </Accordion>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion square elevation={0} expanded={this.state.accGrp4} style={{ marginTop: -12, marginBottom: 0, paddingTop: 0 }} classes={{ root: classes.MuiAccordionroot }}>
                          <AccordionSummary
                            style={{ backgroundColor: '#D6D6D6', marginTop: 0, marginLeft: -15, marginBottom: 0, paddingRight: 0, justifyContent: 'center', minHeight: 0 }}
                            aria-controls='panel1a-content'
                            id='panel1a-header'
                          >
                            <Typography style={{ fontSize: '14px', borderBottom: '1px solid black', width: '100%', marginBottom: 5, color: 'rgba(0, 0, 0, 1)' }}>Union Square North Plaza</Typography>
                            {this.state.accGrp4
                              ? <ExpandLessRoundedIcon onClick={() => { this.setState({ accGrp4: false }) }} style={{ marginBottom: 0, marginLeft: -11, color: '#00313D' }} />
                              : <ExpandMoreIcon onClick={() => { this.setState({ accGrp4: true }) }} style={{ marginBottom: 0, marginLeft: -11, color: '#00313D' }} />
                            }
                          </AccordionSummary>
                          <AccordionDetails style={{ marginTop: -5, marginBottom: -5, backgroundColor: '#D6D6D6', }}>
                            <Grid container direction='column' justifyContent='space-evenly' alignItems='flex-start'>
                              {locationsObject['locationList'].map((value, index) => {
                                return (index > 9 && index < 21)
                                  ? <Fragment key={index}>
                                    {locationsObject['locGroupLbls'][Object.keys(cardState.locationChecked)[index]] &&
                                      // insert label defined in locationsArray above location
                                      <FormLabel className={classes.locGroupLbl}>{locationsObject['locGroupLbls'][Object.keys(cardState.locationChecked)[index]]}</FormLabel>
                                    }
                                    <FormControlLabel
                                      key={'fc' + index}
                                      className={(locationsObject['subsubLocs'].includes(Object.keys(cardState.locationChecked)[index])) ? `${classes.location} ${classes.subsubLocation}` :
                                        (locationsObject['subLocs'].includes(Object.keys(cardState.locationChecked)[index]))
                                          ? `${classes.location} ${classes.subLocation}`
                                          : classes.location
                                      }
                                      label={<Typography color='textPrimary' style={{ lineHeight: 1 }}>{Object.keys(cardState.locationChecked)[index]}</Typography>}
                                      control={cardState.aggregate
                                        ? <Checkbox style={{ marginTop: -4, color: locationsObject['colors'][index] }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[index]]} onChange={this.changeLoc(Object.keys(cardState.locationChecked)[index])} value={Object.keys(cardState.locationChecked)[index]} />
                                        : <Radio style={{ marginTop: -4, color: locationsObject['colors'][index] }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[index]]} onChange={this.changeLoc(Object.keys(cardState.locationChecked)[index])} value={Object.keys(cardState.locationChecked)[index]} />
                                      }
                                    />
                                  </Fragment>
                                  : ''
                              })}
                            </Grid>
                          </AccordionDetails>
                        </Accordion>

                      </Grid>
                    }
                  </FormGroup>
                </Grid>

                <Divider hidden={showCombos ? false : true} orientation='vertical' flexItem />

                <Grid item hidden={showCombos ? false : true} style={{ paddingTop: 20, paddingLeft: 20, paddingRight: 0, maxWidth: 310 }}>

                  {Object.keys(locationsObject.locCategoryTypes).map(catType => {
                    return (
                      <Fragment key={catType}>
                        <FormLabel>{catType}</FormLabel>
                        <FormGroup style={{ paddingBottom: 0, paddingLeft: 10, paddingTop: 6 }}>
                          {Object.values(locationsObject.locCategoryTypes[catType]).map(category => {
                            return (
                              <FormControlLabel
                                key={category}
                                className={classes.location}
                                control={<Radio style={{ color: 'black' }} checked={cardState.checkedLocCategories[category]} onChange={this.changeLocCategory(category)} value={category} />}
                                label={<Typography color='textPrimary'>{category}</Typography>}
                                style={{ marginTop: -12 }}
                              />
                            )
                          })}
                        </FormGroup>
                      </Fragment>
                    )
                  })}

                  <FormLabel>Landlord</FormLabel>
                  <FormGroup style={{ paddingBottom: 0, paddingLeft: 10, paddingTop: 6 }}>
                    {Object.keys(cardState.checkedLocOwners).map((value, index) => {
                      return (
                        <FormControlLabel
                          key={'ll' + index}
                          className={classes.location}
                          control={typeof locationsObject['locOwners'][value] === 'undefined'
                            ? <Radio style={{ color: 'black' }} checked={cardState.checkedLocOwners[Object.keys(cardState.checkedLocOwners)[index]]}
                              onChange={this.changeOwner(Object.keys(cardState.checkedLocOwners)[index])} value={Object.keys(cardState.checkedLocOwners)[index]} />
                            : <Radio style={{ color: 'black' }} checked={cardState.checkedLocOwners[Object.keys(cardState.checkedLocOwners)[index]]}
                              onChange={this.changeOwner(Object.keys(cardState.checkedLocOwners)[index])} value={Object.keys(cardState.checkedLocOwners)[index]} />}
                          label={<Typography color='textPrimary'>{Object.keys(cardState.checkedLocOwners)[index]}</Typography>}
                          style={{ paddingLeft: typeof locationsObject['locOwners'][value] === 'undefined' ? 20 : 0, marginTop: typeof locationsObject['locOwners'][value] === 'undefined' ? -16 : -12 }}
                        />
                      )
                    })}
                  </FormGroup>

                  <FormGroup style={{ paddingBottom: 12 }}>
                    <FormControlLabel style={{ color: '#00313D' }}
                      control={
                        <Switch
                          checked={cardState.aggregate}
                          onChange={this.setAggregate}
                          name='checkedB'
                          color='primary'
                        />
                      }
                      label={<Typography color='textPrimary'>Combine Locations</Typography>}
                    />
                    <Collapse in={cardState.aggregate}>
                      <FormControl component='fieldset' style={{ paddingLeft: 10, color: '#00313D' }}>
                        <RadioGroup style={{ marginTop: -12 }} name='combined' value={cardState.aggregationType} onChange={this.changeAggregationType}>
                          <FormControlLabel
                            value='average'
                            control={<StyledRadio classes={{ root: classes.root, checked: classes.checked }} />}
                            label={<Typography color='textPrimary'>Average</Typography>} />
                          <FormControlLabel
                            value='total'
                            control={<StyledRadio classes={{ root: classes.root, checked: classes.checked }} />}
                            style={{ marginTop: -14 }}
                            label={<Typography color='textPrimary'>Total</Typography>} />
                        </RadioGroup>
                      </FormControl>
                    </Collapse>
                  </FormGroup>
                </Grid>
              </Grid>
            </Paper>
          </ClickAwayListener>
        </Popover>
      </Grid>
    )
  }
}

export default withRouter(withStyles(styles)(LocationCompare));
