import React, { Fragment, PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

import { LayersClearOutlined as LayersClearRoundedIcon, CloseRounded as CloseIcon, ExpandMore as ExpandMoreIcon, KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import {
  Button,
  Card,
  Checkbox,
  Chip,
  ClickAwayListener,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  Paper,
  Popover,
  Radio,
  RadioGroup,
  Switch,
  Typography,
  Accordion,
  AccordionDetails
} from '@material-ui/core';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import { alpha, withStyles } from '@material-ui/core/styles';

import { vImgsObj } from '../../utils/images';

let closeCardBtn = false;

const styles = ({
  location: {
    // marginTop: 0,
    // paddingLeft: 0,
    color: '#3A3A3A',
  },
  subLocation: {
    paddingLeft: 15,
  },
  subsubLocation: {
    paddingLeft: 30,
  },
  locGroupLbl: {
    padding: '10px 0 6px',
    textAlign: 'left',
    borderBottom: '1px solid black',
    marginBottom: '5px',
    fontSize: '14px'
  },
  cardContainer: {
    cursor: 'pointer',
    height: '100%',
    width: '100%',
  },
  locationCard: {
    cursor: 'pointer',
    position: 'relative',
    minWidth: 56,
    height: '100%',
    backgroundColor: '#f5f5f5',
  },
  locationCardContents: {
    cursor: 'pointer',
  },
  chip: {
    marginRight: 1,
    marginLeft: 1,
    textAlign: 'center',
    alignItems: 'center',
    fontWeight: 400,
    cursor: 'pointer',
    color: '#1e1e1e'
  },
  locMenu: {
    backgroundColor: '#D6D6D6',
  },
  subMenu: {
    backgroundColor: '#D6D6D6',
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
  },
  root: {},
  checked: {},
  // MuiAccordionroot: {
  //   '&.MuiAccordion-root:before': {
  //     height: 0
  //   }
  // },
  locationsGrid: {
    '& label > span:first-child': {
      padding: '7px 9px',
    },
  },
  dropDownContainer: {
    paddingRight: 25
  }
});

const StyledRadio = withStyles({
  root: {
    color: '#7A7A7A',
    '&$checked': {
      color: '#277175'
    }
  },
  checked: {}
})(props => <Radio size='small' {...props} />);

const AccordionSummary = withStyles({
  expanded: {},
  content: {
    margin: 0,
    '&$expanded': {
      margin: 0,
    },
  },
  expandIcon: {
    padding: '6px 12px'
  }
})(MuiAccordionSummary);

const initLocationChecked = (locList, initLoc, emptyCard) => {
  let locationChecked = {};

  for (let l in locList) {
    let locName = locList[l];
    locationChecked[locName] = false;
  }

  if (!emptyCard) {
    locationChecked[locList[initLoc]] = true;
  }

  return locationChecked;
}

const initLocationChecked2 = (locList, initLoc, emptyCard, locTypeList) => {
  const locationChecked = {};

  locList.forEach(loc => locationChecked[loc] = false); // ALL locations, set to false
  if (!emptyCard) locationChecked[locTypeList[initLoc].name] = true;

  return locationChecked;
}

const initOwnerChecked = (ownerList) => {
  let ownerChecked = {};

  if (Object.keys(ownerList).length === 1) {
    ownerChecked[Object.keys(ownerList)[0]] = false;
  }
  else {
    for (let o in ownerList) {
      ownerChecked[o] = false;
      let ownerDivisions = ownerList[o];

      if (!Array.isArray(ownerDivisions)) {
        for (let od in ownerDivisions) {
          ownerChecked[od] = false;
        }
      }
    }
  }

  return ownerChecked;
}

class LocationCard2 extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      openObj: this.props.locationsObject['defaultOpen'],
      acIndex: this.props.acIndex,
      cardState: {
        name: this.props.name,
        aggregate: (Object.keys(this.props.initlocationCard).length === 0) ? false : this.props.initlocationCard.aggregate,
        aggregationType: (Object.keys(this.props.initlocationCard).length === 0) ? 'total' : this.props.initlocationCard.aggregationType,
        locationChecked: (Object.keys(this.props.initlocationCard).length === 0)
          ? initLocationChecked2(this.props.locationsObject['locationList'], ((this.props.accessControl) ? this.props.acIndex : this.props.name), this.props.emptyCard, this.props.locationsObject['locationTypes'][this.props.locFilter]) // props.emptyCard is always undefined
          : this.props.initlocationCard.locationChecked,
        checkedLocOwners: (Object.keys(this.props.initlocationCard).length === 0)
          ? initOwnerChecked(this.props.locationsObject['locOwners'])
          : this.props.initlocationCard.checkedLocOwners,
        checkedLocCategories: (Object.keys(this.props.initlocationCard).length === 0)
          ? Object.keys(this.props.locationsObject['locCategories']).reduce((acc, category) => ({ ...acc, [category]: false }), {})
          : this.props.initlocationCard.checkedLocCategories,
        checkedVehicleTypes: (Object.keys(this.props.initlocationCard).length === 0)
          ? this.props.locationsObject.vehicleTypes.reduce((acc, vehicle) => ({ ...acc, [vehicle]: true }), {}) // { 'bikes': true, 'cars': true, 'buses': true, 'trucks': true, 'motorbikes': true }
          : (this.props.initlocationCard.checkedVehicleTypes || this.props.locationsObject.vehicleTypes.reduce((acc, vehicle) => ({ ...acc, [vehicle]: true }), {})),
      },
      menuAnchor: null,
      menuOpen: false,
      noCard: this.props.noCard,
    }
  }

  dropDownToggle = e => {
    this.setState({
      openObj: {
        ...this.state.openObj,
        [e]: !this.state.openObj[e]
      }
    });
  };

  changeLocCategory = catName => event => {
    let cardState = this.state.cardState;
    let locChecked = cardState.locationChecked;
    let tempCheckedLocOwners = cardState.checkedLocOwners;
    let tempCheckedLocCategories = cardState.checkedLocCategories;

    if (Object.keys(tempCheckedLocOwners).length === 1) {
      tempCheckedLocOwners[Object.keys(tempCheckedLocOwners)[0]] = false;
    }
    else {
      for (let i in tempCheckedLocOwners) {
        tempCheckedLocOwners[i] = false;
      }
    }

    for (let i in tempCheckedLocCategories) {
      tempCheckedLocCategories[i] = false;
    }

    if (event.target.checked) tempCheckedLocCategories[catName] = true;

    let catLocs = this.props.locationsObject.locCategories[catName]; // array of all locations within a category
    catLocs = (catLocs && catLocs.length > 0) ? catLocs.filter(loc => !this.props.locationsObject.noCompare.includes(loc)) : []; // if location should be excluded, remove from array

    for (let l in locChecked) {
      if (catLocs.indexOf(l) > -1) {
        locChecked[l] = true;
      }
      else {
        locChecked[l] = false;
      }
    }

    cardState.locationChecked = locChecked;
    cardState.checkedLocOwners = tempCheckedLocOwners;
    cardState.checkedLocCategories = tempCheckedLocCategories;
    cardState.aggregate = true;
    cardState.aggregationType = this.props.locationsObject.aggregationType[catName];

    this.setState({ ...this.state, cardState: cardState }, () => {
      this.props.onChange(cardState);
    });
  }

  changeOwner = name => event => {
    let cardState = this.state.cardState;
    let locChecked = cardState.locationChecked;
    let tempCheckedLocOwners = cardState.checkedLocOwners;
    let tempCheckedLocCategories = cardState.checkedLocCategories;
    const tempLocOwners = this.props.locationsObject['locOwners'];

    if (Object.keys(tempCheckedLocOwners).length === 1) {
      tempCheckedLocOwners[Object.keys(tempCheckedLocOwners)[0]] = false;
    }
    else {
      for (let i in tempCheckedLocOwners) {
        tempCheckedLocOwners[i] = false;
      }
    }

    for (let i in tempCheckedLocCategories) {
      tempCheckedLocCategories[i] = false;
    }

    if (event.target.checked) tempCheckedLocOwners[name] = true;

    let ownerLocs = [];
    let owner = tempLocOwners[name]

    if (typeof owner === 'undefined') {
      for (let o in tempLocOwners) {
        let ownerDivisions = tempLocOwners[o];

        if (!Array.isArray(ownerDivisions)) {
          let ownerDivision = ownerDivisions[name];

          if (typeof ownerDivision !== 'undefined') {
            ownerLocs = ownerDivision;
          }
        }
      }
    }
    else if (Object.keys(tempCheckedLocOwners).length === 1) {
      ownerLocs = tempLocOwners[name];
    }
    else if (!Array.isArray(owner)) {
      for (let od in owner) {
        if (event.target.checked) {
          tempCheckedLocOwners[od] = true;
          ownerLocs = ownerLocs.concat(owner[od]);
        }
      }
    }
    else {
      ownerLocs = tempLocOwners[name];
    }

    ownerLocs = (ownerLocs.length > 0) ? ownerLocs.filter(loc => !this.props.locationsObject.noCompare.includes(loc)) : [];

    for (let l in locChecked) {
      if (ownerLocs.indexOf(l) > -1) {
        locChecked[l] = true;
      }
      else {
        locChecked[l] = false;
      }
    }

    cardState.aggregate = true;
    cardState.locationChecked = locChecked;
    cardState.checkedLocOwners = tempCheckedLocOwners;
    cardState.checkedLocCategories = tempCheckedLocCategories;

    this.props.onChange(cardState);
  }

  changeVehicleType = name => event => {
    let cardState = this.state.cardState;
    let locChecked = cardState.locationChecked;
    let tempCheckedLocOwners = cardState.checkedLocOwners;
    let tempCheckedLocCategories = cardState.checkedLocCategories;
    let tempCheckedVehicleTypes = cardState.checkedVehicleTypes;

    if (Object.keys(tempCheckedLocOwners).length === 1) {
      tempCheckedLocOwners[Object.keys(tempCheckedLocOwners)[0]] = false;
    }
    else {
      for (let i in tempCheckedLocOwners) {
        tempCheckedLocOwners[i] = false;
      }
    }

    for (let i in tempCheckedLocCategories) {
      tempCheckedLocCategories[i] = false;
    }

    // if name is array set all vehicle types to false to deselect.
    if (Array.isArray(name)) {
      name.forEach(v => tempCheckedVehicleTypes[v] = false);
    }
    else {
      tempCheckedVehicleTypes[name] = event.target.checked;
    }

    cardState.locationChecked = locChecked;
    cardState.checkedLocOwners = tempCheckedLocOwners;
    cardState.checkedLocCategories = tempCheckedLocCategories;
    cardState.checkedVehicleTypes = tempCheckedVehicleTypes;

    this.setState({ ...this.state, cardState: cardState, }, () => {
      this.props.onChange(cardState);
    });
  }

  changeLoc = name => event => {
    let updateObj = (this.props.locationsObject.fullDetails[name].expandLocation && this.state.openObj[name]) ? true : false;

    let cardState = this.state.cardState;
    let locChecked = cardState.locationChecked;
    let tempCheckedLocOwners = cardState.checkedLocOwners;
    let tempCheckedLocCategories = cardState.checkedLocCategories;

    if (Object.keys(tempCheckedLocOwners).length === 1) {
      tempCheckedLocOwners[Object.keys(tempCheckedLocOwners)[0]] = false;
    }
    else {
      for (let i in tempCheckedLocOwners) {
        tempCheckedLocOwners[i] = false;
      }
    }

    for (let i in tempCheckedLocCategories) {
      tempCheckedLocCategories[i] = false;
    }

    cardState.checkedLocOwners = tempCheckedLocOwners;
    cardState.checkedLocCategories = tempCheckedLocCategories;

    if (cardState.aggregate) {
      const parentLocsObj = this.props.locationsObject['pLocs']; // { 'Wrentham Village': ['Wrentham Village (Wrentham Court)', 'Wrentham Village (Liberty Court)'] }
      const childLocsObj = this.props.locationsObject['cLocs']; // { 'Wrentham Village (Wrentham Court)': ['Wrentham Village'], 'Wrentham Village (Liberty Court)': ['Wrentham Village'] }
      locChecked[name] = event.target.checked;

      if (Object.keys(childLocsObj).includes(name)) {
        for (const loc of childLocsObj[name]) {
          locChecked[loc] = false; // if name is a child location, uncheck its parent
        }
      }

      if (Object.keys(parentLocsObj).includes(name)) {
        for (const loc of parentLocsObj[name]) {
          locChecked[loc] = false; // if name is a parent location, uncheck all of its child locations
        }
      }

      cardState.locationChecked = locChecked;

      this.setState({
        ...this.state,
        cardState: cardState,
        ...(updateObj && { openObj: { ...this.state.openObj, [name]: !this.state.openObj[name] } }),
      }, () => {
        this.props.onChange(cardState);
      });
    }
    else {
      for (let l in locChecked) {
        locChecked[l] = false;
      }
      locChecked[name] = event.target.checked;
      cardState.locationChecked = locChecked;

      this.setState({
        ...this.state,
        cardState: cardState,
        ...(updateObj && { openObj: { ...this.state.openObj, [name]: !this.state.openObj[name] } }),
      }, () => {
        this.props.onChange(cardState);
      });
    }
  };

  setAggregate = event => {
    let cardState = this.state.cardState;
    let locChecked = cardState.locationChecked;
    let tempCheckedLocOwners = cardState.checkedLocOwners;
    let tempCheckedLocCategories = cardState.checkedLocCategories;

    if (!event.target.checked) {
      let isFirst = false;

      if (Object.keys(tempCheckedLocOwners).length === 1) {
        tempCheckedLocOwners[Object.keys(tempCheckedLocOwners)[0]] = false;
      }
      else {
        for (let i in tempCheckedLocOwners) {
          tempCheckedLocOwners[i] = false;
        }
      }

      for (let i in tempCheckedLocCategories) {
        tempCheckedLocCategories[i] = false;
      }

      for (let l in locChecked) {
        if (isFirst) {
          locChecked[l] = false;
        }
        else if (locChecked[l]) {
          isFirst = true;
        }
      }
    }

    cardState.checkedLocCategories = tempCheckedLocCategories;
    cardState.checkedLocOwners = tempCheckedLocOwners;
    cardState.aggregate = event.target.checked;
    cardState.locationChecked = locChecked;

    this.setState({ ...this.state, cardState: cardState }, () => {
      this.props.onChange(cardState)
    });
  };

  changeAggregationType = (event) => {
    let cardState = this.state.cardState;
    cardState.aggregationType = event.target.value;

    this.setState({ ...this.state, cardState: cardState }, () => {
      this.props.onChange(cardState);
    });
  }

  handleClick = (event) => {
    if (closeCardBtn) return; // handleClick overlaps closeCard, if closeCard was clicked return here.
    this.setState({ menuOpen: !this.state.menuOpen, menuAnchor: event.currentTarget });
  };

  handleClickAway = () => {
    this.setState({ menuOpen: false, menuAnchor: null });
  };

  closeCard = name => () => {
    closeCardBtn = true;

    this.setState({ openObj: {} }, () => {
      this.props.closeLocation(name);
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    closeCardBtn = false; // reset closeCardBtn

    if (nextProps.noCard !== prevState.noCard) {
      // is this ever entered?
      if (nextProps.noCard) {
        let cardState = prevState.cardState;
        let locChecked = cardState.locationChecked;
        let tempCheckedLocOwners = cardState.checkedLocOwners;
        let tempCheckedLocCategories = cardState.checkedLocCategories;

        for (let l in locChecked) {
          locChecked[l] = false;
        }
        for (let i in tempCheckedLocOwners) {
          tempCheckedLocOwners[i] = false;
        }
        for (let i in tempCheckedLocCategories) {
          tempCheckedLocCategories[i] = false;
        }

        cardState.locationChecked = locChecked;
        cardState.checkedLocOwners = tempCheckedLocOwners;
        cardState.checkedLocCategories = tempCheckedLocCategories;
        cardState.aggregate = false;
        cardState.aggregationType = 'total';
        cardState.name = 0;

        return {
          cardState: cardState,
          noCard: true,
        };
      }
      else {
        return {
          noCard: false,
        };
      }
    }

    if (nextProps.accessControl && nextProps.acIndex !== prevState.acIndex) {
      let newCheckLoc = initLocationChecked(nextProps.locationsObject['locationList'], nextProps.acIndex, nextProps.emptyCard);
      let newCardState = prevState.cardState;

      newCardState.locationChecked = newCheckLoc;
      newCardState.name = nextProps.name;

      return {
        acIndex: nextProps.acIndex,
        cardState: newCardState,
      };
    }

    // unless func returns before here, this will always be entered, only checks if objects are the same instance
    if (nextProps.initlocationCard !== prevState.cardState) {
      if (Object.keys(nextProps.initlocationCard).length > 0) {

        return {
          cardState: nextProps.initlocationCard,
        };
      }
    }

    return {};
  }

  render() {
    const { accessControl, classes, locationsObject, locFilter, name, showCombos, showX } = this.props;
    const { cardState, menuAnchor, menuOpen, noCard, openObj } = this.state;
    const transpar = 0.6;
    const headerHeight = document.getElementById('headerId')?.clientHeight || 32; // gets height of the appBar header, use to offset location popover maxheight

    const locTypes = locationsObject.locationTypes;
    const filteredLocs = locTypes[locFilter];

    return (
      <Grid container className={classes.cardContainer} style={{ height: 46 }}>
        <Card className={classes.locationCard} onClick={this.handleClick}>
          <Grid container direction='column' justifyContent='flex-start' alignItems='stretch'>
            <IconButton style={{ position: 'absolute', right: -11, top: -16, display: (noCard || !showX) ? 'none' : '' }} className={classes.closeBtn} onClick={this.closeCard(name)}>
              <CloseIcon style={{ width: '14px', color: '#1e1e1e' }} />
            </IconButton>

{/* 2 or more combined locations - this sets the Avg/Total label in the container around the locations */}
            {!noCard && (Object.values(cardState.locationChecked).filter(Boolean).length > 1 || Object.values(cardState.checkedLocCategories).indexOf(true) > -1 || Object.values(cardState.checkedLocOwners).indexOf(true) > -1) &&
              <Typography
                style={{
                  height: 17, paddingRight: 18, paddingLeft: 5, fontSize: 'calc(8px + 0.2vw)', fontWeight: '600',
                  backgroundColor: (!accessControl && typeof (this.props.cardColor) !== 'undefined') ? alpha(this.props.cardColor, transpar) : '',
                  display: (noCard || (Object.values(cardState.locationChecked).filter(Boolean).length < 2 && Object.values(cardState.checkedLocCategories).indexOf(true) === -1 && Object.values(cardState.checkedLocOwners).indexOf(true) === -1)) ? 'none' : ''
                }}
              >
{/* If loc category selected, display category plus total or avg ie. Downtown (Total) */}
                {Object.values(cardState.checkedLocCategories).indexOf(true) > -1
                  ? Object.keys(cardState.checkedLocCategories)[Object.values(cardState.checkedLocCategories).indexOf(true)] + (cardState.aggregationType === 'average' ? ' (Avg)' : ' (Total)')
                  : ''}

{/* If loc owner selected, display owner plus total or avg ie. Macerich (Total) */}
                {Object.values(cardState.checkedLocOwners).indexOf(true) > -1
                  ? Object.keys(cardState.checkedLocOwners)[Object.values(cardState.checkedLocOwners).indexOf(true)] + (cardState.aggregationType === 'average' ? ' (Avg)' : ' (Total)')
                  : ''}

{/* Custom McDonalds labels for specific location groups */}
                {/* {(locationsObject['locationList'][0] === 'Massachusetts Ave' && Object.values(cardState.checkedLocCategories).indexOf(true) === -1 && Object.values(cardState.checkedLocOwners).indexOf(true) === -1 && cardState.aggregate)
                  ? (cardState.locationChecked['McDonald\'s Sidewalk'] && cardState.locationChecked['Whole Foods Sidewalk']) && !cardState.locationChecked['Whole Foods Entering'] && !cardState.locationChecked['Whole Foods Exiting'] && !cardState.locationChecked['Mass Ave - Edgerly Passage Entering'] && !cardState.locationChecked['Mass Ave - Edgerly Passage Exiting'] && !cardState.locationChecked['Massachusetts Ave'] && !cardState.locationChecked['Edgerly Crossing'] && !cardState.locationChecked['New Edgerly Crossing']
                    ? 'Edgerly Rd ' + (cardState.aggregationType === 'average' ? '(Avg)' : '(Total)')
                    : (cardState.locationChecked['Mass Ave - Edgerly Passage Entering'] && cardState.locationChecked['Mass Ave - Edgerly Passage Exiting']) && !cardState.locationChecked['McDonald\'s Sidewalk'] && !cardState.locationChecked['Whole Foods Sidewalk'] && !cardState.locationChecked['Whole Foods Entering'] && !cardState.locationChecked['Whole Foods Exiting'] && !cardState.locationChecked['Massachusetts Ave'] && !cardState.locationChecked['Edgerly Crossing'] && !cardState.locationChecked['New Edgerly Crossing']
                      ? 'Mass Ave - Edgerly Passage ' + (cardState.aggregationType === 'average' ? '(Avg)' : '(Total)')
                      : cardState.aggregationType === 'average' ? 'Average' : 'Total'
                  : ''} */}

{/* If neither a loc category or owner is selected, display Average or Total label */}
                {(locationsObject['locationList'][0] !== 'Massachusetts Ave' && Object.values(cardState.checkedLocCategories).indexOf(true) === -1 && Object.values(cardState.checkedLocOwners).indexOf(true) === -1 && cardState.aggregate)
                  ? cardState.aggregationType === 'average' ? 'Average' : 'Total'
                  : ''}
              </Typography>
            }

{/* Single Selected location */}
            {!noCard && (Object.values(cardState.locationChecked).indexOf(true) > -1) && (!cardState.aggregate || (Object.values(cardState.locationChecked).filter(Boolean).length === 1 && Object.values(cardState.checkedLocCategories).indexOf(true) === -1 && Object.values(cardState.checkedLocOwners).indexOf(true) === -1)) &&
              <Grid container direction='column' justifyContent='center' alignItems='center'
                style={{
                  display: (!cardState.aggregate || (Object.values(cardState.locationChecked).filter(Boolean).length === 1 && Object.values(cardState.checkedLocCategories).indexOf(true) === -1 && Object.values(cardState.checkedLocOwners).indexOf(true) === -1)) ? '' : 'none',
                  width: '100%', paddingRight: 11, paddingLeft: 10, paddingBottom: 0, height: 46, backgroundColor: alpha(locationsObject['colors'][Object.values(cardState.locationChecked).indexOf(true)], transpar),
                  fontWeight: '500', fontSize: 'calc(5px + 0.4vw)'
                }}
              >
                {locationsObject['locAbbreviation'][Object.values(cardState.locationChecked).indexOf(true)]}

{/* Display vehicle icons when 1 or more vehicle type is filtered */}
                {/* {locFilter === 'Vehicles' && cardState.checkedVehicleTypes && (Object.values(cardState.checkedVehicleTypes).indexOf(false) > -1) && */}
{/* Always sisplay vehicle icons */}
                {locFilter === 'Vehicles' && cardState.checkedVehicleTypes &&
                  <div style={{ marginTop: 5 }}>
                    {Object.keys(cardState.checkedVehicleTypes).map((value, i) => {
                      if (cardState.checkedVehicleTypes[value]) {
                        return <img key={i} alt={value} src={vImgsObj[value]} style={{ height: 10, paddingRight: 5 }} />
                      }
                    })}
                  </div>
                }
              </Grid>
            }

{/* Grid container for combined locations and when no location is selected - should change to be conditionally rendered instead if using display none  */}
            <Grid container direction='column' justifyContent='space-around' alignItems='stretch' style={{ padding: 1, height: (cardState.aggregate) ? 29 : 46, paddingTop: 0, paddingBottom: 0 }}>
              <Grid item container direction='row' justifyContent='center' alignItems='stretch' className={classes.locationCardContents} style={{ padding: 0 }}>

{/* When multiple locations combined on 1 card, displays loc abbreviation */}
                {locationsObject['locationList'].map((value, index) => {
                  if (cardState.aggregate && !noCard && cardState.locationChecked[Object.keys(cardState.locationChecked)[index]]) {
                    return (
                      <Chip
                        key={'ch' + index}
                        size={(Object.values(cardState.locationChecked).filter(Boolean).length < 2 && Object.values(cardState.checkedLocCategories).indexOf(true) === -1 && Object.values(cardState.checkedLocOwners).indexOf(true) === -1) ? 'medium' : 'small'}
                        style={{
                          paddingBottom: 0,
                          backgroundColor: alpha(locationsObject['colors'][index], transpar),
                          display: (cardState.aggregate && !noCard && cardState.locationChecked[Object.keys(cardState.locationChecked)[index]]) ? '' : 'none',
                          fontWeight: '400',
                          fontSize: 'calc(7px + 0.2vw)'
                        }}
                        label={locationsObject['locAbbreviation'][index]}
                        className={classes.chip}
                      />
                    )
                  }
                })}

{/* When no location is selected yet */}
                <Typography
                  style={{
                    cursor: 'pointer', fontWeight: '600', color: '#02313D', lineHeight: '115%', fontSize: 'calc(10px + 0.2vw)', paddingLeft: 7, paddingRight: 7,
                    marginTop: (Object.values(cardState.checkedLocCategories).indexOf(true) === -1 && Object.values(cardState.checkedLocOwners).indexOf(true) === -1) ? 18 : 0, // empty card or empty w/ Category label
                    display: (Object.values(cardState.locationChecked).indexOf(true) === -1 || noCard) ? 'block' : 'none'
                  }}>
                  Select Location
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Card>
        <Popover
          open={menuOpen}
          anchorEl={menuAnchor}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          PaperProps={{
            style: { maxHeight: 'calc(100% - ' + headerHeight + 'px)' },
          }}
        >
          <ClickAwayListener onClickAway={this.handleClickAway}>
            <Paper className={classes.locMenu} style={{ width: window.innerWidth > 767 ? '100%' : '72vw' }}>
              <Grid container alignItems='flex-start' direction='row'>
                <Grid item>
                  <FormGroup style={{ paddingTop: 16, paddingLeft: 20, paddingRight: 10, paddingBottom: 15 }}>

                    {/* Access Control and 'all' clients */}
                    {this.props.client === 'all' && locationsObject['locationList'].map((value, index) => {
                      return Object.keys(cardState.locationChecked)[index] === '26 Broadway' ?
                        <Accordion key={'acc' + index} square style={{ marginTop: 0, marginBottom: 5 }} className={classes.subMenu}>
                          <AccordionSummary
                            style={{ minHeight: 0 }}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls='panel1a-content'
                            id='panel1a-header'
                          >
                            <Typography style={{ fontSize: 'calc(6px + 0.4vw)' }}>ADDITIONAL LOCATIONS</Typography>
                          </AccordionSummary>
                          <AccordionDetails style={{ padding: '4px 16px 8px', flexDirection: 'column' }}>
                            <FormControlLabel
                              key={'fc' + index}
                              className={classes.location}
                              control={cardState.aggregate ?
                                <Checkbox style={{ marginTop: -4, color: locationsObject['colors'][index], padding: '4px 9px 12px' }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[index]]}
                                  onChange={this.changeLoc(Object.keys(cardState.locationChecked)[index])} value={Object.keys(cardState.locationChecked)[index]} /> :
                                <Radio style={{ marginTop: -4, color: locationsObject['colors'][index], padding: '4px 9px 12px' }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[index]]}
                                  onChange={this.changeLoc(Object.keys(cardState.locationChecked)[index])} value={Object.keys(cardState.locationChecked)[index]} />
                              }
                              label={<Typography color='textPrimary' style={{ paddingBottom: 8 }}>{Object.keys(cardState.locationChecked)[index]}</Typography>}
                            />
                          </AccordionDetails>
                        </Accordion> :
                        <Fragment key={index}>
                          {locationsObject['locGroupLbls'][Object.keys(cardState.locationChecked)[index]] &&
                            // insert label defined in locationsArray above location
                            <FormLabel className={classes.locGroupLbl}>{locationsObject['locGroupLbls'][Object.keys(cardState.locationChecked)[index]]}</FormLabel>
                          }
                          <FormControlLabel
                            key={'fc' + index}
                            className={(locationsObject['subsubLocs'].includes(Object.keys(cardState.locationChecked)[index])) ? `${classes.location} ${classes.subsubLocation}` :
                              (locationsObject['subLocs'].includes(Object.keys(cardState.locationChecked)[index]))
                                ? `${classes.location} ${classes.subLocation}`
                                : classes.location
                            }
                            control={cardState.aggregate ?
                              <Checkbox style={{ marginTop: -4, color: locationsObject['colors'][index] }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[index]]}
                                onChange={this.changeLoc(Object.keys(cardState.locationChecked)[index])} value={Object.keys(cardState.locationChecked)[index]} /> :
                              <Radio style={{ marginTop: -4, color: locationsObject['colors'][index] }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[index]]}
                                onChange={this.changeLoc(Object.keys(cardState.locationChecked)[index])} value={Object.keys(cardState.locationChecked)[index]} />
                            }
                            label={<Typography color='textPrimary'>{Object.keys(cardState.locationChecked)[index]}</Typography>}
                          />
                        </Fragment>
                    })}

                    {this.props.client !== 'all' &&
                      <Grid container direction='column' className={classes.locationsGrid}>

                        {filteredLocs.map((value, index) => {
                          return (
                            <Fragment key={index}>
{/* Expandable Locations */}
                              {value.expandLocation && !value.childOf &&
                                <>
                                  <Grid container justifyContent='flex-start' alignItems='center' style={{ marginLeft: (value.leftIndent) ? 10 : '' }}>
                                    <Grid item xs>
                                      <FormControlLabel
                                        className={classes.location}
                                        label={<Typography color='textPrimary'>{value.name}</Typography>}
                                        control={cardState.aggregate
                                          ? <Checkbox style={{ color: value.color }} checked={cardState.locationChecked[value.name]} onChange={this.changeLoc(value.name)} value={value.name} />
                                          : <Radio style={{ color: value.color }} checked={cardState.locationChecked[value.name]} onChange={this.changeLoc(value.name)} value={value.name} />
                                        }
                                      />
                                    </Grid>
                                    <Grid item xs={1}>
                                      <IconButton aria-label='expand row' size='small' onClick={this.dropDownToggle.bind(this, value.name)}>
                                        {openObj[value.name] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                      </IconButton>
                                    </Grid>
                                  </Grid>

                                  <Collapse in={openObj[value.name]} timeout='auto' style={{ marginLeft: (value.leftIndent) ? 25 : 15 }}>

                                    {value.parentOf.map((v, i) => {
                                      if (locationsObject.fullDetails[v].expandLocation) {
                                        return (
                                          <Fragment key={i}>

                                            <Grid container justifyContent='flex-start' alignItems='center'>
                                              <Grid item xs>
                                                <FormControlLabel
                                                  key={i}
                                                  className={classes.location}
                                                  label={<Typography color='textPrimary'>{v}</Typography>}
                                                  control={cardState.aggregate
                                                    ? <Checkbox style={{ color: locationsObject.fullDetails[v].color }} checked={cardState.locationChecked[v]} onChange={this.changeLoc(v)} value={v} />
                                                    : <Radio style={{ color: locationsObject.fullDetails[v].color }} checked={cardState.locationChecked[v]} onChange={this.changeLoc(v)} value={v} />
                                                  } />
                                              </Grid>

                                              <Grid item xs={1}>
                                                <IconButton aria-label='expand row' size='small' onClick={this.dropDownToggle.bind(this, v)} disabled={accessControl}>
                                                  {openObj[v] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                                </IconButton>
                                              </Grid>
                                            </Grid>

                                            <Collapse in={openObj[v] || accessControl} timeout='auto' style={{ marginLeft: 15 }}>

                                              <Grid container direction={(locationsObject.fullDetails[v].paired) ? 'row' : 'column'} justifyContent={(locationsObject.fullDetails[v].paired) ? 'flex-start' : 'space-evenly'} alignItems='flex-start'>
                                                {locationsObject.fullDetails[v].parentOf.map((v, i) => {
                                                  return (
                                                    <FormControlLabel
                                                      key={i}
                                                      className={classes.location}
                                                      label={<Typography color='textPrimary'>{v}</Typography>}
                                                      control={cardState.aggregate
                                                        ? <Checkbox style={{ color: locationsObject.fullDetails[v].color }} checked={cardState.locationChecked[v]} onChange={this.changeLoc(v)} value={v} />
                                                        : <Radio style={{ color: locationsObject.fullDetails[v].color }} checked={cardState.locationChecked[v]} onChange={this.changeLoc(v)} value={v} />
                                                      } />
                                                  )
                                                })}
                                              </Grid>

                                            </Collapse>
                                          </Fragment>
                                        )
                                      }

                                      if (!locationsObject.fullDetails[v].expandLocation) {
                                        return (
                                          <Fragment key={i}>
                                            <Grid container direction='column' justifyContent='space-evenly' alignItems='flex-start' style={{ marginLeft: (locationsObject.fullDetails[v].leftIndent) ? 10 : '' }}>
                                              <FormControlLabel
                                                key={i}
                                                className={(locationsObject.fullDetails[v].subsubLocation)
                                                  ? `${classes.location} ${classes.subsubLocation}`
                                                  : (locationsObject.fullDetails[v].subLocation)
                                                    ? `${classes.location} ${classes.subLocation}`
                                                    : classes.location
                                                }
                                                label={<Typography color='textPrimary'>{v}</Typography>}
                                                control={cardState.aggregate
                                                  ? <Checkbox style={{ color: locationsObject.fullDetails[v].color }} checked={cardState.locationChecked[v]} onChange={this.changeLoc(v)} value={v} />
                                                  : <Radio style={{ color: locationsObject.fullDetails[v].color }} checked={cardState.locationChecked[v]} onChange={this.changeLoc(v)} value={v} />
                                                } />
                                            </Grid>
                                          </Fragment>
                                        )
                                      }
                                    })}

                                  </Collapse>
                                </>
                              }
{/* Normal Locations */}
                              {!value.expandLocation && !value.expandLabel && !value.childOf && !value.paired &&
                                <FormControlLabel
                                  key={'fc' + index}
                                  className={(value.subsubLocation)
                                    ? `${classes.location} ${classes.subsubLocation}`
                                    : (value.subLocation)
                                      ? `${classes.location} ${classes.subLocation}`
                                      : classes.location
                                  }
                                  label={<Typography color='textPrimary'>{value.name}</Typography>}
                                  control={cardState.aggregate
                                    ? <Checkbox style={{ color: value.color }} checked={cardState.locationChecked[value.name]} onChange={this.changeLoc(value.name)} value={value.name} />
                                    : <Radio style={{ color: value.color }} checked={cardState.locationChecked[value.name]} onChange={this.changeLoc(value.name)} value={value.name} />
                                  }
                                />
                              }
{/* Expandable Labels: ie: Archived */}
                              {value.expandLabel && !value.childOf &&
                                <>
                                  <Grid container justifyContent='flex-start' alignItems='center'>
                                    <Grid item xs>
                                      <Typography onClick={this.dropDownToggle.bind(this, value.name)} style={{ cursor: 'pointer', fontSize: '14px', padding: '0 0 6px', borderBottom: '1px solid black', color: 'rgba(0, 0, 0, 0.54)' }}>{value.name}</Typography>
                                    </Grid>
                                    <Grid item xs={1} className={classes.dropDownContainer}>
                                      <IconButton aria-label='expand row' size='small' onClick={this.dropDownToggle.bind(this, value.name)} disabled={accessControl}>
                                        {openObj[value.name] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                      </IconButton>
                                    </Grid>
                                  </Grid>

                                  <Collapse in={openObj[value.name] || accessControl} timeout='auto' style={{ marginLeft: 15 }}>

                                    {value.parentOf.map((v, i) => {
// Nested Normal Locations
                                      if (!locationsObject.fullDetails[v].paired) {
                                        return (
                                          <Fragment key={i}>
                                            {locationsObject.fullDetails[v].groupLabel &&
                                              <Typography style={{ fontSize: '14px', marginTop: 5, marginBottom: -5, color: 'rgba(0, 0, 0, 1)' }}>{locationsObject.fullDetails[v].groupLabel}</Typography>
                                            }
                                            <Grid container direction='column' justifyContent='space-evenly' alignItems='flex-start' style={{ marginLeft: (locationsObject.fullDetails[v].leftIndent) ? 10 : '' }}>
                                              <FormControlLabel
                                                key={i}
                                                className={(locationsObject.fullDetails[v].subsubLocation)
                                                  ? `${classes.location} ${classes.subsubLocation}`
                                                  : (locationsObject.fullDetails[v].subLocation)
                                                    ? `${classes.location} ${classes.subLocation}`
                                                    : classes.location
                                                }
                                                label={<Typography color='textPrimary'>{v}</Typography>}
                                                control={cardState.aggregate
                                                  ? <Checkbox style={{ color: locationsObject.fullDetails[v].color }} checked={cardState.locationChecked[v]} onChange={this.changeLoc(v)} value={v} />
                                                  : <Radio style={{ color: locationsObject.fullDetails[v].color }} checked={cardState.locationChecked[v]} onChange={this.changeLoc(v)} value={v} />
                                                } />
                                            </Grid>
                                          </Fragment>
                                        )
                                      }
                                    })}

                                  </Collapse>
                                </>
                              }

                            </Fragment>
                          )
                        })}
                      </Grid>
                    }

                  </FormGroup>
                </Grid>

                <Divider hidden={showCombos ? false : true} orientation='vertical' flexItem />

                <Grid item hidden={showCombos ? false : true} style={{ paddingTop: 20, paddingLeft: 20, paddingRight: 0 }}>

                  {locFilter === 'Vehicles' && cardState.checkedVehicleTypes &&
                    <>
                      <FormLabel>Vehicle Type</FormLabel>
                      <Button
                        color='primary'
                        onClick={this.changeVehicleType(locationsObject.vehicleTypes)} // ['bikes', 'cars', 'buses', 'trucks', 'motorbikes']
                        style={{ marginLeft: 15 }}
                      >
                        <LayersClearRoundedIcon style={{ width: 20, display: 'inline', color: '#00313D' }} />
                      </Button>
                      <FormGroup style={{ paddingBottom: 0, paddingLeft: 10, paddingTop: 6 }}>
                        {Object.keys(cardState.checkedVehicleTypes).map((value, index) => {
                          return (
                            <FormControlLabel
                              className={classes.location}
                              key={'vt' + index}
                              control={
                                <Checkbox
                                  // style={{ color: locationsObject['colors'][index] }}
                                  style={{ color: 'black' }}
                                  checked={cardState.checkedVehicleTypes[value]}
                                  onChange={this.changeVehicleType(value)}
                                  value={value}
                                />
                              }
                              label={
                                <Typography color='textPrimary'>
                                  {value}<img alt={value} src={vImgsObj[value]} style={{ height: 14, marginLeft: 10 }} />
                                </Typography>
                              }
                              style={{ marginTop: -12 }}
                            />
                          )
                        })}
                      </FormGroup>
                    </>
                  }

                  {locFilter !== 'Vehicles' &&
                    <>
                      {Object.keys(locationsObject.locCategoryTypes).map(catType => {
                        return (
                          <Fragment key={catType}>
                            <FormLabel>{catType}</FormLabel>
                            <FormGroup style={{ paddingBottom: 0, paddingLeft: 10, paddingTop: 6 }}>
                              {Object.values(locationsObject.locCategoryTypes[catType]).map(category => {
                                return (
                                  <FormControlLabel
                                    key={category}
                                    className={classes.location}
                                    control={<Radio style={{ color: (locFilter !== 'Vehicles') ? 'black' : 'rgba(0, 0, 0, 0.38)' }} checked={cardState.checkedLocCategories[category]} onChange={this.changeLocCategory(category)} value={category} />}
                                    label={<Typography color={(locFilter !== 'Vehicles') ? 'textPrimary' : ''}>{category}</Typography>}
                                    style={{ marginTop: -12 }}
                                    disabled={(locFilter !== 'Vehicles') ? false : true}
                                  />
                                )
                              })}
                            </FormGroup>
                          </Fragment>
                        )
                      })}

                      <FormLabel>Landlord</FormLabel>
                      <FormGroup style={{ paddingBottom: 0, paddingLeft: 10, paddingTop: 6 }}>
                        {Object.keys(cardState.checkedLocOwners).map((value, index) => {
                          return (
                            <FormControlLabel
                              key={'ll' + index}
                              className={classes.location}
                              control={(typeof locationsObject['locOwners'][value] === 'undefined')
                                ? <Radio style={{ color: (locFilter !== 'Vehicles') ? 'black' : 'rgba(0, 0, 0, 0.38)' }} checked={cardState.checkedLocOwners[Object.keys(cardState.checkedLocOwners)[index]]}
                                  onChange={this.changeOwner(Object.keys(cardState.checkedLocOwners)[index])} value={Object.keys(cardState.checkedLocOwners)[index]} />
                                : <Radio style={{ color: (locFilter !== 'Vehicles') ? 'black' : 'rgba(0, 0, 0, 0.38)' }} checked={cardState.checkedLocOwners[Object.keys(cardState.checkedLocOwners)[index]]}
                                  onChange={this.changeOwner(Object.keys(cardState.checkedLocOwners)[index])} value={Object.keys(cardState.checkedLocOwners)[index]} />
                              }
                              label={<Typography color={(locFilter !== 'Vehicles') ? 'textPrimary' : ''}>{Object.keys(cardState.checkedLocOwners)[index]}</Typography>}
                              style={{ paddingLeft: typeof locationsObject['locOwners'][value] === 'undefined' ? 20 : 0, marginTop: typeof locationsObject['locOwners'][value] === 'undefined' ? -16 : -12 }}
                              disabled={(locFilter !== 'Vehicles') ? false : true}
                            />
                          )
                        })}
                      </FormGroup>
                    </>
                  }

                  <FormGroup style={{ paddingBottom: 12 }}>
                    <FormControlLabel style={{ color: '#00313D' }}
                      control={
                        <Switch
                          checked={cardState.aggregate}
                          onChange={this.setAggregate}
                          name='checkedB'
                          color='primary'
                        />
                      }
                      label={<Typography color='textPrimary'>Combine Locations</Typography>}
                    />
                    <Collapse in={cardState.aggregate}>
                      <FormControl component='fieldset' style={{ paddingLeft: 10, color: '#00313D' }}>
                        <RadioGroup style={{ marginTop: -12 }} name='combined' value={cardState.aggregationType} onChange={this.changeAggregationType}>
                          <FormControlLabel
                            value='average'
                            control={<StyledRadio classes={{ root: classes.root, checked: classes.checked }} />}
                            label={<Typography color='textPrimary'>Average</Typography>} />
                          <FormControlLabel
                            value='total'
                            control={<StyledRadio classes={{ root: classes.root, checked: classes.checked }} />}
                            style={{ marginTop: -14 }}
                            label={<Typography color='textPrimary'>Total</Typography>} />
                        </RadioGroup>
                      </FormControl>
                    </Collapse>
                  </FormGroup>
                </Grid>
              </Grid>
            </Paper>
          </ClickAwayListener>
        </Popover>
      </Grid>
    )
  }
}

export default withRouter(withStyles(styles)(LocationCard2));
