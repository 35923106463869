import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

import CompareIcon from '@material-ui/icons/CollectionsBookmarkOutlined';
import { Button, ClickAwayListener, Grid, Paper, Popover, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = ({
  locMenu: {
    backgroundColor: '#D6D6D6',
  },
  menuItem: {
    padding: 5,
    width: '100%',
  },
  root: {},
  checked: {}
});

const initLocationChecked = (locList) => {
  let locationChecked = {};

  for (let l in locList) {
    let locName = locList[l];
    locationChecked[locName] = false;
  }

  return locationChecked;
}

const initOwnerChecked = (ownerList) => {
  let ownerChecked = {};

  if (Object.keys(ownerList).length === 1) {
    ownerChecked[Object.keys(ownerList)[0]] = false;
  }
  else {
    for (let o in ownerList) {
      ownerChecked[o] = false;
      let ownerDivisions = ownerList[o];

      if (!Array.isArray(ownerDivisions)) {
        for (let od in ownerDivisions) {
          ownerChecked[od] = false;
        }
      }
    }
  }

  return ownerChecked;
}

class LocationMultiCompare extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      cardState: {
        name: this.props.name,
        aggregate: false,
        aggregationType: 'average',
        locationChecked: initLocationChecked(this.props.locationsObject['locationList']),
        checkedLocOwners: initOwnerChecked(this.props.locationsObject['locOwners']),
        checkedLocCategories: Object.keys(this.props.locationsObject['locCategories']).reduce((acc, category) => ({ ...acc, [category]: false }), {}),
      },
      menuAnchor: null,
      menuOpen: false,
      newCard: false,
    }
  }

  compareLocations = (e) => {
    this.props.closeAllLocations();

    const btnClicked = e.currentTarget.name;
    let locChecked = this.state.cardState.locationChecked;
    let tempCheckedLocOwners = this.state.cardState.checkedLocOwners;
    let tempCheckedLocCategories = this.state.cardState.checkedLocCategories;

    for (let i in tempCheckedLocOwners) {
      tempCheckedLocOwners[i] = false;
    }

    for (let i in tempCheckedLocCategories) {
      tempCheckedLocCategories[i] = false;
    }

    const locArray = (btnClicked !== 'All') ? this.props.locationsObject.locCategories[e.currentTarget.name] : Object.keys(locChecked); // 'All' creates array of loc names from object

    let n = 0;
    for (const loc of locArray) {
      if (this.props.locationsObject.noCompare.includes(loc)) continue; // if location should be excluded skip to next location

      locArray.forEach(v => locChecked[v] = false); // reset all to false
      locChecked[loc] = true;

      let newCardState = {};
      newCardState.name = n;
      newCardState.locationChecked = locChecked;
      newCardState.checkedLocOwners = tempCheckedLocOwners;
      newCardState.checkedLocCategories = tempCheckedLocCategories;
      newCardState.aggregate = false;
      newCardState.aggregationType = 'average'; // not used passed as placeholder
      n++;

      this.props.onChange(newCardState);
    }
  };

  handleClick = event => {
    let cardState = this.state.cardState;

    // reset cardState
    if (!this.state.menuOpen) {
      let locChecked = cardState.locationChecked;
      let tempCheckedLocOwners = cardState.checkedLocOwners;
      let tempCheckedLocCategories = cardState.checkedLocCategories;

      for (let l in locChecked) {
        locChecked[l] = false;
      }
      for (let i in tempCheckedLocOwners) {
        tempCheckedLocOwners[i] = false;
      }
      for (let i in tempCheckedLocCategories) {
        tempCheckedLocCategories[i] = false;
      }

      cardState.locationChecked = locChecked;
      cardState.checkedLocOwners = tempCheckedLocOwners;
      cardState.checkedLocCategories = tempCheckedLocCategories;
      cardState.aggregate = false;
      cardState.aggregationType = 'average';
    }

    this.setState({
      newCard: this.state.menuOpen,
      menuOpen: !this.state.menuOpen,
      menuAnchor: event.currentTarget,
      cardState: cardState,
    });
  };

  handleClickAway = () => {
    this.setState({
      newCard: !this.state.newCard,
      menuOpen: false,
      menuAnchor: null
    });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let cardState = prevState.cardState;

    // if ((nextProps.noCard !== prevState.noCard)) {
    //   if (nextProps.noCard) {
    //     cardState.name = 0;
    //   }
    // }

    if (nextProps.name !== prevState.cardState.name && prevState.newCard) {
      cardState.name = nextProps.name;
    }

    return {
      cardState: cardState,
    };
  }

  render() {
    const { classes, compareActiveLoc, locationsObject, locFilter, noCard } = this.props;
    const { menuAnchor, menuOpen } = this.state;

    return (
      <Grid
        container
        alignItems='center'
        justifyContent='center'
        style={{
          height: 40, width: '100%',
          color: (locFilter !== 'Vehicles' && (compareActiveLoc || noCard)) ? '#02313D' : '#4d6e77',
          fontWeight: 600, lineHeight: '115%', paddingLeft: 2, paddingRight: 2, fontSize: 'calc(7px + 0.2vw)',
          backgroundColor: (locFilter !== 'Vehicles' && (compareActiveLoc || noCard)) ? '#f5f5f5' : '#B3C0C4'
        }}
        disabled={compareActiveLoc ? false : true}
        onClick={this.handleClick}
      >
        <CompareIcon justify='center' style={{ width: 'calc(10px + 0.4vw)', paddingBottom: '0.0em', paddingLeft: '0.0vw' }} />
        <Popover
          open={menuOpen}
          anchorEl={menuAnchor}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
          <ClickAwayListener onClickAway={this.handleClickAway}>
            <Paper className={classes.locMenu}>
              <Grid container direction='column' alignItems='flex-start' style={{ paddingTop: 10, paddingLeft: 10, paddingRight: 20, paddingBottom: 15 }}>
                <Typography className={classes.menuItem} style={{ marginBottom: 3 }}>Multi-Compare</Typography>

                <Grid className={classes.menuItem}>
                  <Button name={'All'} onClick={this.compareLocations} className='changeButton btn btn-small waves-effect waves-light hoverable accent-3' variant='contained' justify='center' aria-label='All' style={{ width: '100%', justifyContent: 'flex-start' }}>
                    All
                  </Button>
                </Grid>

                {Object.keys(locationsObject.locCategories).map(category => {
                  return (
                    <Grid key={category} className={classes.menuItem}>
                      <Button name={category} onClick={this.compareLocations} className='changeButton btn btn-small waves-effect waves-light hoverable accent-3' variant='contained' justify='center' aria-label={category} style={{ width: '100%', justifyContent: 'flex-start' }}>
                        {category}
                      </Button>
                    </Grid>
                  )
                })}
              </Grid>
            </Paper>
          </ClickAwayListener>
        </Popover>
      </Grid>
    )
  }
}

export default withRouter(withStyles(styles)(LocationMultiCompare));
