import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { Line, XAxis, YAxis, ComposedChart, ResponsiveContainer } from 'recharts';

import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { reduceArray, getColor } from '../../utils/helpers';
import { colorsMen, colorsWomen, colorsChildren, colorsAdults, colorsSeniors, colorsBikes, colorsBuses, colorsCars, colorsTrucks } from '../../utils/colors';

const styles = theme => ({});


class TinyByDate extends PureComponent {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    const { age, gender, mode, multDates, numPlots, openTab, vehicles, series } = this.props;

    let [dataPoints, secondDateCardIndex] = [0, 0];
    try { secondDateCardIndex = series.length - 1; }
    catch (e) {}

    let chartArray = reduceArray(series, numPlots, multDates, secondDateCardIndex);
    try {
      for (let ser in chartArray) {
        if (chartArray[ser].data.length > dataPoints) {
          dataPoints = chartArray[ser].data.length;
        }
      }
    } catch (err) {}

    return (
      <Grid container item xs justifyContent='space-around' alignItems='center' style={{ height: '10vh', paddingTop: '2vh', paddingBottom: '3vh', paddingLeft: '2vw', paddingRight: '2vw', textAlign: 'center' }}>
        <Typography style={{ fontSize: 'calc(2px + 1.0vw)', cursor: 'pointer' }}>
          {(this.props.occupancy)
            ? <>Occupancy<br />by Date</>
            : <>{mode} by<br />Date</>
          }
        </Typography>
        {dataPoints === 0 &&
          <Grid container justifyContent='space-around' alignItems='center'>
            <CircularProgress size={'calc(1vw + 1vh)'} style={{ marginTop: 'calc(1vw + 2vh)' }} />
          </Grid>
        }
        {dataPoints > 0 &&
          <ResponsiveContainer width={'100%'} height={'100%'} minHeight={0}>
            <ComposedChart style={{ cursor: 'pointer' }} data={chartArray} margin={{ top: 10, right: 2, left: 2, bottom: 0 }}>
              <XAxis xAxisId={0} dataKey='_id.timeStamp' scale='utc' type='number' domain={['auto', 'auto']} hide={true} />
              <XAxis xAxisId={1} dataKey='_id.timeStamp' scale='utc' type='number' domain={['auto', 'auto']} hide={true} />
              <YAxis hide={true} domain={[0, dataMax => (dataMax * 1.75)]} />
              {openTab === 0 &&
                chartArray.map((s, idx) => (
                  [
                    <Line isAnimationActive={true} type='monotoneX' data={s.data} dataKey='count' stroke={getColor(s.locationLabels, idx, this.props.locationsObject, chartArray)}
                      strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''} dot={false} name={'Traffic ' + (s.locationLabels)}
                      hide={!this.props.count || s.data.length === 0} strokeWidth={2} xAxisId={(multDates & idx === (chartArray.length - 1)) ? 1 : 0} />,
                  ]
                ))
              }
              {openTab === 1 && !vehicles &&
                chartArray.map((s, idx) => (
                  [
                    <Line isAnimationActive={true} type='monotoneX' data={s.data} dataKey='women' stroke={colorsWomen[idx]}
                      strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''} dot={false} name={'Women ' + (s.locationLabels)}
                      hide={!gender || s.data.length === 0} strokeWidth={2} xAxisId={(multDates & idx === (chartArray.length - 1)) ? 1 : 0} />,

                    <Line isAnimationActive={true} type='monotoneX' data={s.data} dataKey='men' stroke={colorsMen[idx]}
                      strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''} dot={false} name={'Men ' + (s.locationLabels)}
                      hide={!gender || s.data.length === 0} strokeWidth={2} xAxisId={(multDates & idx === (chartArray.length - 1)) ? 1 : 0} />,
                  ]
                ))
              }
              {openTab === 1 && vehicles &&
                chartArray.map((s, idx) => (
                  [
                    <Line isAnimationActive={true} type='monotoneX' data={s.data} dataKey='bikes' stroke={colorsBikes[idx]} fill={colorsBikes[idx]}
                      strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''} dot={false} name={'Bikes ' + (s.locationLabels)}
                      hide={!gender || s.data.length === 0} strokeWidth={2} xAxisId={(multDates & idx === (chartArray.length - 1)) ? 1 : 0} />,

                    <Line isAnimationActive={true} type='monotoneX' data={s.data} dataKey='buses' stroke={colorsBuses[idx]} fill={colorsBuses[idx]}
                      strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''} dot={false} name={'Buses ' + (s.locationLabels)}
                      hide={!gender || s.data.length === 0} strokeWidth={2} xAxisId={(multDates & idx === (chartArray.length - 1)) ? 1 : 0} />,

                    <Line isAnimationActive={true} type='monotoneX' data={s.data} dataKey='cars' stroke={colorsCars[idx]} fill={colorsCars[idx]}
                      strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''} dot={false} name={'Cars ' + (s.locationLabels)}
                      hide={!gender || s.data.length === 0} strokeWidth={2} xAxisId={(multDates & idx === (chartArray.length - 1)) ? 1 : 0} />,

                    <Line isAnimationActive={true} type='monotoneX' data={s.data} dataKey='trucks' stroke={colorsTrucks[idx]} fill={colorsTrucks[idx]}
                      strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''} dot={false} name={'Trucks ' + (s.locationLabels)}
                      hide={!gender || s.data.length === 0} strokeWidth={2} xAxisId={(multDates & idx === (chartArray.length - 1)) ? 1 : 0} />,
                  ]
                ))
              }
              {openTab === 2 && !vehicles &&
                chartArray.map((s, idx) => (
                  [
                    <Line isAnimationActive={true} type='monotoneX' data={s.data} dataKey='adults' stroke={colorsAdults[idx]} fill={colorsAdults[idx]}
                      strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''} dot={false} name={'Adults ' + (s.locationLabels)}
                      hide={!age || s.data.length === 0} strokeWidth={2} xAxisId={(multDates & idx === (chartArray.length - 1)) ? 1 : 0} />,

                    <Line isAnimationActive={true} type='monotoneX' data={s.data} dataKey='children' stroke={colorsChildren[idx]} fill={colorsChildren[idx]}
                      strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''} dot={false} name={'Children ' + (s.locationLabels)}
                      hide={!age || s.data.length === 0} strokeWidth={2} xAxisId={(multDates & idx === (chartArray.length - 1)) ? 1 : 0} />,

                    <Line isAnimationActive={true} type='monotoneX' data={s.data} dataKey='seniors' stroke={colorsSeniors[idx]} fill={colorsSeniors[idx]}
                      strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''} dot={false} name={'Seniors ' + (s.locationLabels)}
                      hide={!age || s.data.length === 0} strokeWidth={2} xAxisId={(multDates & idx === (chartArray.length - 1)) ? 1 : 0} />
                  ]
                ))
              }
            </ComposedChart>
          </ResponsiveContainer>
        }
      </Grid>
    )
  }
}

export default withRouter(withStyles(styles)(TinyByDate));
