import React, { PureComponent } from 'react';
import moment from 'moment';
import { Area, ComposedChart, XAxis, YAxis } from 'recharts';
import { Grid, Typography } from '@material-ui/core';

class ByTimeArea extends PureComponent {

  render() {
    const { age, gender, series } = this.props;
    const [MEN_C1, WOMEN_C1, CHILDREN_C1, SENIOR_C1] = ['#6089c4', '#d77da5', '#7ea84e', '#643d23'];

    const countsObj = {
      weekend: {
        count: series[0]?.totalCounts?.weekend?.count ?? 0,
        men: series[0]?.totalCounts?.weekend?.men ?? 0,
        women: series[0]?.totalCounts?.weekend?.women ?? 0,
        children: series[0]?.totalCounts?.weekend?.children ?? 0,
        seniors: series[0]?.totalCounts?.weekend?.seniors ?? 0
      },
      weekday: {
        count: series[0]?.totalCounts?.weekday?.count ?? 0,
        men: series[0]?.totalCounts?.weekday?.men ?? 0,
        women: series[0]?.totalCounts?.weekday?.women ?? 0,
        children: series[0]?.totalCounts?.weekday?.children ?? 0,
        seniors: series[0]?.totalCounts?.weekday?.seniors ?? 0
      },
      count: { dataKey: 'count', fill: series[0].chartColor, stroke: series[0].chartColor },
      men: { dataKey: 'men', fill: MEN_C1, stroke: MEN_C1 },
      women: { dataKey: 'women', fill: WOMEN_C1, stroke: WOMEN_C1 },
      children: { dataKey: 'children', fill: CHILDREN_C1, stroke: CHILDREN_C1 },
      seniors: { dataKey: 'seniors', fill: SENIOR_C1, stroke: SENIOR_C1 }
    }

    // set order, larger area chart is lower number, displayed behind smaller chart.
    const countObj = {
      weekend: {
        1: countsObj.count
      },
      weekday: {
        1: countsObj.count
      }
    }
    const genderObj = {
      weekend: {
        1: (countsObj.weekend.men >= countsObj.weekend.women) ? countsObj.men : countsObj.women,
        2: (countsObj.weekend.men < countsObj.weekend.women) ? countsObj.men : countsObj.women
      },
      weekday: {
        1: (countsObj.weekday.men >= countsObj.weekday.women) ? countsObj.men : countsObj.women,
        2: (countsObj.weekday.men < countsObj.weekday.women) ? countsObj.men : countsObj.women
      }
    }
    const ageObj = {
      weekend: {
        1: (countsObj.weekend.children >= countsObj.weekend.seniors) ? countsObj.children : countsObj.seniors,
        2: (countsObj.weekend.children < countsObj.weekend.seniors) ? countsObj.children : countsObj.seniors
      },
      weekday: {
        1: (countsObj.weekday.children >= countsObj.weekday.seniors) ? countsObj.children : countsObj.seniors,
        2: (countsObj.weekday.children < countsObj.weekday.seniors) ? countsObj.children : countsObj.seniors
      }
    }

    let dayTypeObj = {
      weekend: { dataPoints: 0, label: 'Weekend' },
      weekday: { dataPoints: 0, label: 'Weekday' }
    }

    try {
      for (let ser in series) {
        for (let type in dayTypeObj) {
          if (series[ser].data[type].length > dayTypeObj[type].dataPoints) {
            dayTypeObj[type].dataPoints = series[ser].data[type].length;
          }
        }
      }
    } catch (err) { }

    return (
      <>
        {(!gender && !age) && Object.keys(dayTypeObj).map(type => (
          <Grid key={type} item xs={12} style={{ display: gender || age ? 'none' : '', height: '100%', marginTop: 15, marginBottom: 15 }}>
            <Typography style={{ fontSize: 13, fontWeight: 600, marginLeft: 5, marginBottom: 8, fontFamily: 'Nunito Sans' }}>{dayTypeObj[type].label}</Typography>

            {dayTypeObj[type].dataPoints > 0 &&
              <ComposedChart width={320} height={145} data={series[0].data[type]} margin={{ top: 5, right: 5, left: -30, bottom: 0 }}>

                <XAxis
                  allowDuplicatedCategory={false}
                  dataKey='_id.time'
                  domain={[0, 'auto']}
                  dy={6}
                  interval={20}
                  // label={{ value: 'Time', position: 'bottom', fontSize: 'calc(10px + 0.4vw)', dy: (window.innerWidth / 60 - 25) }} // don't use window.innerWidth
                  style={{ fontSize: 9, fontFamily: 'Nunito Sans' }}
                  tickFormatter={timeStr => moment(timeStr).tz('America/New_York').add(0, 'minutes').format('h A')} />

                <YAxis
                  dx={0}
                  // domain={[
                  //   0,
                  //   dataMax => (Math.round((dataMax * 1.3) / parseInt('1'.padEnd(Math.log(dataMax * 1) * Math.LOG10E + 1 | 0, '0'))) * parseInt('1'.padEnd(Math.log(dataMax * 1) * Math.LOG10E + 1 | 0, '0')))
                  // ]}
                  // label={{ value: 'Traffic', angle: -90, position: 'left' }}
                  style={{ fontSize: 9, fontFamily: 'Nunito Sans', }}
                  // tickCount={6}
                  tickFormatter={(tickItem) => { return (tickItem >= 1000 ? (tickItem / 1000).toLocaleString() + ' k' : tickItem.toLocaleString()) }}
                  type='number' />

                <Area
                  data={series[0].data[type]}
                  dataKey={countObj[type][1].dataKey}
                  fill={this.props.color}
                  isAnimationActive={false}
                  stroke={this.props.color}
                  type='monotone' />

              </ComposedChart>
            }
          </Grid>
        ))}

        {(gender || age) && Object.keys(dayTypeObj).map(type => (
          <Grid key={type} item xs={6} style={{ display: gender || age ? '' : 'none', height: '100%' }}>
            <Typography style={{ fontSize: 13, fontWeight: 600, marginLeft: 5, marginBottom: 8, fontFamily: 'Nunito Sans' }}>{dayTypeObj[type].label}</Typography>

            {dayTypeObj[type].dataPoints > 0 &&
              <ComposedChart width={170} height={118} data={series[0].data[type]} margin={{ top: 5, right: 5, left: -30, bottom: 0 }}>

                <XAxis
                  allowDuplicatedCategory={false}
                  dataKey='_id.time'
                  domain={[0, 'auto']}
                  dy={6}
                  interval={20}
                  // label={{ value: 'Time', position: 'bottom', fontSize: 'calc(10px + 0.4vw)', dy: (window.innerWidth / 60 - 25) }} // don't use window.innerWidth
                  style={{ fontSize: 9, fontFamily: 'Nunito Sans' }}
                  tickFormatter={timeStr => moment(timeStr).tz('America/New_York').add(0, 'minutes').format('h A')} />

                <YAxis
                  dx={0}
                  // domain={[
                  //   0,
                  //   dataMax => (Math.round((dataMax * 1.3) / parseInt('1'.padEnd(Math.log(dataMax * 1) * Math.LOG10E + 1 | 0, '0'))) * parseInt('1'.padEnd(Math.log(dataMax * 1) * Math.LOG10E + 1 | 0, '0')))
                  // ]}
                  // label={{ value: 'Traffic', angle: -90, position: 'left' }}
                  style={{ fontSize: 9, fontFamily: 'Nunito Sans', }}
                  // tickCount={6}
                  tickFormatter={(tickItem) => { return (tickItem >= 1000 ? (tickItem / 1000).toLocaleString() + ' k' : tickItem.toLocaleString()) }}
                  type='number' />

                {gender &&
                  series.map(s => (
                    [
                      <Area
                        data={s.data[type]}
                        dataKey={genderObj[type][1].dataKey}
                        fill={genderObj[type][1].fill}
                        isAnimationActive={false}
                        stroke={genderObj[type][1].stroke}
                        type='monotone' />,

                      <Area
                        data={s.data[type]}
                        dataKey={genderObj[type][2].dataKey}
                        fill={genderObj[type][2].fill}
                        isAnimationActive={false}
                        stroke={genderObj[type][2].stroke}
                        type='monotone' />,
                    ]
                  ))
                }

                {age &&
                  series.map(s => (
                    [
                      <Area
                        data={s.data[type]}
                        dataKey={ageObj[type][1].dataKey}
                        fill={ageObj[type][1].fill}
                        isAnimationActive={false}
                        stroke={ageObj[type][1].stroke}
                        type='monotone' />,

                      <Area
                        data={s.data[type]}
                        dataKey={ageObj[type][2].dataKey}
                        fill={ageObj[type][2].fill}
                        isAnimationActive={false}
                        stroke={ageObj[type][2].stroke}
                        type='monotone' />,
                    ]
                  ))
                }
              </ComposedChart>
            }
          </Grid>
        ))}
      </>
    )
  }
}

export default ByTimeArea;
