import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { parse } from 'query-string';
import moment from 'moment-timezone';

import { alpha, Button, Grid, Typography, withStyles } from '@material-ui/core';

import config from '../../utils/config';
import { getLocations, getLocationInfo } from '../../utils/helpers';

import ByDateReport from './reports/ByDateLine';
import ByTimeReport from './reports/ByTimeArea';
import ByTimeAllReport from './reports/ByTimeAreaAll';
import ByDayOfWeekReport from './reports/DayOfWeekBar';
import ByDayHourlyTable from './reports/HourlyByDayTable';
import MonthlyAveragePie from './reports/MonthlyAveragePie';

// import MonthlyChangeBar from './reports/MonthlyChangeBar';
// import StackedBarReport from './reports/MonthlySequentialBar';


//PLEASE DO NOT DELETE!
//http://localhost:3000/report?client=mcdonalds&location=Massachusetts+Ave&week=2022-08-17&estimate=true


const styles = ({
  params: {
    width: '100%',
    margin: '5px 10px 0',
    textAlign: 'left'
  }
});

const PAGEWIDTH = 793
const PAGEHEIGHT = 1122

class CreateMcDReport extends PureComponent {
  constructor(props) {
    super(props);

    const locationsObject = getLocations({ client: props.auth.user.client });

    this.state = {
      locationsObject: locationsObject,
      renderAvgs: false,
      renderTime: false,
      // renderReport: false,
      puppeteer: (parse(window.location.search).puppeteer) || 'false', // only check query string
      reportEstimate: this.props.reportEstimate?.toString() || parse(window.location.search).estimate || 'true', // default to true
      reportClient: (parse(window.location.search).client?.toLowerCase()) || this.props.auth.user.client?.toLowerCase(),
      reportMonth: this.props.startDate?.[0]?.startOf('month') || moment(parse(window.location.search).month)?.startOf('month'), // querystring expects any valid date
      reportWeek: this.props.startDate?.[0]?.startOf('isoWeek') || moment(parse(window.location.search).week)?.startOf('isoWeek'),
      reportLocation: this.props.reportLocation?.[0]?.[0] || parse(window.location.search).location || '',
      weeklyAvgData: {},
      weeklyTimeData: {},
      byDateReportData: [],
      byTimeReportAllData: [],
      byDayOfWeekReportData: [],
      monthlyTrendsData: {
        data: [],
      },
      monthlyChangeData: {
        data: [],
      },
      footTrafficData: {
        data: [],
        labels: {}
      },
      mcdChartColor: [locationsObject.colors[0], locationsObject.COMBCOLORS[0], locationsObject.COMBCOLORS[7]],
      mcdChartLbl: ['Massachusetts Ave', 'Edgerly Rd (Total)', 'Edgerly Rd, Whole Foods Entering (Total)'],
      reportTitle: '245 Massachusetts Ave',
      reportSubTitle: 'Mass Ave vs Edgerly Rd',
    };

    if (this.state.reportLocation !== '') this.getReportData();
  }

  // componentDidMount() {
    // console.log('componentDidMount');
    // document.title = 'Create Report - Exteros';
  // }

  getReportData = async () => {
    // add error handling, all reject as soon as one of the promises rejects
    await Promise.all([
      this.apiGetAverages(),
      this.apiGetByTime()
    ]);

    const loadedDiv = document.getElementById('loaded');
    if (loadedDiv?.style.visibility) loadedDiv.style.visibility = 'visible'; // used to signal puppeteer data is loaded
  }

  getQueryDates = async (date) => {
    try {
      // use moment's 'isoWeek', matches mongodb starting week on Monday
      const startOfReportWeek = moment(date).startOf('isoWeek');
      const endOfReportWeek = moment(date).endOf('isoWeek');

      const startOfPrevReportWeek = moment(date).startOf('isoWeek').subtract(1, 'week');
      const endOfPrevReportWeek = moment(date).endOf('isoWeek').subtract(1, 'week');

      const returnObj = {
        startOfReportWeek: startOfReportWeek,
        endOfReportWeek: endOfReportWeek,
        startOfPrevReportWeek: startOfPrevReportWeek,
        endOfPrevReportWeek: endOfPrevReportWeek
      }

      return returnObj;
    } catch (err) {
      console.log('getQueryDates - error: ');
      console.log(err);
    }
  }

  getPercDelta = (current, previous, string) => {
    if (typeof current === 'undefined' || typeof previous === 'undefined' || previous === 0) return (string) ? '-' : 0;

    let avg = ((current - previous) / previous) * 100;
    avg = Math.round((avg + Number.EPSILON) * 100) / 100; // round to 2 dec places

    if (Number.isNaN(avg) || !Number.isFinite(avg)) return (string) ? '-' : 0;

    return avg;
  }

  formatValue = (number, string) => {
    if (typeof number === 'undefined' || number === 0) return (string) ? '-' : 0;

    let newNum = (number >= 1000) ? number / 1000 : number;
    newNum = Math.round((newNum + Number.EPSILON) * 10) / 10; // round to 1 dec place

    if (Number.isNaN(newNum) || !Number.isFinite(newNum)) return (string) ? '-' : 0;

    return newNum;
  }

  getBusiestHour = (dataArray) => {
    let byDayHourlyArray = JSON.parse(JSON.stringify(dataArray));
    byDayHourlyArray.sort((a, b) => {
      return (b.hrTotal - a.hrTotal);
    });

    const objHr = byDayHourlyArray[0]?.hour ?? undefined;
    const bHr = (objHr !== undefined) ? objHr - 5 : undefined;
    const suffix = (bHr !== undefined) ? ((bHr >= 12 || bHr < 0) ? 'PM' : 'AM') : ''; // set AM/PM before converting from 24hr
    const hour = (bHr !== undefined) ? ((bHr >= 13) ? (bHr - 12) : (bHr <= 0) ? (bHr + 12) : bHr) : 0; // convert from 24hr to 12hr

    return [hour, suffix];
  }

  createTable = () => {
    let counts = [];
    let [start, end, curHr, nextHr] = [0, 24, 0, 0]; // start/end are variable per client, need to update
    let [curHrString, hrString, nextHrStr] = ['', '', ''];

    for (let hr = 0; hr < end - start; hr++) {
      curHr = (hr + start);
      nextHr = curHr + 1;
    
      if (nextHr === 24) {
        nextHrStr = '12AM';
      } else if (nextHr >= 13) {
        nextHrStr = (nextHr - 12).toString() + 'PM';
      } else if (nextHr === 12) {
        nextHrStr = '12PM';
      } else {
        nextHrStr = nextHr.toString() + 'AM';
      }
    
      if (curHr >= 13) {
        curHrString = (curHr - 12).toString() + 'PM';
      } else if (curHr === 12) {
        curHrString = '12PM';
      } else if (curHr === 0) {
        curHrString = '12AM';
      } else {
        curHrString = curHr.toString() + 'AM';
      }
    
      hrString = curHrString + ' - ' + nextHrStr;
    
      let matchHr = curHr + 5;
      if (matchHr > 23) {
        matchHr = matchHr - 24;
      }
    
      counts[curHr - start] = { 'matchHr': matchHr, 'Time': hrString, 'Monday': 0, 'Tuesday': 0, 'Wednesday': 0, 'Thursday': 0, 'Friday': 0, 'Saturday': 0, 'Sunday': 0 };
    }

    return counts;
  }

  weeklyTimeData = async (dataObj, reportWeek) => {
    const returnObj = {};
    const byTimeArray = [];
    // returnObj.labels = labelsObj;

    // dataObj { dataArray: [response.data['loc'], response.data['grpA'], response.data['grpB'], nameArray: ['loc', 'grpA', 'grpB'] };
    dataObj.dataArray.forEach((d, i) => {
      // byTime
      let byTimeObj = {
        locationLabels: ['Series ' + i],
        chartColor: this.state.mcdChartColor[i],
        totalCount: d.totals?.count ?? 0,
        data: d.all
      }
      byTimeArray.push(byTimeObj);

      // create copy of byDayHourly then sort to get maxCount
      let byDayHourlyArray = JSON.parse(JSON.stringify(d.bdh));
      byDayHourlyArray.sort((a, b) => {
        return (b.max - a.max);
      });
      let maxCount = (byDayHourlyArray[0]?.max) ? byDayHourlyArray[0].max : 0;


      const [hour, suffix] = this.getBusiestHour(d.bdh);

      let tableArray = this.createTable(); // returns array /w obj for each hour, { matchHr: 5, Time: '12AM - 1AM', Monday: 0, Tuesday: 0, ... }
      tableArray.forEach((row, i) => {
        let key = row.matchHr;
        let hour = d.bdh.filter(row2 => key === row2.hour);

        if (hour.length > 0) {
          hour[0].Time = row.Time;
          tableArray[i] = hour[0];
        }
        else {
          tableArray[i].max = 0;
          tableArray[i].hour = key;
        }
      });

      returnObj[dataObj.nameArray[i]] = {
        busiestHr: {
          hr: hour,
          suffix: suffix
        },
        byDayHourly: {
          data: tableArray,
          maxCount: maxCount,
          locationLabels: ['Series ' + i]
        },
        byTimeSplit: {
          data: {
            weekday: d.weekday,
            weekend: d.weekend,
          },
          totalCounts: d.totals,
          locationLabels: ['Series ' + i]
        }
      }
    });

    returnObj.byTimeChart = byTimeArray;

    // console.log('#### weeklyTimeData returnObj');
    // console.log(returnObj);

    return returnObj;
  }

  weeklyData = async (dataObj, reportWeek) => {
    const returnObj = {};
    const [byDateArray, byDayOfWeekArray] = [[], []];
    // returnObj.labels = labelsObj;

    // dataObj { dataArray: [response.data['loc'], response.data['grpA'], response.data['grpB'], nameArray: ['loc', 'grpA', 'grpB'] };
    dataObj.dataArray.forEach((d, i) => {
      // byDate
      let byDateObj = {
        locationLabels: ['Series ' + i],
        chartColor: this.state.mcdChartColor[i],
        chartLbl: this.state.mcdChartLbl[i],
        data: d.byDayChart
      }
      byDateArray.push(byDateObj);

      // byDayOfWeek
      let dayOfWkObj = {
        locationLabels: ['Series ' + i],
        chartColor: this.state.mcdChartColor[i],
        data: d.byDayOfWeekChart
      }
      byDayOfWeekArray.push(dayOfWkObj); // data already sorted Mon - Sun for chart

      // create copy of byDayOfWeekChart then sort to get busiest day
      let dayOfWeekArray = JSON.parse(JSON.stringify(d.byDayOfWeekChart));
      dayOfWeekArray.sort((a, b) => {
        return (b.count - a.count);
      });
      let busiestDay = (dayOfWeekArray[0]?.dayOfWeek) ? moment().isoWeekday(dayOfWeekArray[0].dayOfWeek).format('dddd') : '-';

      let totalTraffic = d.currentWeek?.daily?.totals?.count || 0;

      // Current - Daily, Weekend, Weekday Averages
      let thisWkDaily = d.currentWeek?.daily?.avgs?.count || 0;
      let thisWkWkend = d.currentWeek?.weekend?.avgs?.count || 0;
      let thisWkWkday = d.currentWeek?.weekday?.avgs?.count || 0;

      // Previous - Daily, Weekend, Weekday Averages
      let prevWkDaily = d.prevWeek?.daily?.avgs?.count || 0;
      let prevWkWkend = d.prevWeek?.weekend?.avgs?.count || 0;
      let prevWkWkday = d.prevWeek?.weekday?.avgs?.count || 0;

      // % Change - Daily, Weekend, Weekday Averages
      let dailyDelta = this.getPercDelta(thisWkDaily, prevWkDaily, false);
      let wkEndDelta = this.getPercDelta(thisWkWkend, prevWkWkend, false);
      let wkDayDelta = this.getPercDelta(thisWkWkday, prevWkWkday, false);

      returnObj[dataObj.nameArray[i]] = {
        avgs: {
          dailyAvg: thisWkDaily,
          wkendAvg: thisWkWkend,
          wkdayAvg: thisWkWkday,
        },
        avgsFormatted: {
          dailyAvg: this.formatValue(thisWkDaily, false),
          wkendAvg: this.formatValue(thisWkWkend, false),
          wkdayAvg: this.formatValue(thisWkWkday, false),
        },
        perChange: {
          dailyAvg: dailyDelta,
          wkendAvg: wkEndDelta,
          wkdayAvg: wkDayDelta,
        },
        busiestDay: busiestDay,
        totalTraffic: totalTraffic,
        genderPie: [
          { name: 'women', value: d.currentWeek?.daily?.totals?.women || 0 }, { name: 'men', value: d.currentWeek?.daily?.totals?.men || 0 }
        ],
        agePie: [
          { name: 'children', value: d.currentWeek?.daily?.totals?.children || 0 }, { name: 'adults', value: d.currentWeek?.daily?.totals?.adults || 0 }, { name: 'seniors', value: d.currentWeek?.daily?.totals?.seniors || 0 }
        ],
        label: ''
      }
    });

    returnObj.byDateChart = byDateArray;
    returnObj.byDayOfWeekChart = byDayOfWeekArray;

    // console.log('#### weeklyData returnObj');
    // console.log(returnObj);

    return returnObj;
  }

  // monthlyChartData = async (data, numMonths) => {
  //   let [monthlyTrendsArray, monthlyChangeArray] = [[], []];
  //   let y = 0;

  //   for (const [key, value] of Object.entries(data.monthly)) {
  //     if (y < numMonths) {
  //       const prevMonthKey = moment(key).startOf('month').subtract(1, 'month');
  //       const prev = data.monthly[prevMonthKey.format('YYYY-MM')];

  //       if (y < numMonths) {
  //       // if (y < numMonths - 1) {
  //         monthlyChangeArray.push(
  //           {
  //             label: moment(value?.daily?.label, 'MMM-YYYY').format('MMM'), // 3 letter month abr only
  //             men: this.getPercDelta(value?.daily?.avgs?.men, prev?.daily?.avgs?.men),
  //             women: this.getPercDelta(value?.daily?.avgs?.women, prev?.daily?.avgs?.women),
  //             children: this.getPercDelta(value?.daily?.avgs?.children, prev?.daily?.avgs?.men),
  //             adults: this.getPercDelta(value?.daily?.avgs?.adults, prev?.daily?.avgs?.adults),
  //             seniors: this.getPercDelta(value?.daily?.avgs?.seniors, prev?.daily?.avgs?.seniors),

  //             dailyAvg: this.getPercDelta(value?.daily?.avgs?.count, prev?.daily?.avgs?.count),
  //             wkdayAvg: this.getPercDelta(value?.weekday?.avgs?.count, prev?.weekday?.avgs?.count),
  //             wkendAvg: this.getPercDelta(value?.weekend?.avgs?.count, prev?.weekend?.avgs?.count),
  //           }
  //         )
  //       }

  //       monthlyTrendsArray.push(
  //         {
  //           label: value?.daily?.label,
  //           men: value?.daily?.avgs?.men || 0,
  //           women: value?.daily?.avgs?.women || 0,
  //           children: value?.daily?.avgs?.children || 0,
  //           adults: value?.daily?.avgs?.adults || 0,
  //           seniors: value?.daily?.avgs?.seniors || 0,
  //           dailyAvg: value?.daily?.avgs?.count || 0,
  //           wkdayAvg: value?.weekday?.avgs?.count || 0,
  //           wkendAvg: value?.weekend?.avgs?.count || 0,
  //           perChange: {
  //             men: this.getPercDelta(value?.daily?.avgs?.men, prev?.daily?.avgs?.men),
  //             women: this.getPercDelta(value?.daily?.avgs?.women, prev?.daily?.avgs?.women),
  //             children: this.getPercDelta(value?.daily?.avgs?.children, prev?.daily?.avgs?.men),
  //             adults: this.getPercDelta(value?.daily?.avgs?.adults, prev?.daily?.avgs?.adults),
  //             seniors: this.getPercDelta(value?.daily?.avgs?.seniors, prev?.daily?.avgs?.seniors),

  //             dailyAvg: this.getPercDelta(value?.daily?.avgs?.count, prev?.daily?.avgs?.count),
  //             wkdayAvg: this.getPercDelta(value?.weekday?.avgs?.count, prev?.weekday?.avgs?.count),
  //             wkendAvg: this.getPercDelta(value?.weekend?.avgs?.count, prev?.weekend?.avgs?.count),
  //           },
  //           genderPie: [
  //             { name: 'women', value: value?.daily?.avgs?.women || 0 }, { name: 'men', value: value?.daily?.avgs?.men || 0 }
  //           ],
  //           agePie: [
  //             { name: 'children', value: value?.daily?.avgs?.children || 0 }, { name: 'adults', value: value?.daily?.avgs?.adults || 0 }, { name: 'seniors', value: value?.daily?.avgs?.seniors || 0 }
  //           ]
  //         }
  //       );
  //       y++
  //     }
  //   }

  //   return { monthlyTrendsArray, monthlyChangeArray }
  // }

  apiGetAverages = async () => {
    try {
      const { puppeteer, reportEstimate, reportLocation, reportMonth, reportWeek } = this.state;
      const { startOfReportWeek, endOfReportWeek, startOfPrevReportWeek, endOfPrevReportWeek } = await this.getQueryDates(reportWeek);
      const qName = getLocationInfo(reportLocation).qName ?? reportLocation; // use qName if location has one

      const response = await axios.get(config.api.url + '/r1/traffic/rReportWeeklyAverages', {
        params: {
          firstDate: startOfPrevReportWeek.format(),
          endDate: endOfReportWeek.format(),
          startOfReportWeek: startOfReportWeek.format(),
          endOfPrevReportWeek: endOfPrevReportWeek.format(),
          location: [qName], // ['Woodbury Common']
          estimate: reportEstimate,
          // puppeteer: puppeteer
        }
      });

      const locRespObj = { dataArray: [response.data['loc'], response.data['grpA'], response.data['grpB']], nameArray: ['loc', 'grpA', 'grpB'] };
      const { byDateChart, byDayOfWeekChart, ...weeklyDataObj } = await this.weeklyData(locRespObj, reportWeek); // all returned data
      // const { monthlyTrendsArray, monthlyChangeArray } = await this.monthlyChartData(response.data['loc'], 4); // loc data only

      this.setState({
        // renderReport: true,
        renderAvgs: true,
        byDateReportData: byDateChart,
        byDayOfWeekReportData: byDayOfWeekChart,
        weeklyAvgData: weeklyDataObj,
        // monthlyTrendsData: {
        //   data: monthlyTrendsArray.reverse() // order earliest -> latest
        //   // data: [
        //   //   // static demo data
        //   //   { label: 'Jan 2022', men: 5325, women: 5336, children: 97, adults: 10148, seniors: 416, dailyAvg: 10661, wkdayAvg: 10990, wkendAvg: 9970, genderPie: [{ name: 'women', value: 11289 }, { name: 'men', value: 12695 }] },
        //   //   { label: 'Feb 2022', men: 7206, women: 6868, children: 156, adults: 13312, seniors: 605, dailyAvg: 14073, wkdayAvg: 14129, wkendAvg: 13933, genderPie: [{ name: 'women', value: 11289 }, { name: 'men', value: 12695 }] },
        //   //   { label: 'Mar 2022', men: 9749, women: 10746, children: 194, adults: 19272, seniors: 1030, dailyAvg: 20495, wkdayAvg: 21383, wkendAvg: 17941, genderPie: [{ name: 'women', value: 11289 }, { name: 'men', value: 12695 }] },
        //   //   { label: 'Apr 2022', men: 11289, women: 12695, children: 269, adults: 22502, seniors: 1213, dailyAvg: 23985, wkdayAvg: 25003, wkendAvg: 21608, genderPie: [{ name: 'women', value: 12695 }, { name: 'men', value: 11289 }], agePie: [{ name: 'children', value: 269 }, { name: 'adults', value: 22502 }, { name: 'seniors', value: 1213 }] },
        //   // ],
        // },
        // monthlyChangeData: {
        //   data: monthlyChangeArray.reverse() // order earliest -> latest
        // },
        // footTrafficData: {
        //   data: ftData,
        //   labels: ftLabels
        // }
      });
    } catch (err) {
      console.log('apiGetAverages - error: ');
      console.log(err);
    }
  };

  apiGetByTime = async () => {
    try {
      const { puppeteer, reportEstimate, reportLocation, reportMonth, reportWeek } = this.state;
      const qName = getLocationInfo(reportLocation).qName ?? reportLocation; // use qName if location has one

      // const response = await axios.get(config.api.url + '/r1/traffic/rByTimeDayType', {
      const response = await axios.get(config.api.url + '/r1/traffic/rWeeklyByTimeDayType', {
        params: {
          // startDate: reportMonth.clone().format(),
          // endDate: reportMonth.clone().endOf('month').format(),
          startDate: reportWeek.clone().format(),
          endDate: reportWeek.clone().endOf('isoWeek').format(),
          location: [qName], // ['761 7th Ave']
          estimate: reportEstimate,
          // puppeteer: puppeteer
        }
      });

      const locRespObj = { dataArray: [response.data['loc'], response.data['grpA'], response.data['grpB']], nameArray: ['loc', 'grpA', 'grpB'] };
      const { byTimeChart, ...weeklyTimeDataObj } = await this.weeklyTimeData(locRespObj, reportWeek); // all returned data

      this.setState({
        // renderReport: true,
        renderTime: true,
        weeklyTimeData: weeklyTimeDataObj,
        byTimeReportAllData: byTimeChart,
      });
    } catch (err) {
      console.log('apiGetByTime - error: ');
      console.log(err);
    }
  };

  apiCreatePdf = async (e) => {
    e.preventDefault();
    try {
      const { reportClient, reportEstimate, reportLocation, reportMonth, reportWeek } = this.state;

      const response = await axios.get(config.api.url + '/r1/traffic/rPdf', {
        headers: {
          'Accept': 'application/pdf'
        },
        params: {
          url: config.dashboard.url + '/report2?puppeteer=true&client=' + reportClient + '&location=' + reportLocation + '&week=' + reportWeek.format() + '&estimate=' + reportEstimate,
        },
        responseType: 'arraybuffer' // https://stackoverflow.com/questions/60454048/how-does-axios-handle-blob-vs-arraybuffer-as-responsetype
      });

      const blob = new Blob([response.data], { type: 'application/pdf' });
      const file = URL.createObjectURL(blob); // https://developer.mozilla.org/en-US/docs/Web/API/File_API/Using_files_from_web_applications#using_object_urls
      const iframe = document.getElementById('viewpdf');
      if (iframe?.src) iframe.src = file;
      if (iframe?.style.display) iframe.style.display = '';

      // console.log('apiCreatePdf - file: ');
      // console.log(file);

      // URL.revokeObjectURL(file); // if revoke is called here user can not save pdf
    } catch (error) {
      console.log('apiCreatePdf error: ');
      console.log(error);
    }
  };

  render() {
    const { byDateReportData, byDayOfWeekReportData, byTimeReportAllData, locationsObject, renderAvgs, renderTime, reportLocation, reportTitle, reportSubTitle, reportWeek, weeklyAvgData, weeklyTimeData, puppeteer } = this.state;
    // const locInfo = (renderAvgs && renderTime) ? getLocationInfo(reportLocation) : {};
    const formattedHighlightDate = (moment.isMoment(reportWeek)) ? reportWeek.clone().format('MMMM Do, YYYY') : ''; // August 22nd, 2022
    const formattedDateHeader = (moment.isMoment(reportWeek)) ? 'Week of ' + reportWeek.clone().format('MMMM D, YYYY') + ' ( ' + reportWeek.clone().format('M/D/YY') + ' - ' + reportWeek.clone().endOf('isoWeek').format('M/D/YY') + ' )' : ''; // Week of August 22, 2022 ( 8/22/22 - 8/28/22 )

    // to render faster while laying out, remove (renderAvgs && renderTime)
    return (
      <Grid container style={{ backgroundColor: 'white', width: PAGEWIDTH, border: '', }} direction='column' justifyContent='flex-start' alignItems='center'>

        {(renderAvgs && renderTime) &&
          <Fragment>

            {/* PAGE 1 */}
            <Grid container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ border: '', backgroundColor: '', width: PAGEWIDTH * 0.938, height: PAGEHEIGHT * 0.9545, marginTop: PAGEWIDTH / 32 }}>

              {/* HEADER */}
              <Grid container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ width: PAGEWIDTH * 0.938, height: PAGEHEIGHT / 22, backgroundColor: '' }}>
                <Grid item container direction='row' justifyContent='flex-start' alignItems='flex-start' style={{ backgroundColor: '', width: '90%' }}>
                  <Grid item container style={{ backgroundColor: '', width: '100%' }}>
                    <Typography style={{ backgroundColor: '', marginTop: -7, fontWeight: 700, fontSize: 30, fontFamily: 'Rajdhani' }}>{reportTitle}</Typography>
                    <Typography style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', backgroundColor: '', marginLeft: 20, fontWeight: 700, fontSize: 14, fontFamily: 'Nunito Sans' }}>{reportSubTitle}</Typography>
                  </Grid>
                  <Typography style={{ backgroundColor: '', marginTop: -7, fontWeight: 500, fontSize: 18, fontFamily: 'Rajdhani' }}>{formattedDateHeader}</Typography>
                </Grid>
                <Grid item container direction='row' justifyContent='flex-end' alignItems='flex-start' style={{ height: '100%', backgroundColor: '', width: '10%' }}>
                  {/* <img style={{ height: '100%' }} alt='Client Logo' src={require('../layout/mcdonalds/mcdonaldsLogo.png')} /> */}
                  <img style={{ height: '100%' }} alt='Client Logo' src={require('../layout/' + this.props.auth.user.client + '/' + this.props.auth.user.client + 'Logo.png')} />
                </Grid>
              </Grid>

              {/* LOCATIONS HEADER */}
              <Grid item container direction='row' justifyContent='space-between' alignItems='flex-start' style={{ marginTop: PAGEHEIGHT / 50, width: '100%', backgroundColor: '' }}>
                <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ width: '32%', height: '100%', backgroundColor: '' }}>
                  <img style={{ width: '100%' }} alt='loc Logo' src={require('../layout/mcdonalds/Massachusetts_Ave.jpg')} />
                  <Grid item container direction='row' justifyContent='center' alignItems='center' style={{ width: '100%', height: PAGEHEIGHT / 30, marginTop: PAGEHEIGHT / 250, backgroundColor: alpha(locationsObject.colors[0], 0.6) }}>
                    <Typography style={{ backgroundColor: '', marginTop: 0, fontWeight: 700, fontSize: 16, fontFamily: 'Nunito Sans' }}>{reportLocation}</Typography>
                  </Grid>
                  <Grid item container direction='row' justifyContent='space-between' alignItems='flex-start' style={{ width: '100%', height: PAGEHEIGHT / 12, marginTop: PAGEHEIGHT / 250, backgroundColor: '' }}>
                    <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ width: '32%', height: '100%', paddingTop: 8, backgroundColor: '#efefefff' }}>
                      <Typography style={{ paddingLeft: 10, width: '100%', marginTop: -2, fontWeight: 700, fontSize: 20, fontFamily: 'Nunito Sans' }}>{weeklyAvgData.loc.avgsFormatted.dailyAvg}&thinsp;<small>{(weeklyAvgData.loc.avgs.dailyAvg >= 1000) ? 'K' : ''}</small></Typography>
                      <Typography style={{ paddingLeft: 10, width: '100%', marginTop: -3, fontWeight: 400, fontSize: 10, fontFamily: 'Nunito Sans' }}>Daily Avg</Typography>
                      <Typography style={{ paddingLeft: 10, width: '100%', marginTop: 1, fontWeight: 600, fontSize: 18, fontFamily: 'Nunito Sans', color: (weeklyAvgData.loc.perChange.dailyAvg >= 0) ? '#56993B' : '#BF3E37' }}>{(weeklyAvgData.loc.perChange.dailyAvg >= 0) ? '+' : ''}{(weeklyAvgData.loc.perChange.dailyAvg).toFixed(0)}<small>%</small></Typography>
                      <Typography style={{ paddingLeft: 10, width: '100%', marginTop: -5, fontWeight: 400, fontSize: 9, fontFamily: 'Nunito Sans' }}>vs. prev wk</Typography>
                    </Grid>
                    <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ width: '32%', height: '100%', paddingTop: 8, backgroundColor: '#efefefff' }}>
                      <Typography style={{ paddingLeft: 10, width: '100%', marginTop: -2, fontWeight: 700, fontSize: 20, fontFamily: 'Nunito Sans' }}>{weeklyAvgData.loc.avgsFormatted.wkdayAvg}&thinsp;<small>{(weeklyAvgData.loc.avgs.wkdayAvg >= 1000) ? 'K' : ''}</small></Typography>
                      <Typography style={{ paddingLeft: 10, width: '100%', marginTop: -3, fontWeight: 400, fontSize: 10, fontFamily: 'Nunito Sans' }}>Wkday Avg</Typography>
                      <Typography style={{ paddingLeft: 10, width: '100%', marginTop: 1, fontWeight: 600, fontSize: 18, fontFamily: 'Nunito Sans', color: (weeklyAvgData.loc.perChange.wkdayAvg >= 0) ? '#56993B' : '#BF3E37' }}>{(weeklyAvgData.loc.perChange.wkdayAvg >= 0) ? '+' : ''}{(weeklyAvgData.loc.perChange.wkdayAvg).toFixed(0)}<small>%</small></Typography>
                      <Typography style={{ paddingLeft: 10, width: '100%', marginTop: -5, fontWeight: 400, fontSize: 9, fontFamily: 'Nunito Sans' }}>vs. prev wk</Typography>
                    </Grid>
                    <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ width: '32%', height: '100%', paddingTop: 8, backgroundColor: '#efefefff' }}>
                      <Typography style={{ paddingLeft: 10, width: '100%', marginTop: -2, fontWeight: 700, fontSize: 20, fontFamily: 'Nunito Sans' }}>{weeklyAvgData.loc.avgsFormatted.wkendAvg}&thinsp;<small>{(weeklyAvgData.loc.avgs.wkendAvg >= 1000) ? 'K' : ''}</small></Typography>
                      <Typography style={{ paddingLeft: 10, width: '100%', marginTop: -3, fontWeight: 400, fontSize: 10, fontFamily: 'Nunito Sans' }}>Wkend Avg</Typography>
                      <Typography style={{ paddingLeft: 10, width: '100%', marginTop: 1, fontWeight: 600, fontSize: 18, fontFamily: 'Nunito Sans', color: (weeklyAvgData.loc.perChange.wkendAvg >= 0) ? '#56993B' : '#BF3E37' }}>{(weeklyAvgData.loc.perChange.wkendAvg >= 0) ? '+' : ''}{(weeklyAvgData.loc.perChange.wkendAvg).toFixed(0)}<small>%</small></Typography>
                      <Typography style={{ paddingLeft: 10, width: '100%', marginTop: -5, fontWeight: 400, fontSize: 9, fontFamily: 'Nunito Sans' }}>vs. prev wk</Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ width: '32%', height: '100%', backgroundColor: '' }}>
                  <img style={{ width: '100%' }} alt='loc2 Logo' src={require('../layout/mcdonalds/Edgerly_Rd.jpg')} />
                  <Grid item container direction='row' justifyContent='center' alignItems='center' style={{ width: '100%', height: PAGEHEIGHT / 30, marginTop: PAGEHEIGHT / 250, backgroundColor: alpha(locationsObject.COMBCOLORS[0], 0.6) }}>
                    <Typography style={{ backgroundColor: '', marginTop: 0, fontWeight: 700, fontSize: 16, fontFamily: 'Nunito Sans' }}>Edgerly Rd</Typography>
                  </Grid>
                  <Grid item container direction='row' justifyContent='space-between' alignItems='flex-start' style={{ width: '100%', height: PAGEHEIGHT / 12, marginTop: PAGEHEIGHT / 250, backgroundColor: '' }}>
                    <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ width: '32%', height: '100%', paddingTop: 8, backgroundColor: '#efefefff' }}>
                      <Typography style={{ paddingLeft: 10, width: '100%', marginTop: -2, fontWeight: 700, fontSize: 20, fontFamily: 'Nunito Sans' }}>{weeklyAvgData.grpA.avgsFormatted.dailyAvg}&thinsp;<small>{(weeklyAvgData.grpA.avgs.dailyAvg >= 1000) ? 'K' : ''}</small></Typography>
                      <Typography style={{ paddingLeft: 10, width: '100%', marginTop: -3, fontWeight: 400, fontSize: 10, fontFamily: 'Nunito Sans' }}>Daily Avg</Typography>
                      <Typography style={{ paddingLeft: 10, width: '100%', marginTop: 1, fontWeight: 600, fontSize: 18, fontFamily: 'Nunito Sans', color: (weeklyAvgData.grpA.perChange.dailyAvg >= 0) ? '#56993B' : '#BF3E37' }}>{(weeklyAvgData.grpA.perChange.dailyAvg >= 0) ? '+' : ''}{(weeklyAvgData.grpA.perChange.dailyAvg).toFixed(0)}<small>%</small></Typography>
                      <Typography style={{ paddingLeft: 10, width: '100%', marginTop: -5, fontWeight: 400, fontSize: 9, fontFamily: 'Nunito Sans' }}>vs. prev wk</Typography>
                    </Grid>
                    <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ width: '32%', height: '100%', paddingTop: 8, backgroundColor: '#efefefff' }}>
                      <Typography style={{ paddingLeft: 10, width: '100%', marginTop: -2, fontWeight: 700, fontSize: 20, fontFamily: 'Nunito Sans' }}>{weeklyAvgData.grpA.avgsFormatted.wkdayAvg}&thinsp;<small>{(weeklyAvgData.grpA.avgs.wkdayAvg >= 1000) ? 'K' : ''}</small></Typography>
                      <Typography style={{ paddingLeft: 10, width: '100%', marginTop: -3, fontWeight: 400, fontSize: 10, fontFamily: 'Nunito Sans' }}>Wkday Avg</Typography>
                      <Typography style={{ paddingLeft: 10, width: '100%', marginTop: 1, fontWeight: 600, fontSize: 18, fontFamily: 'Nunito Sans', color: (weeklyAvgData.grpA.perChange.wkdayAvg >= 0) ? '#56993B' : '#BF3E37' }}>{(weeklyAvgData.grpA.perChange.wkdayAvg >= 0) ? '+' : ''}{(weeklyAvgData.grpA.perChange.wkdayAvg).toFixed(0)}<small>%</small></Typography>
                      <Typography style={{ paddingLeft: 10, width: '100%', marginTop: -5, fontWeight: 400, fontSize: 9, fontFamily: 'Nunito Sans' }}>vs. prev wk</Typography>
                    </Grid>
                    <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ width: '32%', height: '100%', paddingTop: 8, backgroundColor: '#efefefff' }}>
                      <Typography style={{ paddingLeft: 10, width: '100%', marginTop: -2, fontWeight: 700, fontSize: 20, fontFamily: 'Nunito Sans' }}>{weeklyAvgData.grpA.avgsFormatted.wkendAvg}&thinsp;<small>{(weeklyAvgData.grpA.avgs.wkendAvg >= 1000) ? 'K' : ''}</small></Typography>
                      <Typography style={{ paddingLeft: 10, width: '100%', marginTop: -3, fontWeight: 400, fontSize: 10, fontFamily: 'Nunito Sans' }}>Wkend Avg</Typography>
                      <Typography style={{ paddingLeft: 10, width: '100%', marginTop: 1, fontWeight: 600, fontSize: 18, fontFamily: 'Nunito Sans', color: (weeklyAvgData.grpA.perChange.wkendAvg >= 0) ? '#56993B' : '#BF3E37' }}>{(weeklyAvgData.grpA.perChange.wkendAvg >= 0) ? '+' : ''}{(weeklyAvgData.grpA.perChange.wkendAvg).toFixed(0)}<small>%</small></Typography>
                      <Typography style={{ paddingLeft: 10, width: '100%', marginTop: -5, fontWeight: 400, fontSize: 9, fontFamily: 'Nunito Sans' }}>vs. prev wk</Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ width: '32%', height: '100%', backgroundColor: '' }}>
                  <img style={{ width: '100%' }} alt='loc3 Logo' src={require('../layout/mcdonalds/Edgerly_Rd_Whole_Foods_Entering.jpg')} />
                  <Grid item container direction='row' justifyContent='center' alignItems='center' style={{ width: '100%', height: PAGEHEIGHT / 30, marginTop: PAGEHEIGHT / 250, backgroundColor: alpha(locationsObject.COMBCOLORS[7], 0.6) }}>
                    <Typography style={{ backgroundColor: '', marginTop: 0, fontWeight: 700, fontSize: 13, fontFamily: 'Nunito Sans' }}>Edgerly Rd + Whole Foods Entering</Typography>
                  </Grid>
                  <Grid item container direction='row' justifyContent='space-between' alignItems='flex-start' style={{ width: '100%', height: PAGEHEIGHT / 12, marginTop: PAGEHEIGHT / 250, backgroundColor: '' }}>
                    <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ width: '32%', height: '100%', paddingTop: 8, backgroundColor: '#efefefff' }}>
                      <Typography style={{ paddingLeft: 10, width: '100%', marginTop: -2, fontWeight: 700, fontSize: 20, fontFamily: 'Nunito Sans' }}>{weeklyAvgData.grpB.avgsFormatted.dailyAvg}&thinsp;<small>{(weeklyAvgData.grpB.avgs.dailyAvg >= 1000) ? 'K' : ''}</small></Typography>
                      <Typography style={{ paddingLeft: 10, width: '100%', marginTop: -3, fontWeight: 400, fontSize: 10, fontFamily: 'Nunito Sans' }}>Daily Avg</Typography>
                      <Typography style={{ paddingLeft: 10, width: '100%', marginTop: 1, fontWeight: 600, fontSize: 18, fontFamily: 'Nunito Sans', color: (weeklyAvgData.grpB.perChange.dailyAvg >= 0) ? '#56993B' : '#BF3E37' }}>{(weeklyAvgData.grpB.perChange.dailyAvg >= 0) ? '+' : ''}{(weeklyAvgData.grpB.perChange.dailyAvg).toFixed(0)}<small>%</small></Typography>
                      <Typography style={{ paddingLeft: 10, width: '100%', marginTop: -5, fontWeight: 400, fontSize: 9, fontFamily: 'Nunito Sans' }}>vs. prev wk</Typography>
                    </Grid>
                    <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ width: '32%', height: '100%', paddingTop: 8, backgroundColor: '#efefefff' }}>
                      <Typography style={{ paddingLeft: 10, width: '100%', marginTop: -2, fontWeight: 700, fontSize: 20, fontFamily: 'Nunito Sans' }}>{weeklyAvgData.grpB.avgsFormatted.wkdayAvg}&thinsp;<small>{(weeklyAvgData.grpB.avgs.wkdayAvg >= 1000) ? 'K' : ''}</small></Typography>
                      <Typography style={{ paddingLeft: 10, width: '100%', marginTop: -3, fontWeight: 400, fontSize: 10, fontFamily: 'Nunito Sans' }}>Wkday Avg</Typography>
                      <Typography style={{ paddingLeft: 10, width: '100%', marginTop: 1, fontWeight: 600, fontSize: 18, fontFamily: 'Nunito Sans', color: (weeklyAvgData.grpB.perChange.wkdayAvg >= 0) ? '#56993B' : '#BF3E37' }}>{(weeklyAvgData.grpB.perChange.wkdayAvg >= 0) ? '+' : ''}{(weeklyAvgData.grpB.perChange.wkdayAvg).toFixed(0)}<small>%</small></Typography>
                      <Typography style={{ paddingLeft: 10, width: '100%', marginTop: -5, fontWeight: 400, fontSize: 9, fontFamily: 'Nunito Sans' }}>vs. prev wk</Typography>
                    </Grid>
                    <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ width: '32%', height: '100%', paddingTop: 8, backgroundColor: '#efefefff' }}>
                      <Typography style={{ paddingLeft: 10, width: '100%', marginTop: -2, fontWeight: 700, fontSize: 20, fontFamily: 'Nunito Sans' }}>{weeklyAvgData.grpB.avgsFormatted.wkendAvg}&thinsp;<small>{(weeklyAvgData.grpB.avgs.wkendAvg >= 1000) ? 'K' : ''}</small></Typography>
                      <Typography style={{ paddingLeft: 10, width: '100%', marginTop: -3, fontWeight: 400, fontSize: 10, fontFamily: 'Nunito Sans' }}>Wkend Avg</Typography>
                      <Typography style={{ paddingLeft: 10, width: '100%', marginTop: 1, fontWeight: 600, fontSize: 18, fontFamily: 'Nunito Sans', color: (weeklyAvgData.grpB.perChange.wkendAvg >= 0) ? '#56993B' : '#BF3E37' }}>{(weeklyAvgData.grpB.perChange.wkendAvg >= 0) ? '+' : ''}{(weeklyAvgData.grpB.perChange.wkendAvg).toFixed(0)}<small>%</small></Typography>
                      <Typography style={{ paddingLeft: 10, width: '100%', marginTop: -5, fontWeight: 400, fontSize: 9, fontFamily: 'Nunito Sans' }}>vs. prev wk</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {/* ROW 2 */}
              <Grid item container direction='row' justifyContent='space-between' alignItems='flex-start' style={{ border: '', marginTop: 5, height: PAGEHEIGHT / 5, width: '100%', backgroundColor: '' }}>
                <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start' style={{ width: '48%', height: '100%', backgroundColor: '' }}>
                  <Typography style={{ paddingLeft: 0, backgroundColor: '', fontWeight: 700, fontSize: 17, fontFamily: 'Nunito Sans' }}>Highlights</Typography>
                  <Typography style={{ paddingLeft: 0, backgroundColor: '', fontWeight: 400, fontSize: 14, fontFamily: 'Nunito Sans' }}>
                    During the week of {formattedHighlightDate}, {reportLocation} pedestrian traffic totalled {weeklyAvgData.loc.totalTraffic.toLocaleString()} people. Edgerly Rd pedestrian traffic (McDonald’s Sidewalk + Whole Foods Sidewalk) was <strong><em>{(((weeklyAvgData.grpA.totalTraffic - weeklyAvgData.loc.totalTraffic) / weeklyAvgData.loc.totalTraffic) * 100).toFixed(0)}%</em></strong> higher than Massachusetts Ave, totalling {weeklyAvgData.grpA.totalTraffic.toLocaleString()} people.
                  </Typography>
                  <Typography style={{ paddingLeft: 0, backgroundColor: '', fontWeight: 400, fontSize: 14, fontFamily: 'Nunito Sans' }}>
                    Edgerly Rd combined with Whole Foods Entering traffic was <strong><em>{(((weeklyAvgData.grpB.totalTraffic - weeklyAvgData.loc.totalTraffic) / weeklyAvgData.loc.totalTraffic) * 100).toFixed(0)}%</em></strong> higher than Massachusetts Ave and <strong><em>{(((weeklyAvgData.grpB.totalTraffic - weeklyAvgData.grpA.totalTraffic) / weeklyAvgData.grpA.totalTraffic) * 100).toFixed(0)}%</em></strong> higher than Edgerly Rd, totalling {weeklyAvgData.grpB.totalTraffic.toLocaleString()} people.
                  </Typography>
                </Grid>
                <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ border: '', width: '49%', height: '100%', backgroundColor: '' }}>
                  <Grid container justifyContent='flex-start' alignItems='flex-start' style={{ width: '100%' }}>
                    <ByTimeAllReport
                      locationsObject={locationsObject}
                      series={byTimeReportAllData} />
                  </Grid>
                </Grid>
              </Grid>

              {/* BY DATE ROW */}
              <Grid item container direction='row' justifyContent='space-between' alignItems='flex-start' style={{ border: '', marginTop: 0, height: PAGEHEIGHT / 2.9, width: '100%', backgroundColor: '' }}>
                <ByDateReport
                  locationsObject={locationsObject}
                  series={byDateReportData} />
              </Grid>

              {/* FOOTER */}
              <Grid container item direction='column' justifyContent='space-between' alignItems='flex-start' style={{ width: PAGEWIDTH * 0.938, height: PAGEHEIGHT * 0.05, backgroundColor: '' }}>
                <Grid container direction='row' justifyContent='space-between' alignItems='flex-start' style={{ width: PAGEWIDTH * 0.938, height: 1, backgroundColor: '' }}>
                </Grid>
                <Grid container direction='column' justifyContent='flex-end' alignItems='flex-start' style={{ width: PAGEWIDTH * 0.938, height: PAGEHEIGHT / 22, backgroundColor: '' }}>

                  <Grid item container direction='row' justifyContent='flex-start' alignItems='flex-start' style={{ backgroundColor: '', width: '50%' }}>
                    <Typography style={{ backgroundColor: '', marginTop: 0, marginBottom: -4, fontWeight: 700, fontSize: 18, fontFamily: 'Rajdhani' }}>Traffic Report</Typography>
                  </Grid>

                  <Grid item container direction='row' justifyContent='flex-end' alignItems='flex-start' style={{ height: '100%', backgroundColor: '', width: '50%' }}>
                    <img style={{ height: '100%' }} alt='Client Logo' src={require('../layout/exteros_blue.png')} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* PAGE 2  */}
            <Grid container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ border: '', backgroundColor: '', width: PAGEWIDTH * 0.938, height: PAGEHEIGHT * 0.9545, marginTop: PAGEWIDTH / 16 }}>

              {/* AVERAGES HEADER */}
              <Grid item container direction='row' justifyContent='space-between' alignItems='flex-start' style={{ width: PAGEWIDTH * 0.938, height: PAGEHEIGHT * 0.296, backgroundColor: '' }}>
                <Grid item container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ width: '53%', height: '100%', marginRight: '0%', backgroundColor: '' }}>
                  <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ width: '100%', height: PAGEHEIGHT / 18, backgroundColor: alpha(locationsObject.colors[0], 0.6) }}>
                    <Typography style={{ paddingLeft: 10, backgroundColor: '', marginTop: 5, fontWeight: 600, fontSize: 23, fontFamily: 'Rajdhani' }}>{reportLocation}</Typography>
                    <Typography style={{ paddingLeft: 10, backgroundColor: '', marginTop: -5, fontWeight: 400, fontSize: 13, fontFamily: 'Nunito Sans' }}>{formattedDateHeader}</Typography>
                  </Grid>
                  <Grid item container direction='row' justifyContent='space-between' alignItems='flex-start' style={{ marginTop: 0, height: PAGEHEIGHT / 10, width: '100%' }}>
                    <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ width: '32%', height: '100%', backgroundColor: '#efefefff' }}>
                      <Typography style={{ paddingLeft: 20, width: '100%', marginTop: 8, fontWeight: 700, fontSize: 24, fontFamily: 'Nunito Sans' }}>{weeklyAvgData.loc.avgsFormatted.dailyAvg}&thinsp;<small>{(weeklyAvgData.loc.avgs.dailyAvg >= 1000) ? 'K' : ''}</small></Typography>
                      <Typography style={{ paddingLeft: 20, width: '100%', marginTop: -8, fontWeight: 400, fontSize: 12, fontFamily: 'Nunito Sans' }}>Daily Avg</Typography>
                      <Typography style={{ paddingLeft: 20, width: '100%', marginTop: 2, fontWeight: 600, fontSize: 20, fontFamily: 'Nunito Sans', color: (weeklyAvgData.loc.perChange.dailyAvg >= 0) ? '#56993B' : '#BF3E37' }}>{(weeklyAvgData.loc.perChange.dailyAvg >= 0) ? '+' : ''}{(weeklyAvgData.loc.perChange.dailyAvg).toFixed(1)}<small>%</small></Typography>
                      <Typography style={{ paddingLeft: 20, width: '100%', marginTop: -5, fontWeight: 400, fontSize: 10, fontFamily: 'Nunito Sans' }}>vs. prev week</Typography>
                    </Grid>
                    <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ width: '32%', height: '100%', backgroundColor: '#efefefff' }}>
                      <Typography style={{ paddingLeft: 20, width: '100%', marginTop: 8, fontWeight: 700, fontSize: 24, fontFamily: 'Nunito Sans' }}>{weeklyAvgData.loc.avgsFormatted.wkdayAvg}&thinsp;<small>{(weeklyAvgData.loc.avgs.wkdayAvg >= 1000) ? 'K' : ''}</small></Typography>
                      <Typography style={{ paddingLeft: 20, width: '100%', marginTop: -8, fontWeight: 400, fontSize: 12, fontFamily: 'Nunito Sans' }}>Wkday Avg</Typography>
                      <Typography style={{ paddingLeft: 20, width: '100%', marginTop: 2, fontWeight: 600, fontSize: 20, fontFamily: 'Nunito Sans', color: (weeklyAvgData.loc.perChange.wkdayAvg >= 0) ? '#56993B' : '#BF3E37' }}>{(weeklyAvgData.loc.perChange.wkdayAvg >= 0) ? '+' : ''}{(weeklyAvgData.loc.perChange.wkdayAvg).toFixed(1)}<small>%</small></Typography>
                      <Typography style={{ paddingLeft: 20, width: '100%', marginTop: -5, fontWeight: 400, fontSize: 10, fontFamily: 'Nunito Sans' }}>vs. prev week</Typography>
                    </Grid>
                    <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ width: '32%', height: '100%', backgroundColor: '#efefefff' }}>
                      <Typography style={{ paddingLeft: 20, width: '100%', marginTop: 8, fontWeight: 700, fontSize: 24, fontFamily: 'Nunito Sans' }}>{weeklyAvgData.loc.avgsFormatted.wkendAvg}&thinsp;<small>{(weeklyAvgData.loc.avgs.wkendAvg >= 1000) ? 'K' : ''}</small></Typography>
                      <Typography style={{ paddingLeft: 20, width: '100%', marginTop: -8, fontWeight: 400, fontSize: 12, fontFamily: 'Nunito Sans' }}>Wkend Avg</Typography>
                      <Typography style={{ paddingLeft: 20, width: '100%', marginTop: 2, fontWeight: 600, fontSize: 20, fontFamily: 'Nunito Sans', color: (weeklyAvgData.loc.perChange.wkendAvg >= 0) ? '#56993B' : '#BF3E37' }}>{(weeklyAvgData.loc.perChange.wkendAvg >= 0) ? '+' : ''}{(weeklyAvgData.loc.perChange.wkendAvg).toFixed(1)}<small>%</small></Typography>
                      <Typography style={{ paddingLeft: 20, width: '100%', marginTop: -5, fontWeight: 400, fontSize: 10, fontFamily: 'Nunito Sans' }}>vs. prev week</Typography>
                    </Grid>
                  </Grid>
                  <Grid item container direction='row' justifyContent='space-between' alignItems='flex-start' style={{ marginTop: 0, height: PAGEHEIGHT / 16, width: '100%' }}>
                    <Grid container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ width: '49%', height: '100%', backgroundColor: '#efefefff' }}>
                      <Typography style={{ paddingLeft: 20, marginTop: 8, fontWeight: 700, fontSize: 24, fontFamily: 'Nunito Sans' }}>{weeklyTimeData.loc.busiestHr.hr}&thinsp;<small>{weeklyTimeData.loc.busiestHr.suffix}</small></Typography>
                      <Typography style={{ paddingLeft: 20, marginTop: -6, fontWeight: 400, fontSize: 12, fontFamily: 'Nunito Sans' }}>Busiest Hour</Typography>
                    </Grid>
                    <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ width: '49%', height: '100%', backgroundColor: '#efefefff' }}>
                      <Typography style={{ paddingLeft: 20, marginTop: 8, fontWeight: 700, fontSize: 24, fontFamily: 'Nunito Sans' }}>{weeklyAvgData.loc.busiestDay}</Typography>
                      <Typography style={{ paddingLeft: 20, marginTop: -6, fontWeight: 400, fontSize: 12, fontFamily: 'Nunito Sans' }}>Busiest Day</Typography>
                    </Grid>
                  </Grid>
                  <Grid item container direction='row' justifyContent='space-between' alignItems='flex-start' style={{ backgroundColor: '', marginTop: 5, marginBottom: 5, width: '100%', height: PAGEHEIGHT / 20 }}>
                    <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ backgroundColor: '', marginTop: 0, width: '49%', height: PAGEHEIGHT / 20 }}>
                      <MonthlyAveragePie
                        age={false} gender={true}
                        series={[{ data: [{ genderPie: weeklyAvgData.loc.genderPie }] }]} />
                    </Grid>
                    <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ marginTop: 0, width: '49%', height: PAGEHEIGHT / 20 }}>
                      <MonthlyAveragePie
                        age={true} gender={false}
                        series={[{ data: [{ agePie: weeklyAvgData.loc.agePie }] }]} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item container direction='row' justifyContent='flex-start' alignItems='flex-start' style={{ width: '45%', height: PAGEHEIGHT / 3.17, backgroundColor: '', marginLeft: '1%' }}>
                  <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start' style={{ border: '', width: '100%' }}>
                    {/* <img style={{ width: '99.2%' }} alt='Client Logo' src={require('../layout/' + locInfo?.client + '/' + reportLocation.split(' ').join('_').split('\'').join('_') + '_Square.jpg')} /> */}
                    <img style={{ width: '99.2%' }} alt='Client Logo' src={require('../layout/mcdonalds/Massachusetts_Ave_Square.jpg')} />
                  </Grid>
                </Grid>
              </Grid>

              {/* GRAPHS */}
              <Grid container item direction='row' justifyContent='space-between' alignItems='flex-start' style={{ border: '', width: PAGEWIDTH * 0.938, height: PAGEHEIGHT * 0.56, backgroundColor: '' }}>
                <Grid container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ border: '', width: '53%', height: PAGEHEIGHT * 0.56, backgroundColor: '' }}>

                  <Grid container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ border: '', width: '100%', height: '100%', backgroundColor: '' }}>
                    <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ fontWeight: 700, fontSize: 17, fontFamily: 'Nunito Sans', width: '100%' }}>
                      Avg. Daily Traffic: Time by Day of Week
                    </Grid>
                    <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ backgroundColor: '' }}>
                      <ByDayHourlyTable
                        remDays={[]}
                        series={[weeklyTimeData.loc.byDayHourly]} />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ border: '', width: '45%', height: PAGEHEIGHT * 0.56, backgroundColor: '' }}>
                  <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start' style={{ border: '', width: '100%', height: PAGEHEIGHT / 6.7, backgroundColor: '' }}>
                    <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ fontWeight: 700, fontSize: 17, fontFamily: 'Nunito Sans', width: '100%' }}>
                      Traffic By Day of Week
                    </Grid>
                    <ByDayOfWeekReport
                      locationsObject={locationsObject}
                      series={[byDayOfWeekReportData[0]]} />
                  </Grid>
                  <Grid container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ border: '', width: '100%', height: PAGEHEIGHT / 2.53, backgroundColor: '' }}>
                    <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ backgroundColor: '', fontWeight: 700, fontSize: 17, fontFamily: 'Nunito Sans', width: '100%' }}>
                      Demographics By Time of Day
                    </Grid>
                    <Grid item container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ width: '100%', height: PAGEHEIGHT / 2.75, marginTop: 0, marginBottom: 0, backgroundColor: '' }}>
                      <Grid item container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ width: '100%', height: PAGEHEIGHT / 5.65, marginTop: 0, marginBottom: 0, backgroundColor: '' }}>
                        <Grid item container direction='row' justifyContent='center' alignItems='flex-start' style={{ backgroundColor: '#efefefff', color: '', paddingTop: 3, paddingBottom: 2, fontWeight: 700, marginTop: 0, fontSize: 13, fontFamily: 'Nunito Sans', width: '100%' }}>
                          Gender
                        </Grid>
                        <Grid item container direction='row' justifyContent='center' alignItems='center' style={{ width: '100%', marginTop: 0, marginBottom: 0 }}>
                          <Grid style={{ marginRight: 4, border: '', width: 10, height: 10, backgroundColor: '#6089c4' }}> </Grid>
                          <Typography style={{ fontWeight: 600, fontSize: 12, fontFamily: 'Nunito Sans' }} > Male</Typography>
                          <Grid style={{ marginLeft: 40, marginRight: 4, border: '', width: 10, height: 10, backgroundColor: '#d77da5' }}> </Grid>
                          <Typography style={{ fontWeight: 600, fontSize: 12, fontFamily: 'Nunito Sans' }}> Female</Typography>
                        </Grid>
                        <Grid item container direction='row' justifyContent='flex-start' alignItems='flex-start' style={{ border: '', width: '100%', height: PAGEHEIGHT / 7.8, backgroundColor: '' }}>
                          <ByTimeReport
                            age={false} gender={true}
                            series={[weeklyTimeData.loc.byTimeSplit]} />
                        </Grid>
                      </Grid>
                      <Grid item container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ width: '100%', height: PAGEHEIGHT / 5.65, marginTop: 0, marginBottom: 0, backgroundColor: '' }}>
                        <Grid item container direction='row' justifyContent='center' alignItems='flex-start' style={{ backgroundColor: '#efefefff', color: '', paddingTop: 3, paddingBottom: 2, fontWeight: 700, marginTop: 0, fontSize: 13, fontFamily: 'Nunito Sans', width: '100%' }}>
                          Age
                        </Grid>
                        <Grid item container direction='row' justifyContent='center' alignItems='center' style={{ width: '100%', marginTop: 0, marginBottom: 0, backgroundColor: '', }}>
                          <Grid style={{ marginRight: 4, border: '', width: 10, height: 10, backgroundColor: '#7ea84e' }}> </Grid>
                          <Typography style={{ fontWeight: 600, fontSize: 12, fontFamily: 'Nunito Sans' }} > Children</Typography>
                          <Grid style={{ marginLeft: 40, marginRight: 4, border: '', width: 10, height: 10, backgroundColor: '#643d23' }}> </Grid>
                          <Typography style={{ fontWeight: 600, fontSize: 12, fontFamily: 'Nunito Sans' }}> Seniors</Typography>
                        </Grid>
                        <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start' style={{ border: '', width: '100%', height: PAGEHEIGHT / 7.8, backgroundColor: '' }}>
                          <ByTimeReport
                            age={true} gender={false}
                            series={[weeklyTimeData.loc.byTimeSplit]} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {/* FOOTER */}
              <Grid container item direction='column' justifyContent='space-between' alignItems='flex-start' style={{ width: PAGEWIDTH * 0.938, height: PAGEHEIGHT * 0.05, backgroundColor: '' }}>
                <Grid container direction='row' justifyContent='space-between' alignItems='flex-start' style={{ width: PAGEWIDTH * 0.938, height: 1, backgroundColor: '' }}>
                </Grid>
                <Grid container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ width: PAGEWIDTH * 0.938, height: PAGEHEIGHT / 22, backgroundColor: '' }}>

                  <Grid item container direction='row' justifyContent='flex-start' alignItems='flex-start' style={{ backgroundColor: '', width: '50%' }}>
                    <Typography style={{ backgroundColor: '', marginTop: -7, fontWeight: 700, fontSize: 30, fontFamily: 'Rajdhani' }}>{reportTitle}</Typography>
                    <Typography style={{ backgroundColor: '', marginTop: -9, fontWeight: 700, fontSize: 18, fontFamily: 'Rajdhani' }}>Traffic Report</Typography>
                  </Grid>

                  <Grid item container direction='row' justifyContent='flex-end' alignItems='flex-start' style={{ height: '100%', backgroundColor: '', width: '50%' }}>
                    <img style={{ height: '100%' }} alt='Client Logo' src={require('../layout/exteros_blue.png')} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* PAGE 3  */}
            <Grid container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ border: '', backgroundColor: '', width: PAGEWIDTH * 0.938, height: PAGEHEIGHT * 0.9545, marginTop: PAGEWIDTH / 16 }}>

              {/* AVERAGES HEADER */}
              <Grid item container direction='row' justifyContent='space-between' alignItems='flex-start' style={{ width: PAGEWIDTH * 0.938, height: PAGEHEIGHT * 0.296, backgroundColor: '' }}>
                <Grid item container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ width: '53%', height: '100%', marginRight: '0%' }}>
                  <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ width: '100%', height: PAGEHEIGHT / 18, backgroundColor: alpha(locationsObject.COMBCOLORS[0], 0.6) }}>
                    <Typography style={{ paddingLeft: 10, backgroundColor: '', marginTop: 5, fontWeight: 600, fontSize: 23, fontFamily: 'Rajdhani' }}>Edgerly Rd</Typography>
                    <Typography style={{ paddingLeft: 10, backgroundColor: '', marginTop: -5, fontWeight: 400, fontSize: 13, fontFamily: 'Nunito Sans' }}>{formattedDateHeader}</Typography>
                  </Grid>
                  <Grid item container direction='row' justifyContent='space-between' alignItems='flex-start' style={{ marginTop: 0, height: PAGEHEIGHT / 10, width: '100%' }}>
                    <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ width: '32%', height: '100%', backgroundColor: '#efefefff' }}>
                      <Typography style={{ paddingLeft: 20, width: '100%', marginTop: 8, fontWeight: 700, fontSize: 24, fontFamily: 'Nunito Sans' }}>{weeklyAvgData.grpA.avgsFormatted.dailyAvg.toFixed(1)}&thinsp;<small>{(weeklyAvgData.grpA.avgs.dailyAvg >= 1000) ? 'K' : ''}</small></Typography>
                      <Typography style={{ paddingLeft: 20, width: '100%', marginTop: -8, fontWeight: 400, fontSize: 12, fontFamily: 'Nunito Sans' }}>Daily Avg</Typography>
                      <Typography style={{ paddingLeft: 20, width: '100%', marginTop: 2, fontWeight: 600, fontSize: 20, fontFamily: 'Nunito Sans', color: (weeklyAvgData.grpA.perChange.dailyAvg >= 0) ? '#56993B' : '#BF3E37' }}>{(weeklyAvgData.grpA.perChange.dailyAvg >= 0) ? '+' : ''}{(weeklyAvgData.grpA.perChange.dailyAvg).toFixed(1)}<small>%</small></Typography>
                      <Typography style={{ paddingLeft: 20, width: '100%', marginTop: -5, fontWeight: 400, fontSize: 10, fontFamily: 'Nunito Sans' }}>vs. prev week</Typography>
                    </Grid>
                    <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ width: '32%', height: '100%', backgroundColor: '#efefefff' }}>
                      <Typography style={{ paddingLeft: 20, width: '100%', marginTop: 8, fontWeight: 700, fontSize: 24, fontFamily: 'Nunito Sans' }}>{weeklyAvgData.grpA.avgsFormatted.wkdayAvg.toFixed(1)}&thinsp;<small>{(weeklyAvgData.grpA.avgs.wkdayAvg >= 1000) ? 'K' : ''}</small></Typography>
                      <Typography style={{ paddingLeft: 20, width: '100%', marginTop: -8, fontWeight: 400, fontSize: 12, fontFamily: 'Nunito Sans' }}>Wkday Avg</Typography>
                      <Typography style={{ paddingLeft: 20, width: '100%', marginTop: 2, fontWeight: 600, fontSize: 20, fontFamily: 'Nunito Sans', color: (weeklyAvgData.grpA.perChange.wkdayAvg >= 0) ? '#56993B' : '#BF3E37' }}>{(weeklyAvgData.grpA.perChange.wkdayAvg >= 0) ? '+' : ''}{(weeklyAvgData.grpA.perChange.wkdayAvg).toFixed(1)}<small>%</small></Typography>
                      <Typography style={{ paddingLeft: 20, width: '100%', marginTop: -5, fontWeight: 400, fontSize: 10, fontFamily: 'Nunito Sans' }}>vs. prev week</Typography>
                    </Grid>
                    <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ width: '32%', height: '100%', backgroundColor: '#efefefff' }}>
                      <Typography style={{ paddingLeft: 20, width: '100%', marginTop: 8, fontWeight: 700, fontSize: 24, fontFamily: 'Nunito Sans' }}>{weeklyAvgData.grpA.avgsFormatted.wkendAvg.toFixed(1)}&thinsp;<small>{(weeklyAvgData.grpA.avgs.wkendAvg >= 1000) ? 'K' : ''}</small></Typography>
                      <Typography style={{ paddingLeft: 20, width: '100%', marginTop: -8, fontWeight: 400, fontSize: 12, fontFamily: 'Nunito Sans' }}>Wkend Avg</Typography>
                      <Typography style={{ paddingLeft: 20, width: '100%', marginTop: 2, fontWeight: 600, fontSize: 20, fontFamily: 'Nunito Sans', color: (weeklyAvgData.grpA.perChange.wkendAvg >= 0) ? '#56993B' : '#BF3E37' }}>{(weeklyAvgData.grpA.perChange.wkendAvg >= 0) ? '+' : ''}{(weeklyAvgData.grpA.perChange.wkendAvg).toFixed(1)}<small>%</small></Typography>
                      <Typography style={{ paddingLeft: 20, width: '100%', marginTop: -5, fontWeight: 400, fontSize: 10, fontFamily: 'Nunito Sans' }}>vs. prev week</Typography>
                    </Grid>
                  </Grid>
                  <Grid item container direction='row' justifyContent='space-between' alignItems='flex-start' style={{ marginTop: 0, height: PAGEHEIGHT / 16, width: '100%' }}>
                    <Grid container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ width: '49%', height: '100%', backgroundColor: '#efefefff' }}>
                      <Typography style={{ paddingLeft: 20, marginTop: 8, fontWeight: 700, fontSize: 24, fontFamily: 'Nunito Sans' }}>{weeklyTimeData.grpA.busiestHr.hr}&thinsp;<small>{weeklyTimeData.grpA.busiestHr.suffix}</small></Typography>
                      <Typography style={{ paddingLeft: 20, marginTop: -6, fontWeight: 400, fontSize: 12, fontFamily: 'Nunito Sans' }}>Busiest Hour</Typography>
                    </Grid>
                    <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ width: '49%', height: '100%', backgroundColor: '#efefefff' }}>
                      <Typography style={{ paddingLeft: 20, marginTop: 8, fontWeight: 700, fontSize: 24, fontFamily: 'Nunito Sans' }}>{weeklyAvgData.grpA.busiestDay}</Typography>
                      <Typography style={{ paddingLeft: 20, marginTop: -6, fontWeight: 400, fontSize: 12, fontFamily: 'Nunito Sans' }}>Busiest Day</Typography>
                    </Grid>
                  </Grid>
                  <Grid item container direction='row' justifyContent='space-between' alignItems='flex-start' style={{ backgroundColor: '', marginTop: 5, marginBottom: 5, width: '100%', height: PAGEHEIGHT / 20 }}>
                    <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ backgroundColor: '', marginTop: 0, width: '49%', height: PAGEHEIGHT / 20 }}>
                      <MonthlyAveragePie
                        age={false} gender={true}
                        series={[{ data: [{ genderPie: weeklyAvgData.grpA.genderPie }] }]} />
                    </Grid>
                    <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ marginTop: 0, width: '49%', height: PAGEHEIGHT / 20 }}>
                      <MonthlyAveragePie
                        age={true} gender={false}
                        series={[{ data: [{ agePie: weeklyAvgData.grpA.agePie }] }]} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item container direction='row' justifyContent='flex-start' alignItems='flex-start' style={{ width: '45%', height: PAGEHEIGHT / 3.17, backgroundColor: '', marginLeft: '1%' }}>
                  <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start' style={{ border: '', width: '100%' }}>
                    {/* <img style={{width:'99.2%'}} alt='Client Logo' src={require('../layout/' + locInfo?.client + '/' + reportLocation.split(' ').join('_').split('\'').join('_')  + '_Square.jpg')}  /> */}
                    <img style={{ width: '99.2%' }} alt='Client Logo' src={require('../layout/mcdonalds/Edgerly_Rd_Square.jpg')} />
                  </Grid>
                </Grid>
              </Grid>

              {/* GRAPHS */}
              <Grid container item direction='row' justifyContent='space-between' alignItems='flex-start' style={{ border: '', width: PAGEWIDTH * 0.938, height: PAGEHEIGHT * 0.56, backgroundColor: '' }}>
                <Grid container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ border: '', width: '53%', height: PAGEHEIGHT * 0.56, backgroundColor: '' }}>

                  <Grid container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ border: '', width: '100%', height: '100%', backgroundColor: '' }}>
                    <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ fontWeight: 700, fontSize: 17, fontFamily: 'Nunito Sans', width: '100%' }}>
                      Avg. Daily Traffic: Time by Day of Week
                    </Grid>
                    <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ backgroundColor: '' }}>
                      <ByDayHourlyTable
                        remDays={[]}
                        series={[weeklyTimeData.grpA.byDayHourly]} />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ border: '', width: '45%', height: PAGEHEIGHT * 0.56, backgroundColor: '' }}>
                  <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start' style={{ border: '', width: '100%', height: PAGEHEIGHT / 6.7, backgroundColor: '' }}>
                    <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ fontWeight: 700, fontSize: 17, fontFamily: 'Nunito Sans', width: '100%' }}>
                      Traffic By Day of Week
                    </Grid>
                    <ByDayOfWeekReport
                      locationsObject={locationsObject}
                      series={[byDayOfWeekReportData[1]]} />
                  </Grid>
                  <Grid container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ border: '', width: '100%', height: PAGEHEIGHT / 2.53, backgroundColor: '' }}>
                    <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ backgroundColor: '', fontWeight: 700, fontSize: 17, fontFamily: 'Nunito Sans', width: '100%' }}>
                      Demographics By Time of Day
                    </Grid>
                    <Grid item container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ width: '100%', height: PAGEHEIGHT / 2.75, marginTop: 0, marginBottom: 0, backgroundColor: '' }}>
                      <Grid item container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ width: '100%', height: PAGEHEIGHT / 5.65, marginTop: 0, marginBottom: 0, backgroundColor: '' }}>
                        <Grid item container direction='row' justifyContent='center' alignItems='flex-start' style={{ backgroundColor: '#efefefff', color: '', paddingTop: 3, paddingBottom: 2, fontWeight: 700, marginTop: 0, fontSize: 13, fontFamily: 'Nunito Sans', width: '100%' }}>
                          Gender
                        </Grid>
                        <Grid item container direction='row' justifyContent='center' alignItems='center' style={{ width: '100%', marginTop: 0, marginBottom: 0 }}>
                          <Grid style={{ marginRight: 4, border: '', width: 10, height: 10, backgroundColor: '#6089c4' }}> </Grid>
                          <Typography style={{ fontWeight: 600, fontSize: 12, fontFamily: 'Nunito Sans' }} > Male</Typography>
                          <Grid style={{ marginLeft: 40, marginRight: 4, border: '', width: 10, height: 10, backgroundColor: '#d77da5' }}> </Grid>
                          <Typography style={{ fontWeight: 600, fontSize: 12, fontFamily: 'Nunito Sans' }}> Female</Typography>
                        </Grid>
                        <Grid item container direction='row' justifyContent='flex-start' alignItems='flex-start' style={{ border: '', width: '100%', height: PAGEHEIGHT / 7.8, backgroundColor: '' }}>
                          <ByTimeReport
                            age={false} gender={true}
                            series={[weeklyTimeData.grpA.byTimeSplit]} />
                        </Grid>
                      </Grid>
                      <Grid item container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ width: '100%', height: PAGEHEIGHT / 5.65, marginTop: 0, marginBottom: 0, backgroundColor: '' }}>
                        <Grid item container direction='row' justifyContent='center' alignItems='flex-start' style={{ backgroundColor: '#efefefff', color: '', paddingTop: 3, paddingBottom: 2, fontWeight: 700, marginTop: 0, fontSize: 13, fontFamily: 'Nunito Sans', width: '100%' }}>
                          Age
                        </Grid>
                        <Grid item container direction='row' justifyContent='center' alignItems='center' style={{ width: '100%', marginTop: 0, marginBottom: 0, backgroundColor: '', }}>
                          <Grid style={{ marginRight: 4, border: '', width: 10, height: 10, backgroundColor: '#7ea84e' }}> </Grid>
                          <Typography style={{ fontWeight: 600, fontSize: 12, fontFamily: 'Nunito Sans' }} > Children</Typography>
                          <Grid style={{ marginLeft: 40, marginRight: 4, border: '', width: 10, height: 10, backgroundColor: '#643d23' }}> </Grid>
                          <Typography style={{ fontWeight: 600, fontSize: 12, fontFamily: 'Nunito Sans' }}> Seniors</Typography>
                        </Grid>
                        <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start' style={{ border: '', width: '100%', height: PAGEHEIGHT / 7.8, backgroundColor: '' }}>
                          <ByTimeReport
                            age={true} gender={false}
                            series={[weeklyTimeData.grpA.byTimeSplit]} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {/* FOOTER */}
              <Grid container item direction='column' justifyContent='space-between' alignItems='flex-start' style={{ width: PAGEWIDTH * 0.938, height: PAGEHEIGHT * 0.05, backgroundColor: '' }}>
                <Grid container direction='row' justifyContent='space-between' alignItems='flex-start' style={{ width: PAGEWIDTH * 0.938, height: 1, backgroundColor: '' }}>
                </Grid>
                <Grid container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ width: PAGEWIDTH * 0.938, height: PAGEHEIGHT / 22, backgroundColor: '' }}>

                  <Grid item container direction='row' justifyContent='flex-start' alignItems='flex-start' style={{ backgroundColor: '', width: '50%' }}>
                    <Typography style={{ backgroundColor: '', marginTop: -7, fontWeight: 700, fontSize: 30, fontFamily: 'Rajdhani' }}>{reportTitle}</Typography>
                    <Typography style={{ backgroundColor: '', marginTop: -9, fontWeight: 700, fontSize: 18, fontFamily: 'Rajdhani' }}>Traffic Report</Typography>
                  </Grid>

                  <Grid item container direction='row' justifyContent='flex-end' alignItems='flex-start' style={{ height: '100%', backgroundColor: '', width: '50%' }}>
                    <img style={{ height: '100%' }} alt='Client Logo' src={require('../layout/exteros_blue.png')} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* PAGE 4  */}
            <Grid container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ border: '', backgroundColor: '', width: PAGEWIDTH * 0.938, height: PAGEHEIGHT * 0.9545, marginTop: PAGEWIDTH / 16 }}>

              {/* AVERAGES HEADER */}
              <Grid item container direction='row' justifyContent='space-between' alignItems='flex-start' style={{ width: PAGEWIDTH * 0.938, height: PAGEHEIGHT * 0.296, backgroundColor: '' }}>
                <Grid item container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ width: '53%', height: '100%', marginRight: '0%' }}>
                  <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ width: '100%', height: PAGEHEIGHT / 18, backgroundColor: alpha(locationsObject.COMBCOLORS[7], 0.6) }}>
                    <Typography style={{ paddingLeft: 10, marginTop: 7, fontWeight: 600, fontSize: 21, fontFamily: 'Rajdhani' }}>Edgerly Rd + Whole Foods Entering</Typography>
                    <Typography style={{ paddingLeft: 10, marginTop: -5, fontWeight: 400, fontSize: 13, fontFamily: 'Nunito Sans' }}>{formattedDateHeader}</Typography>
                  </Grid>
                  <Grid item container direction='row' justifyContent='space-between' alignItems='flex-start' style={{ marginTop: 0, height: PAGEHEIGHT / 10, width: '100%' }}>
                    <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ width: '32%', height: '100%', backgroundColor: '#efefefff' }}>
                      <Typography style={{ paddingLeft: 20, width: '100%', marginTop: 8, fontWeight: 700, fontSize: 24, fontFamily: 'Nunito Sans' }}>{weeklyAvgData.grpB.avgsFormatted.dailyAvg.toFixed(1)}&thinsp;<small>{(weeklyAvgData.grpB.avgs.dailyAvg >= 1000) ? 'K' : ''}</small></Typography>
                      <Typography style={{ paddingLeft: 20, width: '100%', marginTop: -8, fontWeight: 400, fontSize: 12, fontFamily: 'Nunito Sans' }}>Daily Avg</Typography>
                      <Typography style={{ paddingLeft: 20, width: '100%', marginTop: 2, fontWeight: 600, fontSize: 20, fontFamily: 'Nunito Sans', color: (weeklyAvgData.grpB.perChange.dailyAvg >= 0) ? '#56993B' : '#BF3E37' }}>{(weeklyAvgData.grpB.perChange.dailyAvg >= 0) ? '+' : ''}{(weeklyAvgData.grpB.perChange.dailyAvg).toFixed(1)}<small>%</small></Typography>
                      <Typography style={{ paddingLeft: 20, width: '100%', marginTop: -5, fontWeight: 400, fontSize: 10, fontFamily: 'Nunito Sans' }}>vs. prev week</Typography>
                    </Grid>
                    <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ width: '32%', height: '100%', backgroundColor: '#efefefff' }}>
                      <Typography style={{ paddingLeft: 20, width: '100%', marginTop: 8, fontWeight: 700, fontSize: 24, fontFamily: 'Nunito Sans' }}>{weeklyAvgData.grpB.avgsFormatted.wkdayAvg.toFixed(1)}&thinsp;<small>{(weeklyAvgData.grpB.avgs.wkdayAvg >= 1000) ? 'K' : ''}</small></Typography>
                      <Typography style={{ paddingLeft: 20, width: '100%', marginTop: -8, fontWeight: 400, fontSize: 12, fontFamily: 'Nunito Sans' }}>Wkday Avg</Typography>
                      <Typography style={{ paddingLeft: 20, width: '100%', marginTop: 2, fontWeight: 600, fontSize: 20, fontFamily: 'Nunito Sans', color: (weeklyAvgData.grpB.perChange.wkdayAvg >= 0) ? '#56993B' : '#BF3E37' }}>{(weeklyAvgData.grpB.perChange.wkdayAvg >= 0) ? '+' : ''}{(weeklyAvgData.grpB.perChange.wkdayAvg).toFixed(1)}<small>%</small></Typography>
                      <Typography style={{ paddingLeft: 20, width: '100%', marginTop: -5, fontWeight: 400, fontSize: 10, fontFamily: 'Nunito Sans' }}>vs. prev week</Typography>
                    </Grid>
                    <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ width: '32%', height: '100%', backgroundColor: '#efefefff' }}>
                      <Typography style={{ paddingLeft: 20, width: '100%', marginTop: 8, fontWeight: 700, fontSize: 24, fontFamily: 'Nunito Sans' }}>{weeklyAvgData.grpB.avgsFormatted.wkendAvg.toFixed(1)}&thinsp;<small>{(weeklyAvgData.grpB.avgs.wkendAvg >= 1000) ? 'K' : ''}</small></Typography>
                      <Typography style={{ paddingLeft: 20, width: '100%', marginTop: -8, fontWeight: 400, fontSize: 12, fontFamily: 'Nunito Sans' }}>Wkend Avg</Typography>
                      <Typography style={{ paddingLeft: 20, width: '100%', marginTop: 2, fontWeight: 600, fontSize: 20, fontFamily: 'Nunito Sans', color: (weeklyAvgData.grpB.perChange.wkendAvg >= 0) ? '#56993B' : '#BF3E37' }}>{(weeklyAvgData.grpB.perChange.wkendAvg >= 0) ? '+' : ''}{(weeklyAvgData.grpB.perChange.wkendAvg).toFixed(1)}<small>%</small></Typography>
                      <Typography style={{ paddingLeft: 20, width: '100%', marginTop: -5, fontWeight: 400, fontSize: 10, fontFamily: 'Nunito Sans' }}>vs. prev week</Typography>
                    </Grid>
                  </Grid>
                  <Grid item container direction='row' justifyContent='space-between' alignItems='flex-start' style={{ marginTop: 0, height: PAGEHEIGHT / 16, width: '100%' }}>
                    <Grid container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ width: '49%', height: '100%', backgroundColor: '#efefefff' }}>
                      <Typography style={{ paddingLeft: 20, marginTop: 8, fontWeight: 700, fontSize: 24, fontFamily: 'Nunito Sans' }}>{weeklyTimeData.grpB.busiestHr.hr}&thinsp;<small>{weeklyTimeData.grpB.busiestHr.suffix}</small></Typography>
                      <Typography style={{ paddingLeft: 20, marginTop: -6, fontWeight: 400, fontSize: 12, fontFamily: 'Nunito Sans' }}>Busiest Hour</Typography>
                    </Grid>
                    <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ width: '49%', height: '100%', backgroundColor: '#efefefff' }}>
                      <Typography style={{ paddingLeft: 20, marginTop: 8, fontWeight: 700, fontSize: 24, fontFamily: 'Nunito Sans' }}>{weeklyAvgData.grpB.busiestDay}</Typography>
                      <Typography style={{ paddingLeft: 20, marginTop: -6, fontWeight: 400, fontSize: 12, fontFamily: 'Nunito Sans' }}>Busiest Day</Typography>
                    </Grid>
                  </Grid>
                  <Grid item container direction='row' justifyContent='space-between' alignItems='flex-start' style={{ marginTop: 5, marginBottom: 5, width: '100%', height: PAGEHEIGHT / 20 }}>
                    <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ marginTop: 0, width: '49%', height: PAGEHEIGHT / 20 }}>
                      <MonthlyAveragePie
                        age={false} gender={true}
                        series={[{ data: [{ genderPie: weeklyAvgData.grpB.genderPie }] }]} />
                    </Grid>
                    <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ marginTop: 0, width: '49%', height: PAGEHEIGHT / 20 }}>
                      <MonthlyAveragePie
                        age={true} gender={false}
                        series={[{ data: [{ agePie: weeklyAvgData.grpB.agePie }] }]} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item container direction='row' justifyContent='flex-start' alignItems='flex-start' style={{ width: '45%', height: PAGEHEIGHT / 3.17, backgroundColor: '', marginLeft: '1%' }}>
                  <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start' style={{ border: '', width: '100%' }}>
                    {/* <img style={{width:'99.2%'}} alt='Client Logo' src={require('../layout/' + locInfo?.client + '/' + reportLocation.split(' ').join('_').split('\'').join('_')  + '_Square.jpg')}  /> */}
                    <img style={{ width: '99.2%' }} alt='Client Logo' src={require('../layout/mcdonalds/Edgerly_Rd_Whole_Foods_Entering_Square.jpg')} />
                  </Grid>
                </Grid>
              </Grid>

              {/* GRAPHS */}
              <Grid container item direction='row' justifyContent='space-between' alignItems='flex-start' style={{ border: '', width: PAGEWIDTH * 0.938, height: PAGEHEIGHT * 0.56, backgroundColor: '' }}>
                <Grid container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ border: '', width: '53%', height: PAGEHEIGHT * 0.56, backgroundColor: '' }}>

                  <Grid container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ border: '', width: '100%', height: '100%', backgroundColor: '' }}>
                    <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ fontWeight: 700, fontSize: 17, fontFamily: 'Nunito Sans', width: '100%' }}>
                      Avg. Daily Traffic: Time by Day of Week
                    </Grid>
                    <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ backgroundColor: '' }}>
                      <ByDayHourlyTable
                        remDays={[]}
                        series={[weeklyTimeData.grpB.byDayHourly]} />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ border: '', width: '45%', height: PAGEHEIGHT * 0.56, backgroundColor: '' }}>
                  <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start' style={{ border: '', width: '100%', height: PAGEHEIGHT / 6.7, backgroundColor: '' }}>
                    <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ fontWeight: 700, fontSize: 17, fontFamily: 'Nunito Sans', width: '100%' }}>
                      Traffic By Day of Week
                    </Grid>
                    <ByDayOfWeekReport
                      locationsObject={locationsObject}
                      series={[byDayOfWeekReportData[2]]} />
                  </Grid>
                  <Grid container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ border: '', width: '100%', height: PAGEHEIGHT / 2.53, backgroundColor: '' }}>
                    <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ backgroundColor: '', fontWeight: 700, fontSize: 17, fontFamily: 'Nunito Sans', width: '100%' }}>
                      Demographics By Time of Day
                    </Grid>
                    <Grid item container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ width: '100%', height: PAGEHEIGHT / 2.75, marginTop: 0, marginBottom: 0, backgroundColor: '' }}>
                      <Grid item container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ width: '100%', height: PAGEHEIGHT / 5.65, marginTop: 0, marginBottom: 0, backgroundColor: '' }}>
                        <Grid item container direction='row' justifyContent='center' alignItems='flex-start' style={{ backgroundColor: '#efefefff', color: '', paddingTop: 3, paddingBottom: 2, fontWeight: 700, marginTop: 0, fontSize: 13, fontFamily: 'Nunito Sans', width: '100%' }}>
                          Gender
                        </Grid>
                        <Grid item container direction='row' justifyContent='center' alignItems='center' style={{ width: '100%', marginTop: 0, marginBottom: 0 }}>
                          <Grid style={{ marginRight: 4, border: '', width: 10, height: 10, backgroundColor: '#6089c4' }}> </Grid>
                          <Typography style={{ fontWeight: 600, fontSize: 12, fontFamily: 'Nunito Sans' }} > Male</Typography>
                          <Grid style={{ marginLeft: 40, marginRight: 4, border: '', width: 10, height: 10, backgroundColor: '#d77da5' }}> </Grid>
                          <Typography style={{ fontWeight: 600, fontSize: 12, fontFamily: 'Nunito Sans' }}> Female</Typography>
                        </Grid>
                        <Grid item container direction='row' justifyContent='flex-start' alignItems='flex-start' style={{ border: '', width: '100%', height: PAGEHEIGHT / 7.8, backgroundColor: '' }}>
                          <ByTimeReport
                            age={false} gender={true}
                            series={[weeklyTimeData.grpB.byTimeSplit]} />
                        </Grid>
                      </Grid>
                      <Grid item container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ width: '100%', height: PAGEHEIGHT / 5.65, marginTop: 0, marginBottom: 0, backgroundColor: '' }}>
                        <Grid item container direction='row' justifyContent='center' alignItems='flex-start' style={{ backgroundColor: '#efefefff', color: '', paddingTop: 3, paddingBottom: 2, fontWeight: 700, marginTop: 0, fontSize: 13, fontFamily: 'Nunito Sans', width: '100%' }}>
                          Age
                        </Grid>
                        <Grid item container direction='row' justifyContent='center' alignItems='center' style={{ width: '100%', marginTop: 0, marginBottom: 0, backgroundColor: '', }}>
                          <Grid style={{ marginRight: 4, border: '', width: 10, height: 10, backgroundColor: '#7ea84e' }}> </Grid>
                          <Typography style={{ fontWeight: 600, fontSize: 12, fontFamily: 'Nunito Sans' }} > Children</Typography>
                          <Grid style={{ marginLeft: 40, marginRight: 4, border: '', width: 10, height: 10, backgroundColor: '#643d23' }}> </Grid>
                          <Typography style={{ fontWeight: 600, fontSize: 12, fontFamily: 'Nunito Sans' }}> Seniors</Typography>
                        </Grid>
                        <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start' style={{ border: '', width: '100%', height: PAGEHEIGHT / 7.8, backgroundColor: '' }}>
                          <ByTimeReport
                            age={true} gender={false}
                            series={[weeklyTimeData.grpB.byTimeSplit]} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {/* FOOTER */}
              <Grid container item direction='column' justifyContent='space-between' alignItems='flex-start' style={{ width: PAGEWIDTH * 0.938, height: PAGEHEIGHT * 0.05, backgroundColor: '' }}>
                <Grid container direction='row' justifyContent='space-between' alignItems='flex-start' style={{ width: PAGEWIDTH * 0.938, height: 1, backgroundColor: '' }}>
                </Grid>
                <Grid container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ width: PAGEWIDTH * 0.938, height: PAGEHEIGHT / 22, backgroundColor: '' }}>

                  <Grid item container direction='row' justifyContent='flex-start' alignItems='flex-start' style={{ backgroundColor: '', width: '50%' }}>
                    <Typography style={{ backgroundColor: '', marginTop: -7, fontWeight: 700, fontSize: 30, fontFamily: 'Rajdhani' }}>{reportTitle}</Typography>
                    <Typography style={{ backgroundColor: '', marginTop: -9, fontWeight: 700, fontSize: 18, fontFamily: 'Rajdhani' }}>Traffic Report</Typography>
                  </Grid>

                  <Grid item container direction='row' justifyContent='flex-end' alignItems='flex-start' style={{ height: '100%', backgroundColor: '', width: '50%' }}>
                    <img style={{ height: '100%' }} alt='Client Logo' src={require('../layout/exteros_blue.png')} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Fragment>
        }

        {(puppeteer !== 'true') &&
          <Fragment>
            <Button
              fullWidth
              variant='contained'
              color='primary'
              type='submit'
              style={{ borderRadius: '3px', letterSpacing: '1.5px', marginTop: '1000px' }}
              className='btn btn-large waves-effect waves-light hoverable accent-3'
              onClick={this.apiCreatePdf}
            >
              Create PDF
            </Button>

            <div style={{ width: '95%', height: 'auto', margin: '10px auto 0' }}>
              <iframe id='viewpdf' src='' title='PDF Report' width='100%' height='100%' style={{ display: 'none', height: '900px' }}></iframe>
            </div>
          </Fragment>
        }

        <div id='loaded' style={{ visibility: 'hidden' }}></div>
      </Grid>
    );
  }
}

{/* <p><strong>{reportLocation}</strong> {locInfo?.sensorCoverage?.split(':')[1]} <br /> <strong>{reportMonth.format('MMMM YYYY')}</strong></p>
<img style={{ height: 34}} alt='Client Logo' src={require('../layout/' + locInfo?.client + '/' + locInfo?.client + 'Logo.png')} />
                <ByDateReport
                  locationsObject={locationsObject}
                  series={[byDateReportData]} />

                <MonthlyChangeBar
                  series={[monthlyChangeData]} />

                <MonthlyAveragePie
                  age={false} gender={true}
                  series={[monthlyTrendsData]} />

                <StackedBarReport
                  age={false} count={true} gender={false}
                  series={[monthlyTrendsData]} />

                <StackedBarReport
                  age={false} count={false} gender={true}
                  series={[monthlyTrendsData]} />

                <StackedBarReport
                  age={true} count={false} gender={false}
                  series={[monthlyTrendsData]} />

                <ByTimeReport
                  age={false} gender={true}
                  series={[byTimeReportData]} />

                <ByTimeReport
                  age={true} gender={false}
                  series={[byTimeReportData]} /> */}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(withStyles(styles)(CreateMcDReport));
