import React, { PureComponent } from 'react';
import moment from 'moment';
import { Bar, ComposedChart, XAxis, YAxis } from 'recharts';
import { Grid } from '@material-ui/core';

class DayOfWeekBar extends PureComponent {

  render() {
    const { series } = this.props;

    let dataPoints = 0;
    try {
      for (let ser in series) {
        if (series[ser].data.length > dataPoints) {
          dataPoints = series[ser].data.length;
        }
      }
    } catch (err) { }

    return (
      <Grid item xs={12} style={{ backgroundColor: '' }}>

        {dataPoints > 0 &&
          <ComposedChart width={350} height={this.props.demo ? 145 : 190} barSize={30} style={{ backgroundColor: '' }} data={series[0].data} margin={{ top: 18, right: 20, left: -15, bottom: -10 }}>

            <XAxis
              allowDuplicatedCategory={false}
              dataKey='dayOfWeek'
              tickFormatter={d => moment().isoWeekday(d).format('ddd')}
              tickLine={true}
              style={{ fontSize: 12, fontWeight: 700, fontFamily: 'Nunito Sans', }}
              // label={{ value: 'Date', position: 'bottom',fontSize:12,fontWeight:600,fontFamily: 'Nunito Sans', dy: -8 }}
              type='category' />

            <YAxis
              domain={[0, 'auto']}
              hide={false}
              // label={{ value: 'Counts', angle: -90, position: 'left',fontSize:12,fontWeight:600,fontFamily: 'Nunito Sans', dx: 28, dy:-15}}
              style={{ fontSize: 11, fontFamily: 'Nunito Sans', }}
              tickFormatter={(tickItem) => { return (tickItem >= 1000 ? (tickItem / 1000).toLocaleString() + ' k' : tickItem.toLocaleString()) }}
              type='number' />

            <Bar
              // data={series[0].data}
              dataKey='count'
              fill={series[0].chartColor}
              isAnimationActive={false}
              style={{ fontSize: 12, fontFamily: 'Nunito Sans', }}
              key={'count'} />,

          </ComposedChart>
        }
      </Grid>
    )
  }
}

export default DayOfWeekBar;
