import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { parse } from 'query-string';
import moment from 'moment-timezone';

import { Grid } from '@material-ui/core';

import config from '../../utils/config';
import { clientData } from '../../utils/clients';
import {
  formatAvgsData, formatTimeData, formatReportDates,
  getShowDemographicsArray, getLocalStorageData, getLocationColors, getQueryDates, objToArray
} from '../../utils/reportHelpers';

import MultiLocationReport from './reports/MultiLocationReport';
import SingleLocationReport from './reports/SingleLocationReport';

const PAGEWIDTH = 793
const PAGEHEIGHT = 1122


class CreateReport extends PureComponent {
  constructor(props) {
    super(props);

    this.reportClient = this.props.auth.user.client?.toLowerCase();  // this should always be set even when puppeteer renders page
    this.isPuppeteer = (parse(window.location.search).puppeteer === 'true'); // boolean, true only if in queryString and 'true'

    const [repColors, repLocLabels, repChartLabels, repLocations, repHighlights] = (this.isPuppeteer) ? getLocalStorageData() : [[], [], [], [], []];

    this.state = {
      renderAvgs: false,
      renderTime: false,

      reportByWeek: (this.isPuppeteer) ? parseInt(parse(window.location.search).reportByWeek) : this.props.reportByWeek, // toggle report between byWeek (1), byMonth (2), custom (3)

      reportEstimate: this.props.reportEstimate?.toString() || parse(window.location.search).estimate || 'true', // default to true
      reportStartDate: this.props.startDate?.clone() || moment(parse(window.location.search).sDate).tz('America/New_York'), // if no prop and queryStr undefined, moment is created w/ current date
      reportEndDate: this.props.endDate?.clone() || moment(parse(window.location.search).eDate).tz('America/New_York'),

      dataStartDate: this.props.dateRangeStart?.clone() || moment(parse(window.location.search).sDateData).tz('America/New_York'), // earliest data start date for open locations

      chartColors: (this.isPuppeteer) ? repColors : getLocationColors(this.props.reportLocations), // array of str w/ loc colors: ['#5899da', '#fbbf45', '#710162']
      chartLabels: (this.isPuppeteer) ? repChartLabels : objToArray(this.props.chartLabels, false, true), // array of str w/ lbls for chart legend: ['Massachusetts Ave', 'Edgerly Rd (Total)', 'Edgerly Rd, Whole Foods Entering (Total)']
      locationLabels: (this.isPuppeteer) ? repLocLabels : objToArray(this.props.chartLabels, false, false), // array of str w/ lbls for loc names: ['Massachusetts Ave', 'Edgerly Rd', 'Edgerly Rd + Whole Foods Entering']
      reportLocations: (this.isPuppeteer) ? repLocations : objToArray(this.props.reportLocations, true, false), // array of arrays w/ with loc query names: [['Massachusetts Ave'],['McDonald's Sidewalk', 'Whole Foods Sidewalk'],['McDonald's Sidewalk', 'Whole Foods Sidewalk', 'Whole Foods Entering']]

      reportTitle: clientData[this.reportClient].reports.reportTitle, // '245 Massachusetts Ave'
      reportSubTitle: clientData[this.reportClient].reports.reportSubTitle, // 'Mass Ave vs Edgerly Rd'

      byDateData: {},
      byTimeData: {},
      byDateChartData: [],
      byTimeChartData: [],

      locType: this.props.locType || parse(window.location.search).locType, // 'People', 'Vehicles'

      demo: this.props.demo || (parse(window.location.search).demo === 'true'),
      change: this.props.change || (parse(window.location.search).change === 'true'),
      detailed: this.props.detailed || (parse(window.location.search).detailed === 'true'),

      reportHighlights: (this.isPuppeteer && repHighlights.length) ? repHighlights[0] : '',
      reportDateInterval: this.props.dateInterval || parse(window.location.search).dateInterval, // 'Day', 'Week', 'Month', 'Year'
    };

    if (this.state.reportLocations.length > 0) this.getReportData();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.dashboard && nextProps.reportByWeek !== prevState.reportByWeek) {
      return {
        reportByWeek: nextProps.reportByWeek,
      };
    }

    if (nextProps.dashboard && nextProps.demo !== prevState.demo) {
      return {
        demo: nextProps.demo,
      };
    }

    if (nextProps.dashboard && nextProps.change !== prevState.change) {
      return {
        change: nextProps.change,
      };
    }

    if (nextProps.dashboard && nextProps.detailed !== prevState.detailed) {
      return {
        detailed: nextProps.detailed,
      };
    }

    return {};
  }

  componentDidUpdate(prevProps) {
    if (prevProps.reportByWeek !== this.props.reportByWeek) {
      if (this.state.reportLocations.length > 0) this.getReportData();
    }
    if (prevProps.demo !== this.props.demo) {
      if (this.state.reportLocations.length > 0) this.getReportData();
    }
    if (prevProps.change !== this.props.change) {
      if (this.state.reportLocations.length > 0) this.getReportData();
    }
    if (prevProps.detailed !== this.props.detailed) {
      if (this.state.reportLocations.length > 0) this.getReportData();
    }
  }

  getReportData = async () => {
    // add error handling, all reject as soon as one of the promises rejects
    await Promise.all([
      this.apiGetAverages(),
      this.apiGetByTime()
    ]);

    const loadedDiv = document.getElementById('loaded');
    if (loadedDiv?.style.visibility) loadedDiv.style.visibility = 'visible'; // used to signal puppeteer data is loaded
  }

  apiGetAverages = async () => {
    try {
      const { reportEstimate, reportLocations, reportStartDate, reportEndDate, reportByWeek, chartColors, chartLabels, dataStartDate, reportDateInterval } = this.state;
      const { currentStart, currentEnd, prevStart, prevEnd } = await getQueryDates(reportStartDate, reportEndDate, reportByWeek, dataStartDate);

      // const response = await axios.get(config.api.url + '/r1/traffic/rReportWeeklyAverages', {
      const response = await axios.get(config.api.url + '/r1/traffic/rReportWeeklyAverages2', {
        params: {
          firstDate: prevStart.format(),
          endDate: currentEnd.format(),
          startOfCurrent: currentStart.format(),
          endOfPrev: prevEnd.format(),
          location: JSON.stringify(reportLocations),
          estimate: reportEstimate,
          dateInterval: reportDateInterval,
        }
      });

      const { byDateChart, ...avgsDataObj } = await formatAvgsData(response.data, chartColors, chartLabels); // all returned data

      this.setState({
        renderAvgs: true,
        byDateData: avgsDataObj,
        byDateChartData: byDateChart,
      });
    } catch (err) {
      // console.log('apiGetAverages - error: ');
      // console.log(err);
    }
  };

  apiGetByTime = async () => {
    try {
      const { reportEstimate, reportLocations, reportStartDate, reportEndDate, reportByWeek, chartColors, chartLabels } = this.state;
      const range = { 1: 'isoWeek', 2: 'month', 3: 'day' };

      // const response = await axios.get(config.api.url + '/r1/traffic/rWeeklyByTimeDayType', {
      const response = await axios.get(config.api.url + '/r1/traffic/rWeeklyByTimeDayType2', {
        params: {
          startDate: reportStartDate.clone().startOf(range[reportByWeek]).format(),
          endDate: (reportByWeek < 3) ? reportStartDate.clone().endOf(range[reportByWeek]).format() : reportEndDate.clone().endOf(range[reportByWeek]).format(),
          // endDate: (reportByWeek) ? startDate.clone().endOf('isoWeek').format() : startDate.clone().endOf('month').format(),
          location: JSON.stringify(reportLocations),
          estimate: reportEstimate,
        }
      });

      const { byTimeChart, ...byTimeDataObj } = await formatTimeData(response.data, chartColors, chartLabels, this.reportClient, reportLocations); // all returned data

      this.setState({
        renderTime: true,
        byTimeData: byTimeDataObj,
        byTimeChartData: byTimeChart,
      });
    } catch (err) {
      // console.log('apiGetByTime - error: ');
      // console.log(err);
    }
  };

  render() {
    const {
      byDateChartData, byDateData, byTimeChartData, byTimeData, change, chartColors, demo, detailed, locationLabels, locType, renderAvgs, renderTime,
      reportByWeek, reportDateInterval, reportEndDate, reportHighlights, reportLocations, reportStartDate, reportSubTitle, reportTitle } = this.state;

    const client = this.props.auth.user.client; // use for image path, not all lowercase
    const dateLabels = formatReportDates(reportStartDate, reportEndDate, reportByWeek); // construct date labels

    const showDemo = (locType === 'Vehicles') ? false : (demo ? true : false);
    const showDemoArray = getShowDemographicsArray(this.reportClient, showDemo, reportLocations);

    let isFlagship = false;
    if (this.reportClient === 'lindt') {
      reportLocations.forEach(el => {
        el.forEach(loc => { if (['579 5th Ave', 'Laderach (537 5th Ave)', 'Laderach Entering', 'Laderach Window Browsing', 'Microsoft Store (677 5th Ave)'].includes(loc)) isFlagship = true; });
      });
    }

    return (
      <Grid container style={{ width: PAGEWIDTH, marginTop: 0 }} direction='column' justifyContent='flex-start' alignItems='center'>

        {(renderAvgs && renderTime) && reportLocations.length === 1 &&
          <SingleLocationReport
            byDateChartData={byDateChartData}
            byDateData={byDateData}
            byTimeChartData={byTimeChartData}
            byTimeData={byTimeData}

            change={change}
            chartColors={chartColors}
            client={client}
            dateLabels={dateLabels}
            detailed={detailed}
            handleHighlightsChange={this.props.reportHighlightsChanged}
            isFlagship={isFlagship}
            isPuppeteer={this.isPuppeteer}
            locationLabels={locationLabels}
            pageHeight={PAGEHEIGHT}
            pageWidth={PAGEWIDTH}

            reportByWeek={reportByWeek}
            reportClient={this.reportClient}
            reportDateInterval={reportDateInterval}
            reportHighlights={reportHighlights}
            // reportLocations={reportLocations}
            reportTitle={reportTitle}
            showDemoArray={showDemoArray}
          />
        }

        {(renderAvgs && renderTime) && reportLocations.length > 1 &&
          <MultiLocationReport
            byDateChartData={byDateChartData}
            byDateData={byDateData}
            byTimeChartData={byTimeChartData}
            byTimeData={byTimeData}

            change={change}
            chartColors={chartColors}
            client={client}
            dateLabels={dateLabels}
            detailed={detailed}
            handleHighlightsChange={this.props.reportHighlightsChanged}
            isFlagship={isFlagship}
            isPuppeteer={this.isPuppeteer}
            locationLabels={locationLabels}
            pageHeight={PAGEHEIGHT}
            pageWidth={PAGEWIDTH}

            reportByWeek={reportByWeek}
            reportClient={this.reportClient}
            reportDateInterval={reportDateInterval}
            reportHighlights={reportHighlights}
            reportLocations={reportLocations}
            reportSubTitle={reportSubTitle}
            reportTitle={reportTitle}
            showDemoArray={showDemoArray}
          />
        }

        <div id='loaded' style={{ visibility: 'hidden' }}></div>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(CreateReport);
