const config = {
  api: {
    url: 'https://exterosapi.appspot.com'
    // url: 'http://localhost:5000'
  },
  auth: {
    localStorage: {
      access: 'token',
      refresh: 'refreshToken',
      notify: 'notify'
    },
    header: {
      access: 'Authorization',
      refresh: 'Refresh'
    }
  },
  dashboard: {
    url: 'https://dashboard.exteros.com'
    // url: 'http://localhost:3000'
  },
  analytics: true
};

module.exports = config;
