import React, { PureComponent } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';

import store from './store';
import { isLoggedIn, setCurrentUser, logoutUserOld } from './utils/services';

import PrivateRoute from './components/private-route/PrivateRoute';
import AdminRoute from './components/private-route/AdminRoute';
import StreamingRoute from './components/private-route/StreamingRoute';

import Dashboard from './components/dashboard/Dashboard';
import ForgotPassword from './components/auth/ForgotPassword';
import Login from './components/auth/Login';
import ResetPassword from './components/auth/ResetPassword';
import SetupPassword from './components/auth/SetupPassword';
import Email from './components/auth/Email';

import AddUser from './components/auth/AddUser';
import AdminDashboard from './components/auth/AdminDashboard';

import VideoDashboard from './components/video/VideoDashboard';

import Report from './components/dashboard/CreateReport';
import Report2 from './components/dashboard/CreateMcDReport';

// import Landing from './components/layout/Landing';
// import Register from './components/auth/Register';

import { createTheme, ThemeProvider } from '@material-ui/core/styles';

import './App.css';

// force logout to migrate users to new auth system
let oldToken = localStorage.getItem('jwtToken');
if (oldToken !== 'undefined' && oldToken !== null) store.dispatch(logoutUserOld());

// Check for token to keep user logged in
const token = isLoggedIn(); // returns token or false
if (token) store.dispatch(setCurrentUser(token)); // Set user and isAuthenticated

const exteros_theme = createTheme({
  palette: {
    primary: {
      main: '#00313D'
    },
    secondary: {
      main: '#AFD8D0'
    },
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      'Lato',
      'sans-serif'
    ].join(',')
  },
  graphColors: {
    men: {
      first: '#8599BC',
      second: '#9AB2DA'
    },
    women: {
      first: '#D3829E',
      second: '#F699B9'
    },
  },
});

class App extends PureComponent {

  render() {
    return (
      <Provider store={store}>
        <Router>
          <div className='App'>
            <ThemeProvider theme={exteros_theme}>
              <Switch>
                <Route exact path='/' component={Login} />
                <Route exact path='/login' component={Login} />
                <Route exact path='/forgot-password' component={ForgotPassword} />
                <Route exact path='/reset-password' component={ResetPassword} />
                <Route exact path='/setup-password' component={SetupPassword} />
                <Route exact path='/email' component={Email} />

                <PrivateRoute exact path='/dashboard' component={Dashboard} />
                <PrivateRoute exact path='/report' component={Report} />
                <PrivateRoute exact path='/report2' component={Report2} />

                <AdminRoute exact path='/users' component={AddUser} />
                <AdminRoute exact path='/admin' component={AdminDashboard} />

                <StreamingRoute exact path='/video' component={VideoDashboard} />
              </Switch>
            </ThemeProvider>
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
