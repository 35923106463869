import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { Label, Text, LineChart, Line, Symbols, XAxis, YAxis, Surface, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { CSVLink } from 'react-csv';

import ExportButton from '@material-ui/icons/SaveAltRounded';
import { CircularProgress, Grid, IconButton, ImageList, Menu, MenuItem, Select, Typography } from '@material-ui/core';
import { alpha, lighten, withStyles } from '@material-ui/core/styles';

import { saveImage, reduceArray, getColor, getCordinates } from '../../utils/helpers';
import { colorsMen, colorsWomen, colorsChildren, colorsAdults, colorsSeniors, colorsBikes, colorsBuses, colorsCars, colorsTrucks } from '../../utils/colors';
import rainIcon from './rain.svg';
import snowIcon from './snow.svg';
import holidayIcon from './holiday.svg';


const SIZE = 32;

const styles = theme => ({
  menuItem: {
    // color: theme.palette.primary.main
  },
  gridList: {
    // width: '100%',
    height:'100%',
    overflowX:'hidden',
    overflowY:'hidden',
    // height: 100,
    // flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    // transform: 'translateZ(0)',
  },
});

function CustomTooltip({ payload, label, active, multDates, sameWeather, singleLocation, numPlots, mode,interval }) {
  if (active && payload && payload.length) {
    const dateStr = moment(label * 1000).tz('America/New_York'); // moment(payload[0]?.payload?._id?.timeStamp * 1000).tz('America/New_York');

    let dataName = [];
    let numLines = payload.length;
    let fontAdjust = 12 - numPlots - (numLines * 1.5);
    let calMinSize = 3;
    let countMinSize = 1;
    if (sameWeather) {
      calMinSize = calMinSize + 4;
      countMinSize = countMinSize + 4;
    }
    if (multDates) {
      fontAdjust = fontAdjust / 1.7;
    }

    for (let p = 0; p < numLines; p++) {
      dataName[p] = payload[p].name.split(': ');
    }

    return (
      <Grid>
        <Grid container alignItems={'flex-end'} style={{ textAlign: 'left', paddingTop: 0, paddingLeft: 1, paddingRight: 0, paddingBottom: Math.max(countMinSize - 2, fontAdjust) / 4, backgroundColor: alpha('#ffffff', 0.5) }}>
          <Typography component={'span'} style={{ fontWeight: 500, fontSize: 'calc(' + Math.max(countMinSize, fontAdjust) + 'px + 4px + 0.5vw)', paddingRight: 5 }}>
            {interval==='Year'?`${dateStr.format('YYYY')}`:interval==='Month'?`${dateStr.format('MMM')}`:`${dateStr.format('M-D-YYYY')}`}
          </Typography> 
          <Typography component={'span'} style={{ fontWeight: 600, fontSize: 'calc(' + Math.max(countMinSize, fontAdjust) + 'px + 4px + 0.5vw)', color: '#8c8c8c' }}>
            {/* {`${dateStr.format('ddd')}`} */}
            {interval==='Year'?'':interval==='Month'?`${dateStr.format('YYYY')}`:interval==='Week'?'week of':`${dateStr.format('ddd')}`}
          </Typography>

          <Grid alignItems={'center'} container style={{ paddingBottom: Math.max(countMinSize, fontAdjust) / 4, display: (payload[0]?.payload?.holiday === '' || payload[0]?.payload?.holiday === null) ? 'none': 'flex' }}>
            <img alt='Holiday' src={holidayIcon} style = {{ height: 'calc(' + Math.max(calMinSize - 2, fontAdjust) + 'px - 0px + 0.4vw)', paddingRight: 5 }}/>
            <Typography component={'span'} style={{ fontSize: 'calc(' + Math.max(calMinSize, fontAdjust) + 'px - 0px + 0.4vw)' }}>
              {payload[0]?.payload?.holiday}
            </Typography>
          </Grid>
        </Grid>

      { payload.map( (p, index) => (
        <Grid key={'tt' + index} container direction='column' justifyContent='flex-end' alignItems='flex-end' style={{ textAlign: 'right', paddingTop: Math.max(countMinSize, fontAdjust) / 2, paddingBottom: Math.max(countMinSize, fontAdjust), paddingLeft: 3 + Math.max(countMinSize, fontAdjust), paddingRight: 5 + Math.max(countMinSize, fontAdjust), marginBottom: 1 + (Math.max(countMinSize - 1, fontAdjust) / 2), backgroundColor: alpha(lighten(p.color, 0.4), 0.9) }}>
          <Grid container direction='row' justifyContent='space-between' alignItems='flex-end'>
            <Typography component={'span'} style={{ fontWeight: 500, fontSize: 'calc(' + Math.max(countMinSize, fontAdjust) + 'px + 2px + 0.5vw)' }}>
              {mode === 'Conversion' ? `${(p.value).toLocaleString()}%` : (mode === 'Dwell') ? `${(p.value).toLocaleString()}s` : `${Math.round(p.value).toLocaleString()} `}
            </Typography>
            <Typography component={'span'} style={{ display: singleLocation ? '' : '', color: 'white', fontWeight: 500, paddingLeft: 20, fontSize: 'calc(' + Math.max(countMinSize, fontAdjust) + 'px + 2px + 0.5vw)', textAlign: 'right' }}>
              {dataName[index][0]}
            </Typography>
            <Typography component={'span'} style={{ display: (dataName[index].length > 1 && !singleLocation) ? '' : 'none', color: 'white', fontWeight: 500, fontSize: 'calc(' + Math.max(countMinSize, fontAdjust) + 'px + 2px + 0.5vw)', textAlign: 'right' }}>
              {': ' + dataName[index][1]}
            </Typography>
          </Grid>
          <Grid container justifyContent='flex-end' style={{ display: (singleLocation || sameWeather) ? 'none' : '', paddingBottom: Math.max(countMinSize - 2, fontAdjust) / 10 }}>
            <Typography component={'span'} style={{ fontWeight: 500, paddingBottom: 1, fontSize: 'calc(' + Math.max(calMinSize, fontAdjust) + 'px - 0px + 0.3vw)' }}>
              {p.payload.tavg === null ? `- \xB0F` : `${p.payload.tavg.toFixed(1)}\xB0F`}
            </Typography>
            <Grid container justifyContent='flex-end' alignItems={'center'} style={{ display: p.payload.prcp > 0 ? 'flex' : 'none' }}>
              <img alt='Rain' src={rainIcon} color='white' style={{ height: 'calc(' + Math.max(calMinSize - 2, fontAdjust) + 'px - 0px + 0.5vw)', paddingRight: 5 }}/>
              <Typography component={'span'} style={{ fontWeight: 400, fontSize: 'calc(' + Math.max(calMinSize, fontAdjust) + 'px - 0px + 0.3vw)' }}>
                {`${p.payload.prcp.toFixed(2)} in`}
              </Typography>
            </Grid>
            <Grid container justifyContent='flex-end' alignItems={'center'} style={{ paddingBottom: 0, display: p.payload.snow > 0 ? 'flex' : 'none' }}>
              <img alt='Snow' src={snowIcon} style={{ height: 'calc(' + Math.max(calMinSize - 2, fontAdjust) + 'px - 0px + 0.5vw)', paddingBottom: 0, paddingRight: 5 }}/>
              <Typography component={'span'} style={{ fontWeight: 400, fontSize: 'calc(' + Math.max(calMinSize, fontAdjust) + 'px - 0px + 0.3vw)' }}>
                {`Snow: ${p.payload.snow.toFixed(2)} in`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      ))}
      { payload.length > 0 &&
        <Grid container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ display: (singleLocation || sameWeather) ? '' : 'none', textAlign: 'left', paddingTop: fontAdjust / 2, paddingBottom: fontAdjust, paddingLeft: 0, paddingRight: fontAdjust, marginBottom: 1 + (fontAdjust / 2), backgroundColor: alpha('#ffffff', 0.5) }}>
          {/* <Grid alignItems={'center'} container style={{ paddingBottom: fontAdjust / 10, display: (payload[0].payload.holiday === '' || payload[0].payload.holiday === null) ? 'none' : 'flex' }}>
            <img alt='Holiday' src={holidayIcon} style={{ height: 'calc(' + Math.max(calMinSize - 2, fontAdjust) + 'px - 0px + 0.5vw)', paddingRight: 5 }}/>
            <Typography component={'span'} style={{ fontSize: 'calc(' + Math.max(calMinSize, fontAdjust) + 'px - 0px + 0.3vw)' }}>
              {payload[0].payload.holiday}
            </Typography>
          </Grid> */}
          <Typography component={'span'} style={{ fontWeight: 500, paddingBottom: 1, fontSize: 'calc(' + Math.max(calMinSize + 2, fontAdjust) + 'px - 2px + 0.5vw)' }}>
            {payload[0].payload.tavg === null ? `- \xB0F` : `${payload[0].payload.tavg.toFixed(1)}\xB0F`}
          </Typography>
          <Grid alignItems={'center'} container style={{ paddingBottom: fontAdjust / 10, display: payload[0].payload.prcp > 0 ? 'flex' : 'none' }}>
            <img alt='Rain' src={rainIcon} style={{ height: 'calc(' + Math.max(calMinSize - 0, fontAdjust) + 'px - 2px + 0.6vw)', paddingRight: 5 }}/>
            <Typography component={'span'} style={{ fontWeight: 500, fontSize: 'calc(' + Math.max(calMinSize + 2, fontAdjust) + 'px - 2px + 0.5vw)' }}>
              {`${payload[0].payload.prcp.toFixed(2)} in`}
            </Typography>
          </Grid>
          <Grid alignItems={'center'} container style={{ paddingBottom: 0, display: payload[0].payload.snow > 0 ? 'flex' : 'none' }}>
            <img alt='Snow' src={snowIcon} style={{ height: 'calc(' + Math.max(calMinSize - 0, fontAdjust) + 'px - 2px + 0.6vw)', paddingBottom: 0, paddingRight: 5 }}/>
            <Typography component={'span'} style={{ fontWeight: 500, fontSize: 'calc(' + Math.max(calMinSize + 2, fontAdjust) + 'px - 2px + 0.5vw)' }}>
              {`Snow: ${payload[0].payload.snow.toFixed(2)} in`}
            </Typography>
          </Grid>
        </Grid>
      }
      </Grid>
    );
  }

  return null;
}

class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, payload, yshift, numPlotRows, interval } = this.props;
    const fontSize1 = 'calc(' + (7 - numPlotRows + 1) + 'px + ' + (0.4 / 1) + 'vw)';
    const fontSize2 = 'calc(' + (5 - numPlotRows + 1) + 'px + ' + (0.4 / 1) + 'vw)';
    const adjYshift = yshift - numPlotRows + 1;
    const axisDate = moment(payload.value * 1000).tz('America/New_York');

    return (
      <g transform={`translate(${x - 5},${y + adjYshift}) rotate(30, 0,0)`}>
        <Text
          x={0}
          y={0}
          dx={0}
          dy={0}
          textAnchor='start'
          fill='#666'
          align='left'
          style={{ fontSize: fontSize1 }}
        >
          {interval==='Year'?axisDate.format('YYYY'):interval==='Month'?axisDate.format('MMM'):axisDate.format('M-D-YY')}
        </Text>
        <Text
          x={0}
          y={6 / numPlotRows + window.innerWidth / 200}
          dx={0}
          dy={0}
          textAnchor='start'
          align='left'
          fill='#a6a6a6'
          style={{ fontSize: fontSize2 }}
        >
          {interval==='Year'?'':interval==='Month'?axisDate.format('YYYY'):interval==='Week'?'week of':axisDate.format('ddd')}
        </Text>
      </g>
    );
  }
}

// const initDashed = (numLocs) => {
//   var dashed = []
//   for (let p = 0; p < numLocs; p++) {
//     dashed.push('');
//   }
//   for (let p = 0; p < numLocs; p++) {
//     dashed.push('10 5');
//   }
//   return dashed;
// }


class ByDateChart extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      series: this.props.series,
      anchorEl: null,
      exportData: [],
      exportHeaders: null,
      dateRange: '',
      // showCopied: false,
      colors: this.props.locationsObject['colors'],
      COMBCOLORS: this.props.locationsObject['COMBCOLORS'],
      // dashed: initDashed(this.props.locationsObject.labelList.length),
      labelList: this.props.locationsObject['labelList'],
      count: this.props.count,
      gender: this.props.gender,
      age: this.props.age,
      multDates: this.props.multDates,
      sameWeather: this.props.sameWeather,
      numPlots: this.props.numPlots,
      CELLWIDTH: this.props.CELLWIDTH,
      interval: this.props.interval,
    };

    this.currentChart = React.createRef();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // do things with nextProps.someProp and prevState.cachedSomeProp
    if (nextProps.series !== prevState.series) {
      return {
        series: nextProps.series,
        multDates: nextProps.multDates,
        numPlots: nextProps.numPlots,
        interval: nextProps.interval,
      };
    }
    return {};
  }

  // getLine(idx) {
  //   if (this.state.multDates) {
  //     return this.state.dashed[idx];
  //   } else {
  //     return '';
  //   }
  // }


  renderIcon(data) {
    const { inactiveColor } = this.props;
    const halfSize = SIZE / 2;
    const sixthSize = SIZE / 6;
    const thirdSize = SIZE / 3;
    const color = data.inactive ? inactiveColor : data.color;
    var newStroke = data.payload.strokeDasharray;
    if (newStroke !== '') {
      newStroke = '5 3'
    }

    if (data.type === 'plainline') {
      return (
        <line
          strokeWidth={4}
          fill='none'
          stroke={color}
          strokeDasharray={newStroke}
          x1={0}
          y1={halfSize}
          x2={SIZE}
          y2={halfSize}
          className='recharts-legend-icon'
        />
      );
    } if (data.type === 'line') {
      return (
        <path
          strokeWidth={4}
          fill='none'
          stroke={color}
          strokeDasharray={newStroke}
          d={`M0,${halfSize}h${thirdSize}
            A${sixthSize},${sixthSize},0,1,1,${2 * thirdSize},${halfSize}
            H${SIZE}M${2 * thirdSize},${halfSize}
            A${sixthSize},${sixthSize},0,1,1,${thirdSize},${halfSize}`}
          className='recharts-legend-icon'
        />
      );
    } if (data.type === 'rect') {
      return (
        <path
          stroke='none'
          fill={color}
          d={`M0,${SIZE / 8}h${SIZE}v${SIZE * 3 / 4}h${-SIZE}z`}
          className='recharts-legend-icon'
        />
      );
    }

    return (
      <Symbols
        fill={color}
        cx={halfSize}
        cy={halfSize}
        size={SIZE}
        sizeType='diameter'
        type={data.type}
      />
    );
  }

  renderLegend = (props) => {
    const { payload } = props;
    const numLines = (this.state.multDates) ? (this.state.numPlots + 1) : (this.state.numPlots);
    // const active = payload.filter(line => !line.inactive); // not needed, this chart only sends data for active plots

    var ICONSIZE = 24;
    const viewBox = { x: 0, y: 0, width: ICONSIZE, height: ICONSIZE };
    ICONSIZE = (4.5 * Math.sqrt(window.innerWidth / 100)) - (numLines * 0.5);
    if (this.state.multDates) {
      ICONSIZE = ICONSIZE / 1.3;
    }
    let fontAdjust = ICONSIZE;

    return (
      <ul className='recharts-default-legend' style={{ position: 'absolute', width: '25vw', top: 0, right: 0 }}>
      { payload.map( (entry, index) => (
        <li key={`item-${index}-${entry.dataKey}`} style={{ display: entry.inactive ? 'none' : 'inline', textAlign: 'left' }}>
          <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start' style={{ marginTop: ICONSIZE / 3, display: '' }}>
            <Surface xs={1} width={ICONSIZE * 2} height={ICONSIZE * 1.1} viewBox={viewBox} style={{ display: '', paddingRight: fontAdjust / 2, backgroundColor: alpha('#ffffff', 0.5) }}>
              {this.renderIcon(entry)}
            </Surface>
            <Grid container xs item style={{ display: '', paddingRight: fontAdjust / 2 }}>
              <Typography style={{ fontSize: fontAdjust, backgroundColor: alpha('#ffffff', 0.5) }} className='recharts-legend-item-text'>
                {entry.value}
              </Typography>
            </Grid>
          </Grid>
        </li>
      ))}
      </ul>
    );
  }

  // svgToPng = (svg, width, height) => {
  //   return new Promise((resolve, reject) => {
  //     let canvas = document.createElement('canvas');
  //     canvas.width = width;
  //     canvas.height = height;
  //     let ctx = canvas.getContext('2d');

  //     ctx.fillStyle = '#ffffff'; // Set background to white
  //     ctx.fillRect(0, 0, width, height);

  //     let xml = new XMLSerializer().serializeToString(svg);
  //     let dataUrl = 'data:image/svg+xml;utf8,' + encodeURIComponent(xml);
  //     let img = new Image(width, height);    
  //     img.onload = () => {
  //       ctx.drawImage(img, 0, 0);
  //       let imageData = canvas.toDataURL('image/png', 1.0);
  //       resolve(imageData);
  //     }
  //     img.onerror = () => reject();
  //     img.src = dataUrl;
  //   });
  // };

  clickExport = async (event) => {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  }

  changeInterval(event) {
    if (this.state.interval !== event.target.value) {
      this.props.changeInterval(event.target.value);
    }
  }

  exportData = (activeData) => () => {
    let exportData = [];

    for (let s = 0; s < activeData.length; s++) {
      const { lat, lon } = (activeData[s].data.length > 0) ? getCordinates(activeData[s].locationLabels, this.props.locationsObject) : { lat: '', lon: '' };

      let partData = activeData[s].data;

      for (let d = 0; d < partData.length; d++) {
        exportData.push({});
        let curIdx = exportData.length - 1;
        let tempObj = {};
        let formatDate = (partData[d]._id?.timeStamp) ? moment(partData[d]._id.timeStamp * 1000).tz('America/New_York') : '';

        tempObj['Location(s)'] = activeData[s].locationLabels ?? '';
        tempObj['Date'] = (formatDate) ? formatDate.format('M/D/YYYY') : '';
        if (this.state.multDates) { tempObj['Date Range'] = activeData[s].dateLabel ?? ''; }
        tempObj['latitude'] = lat;
        tempObj['longitude'] = lon;
        tempObj['Day of Week'] = (formatDate) ? formatDate.format('dddd') : '';
        tempObj['Holiday'] = (partData[d].holiday === null || partData[d].holiday === '') ? '' : partData[d].holiday;
        tempObj['Avg Temp (F)'] = (partData[d].tavg === null || partData[d].tavg === '') ? '' : partData[d].tavg.toFixed(1);
        tempObj['High Temp (F)'] = (partData[d].tmax === null || partData[d].tmax === '') ? '' : partData[d].tmax.toFixed(1);
        tempObj['Low Temp (F)'] = (partData[d].tmin === null || partData[d].tmin === '') ? '' : partData[d].tmin.toFixed(1);
        tempObj['Rain (in)'] = (partData[d].prcp === null || partData[d].prcp === '') ? '' : partData[d].prcp.toFixed(2);
        tempObj['Snow (in)'] = (partData[d].snow === null || partData[d].snow === '') ? '' : partData[d].snow.toFixed(2);

        if (this.props.mode !== 'Conversion') {
          if (this.state.count) { tempObj['Count'] = partData[d]?.count?.toFixed(0) ?? 0 };
          if (this.state.gender) { tempObj['Male'] = partData[d]?.men?.toFixed(0) ?? 0; tempObj['Female'] = partData[d]?.women?.toFixed(0) ?? 0; };
          if (this.state.age) { tempObj['Adults'] = partData[d]?.adults?.toFixed(0) ?? 0; tempObj['Children'] = partData[d]?.children?.toFixed(0) ?? 0; tempObj['Seniors'] = partData[d]?.seniors?.toFixed(0) ?? 0; };
        }
        else {
          if (this.state.count) { tempObj['Count'] = partData[d]?.count ?? 0 };
          if (this.state.gender) { tempObj['Male'] = partData[d]?.men ?? 0; tempObj['Female'] = partData[d]?.women ?? 0; };
          if (this.state.age) { tempObj['Adults'] = partData[d]?.adults ?? 0; tempObj['Children'] = partData[d]?.children ?? 0; tempObj['Seniors'] = partData[d]?.seniors ?? 0; };
        }

        exportData[curIdx] = tempObj;
      }
    }

    this.setState({
      exportData: exportData, dateRange: activeData[0]?.dateLabel ?? '', anchorEl: null
    });
  }

  downloadImage = async (format) => {
    try {
      const fileObj = {
        chartName: 'ExterosChart_ByDate_',
        dateLabel: this.state.series[0].dateLabel,
        count: this.state.count,
        gender: this.state.gender,
        age: this.state.age,
        fileType: format
      }

      saveImage(this.currentChart.current, fileObj, false);
    } catch (err) {
      // notify user of error
    } finally {
      this.handleClose();
    }
  }

  renderLinesCount(chartArray, multDate, isVehicles) {
    const lines = chartArray.map((s, idx) => (
      <Line
        isAnimationActive={true}
        type='monotoneX'
        data={s.data}
        dataKey='count'
        key={s.locationLabels + (' (' + s.dateLabel + ')') + (multDate & idx === (chartArray.length - 1) ? ' DC' : '') + ((isVehicles) ? idx : '')}
        strokeWidth={3.0}
        strokeDasharray={(multDate & idx === (chartArray.length - 1)) ? '10 5' : ''}
        name={(s.locationLabels.join(', ')) + (multDate ? (' (' + s.dateLabel + ')') : '')}
        hide={!this.state.count || s.data.length === 0}
        stroke={getColor(s.locationLabels, idx, this.props.locationsObject, chartArray)}
        xAxisId={(multDate & idx === (chartArray.length - 1)) ? 1 : 0}
      />
    ));

    return lines;
  }

  renderLinesGender(chartArray, multDate) {
    const lines = chartArray.map((s, idx) => (
      [
        <Line
          isAnimationActive={true}
          type='monotoneX'
          data={s.data}
          dataKey='women'
          key={'Female ' + s.locationLabels + (' (' + s.dateLabel + ')') + (multDate & idx === (chartArray.length - 1) ? ' DC' : '')}
          strokeWidth={3.0}
          stroke={colorsWomen[idx]}
          strokeDasharray={(multDate & idx === (chartArray.length - 1)) ? '10 5' : ''}
          name={'Female: ' + (s.locationLabels.join(', ')) + (multDate ? (' (' + s.dateLabel + ')') : '')}
          hide={!this.state.gender || s.data.length === 0}
          xAxisId={(multDate & idx === (chartArray.length - 1)) ? 1 : 0}
        />,
        <Line
          isAnimationActive={true}
          type='monotoneX'
          data={s.data}
          dataKey='men'
          key={'Male ' + s.locationLabels + (' (' + s.dateLabel + ')') + (multDate & idx === (chartArray.length - 1) ? ' DC' : '')}
          strokeWidth={3.0}
          stroke={colorsMen[idx]}
          strokeDasharray={(multDate & idx === (chartArray.length - 1)) ? '10 5' : ''}
          name={'Male: ' + (s.locationLabels.join(', ')) + (multDate ? (' (' + s.dateLabel + ')') : '')}
          hide={!this.state.gender || s.data.length === 0}
          xAxisId={(multDate & idx === (chartArray.length - 1)) ? 1 : 0}
        />
      ]
    ));

    return lines;
  }

  renderLinesAge(chartArray, multDate) {
    const lines = chartArray.map((s, idx) => (
      [
        <Line
          isAnimationActive={true}
          type='monotoneX'
          data={s.data}
          dataKey='adults'
          key={'Adults ' + s.locationLabels + (' (' + s.dateLabel + ')') + (multDate & idx === (chartArray.length - 1) ? ' DC' : '')}
          strokeWidth={3.0}
          stroke={colorsAdults[idx]}
          strokeDasharray={(multDate & idx === (chartArray.length - 1)) ? '10 5' : ''}
          name={'Adults: ' + (s.locationLabels.join(', ')) + (multDate ? (' (' + s.dateLabel + ')') : '')}
          hide={!this.state.age || s.data.length === 0}
          xAxisId={(multDate & idx === (chartArray.length - 1)) ? 1 : 0}
        />,
        <Line
          isAnimationActive={true}
          type='monotoneX'
          data={s.data}
          dataKey='children'
          key={'Children ' + s.locationLabels + (' (' + s.dateLabel + ')') + (multDate & idx === (chartArray.length - 1) ? ' DC' : '')}
          strokeWidth={3.0}
          stroke={colorsChildren[idx]}
          strokeDasharray={(multDate & idx === (chartArray.length - 1)) ? '10 5' : ''}
          name={'Children: ' + (s.locationLabels.join(', ')) + (multDate ? (' (' + s.dateLabel + ')') : '')}
          hide={!this.state.age || s.data.length === 0}
          xAxisId={(multDate & idx === (chartArray.length - 1)) ? 1 : 0}
        />,
        <Line
          isAnimationActive={true}
          type='monotoneX'
          data={s.data}
          dataKey='seniors'
          key={'Seniors ' + s.locationLabels + (' (' + s.dateLabel + ')') + (multDate & idx === (chartArray.length - 1) ? ' DC' : '')}
          strokeWidth={3.0}
          stroke={colorsSeniors[idx]}
          strokeDasharray={(multDate & idx === (chartArray.length - 1)) ? '10 5' : ''}
          name={'Seniors: ' + (s.locationLabels.join(', ')) + (multDate ? (' (' + s.dateLabel + ')') : '')}
          hide={!this.state.age || s.data.length === 0}
          xAxisId={(multDate & idx === (chartArray.length - 1)) ? 1 : 0}
        />
      ]
    ));

    return lines;
  }

  renderLinesVehicles(chartArray, multDate) {
    const lines = chartArray.map((s, idx) => (
      [
        <Line
          isAnimationActive={true}
          type='monotoneX'
          data={s.data}
          dataKey='bikes'
          key={'Bikes ' + s.locationLabels + (' (' + s.dateLabel + ')') + (multDate & idx === (chartArray.length - 1) ? ' DC' : '')}
          strokeWidth={3.0}
          stroke={colorsBikes[idx]}
          strokeDasharray={(multDate & idx === (chartArray.length - 1)) ? '10 5' : ''}
          name={'Bikes: ' + (s.locationLabels.join(', ')) + (multDate ? (' (' + s.dateLabel + ')') : '')}
          hide={!this.props.vehicles || s.data.length === 0}
          xAxisId={(multDate & idx === (chartArray.length - 1)) ? 1 : 0}
        />,
        <Line
          isAnimationActive={true}
          type='monotoneX'
          data={s.data}
          dataKey='buses'
          key={'Buses ' + s.locationLabels + (' (' + s.dateLabel + ')') + (multDate & idx === (chartArray.length - 1) ? ' DC' : '')}
          strokeWidth={3.0}
          stroke={colorsBuses[idx]}
          strokeDasharray={(multDate & idx === (chartArray.length - 1)) ? '10 5' : ''}
          name={'Buses: ' + (s.locationLabels.join(', ')) + (multDate ? (' (' + s.dateLabel + ')') : '')}
          hide={!this.props.vehicles || s.data.length === 0}
          xAxisId={(multDate & idx === (chartArray.length - 1)) ? 1 : 0}
        />,
        <Line
          isAnimationActive={true}
          type='monotoneX'
          data={s.data}
          dataKey='cars'
          key={'Cars ' + s.locationLabels + (' (' + s.dateLabel + ')') + (multDate & idx === (chartArray.length - 1) ? ' DC' : '')}
          strokeWidth={3.0}
          stroke={colorsCars[idx]}
          strokeDasharray={(multDate & idx === (chartArray.length - 1)) ? '10 5' : ''}
          name={'Cars: ' + (s.locationLabels.join(', ')) + (multDate ? (' (' + s.dateLabel + ')') : '')}
          hide={!this.props.vehicles || s.data.length === 0}
          xAxisId={(multDate & idx === (chartArray.length - 1)) ? 1 : 0}
        />,
        <Line
          isAnimationActive={true}
          type='monotoneX'
          data={s.data}
          dataKey='trucks'
          key={'Trucks ' + s.locationLabels + (' (' + s.dateLabel + ')') + (multDate & idx === (chartArray.length - 1) ? ' DC' : '')}
          strokeWidth={3.0}
          stroke={colorsTrucks[idx]}
          strokeDasharray={(multDate & idx === (chartArray.length - 1)) ? '10 5' : ''}
          name={'Trucks: ' + (s.locationLabels.join(', ')) + (multDate ? (' (' + s.dateLabel + ')') : '')}
          hide={!this.props.vehicles || s.data.length === 0}
          xAxisId={(multDate & idx === (chartArray.length - 1)) ? 1 : 0}
        />
      ]
    ));

    return lines;
  }

  renderDemographics(chartArray, numPlots, multDate) {
    const { age, CELLWIDTH, gender } = this.state;
    const { vehicles, windowHeight, windowWidth } = this.props;

    const lines = chartArray.map((s, idx) => (
      [
        <Grid
          container
          key={'ch' + idx}
          style={{ paddingRight: '0.0vw', height: (windowWidth < CELLWIDTH && numPlots <= 2) ? (windowHeight / Math.round(numPlots / 2)) * 0.3 : (windowHeight / Math.round(numPlots / 2)) * 0.46, backgroundColor: 'white', display: s.data.length > 0 ? '' : 'none' }}
        >
          <Grid item style={{ width: '100%', marginBottom: '1vh', marginRight: '0vw', paddingRight: '0vw', paddingLeft: '1.5vh' }}>
            <Typography style={{ backgroundColor: alpha(getColor(s.locationLabels, idx, this.props.locationsObject, chartArray), 0.6), fontWeight: 600, fontSize: 'calc(6px + 0.4vw)' }}>
              {s.locationLabels.join(', ')}
            </Typography>
          </Grid>
          <ResponsiveContainer container width={'100%'} maxHeight={'100vh'} minHeight={50} height={'100%'} style={{ backgroundColor: 'white' }}>
            <LineChart
              syncId='sync'
              style={{ cursor: 'pointer' }}
              margin={{
                left: windowWidth < CELLWIDTH ? -windowWidth * 0.06 : -windowWidth * 0.01,
                bottom: windowWidth < CELLWIDTH ? windowHeight * 0.005 : windowHeight * 0.02,
                right: windowWidth < CELLWIDTH ? windowWidth * 0.04 : windowWidth * 0.04
              }}
            >
              <XAxis
                style={{ fontSize: 'calc(7px + 0.4vw)' }}
                dataKey='_id.timeStamp'
                scale='utc'
                type='number'
                domain={['auto', 'auto']}
                xAxisId={0}
                label={idx === -1 ? { value: 'Date', position: 'bottom', dy: windowWidth / 60 } : ''}
                tick={<CustomizedAxisTick yshift={10} numPlotRows={(Math.round(numPlots / 2))} interval={this.state.interval}/>}
              />
              <XAxis
                style={{ fontSize: 'calc(7px + 0.4vw)' }}
                dataKey='_id.timeStamp'
                scale='utc'
                type='number'
                domain={['auto', 'auto']}
                xAxisId={1}
                orientation='top'
                mirror={true}
                axisLine={<Line stroke={'#b0b0b0'} strokeDasharray={'6 4'} dot={false}/>}
                tickLine={{ stroke: '#b0b0b0' }}
                tick={<CustomizedAxisTick yshift={12} numPlotRows={(Math.round(numPlots / 2))} interval={this.state.interval}/>}
              />
              <YAxis
                style={{ fontSize: 'calc(' + (8 - (Math.round(numPlots / 2))) + 'px + 0.4vw)' }}
                type='number'
                dx={0}
                tickCount={6}
                domain={[
                  dataMin => 0,
                  dataMax => (dataMax < 0.35 && dataMax !== 0) ? 1 : (Math.round((dataMax * 1.5) / parseInt('1'.padEnd(Math.log(dataMax * 1) * Math.LOG10E + 1 | 0, '0'))) * parseInt('1'.padEnd(Math.log(dataMax * 1) * Math.LOG10E + 1 | 0, '0')))
                ]}
                tickFormatter={
                  this.props.mode === 'Conversion'
                    ? (tickItem) => { return ((tickItem / 1).toLocaleString() + ' %') }
                    : (tickItem) => { return (tickItem >= 1000 ? (tickItem / 1000).toLocaleString() + ' k' : tickItem.toLocaleString()) }
                }
              >
                {idx === -1 &&
                  <Label value={this.props.mode === 'Conversion' ? 'Percent' : (this.props.mode === 'Dwell') ? 'Seconds' : 'Counts'} position='left' angle={-90} dx={30 - (windowWidth / 60)} fontSize={'calc(10px + 0.4vw)'} />
                }
              </YAxis>
              <Tooltip position={{ y: 0 }} content={<CustomTooltip allowEscapeViewBox={{ x: true, y: true }} multDates={multDate} sameWeather={this.state.sameWeather} singleLocation={true} numPlots={numPlots} mode={this.props.mode} interval={this.state.interval} />} />

              {gender && !vehicles &&
                [
                  <Line
                    isAnimationActive={true}
                    type='monotoneX'
                    data={s.data}
                    dataKey='women'
                    key={'Female ' + s.locationLabels + (' (' + s.dateLabel + ')') + (multDate & idx === (chartArray.length - 1) ? ' DC' : '')}
                    strokeWidth={3.0}
                    stroke={colorsWomen[idx]}
                    strokeDasharray={(multDate & idx === (chartArray.length - 1)) ? '10 5' : ''}
                    name={'Female: ' + (s.locationLabels.join(', ')) + (multDate ? (' (' + s.dateLabel + ')') : '')}
                    hide={!gender || s.data.length === 0}
                    xAxisId={(multDate & idx === (chartArray.length - 1)) ? 1 : 0}
                  />,
                  <Line
                    isAnimationActive={true}
                    type='monotoneX'
                    data={s.data}
                    dataKey='men'
                    key={'Male ' + s.locationLabels + (' (' + s.dateLabel + ')') + (multDate & idx === (chartArray.length - 1) ? ' DC' : '')}
                    strokeWidth={3.0}
                    stroke={colorsMen[idx]}
                    strokeDasharray={(multDate & idx === (chartArray.length - 1)) ? '10 5' : ''}
                    name={'Male: ' + (s.locationLabels.join(', ')) + (multDate ? (' (' + s.dateLabel + ')') : '')}
                    hide={!gender || s.data.length === 0}
                    xAxisId={(multDate & idx === (chartArray.length - 1)) ? 1 : 0}
                  />,
                ]
              }

              {age && !vehicles &&
                [
                  <Line
                    isAnimationActive={true}
                    type='monotoneX'
                    data={s.data}
                    dataKey='adults'
                    key={'Adults ' + s.locationLabels + (' (' + s.dateLabel + ')') + (multDate & idx === (chartArray.length - 1) ? ' DC' : '')}
                    strokeWidth={3.0}
                    stroke={colorsAdults[idx]}
                    strokeDasharray={(multDate & idx === (chartArray.length - 1)) ? '10 5' : ''}
                    name={'Adults: ' + (s.locationLabels.join(', ')) + (multDate ? (' (' + s.dateLabel + ')') : '')}
                    hide={!age || s.data.length === 0}
                    xAxisId={(multDate & idx === (chartArray.length - 1)) ? 1 : 0}
                  />,
                  <Line
                    isAnimationActive={true}
                    type='monotoneX'
                    data={s.data}
                    dataKey='children'
                    key={'Children ' + s.locationLabels + (' (' + s.dateLabel + ')') + (multDate & idx === (chartArray.length - 1) ? ' DC' : '')}
                    strokeWidth={3.0}
                    stroke={colorsChildren[idx]}
                    strokeDasharray={(multDate & idx === (chartArray.length - 1)) ? '10 5' : ''}
                    name={'Children: ' + (s.locationLabels.join(', ')) + (multDate ? (' (' + s.dateLabel + ')') : '')}
                    hide={!age || s.data.length === 0}
                    xAxisId={(multDate & idx === (chartArray.length - 1)) ? 1 : 0}
                  />,
                  <Line
                    isAnimationActive={true}
                    type='monotoneX'
                    data={s.data}
                    dataKey='seniors'
                    key={'Seniors ' + s.locationLabels + (' (' + s.dateLabel + ')') + (multDate & idx === (chartArray.length - 1) ? ' DC' : '')}
                    strokeWidth={3.0}
                    stroke={colorsSeniors[idx]}
                    strokeDasharray={(multDate & idx === (chartArray.length - 1)) ? '10 5' : ''}
                    name={'Seniors: ' + (s.locationLabels.join(', ')) + (multDate ? (' (' + s.dateLabel + ')') : '')}
                    hide={!age || s.data.length === 0}
                    xAxisId={(multDate & idx === (chartArray.length - 1)) ? 1 : 0}
                  />
                ]
              }

              {vehicles &&
                [
                  <Line
                    isAnimationActive={true}
                    type='monotoneX'
                    data={s.data}
                    dataKey='bikes'
                    key={'Bikes ' + s.locationLabels + (' (' + s.dateLabel + ')') + (multDate & idx === (chartArray.length - 1) ? ' DC' : '')}
                    strokeWidth={3.0}
                    stroke={colorsBikes[idx]}
                    strokeDasharray={(multDate & idx === (chartArray.length - 1)) ? '10 5' : ''}
                    name={'Bikes: ' + (s.locationLabels.join(', ')) + (multDate ? (' (' + s.dateLabel + ')') : '')}
                    hide={!gender || s.data.length === 0}
                    xAxisId={(multDate & idx === (chartArray.length - 1)) ? 1 : 0}
                  />,
                  <Line
                    isAnimationActive={true}
                    type='monotoneX'
                    data={s.data}
                    dataKey='buses'
                    key={'Buses ' + s.locationLabels + (' (' + s.dateLabel + ')') + (multDate & idx === (chartArray.length - 1) ? ' DC' : '')}
                    strokeWidth={3.0}
                    stroke={colorsBuses[idx]}
                    strokeDasharray={(multDate & idx === (chartArray.length - 1)) ? '10 5' : ''}
                    name={'Buses: ' + (s.locationLabels.join(', ')) + (multDate ? (' (' + s.dateLabel + ')') : '')}
                    hide={!gender || s.data.length === 0}
                    xAxisId={(multDate & idx === (chartArray.length - 1)) ? 1 : 0}
                  />,
                  <Line
                    isAnimationActive={true}
                    type='monotoneX'
                    data={s.data}
                    dataKey='cars'
                    key={'Cars ' + s.locationLabels + (' (' + s.dateLabel + ')') + (multDate & idx === (chartArray.length - 1) ? ' DC' : '')}
                    strokeWidth={3.0}
                    stroke={colorsCars[idx]}
                    strokeDasharray={(multDate & idx === (chartArray.length - 1)) ? '10 5' : ''}
                    name={'Cars: ' + (s.locationLabels.join(', ')) + (multDate ? (' (' + s.dateLabel + ')') : '')}
                    hide={!gender || s.data.length === 0}
                    xAxisId={(multDate & idx === (chartArray.length - 1)) ? 1 : 0}
                  />,
                  <Line
                    isAnimationActive={true}
                    type='monotoneX'
                    data={s.data}
                    dataKey='trucks'
                    key={'Trucks ' + s.locationLabels + (' (' + s.dateLabel + ')') + (multDate & idx === (chartArray.length - 1) ? ' DC' : '')}
                    strokeWidth={3.0}
                    stroke={colorsTrucks[idx]}
                    strokeDasharray={(multDate & idx === (chartArray.length - 1)) ? '10 5' : ''}
                    name={'Trucks: ' + (s.locationLabels.join(', ')) + (multDate ? (' (' + s.dateLabel + ')') : '')}
                    hide={!gender || s.data.length === 0}
                    xAxisId={(multDate & idx === (chartArray.length - 1)) ? 1 : 0}
                  />
                ]
              }

            </LineChart>
          </ResponsiveContainer>
        </Grid>
      ]
    ));

    return lines;
  }

  render() {
    const { classes, openTab, vehicles, windowHeight, windowWidth } = this.props;

    let dataPoints = 0;
    let secondDateCardIndex = 0;
    try { secondDateCardIndex = this.state.series.length - 1; }
    catch (e) {}

    let chartArray = reduceArray(this.state.series, this.state.numPlots, this.state.multDates, secondDateCardIndex);
    try {
      for (let ser in chartArray) {
        if (chartArray[ser].data.length > dataPoints) {
          dataPoints = chartArray[ser].data.length;
        }
      }
    } catch (err) {}

    return (
      <Grid key={'byDate'} style={{ width: '100%', backgroundColor: 'white', height: (windowWidth < this.state.CELLWIDTH && this.state.numPlots <= 2) ? '40vh' : '60vh' }}>
        <Grid container direction='row' justifyContent='space-between' alignItems='stretch' style={{ width: '100%', height: '5.5vh', paddingBottom: '0.5vh', backgroundColor: 'white' }}>
          <Grid container item xs direction='row' justifyContent='flex-start' alignItems='flex-end' style={{ marginLeft: '1.5vw', marginBottom: 0, width: '100%', height: '100%' }}>
            <Typography align='left' style={{ cursor: 'pointer', paddingTop: '0px', width: 'calc(80px + 7vw)', paddingBottom: 4, fontSize: 'calc(12px + 0.6vw)' }}>
              {this.props.mode === 'Conversion'
                ? 'Capture by Date'
                : this.props.mode === 'Dwell'
                  ? 'Avg Dwell by Date'
                  : (this.props.occupancy)
                    ? 'Peak Occupancy'
                    : 'Traffic by Date'
              }
            </Typography>
            <Grid style={{ paddingLeft: '0vw', paddingTop: 0 }}>
              <Select disableUnderline
                labelId='demo-simple-select-outlined-label'
                id='demo-simple-select-outlined'
                value={this.state.interval}
                onChange={this.changeInterval.bind(this)}
                defaultValue='Day'
                label='Interval'
                autoWidth={true}
                // startAdornment={<InputAdornment position='start' style={{ color: '#616161', fontSize: 'calc(4px + 0.6vw)' }} disableTypography={false}></InputAdornment>}
                style={{ fontSize: 'calc(6px + 0.6vw)', textAlign: 'left' }}
              >
                <MenuItem value={'Day'}>Daily</MenuItem>
                <MenuItem value={'Week'}>Weekly</MenuItem>
                <MenuItem value={'Month'}>Monthly</MenuItem>
                <MenuItem value={'Year'}>Yearly</MenuItem>
              </Select>
            </Grid>
          </Grid>
          <Grid item style={{ marginTop: '1.5vh' }}>
            <Grid container direction='row' alignItems='center'>
            { this.state.gender && !this.state.multDates && !vehicles &&
              <Grid container direction='row' alignItems='center' style={{ marginTop: 0 }}>
                <Grid style={{ display: 'inline', marginRight: 'calc(2px + 0.1vw)', width: 'calc(8px + 0.6vw)', height: 'calc(8px + 0.6vw)', backgroundColor: colorsWomen[0] }} />
                <Typography style={{ fontSize: 'calc(8px + 0.6vw)', display: 'inline' }}>Female</Typography>

                <Grid style={{ display: 'inline', marginLeft: 'calc(8px + 0.6vw)', marginRight: 'calc(4px + 0.2vw)', width: 'calc(8px + 0.6vw)', height: 'calc(8px + 0.6vw)', backgroundColor: colorsMen[0] }} />
                <Typography style={{ fontSize: 'calc(8px + 0.6vw)', display: 'inline' }}>Male</Typography>
              </Grid>
            }
            { this.state.age && !this.state.multDates && !vehicles &&
              <Grid container direction='row' alignItems='center' style={{ marginTop: '0.5vh' }}>
                <Grid style={{ display: 'inline', marginRight: 'calc(4px + 0.2vw)', width: 'calc(8px + 0.4vw)', height: 'calc(8px + 0.4vw)', backgroundColor: colorsChildren[0] }} />
                <Typography style={{ fontSize: 'calc(8px + 0.4vw)', display: 'inline' }}>Children</Typography>

                <Grid style={{ display: 'inline', marginLeft: 'calc(8px + 0.4vw)', marginRight: 'calc(4px + 0.2vw)', width: 'calc(8px + 0.4vw)', height: 'calc(8px + 0.4vw)', backgroundColor: colorsAdults[0] }} />
                <Typography style={{ fontSize: 'calc(8px + 0.4vw)', display: 'inline' }}>Adults</Typography>

                <Grid style={{ display: 'inline', marginLeft: 'calc(8px + 0.4vw)', marginRight: 'calc(4px + 0.2vw)', width: 'calc(8px + 0.4vw)', height: 'calc(8px + 0.4vw)', backgroundColor: colorsSeniors[0] }} />
                <Typography style={{ fontSize: 'calc(8px + 0.4vw)', display: 'inline' }}>Seniors</Typography>                
              </Grid>
            }
            { vehicles && this.state.gender && !this.state.multDates &&
              <Grid container direction='row' alignItems='center' style={{ marginTop: '0.5vh' }}>
                <Grid style={{ display: 'inline', marginRight: 'calc(4px + 0.2vw)', width: 'calc(8px + 0.4vw)', height: 'calc(8px + 0.4vw)', backgroundColor: colorsBikes[0] }} />
                <Typography style={{ fontSize: 'calc(8px + 0.4vw)', display: 'inline' }}>Bikes</Typography>

                <Grid style={{ display: 'inline', marginLeft: 'calc(8px + 0.4vw)', marginRight: 'calc(4px + 0.2vw)', width: 'calc(8px + 0.4vw)', height: 'calc(8px + 0.4vw)', backgroundColor: colorsBuses[0] }} />
                <Typography style={{ fontSize: 'calc(8px + 0.4vw)', display: 'inline' }}>Buses</Typography>

                <Grid style={{ display: 'inline', marginLeft: 'calc(8px + 0.4vw)', marginRight: 'calc(4px + 0.2vw)', width: 'calc(8px + 0.4vw)', height: 'calc(8px + 0.4vw)', backgroundColor: colorsCars[0] }} />
                <Typography style={{ fontSize: 'calc(8px + 0.4vw)', display: 'inline' }}>Cars</Typography>

                <Grid style={{ display: 'inline', marginLeft: 'calc(8px + 0.4vw)', marginRight: 'calc(4px + 0.2vw)', width: 'calc(8px + 0.4vw)', height: 'calc(8px + 0.4vw)', backgroundColor: colorsTrucks[0] }} />
                <Typography style={{ fontSize: 'calc(8px + 0.4vw)', display: 'inline' }}>Trucks</Typography>
              </Grid>
            }
            </Grid>
          </Grid>

          <Grid item xs={2}>
            <Grid container alignItems='flex-start' justifyContent='flex-end'>
              <Grid item>
                <IconButton className={classes.exportBtn} onClick={this.clickExport}>
                  <ExportButton />
                </IconButton>
                <Menu
                  id='export-menu'
                  anchorEl={this.state.anchorEl}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  open={Boolean(this.state.anchorEl)}
                  onClose={this.handleClose}>
                  <MenuItem onClick={this.exportData(chartArray)} className={classes.menuItem} disabled={(dataPoints > 0 && !vehicles) ? false : true}>
                    <CSVLink
                      data={this.state.exportData}
                      filename={'ExterosData_ByDate_'.concat(this.state.dateRange, '_', this.state.count ? 'Counts' : '', this.state.gender ? 'Gender' : '', this.state.age ? 'Age' : '', '.csv').replace(/\s/g, '')}
                      style={{ textDecoration: 'none', color: 'inherit' }}>
                      CSV (.csv)
                    </CSVLink>
                  </MenuItem>
                  <MenuItem className={classes.menuItem} onClick={() => this.downloadImage('jpeg')}>Image (.jpeg)</MenuItem>
                  <MenuItem className={classes.menuItem} onClick={() => this.downloadImage('svg')}>Image (.svg)</MenuItem>
                </Menu>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item container direction='column' ref={this.currentChart} justifyContent='flex-start' alignItems='center' style={{ position: 'relative', width: '100%', backgroundColor: 'white', height: (windowWidth < this.state.CELLWIDTH && this.state.numPlots <= 2) ? '31vh' : '50vh' }}>
          <Typography style={{ fontSize: 'calc(8px + 0.4vw)', display: dataPoints > 0 ? 'block' : 'none', position: 'absolute', top: (windowWidth < this.state.CELLWIDTH && this.state.numPlots <= 2) ? windowHeight / 7.4 : windowHeight / 4.2, left: 0, transformOrigin: 'center', transform: 'translate(0px,0px) rotate(-90deg)' }}>
            {this.props.mode === 'Conversion' ? 'Percent' : (this.props.mode === 'Dwell') ? 'Seconds' : 'Counts'}
          </Typography>
          <Typography style={{ fontSize: 'calc(8px + 0.4vw)', display: dataPoints > 0 ? 'block' : 'none', position: 'absolute', left: '50%', bottom: '-2vh' }}>
            Date
          </Typography>

          {/* <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={this.state.showCopied}
            autoHideDuration={1500}
            transitionDuration={{enter:600,exit:1000}}
            onClose={this.messageClose.bind(this)}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={<span id='message-id'>Chart Copied to Clipboard</span>}
          /> */}

        { dataPoints === 0 &&
          <Grid container justifyContent='space-around' alignItems='center'>
            <CircularProgress style={{ position: 'absolute', top: windowHeight / 4.2, left: windowWidth / 2.4 }}/>
          </Grid>
        }
        { dataPoints > 0 && (this.state.multDates || (!this.state.gender && !this.state.age)) &&
          <Grid container item className={classes.gridList} style={{ height: '100%', backgroundColor: 'white', marginTop: '0px', marginBottom: 0, marginLeft: '0px' }}>
            {/* <Grid style={{width:'100%',marginBottom:10, marginLeft:20, display:this.state.multDates?'':'none'}}>
              <Typography item style={{backgroundColor:alpha(this.getColor(this.props.series[0].locationLabels,0),0.6),fontWeight: 700,fontSize:'calc(6px + 0.4vw)'}}>
                {this.props.series[0].locationLabels}
              </Typography>
            </Grid> */}
            <ResponsiveContainer width={'100%'} height={'100%'} maxHeight={'100vh'} minHeight={50}> 
              <LineChart style={{ cursor: 'pointer' }}
                margin={{
                  left: windowWidth < this.state.CELLWIDTH ? -windowWidth * 0.02 : windowWidth * 0.01,
                  bottom: windowWidth < this.state.CELLWIDTH ? windowHeight * 0.01 : windowHeight * 0.03,
                  right: windowWidth < this.state.CELLWIDTH ? windowWidth * 0.08 : windowWidth * 0.04
                }}>
                <XAxis style={{ fontSize: 'calc(8px + 0.4vw)' }} dataKey='_id.timeStamp' scale='utc' type='number' domain={['auto', 'auto']} xAxisId={0}
                  allowDuplicatedCategory={this.state.multDates ? true : false}
                  // label={{ value: 'Date', position: 'bottom',fontSize:'calc(10px + 0.4vw)',dy:windowWidth/60}}
                  tick={<CustomizedAxisTick yshift={10} numPlotRows={1} interval={this.state.interval}/>}/>

                <XAxis style={{ fontSize: 'calc(8px + 0.4vw)' }} dataKey='_id.timeStamp' scale='utc' type='number' domain={['auto', 'auto']} xAxisId={1}
                  orientation='top' mirror={true} axisLine={<Line stroke={'#b0b0b0'} strokeDasharray={'6 4'} dot={false}/>} tickLine={{ stroke: '#b0b0b0' }}
                  tick={<CustomizedAxisTick yshift={12} numPlotRows={1} interval={this.state.interval}/>}/>

                <YAxis
                  type='number'
                  style={{ fontSize: 'calc(8px + 0.4vw)' }}
                  dx={0}
                  tickCount={6}
                  domain={[
                    dataMin => 0,
                    dataMax => (dataMax < 0.35 && dataMax !== 0) ? 1 : (Math.round((dataMax * 1.5) / parseInt('1'.padEnd(Math.log(dataMax * 1) * Math.LOG10E + 1 | 0, '0'))) * parseInt('1'.padEnd(Math.log(dataMax * 1) * Math.LOG10E + 1 | 0, '0')))
                  ]}
                  tickFormatter={
                    this.props.mode === 'Conversion'
                      ? (tickItem) => { return ((tickItem / 1).toLocaleString() + ' %') }
                      : (tickItem) => { return (tickItem >= 1000 ? (tickItem / 1000).toLocaleString() + ' k' : tickItem.toLocaleString()) }
                  }
                >
                  {/* <Label value= 'Counts' position= 'left' angle= {-90} dx={30-(windowWidth/60)} fontSize={'calc(10px + 0.4vw)'} /> */}
                </YAxis>
                <Tooltip position={{ y: 0 }} content={<CustomTooltip allowEscapeViewBox={{ x: true, y: true }} multDates={this.state.multDates} sameWeather={this.state.sameWeather} singleLocation={false} numPlots={1} mode={this.props.mode} interval={this.state.interval}/>} />

              { openTab === 0 &&
                this.renderLinesCount(chartArray, this.state.multDates, vehicles)
              }
              { openTab === 1 && !vehicles &&
                this.renderLinesGender(chartArray, this.state.multDates)
              }
              { openTab === 1 && vehicles &&
                this.renderLinesVehicles(chartArray, this.state.multDates)
              }
              { openTab === 2 &&
                this.renderLinesAge(chartArray, this.state.multDates)
              }

                <Legend iconType='line' align='right' verticalAlign='top' layout='vertical' wrapperStyle={{ right: 0, top: this.state.multDates ? 40 : 0 }} content={this.renderLegend}/>
              </LineChart>
            </ResponsiveContainer>
          </Grid>
        }
        { dataPoints > 0 && !this.state.multDates  &&  (this.state.gender || this.state.age) &&
          <ImageList className={classes.gridList} cols={this.state.numPlots > 1 ? 2 : 1} style={{ width: '96%', backgroundColor: 'white', marginTop: '0px', marginBottom: '0px', paddingLeft: '1vw' }}>

            {this.renderDemographics(chartArray, this.state.numPlots, this.state.multDates)}

          </ImageList>
        }
        </Grid>
      </Grid>
    )
  }
}

export default withRouter(withStyles(styles)(ByDateChart));
