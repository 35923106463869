import React, { PureComponent } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { CircularProgress, Grid } from '@material-ui/core';


const PAGE_HEIGHT = 500
const PAGE_WIDTH = 1000
const GRAPH_HEIGHT = 60
const GRAPH_WIDTH = 197

class MonthlyAveragePie extends PureComponent {

  renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, name, fill, maxRadius, index }) => {
    const [RADIAN, height, width] = [Math.PI / 180, PAGE_HEIGHT, PAGE_WIDTH];
    const radius = (innerRadius + (outerRadius - innerRadius)) * (height / 220) * (450 / height) + 7;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const centerXtitle = cx + radius * Math.cos(-90 * RADIAN);
    const centerAngle = (midAngle > 90) ? 135 : 45;
    const centerX = cx + radius * Math.cos(-centerAngle * RADIAN);

    return (
      <g>
        <text fontFamily={'Nunito Sans'} fontSize={11} x={x > cx ?centerX-4:centerX+0} y={'49%'} fontWeight={700} fill={fill} textAnchor={x > cx ? 'end' : 'start'} dominantBaseline='central'>
          {`${(percent * 100).toFixed(0)}%`}
        </text>
        <text fontFamily={'Nunito Sans'} fontSize={11} fontWeight={300} x={x > cx ?centerX-4:centerX+0} y={'30%'} fill='black' textAnchor={x > cx ? 'end' : 'start'} dominantBaseline='central'>
          {name}
        </text>
        <text fontFamily={'Nunito Sans'} display={index === 0 ? '' : 'none'} fontSize={11} fontWeight={600} x={centerXtitle} y={'82%'} fill='black' textAnchor={'middle'} dominantBaseline='central'>
          {'Daily Avg'}
        </text>
      </g>
    );
  };

  renderCustomizedLabelAge = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, name, fill, index, maxRadius }) => {
    const [RADIAN, height, width] = [Math.PI / 180, PAGE_HEIGHT, PAGE_WIDTH];
    var adultRadius = (innerRadius + (outerRadius - innerRadius)) * (height / 220) * (450 / height);
    var radius = (innerRadius + (outerRadius - innerRadius)) * (height / 190) * (450 / height);
    var x = cx + radius * Math.cos(-midAngle * RADIAN);
    // const y = cy + radius * Math.sin(-midAngle * RADIAN) * 3;
    let centerAngle = 45;
    if (midAngle > 90) {
      centerAngle = 135;
    }
    var centerX = cx + radius * Math.cos(-centerAngle * RADIAN);
    var centerXtitle = cx + adultRadius * Math.cos(-90 * RADIAN);
    let title = 'Daily Avg';

    return (
      <g>
        <text display={name === 'Adults' ? '' : 'none'} fontFamily={'Nunito Sans'} fontSize={11} x={centerXtitle} y={'57%'} fontWeight={700} fill={fill} textAnchor={'middle'} dominantBaseline='central'>
          {`${(percent * 100).toFixed(0)}%`}
        </text>
        <text display={name === 'Adults' ? '' : 'none'} fontFamily={'Nunito Sans'} fontSize={11} fontWeight={300} x={centerXtitle} y={'38%'} fill='black' textAnchor={'middle'} dominantBaseline='central'>
          {name}
        </text>
        <text display={name !== 'Adults' ? '' : 'none'} fontFamily={'Nunito Sans'} fontSize={11} x={x > cx ?centerX-9:centerX+5} y={'49%'} fontWeight={700} fill={fill} textAnchor={x > cx ? 'end' : 'start'} dominantBaseline='central'>
          {`${(percent * 100).toFixed(0)}%`}
        </text>
        <text display={name === 'Seniors'? '' : 'none'} fontFamily={'Nunito Sans'} fontSize={11} fontWeight={300} x={centerX-9} y={'30%'} fill='black' textAnchor={x > cx ? 'end' : 'start'} dominantBaseline='central'>
          {name}
        </text>
        <text display={name === 'Children'? '' : 'none'} fontFamily={'Nunito Sans'} fontSize={11} fontWeight={300} x={centerX+5} y={'30%'} fill='black' textAnchor={x > cx ? 'end' : 'start'} dominantBaseline='central'>
          {name}
        </text>
        <text display={index === 0 ? '' : 'none'} fontFamily={'Nunito Sans'} fontSize={11} fontWeight={600} x={centerXtitle} y={'82%'} fill='black' textAnchor={'middle'} dominantBaseline='central'>
          {title}
        </text>
      </g>
    );
  };

  render() {
    const { age, gender, series } = this.props;
    const [MEN_C1, WOMEN_C1, CHILDREN_C1, ADULT_C1, SENIOR_C1] = ['#6089c4', '#d77da5', '#7ea84e', '#ffd000', '#643d23'];

    let [dataPoints, currentMonthIndex, genderExists, ageExists] = [0, 0, false, false];
    try {
      for (let ser in series) {
        if (series[ser].data.length > dataPoints) {
          dataPoints = series[ser].data.length;
        }
      }

      if (dataPoints > 0) currentMonthIndex = dataPoints - 1;
      if (series[0]?.data[currentMonthIndex]?.genderPie) genderExists = true;
      if (series[0]?.data[currentMonthIndex]?.agePie) ageExists = true;
    } catch (err) { }

    return (
      <Grid container style={{backgroundColor:'' }}>

        {(dataPoints > 0 && ((gender && genderExists) || (age && ageExists))) &&
          // <ResponsiveContainer width={'100%'} height={'100%'} minHeight={0}>
            <PieChart width={GRAPH_WIDTH} height={GRAPH_HEIGHT} style={{ paddingTop: 0,backgroundColor:'' }}>

              {gender &&
                <Pie 
                  dataKey='value'
                  innerRadius={parseInt(GRAPH_WIDTH/4.7)}
                  outerRadius={parseInt(GRAPH_WIDTH/3.7)}
                  cy={'100%'}
                  cx={'49%'}
                  // maxRadius={parseInt(PAGE_WIDTH/2)}
                  startAngle={180}
                  endAngle={0}
                  paddingAngle={3}
                  label={this.renderCustomizedLabel}
                  labelLine={false}
                  isAnimationActive={false}
                  // data={(typeof s.genderAvg !== 'undefined' && typeof s.genderAvg.current.avgDaily !== 'undefined') ? s.genderAvg.current.avgDaily : []}
                  data={series[0].data[currentMonthIndex]?.genderPie}>

                  <Cell
                    fill={WOMEN_C1}
                    key={'cell-0'}
                    name={'Female'} />

                  <Cell
                    fill={MEN_C1}
                    key={'cell-1'}
                    name={'Male'} />
                </Pie>
              }

              {age &&
                <Pie
                  dataKey='value'
                  // innerRadius={75}
                  // outerRadius={95}
                  innerRadius={parseInt(GRAPH_WIDTH/4.7)}
                  outerRadius={parseInt(GRAPH_WIDTH/3.7)}
                  cy={'100%'}
                  cx={'49%'}
                  // maxRadius={200}
                  startAngle={180}
                  endAngle={0}
                  paddingAngle={3}
                  label={this.renderCustomizedLabelAge}
                  labelLine={false}
                  isAnimationActive={false}
                  // data={(typeof s.genderAvg !== 'undefined' && typeof s.genderAvg.current.avgDaily !== 'undefined') ? s.genderAvg.current.avgDaily : []}
                  data={series[0].data[currentMonthIndex]?.agePie}>

                  <Cell
                    fill={CHILDREN_C1}
                    key={'cell-0'}
                    name={'Children'} />

                  <Cell
                    fill={ADULT_C1}
                    key={'cell-1'}
                    name={'Adults'} />

                  <Cell
                    fill={SENIOR_C1}
                    key={'cell-2'}
                    name={'Seniors'} />
                </Pie>
              }
            </PieChart>
          // </ResponsiveContainer>
        }
      </Grid>
    )
  }
}

export default MonthlyAveragePie;
