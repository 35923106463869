import React, { PureComponent } from 'react';
import axios from 'axios';
import { parse } from 'query-string';

import config from '../../utils/config';
import exterosLogo from './exteros_blue.png';

import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  Typography
} from '@material-ui/core';

const errors = {
  default: 'Something went wrong, please try again.',
  email: 'Email could not be verified, please make sure you copied the url correctly.'
}

class Email extends PureComponent {
  constructor() {
    super();

    this.state = {
      emailAddress: '',
      emailPrefs: {},
      emailToken: parse(window.location.search).token || '',
      errorMessage: '',
      logo: exterosLogo,
      showSuccess: false
    };
  }

  componentDidMount() {
    document.title = 'Email Preferences - Exteros';
    this.getEmailPrefs();
  }

  getEmailPrefs = async () => {
    try {
      if (!this.state.emailToken) {
        return this.setState({
          showSuccess: false,
          errorMessage: 'Invalid url, please make sure you copied the url correctly.'
        });
      }

      const response = await axios.post(config.api.url + '/v2/ac/email-pref', { token: this.state.emailToken });

      if (response.data.success) {
        this.setState({
          emailAddress: response.data.email,
          emailPrefs: response.data.emailPrefs
        });
      }
    } catch (error) {
      this.setState({
        showSuccess: false,
        errorMessage: ((((error || {}).response || {}).data || {}).status === 404) ? errors.email : errors.default
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      emailPrefs: {
        ...this.state.emailPrefs,
        [e.target.name]: e.target.checked
      }
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { emailToken, emailPrefs } = this.state;

    if (!emailToken) {
      return this.setState({
        showSuccess: false,
        errorMessage: errors.email
      });
    }

    this.setState({
      showSuccess: false,
      errorMessage: ''
    });

    try {
      const response = await axios.put(config.api.url + '/v2/ac/update-email-pref', { token: this.state.emailToken, emailPrefs: emailPrefs });

      if (response.data.success) this.setState({ showSuccess: true });
    } catch (error) {
      this.setState({
        showSuccess: false,
        errorMessage: ((((error || {}).response || {}).data || {}).status === 404) ? errors.email : errors.default
      });
    }
  };

  render() {
    const { emailAddress, emailPrefs, errorMessage, logo, showSuccess } = this.state;

    return (
      <Grid container style={{ marginTop:"25vh" }} direction="row" justifyContent="center" alignItems="baseline">
        <FormControl>
          <img alt="Logo" src={logo} style={{ marginBottom:"2vw", width:"25vw", height:"100%", minWidth:"280px" }}/>
          <FormLabel>
            <div>
              <Typography variant="h5">Manage your Exteros email preferences:</Typography>
            </div>
          </FormLabel>
          <br />
          { emailPrefs && Object.keys(emailPrefs).length > 0 && emailPrefs.constructor === Object &&
            <FormGroup>
              {Object.entries(emailPrefs).map(([key, value]) => {
                return (
                  <FormControlLabel
                    key={key}
                    style={{ display:"table", marginBottom:"10px" }}
                    control={
                      <div style={{ display:"table-cell" }}>
                        <Checkbox
                          checked={value}
                          color="primary"
                          name={key}
                          onChange={this.handleChange}
                        />
                      </div>
                    }
                    label={
                      <div>
                        <Typography variant="subtitle1" color="primary">{key}</Typography>
                        <Typography variant="subtitle2" color="textSecondary">Monthly traffic summaries by location.</Typography>
                      </div>
                    }
                  />
                )
              })}
            </FormGroup>
          }
          <FormHelperText><br />Email Address: {emailAddress}</FormHelperText>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
            style={{ borderRadius:"3px", letterSpacing:"1.5px", marginTop:"20px" }}
            className="btn btn-large waves-effect waves-light hoverable accent-3"
            onClick={this.handleSubmit}
          >
            Save
          </Button>
          <div>
          {(showSuccess || errorMessage) && (
            <Typography
              variant="subtitle2"
              color={errorMessage ? "error" : "textPrimary"}
              style={{ width:"25vw", minWidth:"280px", marginTop:"10px" }}
            >
              {errorMessage ? errorMessage : "Your email preferences have been updated."}
            </Typography>
          )}
          </div>
        </FormControl>
      </Grid>
    );
  }
}

export default Email;
