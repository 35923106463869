import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { Bar, XAxis, YAxis, BarChart, Symbols, Surface, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { CSVLink } from 'react-csv';

import ExportButton from '@material-ui/icons/SaveAltRounded';
import { CircularProgress, Grid, IconButton, ImageList, Menu, MenuItem, Typography } from '@material-ui/core';
import { alpha, lighten, withStyles } from '@material-ui/core/styles';

import { saveImage, reduceArray, getColor, getCordinates } from '../../utils/helpers';
import { colorsMen, colorsWomen, colorsChildren, colorsAdults, colorsSeniors, colorsBikes, colorsBuses, colorsCars, colorsTrucks } from '../../utils/colors';

const SIZE = 32;


const styles = theme => ({
  menuItem: {
    // color: theme.palette.primary.main
  },
  gridList: {
    width: '100%',
    height:'100%',
    overflowX:'hidden',
    overflowY:'hidden',
    // height: 100,
    // flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    // transform: 'translateZ(0)',
  },
});

function CustomTooltip({ payload, label, active, multDates,singleLocation, numPlots, calIdx, mode }) {
  if (active && payload) {
    const countMinSize = 6;
    let dataName = []
    let numLines = payload.length;
    let fontAdjust = 12 - numPlots - (numLines * 1.5);
    if (multDates) {
      fontAdjust = fontAdjust / 1.7;
    }

    for (let p = 0; p < numLines; p++) {
      dataName[p] = payload[p].name.split(': ');
    }

    return (
      <div>
        <Grid container alignItems={'flex-end'} style={{ textAlign: 'left', paddingTop: 0, paddingLeft: 1, paddingRight: 0, paddingBottom: Math.max(countMinSize, fontAdjust) / 4, backgroundColor: alpha('#ffffff', 0.2) }}>
          <Typography style={{ fontWeight: 500, fontSize: 'calc(' + Math.max(countMinSize, fontAdjust) + 'px + 4px + 0.5vw)', paddingRight: 5 }}>
            {label}
          </Typography>
        </Grid>
      { payload.map( (p,index) => (
        <Grid key={index} container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ textAlign: 'left', marginBottom: 1 + (Math.max(countMinSize, fontAdjust) / 2) }}>
          <svg style={{ width: '100%', height: 'calc(' + 2 * Math.max(countMinSize, fontAdjust) + 'px + 4px + 1vw)' }}>
            <defs>
              <pattern id="pattern-stripe-tt-dow"
                width="9" height="8" 
                patternUnits="userSpaceOnUse"
                patternTransform="rotate(45)">
                <rect width="7" height="8" transform="translate(0,0)" fill="white"/>
              </pattern>
              <mask id="mask-stripe-tt-dow">
                <rect x="0" y="0" width="100%" height="100%" fill="url(#pattern-stripe-tt-dow)"/>
              </mask>
            </defs>
            <rect fill={alpha(lighten(p.color, 0.4), 0.7)} mask={p.strokeDasharray === '' ? "" : "url(#mask-stripe-tt-dow)"} x="0" y="0" width="100%" height="100%"/>
            <rect fill={alpha(lighten(p.color, 0.4), 0.7)} x="0" y="0" width="100%" height="100%"/>
          </svg>
          <Grid container direction='row' justifyContent='space-between' alignItems='flex-end' style={{ marginTop: 'calc(' + (0 - 2 * Math.max(countMinSize, fontAdjust)) + 'px - 4px - 1vw)', zIndex: 1, paddingTop: Math.max(countMinSize, fontAdjust) / 2, paddingBottom: Math.max(countMinSize, fontAdjust), paddingLeft: Math.max(countMinSize, fontAdjust), paddingRight: Math.max(countMinSize, fontAdjust) }}>
            <Typography style={{ fontWeight: 500, fontSize: 'calc(' + Math.max(countMinSize, fontAdjust) + 'px + 2px + 0.5vw)' }}>
              {mode === 'Conversion' ? `${(p.value).toLocaleString()}%` : (mode === 'Dwell') ? `${(p.value).toLocaleString()}s` : `${Math.round(p.value).toLocaleString()} `}
            </Typography>
            <Typography style={{ display: singleLocation ? '' : '', color: 'white', fontWeight: 500, paddingLeft: 20, fontSize: 'calc(' + Math.max(countMinSize, fontAdjust) + 'px + 2px + 0.5vw)' }}>
              {dataName[index][0]}
            </Typography>
            <Typography style={{ display: (dataName[index].length > 1 && !singleLocation) ? '' : 'none', color: 'white', fontWeight: 500, fontSize: 'calc(' + Math.max(countMinSize, fontAdjust) + 'px + 2px + 0.5vw)', textAlign: 'right' }}>
              {': ' + dataName[index][1]}
            </Typography>
          </Grid>
        </Grid>
      ))}
      </div>
    );
  }

  return null;
}

const CandyBar = (props) => {
  const {    
    x: oX,
    y: oY,
    width: oWidth,
    height: oHeight,
    // value,
    fill
  } = props;
  
  let x = oX;
  let y = oHeight < 0 ? oY + oHeight : oY;
  let width = oWidth;
  let height = Math.abs(oHeight);

  return (
   <rect fill={fill}
       mask='url(#mask-stripe-bar)'
          x={x}
          y={y}
          width={width}
          height={height} />
    );
};

const DAYS = { 1: 'Sunday', 2: 'Monday', 3: 'Tuesday', 4: 'Wednesday', 5: 'Thursday', 6: 'Friday', 7: 'Saturday' };


class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, payload } = this.props;
    return (
      <g transform={`translate(${x},${y}) rotate(0, 0,0)`}>
        <text
          x={0}
          y={0+window.innerWidth/90}
          dy={0}
          textAnchor="middle"
          fill="#666"
          style={{fontSize:'calc(8px + 0.4vw)'}}
        >
          {payload.value.substring(0, 3)}
        </text>
      </g>
    );
  }
}

// const initDashed = (numLocs) => {
//   var dashed = []
//   for (let p = 0; p < numLocs; p++) {
//     dashed.push("");
//   }
//   for (let p = 0; p < numLocs; p++) {
//     dashed.push("10 5");
//   }
//   return dashed;
// }

class ByDayOfWeek extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      series: this.props.series,
      anchorEl: null,
      exportData: [],
      exportHeaders: null,
      dateRange: '',
      // showCopied: false,
      colors: this.props.locationsObject['colors'],
      COMBCOLORS: this.props.locationsObject['COMBCOLORS'],
      // dashed: initDashed(this.props.locationsObject.labelList.length),
      labelList: this.props.locationsObject['labelList'],
      count: this.props.count,
      gender: this.props.gender,
      age: this.props.age,
      multDates: this.props.multDates,
      numPlots: this.props.numPlots,
      CELLWIDTH: this.props.CELLWIDTH,
    };

    this.currentChart = React.createRef();
  }

    static getDerivedStateFromProps(nextProps, prevState) {
      // do things with nextProps.someProp and prevState.cachedSomeProp
      if (nextProps.series !== prevState.series){
        return {
          series: nextProps.series,
          multDates: nextProps.multDates,
          numPlots: nextProps.numPlots,
        };
      }
      return {};
    }

    // getLine(idx) {
    //   if (this.props.multDates) {
    //     return this.state.dashed[idx];
    //   } else {
    //     return '';
    //   }
    // }

    getStroke() {
      if (this.state.multDates) {
        return 5;
      } else {
        return '';
      }
    }


    renderIcon(data) {
      const { inactiveColor } = this.props;
      const halfSize = SIZE / 2;
      const sixthSize = SIZE / 6;
      const thirdSize = SIZE / 3;
      const color = data.inactive ? inactiveColor : data.color;
      var newStroke = data.payload.strokeDasharray;
      if (newStroke !== '') {
        newStroke = '7 3'
      }
  
      if (data.type === 'plainline') {
        return (
          <line
            strokeWidth={100}
            fill="none"
            stroke={color}
            strokeDasharray={newStroke}
            x1={-10}
            y1={halfSize-45}
            x2={SIZE+10}
            y2={halfSize}
            className="recharts-legend-icon"
          />
        );
      } if (data.type === 'line') {
        return (
          <path
            strokeWidth={4}
            fill="none"
            stroke={color}
            strokeDasharray={newStroke}
            d={`M0,${halfSize}h${thirdSize}
              A${sixthSize},${sixthSize},0,1,1,${2 * thirdSize},${halfSize}
              H${SIZE}M${2 * thirdSize},${halfSize}
              A${sixthSize},${sixthSize},0,1,1,${thirdSize},${halfSize}`}
            className="recharts-legend-icon"
          />
        );
      } if (data.type === 'rect') {
        return (
          <path
            stroke="none"
            fill={color}
            d={`M0,${SIZE / 8}h${SIZE}v${SIZE * 3 / 4}h${-SIZE}z`}
            className="recharts-legend-icon"
          />
        );
      }
  
      return (
        <Symbols
          fill={color}
          cx={halfSize}
          cy={halfSize}
          size={SIZE}
          sizeType="diameter"
          type={data.type}
        />
      );
    }

    renderLegend = (props) => {
      const { payload } = props;
      const numLines = (this.props.multDates) ? (this.props.numPlots + 1) : (this.props.numPlots);
      const active = payload.filter(bar => !bar.inactive);

      let ICONSIZE = 24;
      const viewBox = { x: 0, y: 0, width: ICONSIZE, height: ICONSIZE };
      ICONSIZE = (4.5  * Math.sqrt(window.innerWidth / 100)) - (numLines * 0.5);
      if (this.props.multDates) {
        ICONSIZE = ICONSIZE / 1.3;
      }
      let fontAdjust = ICONSIZE;

      return (
        <ul className='recharts-default-legend' style={{ position: 'absolute', width: '25vw', top: 0, right: 0 }}>
        { active.map( (entry, index) => (
          <li key={`item-${index}-${entry.dataKey}`} style={{ display: entry.inactive ? 'none' : 'inline', textAlign: 'left' }}>
            <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start' style={{ marginTop: ICONSIZE / 3, display: '' }}>
              <Surface xs={1} width={ICONSIZE * 2} height={ICONSIZE * 1.1} viewBox={viewBox} style={{ display: '', paddingRight: fontAdjust / 2 }}>
                {this.renderIcon(entry)}
              </Surface>
              <Grid container xs item style={{ display: '', paddingRight: fontAdjust / 2 }}>
                <Grid style={{ display: 'flex' }}>
                  <Typography style={{ paddingLeft: fontAdjust / 2, fontSize: fontAdjust, backgroundColor: alpha('#ffffff', 0.2) }} className='recharts-legend-item-text'>
                    {entry.value} 
                  </Typography>
                </Grid>  
              </Grid>
            </Grid>
          </li>
        ))}
        </ul>
      );
    }

    clickExport = (event) => {
      this.setState({ anchorEl: event.currentTarget });
    }

    handleClose = () => {
      this.setState({ anchorEl: null });
    }

    exportData = (activeData) => () => {
      let exportData = [];

      for (let s = 0; s < activeData.length; s++) {
        const { lat, lon } = (activeData[s].data.length > 0) ? getCordinates(activeData[s].locationLabels, this.props.locationsObject) : { lat: '', lon: '' };

        let partData = activeData[s].data;

        for (let d = 0; d < partData.length; d++) {
          exportData.push({});
          let curIdx = exportData.length - 1;
          let tempObj = {};

          tempObj['Location(s)'] = activeData[s].locationLabels ?? '';
          tempObj['Date Range'] = activeData[s].dateLabel ?? '';
          tempObj['Day of Week'] = DAYS[partData[d]._id?.dayOfWeek] ?? partData[d].dayOfWeekName;
          tempObj['latitude'] = lat;
          tempObj['longitude'] = lon;

          if (this.props.mode !== 'Conversion') {
            if (this.state.count) { tempObj['Count'] = partData[d]?.count?.toFixed(0) ?? 0 };
            if (this.state.gender) { tempObj['Male'] = partData[d]?.men?.toFixed(0) ?? 0; tempObj['Female'] = partData[d]?.women?.toFixed(0) ?? 0; };
            if (this.state.age) { tempObj['Adults'] = partData[d]?.adults?.toFixed(0) ?? 0; tempObj['Children'] = partData[d]?.children?.toFixed(0) ?? 0; tempObj['Seniors'] = partData[d]?.seniors?.toFixed(0) ?? 0; };
          }
          else {
            if (this.state.count) { tempObj['Count'] = partData[d]?.count ?? 0 };
            if (this.state.gender) { tempObj['Male'] = partData[d]?.men ?? 0; tempObj['Female'] = partData[d]?.women ?? 0; };
            if (this.state.age) { tempObj['Adults'] = partData[d]?.adults ?? 0; tempObj['Children'] = partData[d]?.children ?? 0; tempObj['Seniors'] = partData[d]?.seniors ?? 0; };
          }

          exportData[curIdx] = tempObj;
        }
      }

      this.setState({
        exportData: exportData, dateRange: activeData[0]?.dateLabel ?? '', anchorEl: null
      });
    }

    downloadImage = async (format) => {
      try {
        const fileObj = {
          chartName: 'ExterosChart_ByDayOfWeek_',
          dateLabel: this.state.series[0].dateLabel,
          count: this.state.count,
          gender: this.state.gender,
          age: this.state.age,
          fileType: format
        }

        saveImage(this.currentChart.current, fileObj, false);
      } catch (err) {
        // notify user of error
      } finally {
        this.handleClose();
      }
    }

    render() {
      const { age, classes, gender, mode, multDates, openTab, vehicles, windowHeight, windowWidth } = this.props;
      const yAxisFontSize = 'calc('.concat((8 - (Math.round(this.props.numPlots / 2))), 'px + 0.4vw)');

      let [dataPoints, secondDateCardIndex] = [0, 0];
      try { secondDateCardIndex = this.state.series.length - 1; }
      catch (e) {}

      let chartArray = reduceArray(this.state.series, this.state.numPlots, this.state.multDates, secondDateCardIndex);
      try {
        for (let ser in chartArray) {
          if (chartArray[ser].data.length > dataPoints) {
            dataPoints = chartArray[ser].data.length;
          }
        }
      } catch (err) {}

      return (
        <Grid style={{ width: '100%', backgroundColor: 'white', height: (windowWidth < this.props.CELLWIDTH && this.props.numPlots <= 2) ? '40vh' : '60vh' }}>
          <Grid container direction='row' justifyContent='space-between' alignItems='flex-start' style={{ height: '5vh', width: '100%', backgroundColor: 'white' }}>
            <Grid xs item style={{ marginTop: '0.75vw', marginLeft: '1.5vw', marginBottom: 0 }}>
              <Grid container alignItems='flex-end'>
                <Typography align='left' style={{ cursor: 'pointer', paddingTop: '0px', paddingBottom: '0px', fontSize: 'calc(12px + 0.6vw)' }}>
                  {this.props.mode === 'Conversion'
                    ? 'Capture by Day of Week'
                    : this.props.mode === 'Dwell'
                      ? 'Avg Dwell by Day of Week'
                      : (this.props.occupancy)
                        ? 'Peak Occupancy'
                        : 'Traffic by Day of Week'
                  }
                </Typography>
              </Grid>
            </Grid>
            <Grid item style={{ marginTop: '1.5vh' }}>
              <Grid container direction='row' alignItems='center'>
              { gender && !multDates && !vehicles &&
                <Grid container direction='row' alignItems='center' style={{ marginTop: 0 }}>
                  <Grid style={{ display: 'inline', marginRight: 'calc(2px + 0.1vw)', width: 'calc(8px + 0.6vw)', height: 'calc(8px + 0.6vw)', backgroundColor: colorsWomen[0] }} />
                  <Typography style={{ fontSize: 'calc(8px + 0.6vw)', display: 'inline' }}>Female</Typography>

                  <Grid style={{ display: 'inline', marginLeft: 'calc(8px + 0.6vw)', marginRight: 'calc(4px + 0.2vw)', width: 'calc(8px + 0.6vw)', height: 'calc(8px + 0.6vw)', backgroundColor: colorsMen[0] }} />
                  <Typography style={{ fontSize: 'calc(8px + 0.6vw)', display: 'inline' }}>Male</Typography>
                </Grid>
              }
              { age && !multDates && !vehicles &&
                <Grid container direction='row' alignItems='center' style={{ marginTop: '0.5vh' }}>
                  <Grid style={{ display: 'inline', marginRight: 'calc(4px + 0.2vw)', width: 'calc(8px + 0.4vw)', height: 'calc(8px + 0.4vw)', backgroundColor: colorsChildren[0] }} />
                  <Typography style={{ fontSize: 'calc(8px + 0.4vw)', display: 'inline' }}>Children</Typography>

                  <Grid style={{ display: 'inline', marginLeft: 'calc(8px + 0.4vw)', marginRight: 'calc(4px + 0.2vw)', width: 'calc(8px + 0.4vw)', height: 'calc(8px + 0.4vw)', backgroundColor: colorsAdults[0] }} />
                  <Typography style={{ fontSize: 'calc(8px + 0.4vw)', display: 'inline' }}>Adults</Typography>

                  <Grid style={{ display: 'inline', marginLeft: 'calc(8px + 0.4vw)', marginRight: 'calc(4px + 0.2vw)', width: 'calc(8px + 0.4vw)', height: 'calc(8px + 0.4vw)', backgroundColor: colorsSeniors[0] }} />
                  <Typography style={{ fontSize: 'calc(8px + 0.4vw)', display: 'inline' }}>Seniors</Typography>
                </Grid>
              }
              {vehicles && gender && !multDates &&
                <Grid container direction='row' alignItems='center' style={{ marginTop: '0.5vh' }}>
                  <Grid style={{ display: 'inline', marginRight: 'calc(4px + 0.2vw)', width: 'calc(8px + 0.4vw)', height: 'calc(8px + 0.4vw)', backgroundColor: colorsBikes[0] }} />
                  <Typography style={{ fontSize: 'calc(8px + 0.4vw)', display: 'inline' }}>Bikes</Typography>

                  <Grid style={{ display: 'inline', marginLeft: 'calc(8px + 0.4vw)', marginRight: 'calc(4px + 0.2vw)', width: 'calc(8px + 0.4vw)', height: 'calc(8px + 0.4vw)', backgroundColor: colorsBuses[0] }} />
                  <Typography style={{ fontSize: 'calc(8px + 0.4vw)', display: 'inline' }}>Buses</Typography>

                  <Grid style={{ display: 'inline', marginLeft: 'calc(8px + 0.4vw)', marginRight: 'calc(4px + 0.2vw)', width: 'calc(8px + 0.4vw)', height: 'calc(8px + 0.4vw)', backgroundColor: colorsCars[0] }} />
                  <Typography style={{ fontSize: 'calc(8px + 0.4vw)', display: 'inline' }}>Cars</Typography>

                  <Grid style={{ display: 'inline', marginLeft: 'calc(8px + 0.4vw)', marginRight: 'calc(4px + 0.2vw)', width: 'calc(8px + 0.4vw)', height: 'calc(8px + 0.4vw)', backgroundColor: colorsTrucks[0] }} />
                  <Typography style={{ fontSize: 'calc(8px + 0.4vw)', display: 'inline' }}>Trucks</Typography>
                </Grid>
              }
              </Grid>
            </Grid>

            <Grid item xs={2}>
              <Grid container alignItems='flex-start' justifyContent='flex-end'>
                <Grid item>
                  <IconButton className={classes.exportBtn} onClick={this.clickExport}>
                    <ExportButton />
                  </IconButton>
                  <Menu
                    id='export-menu'
                    anchorEl={this.state.anchorEl}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.handleClose}>
                    <MenuItem onClick={this.exportData(chartArray)} className={classes.menuItem} disabled={(dataPoints > 0 && !vehicles) ? false : true}>
                      <CSVLink
                        data={this.state.exportData}
                        filename={'ExterosData_ByDayOfWeek_'.concat(this.state.dateRange, '_', this.state.count ? 'Counts' : '', this.state.gender ? 'Gender' : '', this.state.age ? 'Age' : '', '.csv').replace(/\s/g, '')}
                        style={{ textDecoration: 'none', color: 'inherit' }}>
                        CSV (.csv)
                      </CSVLink>
                    </MenuItem>
                    <MenuItem className={classes.menuItem} onClick={() => this.downloadImage('jpeg')}>Image (.jpeg)</MenuItem>
                    <MenuItem className={classes.menuItem} onClick={() => this.downloadImage('svg')}>Image (.svg)</MenuItem>
                  </Menu>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item container direction='column' ref={this.currentChart} justifyContent='flex-start' alignItems='center' style={{ position: 'relative', width: '100%', backgroundColor: 'white', height: (windowWidth < this.props.CELLWIDTH && this.props.numPlots <= 2) ? '34vh' : '54vh' }}>
            <Typography style={{ fontSize: 'calc(8px + 0.4vw)', visibility: dataPoints > 0 ? 'visible' : 'hidden', position: 'absolute', top: (windowWidth < this.state.CELLWIDTH && this.state.numPlots <= 2) ? windowHeight / 7.4 : windowHeight / 4.2, left: 0, display: 'block', transformOrigin: 'center', transform: 'translate(0px,0px) rotate(-90deg)' }}>
              {mode === 'Conversion' ? 'Percent' : (mode === 'Dwell') ? 'Seconds' : 'Counts'}
            </Typography>
            <Typography style={{ fontSize: 'calc(8px + 0.4vw)', visibility: dataPoints > 0 ? 'visible' : 'hidden', position: 'absolute', left: '50%', bottom: '0vh', display: 'block' }}>
              Day of Week
            </Typography>
            {/* <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={this.state.showCopied}
              autoHideDuration={1500}
              transitionDuration={{enter:600,exit:1000}}
              onClose={this.messageClose.bind(this)}
              ContentProps={{
                'aria-describedby': 'message-id',
              }}
              message={<span id="message-id">Chart Copied to Clipboard</span>}
            /> */}

            {/* <Grid container ref="currentChart" style={{display:'flex'}}> */}
            {/* <div style={{display:'flex'}} > */}
            {/* <Typography align='left' style={{marginBottom:'-5vh',width:'100vw',paddingLeft:'calc(5px + 2vw)',paddingTop:'2vh',fontSize:'calc(12px + 0.6vw)'}}> */}
          { dataPoints === 0 &&
            <Grid container justifyContent='space-around' alignItems='center'>
              <CircularProgress style={{ position: 'absolute', top: windowHeight / 4.2, left: windowWidth / 2.4 }}/>
            </Grid>
          }
          { dataPoints > 0 && (multDates || (!gender && !age)) &&
            <Grid container item className={classes.gridList} style={{ height: '100%', backgroundColor: 'white', marginTop: '0px', marginBottom: 0, marginLeft: '0px' }}>
              <ResponsiveContainer width={'100%'} height={'100%'} maxHeight={'100vh'} minHeight={50}>
                <BarChart barGap={0} data={chartArray} style={{ cursor: 'pointer' }}
                  margin={{
                    left: windowWidth < 600 ? -windowWidth * 0.02 : windowWidth * 0.01,
                    bottom: windowWidth < 600 ? windowHeight * 0.01 : windowHeight * 0.05,
                    right: windowWidth < 600 ? windowWidth * 0.08 : windowWidth * 0.04
                }}>
                  <XAxis style={{ fontSize: 'calc(8px + 0.4vw)' }} data={chartArray} dataKey='dayOfWeekName' type='category' interval={0} allowDuplicatedCategory={false} tick={<CustomizedAxisTick/>}/>
                  <YAxis
                    style={{ fontSize: 'calc(8px + 0.4vw)' }}
                    dx={0}
                    tickCount={6}
                    domain={[0, dataMax => (Math.round((dataMax * 1.5) / parseInt('1'.padEnd(Math.log(dataMax * 1) * Math.LOG10E + 1 | 0, '0'))) * parseInt('1'.padEnd(Math.log(dataMax * 1) * Math.LOG10E + 1 | 0, '0')))]}
                    tickFormatter={
                      mode === 'Conversion'
                        ? (tickItem) => { return ((tickItem / 1).toLocaleString() + ' %') }
                        : (tickItem) => { return (tickItem >= 1000 ? (tickItem / 1000).toLocaleString() + ' k' : tickItem.toLocaleString()) }
                    }
                  >
                    {/* <Label value= 'Counts' position= 'left' angle= {-90} dx={30-(windowWidth/60)} fontSize={'calc(10px + 0.4vw)'} /> */}
                  </YAxis>

                  <pattern id="pattern-stripe-bar"
                    width="8" height="8"
                    patternUnits="userSpaceOnUse"
                    patternTransform="rotate(45)">
                    <rect width="6" height="8" transform="translate(0,0)" fill="white"/>
                  </pattern>
                  <mask id="mask-stripe-bar">
                    <rect x="0" y="0" width="100%" height="100%" fill="url(#pattern-stripe-bar)"/>
                  </mask>

                  <Tooltip position={{ y: 0 }} content={<CustomTooltip multDates={multDates} singleLocation={false} numPlots={1} calIdx={0} mode={mode}/>}/>
            { openTab === 0 &&
              chartArray.map( (s, idx) => (
                [
                  <Bar isAnimationActive={true} data={s.data} dataKey='count' hide={!this.props.count || s.data.length === 0}
                    stroke={getColor(s.locationLabels, idx, this.props.locationsObject, chartArray)}
                    shape={parseInt(s.name.substr(s.name.length - 3)[0]) > 1 ? <CandyBar fill={getColor(s.locationLabels, idx, this.props.locationsObject, chartArray)} /> : ''}
                    fill={getColor(s.locationLabels, idx, this.props.locationsObject, chartArray)}
                    strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''}
                    name={s.locationLabels.join(', ') + (multDates ? (' (' + s.dateLabel + ')') : '')} />,
                ]
              ))
            }
            { openTab === 1 && !vehicles &&
              chartArray.map( (s, idx) => (
                [
                  <Bar isAnimationActive={true} data={s.data} dataKey='women' hide={!gender || s.data.length === 0} stroke={colorsWomen[idx]}
                    shape={parseInt(s.name.substr(s.name.length - 3)[0]) > 1 ? <CandyBar fill={colorsWomen[idx]} /> : ''} fill={colorsWomen[idx]}
                    strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''}
                    name={'Female: ' + s.locationLabels.join(', ') + (multDates ? (' (' + s.dateLabel + ')') : '')} />,

                  <Bar isAnimationActive={true} data={s.data} dataKey='men' hide={!gender || s.data.length === 0} stroke={colorsMen[idx]}
                    shape={parseInt(s.name.substr(s.name.length - 3)[0]) > 1 ? <CandyBar fill={colorsMen[idx]} /> : ''} fill={colorsMen[idx]}
                    strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''}
                    name={'Male: ' + s.locationLabels.join(', ') + (multDates ? (' (' + s.dateLabel + ')') : '')} />,
                ]
              ))
            }
            { openTab === 1 && vehicles &&
              chartArray.map( (s, idx) => (
                [
                  <Bar isAnimationActive={true} data={s.data} dataKey='bikes' hide={!gender || s.data.length === 0} stroke={colorsBikes[idx]}
                    shape={parseInt(s.name.substr(s.name.length - 3)[0]) > 1 ? <CandyBar fill={colorsBikes[idx]} /> : ''} fill={colorsBikes[idx]}
                    strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''}
                    name={'Bikes: ' + s.locationLabels.join(', ') + (multDates ? (' (' + s.dateLabel + ')') : '')} />,

                  <Bar isAnimationActive={true} data={s.data} dataKey='buses' hide={!gender || s.data.length === 0} stroke={colorsBuses[idx]}
                    shape={parseInt(s.name.substr(s.name.length - 3)[0]) > 1 ? <CandyBar fill={colorsBuses[idx]} /> : ''} fill={colorsBuses[idx]}
                    strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''}
                    name={'Buses: ' + s.locationLabels.join(', ') + (multDates ? (' (' + s.dateLabel + ')') : '')} />,

                  <Bar isAnimationActive={true} data={s.data} dataKey='cars' hide={!gender || s.data.length === 0} stroke={colorsCars[idx]}
                    shape={parseInt(s.name.substr(s.name.length - 3)[0]) > 1 ? <CandyBar fill={colorsCars[idx]} /> : ''} fill={colorsCars[idx]}
                    strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''}
                    name={'Cars: ' + s.locationLabels.join(', ') + (multDates ? (' (' + s.dateLabel + ')') : '')} />,

                  <Bar isAnimationActive={true} data={s.data} dataKey='trucks' hide={!gender || s.data.length === 0} stroke={colorsTrucks[idx]}
                    shape={parseInt(s.name.substr(s.name.length - 3)[0]) > 1 ? <CandyBar fill={colorsTrucks[idx]} /> : ''} fill={colorsTrucks[idx]}
                    strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''}
                    name={'Trucks: ' + s.locationLabels.join(', ') + (multDates ? (' (' + s.dateLabel + ')') : '')} />,
                ]
              ))
            }
            { openTab === 2 && !vehicles &&
              chartArray.map( (s, idx) => (
                [
                  <Bar isAnimationActive={true} data={s.data} dataKey='children' hide={!age || s.data.length === 0} stroke={colorsChildren[idx]}
                    shape={parseInt(s.name.substr(s.name.length - 3)[0]) > 1 ? <CandyBar fill={colorsChildren[idx]} /> : ''} fill={colorsChildren[idx]}
                    strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''}
                    name={'Children: ' + s.locationLabels.join(', ') + (multDates ? (' (' + s.dateLabel + ')') : '')} />,

                  <Bar isAnimationActive={true} data={s.data} dataKey='adults' hide={!age || s.data.length === 0} stroke={colorsAdults[idx]}
                    shape={parseInt(s.name.substr(s.name.length - 3)[0]) > 1 ? <CandyBar fill={colorsAdults[idx]} /> : ''} fill={colorsAdults[idx]}
                    strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''}
                    name={'Adults: ' + s.locationLabels.join(', ') + (multDates ? (' (' + s.dateLabel + ')') : '')} />,

                  <Bar isAnimationActive={true} data={s.data} dataKey='seniors' hide={!age || s.data.length === 0} stroke={colorsSeniors[idx]}
                    shape={parseInt(s.name.substr(s.name.length - 3)[0]) > 1 ? <CandyBar fill={colorsSeniors[idx]} /> : ''} fill={colorsSeniors[idx]}
                    strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''}
                    name={'Seniors: ' + s.locationLabels.join(', ') + (multDates ? (' (' + s.dateLabel + ')') : '')} />,
                ]
              ))
            }
                  <Legend align='right' verticalAlign='top' layout='vertical' iconType='plainline' wrapperStyle={{ right: 0, top:0 }} content={this.renderLegend}/>
                </BarChart>
              </ResponsiveContainer>
            </Grid>
          }
          { dataPoints > 0 && (gender || age) && !multDates  &&
            <ImageList className={classes.gridList} cols={this.props.numPlots > 1 ? 2 : 1} style={{ width: '97%', backgroundColor: 'white', marginTop: '0px', marginBottom: '0px', paddingLeft: '2.5vw' }}>
          { chartArray.map( (s, idx) => (
            [
              <Grid container style={{ paddingLeft: '100', height: (windowWidth < this.props.CELLWIDTH && this.props.numPlots <= 2) ? (windowHeight / Math.round(this.props.numPlots / 2)) * 0.3 : (windowHeight / Math.round(this.props.numPlots / 2)) * 0.44, backgroundColor: 'white', display: s.data.length > 0 ? '' : 'none' }}>
                <Grid style={{ width: '100%', marginBottom: '1vh', marginRight: '0.5vw', paddingLeft: '1.5vh' }}>
                  <Typography style={{ backgroundColor: alpha(getColor(s.locationLabels, idx, this.props.locationsObject, chartArray), 0.4, 0.9), fontWeight: 700, fontSize: 'calc(6px + 0.4vw)' }}>
                    {s.locationLabels.join(', ')}
                  </Typography>
                </Grid>
                <ResponsiveContainer container width={'100%'} maxHeight={'100vh'} height={'100%'} minHeight={50}>
                  <BarChart barGap={0} syncId='sync' data={this.state.series} style={{ cursor: 'pointer' }}
                    margin={{
                      left: windowWidth < 600 ? -windowWidth * 0.07 : -windowWidth * 0.01,
                      bottom: windowWidth < 600 ? windowWidth * 0.0 : windowHeight * 0.0,
                      right: windowWidth < 600 ? - windowWidth * 0.0 : windowWidth * 0.02
                    }}>
                    <XAxis style={{ fontSize: yAxisFontSize }} dataKey='dayOfWeekName' type='category' xAxisId={0} interval={0} allowDuplicatedCategory={false} tick={<CustomizedAxisTick/>}/>
                    <YAxis
                      style={{ fontSize: yAxisFontSize }}
                      dx={0}
                      tickCount={6}
                      domain={[0, dataMax => (Math.round((dataMax * 1.5) / parseInt('1'.padEnd(Math.log(dataMax * 1) * Math.LOG10E + 1 | 0, '0'))) * parseInt('1'.padEnd(Math.log(dataMax * 1) * Math.LOG10E + 1 | 0, '0')))]}
                      tickFormatter={
                        mode === 'Conversion'
                          ? (tickItem) => { return ((tickItem / 1).toLocaleString() + ' %') }
                          : (tickItem) => { return (tickItem >= 1000 ? (tickItem / 1000).toLocaleString() + ' k' : tickItem.toLocaleString()) }
                      }
                    >
                      {/* <Label value= 'Counts' position= 'left' angle= {-90} dx={30-(windowWidth/60)} fontSize={'calc(10px + 0.4vw)'} /> */}
                    </YAxis>
                    <Tooltip position={{ y: 0 }}  content={<CustomTooltip multDates={multDates} singleLocation={true} numPlots={this.props.numPlots} calIdx={idx} mode={mode}/>}/>

                    {gender && !vehicles &&
                      [
                        <Bar key={'female' + idx} isAnimationActive={true} data={s.data} dataKey='women' hide={!gender || s.data.length === 0} fill={colorsWomen[idx]}
                          stroke={colorsWomen[idx]} strokeWidth={this.getStroke()} strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''}
                          name={'Female: ' + s.locationLabels.join(', ') + (multDates ? (' (' + s.dateLabel + ')') : '')} xAxisId={(idx >= secondDateCardIndex) ? 0 : 0} />,

                        <Bar key={'male' + idx} isAnimationActive={true} data={s.data} dataKey='men' hide={!gender || s.data.length === 0} fill={colorsMen[idx]}
                          stroke={colorsMen[idx]} strokeWidth={this.getStroke()} strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''}
                          name={'Male: ' + s.locationLabels.join(', ') + (multDates ? (' (' + s.dateLabel + ')') : '')} xAxisId={(idx >= secondDateCardIndex) ? 0 : 0} />,
                      ]
                    }

                    {age && !vehicles &&
                      [
                        <Bar key={'child' + idx} isAnimationActive={true} data={s.data} dataKey='children' hide={!age || s.data.length === 0} fill={colorsChildren[idx]}
                          stroke={colorsChildren[idx]} strokeWidth={this.getStroke()} strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''}
                          name={'Children: ' + s.locationLabels.join(', ') + (multDates ? (' (' + s.dateLabel + ')') : '')} xAxisId={(idx >= secondDateCardIndex) ? 0 : 0} />,

                        <Bar key={'adult' + idx} isAnimationActive={true} data={s.data} dataKey='adults' hide={!age || s.data.length === 0} fill={colorsAdults[idx]}
                          stroke={colorsAdults[idx]} strokeWidth={this.getStroke()} strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''}
                          name={'Adults: ' + s.locationLabels.join(', ') + (multDates ? (' (' + s.dateLabel + ')') : '')} xAxisId={(idx >= secondDateCardIndex) ? 0 : 0} />,

                        <Bar key={'senior' + idx} isAnimationActive={true} data={s.data} dataKey='seniors' hide={!age || s.data.length === 0} fill={colorsSeniors[idx]}
                          stroke={colorsSeniors[idx]} strokeWidth={this.getStroke()} strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''}
                          name={'Seniors: ' + s.locationLabels.join(', ') + (multDates ? (' (' + s.dateLabel + ')') : '')} xAxisId={(idx >= secondDateCardIndex) ? 0 : 0} />,
                      ]
                    }

                    {vehicles &&
                      [
                        <Bar key={'bike' + idx} isAnimationActive={true} data={s.data} dataKey='bikes' hide={!gender || s.data.length === 0} fill={colorsBikes[idx]}
                          stroke={colorsBikes[idx]} strokeWidth={this.getStroke()} strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''}
                          name={'Bikes: ' + s.locationLabels.join(', ') + (multDates ? (' (' + s.dateLabel + ')') : '')} xAxisId={(idx >= secondDateCardIndex) ? 0 : 0} />,

                        <Bar key={'bus' + idx} isAnimationActive={true} data={s.data} dataKey='buses' hide={!gender || s.data.length === 0} fill={colorsBuses[idx]}
                          stroke={colorsBuses[idx]} strokeWidth={this.getStroke()} strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''}
                          name={'Buses: ' + s.locationLabels.join(', ') + (multDates ? (' (' + s.dateLabel + ')') : '')} xAxisId={(idx >= secondDateCardIndex) ? 0 : 0} />,

                        <Bar key={'car' + idx} isAnimationActive={true} data={s.data} dataKey='cars' hide={!gender || s.data.length === 0} fill={colorsCars[idx]}
                          stroke={colorsCars[idx]} strokeWidth={this.getStroke()} strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''}
                          name={'Cars: ' + s.locationLabels.join(', ') + (multDates ? (' (' + s.dateLabel + ')') : '')} xAxisId={(idx >= secondDateCardIndex) ? 0 : 0} />,

                        <Bar key={'truck' + idx} isAnimationActive={true} data={s.data} dataKey='trucks' hide={!gender || s.data.length === 0} fill={colorsTrucks[idx]}
                          stroke={colorsTrucks[idx]} strokeWidth={this.getStroke()} strokeDasharray={(multDates & idx === (chartArray.length - 1)) ? '10 5' : ''}
                          name={'Trucks: ' + s.locationLabels.join(', ') + (multDates ? (' (' + s.dateLabel + ')') : '')} xAxisId={(idx >= secondDateCardIndex) ? 0 : 0} />,
                      ]
                    }

                  </BarChart>
                </ResponsiveContainer>   
              </Grid>    
            ]
          ))}
            </ImageList>
          }
          </Grid>
        </Grid>
      )
    }
}

export default withRouter(withStyles(styles)(ByDayOfWeek));
