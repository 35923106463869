import axios from 'axios';

import config from '../utils/config';
import { Browser } from '../utils/browser.js';
import { GET_ERRORS, USER_LOADING } from './types';
import { setAccessToken, setRefreshToken, setCurrentUser } from '../utils/services';

const API_URL = config.api.url;

// Register User
// export const registerUser = (userData, history) => dispatch => {
//   axios
//     .post(API_URL + '/v2/user/register', userData)
//     .then(res => history.push('/login'))
//     .catch(err =>
//       dispatch({
//         type: GET_ERRORS,
//         payload: err.response.data
//       })
//     );
// };

// Change Password
export const changePassword = (userData) => dispatch => {
  axios
    .post(API_URL + '/v2/user/change-password', userData)
    .then(res => { 
      dispatch({
        type: GET_ERRORS,
        payload: {}
      })

      return {};
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Forgot Password - Request
// export const forgotPasswordRequest = (email) => dispatch => {
//   axios
//     .post(API_URL + '/v2/user/forgot-password', email)
//     .then(res => { 
//       dispatch({
//         type: GET_ERRORS,
//         payload: {}
//       })

//       return {};
//     })
//     .catch(err =>
//       dispatch({
//         type: GET_ERRORS,
//         payload: err.response.data
//       })
//     );
// };

// Forgot Password - Reset
// export const forgotPasswordReset = (userData) => dispatch => {
//   axios
//     .post(API_URL + '/v2/user/reset-password', userData)
//     .then(res => { 
//       dispatch({
//         type: GET_ERRORS,
//         payload: {}
//       })

//       return {};
//     })
//     .catch(err =>
//       dispatch({
//         type: GET_ERRORS,
//         payload: err.response.data
//       })
//     );
// };

// Login - get user token
export const loginUser = userData => dispatch => {
  try {
    userData.deviceName = (Browser.os === 'Mac') ? ('macOS ' + Browser.name) : (Browser.os + ' ' + Browser.name);
  } catch {
    userData.deviceName = 'Unknown';
  }

  axios
    .post(API_URL + '/v2/user/login', userData)
    .then(response => {
      if (response.status !== 200) {
        // console.log('Looks like there was a problem. Status Code: ' + JSON.stringify(response));
        return dispatch({
          type: GET_ERRORS,
          payload: response.data
        });
      }

      // console.log('login data: ' + JSON.stringify(response.data));
      const {token, refresh} = response.data;
      setAccessToken(token);
      setRefreshToken(refresh);
      dispatch(setCurrentUser(token)); // Set current user
    })
    .catch(error => {
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data
      });
    });
};

// Refresh Access Token - use Refresh token to get new access token
export const refreshAccessToken = () => {
  return axios
    .post(API_URL + '/v2/user/refresh')
    .then(response => {
      const { token, refresh } = response.headers;
      setAccessToken(token);
      setRefreshToken(refresh);

      return token;
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

// User loading
export const setUserLoading = () => {
  return {
    type: USER_LOADING
  };
};
