import React, { Component } from 'react';
import axios from 'axios';

import config from '../../utils/config';
import exterosLogo from './exteros_blue.png';
import plugoutLogo from './plugout_blue.png';

import { Button, FormControl, Grid, TextField } from '@material-ui/core';

const getLogo = (url) => {
  if (url.includes('plugout')) return plugoutLogo;

  return exterosLogo;
}

class ForgotPassword extends Component {
  constructor() {
    super();

    this.state = {
      email: '',
      showSuccess: false,
      errorMessage: '',
      logo: getLogo(window.location.href)
    };
  }

  componentDidMount() {
    document.title = 'Forgot Password - Exteros';
  }

  handleChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { email } = this.state;

    if (email === '') {
      return this.setState({
        showSuccess: false,
        errorMessage: 'Please enter your email to continue.'
      });
    }

    this.setState({
      showSuccess: false,
      errorMessage: ''
    });

    try {
      const response = await axios.post(config.api.url + '/v2/user/forgot-password', { email });

      if (response.data.success) {
        this.setState({
          email: '',
          showSuccess: true
        });
      }
    } catch (error) {
      // potential errors:  empty/invalid email | email not found | server error
      this.setState({
        showSuccess: false,
        errorMessage: ((((error || {}).response || {}).data || {}).message) ? error.response.data.message : 'Something went wrong, please try again.'
      });
    }
  };

  render() {
    const { email, showSuccess, errorMessage } = this.state;

    return (
      <Grid container style={{ marginTop: "25vh" }} direction="row" justifyContent="center" alignItems="baseline" >
        <FormControl>
          <img alt="Logo" src={this.state.logo} style={{ marginBottom:"2vw", width:"25vw", height:"100%", minWidth:"280px" }}/>
          <h2 style={{ marginLeft: 'auto', marginRight: 'auto' }}>Forgot Password</h2>
          <p style={{ width:'25vw', minWidth:'280px' }}>Enter your email and we'll send you a link to reset your password.</p>
          <TextField
            required
            fullWidth
            variant="filled"
            margin="dense"
            id="email"
            type="email"
            label="Email"
            value={email}
            error={errorMessage ? true : false}
            helperText={errorMessage}
            autoFocus={true}
            className={errorMessage ? 'invalid' : ''}
            onChange={this.handleChange}
            onKeyDown={(ev) => {
              if (ev.key === 'Enter') { this.handleSubmit(ev); }
            }}
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
            style={{ borderRadius: "3px", letterSpacing: "1.5px", marginTop: "20px" }}
            className="btn btn-large waves-effect waves-light hoverable accent-3"
            onClick={this.handleSubmit}
          >
            Submit
          </Button>
          <div>
            <p style={{ width:'25vw', minWidth:'280px' }}>
              <a href="/login">Login</a>
            </p>
          </div>
          <div>
          {showSuccess && (
            <p style={{ width:'25vw', minWidth:'280px' }}>
              Almost done - we've just sent you an email with a link to reset your password.
            </p>
          )}
          </div>
        </FormControl>
      </Grid>
    );
  }
}

export default ForgotPassword;
