import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import config from '../../utils/config';

const loginMP = (auth) => {
  if (config.analytics && auth.isAuthenticated === true) {
    window.analytics.identify(auth.user.email, {
      // email: auth.user.email
    });
  }
}

const PrivateRoute = ({ component: Component, auth, ...rest }) => {
  // if user is signed in and Admin, add 'client' as a key prop to the component (only concerned with dashboard), this remounts dashboard if client is changed.
  if (auth.isAuthenticated === true && auth.user?.roles?.includes('user_manager')) {
    return (
      <Route
        {...rest}
        {...loginMP(auth)}
        render={props => (auth.isAuthenticated === true) ? (<Component key={auth.user.client} {...props} />) : (<Redirect to='/login' />)}
      />
    )
  }


  return (
    <Route
      {...rest}
      {...loginMP(auth)}
      render={props => (auth.isAuthenticated === true) ? (<Component {...props} />) : (<Redirect to='/login' />)}
    />
  )
};

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(PrivateRoute);
