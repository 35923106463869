import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import moment from 'moment-timezone';
import omit from 'lodash/omit';

import { Accordion, AccordionDetails, Button, Checkbox, FormControlLabel, FormGroup, Grid, IconButton, Paper, Switch, Tooltip, Typography } from '@material-ui/core';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/CloseRounded';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';

import Responsive from 'react-responsive';
// import { withStylesPropTypes, css } from 'react-with-styles';
import { withStylesPropTypes } from 'react-with-styles';

import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import { DateRangePickerPhrases } from 'react-dates/src/defaultPhrases';
// import DateRangePickerShape from 'react-dates/src/shapes/DateRangePickerShape';
import { START_DATE, END_DATE, HORIZONTAL_ORIENTATION, ANCHOR_LEFT } from 'react-dates/src/constants';
import isSameDay from 'react-dates/src/utils/isSameDay';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';

import 'react-dates/lib/css/_datepicker.css';
import '../../react_dates_overrides.css';

export const Mobile = props => <Responsive {...props} maxWidth={767} />;
export const Default = props => <Responsive {...props} minWidth={768} />;

const styles = ({
  dateCard: {
    backgroundColor: '#f5f5f5',
    position: 'relative',
    paddingTop: 10,
    paddingLeft: 0,
  },
  PresetDateRangePicker_panel: {
    padding: '0 0 18px',
  },
  PresetDateRangePicker_button: {
    position: 'relative',
    height: '100%',
    textAlign: 'center',
    background: 'none',
    border: `2px solid ${'#00313D'}`,
    color: '#00313D',
    padding: '9px 3px',
    marginRight: 3,
    marginTop: 4,
    font: 'inherit',
    fontSize: 'calc(7px + 0.2vw)',
    fontWeight: 700,
    lineHeight: 'normal',
    overflow: 'auto',
    boxSizing: 'border-box',
    cursor: 'pointer',
    // zIndex: 100000,
    // '&:active': {
    //   outline: 0,
    // },
  },
  PresetDateRangePicker_button__selected: {
    color: '#ffffff',
    background: '#00313D',
    '&:hover': {
      color: '#00313D',
    },
  },
  arrow1: {
    width: 0,
    height: '64%',
    border: 'solid',
    // borderColor: '#D3DBDE',
    borderWidth: '0 0 0 calc(2px + 0.11vw)',
    display: 'flex',
    transform: 'rotate(-25deg)',
    position: 'absolute',
    top: '-10%',
    left: '45%',
    bottom: 0,
    right: 0,
    zIndex: 1,
  },
  arrow2: {
    alignItems: 'stretch',
    width: 0,
    height: '63%',
    border: 'solid',
    // borderColor: '#D3DBDE',
    borderWidth: '0 0 0 calc(2px + 0.11vw)',
    display: 'flex',
    transform: 'rotate(25deg)',
    position: 'absolute',
    top: '47%',
    left: '45%',
    bottom: 0,
    right: 0,
    zIndex: 1,
  },
  fillRect: {
    alignItems: 'stretch',
    width: 0,
    border: 'solid',
    borderWidth: '0 0 0 calc(6px + 0.6vw)',
    borderColor: '#f5f5f5',
    height: '100%',
    display: 'flex',
    position: 'absolute',
    top: 0,
    // left: '47%',
    left: '44%',
    bottom: 0,
    right: 0,
    zIndex: 1,
  },
  switch_track: {
    // backgroundColor: '#4d6e77',
  },
  switch_base: {
    // color: '#277175',
    // '&.Mui-disabled': {
    //   color: '#4d6e77'
    // },
    '&.Mui-checked': {
      color: '#277175'
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#277175',
    }
  },
  switch_primary: {
    // '&.Mui-checked': {
    //   color: '#4d6e77',
    // },
    // '&.Mui-checked + .MuiSwitch-track': {
    //   backgroundColor: '#4d6e77',
    // },
  },
});

const propTypes = {
  ...withStylesPropTypes, // css, styles, theme
  // example props for the demo
  autoFocus: PropTypes.bool,
  autoFocusEndDate: PropTypes.bool,
  initialStartDate: momentPropTypes.momentObj,
  initialEndDate: momentPropTypes.momentObj,
  presets: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    start: momentPropTypes.momentObj,
    end: momentPropTypes.momentObj,
  })),
  closeBtn: PropTypes.bool,
  closeDate: PropTypes.func,
  compareDateClick: PropTypes.func,
  name: PropTypes.number,
  onChange: PropTypes.func,
  selectRange: PropTypes.number,
  dateRange: PropTypes.shape({
    end: momentPropTypes.momentObj,
    start: momentPropTypes.momentObj
  }),
  simulated: PropTypes.bool,
  toggleSimulated: PropTypes.func,
  compareActiveDate: PropTypes.bool,
  panelColor: PropTypes.string,
  excludeDates: PropTypes.array,
  excludeDatesChange: PropTypes.func,

  // ...omit(DateRangePickerShape, [
  //   'startDate',
  //   'endDate',
  //   'onDatesChange',
  //   'focusedInput',
  //   'onFocusChange',
  // ]),
};

const defaultProps = {
  // example props for the demo
  autoFocus: false,
  autoFocusEndDate: false,
  initialStartDate: null,
  initialEndDate: null,
  presets: [],
  closeBtn: false,
  closeDate() {},
  compareDateClick() {},
  name: 0,
  onChange() {},
  // selectRange: 0,
  simulated: true,
  toggleSimulated() {},
  compareActiveDate: true,

  // input related props
  startDateId: START_DATE,
  startDatePlaceholderText: 'Start Date',
  endDateId: END_DATE,
  endDatePlaceholderText: 'End Date',
  disabled: false,
  required: false,
  screenReaderInputMessage: '',
  showClearDates: false,
  showDefaultInputIcon: false,
  customInputIcon: null,
  customArrowIcon: null,
  customCloseIcon: null,

  // calendar presentation and interaction related props
  renderMonthText: null,
  orientation: HORIZONTAL_ORIENTATION,
  // orientation: VERTICAL_ORIENTATION,
  anchorDirection: ANCHOR_LEFT,
  // anchorDirection: ANCHOR_RIGHT,
  horizontalMargin: 0,
  withPortal: false,
  withFullScreenPortal: false,
  initialVisibleMonth: null,
  // numberOfMonths: 2,
  keepOpenOnDateSelect: false,
  reopenPickerOnClearDates: false,
  // renderKeyboardShortcutsButton: undefined,
  // renderKeyboardShortcutsPanel: undefined,
  renderMonthElement: null,
  renderCalendarInfo: null,
  isRTL: false,

  // navigation related props
  navPrev: null,
  navNext: null,

  renderNavPrevButton: null,
  renderNavNextButton: null,
  onPrevMonthClick() {},
  onNextMonthClick() {},
  onClose() {},

  // day presentation and interaction related props
  renderDayContents: null,
  minimumNights: 0,
  enableOutsideDays: false,
  isDayBlocked: () => false,
  // isOutsideRange:(day) => day.isAfter(moment().tz('America/New_York')) || day.isBefore(moment('16/01/2018', 'DD/MM/YYYY').tz('America/New_York')) ,
  isDayHighlighted: () => false,
  // startDateOffset: null,

  // internationalization
  // displayFormat: () => moment.localeData().longDateFormat('L'),
  displayFormat: () => "MMM D, YYYY",
  monthFormat: 'MMMM YYYY',
  phrases: DateRangePickerPhrases,

  minDate: null,
  maxDate: null
};

const AccordionSummary = withStyles({
  expanded: {},
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
})(MuiAccordionSummary);

class DateRangePickerWrapper extends PureComponent {
  constructor(props) {
    super(props);

    let focusedInput = null;
    if (props.autoFocus) {
      focusedInput = START_DATE;
    } else if (props.autoFocusEndDate) {
      focusedInput = END_DATE;
    }

    this.state = {
      focusedInput,
      startDate: props.initialStartDate,
      endDate: props.initialEndDate,
      wkendChecked: true,
      wkdayChecked: true,
      customChecked: false,
      monChecked: true,
      tueChecked: true,
      wedChecked: true,
      thuChecked: true,
      friChecked: true,
      satChecked: true,
      sunChecked: true,
      datesChecked: false,
    };

    this.blockFocusChange = false; // when user is selecting dates to exclude from data, stop datecards from changing focus from start to end date

    this.onDatesChange = this.onDatesChange.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
    this.renderDatePresets = this.renderDatePresets.bind(this);
  }

  isOutsideRange = (day) => {
    const { dateRange } = this.props;

    let start = (dateRange && dateRange.start) ? dateRange.start : moment('01/16/2018', 'MM/DD/YYYY').tz('America/New_York');
    let end = (dateRange && dateRange.end) ? dateRange.end : moment().endOf('day').tz('America/New_York');

    return day.startOf('day').isAfter(end) || day.startOf('day').isBefore(start);
  }

  reportStartOffset = (day) => {
    const { dateRange, reportsTab } = this.props;

    // startOf isoWeek/month OR startOf dateRange (if data start comes after date)
    if (dateRange.start.isAfter(day.startOf((reportsTab === 1) ? 'isoWeek' : 'month'), 'day')) return dateRange.start.clone();

    return day.startOf((reportsTab === 1) ? 'isoWeek' : 'month'); // 0 (custom), 1 (week), 2 (month)
  }

  reportEndOffset = (day) => {
    const { dateRange, reportsTab } = this.props;

    // endOf isoWeek/month OR endOf dateRange (if data start comes after date)
    if (dateRange.end.isBefore(day.endOf((reportsTab === 1) ? 'isoWeek' : 'month'), 'day')) return dateRange.end.clone();

    return day.endOf((reportsTab === 1) ? 'isoWeek' : 'month');
  }

  visibleMonth = () => {
    const { dateRange } = this.props;
    const { startDate, endDate, focusedInput } = this.state;
    const mobile = (window.innerWidth <= 767) ? true : false;

    if (focusedInput === END_DATE) {
      if (typeof endDate === 'undefined' || endDate === null) {
        if (dateRange && dateRange.end && dateRange.end.isValid()) {

          return (mobile) ? dateRange.end.clone() : dateRange.end.clone().subtract(1, 'month'); // when end date is not set and loc has end date
        }

        return moment().tz('America/New_York'); // when end date not set
      }
      else {
        if (dateRange && dateRange.end && dateRange.end.isValid()) {
          if (endDate.isAfter(dateRange.end, 'day') || isSameDay(endDate, dateRange.end)) {

            return (mobile) ? dateRange.end.clone() : dateRange.end.clone().subtract(1, 'month'); // when selected end date is AFTER or SAME AS loc END date
          }
        }

        if (dateRange && dateRange.start && dateRange.start.isValid()) {
          if (endDate.isBefore(dateRange.start, 'day') || isSameDay(endDate, dateRange.start)) {

            return dateRange.start.clone(); // when selected end date is BEFORE loc START date
          }
        }

        return endDate;
      }
    } else if (focusedInput === START_DATE) {
      if (typeof startDate === 'undefined' || startDate === null) {
        if (dateRange && dateRange.end && dateRange.end.isValid()) {

          return (mobile) ? dateRange.end.clone() : dateRange.end.clone().subtract(1, 'month'); // when start date is not set and loc has end date
        }

        return moment().tz('America/New_York').subtract(1, 'month'); // when start date not set
      }
      else {
        if (dateRange && dateRange.end && dateRange.end.isValid()) {
          if (startDate.isAfter(dateRange.end, 'day') || isSameDay(startDate, dateRange.end)) {

            return (mobile) ? dateRange.end.clone() : dateRange.end.clone().subtract(1, 'month'); // when selected start date is AFTER or SAME AS loc END date
          }
        }

        if (dateRange && dateRange.start && dateRange.start.isValid()) {
          if (startDate.isBefore(dateRange.start, 'day') || isSameDay(startDate, dateRange.start)) {

            return dateRange.start.clone(); // when selected start date is BEFORE loc START date
          }
        }

        return startDate;
      }
    }
  }

  onDatesChange({ startDate, endDate }) {
    // if user is selecting/deselecting dates to exclude from data, check dates are within selectable range and block focus from changing
    if (this.state.datesChecked) {
      const { focusedInput } = this.state;
      const { initialStartDate, initialEndDate } = this.props;

      this.blockFocusChange = true; // stop date card focus from changing (start/end date are fine but null will close dateCard)

      if (focusedInput === START_DATE && startDate) {
        // check if selected date is within the current (initialEndDate, initialStartDate) date range
        if (startDate.startOf('day').isAfter(initialEndDate) || startDate.startOf('day').isBefore(initialStartDate)) return;
        return this.props.excludeDatesChange(startDate);
      }

      if (focusedInput === END_DATE && endDate) {
        // check if selected date is within the current (initialEndDate, initialStartDate) date range
        if (endDate.startOf('day').isAfter(initialEndDate) || endDate.startOf('day').isBefore(initialStartDate)) return;
        return this.props.excludeDatesChange(endDate);
      }

      this.blockFocusChange = false; // reaching here most likely means endDate is null while in focus, reset blockFocusChange

      return;
    }

    // only check for undefined, allow null value - if user selects valid start date while end date is still null:
      // setState updates start date, dashboard skips changedDateCard(), and input changes focus to endDate
    if (typeof startDate !== 'undefined' && typeof endDate !== 'undefined') {
      this.setState({ startDate, endDate }, () => {
        this.props.onChange(this.state);
      });
    }
  }

  onFocusChange(focusedInput) {
    if (this.blockFocusChange) {
      this.blockFocusChange = false; // stop focus change when user is selecting dates to exclude
      return;
    }

    // if datesChecked is true when user closes date card (focusedInput = null) update dashboard data (this is more efficient than onClose() prop)
    if (!focusedInput && this.state.datesChecked) return this.setState({ focusedInput, datesChecked: false }, () => { this.props.onChange(this.state) });

    this.setState({ focusedInput });
  }

  toggleExcludeDates = (e) => {
    if (e.target.checked) return this.setState({ datesChecked: e.target.checked });

    this.blockFocusChange = false; // ensure focusChange returns to normal

    this.setState({ datesChecked: false }, () => {
      this.props.onChange(this.state); // when unchecked update dashboard data
    });
  }

  toggleWkend = () => {
    let tempState = this.state.wkendChecked;
    let allWkdays = this.state.monChecked && this.state.tueChecked && this.state.wedChecked && this.state.thuChecked && this.state.friChecked;
    let anyWkdays = this.state.monChecked || this.state.tueChecked || this.state.wedChecked || this.state.thuChecked || this.state.friChecked;
    let allWkends = this.state.satChecked && this.state.sunChecked;
    let anyWkends = this.state.satChecked || this.state.sunChecked;
    let tempCustom = (anyWkdays && !allWkdays) || (anyWkends && !allWkends);
    this.setState({ wkendChecked: !tempState, satChecked: !tempState, sunChecked: !tempState, customChecked: tempCustom }, function () {
      this.props.onChange(this.state);
    });
  }
  toggleWkday = () => {
    let tempState = this.state.wkdayChecked;
    let allWkdays = this.state.monChecked && this.state.tueChecked && this.state.wedChecked && this.state.thuChecked && this.state.friChecked;
    let anyWkdays = this.state.monChecked || this.state.tueChecked || this.state.wedChecked || this.state.thuChecked || this.state.friChecked;
    let allWkends = this.state.satChecked && this.state.sunChecked;
    let anyWkends = this.state.satChecked || this.state.sunChecked;
    let tempCustom = (anyWkdays && !allWkdays) || (anyWkends && !allWkends);
    this.setState({ wkdayChecked: !tempState, monChecked: !tempState, tueChecked: !tempState, wedChecked: !tempState, thuChecked: !tempState, friChecked: !tempState, customChecked: tempCustom }, function () {
      this.props.onChange(this.state);
    });
  }
  toggleCustom = () => {
    let tempState = this.state.customChecked;
    this.setState({ customChecked: !tempState });
  }
  toggleMon = () => {
    let tempState = this.state.monChecked;
    let allWkdays = !tempState && this.state.tueChecked && this.state.wedChecked && this.state.thuChecked && this.state.friChecked;
    let anyWkdays = !tempState || this.state.tueChecked || this.state.wedChecked || this.state.thuChecked || this.state.friChecked;
    let allWkends = this.state.satChecked && this.state.sunChecked;
    let anyWkends = this.state.satChecked || this.state.sunChecked;
    let tempCustom = (anyWkdays && !allWkdays) || (anyWkends && !allWkends);
    this.setState({ monChecked: !tempState, wkdayChecked: allWkdays, customChecked: tempCustom }, function () {
      this.props.onChange(this.state);
    });
  }
  toggleTue = () => {
    let tempState = this.state.tueChecked;
    let allWkdays = !tempState && this.state.monChecked && this.state.wedChecked && this.state.thuChecked && this.state.friChecked;
    let anyWkdays = !tempState || this.state.monChecked || this.state.wedChecked || this.state.thuChecked || this.state.friChecked;
    let allWkends = this.state.satChecked && this.state.sunChecked;
    let anyWkends = this.state.satChecked || this.state.sunChecked;
    let tempCustom = (anyWkdays && !allWkdays) || (anyWkends && !allWkends);
    this.setState({ tueChecked: !tempState, wkdayChecked: allWkdays, customChecked: tempCustom }, function () {
      this.props.onChange(this.state);
    });
  }
  toggleWed = () => {
    let tempState = this.state.wedChecked;
    let allWkdays = !tempState && this.state.tueChecked && this.state.monChecked && this.state.thuChecked && this.state.friChecked;
    let anyWkdays = !tempState || this.state.tueChecked || this.state.monChecked || this.state.thuChecked || this.state.friChecked;
    let allWkends = this.state.satChecked && this.state.sunChecked;
    let anyWkends = this.state.satChecked || this.state.sunChecked;
    let tempCustom = (anyWkdays && !allWkdays) || (anyWkends && !allWkends);
    this.setState({ wedChecked: !tempState, wkdayChecked: allWkdays, customChecked: tempCustom }, function () {
      this.props.onChange(this.state);
    });
  }
  toggleThu = () => {
    let tempState = this.state.thuChecked;
    let allWkdays = !tempState && this.state.tueChecked && this.state.wedChecked && this.state.monChecked && this.state.friChecked;
    let anyWkdays = !tempState || this.state.tueChecked || this.state.wedChecked || this.state.monChecked || this.state.friChecked;
    let allWkends = this.state.satChecked && this.state.sunChecked;
    let anyWkends = this.state.satChecked || this.state.sunChecked;
    let tempCustom = (anyWkdays && !allWkdays) || (anyWkends && !allWkends);
    this.setState({ thuChecked: !tempState, wkdayChecked: allWkdays, customChecked: tempCustom }, function () {
      this.props.onChange(this.state);
    });
  }
  toggleFri = () => {
    let tempState = this.state.friChecked;
    let allWkdays = !tempState && this.state.tueChecked && this.state.wedChecked && this.state.thuChecked && this.state.monChecked;
    let anyWkdays = !tempState || this.state.tueChecked || this.state.wedChecked || this.state.thuChecked || this.state.monChecked;
    let allWkends = this.state.satChecked && this.state.sunChecked;
    let anyWkends = this.state.satChecked || this.state.sunChecked;
    let tempCustom = (anyWkdays && !allWkdays) || (anyWkends && !allWkends);
    this.setState({ friChecked: !tempState, wkdayChecked: allWkdays, customChecked: tempCustom }, function () {
      this.props.onChange(this.state);
    });
  }
  toggleSat = () => {
    let tempState = this.state.satChecked;
    let allWkdays = this.state.monChecked && this.state.tueChecked && this.state.wedChecked && this.state.thuChecked && this.state.monChecked;
    let anyWkdays = this.state.monChecked || this.state.tueChecked || this.state.wedChecked || this.state.thuChecked || this.state.monChecked;
    let allWkends = !tempState && this.state.sunChecked;
    let anyWkends = !tempState || this.state.sunChecked;
    let tempCustom = (anyWkdays && !allWkdays) || (anyWkends && !allWkends);
    this.setState({ satChecked: !tempState, wkendChecked: allWkends, customChecked: tempCustom }, function () {
      this.props.onChange(this.state);
    });
  }
  toggleSun = () => {
    let tempState = this.state.sunChecked;
    let allWkdays = this.state.monChecked && this.state.tueChecked && this.state.wedChecked && this.state.thuChecked && this.state.monChecked;
    let anyWkdays = this.state.monChecked || this.state.tueChecked || this.state.wedChecked || this.state.thuChecked || this.state.monChecked;
    let allWkends = !tempState && this.state.satChecked;
    let anyWkends = !tempState || this.state.satChecked;
    let tempCustom = (anyWkdays && !allWkdays) || (anyWkends && !allWkends);
    this.setState({ sunChecked: !tempState, wkendChecked: allWkends, customChecked: tempCustom }, function () {
      this.props.onChange(this.state);
    });
  }

  closeCard = () => {
    this.props.closeDate(this.props.name);
  }

  trimDatesToData = (start, end, dateRange) => {
    // if selected start/end date is before/after the data start/end dates, change constrain dates to range with data.
    const ckStart = start.clone().startOf('day').isBefore(dateRange.start) || start.clone().endOf('day').isAfter(dateRange.end, 'day');
    const ckEnd = end.clone().startOf('day').isAfter(dateRange.end) || end.clone().startOf('day').isBefore(dateRange.start, 'day');

    if (ckStart && ckEnd) this.onDatesChange({ startDate: dateRange.start.clone().startOf('day'), endDate: dateRange.end.clone().endOf('day') });
    if (ckStart && !ckEnd) this.onDatesChange({ startDate: dateRange.start.clone().startOf('day'), endDate: end.endOf('day') });
    if (!ckStart && ckEnd) this.onDatesChange({ startDate: start.startOf('day'), endDate: dateRange.end.clone().endOf('day') });
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.name === 0 && nextProps.reportsActive && nextProps.reportsTab !== 0 && nextProps.initialStartDate != null && nextProps.initialEndDate != null) {
      if (!nextProps.initialStartDate.isSame(this.state.startDate, 'day') || !nextProps.initialEndDate.isSame(this.state.endDate, 'day')) {
        // when changing tabs in report mode, this updates the date card to match dates on report.
        // only use setState to change the dates, DO NOT call onDatesChange(), that would update dashboard which is not active.
        this.setState({ startDate: nextProps.initialStartDate, endDate: nextProps.initialEndDate });
      }
    }

    if (nextProps.name === 0 && nextProps.trimDates && nextProps.initialStartDate != null && nextProps.initialEndDate != null) {
      // if data start and end dates haven't changed, don't need to check dates.
      if (!nextProps.dateRange.start.isSame(this.props.dateRange.start, 'day') || !nextProps.dateRange.end.isSame(this.props.dateRange.end, 'day')) {
        this.trimDatesToData(nextProps.initialStartDate, nextProps.initialEndDate, nextProps.dateRange);
      }
    }
  }

  renderDatePresets() {
    // called when dateCard opens and again for every mouse event, keep this simple
    const { classes, dateRange, presets, reportsActive, reportsTab } = this.props;
    const { endDate, startDate } = this.state;

    return (
      <Grid container spacing={0} style={{ marginTop: -20 }} direction='row' justifyContent='space-between' alignItems='center'>
        <Grid style={{ paddingTop: 12, paddingLeft: 22, paddingRight: 22, width: '100%' }} className={classes.PresetDateRangePicker_panel}>
          <Tooltip title={(reportsActive) ? 'Not available on reports.' : ''}>
            <Accordion disabled={reportsActive} square expanded={this.state.customChecked} TransitionProps={{ unmountOnExit: true }}>
              <AccordionSummary
                style={{ marginTop: -5, marginBottom: 0, paddingRight: 10, justifyContent: 'center', minHeight: 0 }}
                aria-controls='panel1a-content'
                id='panel1a-header'
              >
                <FilterAltRoundedIcon style={{ paddingTop: 6, paddingLeft: 10, color: '#00313D' }} />
                <Grid container justifyContent='space-evenly' alignItems='center'>
                  <FormControlLabel control={<Checkbox size='small' style={{ color: '#277175' }} checked={this.state.datesChecked} onChange={this.toggleExcludeDates} />} label='Date' />
                  <FormControlLabel control={<Checkbox size='small' style={{ color: '#277175' }} checked={this.state.wkendChecked} onChange={this.toggleWkend} />} label='Weekend' />
                  <FormControlLabel control={<Checkbox size='small' style={{ color: '#277175' }} checked={this.state.wkdayChecked} onChange={this.toggleWkday} />} label='Weekdays' />
                  <Grid>
                    <FormControlLabel control={<Checkbox size='small' style={{ color: '#277175' }} checked={this.state.customChecked} onChange={this.toggleCustom} />} label='Custom' />
                    {this.state.customChecked
                      ? <ExpandLessRoundedIcon onClick={this.toggleCustom} style={{ marginBottom: -9, marginLeft: -11, color: '#00313D' }} />
                      : <ExpandMoreIcon onClick={this.toggleCustom} style={{ marginBottom: -8, marginLeft: -11, color: '#00313D' }} />
                    }
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails style={{ marginTop: -15, marginBottom: -5 }}>
                <Grid container justifyContent='space-evenly' alignItems='flex-start'>
                  <FormControlLabel control={<Checkbox size='small' style={{ color: '#AFD8D0' }} checked={this.state.monChecked} onChange={this.toggleMon} />} label='Mon' />
                  <FormControlLabel control={<Checkbox size='small' style={{ color: '#AFD8D0' }} checked={this.state.tueChecked} onChange={this.toggleTue} />} label='Tue' />
                  <FormControlLabel control={<Checkbox size='small' style={{ color: '#AFD8D0' }} checked={this.state.wedChecked} onChange={this.toggleWed} />} label='Wed' />
                  <FormControlLabel control={<Checkbox size='small' style={{ color: '#AFD8D0' }} checked={this.state.thuChecked} onChange={this.toggleThu} />} label='Thu' />
                  <FormControlLabel control={<Checkbox size='small' style={{ color: '#AFD8D0' }} checked={this.state.friChecked} onChange={this.toggleFri} />} label='Fri' />
                  <FormControlLabel control={<Checkbox size='small' style={{ color: '#AFD8D0' }} checked={this.state.satChecked} onChange={this.toggleSat} />} label='Sat' />
                  <FormControlLabel control={<Checkbox size='small' style={{ color: '#AFD8D0' }} checked={this.state.sunChecked} onChange={this.toggleSun} />} label='Sun' />
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Tooltip>
        </Grid>

        <Grid container direction='row'>
          <Grid container item xs direction='row' style={{ paddingTop: 0, paddingLeft: 22 }} justifyContent='flex-start' alignItems='stretch' className={classes.PresetDateRangePicker_panel}>
            {presets.map(({ text, start, end }) => {
              const isSelected = (isSameDay(start, startDate) && isSameDay(end, endDate)) && (!reportsActive || reportsTab === 0);
              const isDisabled = (dateRange.start.isAfter(start, 'day') || dateRange.end.isBefore(end, 'day')) || (reportsActive && reportsTab !== 0) || (this.state.datesChecked);

              return (
                <Grid key={text} item xs>
                  <Tooltip title={start.format('MMM D, YYYY') + ' - ' + end.format('MMM D, YYYY')}>
                    <span>
                      <Button
                        style={{ width: 50, height: 50, border: (isDisabled) ? '2px solid' : '' }}
                        disabled={isDisabled}
                        className={[classes.PresetDateRangePicker_button, ...(isSelected ? [classes.PresetDateRangePicker_button__selected] : [])].join(' ')}
                        // {...css(classes.PresetDateRangePicker_button, isSelected && classes.PresetDateRangePicker_button__selected)}
                        onClick={() => this.onDatesChange({ startDate: start, endDate: end })}
                      >
                        {text}
                      </Button>
                    </span>
                  </Tooltip>
                </Grid>
              );
            })}
          </Grid>

          <Grid container item xs={3} direction='row' style={{ marginTop: 4, paddingRight: 0, }} justifyContent='center' alignItems='center' className={classes.PresetDateRangePicker_panel}>
            {this.props.name === 0 &&
              <Grid item xs>
                <Button
                  style={{ height: 100, width: 40, marginLeft: 5, border: '2px solid' }}
                  disabled={!this.props.prevYearActive || !this.props.compareActiveDate}
                  variant='outlined'
                  color='primary'
                  onClick={this.props.compareDateClick}
                >
                  <Grid>
                    <Typography style={{ fontSize: 'calc(5px + 0.3vw)', paddingBottom: 8, fontWeight: 700, lineHeight: 1.3 }}>vs. Prev Year</Typography>
                    <OpenInNewRoundedIcon color='primary' style={{ width: 20, display: 'inline', color: (!this.props.prevYearActive || !this.props.compareActiveDate) ? '#bdbdbd' : '#00313D' }} />
                  </Grid>
                </Button>
              </Grid>
            }
            <Grid item xs style={{ paddingTop: 10, paddingBottom: 0 }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      size='small'
                      classes={{ track: classes.switch_track, switchBase: classes.switch_base, colorPrimary: classes.switch_primary }}
                      checked={this.props.simulated}
                      onChange={this.props.toggleSimulated}
                      name='simulatedSwitch'
                    />
                  }
                  label={<Typography align='center' style={{ fontSize: '10px', lineHeight: '12px', color: '#277175' }}>Include<br></br>Estimated<br></br>Data</Typography>}
                  labelPlacement='bottom'
                />
              </FormGroup>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  render() {
    const { classes, closeBtn, dateRange, excludeDates, name, panelColor, selectRange, reportsActive, reportsTab } = this.props;
    const { datesChecked, endDate, focusedInput, startDate } = this.state;
    // autoFocus, autoFocusEndDate, initialStartDate and initialEndDate are helper props for the example wrapper but are not props on the SingleDatePicker itself and thus, have to be omitted.
    const props = omit(this.props, [
      'autoFocus',
      'autoFocusEndDate',
      'initialStartDate',
      'initialEndDate',
      'presets',
      'classes', 'onChange', 'excludeDates', 'excludeDatesChange',
      'closeBtn', 'history', 'location', 'match', 'staticContext',
      'name', 'closeDate', 'compareDateClick', 'selectRange', 'dateRange', 'simulated', 'toggleSimulated', 'compareActiveDate',
      'panelColor', 'trimDates', 'prevYearActive', 'reportsActive', 'reportsTab'
    ]);

    return (
      <Grid container style={{ width:'100%' }}>
        <Paper className={classes.dateCard} elevation={1} style={{ zIndex: 5, height: 36, width: '100%' }}>
          <div className={classes.fillRect}/>
          <div className={classes.arrow1} style={{ borderColor: panelColor }}/>
          <div className={classes.arrow2} style={{ borderColor: panelColor }}/>

          <Mobile>
          {name !== 1 &&
            <DateRangePicker
              {...props}
              numberOfMonths={1}
              initialVisibleMonth={this.visibleMonth}
              renderCalendarInfo={this.renderDatePresets}
              onDatesChange={this.onDatesChange}
              onFocusChange={this.onFocusChange}
              focusedInput={focusedInput}
              startDate={startDate}
              endDate={endDate}
              readOnly={true} // disables datefield keyboard input
              minDate={dateRange.start}
              maxDate={dateRange.end}
              isOutsideRange={this.isOutsideRange}
              startDateOffset={(!reportsActive || reportsTab === 0)
                ? undefined
                : this.reportStartOffset
              }
              endDateOffset={(!reportsActive || reportsTab === 0)
                ? undefined
                : this.reportEndOffset
              }
              isDayBlocked={current => {
                return (reportsActive)
                  ? false
                  : (!this.state.monChecked && moment(current).day() === 1) || (!this.state.tueChecked && moment(current).day() === 2) ||
                  (!this.state.wedChecked && moment(current).day() === 3) || (!this.state.thuChecked && moment(current).day() === 4) ||
                  (!this.state.friChecked && moment(current).day() === 5) || (!this.state.satChecked && moment(current).day() === 6) ||
                  (!this.state.sunChecked && moment(current).day() === 0)
              }}
              isDayHighlighted={
                (reportsActive || !(excludeDates.length > 0))
                  ? () => {}
                  : (day) => { return excludeDates.some(exDate => isSameDay(day, exDate)) ? true : false }
              }
              // renderDayContents={(reportsActive) ? null : (day) => (excludeDates.some(exDate => isSameDay(day, exDate)) ? '❌' : day.format('D'))}
            />
          }
          {name === 1 &&
            <DateRangePicker
              {...props}
              numberOfMonths={1}
              initialVisibleMonth={this.visibleMonth}
              renderCalendarInfo={this.renderDatePresets}
              onDatesChange={this.onDatesChange}
              onFocusChange={this.onFocusChange}
              focusedInput={focusedInput}
              startDate={startDate}
              endDate={endDate}
              readOnly={true} // disables datefield keyboard input
              minDate={dateRange.start}
              maxDate={dateRange.end}
              isOutsideRange={this.isOutsideRange}
              startDateOffset={(day) => {
                if (datesChecked) return day; // no offset when selecting dates to exclude
                if (focusedInput === END_DATE && name === 1) return day.subtract(selectRange, 'days');
                return day;
              }}
              endDateOffset={(day) => {
                if (datesChecked) return day; // no offset when selecting dates to exclude
                if (focusedInput === START_DATE && (typeof endDate !== 'undefined' && name === 1)) return day.add(selectRange, 'days');
                return day;
              }}
              isDayBlocked={current => {
                return (!this.state.monChecked && moment(current).day() === 1) || (!this.state.tueChecked && moment(current).day() === 2) ||
                  (!this.state.wedChecked && moment(current).day() === 3) || (!this.state.thuChecked && moment(current).day() === 4) ||
                  (!this.state.friChecked && moment(current).day() === 5) || (!this.state.satChecked && moment(current).day() === 6) ||
                  (!this.state.sunChecked && moment(current).day() === 0)
              }}
              isDayHighlighted={
                (reportsActive || !(excludeDates.length > 0))
                  ? () => {}
                  : (day) => { return excludeDates.some(exDate => isSameDay(day, exDate)) ? true : false }
              }
              // renderDayContents={(day) => (excludeDates.some(exDate => isSameDay(day, exDate)) ? '❌' : day.format('D'))}
            />
          }
          </Mobile>

          <Default>
          {name !== 1 &&
            <DateRangePicker
              {...props}
              numberOfMonths={2}
              initialVisibleMonth={this.visibleMonth}
              renderCalendarInfo={this.renderDatePresets}
              onDatesChange={this.onDatesChange}
              onFocusChange={this.onFocusChange}
              focusedInput={focusedInput}
              startDate={startDate}
              endDate={endDate}
              minDate={dateRange.start}
              maxDate={dateRange.end}
              isOutsideRange={this.isOutsideRange}
              startDateOffset={(!reportsActive || reportsTab === 0)
                ? undefined
                : this.reportStartOffset
              }
              endDateOffset={(!reportsActive || reportsTab === 0)
                ? undefined
                : this.reportEndOffset
              }
              isDayBlocked={current => {
                return (reportsActive)
                  ? false
                  : (!this.state.monChecked && moment(current).day() === 1) || (!this.state.tueChecked && moment(current).day() === 2) ||
                  (!this.state.wedChecked && moment(current).day() === 3) || (!this.state.thuChecked && moment(current).day() === 4) ||
                  (!this.state.friChecked && moment(current).day() === 5) || (!this.state.satChecked && moment(current).day() === 6) ||
                  (!this.state.sunChecked && moment(current).day() === 0)
              }}
              isDayHighlighted={
                (reportsActive || !(excludeDates.length > 0))
                  ? () => {}
                  : (day) => { return excludeDates.some(exDate => isSameDay(day, exDate)) ? true : false }
              }
              // renderDayContents={(reportsActive) ? null : (day) => (excludeDates.some(exDate => isSameDay(day, exDate)) ? '❌' : day.format('D'))}
            />
          }
          {name === 1 &&
            <DateRangePicker
              {...props}
              numberOfMonths={2}
              initialVisibleMonth={this.visibleMonth}
              renderCalendarInfo={this.renderDatePresets}
              onDatesChange={this.onDatesChange}
              onFocusChange={this.onFocusChange}
              focusedInput={focusedInput}
              startDate={startDate}
              endDate={endDate}
              minDate={dateRange.start}
              maxDate={dateRange.end}
              isOutsideRange={this.isOutsideRange}
              startDateOffset={(day) => {
                if (datesChecked) return day; // no offset when selecting dates to exclude
                if (focusedInput === END_DATE && name === 1) return day.subtract(selectRange, 'days');
                return day;
              }}
              endDateOffset={(day) => {
                if (datesChecked) return day; // no offset when selecting dates to exclude
                if (focusedInput === START_DATE && (typeof endDate !== 'undefined' && name === 1)) return day.add(selectRange, 'days');
                return day;
              }}
              isDayBlocked={current => {
                return (!this.state.monChecked && moment(current).day() === 1) || (!this.state.tueChecked && moment(current).day() === 2) ||
                  (!this.state.wedChecked && moment(current).day() === 3) || (!this.state.thuChecked && moment(current).day() === 4) ||
                  (!this.state.friChecked && moment(current).day() === 5) || (!this.state.satChecked && moment(current).day() === 6) ||
                  (!this.state.sunChecked && moment(current).day() === 0)
              }}
              isDayHighlighted={
                (reportsActive || !(excludeDates.length > 0))
                  ? () => {}
                  : (day) => { return excludeDates.some(exDate => isSameDay(day, exDate)) ? true : false }
              }
              // renderDayContents={(day) => (excludeDates.some(exDate => isSameDay(day, exDate)) ? '❌' : day.format('D'))}
            />
          }
          </Default>

          <IconButton style={{ display: closeBtn ? 'inline' : 'none', position: 'absolute', right: -11, top: -16 }} onClick={this.closeCard}>
            <CloseIcon style={{ cursor: 'pointer', width: '14px', color: '#1e1e1e' }}/>
          </IconButton>
        </Paper>
      </Grid>
    );
  }
}

DateRangePickerWrapper.propTypes = propTypes;
DateRangePickerWrapper.defaultProps = defaultProps;

export default withRouter(withStyles(styles)(DateRangePickerWrapper));
