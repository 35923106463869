import bikeSvg from '../components/dashboard/bike.svg';
import motorbikeSvg from '../components/dashboard/motorbike.svg';
import carSvg from '../components/dashboard/car.svg';
import busSvg from '../components/dashboard/bus.svg';
import truckSvg from '../components/dashboard/truck.svg';

export const vImgsObj = {
  'bikes': bikeSvg,
  'cars': carSvg,
  'buses': busSvg,
  'trucks': truckSvg,
  'motorbikes': motorbikeSvg,
}
