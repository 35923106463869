import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import VideoPlayer from './videojs';

import { KinesisVideoClient, GetDataEndpointCommand } from '@aws-sdk/client-kinesis-video';
import { KinesisVideoArchivedMediaClient, GetHLSStreamingSessionURLCommand } from '@aws-sdk/client-kinesis-video-archived-media';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { GoogleMap, LoadScript, Marker, Polygon } from '@react-google-maps/api';
// import Drawer from '@mui/material/Drawer';

const styles = ({});

const fa1 = { lat: 40.76129176394986, lng: -73.97521073663054 };
const fa4 = { lat: 40.76256573955672, lng: -73.97428183553559 };
const mapCenter = { lat: 40.76086532684194, lng: -73.97551045905944 };

class VideoDashboard extends PureComponent {
  constructor() {
    super();

    this.loadOrder = ['fa4', 'fa2', 'fa3'];
    this.isMobile = /iphone|ipad|ipod|android/.test((navigator.userAgent || '').toLowerCase());

    this.state = {
      // mapLoaded: false,
      streamDesc: '',
      streamUrl: '',
      markers: {
        fa1: {
          color: 'yellow',
          streamName: 'fa1',
          description: 'Fifth Ave - Between 54th & 55th',
          position: { lat: 40.76155866676751, lng: -73.97521439676389 },
          polygonCoordinates: [
            { lat: 40.76155866676751, lng: -73.97521439676389 },
            { lat: 40.76165821320195, lng: -73.97513908752744 },
            { lat: 40.76170564061296, lng: -73.9747002235714 },
            { lat: 40.761205934181525, lng: -73.97506233942036 },
            { lat: 40.76147700288901, lng: -73.97527380908214 },
          ],
        },
        fa2: {
          color: 'yellow',
          streamName: 'fa2',
          description: 'Fifth Ave - Between 55th & 56th',
          position: { lat: 40.76244955222761, lng: -73.97458519984968 },
          polygonCoordinates: [
            { lat: 40.76244955222761, lng: -73.97458519984968 },
            { lat: 40.76205949035862, lng: -73.97486213817436 },
            { lat: 40.76192027663694, lng: -73.97448861906756 },
            { lat: 40.76212889442154, lng: -73.9743388018561 },
          ],
        },
        fa3: {
          color: 'yellow',
          streamName: 'fa3',
          description: 'Fifth Ave - Between 58th & 59th',
          position: { lat: 40.763658366461605, lng: -73.9737371247537 },
          polygonCoordinates: [
            { lat: 40.763658366461605, lng: -73.9737371247537 },
            { lat: 40.76410219262346, lng: -73.97341827164125 },
            { lat: 40.763862763627216, lng: -73.97274049637635 },
            { lat: 40.76365052176689, lng: -73.9728895806201 },
            { lat: 40.763651569061636, lng: -73.97337548207898 },
          ],
        },
        fa4: {
          color: 'yellow',
          streamName: 'fa4',
          description: 'Fifth Ave - Between 56th & 57th',
          position: { lat: 40.76317235969555, lng: -73.97406538771641 },
          polygonCoordinates: [
            { lat: 40.76317235969555, lng: -73.97406538771641 },
            { lat: 40.7625389867979, lng: -73.97449633125909 },
            { lat: 40.76227183531053, lng: -73.97427706089762 },
            { lat: 40.762854941122775, lng: -73.97383716341062 },
          ],
        },
        fa5: {
          color: 'yellow',
          streamName: 'fa5',
          description: 'Fifth Ave (West) - Between 57th & 58th',
          position: { lat: 40.76336047329443, lng: -73.97349902144913 },
          polygonCoordinates: [
            { lat: 40.76336047329443, lng: -73.97349902144913 },
            { lat: 40.762997405668834, lng: -73.97392833514013 },
            { lat: 40.7630813780313, lng: -73.9741468199155 },
            { lat: 40.76339545027722, lng: -73.97388128090232 },
          ],
        },
        fa6: {
          color: 'yellow',
          streamName: 'fa6',
          description: 'Fifth Ave - Between 57th & 58th',
          position: { lat: 40.7634316206838, lng: -73.9734458546352 },
          polygonCoordinates: [
            { lat: 40.7634316206838, lng: -73.9734458546352 },
            { lat: 40.763300345747, lng: -73.9735444400564 },
            { lat: 40.76335446390088, lng: -73.97390860546132 },
            { lat: 40.76368265892125, lng: -73.9736804241419 },
            { lat: 40.76351332334283, lng: -73.97338425239717 },
          ],
        },
        fa7: {
          color: 'yellow',
          streamName: 'fa7',
          description: 'Fifth Ave - Between 49th & 50th',
          position: { lat: 40.758369682939225, lng: -73.97753720663962 },
          polygonCoordinates: [
            { lat: 40.758369682939225, lng: -73.97753720663962 },
            { lat: 40.75852713913798, lng: -73.9774238831901 },
            { lat: 40.75849913815657, lng: -73.97703755895995 },
            { lat: 40.75818440843216, lng: -73.9772708499985 },
          ],
        },
        fa8: {
          color: 'yellow',
          streamName: 'fa8',
          description: 'Fifth Ave - Between 50th & 51st',
          position: { lat: 40.75853947085345, lng: -73.97745066916997 },
          polygonCoordinates: [
            { lat: 40.75853947085345, lng: -73.97745066916997 },
            { lat: 40.758859416343945, lng: -73.97718535500725 },
            { lat: 40.75872438806857, lng: -73.9768171962353 },
            { lat: 40.75850388915838, lng: -73.97697618201025 },
          ],
        },
        fa9: {
          color: 'yellow',
          streamName: 'fa9',
          description: 'Fifth Ave - Between 50th & 51st (East)',
          position: { lat: 40.759117285907735, lng: -73.97699994974164 },
          polygonCoordinates: [
            { lat: 40.759117285907735, lng: -73.97699994974164 },
            { lat: 40.75914929654279, lng: -73.97647279047663 },
            { lat: 40.75886409183046, lng: -73.97664354193022 },
          ],
        },
        fa10: {
          color: 'yellow',
          streamName: 'fa10',
          description: 'Fifth Ave - Between 51st & 52nd (East)',
          position: { lat: 40.75914933366755, lng: -73.97696547231017 },
          polygonCoordinates: [
            { lat: 40.75914933366755, lng: -73.97696547231017 },
            { lat: 40.759541015191495, lng: -73.97627581417416 },
            { lat: 40.75919321499972, lng: -73.97653211670873 },
          ],
        },
        fa11: {
          color: 'yellow',
          streamName: 'fa11',
          description: 'Fifth Ave - Between 52nd & 53rd (East)',
          position: { lat: 40.76019965714518, lng: -73.97586807960444 },
          polygonCoordinates: [
            { lat: 40.76019965714518, lng: -73.97586807960444 },
            { lat: 40.75981712062762, lng: -73.97608884117004 },
            { lat: 40.759919718750616, lng: -73.97634230990272 },
          ],
        },
        fa12: {
          color: 'yellow',
          streamName: 'fa12',
          description: 'Fifth Ave - Between 53rd & 54th',
          position: { lat: 40.76121222626298, lng: -73.97556496299661 },
          polygonCoordinates: [
            { lat: 40.76121222626298, lng: -73.97556496299661  },
            { lat: 40.760951297456465, lng: -73.97563992161801 },
            { lat: 40.76038632681727, lng: -73.97565518425348 },
            { lat: 40.760940801585356, lng: -73.97525008866855 },
          ],
        },
        fa13: {
          color: 'yellow',
          streamName: 'fa13',
          description: 'Fifth Ave - Between 58th & 59th (East)',
          position: { lat: 40.7635722489491, lng: -73.97286286953236 },
          polygonCoordinates: [
            { lat: 40.7635722489491, lng: -73.97286286953236  },
            { lat: 40.76382647557641, lng: -73.97352293665497 },
            { lat: 40.76418180146708, lng: -73.97325228147874 },
          ],
        },
        fa14: {
          color: 'yellow',
          streamName: 'fa14',
          description: 'Fifth Ave - Between 51st & 53rd', 
          position: { lat: 40.758393312426335, lng: -73.97706063662974 },
          polygonCoordinates: [
            { lat: 40.758393312426335, lng: -73.97706063662974  },
            { lat: 40.759370298692744, lng: -73.97681723335079 },
            { lat: 40.76047285558218, lng: -73.97602447389549 },
            { lat: 40.76037193580486, lng: -73.9757813165979 },
          ],
        },
        fa15: {
          color: 'yellow',
          streamName: 'fa15',
          description: 'Fifth Ave - Between 48th & 49th', 
          position: { lat: 40.757930333252254, lng: -73.97744054587844 },
          polygonCoordinates: [
            { lat: 40.757930333252254, lng: -73.97744054587844  },
            { lat: 40.75734075841365, lng: -73.9778906750272 },
            { lat: 40.757542409976566, lng: -73.97813841349364 },
            { lat: 40.75766939217572, lng: -73.97804118342192 },
          ],
        },
      },
    };
  }

  componentDidMount() {
    document.title = 'Video Dashboard';
    this.btnClick('', true);
  }

  onLoad = () => {
    this.setState({ mapLoaded: true });
  };

  getDataEndpoint = async (streamName, clientOptions, apiName) => {
    try {
      const input = { StreamName: streamName, APIName: apiName };

      const client = new KinesisVideoClient(clientOptions);
      const command = new GetDataEndpointCommand(input);
      const response = await client.send(command);

      return response.DataEndpoint || ''
    } catch (err) {
      console.log('getDataEndpoint - error: ');
      console.log(err);
    }
  }

  getHLSStream = async (streamName) => {
    try {
      const clientOptions = {
        region: 'us-east-1',
        credentials: {
          accessKeyId: 'AKIA3QKIJRRHH5Y3MYOT',
          secretAccessKey: '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',
          sessionToken: undefined
        }
      }

      const dataEndpoint = await this.getDataEndpoint(streamName, clientOptions, 'GET_HLS_STREAMING_SESSION_URL');
      clientOptions['endpoint'] = dataEndpoint;

      const client = new KinesisVideoArchivedMediaClient(clientOptions);
      const input = {
        StreamName: streamName,
        PlaybackMode: 'LIVE',
        HLSFragmentSelector: { FragmentSelectorType: 'PRODUCER_TIMESTAMP' }, // SERVER_TIMESTAMP
        ContainerFormat: 'FRAGMENTED_MP4',
        DiscontinuityMode: 'NEVER', // ALWAYS
        DisplayFragmentTimestamp: 'NEVER', // ALWAYS
        Expires: 3600, // 1 hour
        // MaxMediaPlaylistFragmentResults: Number('long'),
      };

      const command = new GetHLSStreamingSessionURLCommand(input);
      const response = await client.send(command);

      return response.HLSStreamingSessionURL || '';
    } catch (err) {
      console.log('getHLSStream - error: ');
      console.log(err);
    }
  }

  getDefaultStream = async () => {
    try {
      let [hlsUrl, streamName] = ['', ''];

      for (const name of this.loadOrder) {
        hlsUrl = await this.getHLSStream(name);
        streamName = name;

        if (hlsUrl) break; // stream is live, break loop
      }

      return [hlsUrl, streamName];
    } catch (err) {
      console.log('getDefaultStream - error: ');
      console.log(err);
    }
  }

  btnClick = async (streamName, onOpen = false) => {
    try {
      let hlsUrl = '';

      if (onOpen) {
        [hlsUrl, streamName] = await this.getDefaultStream();
      }
      else {
        hlsUrl = await this.getHLSStream(streamName)
      }

      if (!hlsUrl) return false; // undefined indicates stream is down, return here to prevent map and video from updating

      this.setState((prevState) => {
        const updatedMarkers = {};

        Object.values(prevState.markers).forEach(marker => {
          updatedMarkers[marker.streamName] = {
            ...marker,
            color: (marker.streamName === streamName) ? 'green' : 'yellow',
            selected: (marker.streamName === streamName),
          };
        });

        return { markers: updatedMarkers, streamDesc: updatedMarkers[streamName].description, streamUrl: hlsUrl };
      });

      return true;
    } catch (err) {
      console.log('btnClick - error: ');
      console.log(err);
    }
  }

  render() {
    const { markers, streamDesc, streamUrl } = this.state;
    const videoJsOptions = {
      autoplay: true,
      muted: true,
      preload: true,
      // controls: true,
      fluid: true,
      sources: {
        src: streamUrl,
        type: 'application/x-mpegURL'
      },
      liveui: true,
      html5: {
        vhs: {
          maxPlaylistRetries: 5,
        },
      },
      playsinline: true,
    }

    return (
      <Grid style={{ backgroundColor: 'black', width: '100vw', height: '100%' }}>
        {/* <Drawer width={'30vw'}
          variant='permanent'
          sx={{
            width: '30vw',
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: { width: 200, boxSizing: 'border-box' },
          }}
        > */}
        <Grid container direction={this.isMobile ? 'column' : 'row'} style={{ backgroundColor: 'black' }}>
          <Grid style={{ width: this.isMobile ? '100vw' : '40vw', height: this.isMobile ? 'calc(50vh - 66px)' : 'calc(100vh - 50px)' }}>
            <LoadScript
              googleMapsApiKey='AIzaSyDgSY4KoPydGmm1NYoyeIQfAa-aShZAAds'
              mapIds={['10324736f3dd8795']}
            >
              <GoogleMap
                mapContainerStyle={{ width: '100%', height: '100%' }}
                options={{ mapId: '10324736f3dd8795', mapTypeControl: false, streetViewControl: false, fullscreenControl: false }}
                zoom={this.isMobile ? 16.2 : 16.8}
                center={this.isMobile ? fa4 : mapCenter}
                // onLoad={this.onLoad}
              >
                {Object.values(markers).map((marker, index) => (
                  <Fragment key={index}>
                    <Marker
                      // key={index} // Important for performance with multiple markers
                      title={marker.description}
                      position={marker.position}
                      zIndex={(marker.color === 'green') ? 2 : 0}
                      icon={{
                        url: 'https://exteros.com/wp-content/uploads/2024/02/playPin_' + marker.color + '.png',
                        // scaledSize: new window.google.maps.Size((marker.color === 'green') ? 43 : 31, (marker.color === 'green') ? 60 : 42),
                        scaledSize: (marker.color === 'green') ? { width: 43, height: 60 } : { width: 31, height: 42 }
                      }}
                      onClick={() => this.btnClick(marker.streamName)}
                    />
                    <Polygon
                      paths={marker.polygonCoordinates}
                      options={{
                        fillColor: (marker.color === 'green') ? '#3BB15E' : '#FCC401',
                        fillOpacity: (marker.color === 'green') ? 0.5 : 0.2,
                        strokeColor: (marker.color === 'green') ? '#147E3A' : '#ED9100',
                        strokeWeight: (marker.color === 'green') ? 3 : 3,
                        strokeOpacity: (marker.color === 'green') ? 1 : 0.7,
                        zIndex: (marker.color === 'green') ? 2 : 1
                      }}
                      onClick={() => this.btnClick(marker.streamName)}
                    />
                  </Fragment>
                ))}
              </GoogleMap>
            </LoadScript>
          </Grid>

          <Grid container direction='column' item style={{ width: this.isMobile ? '100vw' : '60vw', margin: 0, backgroundColor: 'black' }}>
            <Grid container justifyContent='center' alignItems='flex-end' style={{ marginTop: this.isMobile ? 30 : '7.5vh', marginBottom: this.isMobile ? 15 : '6.5vh', verticalAlign: 'bottom', display: 'flex', alignItems: 'baseline' }}>
            </Grid>

            {streamUrl !== '' &&
              <Grid container direction='column' justifyContent='center' alignItems='center'>
                <VideoPlayer {...videoJsOptions} />
              </Grid>
            }

            <Grid container justifyContent='center' alignItems='flex-end' style={{ marginTop: this.isMobile ? 15 : '5vh', marginBottom: this.isMobile ? 10 : '4vh', verticalAlign: 'bottom', display: 'flex', alignItems: 'baseline' }}>
              <Typography style={{ fontFamily: 'rajdhani', fontSize: this.isMobile ? 18 : '2vw', fontWeight: 400, color: '#d3d3d3', verticalAlign: 'bottom' }}>{streamDesc}</Typography>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    );
  }
}

VideoDashboard.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(withStyles(styles)(VideoDashboard));
