import React, { PureComponent } from 'react';
import moment from 'moment';
import { Area, ComposedChart, XAxis, YAxis } from 'recharts';
import { Grid, Typography } from '@material-ui/core';

class ByTimeAreaAll extends PureComponent {

  render() {
    const { series } = this.props;

    let dataPoints = 0;
    try {
      for (let ser in series) {
        if (series[ser].data.length > dataPoints) {
          dataPoints = series[ser].data.length;
        }
      }
    } catch (err) { }

    if (series.length > 1 && dataPoints > 0) {
      series.sort((a, b) => {
        return (b.totalCount - a.totalCount); // change chart display order by size (largest in back)
      });
    }

    return (
      <Grid item xs={6} style={{ height: '100%' }}>
        <Typography style={{ fontSize: 17, fontWeight: 700, marginBottom: 5, fontFamily: 'Nunito Sans' }}>Traffic By Time of Day</Typography>

        {dataPoints > 0 &&
          <ComposedChart width={370} height={200} margin={{ top: 10, right: 10, left: -30, bottom: 1 }}>
            <XAxis
              allowDuplicatedCategory={false}
              dataKey='_id.time'
              domain={[0, 'auto']}
              dy={6}
              interval={10}
              // label={{ value: 'Time', position: 'bottom', fontSize: 'calc(10px + 0.4vw)', dy: (window.innerWidth / 60 - 25) }} // don't use window.innerWidth
              style={{ fontSize: 9, fontFamily: 'Nunito Sans', }}
              tickFormatter={timeStr => moment(timeStr).tz('America/New_York').add(0, 'minutes').format('h A')} />

            <YAxis
              dx={0}
              // domain={[
              //   0,
              //   dataMax => (Math.round((dataMax * 1.3) / parseInt('1'.padEnd(Math.log(dataMax * 1) * Math.LOG10E + 1 | 0, '0'))) * parseInt('1'.padEnd(Math.log(dataMax * 1) * Math.LOG10E + 1 | 0, '0')))
              // ]}
              // label={{ value: 'Traffic', angle: -90, position: 'left' }}
              style={{ fontSize: 9, fontFamily: 'Nunito Sans', }}
              // tickCount={6}
              tickFormatter={(tickItem) => { return (tickItem >= 1000 ? (tickItem / 1000).toLocaleString() + ' k' : tickItem.toLocaleString()) }}
              type='number' />

            {series.map((s, idx) => (
              [
                <Area
                  data={s.data}
                  dataKey='count'
                  fill={s.chartColor}
                  isAnimationActive={false}
                  // key={s.locationLabels}
                  key={idx}
                  name={s.locationLabels}
                  stroke={s.chartColor}
                  type='monotone' />,
              ]
            ))}
          </ComposedChart>
        }
      </Grid>
    )
  }
}

export default ByTimeAreaAll;
