import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';

import config from '../../utils/config';
import { clientData } from '../../utils/clients';

import {
  Button, Checkbox, Dialog, DialogContent, DialogContentText, DialogTitle,
  FormControl, FormControlLabel, FormHelperText, MenuItem, Switch, TextField
} from '@material-ui/core';


class EditUser extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showSuccess: false,

      admin: props.admin,
      client: props.client,
      company: props.company,
      dashboardaccess: props.dashboardaccess,
      email: props.email,
      emailreports: props.emailreports,
      fullname: props.fullname,
      locations: props.locations,
      locationsAll: props.locationsAll,
      roles: props.roles, // ['','video_only','video_streaming']

      errors: {},
    };

    this.baseState = this.state;
  }

  resetForm = () => {
    this.setState(this.baseState);
  }

  trimInput = (e) => {
    if (e.target.value !== e.target.value.trim()) {
      this.setState({ [e.target.name]: e.target.value.trim() });
    }
  }

  handleValueChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }); // mui select passes name instead of id
  };

  handleCheckChange = (e) => {
    this.setState({
      [e.target.name]: e.target.checked,
      ...(e.target.name === 'locationsAll' && { errors: { ...this.state.errors, locations: '' } }),
    });
  };

  validateLocations = (e) => {
    const locations = e.target.value.trim().replace(/\s*,?\s*$/g, ''); // trim any leading/trailing whitespace and trailing comma

    if (locations) {
      // 'some' iterates array until true, otherwise returns false - in this case 'true' indicates invalid pattern
      const invalid = locations.split(',').some(l => (/^\d{4}-\d+,?$/.test(l.trim())) ? false : true);

      if (invalid) return this.setState({ errors: { ...this.state.errors, locations: 'Check formatting, see example below.' } });
    }

    return this.setState({ errors: { ...this.state.errors, locations: '' } }); // locations either empty or no invalid pattern found, clear any location error
  }

  submitUser = async (e) => {
    e.preventDefault();
    const { admin, client, company, dashboardaccess, email, emailreports, errors, fullname, locations, locationsAll, roles } = this.state;

    if (!dashboardaccess) return this.setState({ showSuccess: false, errors: { ...this.state.errors, server: 'Only users with dashboard access can be updated.' } });
    if (!locationsAll && errors.locations) return this.setState({ showSuccess: false, errors: { ...this.state.errors, server: 'Fix or clear locations before proceeding.' } });

    // locations submitted without any changes are stringified array ('["0001-1","0001-2"]')
    // locations after edits are comma seperated string (0001-1,0001-2,)
    // replace quotes and brackets, convert to array, trim and pattern match -split locations into array, trim any whitespace, discard any not matching pattern 0000-1+, if no locations, returns empty []
    let locArray = (locationsAll) ? [] : locations.replace(/["'[\]]/g, '').split(',').map(loc => loc.trim()).filter(s => /^\d{4}-\d+,?$/.test(s.trim()));
    if (!locationsAll && !locArray.length) return this.setState({ showSuccess: false, errors: { ...this.state.errors, locations: 'No locations found. Enable \'All Locations\' or enter at least 1 location.' } });
    if (!locationsAll) locArray = [...new Set(locArray)]; // remove any duplicate locIds

    const roleArray = (Array.isArray(roles)) ? roles : roles.split(); // Roles are passed from fa in array, select submits as string

    try {
      const response = await axios.post(config.api.url + '/v2/admin/updateUser', {
        admin: admin,
        client: client,
        company: company,
        dashboardaccess: dashboardaccess,
        email: email,
        emailreports: emailreports,
        fullname: fullname,
        locations: locArray,
        locationsAll: locationsAll,
        originalAdmin: this.props.admin,
        originalEmail: this.props.email,
        roles: roleArray.filter(Boolean) // if no role change [''] to []
      });

      if (response.data.success) {
        this.props.userUpdated(); // refresh admin dashboard to show changes

        this.setState({
          errors: {},
          showSuccess: true,
        });
      }
    } catch (error) {
      if (error.response.status === 400 && error.response.data?.message?.toLowerCase()?.includes('email')) {
        return this.setState({
          showSuccess: false,
          errors: {
            email: error.response.data.message, // message: 'Email already registered.' status: 400
          }
        });
      }

      this.setState({
        showSuccess: false,
        errors: {
          server: (error?.response?.data?.message) ? error.response.data.message : 'Something went wrong, please try again.'
        }
      });
    }
  }

  render() {
    const { admin, client, company, dashboardaccess, email, emailreports, errors, fullname, locations, locationsAll, roles, showSuccess } = this.state;
    const updateLocs = (locationsAll) ? '' : locations.replace(/[^\d\-,]/g, ''); // only allow characters 0-9, -, and comma

    const userRoles = (clientData[client]?.userRoles) ? true : false;

    return (
      <Dialog
        fullWidth={true}
        maxWidth='sm' // 600px
        open={true}
        scroll='body'
        onClose={this.props.onClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Edit User</DialogTitle>

        <DialogContent dividers>
          <DialogContentText id='alert-dialog-description' color='primary'>
            User must have dashboard access to be updated.<br />
            All fields contain user's current info:
          </DialogContentText>

          <form onSubmit={this.submitUser} style={{ padding: '0 0 15px' }}>
            <FormControl fullWidth>

              <TextField
                required
                fullWidth
                autoComplete='false'
                variant='outlined'
                margin='dense'
                id='admin-id'
                name='admin'
                type='email'
                label='Admin Email'
                value={admin}
                // helperText={errors.adminEmail || ''}
                error={errors.admin ? true : false}
                onChange={this.handleValueChange}
              />

              <TextField
                required
                fullWidth
                autoComplete='false'
                variant='outlined'
                margin='dense'
                id='email-id'
                name='email'
                type='email'
                label='User Email'
                value={email}
                helperText={errors.email || ''}
                error={errors.email ? true : false}
                onChange={this.handleValueChange}
              />

              <TextField
                required
                fullWidth
                autoComplete='false'
                variant='outlined'
                margin='dense'
                id='fullname-id'
                name='fullname'
                type='text'
                label='Full Name'
                value={fullname}
                // helperText={errors.fullname || ''}
                error={errors.fullname ? true : false}
                onChange={this.handleValueChange}
                onBlur={this.trimInput}
              />

              <TextField
                fullWidth
                autoComplete='false'
                variant='outlined'
                margin='dense'
                id='company-id'
                name='company'
                type='text'
                label='Company'
                value={company}
                // helperText={errors.company || ''}
                error={errors.company ? true : false}
                onChange={this.handleValueChange}
                onBlur={this.trimInput}
              />

              <TextField
                required
                fullWidth
                autoComplete='false'
                variant='outlined'
                margin='dense'
                id='client-id'
                name='client'
                type='text'
                label='Client'
                value={client}
                placeholder={'lowercase only'}
                // helperText={errors.client || ''}
                error={errors.client ? true : false}
                onChange={this.handleValueChange}
                onBlur={this.trimInput}
              />

              <FormControlLabel
                control={<Switch checked={locationsAll} onChange={this.handleCheckChange} id='locationsAll-id' name='locationsAll' color='primary' />}
                label='All Locations'
              />

              {!locationsAll &&
                <>
                  <TextField
                    fullWidth
                    autoComplete='false'
                    multiline
                    minRows={2}
                    variant='outlined'
                    margin='dense'
                    id='locations-id'
                    name='locations'
                    type='text'
                    label='Locations'
                    value={updateLocs}
                    placeholder='0012-1,0012-2,0012-3'
                    helperText={errors.locations || ''}
                    error={errors.locations ? true : false}
                    onChange={this.handleValueChange}
                    onBlur={this.validateLocations}
                  />
                  <FormHelperText variant='outlined' margin='dense'>
                    Add locations by locId, separate with commas:<br />
                    <strong>0012-1, 0012-2, 0012-3</strong>
                  </FormHelperText>
                </>
              }

              <FormControlLabel
                control={<Checkbox disabled checked={dashboardaccess} onChange={this.handleCheckChange} id='dashboardaccess-id' name='dashboardaccess' color='primary' />}
                label='Dashboard Access'
              />
              <FormControlLabel
                control={<Checkbox checked={emailreports} onChange={this.handleCheckChange} id='emailreports-id' name='emailreports' color='primary' />}
                label='Email Reports'
              />

              {userRoles &&
                <TextField
                  select
                  SelectProps={{ displayEmpty: true }}
                  InputLabelProps={{ shrink: true }}
                  id='roles-id'
                  name='roles'
                  label='Role'
                  variant='outlined'
                  margin='dense'
                  value={roles.toString()}
                  onChange={this.handleValueChange}
                  helperText='Leave empty or select role from dropdown.'
                >
                  <MenuItem value={''}><em>None</em></MenuItem>
                  <MenuItem value={'video_streaming'}>Video Streaming</MenuItem>
                  <MenuItem value={'video_only'}>Only Video Streaming</MenuItem>
                </TextField>
              }

              <Button disableFocusRipple disableTouchRipple disableElevation variant='contained' color='primary' type='submit' style={{ marginTop: 10 }}>Submit</Button>
              <Button disableFocusRipple disableTouchRipple disableElevation variant='outlined' onClick={this.resetForm} style={{ marginTop: 10 }}>Reset</Button>

              {showSuccess && (
                <p style={{ margin: '4px 14px 4px', fontSize: 16, lineHeight: 1.66, fontFamily: 'Lato,sans-serif', color: '#43a047' }}>User updated successfully!</p>
              )}

              {errors.server && (
                <p style={{ margin: '4px 14px 4px', fontSize: 16, lineHeight: 1.66, fontFamily: 'Lato,sans-serif', color: '#f44336' }}>{errors.server}</p>
              )}
            </FormControl>
          </form>
        </DialogContent>
      </Dialog>
    )
  }
}

EditUser.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(EditUser);
