import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';

import config from '../../utils/config';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';


class DisableUser extends PureComponent {
  disableUser = async () => {
    try {
      const { admin, email } = this.props;
      if (!admin || !email) return alert('Admin or email are empty, refresh and try again');

      const response = await axios.delete(config.api.url + '/v2/admin/disableUser', { data: { admin: admin, email: email } });
      if (response.data.success) {
        this.props.userDisabled();
      }
    } catch (err) {
      alert('User could not be disabled, refresh and try again.');
      // console.log(err);
    }
  }

  render() {
    return (
      <Dialog
        open={true}
        onClose={this.props.onClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Disable User</DialogTitle>

        <DialogContent dividers>
          <DialogContentText id='alert-dialog-description' color='primary'>
            Are you sure you want to disable this user's account?
          </DialogContentText>

          <DialogContentText id='alert-dialog-description' color='primary'>
            <strong><em>{this.props.email}</em></strong>
          </DialogContentText>

        </DialogContent>

        <DialogActions>
          <Button onClick={this.props.onClose} color='primary'>Cancel</Button>
          <Button onClick={this.disableUser} color='primary'>Disable</Button>
        </DialogActions>
      </Dialog>
    )
  }
}

DisableUser.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(DisableUser);
