// https://carto.com/carto-colors/ https://google.github.io/palette.js/
// const colors = ['#E58606', '#5D69B1', '#52BCA3', '#99C945', '#CC61B0', '#24796C', '#DAA51B', '#2F8AC4', '#764E9F', '#ED645A', '#CC3A8E', '#A5AA99'];
// const colors = ['#5F4690', '#1D6996', '#38A6A5', '#0F8554', '#73AF48', '#EDAD08', '#E17C05', '#CC503E', '#94346E', '#6F4070', '#994E95', '#666666'];
// const colors = ['#277175', '#00313D', '#38a3a8', '#005a70', '#48d4db', '#0083a3'];
// const colors = ['#01579b', '#0288d1', '#03a9f4', '#4fc3f7', '#b3e5fc', '#0277bd'];

// https://experience.sap.com/fiori-design-web/values-and-names/
// const MEN_C1 = '#6089c4', MEN_C2 = '#A6BEEA';
// const WOMEN_C1 = '#d77da5', WOMEN_C2 = '#FCA2C9';
// const CHILDREN_C1 = '#7ea84e', CHILDREN_C2 = '#b1eb6e';
// const ADULT_C1 = '#ffd000', ADULT_C2 = '#ffe380';
// const SENIOR_C1 = '#643d23', SENIOR_C2 = '#a07c5c';

const MEN_C1 = '#6089c4', MEN_C2 = '#6089c4';
const WOMEN_C1 = '#d77da5', WOMEN_C2 = '#d77da5';
const CHILDREN_C1 = '#7ea84e', CHILDREN_C2 = '#7ea84e';
const ADULT_C1 = '#ffd000', ADULT_C2 = '#ffd000';
const SENIOR_C1 = '#643d23', SENIOR_C2 = '#643d23';

const BIKE_C1 = '#7ea84e', BIKE_C2 = '#7ea84e';
const BUS_C1 = '#ffd000', BUS_C2 = '#ffd000';
const CAR_C1 = '#643d23', CAR_C2 = '#643d23';
const MOTORBIKE_C1 = '#d77da5', MOTORBIKE_C2 = '#d77da5';
const TRUCK_C1 = '#6089c4', TRUCK_C2 = '#6089c4';

export const colorsMen = [MEN_C1, MEN_C2, MEN_C1, MEN_C2, MEN_C1, MEN_C2, MEN_C1, MEN_C2, MEN_C1, MEN_C2, MEN_C1, MEN_C2, MEN_C1, MEN_C2, MEN_C1, MEN_C2, MEN_C1, MEN_C2, MEN_C1, MEN_C2, MEN_C1, MEN_C2];
export const colorsWomen = [WOMEN_C1, WOMEN_C2, WOMEN_C1, WOMEN_C2, WOMEN_C1, WOMEN_C2, WOMEN_C1, WOMEN_C2, WOMEN_C1, WOMEN_C2, WOMEN_C1, WOMEN_C2, WOMEN_C1, WOMEN_C2, WOMEN_C1, WOMEN_C2, WOMEN_C1, WOMEN_C2];

export const colorsChildren = [CHILDREN_C1, CHILDREN_C2, CHILDREN_C1, CHILDREN_C2, CHILDREN_C1, CHILDREN_C2, CHILDREN_C1, CHILDREN_C2, CHILDREN_C1, CHILDREN_C2, CHILDREN_C1, CHILDREN_C2, CHILDREN_C1, CHILDREN_C2, CHILDREN_C1, CHILDREN_C2, CHILDREN_C1, CHILDREN_C2, CHILDREN_C1, CHILDREN_C2, CHILDREN_C1, CHILDREN_C2];
export const colorsAdults = [ADULT_C1, ADULT_C2, ADULT_C1, ADULT_C2, ADULT_C1, ADULT_C2, ADULT_C1, ADULT_C2, ADULT_C1, ADULT_C2, ADULT_C1, ADULT_C2, ADULT_C1, ADULT_C2, ADULT_C1, ADULT_C2, ADULT_C1, ADULT_C2, ADULT_C1, ADULT_C2, ADULT_C1, ADULT_C2];
export const colorsSeniors = [SENIOR_C1, SENIOR_C2, SENIOR_C1, SENIOR_C2, SENIOR_C1, SENIOR_C2, SENIOR_C1, SENIOR_C2, SENIOR_C1, SENIOR_C2, SENIOR_C1, SENIOR_C2, SENIOR_C1, SENIOR_C2, SENIOR_C1, SENIOR_C2, SENIOR_C1, SENIOR_C2, SENIOR_C1, SENIOR_C2, SENIOR_C1, SENIOR_C2];

export const colorsBikes = [BIKE_C1, BIKE_C2, BIKE_C1, BIKE_C2, BIKE_C1, BIKE_C2, BIKE_C1, BIKE_C2, BIKE_C1, BIKE_C2, BIKE_C1, BIKE_C2, BIKE_C1, BIKE_C2, BIKE_C1, BIKE_C2, BIKE_C1, BIKE_C2];
export const colorsBuses = [BUS_C1, BUS_C2, BUS_C1, BUS_C2, BUS_C1, BUS_C2, BUS_C1, BUS_C2, BUS_C1, BUS_C2, BUS_C1, BUS_C2, BUS_C1, BUS_C2, BUS_C1, BUS_C2, BUS_C1, BUS_C2, BUS_C1, BUS_C2, BUS_C1, BUS_C2];
export const colorsCars = [CAR_C1, CAR_C2, CAR_C1, CAR_C2, CAR_C1, CAR_C2, CAR_C1, CAR_C2, CAR_C1, CAR_C2, CAR_C1, CAR_C2, CAR_C1, CAR_C2, CAR_C1, CAR_C2, CAR_C1, CAR_C2, CAR_C1, CAR_C2, CAR_C1, CAR_C2];
export const colorsMotorbikes = [MOTORBIKE_C1, MOTORBIKE_C2, MOTORBIKE_C1, MOTORBIKE_C2, MOTORBIKE_C1, MOTORBIKE_C2, MOTORBIKE_C1, MOTORBIKE_C2, MOTORBIKE_C1, MOTORBIKE_C2, MOTORBIKE_C1, MOTORBIKE_C2, MOTORBIKE_C1, MOTORBIKE_C2, MOTORBIKE_C1, MOTORBIKE_C2, MOTORBIKE_C1, MOTORBIKE_C2, MOTORBIKE_C1, MOTORBIKE_C2, MOTORBIKE_C1, MOTORBIKE_C2];
export const colorsTrucks = [TRUCK_C1, TRUCK_C2, TRUCK_C1, TRUCK_C2, TRUCK_C1, TRUCK_C2, TRUCK_C1, TRUCK_C2, TRUCK_C1, TRUCK_C2, TRUCK_C1, TRUCK_C2, TRUCK_C1, TRUCK_C2, TRUCK_C1, TRUCK_C2, TRUCK_C1, TRUCK_C2, TRUCK_C1, TRUCK_C2, TRUCK_C1, TRUCK_C2];

export const heatmapColors = {
  counts: ['#ffffe5', '#f7fcb9', '#d9f0a3', '#addd8e', '#78c679', '#41ab5d', '#238443', '#006837'],

  men: ['#eff3f9', '#dae3f1', '#c6d4ea', '#b2c5e2', '#9eb5db', '#89a6d3', '#7598cc', '#6089c4'],
  women: ['#fcf5f8', '#f7e4ec', '#f3d3e0', '#eec2d5', '#e8b1c9', '#e3a0bd', '#dd8eb1', '#d77da5'],
  children: ['#f2f6ed', '#e1ebd6', '#d1e0bf', '#c0d4a8', '#b0c991', '#9fbe7b', '#8fb365', '#7ea84e'],
  adults: ['#fffae5', '#fff5cb', '#ffefb1', '#ffe997', '#ffe37c', '#ffdd5f', '#ffd73e', '#ffd000'],
  seniors: ['#efebe9', '#dcd0ca', '#c8b6ac', '#b59c8e', '#a18372', '#8d6b56', '#79533c', '#643d23'],

  bikes: ['#f2f6ed', '#e1ebd6', '#d1e0bf', '#c0d4a8', '#b0c991', '#9fbe7b', '#8fb365', '#7ea84e'],
  buses: ['#fffae5', '#fff5cb', '#ffefb1', '#ffe997', '#ffe37c', '#ffdd5f', '#ffd73e', '#ffd000'],
  cars: ['#efebe9', '#dcd0ca', '#c8b6ac', '#b59c8e', '#a18372', '#8d6b56', '#79533c', '#643d23'],
  motorbikes: ['#fcf5f8', '#f7e4ec', '#f3d3e0', '#eec2d5', '#e8b1c9', '#e3a0bd', '#dd8eb1', '#d77da5'],
  trucks: ['#eff3f9', '#dae3f1', '#c6d4ea', '#b2c5e2', '#9eb5db', '#89a6d3', '#7598cc', '#6089c4'],
}
