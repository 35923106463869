import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

import AddIcon from '@material-ui/icons/Add';
import {
  ClickAwayListener,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Popover,
  Radio,
  Typography
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = ({
  location: {
    marginTop: 0,
    paddingLeft: 0,
    color: '#3A3A3A',
  },
  locMenu: {
    backgroundColor: '#D6D6D6',
    maxWidth: 600,
  },
  root: {},
  checked: {}
});

const initLocationChecked = (locList) => {
  let locationChecked = {};

  for (let l in locList) {
    let locName = locList[l];
    locationChecked[locName] = false;
  }

  return locationChecked;
}

const initOwnerChecked = (ownerList) => {
  let ownerChecked = {};

  if (Object.keys(ownerList).length === 1) {
    ownerChecked[Object.keys(ownerList)[0]] = false;
  }
  else {
    for (let o in ownerList) {
      ownerChecked[o] = false;
      let ownerDivisions = ownerList[o];

      if (!Array.isArray(ownerDivisions)) {
        for (let od in ownerDivisions) {
          ownerChecked[od] = false;
        }
      }
    }
  }

  return ownerChecked;
}

class LocationConvCompare extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      cardState: {
        name: this.props.name,
        aggregate: true,
        aggregationType: 'average', // defaults to average
        locationChecked: initLocationChecked(this.props.locationsObject['locationList']),
        locationChecked2: initLocationChecked(this.props.locationsObject['locationList']),
        checkedLocOwners: initOwnerChecked(this.props.locationsObject['locOwners']),
        checkedLocCategories: Object.keys(this.props.locationsObject['locCategories']).reduce((acc, category) => ({ ...acc, [category]: false }), {}),
      },
      menuOpen: false,
      menuAnchor: null,
      newCard: false,
    }
  }

  changeLoc = (num, name) => event => {
    let cardState = this.state.cardState;
    let locChecked = cardState.locationChecked;
    let locChecked2 = cardState.locationChecked2;
    let tempCheckedLocOwners = cardState.checkedLocOwners;
    let tempCheckedLocCategories = cardState.checkedLocCategories;

    if (Object.keys(tempCheckedLocOwners).length === 1) {
      tempCheckedLocOwners[Object.keys(tempCheckedLocOwners)[0]] = false;
    }
    else {
      for (let i in tempCheckedLocOwners) {
        tempCheckedLocOwners[i] = false;
      }
    }

    for (let i in tempCheckedLocCategories) {
      tempCheckedLocCategories[i] = false;
    }

    cardState.checkedLocOwners = tempCheckedLocOwners;
    cardState.checkedLocCategories = tempCheckedLocCategories;

    if (num === 1) {
      for (let l in locChecked) {
        locChecked[l] = false;
      }
      locChecked[name] = event.target.checked;
      cardState.locationChecked = locChecked;
    }
    else {
      for (let l in locChecked2) {
        locChecked2[l] = false;
      }
      locChecked2[name] = event.target.checked;
      cardState.locationChecked2 = locChecked2;
    }

    this.setState({ ...this.state, cardState: cardState }, () => {
      this.props.onChange(cardState);
    });
  };

  handleClick = event => {
    let cardState = this.state.cardState;

    // reset cardState
    if (!this.state.menuOpen) {
      let locChecked = cardState.locationChecked;
      let locChecked2 = cardState.locationChecked2;
      let tempCheckedLocOwners = cardState.checkedLocOwners;
      let tempCheckedLocCategories = cardState.checkedLocCategories;

      for (let l in locChecked) {
        locChecked[l] = false;
      }
      for (let l in locChecked2) {
        locChecked2[l] = false;
      }
      for (let i in tempCheckedLocOwners) {
        tempCheckedLocOwners[i] = false;
      }
      for (let i in tempCheckedLocCategories) {
        tempCheckedLocCategories[i] = false;
      }

      cardState.locationChecked = locChecked;
      cardState.locationChecked2 = locChecked2;
      cardState.checkedLocOwners = tempCheckedLocOwners;
      cardState.checkedLocCategories = tempCheckedLocCategories;
      cardState.aggregate = true;
      cardState.aggregationType = 'average';
    }

    this.setState({
      newCard: this.state.menuOpen,
      menuOpen: !this.state.menuOpen,
      menuAnchor: event.currentTarget,
      cardState: cardState,
    });
  };

  handleClickAway = () => {
    this.setState({ menuOpen: false, menuAnchor: null, newCard: !this.state.newCard });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let cardState = prevState.cardState;

    if (nextProps.noCard !== prevState.noCard) {
      if (nextProps.noCard) {
        cardState.name = 0;
      }
    }

    if (nextProps.name !== prevState.cardState.name && prevState.newCard) {
      cardState.name = nextProps.name;
    }

    return {
      cardState: cardState,
    };
  }

  render() {
    const { classes, compareActiveLoc, locationsObject, noCard } = this.props;
    const { cardState, menuAnchor, menuOpen } = this.state;

    return (
      <Grid
        container
        alignItems='center'
        justifyContent='center'
        style={{
          height: 40, width: '100%',
          color: compareActiveLoc || noCard ? '#02313D' : '#4d6e77',
          backgroundColor: compareActiveLoc || noCard ? '#f5f5f5' : '#B3C0C4',
          fontWeight: 600, lineHeight: '115%', paddingLeft: 12, paddingRight: 8, fontSize: 'calc(7px + 0.2vw)'
        }}
        onClick={this.handleClick}
        disabled={compareActiveLoc ? false : true}
      >
        LOCATION
        <AddIcon justify='center' style={{ width: 'calc(8px + 0.4vw)', paddingBottom: '0.07em', paddingLeft: '0.1vw', display: '' }} />
        <Popover
          open={menuOpen}
          anchorEl={menuAnchor}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <ClickAwayListener onClickAway={this.handleClickAway}>
            <Paper className={classes.locMenu} style={{ width: window.innerWidth > 767 ? '100%' : '72vw' }}>
              <Grid container alignItems='flex-start' direction='row'>
                <Grid item>
                  <FormGroup style={{ paddingTop: 16, paddingLeft: 30, paddingRight: 10, paddingBottom: 15 }}>
                    {locationsObject['locationList'].map((value, index) => {
                      return <FormControlLabel
                        key={'fc' + index}
                        className={classes.location}
                        control={<Radio style={{ marginTop: -4, color: locationsObject['colors'][index] }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[index]]} onChange={this.changeLoc(1, Object.keys(cardState.locationChecked)[index])} value={Object.keys(cardState.locationChecked)[index]} />}
                        label={<Typography color='textPrimary'>{Object.keys(cardState.locationChecked)[index]}</Typography>}
                      />
                    })}
                  </FormGroup>
                </Grid>
                <Divider orientation='vertical' flexItem />
                <Grid item>
                  <FormGroup style={{ paddingTop: 16, paddingLeft: 30, paddingRight: 10, paddingBottom: 15 }}>
                    {locationsObject['locationList'].map((value, index) => {
                      return <FormControlLabel
                        key={'fc' + index}
                        className={classes.location}
                        control={<Radio style={{ marginTop: -4, color: locationsObject['colors'][index] }} checked={cardState.locationChecked2[Object.keys(cardState.locationChecked2)[index]]} onChange={this.changeLoc(2, Object.keys(cardState.locationChecked2)[index])} value={Object.keys(cardState.locationChecked2)[index]} />}
                        label={<Typography color='textPrimary'>{Object.keys(cardState.locationChecked2)[index]}</Typography>}
                      />
                    })}
                  </FormGroup>
                </Grid>
              </Grid>
            </Paper>
          </ClickAwayListener>
        </Popover>
      </Grid>
    )
  }
}

export default withRouter(withStyles(styles)(LocationConvCompare));
