import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { showInfo } from '../../utils/services';
import { loginUser } from '../../actions/authActions';
import exterosLogo from './exteros_blue.png';
import plugoutLogo from './plugout_blue.png';

import Alert from '@material-ui/lab/Alert';
import { Button, FormControl, Grid, TextField, Snackbar } from '@material-ui/core';

const getLogo = (url) => {
  if (url.includes('plugout')) return plugoutLogo;

  return exterosLogo;
}

class Login extends Component {
  constructor() {
    super();

    this.state = {
      email: '',
      password: '',
      errors: {},
      loginInfo: showInfo(),
      logo: getLogo(window.location.href)
    };
  }

  componentDidMount() {
    document.title = 'Login - Exteros';

    if (this.props.auth.isAuthenticated) {
      this.props.history.push('/dashboard'); // If logged in and user navigates to Login page, redirect to dashboard
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push('/dashboard');
    }

    if (!nextProps.errors.success) {
      this.setState({
        errors: {
          server: (nextProps.errors.message !== '') ? nextProps.errors.message : 'Something went wrong, please try again.'
        }
      });
    }
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ loginInfo: false });
  };

  handleChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { email, password } = this.state;

    if (email === '' || password === '') {
      return this.setState({
        errors: {
          email: (email === '') ? 'Please enter your email to continue.' : '',
          password: (password === '') ? 'Please enter your password to continue.' : ''
        }
      });
    }

    this.setState({
      errors: {}
    });

    this.props.loginUser({ email, password });
  };

  render() {
    const { email, password, errors } = this.state;

    return (
      <Grid container style={{ marginTop: "25vh" }} direction="row" justifyContent="center" alignItems="baseline" >
        <FormControl>
          <img alt="Logo" src={this.state.logo} style={{ marginBottom:"2vw", width:"25vw", height:"100%", minWidth:"280px" }}/>
          <TextField
            required
            fullWidth
            variant="filled"
            margin="dense"
            id="email"
            type="email"
            label="Email"
            value={email}
            error={errors.email ? true : false}
            helperText={errors.email}
            autoFocus={true}
            className={errors.email ? 'invalid' : ''}
            onChange={this.handleChange}
          />
          <TextField
            required
            fullWidth
            variant="filled"
            margin="dense"
            id="password"
            type="password"
            label="Password"
            value={password}
            error={errors.password ? true : false}
            helperText={errors.password}
            className={errors.password ? 'invalid' : ''}
            onChange={this.handleChange}
            onKeyDown={(ev) => {
              if (ev.key === 'Enter') { this.handleSubmit(ev); }
            }}
          />
          <span className="red-text"  style={{ color: "#f44336" }}>
            {errors.server}
          </span>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
            style={{ borderRadius: "3px", letterSpacing: "1.5px", marginTop: "20px" }}
            className="btn btn-large waves-effect waves-light hoverable accent-3"
            onClick={this.handleSubmit}
          >
            SIGN IN
          </Button>
          <div>
            <p style={{ width:'25vw', minWidth:'280px' }}>
              <a href="/forgot-password">Forgot Password</a>
            </p>
          </div>
        </FormControl>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={this.state.loginInfo}
          transitionDuration={{ enter: 600, exit: 1000 }}
          autoHideDuration={6000}
          onClose={this.handleClose}
        >
          <Alert onClose={this.handleClose} severity="info">Session expired, please sign in again.</Alert>
        </Snackbar>
      </Grid>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(mapStateToProps, { loginUser })(Login);
