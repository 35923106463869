import React, { PureComponent } from 'react';
import moment from 'moment';
import { Text, Line, Symbols, XAxis, YAxis, Surface, Legend, ComposedChart } from 'recharts';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';

class CustomizedAxisTick extends PureComponent {

  render() {
    const { x, y, payload, interval } = this.props;
    const axisDate = moment(payload.value * 1000).tz('America/New_York');

    return (
      <g transform={`translate(${x - 5}, ${y + 10}) rotate(30, 0, 0)`}>
        <Text x={0} y={0} dx={0} dy={0} textAnchor='start' fill='#666' align='left' style={{ fontWeight: 600, fontSize: 11, fontFamily: 'Nunito Sans' }}>
          {(interval === 'Year') ? axisDate.format('YYYY') : (interval === 'Month') ? axisDate.format('MMM') : axisDate.format('M-D-YY')}
        </Text>
        <Text x={0} y={12} dx={0} dy={0} textAnchor='start' align='left' fill='#a6a6a6' style={{ fontWeight: 600, fontSize: 10, fontFamily: 'Nunito Sans' }}>
          {(interval === 'Year') ? '' : (interval === 'Month') ? axisDate.format('YYYY') : (interval === 'Week') ? 'week of' : axisDate.format('ddd')}
        </Text>
      </g>
    );
  }
}


class ByDateLine extends PureComponent {

  renderIcon(data) {
    const SIZE = 32;
    const { inactiveColor } = this.props;
    const halfSize = SIZE / 2;
    const sixthSize = SIZE / 6;
    const thirdSize = SIZE / 3;
    const color = data.inactive ? inactiveColor : data.color;
    var newStroke = data.payload.strokeDasharray;
    if (newStroke !== '') {
      newStroke = '5 3'
    }

    if (data.type === 'plainline') {
      return (
        <line
          strokeWidth={4}
          fill='none'
          stroke={color}
          strokeDasharray={newStroke}
          x1={0}
          y1={halfSize}
          x2={SIZE}
          y2={halfSize}
          className='recharts-legend-icon'
        />
      );
    } if (data.type === 'line') {
      return (
        <path
          strokeWidth={4}
          fill='none'
          stroke={color}
          strokeDasharray={newStroke}
          d={`M0,${halfSize}h${thirdSize}
            A${sixthSize},${sixthSize},0,1,1,${2 * thirdSize},${halfSize}
            H${SIZE}M${2 * thirdSize},${halfSize}
            A${sixthSize},${sixthSize},0,1,1,${thirdSize},${halfSize}`}
          className='recharts-legend-icon'
        />
      );
    } if (data.type === 'rect') {
      return (
        <path
          stroke='none'
          fill={color}
          d={`M0,${SIZE / 8}h${SIZE}v${SIZE * 3 / 4}h${-SIZE}z`}
          className='recharts-legend-icon'
        />
      );
    }

    return (
      <Symbols
        fill={color}
        cx={halfSize}
        cy={halfSize}
        size={SIZE}
        sizeType='diameter'
        type={data.type}
      />
    );
  }

  renderLegend = (props) => {
    const { payload } = props;
    // const numLines = (this.state.multDates) ? (this.state.numPlots + 1) : (this.state.numPlots);
    // const active = payload.filter(line => !line.inactive); // not needed, this chart only sends data for active plots

    var ICONSIZE = 24;
    const viewBox = { x: 0, y: 0, width: ICONSIZE, height: ICONSIZE };
    ICONSIZE = 14;
    // if (this.state.multDates) {
    //   ICONSIZE = ICONSIZE / 1.3;
    // }
    let fontAdjust = ICONSIZE;

    return (
      <ul ref={this.byDateLegRef} className='recharts-default-legend' style={{ position: 'absolute', width: 330, top: 0, right: 0 }}>
        {payload.map((entry, index) => (
          <li key={`item-${index}-${entry.dataKey}`} style={{ display: entry.inactive ? 'none' : 'inline', textAlign: 'left' }}>
            <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start' style={{ marginTop: ICONSIZE / 3, display: '' }}>
              <Surface xs={1} width={ICONSIZE * 2} height={ICONSIZE * 1.1} viewBox={viewBox} style={{ display: '', paddingRight: fontAdjust / 2, backgroundColor: alpha('#ffffff', 0.5) }}>
                {this.renderIcon(entry)}
              </Surface>
              <Grid container xs item style={{ display: '', paddingRight: fontAdjust / 2 }}>
                <Typography style={{ fontSize: fontAdjust, backgroundColor: alpha('#ffffff', 0.5) }} className='recharts-legend-item-text'>
                  {entry.value}
                </Typography>
              </Grid>
            </Grid>
          </li>
        ))}
      </ul>
    );
  }

  render() {
    const { series, interval } = this.props;
    const intervalLabel = (interval !== 'Day') ? (' (' + interval + 'ly)') : '';

    let dataPoints = 0;
    try {
      for (let ser in series) {
        if (series[ser].data.length > dataPoints) {
          dataPoints = series[ser].data.length;
        }
      }
    } catch (err) { }

    return (
      <Grid item xs={12} style={{ height: '100%' }}>
        <Typography style={{ fontWeight: 700, fontFamily: 'Nunito Sans', fontSize: 17 }}>Traffic by Date{intervalLabel}</Typography>

        {dataPoints === 0 &&
          <Grid container item justifyContent='space-around' alignItems='center'>
            <CircularProgress size={'calc(1vw + 1vh)'} style={{ marginTop: 'calc(1vw + 2vh)' }} />
          </Grid>
        }

        {dataPoints > 0 &&
          <ComposedChart width={756} height={360} style={{}} margin={{ top: 20, right: 47, left: 2, bottom: 30 }}>

            <XAxis
              dataKey='_id.timeStamp'
              domain={['auto', 'auto']}
              // interval='preserveStartEnd'
              label={{ value: 'Date', position: 'bottom', fontSize: 13, fontWeight: 600, fontFamily: 'Nunito Sans', dy: 15 }}
              scale='utc'
              tick={<CustomizedAxisTick interval={interval} />}
              type='number' />

            <YAxis
              allowDecimals={false}
              domain={[0, dataMax => (dataMax < 0.35 && dataMax !== 0) ? 1 : (Math.round((dataMax * 1.5) / parseInt('1'.padEnd(Math.log(dataMax * 1) * Math.LOG10E + 1 | 0, '0'))) * parseInt('1'.padEnd(Math.log(dataMax * 1) * Math.LOG10E + 1 | 0, '0')))]}
              dx={0}
              label={{ value: 'Counts', angle: -90, position: 'left', fontSize: 13, fontWeight: 600, fontFamily: 'Nunito Sans', dx: 18, dy: -16 }}
              style={{ fontSize: 12, fontFamily: 'Nunito Sans', }}
              tickCount={6}
              tickFormatter={(tickItem) => { return (tickItem >= 1000 ? (tickItem / 1000).toLocaleString() + ' k' : tickItem.toLocaleString()) }}
              type='number' />

            {series.map((s, idx) => (
              [
                <Line
                  data={s.data}
                  dataKey='count'
                  dot={true}
                  isAnimationActive={false}
                  // key={s.locationLabels}
                  key={idx}
                  name={s.chartLbl}
                  stroke={s.chartColor}
                  strokeDasharray={''}
                  strokeWidth={3}
                  type='monotoneX' />,
              ]
            ))}

            <Legend iconType='line' align='right' verticalAlign='top' layout='vertical' wrapperStyle={{ right: 0, top: 0 }} content={this.renderLegend} />
          </ComposedChart>

        }
      </Grid>
    )
  }
}

export default ByDateLine;
