import React, { Fragment, PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

import { Add as AddIcon, ExpandMore as ExpandMoreIcon, KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import {
  Checkbox,
  ClickAwayListener,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  Paper,
  Popover,
  Radio,
  RadioGroup,
  Switch,
  Typography,
  Accordion,
  AccordionDetails
} from '@material-ui/core';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import { withStyles } from '@material-ui/core/styles';

const styles = ({
  location: {
    marginTop: 0,
    paddingLeft: 0,
    color: '#3A3A3A',
  },
  subLocation: {
    paddingLeft: 20,
  },
  subsubLocation: {
    paddingLeft: 40,
  },
  locGroupLbl: {
    padding: '10px 0 6px',
    textAlign: 'left',
    borderBottom: '1px solid black',
    marginBottom: '5px',
    fontSize: '14px'
  },
  cardContainer: {
    cursor: 'pointer',
    height: '100%',
    width: '100%',
  },
  locationCard: {
    cursor: 'pointer',
    position: 'relative',
    backgroundColor: '#f5f5f5',
  },
  locMenu: {
    backgroundColor: '#D6D6D6',
  },
  subMenu: {
    backgroundColor: '#D6D6D6',
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
  },
  root: {},
  checked: {},
  // MuiAccordionroot: {
  //   '&.MuiAccordion-root:before': {
  //     height: 0
  //   }
  // },
  locationsGrid: {
    '& label > span:first-child': {
      padding: '7px 9px',
    },
  },
  dropDownContainer: {
    paddingRight: 25
  }
});

const StyledRadio = withStyles({
  root: {
    color: '#7A7A7A',
    '&$checked': {
      color: '#277175',
    },
  },
  checked: {},
})(props => <Radio size='small' {...props} />);

const AccordionSummary = withStyles({
  expanded: {},
  content: {
    margin: 0,
    '&$expanded': {
      margin: 0,
    },
  },
  expandIcon: {
    padding: '6px 12px'
  }
})(MuiAccordionSummary);

const initLocationChecked = (locList) => {
  let locationChecked = {};

  for (let l in locList) {
    let locName = locList[l];
    locationChecked[locName] = false;
  }

  return locationChecked;
}

const initOwnerChecked = (ownerList) => {
  let ownerChecked = {};

  if (Object.keys(ownerList).length === 1) {
    ownerChecked[Object.keys(ownerList)[0]] = false;
  }
  else {
    for (let o in ownerList) {
      ownerChecked[o] = false;
      let ownerDivisions = ownerList[o];

      if (!Array.isArray(ownerDivisions)) {
        for (let od in ownerDivisions) {
          ownerChecked[od] = false;
        }
      }
    }
  }

  return ownerChecked;
}

class LocationCompare3 extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      openObj: this.props.locationsObject['defaultOpen'],
      cardState: {
        name: this.props.name,
        aggregate: false,
        aggregationType: 'total', // defaults to total
        locationChecked: initLocationChecked(this.props.locationsObject['locationList']),
        checkedLocOwners: initOwnerChecked(this.props.locationsObject['locOwners']),
        checkedLocCategories: Object.keys(this.props.locationsObject['locCategories']).reduce((acc, category) => ({ ...acc, [category]: false }), {}),
      },
      menuAnchor: null,
      menuOpen: false,
    }
  }

  dropDownToggle = e => {
    this.setState({
      openObj: {
        ...this.state.openObj,
        [e]: !this.state.openObj[e]
      }
    });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let cardState = Object.assign({}, prevState.cardState);

    if (nextProps.name !== prevState.cardState.name && !prevState.cardState.aggregate) {
      cardState.name = nextProps.name;

      return {
        cardState: cardState
      }
    }
    else if (nextProps.name !== prevState.cardState.name && prevState.cardState.aggregate) {

      return {
        cardState: cardState
      }
    }

    return {}
  }

  handleClick = event => {
    let cardState = this.state.cardState;

    // reset cardState
    if (!this.state.menuOpen) {
      let locChecked = cardState.locationChecked;
      let tempCheckedLocOwners = cardState.checkedLocOwners;
      let tempCheckedLocCategories = cardState.checkedLocCategories;

      for (let l in locChecked) {
        locChecked[l] = false;
      }
      for (let i in tempCheckedLocOwners) {
        tempCheckedLocOwners[i] = false;
      }
      for (let i in tempCheckedLocCategories) {
        tempCheckedLocCategories[i] = false;
      }

      cardState.locationChecked = locChecked;
      cardState.checkedLocOwners = tempCheckedLocOwners;
      cardState.checkedLocCategories = tempCheckedLocCategories;
      cardState.aggregate = false;
      cardState.aggregationType = 'total';
    }

    this.setState({
      menuOpen: !this.state.menuOpen,
      menuAnchor: event.currentTarget,
      cardState: cardState,
      ...(!this.state.menuOpen && { openObj: this.props.locationsObject['defaultOpen'] })
    });
  };

  changeLocCategory = catName => event => {
    let cardState = this.state.cardState;
    let locChecked = cardState.locationChecked;
    let tempCheckedLocOwners = cardState.checkedLocOwners;
    let tempCheckedLocCategories = cardState.checkedLocCategories;

    if (Object.keys(tempCheckedLocOwners).length === 1) {
      tempCheckedLocOwners[Object.keys(tempCheckedLocOwners)[0]] = false;
    }
    else {
      for (let i in tempCheckedLocOwners) {
        tempCheckedLocOwners[i] = false;
      }
    }

    for (let i in tempCheckedLocCategories) {
      tempCheckedLocCategories[i] = false;
    }

    if (event.target.checked) tempCheckedLocCategories[catName] = true;

    let catLocs = this.props.locationsObject.locCategories[catName]; // array of all locations within a category
    catLocs = (catLocs && catLocs.length > 0) ? catLocs.filter(loc => !this.props.locationsObject.noCompare.includes(loc)) : []; // if location should be excluded, remove from array

    for (let l in locChecked) {
      if (catLocs.indexOf(l) > -1) {
        locChecked[l] = true;
      }
      else {
        locChecked[l] = false;
      }
    }

    cardState.locationChecked = locChecked;
    cardState.checkedLocOwners = tempCheckedLocOwners;
    cardState.checkedLocCategories = tempCheckedLocCategories;
    cardState.aggregate = true;
    cardState.aggregationType = this.props.locationsObject.aggregationType[catName];

    this.setState({ menuOpen: false, menuAnchor: null }, () => {
      this.props.onChange(cardState);
    });
  }

  changeOwner = name => event => {
    let cardState = this.state.cardState;
    let locChecked = cardState.locationChecked;
    let tempCheckedLocOwners = cardState.checkedLocOwners;
    let tempCheckedLocCategories = cardState.checkedLocCategories;
    const tempLocOwners = this.props.locationsObject['locOwners'];

    if (Object.keys(tempCheckedLocOwners).length === 1) {
      tempCheckedLocOwners[Object.keys(tempCheckedLocOwners)[0]] = false;
    }
    else {
      for (let i in tempCheckedLocOwners) {
        tempCheckedLocOwners[i] = false;
      }
    }

    for (let i in tempCheckedLocCategories) {
      tempCheckedLocCategories[i] = false;
    }

    if (event.target.checked) tempCheckedLocOwners[name] = true;

    let ownerLocs = [];
    let owner = tempLocOwners[name];

    if (typeof owner === 'undefined') {
      for (let o in tempLocOwners) {
        let ownerDivisions = tempLocOwners[o];

        if (!Array.isArray(ownerDivisions)) {
          let ownerDivision = ownerDivisions[name];

          if (typeof ownerDivision !== 'undefined') {
            ownerLocs = ownerDivision;
          }
        }
      }
    }
    else if (Object.keys(tempCheckedLocOwners).length === 1) {
      ownerLocs = tempLocOwners[name];
    }
    else if (!Array.isArray(owner)) {
      for (let od in owner) {
        if (event.target.checked) {
          tempCheckedLocOwners[od] = true;
          ownerLocs = ownerLocs.concat(owner[od]);
        }
      }
    }
    else {
      ownerLocs = tempLocOwners[name];
    }

    ownerLocs = (ownerLocs.length > 0) ? ownerLocs.filter(loc => !this.props.locationsObject.noCompare.includes(loc)) : [];

    for (let l in locChecked) {
      if (ownerLocs.indexOf(l) > -1) {
        locChecked[l] = true;
      }
      else {
        locChecked[l] = false;
      }
    }

    cardState.aggregate = true;
    cardState.locationChecked = locChecked;
    cardState.checkedLocOwners = tempCheckedLocOwners;
    cardState.checkedLocCategories = tempCheckedLocCategories;

    this.setState({ menuOpen: false, menuAnchor: null }, () => {
      this.props.onChange(cardState);
    });
  }

  changeLoc = name => event => {
    let updateObj = (this.props.locationsObject.fullDetails[name].expandLocation && this.state.openObj[name]) ? true : false;

    let cardState = this.state.cardState;
    let locChecked = cardState.locationChecked;
    let tempCheckedLocOwners = cardState.checkedLocOwners;
    let tempCheckedLocCategories = cardState.checkedLocCategories;

    if (Object.keys(tempCheckedLocOwners).length === 1) {
      tempCheckedLocOwners[Object.keys(tempCheckedLocOwners)[0]] = false;
    }
    else {
      for (let i in tempCheckedLocOwners) {
        tempCheckedLocOwners[i] = false;
      }
    }

    for (let i in tempCheckedLocCategories) {
      tempCheckedLocCategories[i] = false;
    }

    cardState.checkedLocOwners = tempCheckedLocOwners;
    cardState.checkedLocCategories = tempCheckedLocCategories;

    if (cardState.aggregate) {
      const parentLocsObj = this.props.locationsObject['pLocs'];
      const childLocsObj = this.props.locationsObject['cLocs'];
      locChecked[name] = event.target.checked;

      if (Object.keys(childLocsObj).includes(name)) {
        for (const loc of childLocsObj[name]) {
          locChecked[loc] = false; // if name is a child location, uncheck its parent
        }
      }

      if (Object.keys(parentLocsObj).includes(name)) {
        for (const loc of parentLocsObj[name]) {
          locChecked[loc] = false; // if name is a parent location, uncheck all of its child locations
        }
      }

      cardState.locationChecked = locChecked;

      this.setState({
        ...this.state,
        cardState: cardState,
        ...(updateObj && { openObj: { ...this.state.openObj, [name]: !this.state.openObj[name] } }),
      }, () => {
        this.props.onChange(cardState);
      });
    }
    else {
      for (let l in locChecked) {
        locChecked[l] = false;
      }
      locChecked[name] = event.target.checked;
      cardState.locationChecked = locChecked;

      // Location card is closed when selecting a single location (aggregate false)
      //  beside updating state to close the menu, state does not need to be maintained
      //  handleClick func is called when user opens locationCompare menu again and cardState is reset.
      this.setState({ menuOpen: false, menuAnchor: null }, () => {
        this.props.onChange(cardState);
      });
    }
  };

  setAggregate = event => {
    let cardState = Object.assign({}, this.state.cardState); // needed for obj comparison when setState is called without onChange()
    let locChecked = cardState.locationChecked;
    let tempCheckedLocOwners = cardState.checkedLocOwners;
    let tempCheckedLocCategories = cardState.checkedLocCategories;

    if (!event.target.checked) {
      let isFirst = false;

      if (Object.keys(tempCheckedLocOwners).length === 1) {
        tempCheckedLocOwners[Object.keys(tempCheckedLocOwners)[0]] = false;
      }
      else {
        for (let i in tempCheckedLocOwners) {
          tempCheckedLocOwners[i] = false;
        }
      }

      for (let i in tempCheckedLocCategories) {
        tempCheckedLocCategories[i] = false;
      }

      for (let l in locChecked) {
        if (isFirst) {
          locChecked[l] = false;
        }
        else if (locChecked[l]) {
          isFirst = true;
        }
      }
    }

    cardState.checkedLocCategories = tempCheckedLocCategories;
    cardState.checkedLocOwners = tempCheckedLocOwners;
    cardState.aggregate = event.target.checked;
    cardState.locationChecked = locChecked;

    // if 'Combine Locations' toggled off and at least 1 location selected
    if (!event.target.checked && Object.values(locChecked).includes(true)) {
      this.setState({ menuOpen: false, menuAnchor: null }, () => {
        this.props.onChange(cardState);
      });
    }
    else {
      this.setState({ ...this.state, cardState: cardState });
    }
  };

  changeAggregationType = (event) => {
    let cardState = Object.assign({}, this.state.cardState); // needed for obj comparison when setState is called without onChange()
    let locChecked = cardState.locationChecked;

    cardState.aggregationType = event.target.value;

    if (Object.values(locChecked).includes(true)) {
      this.setState({ ...this.state, cardState: cardState }, () => {
        this.props.onChange(cardState);
      });
    }
    else {
      this.setState({ ...this.state, cardState: cardState });
    }
  }

  handleClickAway = () => {
    this.setState({ menuOpen: false, menuAnchor: null });
  };

  render() {
    const { accessControl, classes, compareActiveLoc, locationsObject, noCard, showCombos } = this.props;
    const { cardState, menuAnchor, menuOpen, openObj } = this.state;
    const headerHeight = document.getElementById('headerId')?.clientHeight || 32; // gets height of the appBar header, use to offset location popover maxheight

    return (
      <Grid container className={classes.cardContainer}>
        <Grid
          container
          alignItems='center'
          justifyContent='center'
          style={{
            height: 40, width: '100%',
            color: compareActiveLoc || noCard ? '#02313D' : '#4d6e77',
            backgroundColor: compareActiveLoc || noCard ? '#f5f5f5' : '#B3C0C4',
            fontWeight: 600, lineHeight: '115%', paddingLeft: 12, paddingRight: 8, fontSize: 'calc(7px + 0.2vw)'
          }}
          onClick={this.handleClick}
          disabled={compareActiveLoc ? false : true}
        >
          LOCATION
          <AddIcon justify='center' style={{ width: 'calc(8px + 0.4vw)', paddingBottom: '0.07em', paddingLeft: '0.1vw', display: '' }} />
        </Grid>
        <Popover
          open={menuOpen}
          anchorEl={menuAnchor}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          PaperProps={{
            style: { maxHeight: 'calc(100% - ' + headerHeight + 'px)' },
          }}
        >
          <ClickAwayListener onClickAway={this.handleClickAway}>
            <Paper className={classes.locMenu} style={{ width: window.innerWidth > 767 ? '100%' : '72vw' }}>
              <Grid container alignItems='flex-start' direction='row'>
                <Grid item style={{ maxWidth: 350 }}>
                  <FormGroup style={{ paddingTop: 16, paddingLeft: 20, paddingRight: 10, paddingBottom: 15 }}>

                    {/* All Locations except Lindt, McDonalds, USP, and BPC */}
                    {(this.props.client === 'all' || accessControl) && locationsObject['locationList'].map((value, index) => {
                      return (Object.keys(cardState.locationChecked)[index] === '26 Broadway') ?
                        <Accordion key={'acc' + index} square style={{ marginTop: 0, marginBottom: 5 }} className={classes.subMenu}>
                          <AccordionSummary
                            style={{ minHeight: 0 }}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls='panel1a-content'
                            id='panel1a-header'
                          >
                            <Typography style={{ fontSize: 'calc(6px + 0.4vw)' }}>ADDITIONAL LOCATIONS</Typography>
                          </AccordionSummary>
                          <AccordionDetails style={{ padding: '4px 16px 8px', flexDirection: 'column' }}>
                            <FormControlLabel
                              key={'fc' + index}
                              className={classes.location}
                              control={cardState.aggregate ?
                                <Checkbox style={{ marginTop: -4, color: locationsObject['colors'][index], padding: '4px 9px 12px' }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[index]]}
                                  onChange={this.changeLoc(Object.keys(cardState.locationChecked)[index])} value={Object.keys(cardState.locationChecked)[index]} /> :
                                <Radio style={{ marginTop: -4, color: locationsObject['colors'][index], padding: '4px 9px 12px' }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[index]]}
                                  onChange={this.changeLoc(Object.keys(cardState.locationChecked)[index])} value={Object.keys(cardState.locationChecked)[index]} />
                              }
                              label={<Typography color='textPrimary' style={{ paddingBottom: 8 }}>{Object.keys(cardState.locationChecked)[index]}</Typography>}
                            />
                          </AccordionDetails>
                        </Accordion> :
                        <Fragment key={index}>
                          {locationsObject['locGroupLbls'][Object.keys(cardState.locationChecked)[index]] &&
                            <FormLabel className={classes.locGroupLbl}>{locationsObject['locGroupLbls'][Object.keys(cardState.locationChecked)[index]]}</FormLabel>
                          }
                          <FormControlLabel
                            key={'fc' + index}
                            className={(locationsObject['subsubLocs'].includes(Object.keys(cardState.locationChecked)[index])) ? `${classes.location} ${classes.subsubLocation}` :
                              (locationsObject['subLocs'].includes(Object.keys(cardState.locationChecked)[index]))
                                ? `${classes.location} ${classes.subLocation}`
                                : classes.location
                            }
                            control={cardState.aggregate ?
                              <Checkbox style={{ marginTop: -4, color: locationsObject['colors'][index] }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[index]]}
                                onChange={this.changeLoc(Object.keys(cardState.locationChecked)[index])} value={Object.keys(cardState.locationChecked)[index]} /> :
                              <Radio style={{ marginTop: -4, color: locationsObject['colors'][index] }} checked={cardState.locationChecked[Object.keys(cardState.locationChecked)[index]]}
                                onChange={this.changeLoc(Object.keys(cardState.locationChecked)[index])} value={Object.keys(cardState.locationChecked)[index]} />
                            }
                            label={<Typography color='textPrimary'>{Object.keys(cardState.locationChecked)[index]}</Typography>}
                          />
                        </Fragment>
                    })}

                    {/* JEMB Locations */}
                    {(this.props.client !== 'all' && !accessControl) &&
                      <Grid container direction='column' className={classes.locationsGrid}>

                        {locationsObject.locationTypes['People'].map((value, index) => {
                          return (
                            <Fragment key={index}>
{/* Expandable Locations */}
                              {value.expandLocation && !value.childOf &&
                                <>
                                  <Grid container justifyContent='flex-start' alignItems='center' style={{ marginLeft: (value.leftIndent) ? 10 : '' }}>
                                    <Grid item xs>
                                      <FormControlLabel
                                        className={classes.location}
                                        label={<Typography color='textPrimary'>{value.name}</Typography>}
                                        control={cardState.aggregate
                                          ? <Checkbox style={{ color: value.color }} checked={cardState.locationChecked[value.name]} onChange={this.changeLoc(value.name)} value={value.name} />
                                          : <Radio style={{ color: value.color }} checked={cardState.locationChecked[value.name]} onChange={this.changeLoc(value.name)} value={value.name} />
                                        }
                                      />
                                    </Grid>
                                    <Grid item xs={1}>
                                      <IconButton aria-label='expand row' size='small' onClick={this.dropDownToggle.bind(this, value.name)}>
                                        {openObj[value.name] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                      </IconButton>
                                    </Grid>
                                  </Grid>

                                  <Collapse in={openObj[value.name]} timeout='auto' style={{ marginLeft: (value.leftIndent) ? 25 : 15 }}>

                                  {value.parentOf.map((v, i) => {
                                    if (locationsObject.fullDetails[v].expandLocation) {
                                      return (
                                        <Fragment key={i}>

                                          <Grid container justifyContent='flex-start' alignItems='center'>
                                            <Grid item xs>
                                              <FormControlLabel
                                                key={i}
                                                className={classes.location}
                                                label={<Typography color='textPrimary'>{v}</Typography>}
                                                control={cardState.aggregate
                                                  ? <Checkbox style={{ color: locationsObject.fullDetails[v].color }} checked={cardState.locationChecked[v]} onChange={this.changeLoc(v)} value={v} />
                                                  : <Radio style={{ color: locationsObject.fullDetails[v].color }} checked={cardState.locationChecked[v]} onChange={this.changeLoc(v)} value={v} />
                                                } />
                                            </Grid>

                                            <Grid item xs={1}>
                                              <IconButton aria-label='expand row' size='small' onClick={this.dropDownToggle.bind(this, v)} disabled={accessControl}>
                                                {openObj[v] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                              </IconButton>
                                            </Grid>
                                          </Grid>

                                          <Collapse in={openObj[v] || accessControl} timeout='auto' style={{ marginLeft: 15 }}>

                                            <Grid container direction={(locationsObject.fullDetails[v].paired) ? 'row' : 'column'} justifyContent={(locationsObject.fullDetails[v].paired) ? 'flex-start' : 'space-evenly'} alignItems='flex-start'>
                                              {locationsObject.fullDetails[v].parentOf.map((v, i) => {
                                                return (
                                                  <FormControlLabel
                                                    key={i}
                                                    className={classes.location}
                                                    label={<Typography color='textPrimary'>{v}</Typography>}
                                                    control={cardState.aggregate
                                                      ? <Checkbox style={{ color: locationsObject.fullDetails[v].color }} checked={cardState.locationChecked[v]} onChange={this.changeLoc(v)} value={v} />
                                                      : <Radio style={{ color: locationsObject.fullDetails[v].color }} checked={cardState.locationChecked[v]} onChange={this.changeLoc(v)} value={v} />
                                                    } />
                                                )
                                              })}
                                            </Grid>

                                          </Collapse>
                                        </Fragment>
                                      )
                                    }

                                    if (!locationsObject.fullDetails[v].expandLocation) {
                                      return (
                                        <Fragment key={i}>

                                          <Grid container direction='column' justifyContent='space-evenly' alignItems='flex-start' style={{ marginLeft: (locationsObject.fullDetails[v].leftIndent) ? 10 : '' }}>
                                            <FormControlLabel
                                              key={i}
                                              className={(locationsObject.fullDetails[v].subsubLocation)
                                                ? `${classes.location} ${classes.subsubLocation}`
                                                : (locationsObject.fullDetails[v].subLocation)
                                                  ? `${classes.location} ${classes.subLocation}`
                                                  : classes.location
                                              }
                                              label={<Typography color='textPrimary'>{v}</Typography>}
                                              control={cardState.aggregate
                                                ? <Checkbox style={{ color: locationsObject.fullDetails[v].color }} checked={cardState.locationChecked[v]} onChange={this.changeLoc(v)} value={v} />
                                                : <Radio style={{ color: locationsObject.fullDetails[v].color }} checked={cardState.locationChecked[v]} onChange={this.changeLoc(v)} value={v} />
                                              } />
                                          </Grid>
                                        </Fragment>
                                      )
                                    }
                                  })}

                                  </Collapse>
                                </>
                              }
{/* Normal Locations */}
                              {!value.expandLocation && !value.expandLabel && !value.childOf && !value.paired &&
                                <FormControlLabel
                                  key={'fc' + index}
                                  className={(value.subsubLocation)
                                    ? `${classes.location} ${classes.subsubLocation}`
                                    : (value.subLocation)
                                      ? `${classes.location} ${classes.subLocation}`
                                      : classes.location
                                  }
                                  label={<Typography color='textPrimary'>{value.name}</Typography>}
                                  control={cardState.aggregate
                                    ? <Checkbox style={{ color: value.color }} checked={cardState.locationChecked[value.name]} onChange={this.changeLoc(value.name)} value={value.name} />
                                    : <Radio style={{ color: value.color }} checked={cardState.locationChecked[value.name]} onChange={this.changeLoc(value.name)} value={value.name} />
                                  }
                                />
                              }
{/* Expandable Labels: ie: Archived */}
                              {value.expandLabel && !value.childOf &&
                                <>
                                  <Grid container justifyContent='flex-start' alignItems='center'>
                                    <Grid item xs>
                                      <Typography onClick={this.dropDownToggle.bind(this, value.name)} style={{ cursor:'pointer', fontSize: '14px', padding: '0 0 6px', borderBottom: '1px solid black', color: 'rgba(0, 0, 0, 0.54)' }}>{value.name}</Typography>
                                    </Grid>
                                    <Grid item xs={1} className={classes.dropDownContainer}>
                                      <IconButton aria-label='expand row' size='small' onClick={this.dropDownToggle.bind(this, value.name)} disabled={accessControl}>
                                        {openObj[value.name] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                      </IconButton>
                                    </Grid>
                                  </Grid>

                                  <Collapse in={openObj[value.name] || accessControl} timeout='auto' style={{ marginLeft: 15 }}>

                                    {value.parentOf.map((v, i) => {
// Nested Normal Locations
                                      if (!locationsObject.fullDetails[v].paired) {
                                        return (
                                          <Fragment key={i}>
                                            {locationsObject.fullDetails[v].groupLabel &&
                                              <Typography style={{ fontSize: '14px', marginTop: 5, marginBottom: -5, color: 'rgba(0, 0, 0, 1)' }}>{locationsObject.fullDetails[v].groupLabel}</Typography>
                                            }
                                            <Grid container direction='column' justifyContent='space-evenly' alignItems='flex-start' style={{ marginLeft: (locationsObject.fullDetails[v].leftIndent) ? 10 : '' }}>
                                              <FormControlLabel
                                                key={i}
                                                className={(locationsObject.fullDetails[v].subsubLocation)
                                                  ? `${classes.location} ${classes.subsubLocation}`
                                                  : (locationsObject.fullDetails[v].subLocation)
                                                    ? `${classes.location} ${classes.subLocation}`
                                                    : classes.location
                                                }
                                                label={<Typography color='textPrimary'>{v}</Typography>}
                                                control={cardState.aggregate
                                                  ? <Checkbox style={{ color: locationsObject.fullDetails[v].color }} checked={cardState.locationChecked[v]} onChange={this.changeLoc(v)} value={v} />
                                                  : <Radio style={{ color: locationsObject.fullDetails[v].color }} checked={cardState.locationChecked[v]} onChange={this.changeLoc(v)} value={v} />
                                                } />
                                            </Grid>
                                          </Fragment>
                                        )
                                      }
                                    })}

                                  </Collapse>
                                </>
                              }

                            </Fragment>
                          )
                        })}
                      </Grid>
                    }

                  </FormGroup>
                </Grid>

                <Divider hidden={showCombos ? false : true} orientation='vertical' flexItem />

                <Grid item hidden={showCombos ? false : true} style={{ paddingTop: 20, paddingLeft: 20, paddingRight: 0, maxWidth: 310 }}>

                  {Object.keys(locationsObject.locCategoryTypes).map(catType => {
                    return (
                      <Fragment key={catType}>
                        <FormLabel>{catType}</FormLabel>
                        <FormGroup style={{ paddingBottom: 0, paddingLeft: 10, paddingTop: 6 }}>
                          {Object.values(locationsObject.locCategoryTypes[catType]).map(category => {
                            return (
                              <FormControlLabel
                                key={category}
                                className={classes.location}
                                control={<Radio style={{ color: 'black' }} checked={cardState.checkedLocCategories[category]} onChange={this.changeLocCategory(category)} value={category} />}
                                label={<Typography color='textPrimary'>{category}</Typography>}
                                style={{ marginTop: -12 }}
                              />
                            )
                          })}
                        </FormGroup>
                      </Fragment>
                    )
                  })}

                  <FormLabel>Landlord</FormLabel>
                  <FormGroup style={{ paddingBottom: 0, paddingLeft: 10, paddingTop: 6 }}>
                    {Object.keys(cardState.checkedLocOwners).map((value, index) => {
                      return (
                        <FormControlLabel
                          key={'ll' + index}
                          className={classes.location}
                          control={typeof locationsObject['locOwners'][value] === 'undefined'
                            ? <Radio style={{ color: 'black' }} checked={cardState.checkedLocOwners[Object.keys(cardState.checkedLocOwners)[index]]}
                              onChange={this.changeOwner(Object.keys(cardState.checkedLocOwners)[index])} value={Object.keys(cardState.checkedLocOwners)[index]} />
                            : <Radio style={{ color: 'black' }} checked={cardState.checkedLocOwners[Object.keys(cardState.checkedLocOwners)[index]]}
                              onChange={this.changeOwner(Object.keys(cardState.checkedLocOwners)[index])} value={Object.keys(cardState.checkedLocOwners)[index]} />}
                          label={<Typography color='textPrimary'>{Object.keys(cardState.checkedLocOwners)[index]}</Typography>}
                          style={{ paddingLeft: typeof locationsObject['locOwners'][value] === 'undefined' ? 20 : 0, marginTop: typeof locationsObject['locOwners'][value] === 'undefined' ? -16 : -12 }}
                        />
                      )
                    })}
                  </FormGroup>

                  <FormGroup style={{ paddingBottom: 12 }}>
                    <FormControlLabel style={{ color: '#00313D' }}
                      control={
                        <Switch
                          checked={cardState.aggregate}
                          onChange={this.setAggregate}
                          name='checkedB'
                          color='primary'
                        />
                      }
                      label={<Typography color='textPrimary'>Combine Locations</Typography>}
                    />
                    <Collapse in={cardState.aggregate}>
                      <FormControl component='fieldset' style={{ paddingLeft: 10, color: '#00313D' }}>
                        <RadioGroup style={{ marginTop: -12 }} name='combined' value={cardState.aggregationType} onChange={this.changeAggregationType}>
                          <FormControlLabel
                            value='average'
                            control={<StyledRadio classes={{ root: classes.root, checked: classes.checked }} />}
                            label={<Typography color='textPrimary'>Average</Typography>} />
                          <FormControlLabel
                            value='total'
                            control={<StyledRadio classes={{ root: classes.root, checked: classes.checked }} />}
                            style={{ marginTop: -14 }}
                            label={<Typography color='textPrimary'>Total</Typography>} />
                        </RadioGroup>
                      </FormControl>
                    </Collapse>
                  </FormGroup>
                </Grid>
              </Grid>
            </Paper>
          </ClickAwayListener>
        </Popover>
      </Grid>
    )
  }
}

export default withRouter(withStyles(styles)(LocationCompare3));
