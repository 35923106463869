import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import { parse } from 'query-string';

import config from '../../utils/config';
import { loginUser } from '../../actions/authActions';
import exterosLogo from './exteros_blue.png';
import plugoutLogo from './plugout_blue.png';

import { Button, FormControl, Grid, TextField } from '@material-ui/core';

const getLogo = (url) => {
  if (url.includes('plugout')) return plugoutLogo;

  return exterosLogo;
}

const getToken = (url) => {
  const token = parse(url).token || '';

  return token;
}

class ResetPassword extends Component {
  constructor() {
    super();

    this.state = {
      password: '',
      password2: '',
      changePasswordId: getToken(window.location.search),
      logo: getLogo(window.location.href),
      showSuccess: false,
      errors: {
        password: '',
        password2: '',
        server: ''
      }
    };

    this.baseform = {
      password: '',
      password2: ''
    }
  }

  componentDidMount() {
    document.title = 'Reset Password - Exteros';
  }

  componentWillReceiveProps(nextProps) {
    // Props received from login request after pass reset - if login fails no need to show error, user can still login manually
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push('/dashboard');
    }
  }

  resetForm = () => {
    this.setState(this.baseform);
  }

  handleChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { password, password2, changePasswordId } = this.state;

    if (password === '' || password.length < 8 || password !== password2) {
      let passErr = '';

      if (password === '' || password.length < 8) passErr = (password === '') ? 'Please enter a password to continue.' : 'Password must be at least 8 characters.';

      return this.setState({
        showSuccess: false,
        errors: {
          password: passErr,
          password2: (password !== password2) ? 'Passwords do not match.' : '',
          server: ''
        }
      });
    }

    this.setState({
      showSuccess: false,
      errors: {}
    });

    try {
      const response = await axios.post(config.api.url + '/v2/user/reset-password', { password, changePasswordId });

      if (response.data.success) {
        this.props.loginUser({ oneTimePassword: response.data.oneTimePassword, fp: true }); // use oneTimePassword to directly login user

        this.resetForm();
        this.setState({
          showSuccess: true
        });
      }
    } catch (error) {
      // potential errors:  expired/invalid changePasswordId | invalid password | server error
      this.resetForm();
      this.setState({
        showSuccess: false,
        errors: {
          server: ((((error || {}).response || {}).data || {}).message) ? error.response.data.message : 'Something went wrong, please try again.'
        }
      });
    }
  };

  render() {
    const { password, password2, showSuccess, errors } = this.state;

    return (
      <Grid container style={{ marginTop: "25vh" }} direction="row" justifyContent="center" alignItems="baseline" >
        <FormControl>
          <img alt="Logo" src={this.state.logo} style={{ marginBottom:"2vw", width:"25vw", height:"100%", minWidth:"280px" }}/>
          <h2 style={{ marginLeft: 'auto', marginRight: 'auto' }}>Reset Password</h2>
          <p style={{ width:'25vw', minWidth:'280px' }}>Please choose a password that is at least 8 characters long.</p>
          <TextField
            required
            fullWidth
            variant="filled"
            margin="dense"
            id="password"
            type="password"
            label="New Password"
            value={password}
            error={errors.password ? true : false}
            helperText={errors.password}
            autoFocus={true}
            className={errors.password ? 'invalid' : ''}
            onChange={this.handleChange}
          />
          <TextField
            required
            fullWidth
            variant="filled"
            margin="dense"
            id="password2"
            type="password"
            label="Confirm Password"
            value={password2}
            error={errors.password2 ? true : false}
            helperText={errors.password2}
            className={errors.password2 ? 'invalid' : ''}
            onChange={this.handleChange}
            onKeyDown={(ev) => {
              if (ev.key === 'Enter') { this.handleSubmit(ev); }
            }}
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
            style={{ borderRadius: "3px", letterSpacing: "1.5px", marginTop: "20px" }}
            className="btn btn-large waves-effect waves-light hoverable accent-3"
            onClick={this.handleSubmit}
          >
            Update Password
          </Button>
          <div>
          {showSuccess && (
            <p style={{ width:'25vw', minWidth:'280px' }}>
              Your password has been updated successfully. <a href="/login">Login</a>
            </p>
          )}
          {errors.server && (
            <p style={{ width:'25vw', minWidth:'280px', color:'#f44336' }}>
              {errors.server}
            </p>
          )}
          </div>
        </FormControl>
      </Grid>
    );
  }
}

ResetPassword.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(mapStateToProps, { loginUser })(ResetPassword);
