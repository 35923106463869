import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';

import config from '../../utils/config';
import { clientData } from '../../utils/clients';
import exterosLogo from './exteros_blue.png';

import {
  Grid, Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, MenuItem, Switch, TextField,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  Table, TableBody, TableCell, TableContainer, TableRow
} from '@material-ui/core';


class AddUser extends Component {
  constructor() {
    super();

    this.state = {
      openSubmitModal: false,
      showSuccess: false,

      adminSwitch: false,
      adminEmail: '',
      client: '',
      company: '',
      email: '',
      fullname: '',
      password: '',
      locations: '',
      locationsAll: true,
      dashboardaccess: true,
      emailreports: false,
      roles: [],

      errors: {},
      userObject: {}
    };

    this.baseState = this.state;
  }

  componentDidMount() {
    document.title = 'Add User - Exteros Admin';
  }

  resetForm = () => {
    this.setState(this.baseState);
  }

  handleClose = () => {
    this.setState({ openSubmitModal: false });
  }

  trimInput = (e) => {
    if (e.target.value !== e.target.value.trim()) {
      this.setState({ [e.target.name]: e.target.value.trim() });
    }
  }

  handleValueChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }); // mui select passes name instead of id
  };

  handleCheckChange = (e) => {
    this.setState({
      [e.target.name]: e.target.checked,

      ...(e.target.name === 'locationsAll' && {
        locations: '',
        errors: { ...this.state.errors, locations: '' }
      }),

      ...(e.target.name === 'adminSwitch' && {
        fullname: (e.target.checked) ? 'Exteros Admin' : '',
        company: (e.target.checked) ? 'Exteros, Inc.' : '',
        emailreports: (e.target.checked) ? false : this.state.emailreports
      }),
    });
  };

  validateLocations = (e) => {
    const locations = e.target.value.trim().replace(/\s*,?\s*$/g, ''); // trim any leading/trailing whitespace and trailing comma

    if (locations) {
      // 'some' iterates array until true, otherwise returns false - in this case 'true' indicates invalid pattern
      const invalid = locations.split(',').some(l => (/^\d{4}-\d+,?$/.test(l.trim())) ? false : true);

      if (invalid) return this.setState({ errors: { ...this.state.errors, locations: 'Check formatting, see example below.' } });
    }

    return this.setState({ errors: { ...this.state.errors, locations: '' } }); // locations either empty or no invalid pattern found, clear any location error
  }

  submitUser = async (e) => {
    e.preventDefault();
    const { admin, client, company, dashboardaccess, email, emailreports, fullname, locations, locationsAll, password, roles } = this.state.userObject;

    try {
      const response = await axios.post(config.api.url + '/v2/admin/addUser', {
        admin: admin.value,
        client: client.value,
        company: company.value,
        dashboardaccess: (dashboardaccess.value === 'true'),
        email: email.value,
        emailreports: (emailreports.value === 'true'),
        fullname: fullname.value,
        locations: JSON.parse(locations.value),
        locationsAll: (locationsAll.value === 'true'),
        ...(password?.value && { password: password.value }),
        roles: JSON.parse(roles.value),
      });

      if (response.data.success) {
        if (this.props.dashboardView) this.props.userAdded(); // refresh admin dashboard to show added user

        this.resetForm();
        this.setState({
          showSuccess: true
        });
      }
    } catch (error) {
      this.setState({
        openSubmitModal: false,
        showSuccess: false,
        errors: {
          server: (error?.response?.data?.message) ? error.response.data.message : 'Something went wrong, please try again.'
        }
      });
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const { adminEmail, client, company, dashboardaccess, email, emailreports, errors, fullname, locations, locationsAll, password, roles } = this.state;

    if (!locationsAll && errors.locations) return this.setState({ showSuccess: false, errors: { ...this.state.errors, server: 'Fix or clear locations before proceeding.' } });

    // split locations into array, trim any whitespace, discard any not matching pattern 0000-1+, if no locations, returns empty []
    const locArray = (locationsAll) ? [] : locations.split(',').map(loc => loc.trim()).filter(s => /^\d{4}-\d+,?$/.test(s.trim()));
    if (!locationsAll && !locArray.length) return this.setState({ showSuccess: false, errors: { ...this.state.errors, locations: 'No locations found. Enable \'All Locations\' or enter at least 1 location.' } });

    const roleArray = (Array.isArray(roles)) ? roles : roles.split(); // Roles are passed from fa in array, select submits as string

    try {
      const response = await axios.post(config.api.url + '/v2/admin/validateUser', {
        admin: adminEmail,
        client: client.toLowerCase(),
        company: company,
        dashboardaccess: dashboardaccess,
        email: email,
        emailreports: emailreports,
        fullname: fullname,
        locations: locArray,
        locationsAll: locationsAll,
        password: password,
        roles: roleArray.filter(Boolean) // if no role change [''] to []
      });

      if (response.data.success) {
        this.setState({
          errors: {},
          showSuccess: false,
          openSubmitModal: true,
          userObject: response.data.confirmUser
        });
      }
    } catch (error) {
      if (error.response.status === 400 && error.response.data?.message?.toLowerCase()?.includes('email')) {
        return this.setState({
          openSubmitModal: false,
          showSuccess: false,
          errors: {
            email: error.response.data.message,
          }
        });
      }

      this.setState({
        openSubmitModal: false,
        showSuccess: false,
        errors: {
          server: (error?.response?.data?.message) ? error.response.data.message : 'Something went wrong, please try again.'
        }
      });
    }
  };

  render() {
    const { adminEmail, adminSwitch, client, company, dashboardaccess, email, emailreports, errors, fullname, locations, locationsAll, openSubmitModal, password, roles, showSuccess, userObject } = this.state;
    const { dashboardView } = this.props;

    const userRoles = (clientData[client]?.userRoles) ? true : false;

    return (
      <Grid container style={{ margin: (!dashboardView) ? '10vh 0' : '16px 0' }} direction='row' justifyContent='center' alignItems='baseline'>

        <form onSubmit={this.handleSubmit}>
          <FormControl fullWidth style={{ padding: '0 4px' }}>
            {!dashboardView &&
              <>
                <img alt='Logo' src={exterosLogo} style={{ marginLeft: 'auto', marginRight: 'auto', width: '25vw', height: '100%', minWidth: '280px' }} />
                <h2 style={{ margin: '20px auto', fontFamily: 'Lato,sans-serif', fontWeight: 400 }}>Add New User</h2>
              </>
            }

            <FormGroup>
              <FormLabel>Admin Account</FormLabel>
              <FormControlLabel
                control={<Switch checked={adminSwitch} onChange={this.handleCheckChange} id='adminSwitch-id' name='adminSwitch' color='primary' />}
                label='Toggle admin account options'
              />
            </FormGroup>

            <TextField
              required
              fullWidth
              autoComplete='false'
              variant='outlined'
              margin='dense'
              id='adminEmail-id'
              name='adminEmail'
              type='email'
              label='Admin Email'
              value={adminEmail}
              // helperText={errors.adminEmail || ''}
              error={errors.adminEmail ? true : false}
              onChange={this.handleValueChange}
            />

            <TextField
              required
              fullWidth
              autoComplete='false'
              variant='outlined'
              margin='dense'
              id='email-id'
              name='email'
              type='email'
              label='User Email'
              value={email}
              helperText={errors.email || ''}
              error={errors.email ? true : false}
              onChange={this.handleValueChange}
            />

            <TextField
              required
              fullWidth
              autoComplete='false'
              variant='outlined'
              margin='dense'
              id='fullname-id'
              name='fullname'
              type='text'
              label='Full Name'
              value={fullname}
              // helperText={errors.fullname || ''}
              error={errors.fullname ? true : false}
              onChange={this.handleValueChange}
              onBlur={this.trimInput}
            />

            {adminSwitch &&
              <TextField
                required={(adminSwitch) ? true : false}
                fullWidth
                variant='outlined'
                margin='dense'
                id='password-id'
                name='password'
                type='password'
                label='Password'
                value={password}
                // helperText={errors.password || ''}
                error={errors.password ? true : false}
                onChange={this.handleValueChange}
                onBlur={this.trimInput}
              />
            }

            <TextField
              fullWidth
              autoComplete='false'
              variant='outlined'
              margin='dense'
              id='company-id'
              name='company'
              type='text'
              label='Company'
              value={company}
              // helperText={errors.company || ''}
              error={errors.company ? true : false}
              onChange={this.handleValueChange}
              onBlur={this.trimInput}
            />

            <TextField
              required
              fullWidth
              autoComplete='false'
              variant='outlined'
              margin='dense'
              id='client-id'
              name='client'
              type='text'
              label='Client'
              value={client}
              placeholder={'lowercase only'}
              // helperText={errors.client || ''}
              error={errors.client ? true : false}
              onChange={this.handleValueChange}
              onBlur={this.trimInput}
            />

            <FormControlLabel
              control={<Switch checked={locationsAll} onChange={this.handleCheckChange} id='locationsAll-id' name='locationsAll' color='primary' />}
              label='All Locations'
            />

            {!locationsAll &&
              <>
                <TextField
                  fullWidth
                  autoComplete='false'
                  multiline
                  minRows={4}
                  variant='outlined'
                  margin='dense'
                  id='locations-id'
                  name='locations'
                  type='text'
                  label='Locations'
                  value={locations}
                  placeholder='0012-1, 0012-2, 0012-3'
                  helperText={errors.locations || ''}
                  error={errors.locations ? true : false}
                  onChange={this.handleValueChange}
                  onBlur={this.validateLocations}
                />
                <FormHelperText variant='outlined' margin='dense'>
                  Add locations by locId, separate with commas:<br />
                  <strong>0012-1, 0012-2, 0012-3</strong>
                </FormHelperText>
              </>
            }

            <FormControlLabel
              control={<Checkbox checked={dashboardaccess} onChange={this.handleCheckChange} id='dashboardaccess-id' name='dashboardaccess' color='primary' />}
              label='Dashboard Access'
            />
            <FormControlLabel
              control={<Checkbox checked={emailreports} onChange={this.handleCheckChange} id='emailreports-id' name='emailreports' color='primary' />}
              label='Email Reports'
            />

            {userRoles &&
              <TextField
                select
                SelectProps={{ displayEmpty: true }}
                InputLabelProps={{ shrink: true }}
                id='roles-id'
                name='roles'
                label='Role'
                variant='outlined'
                margin='dense'
                value={roles.toString()}
                onChange={this.handleValueChange}
                helperText='Leave empty or select role from dropdown.'
              >
                <MenuItem value={''}><em>None</em></MenuItem>
                <MenuItem value={'video_streaming'}>Video Streaming</MenuItem>
                <MenuItem value={'video_only'}>Only Video Streaming</MenuItem>
              </TextField>
            }

            <Button
              fullWidth
              variant='contained'
              color='primary'
              type='submit'
              className='btn btn-large waves-effect waves-light hoverable accent-3'
            >
              Add User
            </Button>
            <Button size='small' onClick={this.resetForm}>Clear</Button>

            {showSuccess && (
              <p style={{ margin: '4px 14px 4px', fontSize: 16, lineHeight: 1.66, fontFamily: 'Lato,sans-serif', color: '#43a047' }}>
                User added successfully!
              </p>
            )}

            {errors.server && (
              <p style={{ margin: '4px 14px 4px', fontSize: 16, lineHeight: 1.66, fontFamily: 'Lato,sans-serif', color: '#f44336' }}>
                {errors.server}
              </p>
            )}

            {!dashboardView &&
              <div>
                <p style={{ width: '25vw', minWidth: '280px' }}>
                  <a href='/admin'>Admin Dashboard</a>
                </p>
              </div>
            }
          </FormControl>
        </form>

        <Dialog
          open={openSubmitModal}
          onClose={this.handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>
            Add New {adminSwitch && 'Admin'} User
          </DialogTitle>

          <DialogContent>
            <DialogContentText id='alert-dialog-description' style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
              Confirm all fields are correct before proceeding.
            </DialogContentText>

            <TableContainer>
              <Table>
                <TableBody>
                  {Object.keys(userObject).map((row) => (
                    <TableRow key={userObject[row].name}>
                      <TableCell component='th' scope='row' style={{ fontSize: 16, color: 'rgba(0, 0, 0, 0.54)', width: 175, borderBottom: 'none' }}>
                        {userObject[row].name}
                      </TableCell>
                      <TableCell align='left' style={{ fontSize: 16, borderBottom: 'none' }}>{userObject[row].value}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

          </DialogContent>
          <DialogContent dividers>
            <DialogContentText style={{ marginBottom: 0, color: 'rgba(0, 0, 0, 0.87)' }}>
              {dashboardaccess && !adminSwitch &&
                'Clicking submit adds this user to the Dashboard, Access Control, and sends Welcome email with directions to setup their password.'
              }

              {dashboardaccess && adminSwitch &&
                'Clicking submit adds this admin user to the Dashboard and Access Control, NO email is sent.'
              }

              {!dashboardaccess && !adminSwitch &&
                'Clicking submit adds this user to Access Control only.'
              }

              {!dashboardaccess && adminSwitch &&
                'Clicking submit adds this admin user to Access Control only.'
              }
            </DialogContentText>
          </DialogContent>

          <DialogActions style={{ padding: '15px 24px' }}>
            <Button onClick={this.handleClose} color='primary' style={{ marginRight: 50 }}>Cancel</Button>
            <Button onClick={this.submitUser} color='primary'>Submit</Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  }
}

AddUser.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(AddUser);
