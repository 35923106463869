const _getEmptyAvgObjs = () => {
  const countAvg = {
    'current': { 'avgDaily': 0, 'avgWeekend': 0, 'avgWork': 0 },
    'previous': { 'avgDaily': 0, 'avgWeekend': 0, 'avgWork': 0 },
    'delta': { 'avgDaily': 0, 'avgWeekend': 0, 'avgWork': 0 }
  };
  const genderAvg = {
    'current': { 'avgDaily': [], 'avgWeekend': [], 'avgWork': [] },
    'previous': { 'avgDaily': [], 'avgWeekend': [], 'avgWork': [] },
    'delta': { 'men': { 'avgDaily': 0, 'avgWeekend': 0, 'avgWork': 0 }, 'women': { 'avgDaily': 0, 'avgWeekend': 0, 'avgWork': 0 } }
  };
  const ageAvg = {
    'current': { 'avgDaily': [], 'avgWeekend': [], 'avgWork': [] },
    'previous': { 'avgDaily': [], 'avgWeekend': [], 'avgWork': [] },
    'delta': { 'children': { 'avgDaily': 0, 'avgWeekend': 0, 'avgWork': 0 }, 'adults': { 'avgDaily': 0, 'avgWeekend': 0, 'avgWork': 0 }, 'seniors': { 'avgDaily': 0, 'avgWeekend': 0, 'avgWork': 0 } }
  };
  const vehicleAvg = {
    'current': { 'avgDaily': [], 'avgWeekend': [], 'avgWork': [] },
    'previous': { 'avgDaily': [], 'avgWeekend': [], 'avgWork': [] },
    'delta': { 'bikes': { 'avgDaily': 0, 'avgWeekend': 0, 'avgWork': 0 }, 'buses': { 'avgDaily': 0, 'avgWeekend': 0, 'avgWork': 0 }, 'cars': { 'avgDaily': 0, 'avgWeekend': 0, 'avgWork': 0 }, 'motorbikes': { 'avgDaily': 0, 'avgWeekend': 0, 'avgWork': 0 }, 'trucks': { 'avgDaily': 0, 'avgWeekend': 0, 'avgWork': 0 } }
  };

  return [countAvg, genderAvg, ageAvg, vehicleAvg];
}

const _getPercDelta = (current, previous) => {
  if (typeof current === 'undefined' || typeof previous === 'undefined' || previous === 0) return 0;

  let avg = ((current - previous) / previous) * 100;
  avg = Math.round((avg + Number.EPSILON) * 100) / 100; // round to 2 dec places

  if (Number.isNaN(avg) || !Number.isFinite(avg)) return 0;

  return avg; // returns % change between current (daily, wkday, wkend) avg and prev avg. Safe to compare uneven date ranges.
}

const _getAverages = (data, mode) => {
  let [dailyAvg, wkDayAvg, wkEndAvg] = [{}, {}, {}];

  if (typeof data !== 'undefined' && data.length > 0) {
    const countTypes = ['count', 'men', 'women', 'children', 'adults', 'seniors', 'bikes', 'buses', 'cars', 'motorbikes', 'trucks'];

    for (let countType of countTypes) {
      let [wkdayTot, wkendTot, wkendDays, wkDays] = [0, 0, 0, 0];

      if (data.length < 2) {
        if (data[0].type === 'weekend') {
          wkendTot = data[0].totals[countType] || 0;
          wkendDays = data[0].numDays || 0;
        }
        else if (data[0].type === 'weekday') {
          wkdayTot = data[0].totals[countType] || 0;
          wkDays = data[0].numDays || 0;
        }
      }
      else {
        if (data[0].type === 'weekend') {
          wkendTot = data[0].totals[countType] || 0;
          wkdayTot = data[1].totals[countType] || 0;
          wkendDays = data[0].numDays || 0;
          wkDays = data[1].numDays || 0;
        }
        else if (data[0].type === 'weekday') {
          wkendTot = data[1].totals[countType] || 0;
          wkdayTot = data[0].totals[countType] || 0;
          wkendDays = data[1].numDays || 0;
          wkDays = data[0].numDays || 0;
        }
      }

      if (mode === 'dwell') {
        const avgDaily = (data[0].allTotals && data[0].allTotals[countType]) ? (data[0].allTotals[countType]) : 0;
        // totalCounts[countType] = (wkdayTot + wkendTot); // totalCounts only used with byTime chart to calc perc, not used with dwell or conversion
        dailyAvg[countType] = (avgDaily !== 0) ? Math.round(avgDaily) : 0;
        wkDayAvg[countType] = (wkDays !== 0) ? Math.round(wkdayTot) : 0;
        wkEndAvg[countType] = (wkendDays !== 0) ? Math.round(wkendTot) : 0;
      }
      else if (mode === 'conversion') {
        // totalCounts[countType] = (wkdayTot + wkendTot); // totalCounts only used with byTime chart to calc perc, not used with dwell or conversion
        dailyAvg[countType] = (data[0].allTotals && data[0].allTotals[countType]) ? (data[0].allTotals[countType]) : 0;
        wkDayAvg[countType] = wkdayTot;
        wkEndAvg[countType] = wkendTot;
      }
      else {
        // totalCounts[countType] = (wkdayTot + wkendTot);
        if (countType === 'count') {
          dailyAvg[countType] = (wkDays + wkendDays !== 0) ? Math.round((wkdayTot + wkendTot) / (wkDays + wkendDays)) : 0;
          wkDayAvg[countType] = (wkDays !== 0) ? Math.round((wkdayTot / wkDays) * 1) : 0;
          wkEndAvg[countType] = (wkendDays !== 0) ? Math.round((wkendTot / wkendDays) * 1) : 0;
        }
        else {
          dailyAvg[countType] = wkdayTot + wkendTot;
          wkDayAvg[countType] = (wkDays !== 0) ? wkdayTot : 0;
          wkEndAvg[countType] = (wkendDays !== 0) ? wkendTot : 0;
        }
      }
    }
  }

  return [dailyAvg, wkEndAvg, wkDayAvg];
}



export const getDailyAvgData = (respData, mode) => {
  let [countAvg, genderAvg, ageAvg, vehicleAvg] = _getEmptyAvgObjs();

  const data = (mode === 'traffic') ? respData[0]['current'] : respData; // data structure different for dwell and conversion

  if (typeof data !== 'undefined' && data.length > 0) {
    const [dailyAvg, wkEndAvg, wkDayAvg] = _getAverages(data, mode); // avgs for current (user selected) date range

    if (mode === 'traffic') {
      countAvg.current = { 'avgDaily': dailyAvg.count, 'avgWeekend': wkEndAvg.count, 'avgWork': wkDayAvg.count };
      genderAvg.current = {
        'avgDaily': [{ 'name': 'Female', 'value': dailyAvg.women }, { 'name': 'Male', 'value': dailyAvg.men }],
        'avgWeekend': [{ 'name': 'Female', 'value': wkEndAvg.women }, { 'name': 'Male', 'value': wkEndAvg.men }],
        'avgWork': [{ 'name': 'Female', 'value': wkDayAvg.women }, { 'name': 'Male', 'value': wkDayAvg.men }]
      };
      ageAvg.current = {
        'avgDaily': [{ 'name': 'Children', 'value': dailyAvg.children }, { 'name': 'Adults', 'value': dailyAvg.adults }, { 'name': 'Seniors', 'value': dailyAvg.seniors }],
        'avgWeekend': [{ 'name': 'Children', 'value': wkEndAvg.children }, { 'name': 'Adults', 'value': wkEndAvg.adults }, { 'name': 'Seniors', 'value': wkEndAvg.seniors }],
        'avgWork': [{ 'name': 'Children', 'value': wkDayAvg.children }, { 'name': 'Adults', 'value': wkDayAvg.adults }, { 'name': 'Seniors', 'value': wkDayAvg.seniors }]
      };
      vehicleAvg.current = {
        'avgDaily': [{ 'name': 'Bikes', 'value': dailyAvg.bikes }, { 'name': 'Buses', 'value': dailyAvg.buses }, { 'name': 'Cars', 'value': dailyAvg.cars }, { 'name': 'Motorbikes', 'value': dailyAvg.motorbikes }, { 'name': 'Trucks', 'value': dailyAvg.trucks }],
        'avgWeekend': [{ 'name': 'Bikes', 'value': wkEndAvg.bikes }, { 'name': 'Buses', 'value': wkEndAvg.buses }, { 'name': 'Cars', 'value': wkEndAvg.cars }, { 'name': 'Motorbikes', 'value': wkEndAvg.motorbikes }, { 'name': 'Trucks', 'value': wkEndAvg.trucks }],
        'avgWork': [{ 'name': 'Bikes', 'value': wkDayAvg.bikes }, { 'name': 'Buses', 'value': wkDayAvg.buses }, { 'name': 'Cars', 'value': wkDayAvg.cars }, { 'name': 'Motorbikes', 'value': wkDayAvg.motorbikes }, { 'name': 'Trucks', 'value': wkDayAvg.trucks }]
      };

      const [dailyAvgPrev, wkEndAvgPrev, wkDayAvgPrev] = _getAverages(respData[0]['previous'], mode); // previous date range averages to calculate delta

      if (Object.keys(dailyAvgPrev).length > 0) {
        // Count, Gender, Age, Vehicle - PREVIOUS
        countAvg.previous = { 'avgDaily': dailyAvgPrev.count, 'avgWeekend': wkEndAvgPrev.count, 'avgWork': wkDayAvgPrev.count };
        genderAvg.previous = {
          'avgDaily': [{ 'name': 'Female', 'value': dailyAvgPrev.women }, { 'name': 'Male', 'value': dailyAvgPrev.men }],
          'avgWeekend': [{ 'name': 'Female', 'value': wkEndAvgPrev.women }, { 'name': 'Male', 'value': wkEndAvgPrev.men }],
          'avgWork': [{ 'name': 'Female', 'value': wkDayAvgPrev.women }, { 'name': 'Male', 'value': wkDayAvgPrev.men }]
        };
        ageAvg.previous = {
          'avgDaily': [{ 'name': 'Children', 'value': dailyAvgPrev.children }, { 'name': 'Adults', 'value': dailyAvgPrev.adults }, { 'name': 'Seniors', 'value': dailyAvgPrev.seniors }],
          'avgWeekend': [{ 'name': 'Children', 'value': wkEndAvgPrev.children }, { 'name': 'Adults', 'value': wkEndAvgPrev.adults }, { 'name': 'Seniors', 'value': wkEndAvgPrev.seniors }],
          'avgWork': [{ 'name': 'Children', 'value': wkDayAvgPrev.children }, { 'name': 'Adults', 'value': wkDayAvgPrev.adults }, { 'name': 'Seniors', 'value': wkDayAvgPrev.seniors }]
        };
        vehicleAvg.previous = {
          'avgDaily': [{ 'name': 'Bikes', 'value': dailyAvgPrev.bikes }, { 'name': 'Buses', 'value': dailyAvgPrev.buses }, { 'name': 'Cars', 'value': dailyAvgPrev.cars }, { 'name': 'Motorbikes', 'value': dailyAvgPrev.motorbikes }, { 'name': 'Trucks', 'value': dailyAvgPrev.trucks }],
          'avgWeekend': [{ 'name': 'Bikes', 'value': wkEndAvgPrev.bikes }, { 'name': 'Buses', 'value': wkEndAvgPrev.buses }, { 'name': 'Cars', 'value': wkEndAvgPrev.cars }, { 'name': 'Motorbikes', 'value': wkEndAvgPrev.motorbikes }, { 'name': 'Trucks', 'value': wkEndAvgPrev.trucks }],
          'avgWork': [{ 'name': 'Bikes', 'value': wkDayAvgPrev.bikes }, { 'name': 'Buses', 'value': wkDayAvgPrev.buses }, { 'name': 'Cars', 'value': wkDayAvgPrev.cars }, { 'name': 'Motorbikes', 'value': wkDayAvgPrev.motorbikes }, { 'name': 'Trucks', 'value': wkDayAvgPrev.trucks }]
        };

        // Count, Gender, Age, Vehicle - DELTA
        let percDelta = { 'daily': {}, 'wkEnd': {}, 'wkDay': {} };

        for (let avgType of Object.keys(dailyAvg)) { // daily, wkEnd, & wkDay should all have same keys ['count', 'men', 'women', 'children', 'adults', 'seniors']
          percDelta.daily[avgType] = _getPercDelta(dailyAvg[avgType], dailyAvgPrev[avgType]);
          percDelta.wkEnd[avgType] = _getPercDelta(wkEndAvg[avgType], wkEndAvgPrev[avgType]);
          percDelta.wkDay[avgType] = _getPercDelta(wkDayAvg[avgType], wkDayAvgPrev[avgType]);
        }
        countAvg.delta = { 'avgDaily': percDelta.daily.count, 'avgWeekend': percDelta.wkEnd.count, 'avgWork': percDelta.wkDay.count };
        genderAvg.delta = {
          'men': { 'avgDaily': percDelta.daily.men, 'avgWeekend': percDelta.wkEnd.men, 'avgWork': percDelta.wkDay.men },
          'women': { 'avgDaily': percDelta.daily.women, 'avgWeekend': percDelta.wkEnd.women, 'avgWork': percDelta.wkDay.women }
        };
        ageAvg.delta = {
          'children': { 'avgDaily': percDelta.daily.children, 'avgWeekend': percDelta.wkEnd.children, 'avgWork': percDelta.wkDay.children },
          'adults': { 'avgDaily': percDelta.daily.adults, 'avgWeekend': percDelta.wkEnd.adults, 'avgWork': percDelta.wkDay.adults },
          'seniors': { 'avgDaily': percDelta.daily.seniors, 'avgWeekend': percDelta.wkEnd.seniors, 'avgWork': percDelta.wkDay.seniors }
        };
        vehicleAvg.delta = {
          'bikes': { 'avgDaily': percDelta.daily.bikes, 'avgWeekend': percDelta.wkEnd.bikes, 'avgWork': percDelta.wkDay.bikes },
          'buses': { 'avgDaily': percDelta.daily.buses, 'avgWeekend': percDelta.wkEnd.buses, 'avgWork': percDelta.wkDay.buses },
          'cars': { 'avgDaily': percDelta.daily.cars, 'avgWeekend': percDelta.wkEnd.cars, 'avgWork': percDelta.wkDay.cars },
          'motorbikes': { 'avgDaily': percDelta.daily.motorbikes, 'avgWeekend': percDelta.wkEnd.motorbikes, 'avgWork': percDelta.wkDay.motorbikes },
          'trucks': { 'avgDaily': percDelta.daily.trucks, 'avgWeekend': percDelta.wkEnd.trucks, 'avgWork': percDelta.wkDay.trucks }
        };
      }
    }
    else {
      // Dwell and Conversion don't provide previous or delta values yet - only update current
      countAvg.current = { 'avgDaily': dailyAvg.count, 'avgWeekend': wkEndAvg.count, 'avgWork': wkDayAvg.count };
      genderAvg.current = {
        'avgDaily': [{ 'name': 'Female', 'value': dailyAvg.women }, { 'name': 'Male', 'value': dailyAvg.men }],
        'avgWeekend': [{ 'name': 'Female', 'value': wkEndAvg.women }, { 'name': 'Male', 'value': wkEndAvg.men }],
        'avgWork': [{ 'name': 'Female', 'value': wkDayAvg.women }, { 'name': 'Male', 'value': wkDayAvg.men }]
      }
      ageAvg.current = {
        'avgDaily': [{ 'name': 'Children', 'value': dailyAvg.children }, { 'name': 'Adults', 'value': dailyAvg.adults }, { 'name': 'Seniors', 'value': dailyAvg.seniors }],
        'avgWeekend': [{ 'name': 'Children', 'value': wkEndAvg.children }, { 'name': 'Adults', 'value': wkEndAvg.adults }, { 'name': 'Seniors', 'value': wkEndAvg.seniors }],
        'avgWork': [{ 'name': 'Children', 'value': wkDayAvg.children }, { 'name': 'Adults', 'value': wkDayAvg.adults }, { 'name': 'Seniors', 'value': wkDayAvg.seniors }]
      }
    }
  }

  return [genderAvg, ageAvg, countAvg, vehicleAvg];
}

export const getBusiestHour = (respData) => {
  const objHr = ((respData[0] || {}).busiestHour[0] || {})._id;
  const bHr = (objHr !== undefined) ? objHr - 5 : undefined;
  const suffix = (bHr !== undefined) ? ((bHr >= 12 || bHr < 0) ? 'PM' : 'AM') : ''; // set AM/PM before converting from 24hr
  const hour = (bHr !== undefined) ? ((bHr >= 13) ? (bHr - 12) : (bHr <= 0) ? (bHr + 12) : bHr) : 0; // convert from 24hr to 12hr

  return [hour, suffix];
}

export const getVehicleMaxDays = (response) => {
  let sundayObj = response.find(response => response._id.dayOfWeek === 1);

  let maxDayCount = typeof(sundayObj) === 'undefined' ? 0 : sundayObj.count;
  let maxBikeCount = typeof(sundayObj) === 'undefined' ? 0 : sundayObj.bikes;
  let maxBusCount = typeof(sundayObj) === 'undefined' ? 0 : sundayObj.buses;
  let maxCarCount = typeof(sundayObj) === 'undefined' ? 0 : sundayObj.cars;
  let maxMotorbikeCount = typeof(sundayObj) === 'undefined' ? 0 : sundayObj.motorbikes;
  let maxTruckCount = typeof(sundayObj) === 'undefined' ? 0 : sundayObj.trucks;

  let [maxDay, maxBikeDay, maxBusDay, maxCarDay, maxMotorbikeDay, maxTruckDay] = [1, 1, 1, 1, 1, 1];

  for (let d = 2; d <= 7; d++) {
    let curObj = response.find(response => response._id.dayOfWeek === d);

    if (typeof curObj === 'undefined') {
      curObj = {};
    }
    else {
      if (curObj.count > maxDayCount) {
        maxDayCount = curObj.count;
        maxDay = d;
      }
      if (curObj.bikes > maxBikeCount) {
        maxBikeCount = curObj.bikes;
        maxBikeDay = d;
      }
      if (curObj.buses > maxBusCount) {
        maxBusCount = curObj.buses;
        maxBusDay = d;
      }
      if (curObj.cars > maxCarCount) {
        maxCarCount = curObj.cars;
        maxCarDay = d;
      }
      if (curObj.motorbikes > maxMotorbikeCount) {
        maxMotorbikeCount = curObj.motorbikes;
        maxMotorbikeDay = d;
      }
      if (curObj.trucks > maxTruckCount) {
        maxTruckCount = curObj.trucks;
        maxTruckDay = d;
      }
    }
  }

  return { maxBikeDay, maxBusDay, maxCarDay, maxMotorbikeDay, maxTruckDay };
}
