import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import config from '../../utils/config';
import { clientData } from '../../utils/clients';

import LocationCard from '../dashboard/LocationCard';
import LocationCompare from '../dashboard/LocationCompare';

import {
  Button, Card, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  FormControlLabel, Grid, IconButton, Switch, TextField, Toolbar, Tooltip, Typography
} from '@material-ui/core';
import { Add, CloseRounded, EditOutlined } from '@material-ui/icons';

const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (email === '') {
    return 'Please enter a user email to continue.';
  }
  else if (!re.test(email)) {
    return 'Please enter a valid email.';
  }
  return '';
};

const getCompanyName = (client) => {
  const checkClient = (client) ? client.toLowerCase() : '';

  return clientData[checkClient]?.companyName ?? '';
};

class AccessControl extends PureComponent {
  constructor (props) {
    super(props);

    this.state =  {
      editedCards: [],
      errorMessage: [''],
      initCards: [],
      locationError: [''],
      savedUserCards: [],
      unsavedChanges: false,
      userCards: this.getInitUserCard(),
    }
  }

  componentDidMount() {
    this.queryAC();
  }

  getInitUserCard = () => {
    const { locationsObject } = this.props; // use local data to layout and display first user card w/ first render
    const { client, email, locations, name } = this.props.auth.user;
    const [userCards, locCard, location, locId, initlocationCard] = [[], [], [], [], []];

    for (let l in locationsObject.locationList) {
      locCard.push(parseInt(l)); // [0,1,2,3,4,5,6,7,8]
      location.push(locationsObject.locationList[l]); // ['NYSE Intersection','Charging Bull','40 Water St','104 Fulton St','14 Wall St','54 Stone St','75 Wall St','55 Broad St','60 Broad St'] (same as locationList array ??)
      locId.push(locationsObject.locIds[l]);
      initlocationCard.push({}); // [{},{},{},{},{},{},{},{},{}]
    }

    userCards.push({
      admin: '', // placeholder until server returns data
      allLocationsChecked: (locations && locations.length) ? false : true, // if token contains locations array w/ locs: false
      client: client,
      company: '',
      dashboardChecked: true,
      email: email,
      emailChecked: false, // placeholder until server returns data
      fullName: name,
      initlocationCard: initlocationCard,
      location: location,
      locationCard: locCard,
      locationId: locId,
      locationIndex: locCard,
      noCard: (locCard.length > 0) ? false : true,
    });

    return userCards;
  }

  queryAC = () => {
    axios.get(config.api.url + '/v2/ac/get').then(response => {
      const { locationsObject } = this.props;
      const [editedCards, errorMessage, initCards, locationError, userCards] = [[], [], [], [], []];

      response.data.users.forEach(user => {
        const [newCard, locationCard, initlocationCard, locIndex, location] = [{}, [], [], [], []];

        newCard.locationId = JSON.parse(user.locations);

        for (let l in newCard.locationId) {
          const index = locationsObject.locIds.findIndex(loc => loc === newCard.locationId[l]);

          locIndex.push(index);
          location.push(locationsObject.locationList[index]);
          locationCard.push(parseInt(l)); // [0,1,2,3,4,5,6,7,8]
          initlocationCard.push({}); // [{},{},{},{},{},{},{},{},{}]
        }

        newCard.location = location;
        newCard.locationIndex = locIndex;
        newCard.locationCard = locationCard;
        newCard.initlocationCard = initlocationCard;
        newCard.noCard = (locationCard.length > 0) ? false : true

        newCard.admin = user.admin;
        newCard.allLocationsChecked = user.locationsAll ?? false;
        newCard.email = user.email;
        newCard.client = user.client;
        newCard.company = user.company;
        newCard.fullName = user.fullname;
        newCard.emailChecked = user.emailreports;
        newCard.dashboardChecked = user.dashboardaccess;

        editedCards.push(false);
        errorMessage.push('');
        initCards.push(false);
        locationError.push('');
        userCards.push(newCard);
      });

      this.setState({
        editedCards: editedCards,
        errorMessage: errorMessage,
        initCards: initCards,
        locationError: locationError,
        userCards: userCards,
        savedUserCards: JSON.parse(JSON.stringify(userCards)), // deeo copy of userCards obj
      });
    })
    .catch(error => {
      if (error.response.status === 404) this.saveInitUserCard(); // user not found, add to ac (this should never happen anymore)
    });
  };

  saveInitUserCard = () => {
    const { client, email, name } = this.props.auth.user; // this should never get called, all users exist in accessControl before first login.
    const locIds = (this.state.userCards[0].allLocationsChecked) ? [] : this.state.userCards[0].locationId;
    const newUserObj = {
      admin: email, // if user was added by an existing dashboard user they would already have a record.
      client: client,
      company: getCompanyName(client),
      dashboardaccess: true,
      email: email,
      emailreports: false,
      fullName: name,
      initSetup: true, // skip FA registration, user already has account
      locations: locIds,
      locationsAll: this.state.userCards[0].allLocationsChecked ?? false
    };

    axios.post(config.api.url + '/v2/ac/insert', newUserObj).then(() => {
      const [editedCards, errorMessage, initCards, locationError] = [[], [], [], []];
      const userCards = [...this.state.userCards];

      userCards[0] = {
        ...userCards[0],
        admin: newUserObj.admin, // update state to include admin and company (not set in getInitUserCard)
        company: newUserObj.company
      };

      editedCards.push(false);
      errorMessage.push('');
      initCards.push(false);
      locationError.push('');

      this.setState({
        editedCards: editedCards,
        errorMessage: errorMessage,
        initCards: initCards,
        locationError: locationError,
        userCards: userCards,
        savedUserCards: JSON.parse(JSON.stringify(userCards)), // deeo copy of userCards obj
      });
    })
    .catch(error => {
      // console.log('saveInitUserCard error', error);
    });
  }

  changedLocationCard = (event) => {
    const cardState = JSON.parse(JSON.stringify(event));
    const uIndex = cardState.uIndex; // get user index passed from location component
    delete cardState.uIndex; // remove uIndex from event obj so it doesn't get added to user's initlocationCard

    let locNum = 0;
    if (cardState.name >= 0 && cardState.name !== null) {
      locNum = cardState.name;
    }
    cardState.name = locNum;

    const { locationsObject } = this.props;
    const locationError = [...this.state.locationError];;
    const userCards = [...this.state.userCards]; // JSON.parse(JSON.stringify(this.state.userCards));

    const tempLocation = userCards[uIndex].location; // ['NYSE Intersection', 'Charging Bull', '40 Water St']
    const tempLocCard = userCards[uIndex].locationCard; // array of displayed loc cards [0, 1, 2, 3]
    const tempLocId = userCards[uIndex].locationId; // ['0003-1', '0003-2', '0003-4']
    const tempLocIndex = userCards[uIndex].locationIndex; // [8,1,6,4]
    const tempInitLocationCard = userCards[uIndex].initlocationCard; // [{…}, {…}, {…}]

    for (let l in locationsObject.locationList) { // array of all available locations ['NYSE Intersection', 'Charging Bull', '40 Water St']
      const curLoc = locationsObject.locationList[l];

      if (event.locationChecked[curLoc]) {
        const index = locationsObject.locationList.findIndex(loc => loc === curLoc);

        tempLocation[locNum] = curLoc;
        tempLocIndex[locNum] = index;
        tempLocId[locNum] = locationsObject.locIds[index];
      }
    }

    if (locNum > tempLocCard.length) {
      tempLocCard.push(locNum);
      tempInitLocationCard.push(cardState); // adding new location
    }
    else {
      tempLocCard[locNum] = locNum;
      tempInitLocationCard[locNum] = cardState; // changing location
    }

    userCards[uIndex] = {
      ...userCards[uIndex],
      initlocationCard: tempInitLocationCard,
      location: tempLocation,
      locationCard: tempLocCard,
      locationId: tempLocId,
      locationIndex: tempLocIndex,
      noCard: false,
    };
    locationError[uIndex] = ''; // clear possible location error

    this.setState({
      locationError: locationError,
      userCards: userCards
    });
  };

  closeLocation = (closeObj) => {
    const name = closeObj.name;
    const uIndex = closeObj.uIndex; // get user index passed from location component

    const userCards = [...this.state.userCards]; // Deep Clone forces all open locations (for all users) to rerender, shallow clone only rerender specific user's locations

    const location = userCards[uIndex].location; // Obj containing arrays for each open location card: {'0':['Woodbury Common'],'1':['Wrentham Village','Boston - Boylston'],'2':[],'3':[],...
    const locArray = userCards[uIndex].locationCard; // Array of open location card positions: [0, 1, 2]
    const tempLocId = userCards[uIndex].locationId; // ['0003-1', '0003-2', '0003-4']
    const tempLocIndex = userCards[uIndex].locationIndex; // [8,1,6,4]
    const tempInitLocationCard = userCards[uIndex].initlocationCard; // Array of objs, each obj contains location data for each open location card

    const cardInd = locArray.indexOf(name); // Index in array of location card to close (same value as name?)
    const noCard = (locArray.length === 1) ? true : false;

    locArray.pop(); // remove last element from location array, location # in this array indicates cards position
    location.splice(cardInd, 1); // specifically remove closed location from array
    tempLocId.splice(cardInd, 1);
    tempLocIndex.splice(cardInd, 1);
    tempInitLocationCard.splice(cardInd, 1);

    let count = 0;
    for (const key in location) {
      if (Object.prototype.hasOwnProperty.call(location, key)) {
        if (location[key].length !== 0) {
          if (Object.prototype.hasOwnProperty.call(tempInitLocationCard[count], 'name')) {
            tempInitLocationCard[count].name = count; // all initlocationCards are initially empty, if any are removed, new properties have data
          }

          count++;
        }
      }
    }

    userCards[uIndex] = {
      ...userCards[uIndex],
      initlocationCard: tempInitLocationCard,
      location: location,
      locationCard: locArray,
      locationId: tempLocId,
      locationIndex: tempLocIndex,
      noCard: noCard,
    };

    this.setState({ userCards: userCards });
  };

  addAllLocations = (uIndex) => () => {
    const locationError = [...this.state.locationError];
    const userCards = [...this.state.userCards]; // JSON.parse(JSON.stringify(this.state.userCards));

    const allLocIds = [...this.props.locationsObject.locIds]; // ['0003-1', '0003-2', '0003-2', ...]
    const allLocCards = Array.from(Array(allLocIds.length).keys()); // [0, 1, 2, ...]
    const allLocIndex = [...allLocCards]; // [0, 1, 2, ...]
    const allLocations = [...this.props.locationsObject.locationList]; // ['name1', 'name2', 'name3', ...]
    const allInitLocationCards = new Array(allLocations.length).fill({}); // [{}, {}, {}, ...]

    userCards[uIndex] = {
      ...userCards[uIndex],
      initlocationCard: allInitLocationCards,
      location: allLocations,
      locationCard: allLocCards,
      locationId: allLocIds,
      locationIndex: allLocIndex,
      noCard: false,
    };
    locationError[uIndex] = ''; // clear possible location error

    this.setState({
      locationError: locationError,
      userCards: userCards
    });
  };

  clearAllLocations = (uIndex) => () => {
    const userCards = [...this.state.userCards]; // JSON.parse(JSON.stringify(this.state.userCards));

    userCards[uIndex] = {
      ...userCards[uIndex],
      initlocationCard: [{}],
      location: [],
      locationCard: [],
      locationId: [],
      locationIndex: [],
      noCard: true
    };

    this.setState({ userCards: userCards });
  };

  toggleEmails = uIndex => event => {
    const userCards = [...this.state.userCards];

    userCards[uIndex] = {
      ...userCards[uIndex],
      emailChecked: event.target.checked
    };

    this.setState({ userCards: userCards });
  };

  toggleDashboard = uIndex => event => {
    const userCards = [...this.state.userCards];

    userCards[uIndex] = {
      ...userCards[uIndex],
      dashboardChecked: event.target.checked
    };

    this.setState({ userCards: userCards });
  };

  toggleAllLocations = uIndex => event => {
    const locationError = [...this.state.locationError];
    const userCards = [...this.state.userCards];

    userCards[uIndex] = {
      ...userCards[uIndex],
      allLocationsChecked: event.target.checked
    };
    if (event.target.checked) locationError[uIndex] = ''; // if toggled on, clear possible location error

    this.setState({
      locationError: locationError,
      userCards: userCards,
    });
  };

  addUser = () => {
    const { client, email } = this.props.auth.user;
    const editedCards = [...this.state.editedCards];
    const errorMessage = [...this.state.errorMessage];
    const initCards = [...this.state.initCards];
    const locationError = [...this.state.locationError];
    const userCards = [...this.state.userCards]; // JSON.parse(JSON.stringify(this.state.userCards));

    editedCards.push(true);
    errorMessage.push('');
    initCards.push(true);
    locationError.push('');
    userCards.push({
      admin: email, allLocationsChecked: false, client: client, company: '', dashboardChecked: false, email: '', emailChecked: false, fullName: '',
      initlocationCard: [{}], location: [], locationCard: [], locationId: [], locationIndex: [], noCard: true
    });

    this.setState({
      editedCards: editedCards,
      errorMessage: errorMessage,
      initCards: initCards,
      locationError: locationError,
      userCards: userCards
    });
  };

  clickCard = uIndex => () => {
    const editedCards = [...this.state.editedCards];

    if (!editedCards[uIndex]) {
      editedCards[uIndex] = true;

      this.setState({ editedCards: editedCards });
    };
  };

  changeText = uIndex => event => {
    if (event.target.id === 'email' && uIndex === 0) return;

    const userCards = [...this.state.userCards];

    userCards[uIndex] = {
      ...userCards[uIndex],
      [event.target.id]: event.target.value // company, email, fullName
    };

    this.setState({ userCards: userCards });
  };

  cancelCardChanges = uIndex => () => {
    const editedCards = [...this.state.editedCards];
    const errorMessage = [...this.state.errorMessage];
    const initCards = [...this.state.initCards];
    const locationError = [...this.state.locationError];
    const savedUserCards = [...this.state.savedUserCards];
    const userCards = [...this.state.userCards]; // JSON.parse(JSON.stringify(this.state.userCards));

    const updateSavedCards = (initCards[uIndex] && savedUserCards[uIndex] === null) ? true : false; // see note below

    if (initCards[uIndex]) {
      editedCards.splice(uIndex, 1);
      errorMessage.splice(uIndex, 1);
      initCards.splice(uIndex, 1);
      locationError.splice(uIndex, 1);
      userCards.splice(uIndex, 1); // new unsaved user (remove from userCards)

      if (updateSavedCards) savedUserCards.splice(uIndex, 1); // if multiple new user cards added and card w/ higher index is saved, null gets inserted 
    }
    else {
      editedCards[uIndex] = false;
      errorMessage[uIndex] = '';
      locationError[uIndex] = '';
      userCards[uIndex] = JSON.parse(JSON.stringify(savedUserCards[uIndex])); // existing user (replace userCard w/ saved userCard)
    }

    this.setState({
      editedCards: editedCards,
      errorMessage: errorMessage,
      initCards: initCards,
      locationError: locationError,
      userCards: userCards,
      ...(updateSavedCards && { savedUserCards: savedUserCards })
    });
  };

  saveCard = uIndex => () => {
    const editedCards = [...this.state.editedCards];
    const errorMessage = [...this.state.errorMessage];
    const initCards = [...this.state.initCards];
    const locationError = [...this.state.locationError];
    const savedUserCards = [...this.state.savedUserCards];
    const userCard = { ...this.state.userCards[uIndex] };

    // validate email field and alert user if problem/empty.
    const emailError = validateEmail(userCard.email);
    if (emailError.length > 0) {
      errorMessage[uIndex] = emailError;
      this.setState({ errorMessage: errorMessage });

      return;
    }
    errorMessage[uIndex] = '';

    // Make sure user selected at least 1 location otherwise alert user
    if (!userCard.allLocationsChecked && !userCard.locationId.length) {
      locationError[uIndex] = 'No locations selected.';
      this.setState({ locationError: locationError });

      return;
    }
    locationError[uIndex] = '';

    const uniqLocIds = (userCard.allLocationsChecked) ? [] : [...new Set(userCard.locationId)]; // remove any duplicate locIds

    if (initCards[uIndex]) {
      axios
        .post(config.api.url + '/v2/ac/insert', {
          admin: this.props.auth.user.email,
          client: this.props.auth.user.client,
          company: userCard.company,
          dashboardaccess: userCard.dashboardChecked,
          email: userCard.email,
          emailreports: userCard.emailChecked,
          fullname: userCard.fullName,
          initSetup: false,
          locations: uniqLocIds,
          locationsAll: userCard.allLocationsChecked
        })
        .then(response => {
          if (response.data.success) {
            editedCards[uIndex] = false;
            initCards[uIndex] = false;
            savedUserCards[uIndex] = userCard;
          }
        })
        .catch(error => {
          // console.log('Access Control Insert Error: ' + error);
          // handle error and inform user
          const errMsg = (error.response.data.message === 'duplicate email') ? 'A user with this email already exists.' : error.response.data.message;
          errorMessage[uIndex] = errMsg;
        })
        .finally(() => {
          this.setState({
            editedCards: editedCards,
            errorMessage: errorMessage,
            initCards: initCards,
            locationError: locationError,
            savedUserCards: JSON.parse(JSON.stringify(savedUserCards)),
          });
        });
    }
    else {
      axios
        .put(config.api.url + '/v2/ac/update', {
          admin: userCard.admin,
          client: userCard.client,
          company: userCard.company,
          dashboardaccess: userCard.dashboardChecked,
          emailreports: userCard.emailChecked,
          fullname: userCard.fullName,
          locations: uniqLocIds,
          locationsAll: userCard.allLocationsChecked,
          newEmail: userCard.email,
          originalEmail: savedUserCards[uIndex].email,
        })
        .then(response => {
          if (response.data.success) {
            editedCards[uIndex] = false;
            savedUserCards[uIndex] = userCard;
          }
        })
        .catch(error => {
          // console.log('Access Control Update Error: ' + error);
          // handle error and inform user
          alert('User could not be updated, please refresh and try again.');
        })
        .finally(() => {
          this.setState({
            editedCards: editedCards,
            errorMessage: errorMessage,
            initCards: initCards,
            locationError: locationError,
            savedUserCards: JSON.parse(JSON.stringify(savedUserCards)),
          });
        });
    }
  };

  deleteCard = uIndex => () => {
    const editedCards = [...this.state.editedCards];
    const errorMessage = [...this.state.errorMessage];
    const initCards = [...this.state.initCards];
    const locationError = [...this.state.locationError];
    const savedUserCards = [...this.state.savedUserCards];
    const userCards = [...this.state.userCards];

    const updateSavedCards = (initCards[uIndex] && savedUserCards[uIndex] === null) ? true : false; // see note below

    if (initCards[uIndex]) {
      // Card not saved yet, remove locally
      editedCards.splice(uIndex, 1);
      errorMessage.splice(uIndex, 1);
      initCards.splice(uIndex, 1);
      locationError.splice(uIndex, 1);
      userCards.splice(uIndex, 1);

      if (updateSavedCards) savedUserCards.splice(uIndex, 1); // if multiple new user cards added and card w/ higher index is saved, null gets inserted 

      this.setState({
        editedCards: editedCards,
        errorMessage: errorMessage,
        initCards: initCards,
        locationError: locationError,
        userCards: userCards,
        ...(updateSavedCards && { savedUserCards: savedUserCards }) // JSON.parse(JSON.stringify(savedUserCards)),
      });

      return;
    }

    axios
      .delete(config.api.url + '/v2/ac/delete', {
        data: {
          email: userCards[uIndex].email
        }
      })
      .then(response => {
        if (response.data.success) {
          editedCards.splice(uIndex, 1);
          errorMessage.splice(uIndex, 1);
          initCards.splice(uIndex, 1);
          locationError.splice(uIndex, 1);
          userCards.splice(uIndex, 1);
          savedUserCards.splice(uIndex, 1);
        }
      })
      .catch(error => {
        // console.log('Access Control Delete Error: ' + error.response.data.message);
        alert('User could not be removed, please refresh and try again.');
        if (editedCards[uIndex]) editedCards[uIndex] = false;
      })
      .finally(() => {
        this.setState({
          editedCards: editedCards,
          errorMessage: errorMessage,
          initCards: initCards,
          locationError: locationError,
          userCards: userCards,
          savedUserCards: savedUserCards // JSON.parse(JSON.stringify(savedUserCards)),
        });
      });
  };

  closeAccessControl = () => {
    if (this.state.editedCards.some(Boolean)) {
      this.setState({ unsavedChanges: true });
    }
    else {
      this.props.onClose(true);
    }
  };

  handleCloseUnsavedDialogue = () => {
    this.props.onClose(true); // can close without updating state, state resets when opened
  };

  handleCancelUnsavedDialogue = () => {
    this.setState({ unsavedChanges: false });
  };

  render() {
    const { client } = this.props.auth.user;
    const { accessControlOpen, locationsObject } = this.props;
    const { editedCards, errorMessage, initCards, locationError, unsavedChanges, userCards } = this.state;

    return (
      <Dialog maxWidth='lg' open={accessControlOpen} onClose={this.closeAccessControl}>
        <Grid style={{ padding: 20, backgroundColor: '#FAFAFA' }}>
          <DialogTitle>Access Control</DialogTitle>

          <DialogContent>
            {userCards.map((user, uIndex) => (
              <Collapse key={uIndex} in={(user) ? true : false} style={{ display: (user) ? '' : 'none', marginRight: 22 }}>
                <Card style={{ position: 'relative', backgroundColor: (uIndex > 0) ? 'white' : '#CAD4D7', width: '100%', padding: 10, marginBottom: 15 }} onMouseDown={this.clickCard(uIndex)}>

                  <Tooltip title='Remove Access'>
                    <IconButton style={{ position: 'absolute', right: 0, top: 0, display: (uIndex > 0 && editedCards[uIndex]) ? '' : 'none' }} onClick={this.deleteCard(uIndex)}>
                      <CloseRounded style={{ width: '18px', color: '#1e1e1e' }} />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title='Edit Access'>
                    <IconButton style={{ position: 'absolute', right: 0, top: 0, display: (!editedCards[uIndex]) ? '' : 'none' }} onClick={this.clickCard(uIndex)}>
                      <EditOutlined color='primary' style={{ width: '16px' }} />
                    </IconButton>
                  </Tooltip>

                  <Grid container item xs={12} direction='row' justifyContent='space-between' alignItems='stretch'>
                    <Grid container item xs={3} justifyContent='flex-start' alignItems='center' style={{ marginLeft: 20, marginTop: (editedCards[uIndex]) ? 0 : 3, marginBottom: (editedCards[uIndex]) ? 8 : 0, padding: 6 }}>
                      <form autoComplete='off'>
                        <TextField
                          fullWidth
                          variant='standard'
                          margin='dense'
                          id='fullName' // id must be same as state user obj key
                          type='text'
                          size='small'
                          spellCheck='false'
                          value={user.fullName}
                          label={(editedCards[uIndex]) ? 'Full Name' : ''}
                          InputProps={{ disableUnderline: (editedCards[uIndex]) ? false : true }}
                          style={{ marginTop: (editedCards[uIndex]) ? 0 : -3 }}
                          onChange={this.changeText(uIndex)}
                        />
                        <TextField
                          fullWidth
                          variant='standard'
                          margin='dense'
                          id='company' // id must be same as state user obj key
                          size='small'
                          type='text'
                          spellCheck='false'
                          label={(editedCards[uIndex]) ? 'Company' : ''}
                          InputProps={{ disableUnderline: (editedCards[uIndex]) ? false : true }}
                          value={user.company}
                          style={{ marginTop: (editedCards[uIndex]) ? 0 : -3 }}
                          onChange={this.changeText(uIndex)}
                        />
                        <TextField
                          required
                          fullWidth
                          variant='standard'
                          margin='dense'
                          id='email' // id must be same as state user obj key
                          size='small'
                          type='email'
                          spellCheck='false'
                          label={(initCards[uIndex] && editedCards[uIndex]) ? 'Email' : ''}
                          InputProps={{
                            style: { fontWeight: 600 },
                            disableUnderline: (initCards[uIndex] && editedCards[uIndex]) ? false : true,
                            readOnly: (initCards[uIndex] && editedCards[uIndex]) ? false : true
                          }}
                          value={user.email}
                          error={(errorMessage[uIndex].length > 0) ? true : false}
                          helperText={errorMessage[uIndex]}
                          style={{ marginTop: (editedCards[uIndex]) ? 0 : -3 }}
                          onChange={this.changeText(uIndex)}
                        />
                      </form>
                    </Grid>

                    <Grid container item xs={5} justifyContent='flex-start' alignItems='flex-start' style={{ padding: 6, paddingBottom: 10 }}>

                      {/* When 'All Locations' is toggled, display instead of location cards */}
                      {user.allLocationsChecked &&
                        <Grid container item justifyContent='center' alignItems='center' style={{ height: '100%' }}>
                          <Typography variant='h5'>All Locations</Typography>
                        </Grid>
                      }

                      {!user.allLocationsChecked &&
                        <Toolbar variant='dense' style={{ marginTop: 4, marginBottom: 4 }}>
                          <Grid container alignItems='center' style={{ pointerEvents: uIndex > 0 ? '' : 'none' }}>

                            {user.locationCard.map((location, index) => (
                              <div key={index} style={{ marginTop: 6, marginRight: 4 }}>
                                <LocationCard
                                  accessControl={true}
                                  acIndex={user.locationIndex[index]}
                                  closeLocation={this.closeLocation}
                                  initlocationCard={user.initlocationCard[index]}
                                  locationsObject={locationsObject}
                                  name={index} // name is for index of location card position, not for display name
                                  noCard={user.noCard}
                                  onChange={this.changedLocationCard}
                                  showCombos={false}
                                  uIndex={uIndex}
                                  showX={(uIndex > 0 && editedCards[uIndex]) ? true : false} />
                              </div>
                            ))}

                            {uIndex > 0 &&
                              <div style={{ marginTop: 5, marginLeft: 10, fontFamily: 'Lato, sans-serif' }}>
                                <LocationCompare
                                  accessControl={true}
                                  compareActiveLoc={true}
                                  locationsObject={locationsObject}
                                  name={(user.noCard) ? 0 : Math.max(...Object.values(user.locationCard)) + 1}
                                  noCard={user.noCard}
                                  onChange={this.changedLocationCard}
                                  uIndex={uIndex}
                                  showCombos={false} />
                              </div>
                            }

                            <Button disableTouchRipple={true} onClick={this.addAllLocations(uIndex)} style={{ backgroundColor: '#f5f5f5', padding: 5, marginTop: 5, marginLeft: 10, display: (uIndex > 0) ? '' : 'none' }}>
                              Add All
                            </Button>

                            <Button disableTouchRipple={true} onClick={this.clearAllLocations(uIndex)} style={{ backgroundColor: '#f5f5f5', padding: 5, marginTop: 5, marginLeft: 10, display: (uIndex > 0) ? '' : 'none' }}>
                              Clear All
                            </Button>

                          </Grid>
                        </Toolbar>
                      }
                    </Grid>

                    <Grid container item xs={3} direction='column' justifyContent='space-between' alignItems='flex-end' style={{ padding: 6, marginTop: 5 }}>
                      <Grid item container>
                        <FormControlLabel
                          disabled={false}
                          control={<Switch checked={user.emailChecked} onChange={this.toggleEmails(uIndex)} name='checkedB' color='primary' />}
                          label={(client.toLowerCase() !== 'mcdonalds') ? 'Email Reports (Monthly)' : 'Email Reports (Weekly)'}
                        />

                        <FormControlLabel
                          disabled={(uIndex === 0) ? true : false}
                          control={<Switch checked={user.dashboardChecked} onChange={this.toggleDashboard(uIndex)} name='checkedB' color='primary' />}
                          label='Dashboard Access'
                        />

                        <FormControlLabel
                          // Don't allow users that don't have access to All Locations to create new accounts w/ access to All Locations (hide and disable toggle)
                          style={{ display: (!userCards[0].allLocationsChecked) ? 'none' : '' }}
                          disabled={(uIndex === 0 || !userCards[0].allLocationsChecked) ? true : false}
                          control={<Switch checked={user.allLocationsChecked} onChange={this.toggleAllLocations(uIndex)} name='checkedB' color='primary' />}
                          label='All Locations'
                        />
                      </Grid>

                      <Grid item container style={{ padding: '6px 0' }}>
                        {/* Alert user if attempting to save before adding at least 1 location */}
                        {locationError[uIndex].length > 0 && <Typography style={{ color: '#f44336' }}>{locationError[uIndex]}</Typography>}
                      </Grid>

                      <Grid item container justifyContent='flex-end' align='flex-end' style={{ display: editedCards[uIndex] ? '' : 'none', marginRight: 15, width: '100%' }}>
                        <Tooltip title='Discard Changes'>
                          <Button color='primary' style={{ fontSize: 12, paddingLeft: 20, paddingRight: 20 }} onClick={this.cancelCardChanges(uIndex)}>
                            Cancel
                          </Button>
                        </Tooltip>
                        <Tooltip title='Save Changes'>
                          <Button color='primary' variant='outlined' style={{ fontSize: 12, paddingLeft: 20, paddingRight: 20 }} onClick={this.saveCard(uIndex)}>
                            Save
                          </Button>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
              </Collapse>
            ))}

            <Grid container justifyContent='center' align='center'>
              <Tooltip title='Add User Access'>
                <Button disableTouchRipple={true} variant='outlined' color='primary' style={{ paddingLeft: 12, paddingRight: 10, marginTop: 10 }} onClick={this.addUser}>
                  Add User
                  <Add justify='center' style={{ width: 15, paddingBottom: 0.5, paddingLeft: 6, display: 'inline' }} />
                </Button>
              </Tooltip>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Grid item container justifyContent='flex-end' align='flex-end' style={{ width: '100%' }}>
              <Tooltip title='Close Menu'>
                <Button color='primary' style={{ marginBottom: -14, paddingLeft: 15, paddingRight: 15, marginRight: -12 }} onClick={this.closeAccessControl}>
                  Close
                </Button>
              </Tooltip>

              <Dialog
                style={{ paddingLeft: 20, paddingRight: 20 }}
                maxWidth='sm'
                open={unsavedChanges}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
              >
                <DialogTitle id='alert-dialog-title'>{'Unsaved Changes'}</DialogTitle>

                <DialogContent>
                  <DialogContentText id='alert-dialog-description'>
                    Are you sure you want to close Access Control?
                    <br />
                    You will lose any unsaved changes.
                  </DialogContentText>
                </DialogContent>

                <DialogActions>
                  <Button onClick={this.handleCancelUnsavedDialogue} color='primary' style={{ width: 100, marginRight: 5, marginBottom: 10 }}>
                    Cancel
                  </Button>
                  <Button onClick={this.handleCloseUnsavedDialogue} variant='contained' autoFocus disableElevation style={{ color: 'white', backgroundColor: '#BF4040', width: 100, marginRight: 12, marginBottom: 10 }}>
                    Discard
                  </Button>
                </DialogActions>

              </Dialog>
            </Grid>
          </DialogActions>
        </Grid>
      </Dialog>

    );
  }
}

AccessControl.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(AccessControl);
