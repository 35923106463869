import axios from 'axios';

import store from '../store';
import config from './config';
import { refreshAccessToken } from '../actions/authActions';
import { getToken, logoutUser } from './services';

const tokenArray = {};
// const cacheArray = {};
const HEADER_ACCESS = config.auth.header.access;
const HEADER_REFRESH = config.auth.header.refresh;
const STORAGE_ACCESS = config.auth.localStorage.access;
const STORAGE_REFRESH = config.auth.localStorage.refresh;
const STORAGE_NOTIFY = config.auth.localStorage.notify;

let retryQueue = [];
let isRefreshing = false;

axios.interceptors.request.use((config) => {
    config.headers[HEADER_ACCESS] = getToken(STORAGE_ACCESS);
    config.headers[HEADER_REFRESH] = getToken(STORAGE_REFRESH);

    return config;
}, (error) => {
    return Promise.reject(error);
});

axios.interceptors.response.use((response) => {
    return response;
}, (error) => {
    const { config: originalReq, response: { status } } = error;

    if (status === 401 && !originalReq._retry) {
        if (!isRefreshing) {
            isRefreshing = true;

            refreshAccessToken().then(token => {
                retryRequests(token);
            }).catch(error => {
                localStorage.setItem(STORAGE_NOTIFY, 'true'); // set flag to show msg on login page
                store.dispatch(logoutUser());
            }).finally(() => {
                isRefreshing = false;
                retryQueue = [];
            });
        }

        return new Promise(resolve => {
            addRequestToQueue(token => {
                originalReq._retry = true;
                originalReq.headers[HEADER_ACCESS] = token;

                resolve(axios(originalReq));
            });
        });
    } else {
        return Promise.reject(error);
    }
});

const addRequestToQueue = (callback) => {
    retryQueue.push(callback);
}

const retryRequests = (token) => {
    retryQueue.map(cb => cb(token));
}


const createAxiosRequest = () => {
    let token;

    return async (query, req, params, forceCancel = false, reqArray = []) => {

        if (forceCancel) {
            reqArray.forEach(function(value) {
                try { 
                    tokenArray[value].cancel(); // Cancel previous requests and return /wo creating new request
                } catch (error) {}
            });

            return
        }

        if (tokenArray[req]) {
            try { 
                tokenArray[req].cancel(); // Cancel the previous request before making a new request
            } catch (error) {}
        }

        token = axios.CancelToken.source(); // Create new CancelToken
        tokenArray[req] = token;

        try {
            // if (cacheArray[query]) {
            //     return cacheArray[query]; // Return result if it exists
            // }

            const res = await axios(query, { params: params, cancelToken: token.token });
            const result = res.data;

            // cacheArray[query] = result; // Store response

            return result;
        } catch (error) {
            if (axios.isCancel(error)) {
                // console.log('Request canceled', error.message); // Handle if request was cancelled
            }
            else {
                // console.log('Something went wrong: ', error.message); // Handle usual errors
            }
        }
    }
}

export const getData = createAxiosRequest();