import axios from 'axios';
import jwt_decode from 'jwt-decode';
import config from './config';
import { SET_CURRENT_USER } from '../actions/types';

const API_URL = config.api.url;
const HEADER_ACCESS = config.auth.header.access;
const HEADER_REFRESH = config.auth.header.refresh;
const STORAGE_ACCESS = config.auth.localStorage.access;
const STORAGE_REFRESH = config.auth.localStorage.refresh;
const STORAGE_NOTIFY = config.auth.localStorage.notify;

export function isLoggedIn() {
    let token = localStorage.getItem(STORAGE_ACCESS);
    if (token !== 'undefined' && token !== null) return token;

    return false;
}

export function setAccessToken(token) {
    localStorage.setItem(STORAGE_ACCESS, token);
    axios.defaults.headers.common[HEADER_ACCESS] = token;

    return true;
}

export function setRefreshToken(token) {
    localStorage.setItem(STORAGE_REFRESH, token);
    axios.defaults.headers.common[HEADER_REFRESH] = token;

    return true;
}

export function getToken(tokenName) {
    let token = localStorage.getItem(tokenName);
    if (!token) return false;

    return token;
}

export function showInfo() {
    const info = localStorage.getItem(STORAGE_NOTIFY);
    localStorage.removeItem(STORAGE_NOTIFY);
  
    return (info !== 'undefined' && info !== null && info !== '' && info !== 'false') ? true : false;
}

export function clearTokens() {
    localStorage.removeItem(STORAGE_ACCESS);
    localStorage.removeItem(STORAGE_REFRESH);
    delete axios.defaults.headers.common[HEADER_ACCESS];
    delete axios.defaults.headers.common[HEADER_REFRESH];

    return;
}

export function clearUserData() {
    localStorage.removeItem('userData');

    return;
}

// export function invalidToken() {
//     clearTokens();
//     // clearUserData();
//     window.location.href = '/login'; // would need to clear user prop object still?
// }

export function setCurrentUser(token) {
    const decoded = (token) ? jwt_decode(token) : {}; // empty obj used for logout, sets isAuthenticated to false

    return {
        type: SET_CURRENT_USER,
        payload: decoded
    };
}

export function changeCurrentUserClient(newClient) {
    const token = getToken(STORAGE_ACCESS);
    const decoded = (token) ? jwt_decode(token) : {}; // empty obj used for logout, sets isAuthenticated to false
    decoded.client = newClient;
    decoded.clientChanged = true; // use to indicate the client was changed by user

    return {
        type: SET_CURRENT_USER,
        payload: decoded
    };
}

export const changeClient = newClient => dispatch => {
    dispatch(changeCurrentUserClient(newClient));
};

export function logoutUser() {
    return function (dispatch) {
        const logoutInstance = axios.create(); // create new instance to bypass default interceptor
        logoutInstance.defaults.headers.common[HEADER_ACCESS] = getToken(STORAGE_ACCESS);
        logoutInstance.defaults.headers.common[HEADER_REFRESH] = getToken(STORAGE_REFRESH);
        logoutInstance.get(API_URL + '/v2/user/logout').then(response => {
            clearTokens();
            // clearUserData();
            dispatch(setCurrentUser(''));
        }).catch(error => {
            clearTokens();
            // clearUserData();
            dispatch(setCurrentUser(''));
        });
    };
}

export function logoutUserOld() {
    return function (dispatch) {
        localStorage.removeItem('jwtToken');
        delete axios.defaults.headers.common['Authorization'];
        // clearUserData();
        dispatch(setCurrentUser(''));
    };
};
